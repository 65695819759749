import { ConcatDate } from "../../../../../App/Functions/ConcatDate";
import { totalReportEvents } from "../../../../../Functions/FunctionReportes";

const TablaReportes = ({ campos, data, rol, onClick, onChange }) => {
  const {
    fecha_compra,
    dni_cliente,
    telefono_cliente,
    email_cliente,
    tipo,
    multiplicador,
    monto,
    total,
    descuento,
    total_descuento,
    codigo_descuento,
    state,
  } = campos;

  return (
    <div
      className="table-responsive bg-white my-3"
      style={{ borderRadius: "10px" }}
    >
      <table
        className="table table-hover align-middle border-0"
        id="boleteriaVentas"
      >
        <thead className="border-0">
          <tr>
            <th className="border-0 text-nowrap">Id</th>
            {fecha_compra ? (
              <th className="border-0 text-nowrap">Fecha de compra</th>
            ) : (
              ""
            )}
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Nombre cliente
            </th>
            {dni_cliente ? (
              <th className="border-0 text-nowrap">
                <i className="bi bi-person"></i> Dni cliente
              </th>
            ) : (
              ""
            )}
            {telefono_cliente ? (
              <th className="border-0 text-nowrap">
                <i className="bi bi-person"></i> Telefono cliente
              </th>
            ) : (
              ""
            )}
            {email_cliente ? (
              <th className="border-0 text-nowrap">
                <i className="bi bi-person"></i> Email cliente
              </th>
            ) : (
              ""
            )}
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Codigo
            </th>
            {tipo ? (
              <th className="border-0 text-nowrap">
                <i className="bi bi-ticket-perforated"></i> Tipo
              </th>
            ) : (
              ""
            )}
            {multiplicador ? (
              <th className="border-0 text-nowrap">
                <i className="bi bi-ticket-perforated"></i> Multiplicador
              </th>
            ) : (
              ""
            )}
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Fecha de entrada
            </th>

            {monto ? (
              <th className="border-0 text-nowrap">Monto Unidad</th>
            ) : (
              ""
            )}
            <th className="border-0 text-nowrap">Cant</th>
            {total ? <th className="border-0 text-nowrap">total</th> : ""}
            {descuento ? (
              <th className="border-0 text-nowrap">Descuento</th>
            ) : (
              ""
            )}
            {total_descuento ? (
              <th className="border-0 text-nowrap">Total con descuento</th>
            ) : (
              ""
            )}
            {codigo_descuento ? (
              <th className="border-0 text-nowrap">Codigo descuento</th>
            ) : (
              ""
            )}

            <th className="border-0"></th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((reporte, index) => (
            <tr>
              <td className="border-0">{index + 1}</td>
              {fecha_compra ? (
                <td className="border-0">
                  {ConcatDate(reporte.created_at, "created_at")}
                </td>
              ) : (
                ""
              )}
              <td className="border-0">
                <div className="fw-bold fs-7">
                  <span className="fw-normal">{reporte.nombre_cliente}</span>
                </div>
              </td>
              {dni_cliente ? (
                <td className="border-0">
                  <div className="fw-bold fs-7">
                    <span className="fw-normal">{reporte.dni_cliente}</span>
                  </div>
                </td>
              ) : (
                ""
              )}
              {telefono_cliente ? (
                <td className="border-0">
                  <div className="fw-bold fs-7">
                    <span className="fw-normal">
                      {reporte.telefono_cliente
                        ? reporte.telefono_cliente
                        : reporte.usuarios
                        ? reporte.usuarios.telefono
                        : ""}
                    </span>
                  </div>
                </td>
              ) : (
                ""
              )}
              {email_cliente ? (
                <td className="border-0">
                  {reporte.email_cliente
                    ? reporte.email_cliente
                    : reporte.usuarios
                    ? reporte.usuarios.email
                    : ""}
                </td>
              ) : (
                ""
              )}
              <td className="border-0">{reporte.codigo}</td>
              {tipo ? (
                <td className="border-0">
                  {reporte.entradas ? reporte.entradas.nombre : ""}
                </td>
              ) : (
                ""
              )}
              {multiplicador ? (
                <td className="border-0">
                  {reporte.multiplicador ? "X" + reporte.multiplicador : ""}
                </td>
              ) : (
                ""
              )}
              <td className="border-0">{reporte.fecha}</td>
              {monto ? (
                <td className="border-0 text-nowrap">$ {reporte.unidad}</td>
              ) : (
                ""
              )}
              <td className="border-0">
                {reporte.multiplicador
                  ? reporte.multiplicador*reporte.cantidad
                  : reporte.cantidad}
              </td>
              {total ? (
                <td className="border-0 text-nowrap">$ {reporte.total}</td>
              ) : (
                ""
              )}
              {descuento ? (
                <td className="border-0 text-nowrap">
                  {reporte.descuento ? (
                    <div>
                      ${" "}
                      {((reporte.unidad * reporte.cantidad) / 100) *
                        reporte.descuento}
                    </div>
                  ) : (
                    "Sin descuento"
                  )}
                </td>
              ) : (
                ""
              )}
              {total_descuento ? (
                <td className="border-0">
                  {reporte.descuento ? (
                    <div>
                      ${" "}
                      {reporte.unidad * reporte.cantidad -
                        ((reporte.unidad * reporte.cantidad) / 100) *
                          reporte.descuento}
                    </div>
                  ) : (
                    "Sin descuento"
                  )}
                </td>
              ) : (
                ""
              )}
              {codigo_descuento ? (
                <td className="border-0">
                  {reporte.descuento ? (
                    <div>{reporte.codigo_descuento}</div>
                  ) : (
                    ""
                  )}
                </td>
              ) : (
                ""
              )}
              <td className="border-0">
                {reporte.registro_cliente == "ESPERA" ? (
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={"SwitchStatusReport" + index}
                      name="registro"
                      onChange={(event) => onChange(event, reporte.id)}
                    />
                    <label
                      class="form-check-label"
                      for={"SwitchStatusReport" + index}
                    >
                      En espera
                    </label>
                  </div>
                ) : reporte.registro_cliente == "CONCURRIO" ? (
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={"SwitchStatusReport" + index}
                      name="registro"
                      onChange={(event) => onChange(event, reporte.id)}
                      checked
                    />
                    <label
                      class="form-check-label"
                      for={"SwitchStatusReport" + index}
                    >
                      Registrado
                    </label>
                  </div>
                ) : reporte.registro_cliente == "AUSENTE" ? (
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={"SwitchStatusReport" + index}
                      name="registro"
                      onChange={(event) => onChange(event, reporte.id)}
                    />
                    <label
                      class="form-check-label"
                      for={"SwitchStatusReport" + index}
                    >
                      Ausente
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </td>

              <td className="border-0 d-none">
                {reporte.registro_cliente == "ESPERA" ? (
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="registro"
                    onChange={(event) => onChange(event, reporte.id)}
                  >
                    <option selected>ESPERA</option>
                    <option value="CONCURRIO">CONCURRIO</option>
                    <option value="AUSENTE">AUSENTE</option>
                  </select>
                ) : reporte.registro_cliente == "CONCURRIO" ? (
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="registro"
                    onChange={(event) => onChange(event, reporte.id)}
                  >
                    <option selected>CONCURRIO</option>
                    <option value="ESPERA">ESPERA</option>
                    <option value="AUSENTE">AUSENTE</option>
                  </select>
                ) : reporte.registro_cliente == "AUSENTE" ? (
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="registro"
                    onChange={(event) => onChange(event, reporte.id)}
                  >
                    <option selected>AUSENTE</option>
                    <option value="ESPERA">ESPERA</option>
                    <option value="AUSENTE">AUSENTE</option>
                  </select>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}

          <tr className="bg-secondary bg-opacity-50">
            <td className="border-0" colSpan={4}>
              Totales
            </td>
            {state ? <td colSpan={7}></td> : ""}
            <td className="border-0" colSpan={1}>
              {totalReportEvents(data).total_cantidad}
            </td>
            {state ? <td className="border-0">${totalReportEvents(data).total_bruto}</td> : ""}
            {
              state
              ? <td className="border-0">
              ${totalReportEvents(data).total_descuento}
            </td>
            : ""
            }
            {
              state
              ? <td className="border-0">${totalReportEvents(data).total_pagos}</td>
              : ""
            }
            {
              state
              ? <td className="border-0">{totalReportEvents(data).use_cupon}</td>
              : ""
            }
            <td className="border-0"></td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default TablaReportes;
