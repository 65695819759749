export const ConcatDate = (fecha, select) => {
  if(!fecha){
    return "Sin fecha asignada"
  }

  let result;

  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];


  switch (select) {
    case "created_at":{
      let fh = new Date(fecha);
      
      result = dias[fh.getDay()]+" "+fh.getDate()+" de "+(mes[fh.getMonth()])+" de "+fh.getFullYear()+" Hora: "+fh.getHours()+":"+fh.getMinutes()
    }
    break;
    case "funciones":{
      if(!fecha){
        return ""
      }
      let fechas = JSON.parse(fecha);
      let fh_start = fechas[0].fechas[0].split(" ");
      let fh_end = fechas[fechas.length-1].fechas[fechas[fechas.length-1].fechas.length-1].split(" ");

      result = "Desde el "+fh_start[0]+" "+fh_start[1]+" de "+fh_start[3]+" hasta el "+fh_end[0]+" "+fh_end[1]+" de "+fh_end[3]
    }
    break;
  }

  return result;
}