import { getMinutes } from "date-fns";
import { Link } from "react-router-dom";
import { ConcatDate } from "../../../Functions/ConvertDate";
import { totalReportEvents } from "../../../Functions/FunctionReportes";
import { Tickets } from "../../../Functions/Tickets";
import { Imagenes } from "../../../helpers/ImagenesHelper";

const CardEvent = ({ keys, rol, content, onClick, setModalNotificacionm, onSave }) => {
  const {
    id,
    imagen,
    nombre,
    fechas,
    categorias,
    visitas,
    descripcion,
    direccion,
    provincia,
    localidad,
    atp,
    grupo,
    clase,
    maps_sitio,
    nombre_sitio,
    telefono_sitio,
    website_sitio,
    ficha_tecnica,
    liquidacion,
    entradas,
    reportes,
    usuarios,
    created_at,
    status,
  } = content;

  return (
    <div
      className="col-md-12  p-0 m-0 mb-3 bg-white hover-card border border-light"
      style={{ borderRadius: "10px" }}
    >
      <div className="row bg-transparent w-100 m-0 p-0">
        <div className="col-md-3  border-0 p-0 m-0 d-flex jusitfy-content-center align-items-center">
          <div
            className="w-100 border-0 d-flex justify-content-center align-items-center overflow-hidden"
            style={{ height: "200px" }}
          >
            <img
              src={"/assets/img/eventos/" + imagen}
              className="img-fluid text-center"
              alt="IMAGEN NO ENCONTRADA"
            />
          </div>
        </div>
        <div className="col-md-9  p-0 m-0 ">
          <ul className="list-group">
            <li className="list-group-item bg-transparent d-flex justify-content-between border-0 mb-0 pb-0 ">
              <div className="mt-2">{nombre}</div>
              <div>
                <Link
                  to={"/evento/" + id}
                  className="btn btn-custom-black text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 mt-2 px-2 py-1 fs-10"
                >
                  <i className="fs-8 bi bi-eye "></i> Vista Previa
                </Link>
                {rol == 1 ? (
                  <button
                    title="Enviar una notificacion al usuario responsable"
                    className="btn btn-custom-amarillo GothamBold text-white rounded-pill p-0 m-0 mx-1 mt-2 px-2 py-1"
                    name="notificacion"
                    onFocus={(evento) => onClick(evento, content)}
                  >
                    <i class="fs-10 bi bi-send-plus"></i>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="list-group-item bg-transparent m-0 py-0 border-0">
              <span className="text-muted fs-10">
                {ConcatDate(fechas, "funciones")}
              </span>{" "}
            </li>
            <li className="list-group-item bg-transparent m-0 py-0 border-0">
              <span className="text-muted fs-10">Responsable</span>
              <span className="fs-10">
                {" "}
                {usuarios.nombre + " " + usuarios.apellido}
              </span>
              <span className="text-muted fs-10"> Email</span>
              <span className="fs-10"> {usuarios.email}</span>
            </li>
            <li className="list-group-item bg-transparent border-0">
              {categorias.map((categoria) => (
                <span className="btn btn-dark p-0 px-2 mx-1 rounded-1 fs-10">
                  {categoria.nombre}
                </span>
              ))}
            </li>
            <li className="list-group-item bg-transparent border-0 d-flex justify-content-between">
              <div className="d-flex">
                <span className="text-muted fs-10 me-2">
                  Entradas Vendidas{" "}
                  <p className="text-muted fw-bold">
                    {totalReportEvents(reportes).total_cantidad}
                  </p>
                </span>
                <span className="text-muted fs-10 me-2">
                  Ventas Totales{" "}
                  <p className="text-muted fw-bold">
                    ARS {Tickets(content, "sales")}{" "}
                  </p>
                </span>
                <span className="text-muted fs-10 me-2">
                  Visitas{" "}
                  <p className="text-muted fw-bold">
                    <i class="bi bi-eye"></i> {visitas}{" "}
                  </p>
                </span>
              </div>
            </li>
            
            
            <div className="d-flex justify-content-end">
                <div>
                  <Link
                    to={"/dashboard/eventos/editar/" + id}
                    className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10"
                  >
                    <i className="bi bi-pencil"></i> Editar
                  </Link>
                </div>

                <div class="dropdown">
                  <button
                    class="btn btn-custom-naranja text-white fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <label className="btn m-0 p-0">
                        <i class="text-success mx-1 bi bi-check-circle-fill"></i>
                        Activar
                        <button className="d-none"
                        name=""
                        onClick={(event) => onSave(event, {evento: content, status: 1})}></button>
                      </label>
                    </li>
                    <li> 
                      <label className="btn m-0 p-0">
                        <i class="text-secondary mx-1 bi bi-slash-circle-fill"></i>
                        Pausar
                        <button className="d-none"
                        name=""
                        onClick={(event) => onSave(event, {evento: content, status: 3})}></button>
                      </label>
                    </li>
                    <li> 
                      <label className="btn m-0 p-0">
                        <i class="text-info mx-1 bi bi-recycle"></i>
                        Borradores
                        <button className="d-none" 
                        name=""
                        onClick={(event) => onSave(event, {evento: content, status: 4})}></button>
                      </label>
                    </li>
                    {
                      rol == 1
                      ?  <li> 
                      <label className="btn m-0 p-0">
                        <i class="text-danger mx-1 bi bi-trash"></i>
                        Eliminar
                        <button className="d-none" 
                        name=""
                        onClick={(event) => onSave(event, {evento: content, status: 3})}></button>
                      </label>
                    </li>
                      : ""
                    }
                  </ul>
                </div>

                <div className="dropdown">
                  <button
                    className="btn btn-custom-naranja text-white fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ver...
                  </button>
                  <ul
                    className="dropdown-menu fs-8"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        target="_blank"
                        to={"/dashboard/boleteria/" + id}
                        className="dropdown-item"
                        href="#"
                      >
                        <i class="mx-1 bi bi-ticket-perforated"></i>
                        Boleteria
                      </Link>
                    </li>
                    {rol == 1 ? (
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          name="historial"
                          onFocus={(event) => onClick(event, content)}
                        >
                          <i class="mx-1 bi bi-clock-history"></i>
                          Historial de cambios
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>

            <li className="list-group-item bg-transparent border-0 d-flex justify-content-between border-top">
              <div className="d-flex">
                <span className="text-muted fs-10 me-2">Fecha de creación</span>
                <span className="fs-10 fw-bold me-2">
                  {ConcatDate(created_at, "created_at")}
                </span>
              </div>
              <div className="d-flex">
                <span className="text-muted fs-10 me-2">Estado del evento</span>
                <span className="fs-10 fw-bold me-2">
                  {status == 1
                    ? "ACTIVO"
                    : status == 3
                    ? "PAUSADO"
                    : status == 5
                    ? "ELIMINADO"
                    : status == 4
                    ? "BORRADOR"
                    : ""}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardEvent;
