import { useContext } from "react";
import { useEffect, useState } from "react";
import { authContext } from "../../../../context/auth/AuthContext";
import { UsuarioTicket } from "../../../../helpers/ReporteHelper";
import CardTicket from "../../Card/CardTicket";

const MisCompras = () => {
  const { user } = useContext(authContext);
  const { rol, status, auth } = user;
  const { nombre, apellido, email, telefono, created_at, imagen_logo, direccion, provincia, localidad, historial_compras} =
    user.data;


  //BUTTON EVENT
  const onChange = (e) => {

  }

  return (
    <div class="container-md min-vh-100 texto-normal"  style={{ maxWidth: "800px" }}>
    <div class="row">
      
<div class="col-md-12 mb-3">
          <h1>Mis compras</h1>
          <span class="text-muted">Visualiza tus tickets.</span>
        </div>
      <div class="col-md-12 col-12 my-2 ">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label fs-10 text-muted mb-0">Realiza una busqueda rapida de tus compras anteriores con su codigo o la fecha de entrada.</label>
          <input type="text" class="border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1" id="exampleFormControlInput1" placeholder="Busca tus tickets."/>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-between">
        <div class="fs-5"></div>

        <button class="btn btn-custom-naranja text-white fw-normal GothamBold mx-1 rounded-pill my-4 px-2 py-1 fs-10 d-flex align-items-center" disabled><i class="fs-8 bi bi-headset me-1"></i> Hablar con soporte </button>
      </div>
    </div>
    <div class="row my-5">
      {
        historial_compras.legth !== 0
        ? historial_compras.map((reporte, index) => <CardTicket keys={index+1} data={reporte} />)
        : ""
      }
    </div>
   </div>
  )
}

export default MisCompras