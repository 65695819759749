import { render } from "@testing-library/react";
import React from "react";
import { useEffect } from "react";

const Test = ({}) => {


  useEffect(() => {
    const script = document.createElement("script");

    script.src = "./assets/js/qrReader.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default Test;
