import { useState, useEffect, useContext } from "react";
import { DateRange } from "react-date-range";
import AlertWarning from "../../../../Alerts/AlertWarning";
import { CollectionStateContext } from "../../../../context/EventosState";
import {
  AdministradorCupon,
  AdministradorCuponActualizar,
  AdministradorCuponCrear,
} from "../../../../helpers/CuponHelper";
import useApi from "../../../../hooks/useApi";
import CardCupon from "../../Card/CardCupon";
import ModalAddCupon from "../../Modals/ModalAddCupon";
import ModalEditarStatusCupon from "../../Modals/ModalEditarStatusCupon";
import ModalEditarStatus from "../../Modals/ModalEditarStatusEvento";
import ModalReportCupon from "../../Modals/ModalReportCupon";
//Date format
import { format, add, sub } from "date-fns";
import Filtros from "./Filtros/Filtros";

const Cupones = () => {
  const context = useContext(CollectionStateContext);
  const { salas, usuarioEventos, provincias, administradorEventos, state } =
    context;

  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const [fecha, onFecha] = useState([
    {
      startDate: "",
      endDate: null,
      key: "selection",
    },
  ]);

  const [steps, setSteps] = useState(0);
  const [alert, setAlert] = useState({
    notificacion: false,
    cupon: false,
  });
  const [dataCupones, setCupones] = useState(null);
  const [dataEventos, setEventos] = useState(state.eventosAdm);
  const [dataSalas, setSalas] = useState(state.salas);
  const [loading, setLoading] = useState(false);
  const [selectElement, setSelect] = useState(null);

  //MODALS

  const [modal_reporte_cupon, setModalReporteCupon] = useState(false);
  const [modal_status_cupon, setModalStatusCupon] = useState(false);
  const [modal_cupon, setModalCupon] = useState(false);

  //paramentros
  const [params_cupon, setParamsCupon] = useState({
    status: "ACTIVO"
  })

  const [params, setParams] = useState({
    id: "",
    fecha: "",
    nombre: "",
    sala: "",
    status: "1",
    date_range: [
      format(new Date(), "yyyy/MM/dd").toString(),
      format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
    ],
    provincia: "",
    scroll_pagination: 0,
    offer: "",
    categoria: [],
    //static
    url: "api/auth/Eventos/All",
    pagination: 1000,
  });

  //filtros
  const [filter, setFilter] = useState({
    id: "",
    search: "",
    sala: "",
    status: "1",
    date_range: [], //dateInitialState(new Date(), 7),
    url: "/api/auth/Administrador/Eventos",
    pagination: 300,
    provincia: "",
  });

  const [filter_salas, setFilterSalas] = useState({
    id: "",
    value: "",
    searchSala: "",
    provincia: "",
    localidad: "",
    filtro: "true",
    select_id_sala: null,
  });

  //FORM

  const [formCupones, setFormCupones] = useState({
    nombre_cupon: "",
    descuento_cupon: "",
    stated: "",

    tipo_cupon: null,
    salas_habilitadas: [],
    eventos_habilitados: [],
    limite: "",

    fechas_funciones: "",

    fecha_expiracion_cupon: "",
    fecha_habilitacion_cupon: "",

    dayFilter: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    array_fechas: [],
  });

  //HELPER

  const dateRange = (e) => {
    e.preventDefault();

    if (fecha[0].endDate == null) {
      setAlert({
        ...alert,
        fecha: true,
      });
      return AlertWarning(
        "Complete todos los campos antes de cargar una fecha",
        "Advertencia"
      );
    }

    const dias_semana = [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ];

    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    let start = fecha[0].startDate.toLocaleDateString();
    let end = fecha[0].endDate.toLocaleDateString();

    let array = [];

    start = start.split("/");
    end = end.split("/");

    for (let s = parseInt(start[1]); s <= parseInt(end[1]); s++) {
      const mes = s;
      let diasTotal;

      if (s !== parseInt(start[1])) {
        for (let i = 1; i <= parseInt(end[0]); i++) {
          let concatDate = mes + "/" + i + "/" + start[2];
          let dia = new Date(concatDate);
          let fechaFormat =
            dias_semana[dia.getDay()] +
            ", " +
            dia.getDate() +
            " de " +
            meses[dia.getMonth()] +
            " de " +
            dia.getUTCFullYear();

          array.push(fechaFormat);
        }
      } else {
        diasTotal =
          start[1] != end[1]
            ? new Date(parseInt(start[2]), s, 0).getDate()
            : end[0];

        for (let i = parseInt(start[0]); i <= diasTotal; i++) {
          let concatDate = start[1] + "/" + i + "/" + start[2];
          let dia = new Date(concatDate);
          let fechaFormat =
            dias_semana[dia.getDay()] +
            ", " +
            dia.getDate() +
            " de " +
            meses[dia.getMonth()] +
            " de " +
            dia.getUTCFullYear();

          array.push(fechaFormat);
        }
      }
    }

    formCupones.dayFilter.map((day) => {
      array = array.filter((date) => !date.includes(day));
    });

    if (array.length == 0) {
      return AlertWarning(
        "El rango de fecha seleccionado no contiene un día habil",
        "Advertencia"
      );
    }

    let objFechas;
    let fechas = [];

    fechas = formCupones.array_fechas;

    array.map((item) => {
      objFechas = {
        fechas: [item],
      };

      fechas.push(objFechas);
    });

    setFormCupones({
      ...formCupones,
      array_fechas: fechas,
    });
  };

  //EVENTS

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "report_cupon":
        {
          setModalReporteCupon(true);
          setSelect(value);
        }
        break;
      case "eventos_habilitados":
        {
          e.preventDefault();

          let arrayData = [];

          if(selectElement && formCupones.eventos_habilitados.length == 0){
            selectElement.eventos.map((evento) => {
              arrayData.push(String(evento.id))
            })
          }

          let obj = arrayData.length == 0? formCupones.eventos_habilitados : arrayData;


          if(obj.includes(String(value)) == 1){
            return AlertWarning("Este evento ya fue agregado", "Advertencia");
          }

          obj.push(String(value));

          //console.log(obj);

          setFormCupones({
            ...formCupones,
            eventos_habilitados: obj,
          });
        }
        break;
      case "delete_eventos_habilitados":
        {
          e.preventDefault();

          let arrayData = [];

          if(selectElement && formCupones.eventos_habilitados.length == 0){
            selectElement.eventos.map((evento) => {
              arrayData.push(String(evento.id))
            })
          }

          let obj = arrayData.length == 0? formCupones.eventos_habilitados : arrayData;

          let deletValue = obj.filter(function (f) {
            return f !== String(value);
          });

          //console.log(deletValue);

          setFormCupones({
            ...formCupones,
            eventos_habilitados: deletValue,
          });
        }
        break;
      // CUPON
      case "cupon":
        {
          setModalCupon(true);
        }
        break;
      case "deleteFechaCupon":
        {
          e.preventDefault();
          //console.log(value);
          
          let object;

          if (formCupones.array_fechas.length == 0 && selectElement) {
            object = JSON.parse(selectElement.fechas_funciones);
          } else {
            object = formCupones.array_fechas;
          }

          /*
          let errorFecha = "FALSE";
          evento.reportes.map((reporte) => {
            console.log(reporte.fecha_entrada);
            console.log(object[value].fechas[0]+" HORA: "+object[value].hora);
            if (reporte.fecha_entrada == object[value].fechas[0]+" HORA: "+object[value].hora) {
              errorFecha = "TRUE";
            }
          });
          if (errorFecha == "TRUE") {
            return AlertWarning(
              "No se puede borrar esta fecha porque contiene entradas vendidas",
              "Advertencia"
            );
          }
          */

          delete object[value];

          object = object.filter((item) => {
            return item != null;
          });

          if (selectElement) {
            selectElement.fechas_funciones = JSON.stringify(object);
          }

          setFormCupones({
            ...formCupones,
            array_fechas: object,
          });
        }
        break;
      case "editCupon":
        {
          setModalCupon(true);
          setSelect(value);
        }
        break;
      case "estadoCupon":
        {
          setModalStatusCupon(true);
          setSelect(value);
        }
        break;
      case "close_modal":
        {
          setModalCupon(false);
          setSelect(null);
          setModalReporteCupon(false);
          setModalStatusCupon(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      //EVENTOS
      case "search":
        {
          setParams({
            ...params,
            [e.target.name]: e.target.value,
          });

          if (e.target.value == "all") {
            setParams({
              ...params,
              [e.target.name]: "",
            });
          }
        }
        break;
      //CUPON
      case "select_status":{
        setParamsCupon({
          ...params_cupon,
          status: e.target.value
        })
      }
      break;
      case "limite_fecha":
        {
          let array;
          array = formCupones.array_fechas;

          array[value].limite = e.target.value;

          setFormCupones({
            ...formCupones,
            array_fechas: array,
          });
        }
        break;
      case "day":
        {
          let array = formCupones.dayFilter;

          if (e.target.checked) {
            let deletValue = formCupones["dayFilter"].filter(function (f) {
              return f !== e.target.value;
            });

            setFormCupones({
              ...formCupones,
              dayFilter: deletValue,
            });
          } else {
            array.push(e.target.value);

            setFormCupones({
              ...formCupones,
              dayFilter: array,
            });
          }
        }
        break;
      case "salas_habilitadas":
        {
          let value = formCupones[e.target.name];

          if (e.target.checked) {
            value.push(e.target.value);
            setFormCupones({
              ...formCupones,
              [e.target.name]: value,
            });
          } else {
            let deletValue = formCupones[e.target.name].filter(function (f) {
              return f !== e.target.value;
            });
            setFormCupones({
              ...formCupones,
              [e.target.name]: deletValue,
            });
          }
        }
        break;
      case "tipo_cupon":
        {
          setFormCupones({
            ...formCupones,
            [e.target.name]: e.target.value,
            eventos_habilitados: [],
            salas_habilitadas: [],
          });
        }
        break;
      case "descuento_cupon":
      case "limite":
      case "fecha_habilitacion_cupon":
      case "fecha_expiracion_cupon":
      case "stated":
      case "nombre_cupon":
        {
          setFormCupones({
            ...formCupones,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const onAplyCupon = async (e, value) => {
    let dataForm = new FormData();

    dataForm.append("id", selectElement.id);

    if (formCupones.nombre_cupon.trim() !== "") {
      dataForm.append("nombre", formCupones.nombre_cupon);
    }

    if (formCupones.stated.trim() !== "") {
      dataForm.append("stated", formCupones.stated);
    }

    if (formCupones.descuento_cupon.trim() !== "") {
      dataForm.append("descuento", formCupones.descuento_cupon);
    }

    if (formCupones.fecha_habilitacion_cupon.trim() !== "") {
      dataForm.append(
        "fecha_habilitacion",
        formCupones.fecha_habilitacion_cupon
      );
    }

    if (formCupones.fecha_expiracion_cupon.trim() !== "") {
      dataForm.append("fecha_expiracion", formCupones.fecha_expiracion_cupon);
    }

    if (formCupones.array_fechas.length !== 0) {
      dataForm.append(
        "fechas_habilitadas",
        JSON.stringify(formCupones.array_fechas)
      );
    }

    if (formCupones.tipo_cupon) {
      dataForm.append("tipo_cupon", formCupones.tipo_cupon);
    }

    if (formCupones.limite) {
      dataForm.append("limite", formCupones.limite);
    }

    if (formCupones.eventos_habilitados.length !== 0) {
      for (let i = 0; i < formCupones.eventos_habilitados.length; i++) {
        let stringEventos = "eventos_habilitados[" + i + "]";

        dataForm.append(stringEventos, formCupones.eventos_habilitados[i]);
      }
    }

    if (formCupones.salas_habilitadas.length !== 0) {
      for (let i = 0; i < formCupones.salas_habilitadas.length; i++) {
        let stringSalas = "salas_habilitadas[" + i + "]";

        dataForm.append(stringSalas, formCupones.salas_habilitadas[i]);
      }
    }

    let response = await AdministradorCuponActualizar(dataForm, setLoading);

    setSteps(steps + 1);
  };

  const onSaveCupon = async (e, value) => {
    if (
      formCupones.nombre_cupon.trim() == "" ||
      formCupones.limite.trim() == "" ||
      !formCupones.tipo_cupon ||
      formCupones.array_fechas.length == 0 ||
      formCupones.descuento_cupon.trim() == "" ||
      formCupones.fecha_habilitacion_cupon.trim() == "" ||
      formCupones.fecha_expiracion_cupon.trim() == ""
    ) {
      setAlert({
        ...alert,
        cupon: true,
      });
      return AlertWarning(
        "Debes completar todos los campos antes de guardar",
        "Advertencia"
      );
    }

    let dataForm = new FormData();

    dataForm.append("nombre", formCupones.nombre_cupon);
    dataForm.append("descuento", formCupones.descuento_cupon);
    dataForm.append("fecha_habilitacion", formCupones.fecha_habilitacion_cupon);
    dataForm.append("fecha_expiracion", formCupones.fecha_expiracion_cupon);
    dataForm.append(
      "fechas_habilitadas",
      JSON.stringify(formCupones.array_fechas)
    );
    dataForm.append("tipo_cupon", formCupones.tipo_cupon);
    dataForm.append("limite", formCupones.limite);

    if (formCupones.eventos_habilitados.length !== 0) {
      for (let i = 0; i < formCupones.eventos_habilitados.length; i++) {
        let stringEventos = "eventos_habilitados[" + i + "]";

        dataForm.append(stringEventos, formCupones.eventos_habilitados[i]);
      }
    }

    if (formCupones.salas_habilitadas.length !== 0) {
      for (let i = 0; i < formCupones.salas_habilitadas.length; i++) {
        let stringSalas = "salas_habilitadas[" + i + "]";

        dataForm.append(stringSalas, formCupones.salas_habilitadas[i]);
      }
    }

    let response = await AdministradorCuponCrear(dataForm, setLoading);
  };

  useEffect(() => {
    const axios = async () => {
      const response = await AdministradorCupon(
        "/api/auth/Administrador/Cupones"
        , params_cupon
      );
      setCupones(response);
    };
    axios();
  }, [steps, params_cupon]);

  useEffect(() => {
    salas(
      setSalas,
      filter_salas.id,
      filter_salas.searchSala,
      filter_salas.provincia,
      filter_salas.localidad,
      filter_salas.filtro
    );
  }, [filter_salas]);
  //hooks

  const {data, message, isLoading} = useApi(params.url, params, steps)
  return (
    <div
      class="container-md min-vh-100 texto-normal"
      style={{ maxWidth: "1300px" }}
    >
      <div class="row">
        <div class="col-md-12 mb-3">
          <h1>Mis cupones</h1>
          <span class="text-muted">Visualiza tus cupones creados.</span>
        </div>
    <div className="col-md-12 mb-3">
      <button name="cupon" onClick={(event) => onClick(event)} className="btn btn-custom-naranja text-white p-0 m-0 py-2 px-2 GothamBold fs-7 rounded-pill"><i className="bi bi-plus-lg"></i> Agregar Cupon </button>
    </div>

    <div className="col-md-12">
      <Filtros 
      onClick={onClick}
      onChange={onChange}
      />
    </div>
      </div>
      <div class="row my-5">
        <div className="col-md-12">
          <CardCupon data={dataCupones} onClick={onClick} onChange={onChange} />
        </div>
      </div>

      {modal_cupon ? (
        <ModalAddCupon
          onClick={onClick}
          onChange={onChange}
          form={formCupones}
          locale={locale}
          fecha={fecha}
          onFecha={onFecha}
          dateRange={dateRange}
          onSaveCupon={onSaveCupon}
          onAplyCupon={onAplyCupon}
          alert={alert}
          selectElement={selectElement}
          eventos={data}
          salas={dataSalas}
        />
      ) : (
        ""
      )}

      {modal_status_cupon ? (
        <ModalEditarStatusCupon
          onChange={onChange}
          onClick={onClick}
          onSave={onAplyCupon}
          data={selectElement}
        />
      ) : (
        ""
      )}

      {modal_reporte_cupon ? (
        <ModalReportCupon
          onChange={onChange}
          onClick={onClick}
          cupon={selectElement}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Cupones;
