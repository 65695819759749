import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ModalAddCupon = ({
  onChange,
  onClick,
  onSaveCupon,
  eventos,
  salas,
  form,
  locale,
  fecha,
  onFecha,
  dateRange,
  alert,
  selectElement,
  onAplyCupon
}) => {
  let state = false;

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "1100px", zIndex: "106" }}
      >
        
        
        {
          selectElement
          ? <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Editar Cupón </h1>
              <span class="fs-6 text-muted">
                Edita tu cupon, agrega o quita fechas.
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <form action="" className="bg-white p-2">

              <div className="d-flex justify-content-between">
              <div className="mb-3 w-100 me-md-3">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Nombre
                </label>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="text"
                  defaultValue={selectElement.nombre}
                  name="nombre_cupon"
                  onChange={(event) => onChange(event)}
                />
                 {alert.cupon ? (
                  form.nombre_cupon.trim() == "" ? (
                    <span className="text-danger fs-7">
                      Escribe el nombre de tu cupon.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3 w-100 d-md-flex">
                <div className="w-100 pe-2">
                  <label className="text-muted fs-6 mb-1" htmlFor="">
                    Descuento
                  </label>
                  <div class="input-group">
                    <span
                      class="input-group-text btn-white border-0"
                      id="basic-addon1"
                    >
                      <i class="bi bi-percent"></i>
                    </span>
                    <input
                      type="number"
                      class="form-control border-custom-naranja rounded-start rounded-pill p-0 px-1"
                      placeholder="0"
                      aria-label="0"
                      aria-describedby="basic-addon1"
                      defaultValue={selectElement.descuento}
                      name="descuento_cupon"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  {alert.cupon ? (
                      form.descuento_cupon.trim() == ""? (
                        <span className="text-danger fs-7">
                          El descuento del cupon debe ser mayor a 0
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                </div>
              </div>
              </div>

              <div className="d-flex fustify-content-between">
              <div className="mb-3 w-100 me-md-3">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Fecha de habilitacion
                </label>
                <span className="text-muted fs-8 d-block">
                  Fecha en que el cupon comenzara a funcionar
                </span>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="date"
                  defaultValue={selectElement.fecha_habilitacion}
                  name="fecha_habilitacion_cupon"
                  onChange={(event) => onChange(event)}
                />
                {alert.cupon ? (
                  form.fecha_habilitacion_cupon.trim() == ""? (
                    <span className="text-danger fs-7">
                      Selecciona una fecha de habilitación para tu cupon
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Fecha de expiración
                </label>
                <span className="text-muted fs-8 d-block">
                  Fecha en que el cupon dejara de funcionar.
                </span>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="date"
                  defaultValue={selectElement.fecha_expiracion}
                  name="fecha_expiracion_cupon"
                  onChange={(event) => onChange(event)}
                />
                 {alert.cupon ? (
                  form.fecha_expiracion_cupon.trim() == ""? (
                    <span className="text-danger fs-7">
                    Selecciona una fecha de expiración para tu cupon
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              </div>

              <div className="mb-3">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Limite de cúpones emitidos
                </label>
                <div class="input-group">
                  <span
                    class="input-group-text  btn-white border-0"
                    id="basic-addon1"
                  >
                    <i class="bi bi-123"></i>
                  </span>
                  <input
                    type="number"
                    class="form-control border-custom-naranja rounded-start rounded-pill p-0 px-1"
                    placeholder="0"
                    aria-label="0"
                    aria-describedby="basic-addon1"
                    defaultValue={selectElement.limite}
                    name="limite"
                    onChange={(event) => onChange(event)}
                  />
                </div>
              </div>

              <label className="text-muted fs-6 mb-1" htmlFor="">
                Tipo de cupon
              </label>
              <span className="text-muted fs-8 d-block">
                Selecciona a quien quieres que se aplique el cupon
              </span>
              <div className="mb-3">
                <select
                  class="form-select rounded-pill border-custom-naranja"
                  aria-label="Default select example"
                  name="tipo_cupon"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{selectElement.tipo_aplicacion}</option>
                  <option value="eventos">Eventos</option>
                  <option value="salas">Salas</option>
                </select>
                {alert.cupon ? (
                  !form.tipo_cupon? (
                    <span className="text-danger fs-7">
                      Seleccionar tu tipo de cupon.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {form.edit_tipo_cupon 
              ? (
               form.edit_tipo_cupon == "salas" ? (
                  <div>
                    <label className="text-muted fs-6 mb-1" htmlFor="">
                      Eventos habilitados
                    </label>
                    <span className="text-muted fs-8 d-block">
                      Seleccione los eventos que podran aplicar un cupon de
                      descuento
                    </span>
                    <div
                      className="mb-3 overflow-auto "
                      style={{ height: "300px" }}
                    >
                      <ul class="list-group">
                        {salas
                          ? salas.map((sala) => (
                              <li class="list-group-item border-0">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value={sala.id}
                                    id="flexCheckDefault"
                                    name="salas_habilitadas"
                                    onChange={(event) => onChange(event)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {sala.nombre}
                                  </label>
                                </div>
                              </li>
                            ))
                          : ""}
                      </ul>
                    </div>
                    {alert.cupon ? (
                  form.salas_habilitadas.length == 0? (
                    <span className="text-danger d-block fs-7">
                      Selecciona una o más Salas
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                  </div>
                ) : form.edit_tipo_cupon == "eventos" ?(
                  <div>
                    <label className="text-muted fs-6 mb-1" htmlFor="">
                      Eventos habilitados
                    </label>
                    <span className="text-muted fs-8 d-block">
                      Seleccione los eventos que podran aplicar un cupon de
                      descuento
                    </span>
                    <div className="">
                            <label htmlFor=""></label>
                            <input 
                            className="form-control border-custom-naranja rounded-pill mb-3"
                            type="text"  
                            name="search"
                            placeholder="Busca tu evento..."
                            onChange={(event) => onChange(event)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                    <div
                      className="mb-3 overflow-auto "
                      style={{ height: "300px" }}
                    >
                      <ul class="list-group">
                        {eventos
                          ? eventos.data.map((evento) => (
                              <li class="list-group-item border-0">
                                  <button
                                    class="btn m-0 p-0"
                                    name="eventos_habilitados"
                                    onClick={(event) =>
                                      onClick(event, evento.id)
                                    }
                                  >
                                    {evento.nombre}
                                  </button>
                              </li>
                            ))
                          : ""}
                      </ul>
                    </div>
                            </div>
                            <div className="col-md-6 border-custom-naranja" style={{borderRadius: "10px"}}>
                              <span className="fw-bold">Eventos seleccionados</span>
                            <ul class="list-group">
                        {eventos
                          ? form.eventos_habilitados.length !== 0
                          ? eventos.data.map((evento) => {
                            if(form.eventos_habilitados.includes(String(evento.id)) == 1){
                              return (<li class="list-group-item border-0">
                              <div class="d-flex justify-content-between">
                                  <div>{evento.nombre}</div>
                                  <button className="btn btn-outline-danger border-0" name="delete_eventos_habilitados" onClick={(event) => onClick(event, evento.id)}>x</button>
                              </div> 
                            </li>)
                            }
                          })
                          : ""
                          : ""
                          }
                      </ul>
                            </div>
                          </div>
                    {alert.cupon ? (
                  form.eventos_habilitados.length == 0? (
                    <span className="text-danger d-block fs-7">
                      Selecciona una o más Eventos
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                  </div>
                ) : (
                  ""
                )
              ) : 
                  selectElement 
                  ? (
                    selectElement.tipo_aplicacion == "salas" ? (
                       <div>
                         <label className="text-muted fs-6 mb-1" htmlFor="">
                           Salas habilitadas
                         </label>
                         <span className="text-muted fs-8 d-block">
                         Selecciona las salas que desea aplicar el cupon, las salas marcadas es para visualizar la configuración antigua, si desea que esten en la configuración nueva debe marcarlos nuevamente. 
                         </span>
                         <div
                           className="mb-3 overflow-auto "
                           style={{ height: "300px" }}
                         >
                           <ul class="list-group">
                             {salas
                               ? salas.map((sala) => (
                                   <li class="list-group-item border-0">
                                     <div class="form-check">
                                       <input
                                         class="form-check-input"
                                         type="checkbox"
                                         value={sala.id}
                                         id="flexCheckDefault"
                                         name="salas_habilitadas"
                                         onChange={(event) => onChange(event)}
                                         
                                       />
                                       <label
                                         class={
                                          selectElement.salas.filter(item => item.id == sala.id).length > 0
                                          ? "text-custom-naranja"
                                          : "text-dark"
                                         }
                                         for="flexCheckDefault"
                                       >
                                         {sala.nombre}
                                       </label>
                                     </div>
                                   </li>
                                 ))
                               : ""}
                           </ul>
                         </div>
                         {alert.cupon ? (
                       form.salas_habilitadas.length == 0? (
                         <span className="text-danger d-block fs-7">
                           Selecciona una o más Salas
                         </span>
                       ) : (
                         ""
                       )
                     ) : (
                       ""
                     )}
                       </div>
                     ) : selectElement.tipo_aplicacion == "eventos" ?(
                      <div>
                        <label className="text-muted fs-6 mb-1" htmlFor="">
                          Eventos habilitados
                        </label>
                        <span className="text-muted fs-8 d-block">
                          Seleccione los eventos que podran aplicar un cupon de
                          descuento
                        </span>
                        <div className="">
                                <label htmlFor=""></label>
                                <input 
                                className="form-control border-custom-naranja rounded-pill mb-3"
                                type="text"  
                                name="search"
                                placeholder="Busca tu evento..."
                                onChange={(event) => onChange(event)}
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                        <div
                          className="mb-3 overflow-auto "
                          style={{ height: "300px" }}
                        >
                          <ul class="list-group">
                            {eventos
                              ? eventos.data.map((evento) => (
                                  <li class="list-group-item border-0">
                                      <button
                                        class="btn m-0 p-0"
                                        name="eventos_habilitados"
                                        onClick={(event) =>
                                          onClick(event, evento.id)
                                        }
                                      >
                                        {evento.nombre+" - "+evento.nombre_sitio}
                                      </button>
                                  </li>
                                ))
                              : ""}
                          </ul>
                        </div>
                                </div>
                                <div className="col-md-6 border-custom-naranja" style={{borderRadius: "10px"}}>
                                  <span className="fw-bold">Eventos seleccionados</span>
                                <ul class="list-group">
                            { eventos
                              ? form.eventos_habilitados.length == 0
                              ? selectElement
                              ? selectElement.eventos.map((evento) => (
                                <li class="list-group-item border-0">
                                  <div class="d-flex justify-content-between">
                                      <div>{evento.nombre+" - "+evento.nombre_sitio}</div>
                                      <button className="btn btn-outline-danger border-0" name="delete_eventos_habilitados" onClick={(event) => onClick(event, evento.id)}>x</button>
                                  </div> 
                                </li>
                                ))
                              : ""
                              : eventos.data.map((evento) => {
                                if(form.eventos_habilitados.includes(String(evento.id)) == 1){
                                  return (<li class="list-group-item border-0">
                                  <div class="d-flex justify-content-between">
                                      <div>{evento.nombre+" - "+evento.nombre_sitio}</div>
                                      <button className="btn btn-outline-danger border-0" name="delete_eventos_habilitados" onClick={(event) => onClick(event, evento.id)}>x</button>
                                  </div> 
                                </li>)
                                }
                              })
                              : ""
                              }
                          </ul>
                                </div>
                              </div>
                        {alert.cupon ? (
                      form.eventos_habilitados.length == 0? (
                        <span className="text-danger d-block fs-7">
                          Selecciona una o más Eventos
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                      </div>
                    ) : (
                       ""
                     )
                   )
                  : ""
              }

            

              <label className="text-muted fs-6 mb-1" htmlFor="">
                Fechas de funciones
              </label>
              <span className="text-muted fs-8 d-block">
                Habilita las fechas donde se encuentren funciones disponibles
              </span>
              <div className="mb-3 d-flex justify-content-center">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => onFecha([item.selection])}
                  moveRangeOnFirstSelection={false}
                  locale={locale}
                  minDate={addDays(new Date(), 0)}
                  ranges={fecha}
                  style={{ fontSize: "12px" }}
                />
              </div>

              <div className="mb-3 d-flex justify-content-center">
                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-domingo"
                    autocomplete="off"
                    name="day"
                    value="domingo"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-domingo"
                  >
                    Domingo
                  </label>
                </div>

                <div className="mx-1 d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-lunes"
                    autocomplete="off"
                    name="day"
                    value="lunes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-lunes"
                  >
                    Lunes
                  </label>
                </div>

                <div className="mx-1 d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-martes"
                    autocomplete="off"
                    name="day"
                    value="martes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-martes"
                  >
                    Martes
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-miercoles"
                    autocomplete="off"
                    name="day"
                    value="miércoles"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-miercoles"
                  >
                    Miércoles
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-jueves"
                    autocomplete="off"
                    name="day"
                    value="jueves"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-jueves"
                  >
                    Jueves
                  </label>
                </div>

                <div className="mx-1   d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-viernes"
                    autocomplete="off"
                    name="day"
                    value="viernes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-viernes"
                  >
                    Viernes
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-sabado"
                    autocomplete="off"
                    name="day"
                    value="sábado"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-sabado"
                  >
                    Sábado
                  </label>
                </div>
              </div>

              <button
                className="btn w-100 btn-custom-naranja text-white mb-3 fw-normal GothamBold rounded-pill"
                name=""
                onClick={(event) => dateRange(event)}
              >
                {" "}
                Agregar Fechas <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
              </button>

              {alert.cupon ? (
                  form.array_fechas.length == 0? (
                    <span className="text-danger fs-7">
                      Selecciona una o más fechas.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

              <div>
                <ul class="list-group">
                 {
                   form.array_fechas.length !== 0
                   ? form.array_fechas.map((fecha, index) => (
                    <li class="list-group-item border-0 d-flex justify-content-between">
                    <div className="">
                    <span className="fs-6 text-muted">Dia </span>{fecha.fechas} 
                    </div>
                    <label className="btn text-danger">
                      <i className="bi bi-x-lg"></i>
                    <button className="btn text-danger" name="deleteFechaCupon" onClick={(event) => onClick(event, index)}></button>
                    </label>
                  </li>
                   ))
                   : JSON.parse(selectElement.fechas_funciones).length == 0
                   ? ""
                   : JSON.parse(selectElement.fechas_funciones).map((fecha, index) => (
                    <li class="list-group-item border-0 d-flex justify-content-between">
                    <div className="">
                    <span className="fs-6 text-muted">Dia </span>{fecha.fechas} 
                    </div>
                    <label className="btn text-danger">
                      <i className="bi bi-x-lg"></i>
                    <button className="btn text-danger" name="deleteFechaCupon" onClick={(event) => onClick(event, index)}></button>
                    </label>
                  </li>
                   ))
                 }
                </ul>
              </div>
            </form>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close_modal"
                  onFocus={(event) => onClick(event, null)}
                >
                  cerrar
                </button>
                <button
                  class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                  name="close_modal"
                  onClick={(event) => onAplyCupon(event)}
                >
                  Applicar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
          : <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Crear Cupón</h1>
              <span class="fs-6 text-muted">
                Agrega un cupón de descuento a tu lista.
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <form action="" className="bg-white p-2">
              <div className="d-flex justify-content-between">
              <div className="mb-3 me-2 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Nombre
                </label>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="text"
                  name="nombre_cupon"
                  onChange={(event) => onChange(event)}
                />
                 {alert.cupon ? (
                  form.nombre_cupon.trim() == "" ? (
                    <span className="text-danger fs-7">
                      Escribe el nombre de tu cupon.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3 d-md-flex w-100">
                <div className="w-100 pe-2">
                  <label className="text-muted fs-6 mb-1" htmlFor="">
                    Descuento
                  </label>
                  <div class="input-group">
                    <span
                      class="input-group-text btn-white border-0"
                      id="basic-addon1"
                    >
                      <i class="bi bi-percent"></i>
                    </span>
                    <input
                      type="number"
                      class="form-control border-custom-naranja rounded-start rounded-pill p-0 px-1"
                      placeholder="0"
                      aria-label="0"
                      aria-describedby="basic-addon1"
                      name="descuento_cupon"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  {alert.cupon ? (
                      form.descuento_cupon.trim() == ""? (
                        <span className="text-danger fs-7">
                          El descuento del cupon debe ser mayor a 0
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                </div>
              </div>
              </div>
              
              
              <div className="d-flex justify-content-between">
              <div className="mb-3 me-2 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Fecha de habilitación
                </label>
                <span className="text-muted fs-8 d-block">
                  Fecha en que el cupón comenzara a funcionar
                </span>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="date"
                  name="fecha_habilitacion_cupon"
                  onChange={(event) => onChange(event)}
                />
                {alert.cupon ? (
                  form.fecha_habilitacion_cupon.trim() == ""? (
                    <span className="text-danger fs-7">
                      Selecciona una fecha de habilitación para tu cupón
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Fecha de vencimiento
                </label>
                <span className="text-muted fs-8 d-block">
                  Fecha en que el cupón dejara de funcionar.
                </span>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Mi primer cupon de descuento..."
                  type="date"
                  name="fecha_expiracion_cupon"
                  onChange={(event) => onChange(event)}
                />
                 {alert.cupon ? (
                  form.fecha_expiracion_cupon.trim() == ""? (
                    <span className="text-danger fs-7">
                    Selecciona una fecha de vencimiento para tu cupón
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              </div>

              <div className="mb-3">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Limite de cúpones emitidos
                </label>
                <div class="input-group">
                  <span
                    class="input-group-text  btn-white border-0"
                    id="basic-addon1"
                  >
                    <i class="bi bi-123"></i>
                  </span>
                  <input
                    type="number"
                    class="form-control border-custom-naranja rounded-start rounded-pill p-0 px-1"
                    placeholder="0"
                    aria-label="0"
                    aria-describedby="basic-addon1"
                    name="limite"
                    onChange={(event) => onChange(event)}
                  />
                </div>
              </div>

            

              <label className="text-muted fs-6 mb-1" htmlFor="">
                Tipo de cupón
              </label>
              <span className="text-muted fs-8 d-block">
                Selecciona como quieres que se aplique tu cupón
              </span>
              <div className="mb-3">
                <select
                  class="form-select rounded-pill border-custom-naranja"
                  aria-label="Default select example"
                  name="tipo_cupon"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>Selecciona tu tipo de cupón</option>
                  <option value="eventos">Aplicado a eventos activos</option>
                  <option value="salas">Aplicado a salas activas</option>
                  <option value="salas">Aplicado a todos los eventos</option>
                  <option value="salas">Aplicado a todas las salas</option>
                </select>
                {alert.cupon ? (
                  !form.tipo_cupon? (
                    <span className="text-danger fs-7">
                      Seleccionar tu tipo de cupon.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {form.tipo_cupon ? (
                form.tipo_cupon == "salas" ? (
                  <div>
                    <label className="text-muted fs-6 mb-1" htmlFor="">
                      Salas habilitados
                    </label>
                    <span className="text-muted fs-8 d-block">
                      Seleccione las salas que podran aplicar un cupon de
                      descuento
                    </span>
                    <div
                      className="mb-3 overflow-auto "
                      style={{ height: "300px" }}
                    >
                      <ul class="list-group">
                        {salas
                          ? salas.map((sala) => (
                              <li class="list-group-item border-0">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value={sala.id}
                                    id="flexCheckDefault"
                                    name="salas_habilitadas"
                                    onChange={(event) => onChange(event)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {sala.nombre}
                                  </label>
                                </div>
                              </li>
                            ))
                          : ""}
                      </ul>
                    </div>
                    {alert.cupon ? (
                  form.salas_habilitadas.length == 0? (
                    <span className="text-danger d-block fs-7">
                      Selecciona una o más Salas
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                  </div>
                ) : form.tipo_cupon == "eventos" ? (
                  <div>
                    <label className="text-muted fs-6 mb-1" htmlFor="">
                      Eventos habilitados
                    </label>
                    <span className="text-muted fs-8 d-block">
                      Seleccione los eventos que podran aplicar un cupon de
                      descuento
                    </span>
                    <div className="">
                            <label htmlFor=""></label>
                            <input 
                            className="form-control border-custom-naranja rounded-pill mb-3"
                            type="text"  
                            name="search"
                            placeholder="Busca tu evento..."
                            onChange={(event) => onChange(event)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                    <div
                      className="mb-3 overflow-auto "
                      style={{ height: "300px" }}
                    >
                      <ul class="list-group">
                        {eventos
                          ? eventos.data.map((evento) => (
                              <li class="list-group-item border-0">
                                  <button
                                    class="btn m-0 p-0"
                                    name="eventos_habilitados"
                                    onClick={(event) =>
                                      onClick(event, evento.id)
                                    }
                                  >
                                    {evento.nombre}
                                  </button>
                              </li>
                            ))
                          : ""}
                      </ul>
                    </div>
                            </div>
                            <div className="col-md-6 border-custom-naranja" style={{borderRadius: "10px"}}>
                              <span className="fw-bold">Eventos seleccionados</span>
                            <ul class="list-group">
                        {eventos
                          ? form.eventos_habilitados.length !== 0
                          ? eventos.data.map((evento) => {
                            if(form.eventos_habilitados.includes(String(evento.id)) == 1){
                              return (<li class="list-group-item border-0">
                              <div class="d-flex justify-content-between">
                                  <div>{evento.nombre}</div>
                                  <button className="btn btn-outline-danger border-0" name="delete_eventos_habilitados" onClick={(event) => onClick(event, evento.id)}>x</button>
                              </div> 
                            </li>)
                            }
                          })
                          : ""
                          : ""
                          }
                      </ul>
                            </div>
                          </div>
                    {alert.cupon ? (
                  form.eventos_habilitados.length == 0? (
                    <span className="text-danger d-block fs-7">
                      Selecciona una o más Eventos
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

            

              <label className="text-muted fs-6 mb-1" htmlFor="">
                Fechas de funciones
              </label>
              <span className="text-muted fs-8 d-block">
                Habilita las fechas donde se encuentren funciones disponibles
              </span>
              <div className="mb-3 d-flex justify-content-center">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => onFecha([item.selection])}
                  moveRangeOnFirstSelection={false}
                  locale={locale}
                  minDate={addDays(new Date(), 0)}
                  ranges={fecha}
                  style={{ fontSize: "12px" }}
                />
              </div>

              <div className="mb-3 d-flex justify-content-center">
                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-domingo"
                    autocomplete="off"
                    name="day"
                    value="domingo"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-domingo"
                  >
                    Domingo
                  </label>
                </div>

                <div className="mx-1 d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-lunes"
                    autocomplete="off"
                    name="day"
                    value="lunes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-lunes"
                  >
                    Lunes
                  </label>
                </div>

                <div className="mx-1 d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-martes"
                    autocomplete="off"
                    name="day"
                    value="martes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-martes"
                  >
                    Martes
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-miercoles"
                    autocomplete="off"
                    name="day"
                    value="miércoles"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-miercoles"
                  >
                    Miércoles
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-jueves"
                    autocomplete="off"
                    name="day"
                    value="jueves"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-jueves"
                  >
                    Jueves
                  </label>
                </div>

                <div className="mx-1   d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-viernes"
                    autocomplete="off"
                    name="day"
                    value="viernes"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-viernes"
                  >
                    Viernes
                  </label>
                </div>

                <div className="mx-1  d-inline mx-md-1">
                  <input
                    type="checkbox"
                    class="btn-check"
                    id="check-sabado"
                    autocomplete="off"
                    name="day"
                    value="sábado"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    class="btn btn-outline-dark text-nowrap mb-2"
                    for="check-sabado"
                  >
                    Sábado
                  </label>
                </div>
              </div>

              <button
                className="btn w-100 btn-custom-naranja text-white mb-3 fw-normal GothamBold rounded-pill"
                name=""
                onClick={(event) => dateRange(event)}
              >
                {" "}
                Agregar Fechas <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
              </button>

              {alert.cupon ? (
                  form.array_fechas.length == 0? (
                    <span className="text-danger fs-7">
                      Selecciona una o más fechas.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

              <div>
                <ul class="list-group">
                 {
                   form.array_fechas.length == 0
                   ? ""
                   : form.array_fechas.map((fecha, index) => (
                    <li class="list-group-item border-0 d-flex justify-content-between">
                    <div className="">
                    <span className="fs-6 text-muted">Dia </span>{fecha.fechas} 
                    <div className="d-flex">
                    <span className="fs-6 text-muted">Limite</span> <input type="text" className="form-control" defaultValue={form.limite} name="limite_fecha" onChange={(event) => onChange(event, index)} />
                    </div>
                    </div>
                    <label className="btn text-danger">
                      <i className="bi bi-x-lg"></i>
                    <button className="btn text-danger" name="deleteFechaCupon" onClick={(event) => onClick(event, index)}></button>
                    </label>
                  </li>
                   ))
                 }
                </ul>
              </div>
            </form>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close_modal"
                  onFocus={(event) => onClick(event, null)}
                >
                  cerrar
                </button>
                <button
                  class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                  name="close_modal"
                  onClick={(event) => onSaveCupon(event)}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        }

      </div>
    </div>
  );
};

export default ModalAddCupon;
