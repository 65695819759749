import axios from "axios";

export const obtenerProvincias = async () => {

  const result = await axios.get("https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre")

  return result.data.provincias;
}

export const obtenerMunicipios = async (provincia, nombre) => {

  const params = {
    provincia: provincia,
    nombre: nombre,
    campos: "nombre",
    max: 100
  }

  const result = await axios.get("https://apis.datos.gob.ar/georef/api/municipios", { params })
  return result.data.municipios;

}

export default (
  obtenerProvincias,
  obtenerMunicipios
);