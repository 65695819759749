// reducer
import { configureStore } from "@reduxjs/toolkit";
import me from "../slices/users/Index"
import eventos from "../slices/eventos/Index"
import categorias from "../slices/categorias/Index"


export default configureStore({
  reducer: {
    me,
    eventos,
    categorias
  }
})