import { useContext, useEffect, useState } from "react";
import { addDays } from "date-fns";

import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { CollectionStateContext } from "../../../../../context/EventosState";
import { Link } from "react-router-dom";
//Date format
import { format, add, sub } from "date-fns";
import {
  DateRangeFinde,
  DateRangeMes,
} from "../../../../../Functions/FunctionCalendario";

const Calendario = () => {
  const context = useContext(CollectionStateContext);
  const { obtenerEventos } = context;

  const [steps, setSteps] = useState(0);
  const [countClick, setClick] = useState(0);

  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const [state, setState] = useState({
    selection1: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection1",
    },
  });

  //dateRange endPoint

  const hoy = btoa(
    JSON.stringify([
      format(new Date(), "yyyy/MM/dd").toString(),
      format(new Date(), "yyyy/MM/dd").toString(),
    ])
  );
  const finde = DateRangeFinde();
  const mes = DateRangeMes();

  useEffect(() => {
    let arrayStartDate = [];
    let startDate = format(state.selection1.startDate, "yyyy/MM/dd").toString();
    let endDate = format(state.selection1.endDate, "yyyy/MM/dd").toString();
    arrayStartDate.push(startDate);
    arrayStartDate.push(endDate);

    if (countClick == 0) {
      setClick(1);
    } else {
      let dateRange = btoa(JSON.stringify(arrayStartDate));
      return (window.location.href = "/entradas/NONE/" + dateRange);
    }
  }, [state]);
  return (
    <section id="calendar" className="position-relative pb-100 ">
      <img src="/assets/img/bg-02.svg" className="img" alt="" />
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column mb-4 align-items-center wow bounceInLeft">
            <h1>Qué hacer?</h1>

            <div className="p-0 p-md-0 text-center">
              <DateRange
                onChange={(item) => setState({ ...state, ...item })}
                moveRangeOnFirstSelection={false}
                locale={locale}
                minDate={addDays(new Date(), 0)}
                ranges={[state.selection1]}
                color={"#d0552a"}
                showDateDisplay={false}
                rangeColors={["#d0552a", "#d0552a", "#d0552a"]}
              />
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-center d-flex wow bounceInRight">
            <Link
              to={"/entradas/NONE/" + hoy}
              className="big-button today d-flex align-items-center justify-content-center"
            >
              <h1>hoy</h1>
            </Link>
            <Link
              to={"/entradas/NONE/" + finde}
              className="big-button weekend d-flex align-items-center justify-content-center"
            >
              <h1>el finde</h1>
            </Link>
            <Link
              to={"/entradas/NONE/" + mes}
              className="big-button month d-flex align-items-center justify-content-center"
            >
              <h1>este mes</h1>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calendario;
