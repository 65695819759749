import { ConcatDate } from "../../../../../App/Functions/ConcatDate";
import { totalReportEvents } from "../../../../../Functions/FunctionReportes";
//Librerias
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ModalDetalle = ({
  data,
  onClick,
  onChange
}) => {

  

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "2000px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Reportes</h1>
              <span class="fs-6 text-muted">
                Detalle de reportes individuales.
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="row">

              <div className="col-md-12">
              <label
              className="btn btn-outline-dark fw-normal GothamBold me-1  my-4 px-2 py-1 fs-5"
              title="Descargar tabla"
            >
              <i class="bi bi-filetype-xls"></i>
              <ReactHTMLTableToExcel
                id="buttonDownloadExcel"
                className="d-none btn btn-outline-success rounded-pill w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
                table="reportes_eventos"
                filename="reportes"
                sheet="pagina 1"
                buttonText="Exportar tabla actual"
              />
            </label>
              </div>

              <div className="col-md-12">
                
          <div
          className="table-responsive bg-white my-3"
          style={{ borderRadius: "10px" }}
        >
          <table
            className="table table-hover align-middle border-0"
            id="reportes_detalle"
          >
            <thead className="border-0">
              <tr>
                <th className="border-0 text-nowrap">Id</th>
                <th className="border-0 text-nowrap">Fecha de compra</th>

                <th className="border-0 text-nowrap">
                  <i className="bi bi-person"></i> Nombre cliente
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-person"></i> Dni cliente
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-person"></i> Telefono cliente
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-person"></i> Email cliente
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-ticket-perforated"></i> Codigo
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-ticket-perforated"></i> Tipo
                </th>
                <th className="border-0 text-nowrap">
                  <i className="bi bi-ticket-perforated"></i> Fecha de entrada
                </th>

                <th className="border-0 text-nowrap">Monto Unidad</th>
                <th className="border-0 text-nowrap">Cant</th>
                <th className="border-0 text-nowrap">total</th>
                <th className="border-0 text-nowrap">Descuento</th>
                <th className="border-0 text-nowrap">Total con descuento</th>
                <th className="border-0 text-nowrap">Codigo descuento</th>
              </tr>
            </thead>
            <tbody className="border-0">
              { data.map((reporte, index) => (
                    <tr>
                      <td className="border-0">{index + 1}</td>
                      <td className="border-0">
                        {ConcatDate(reporte.created_at, "created_at")}
                      </td>
                      <td className="border-0">
                        <div className="fw-bold fs-7">
                          <span className="fw-normal">
                            {reporte.nombre_cliente}
                          </span>
                        </div>
                      </td>
                      <td className="border-0">
                      <div className="fw-bold fs-7">
                          <span className="fw-normal">
                            {reporte.dni_cliente}
                          </span>
                        </div>
                      </td>
                      <td className="border-0">
                      <div className="fw-bold fs-7">
                          <span className="fw-normal">
                          {reporte.telefono_cliente
                          ? reporte.telefono_cliente
                          : reporte.usuarios
                          ? reporte.usuarios.telefono
                          : ""}
                          </span>
                        </div>
                      </td>
                      <td className="border-0">
                        {reporte.email_cliente
                          ? reporte.email_cliente
                          : reporte.usuarios
                          ? reporte.usuarios.email
                          : ""}
                      </td>
                      <td className="border-0">{reporte.codigo}</td>
                      <td className="border-0">
                        {reporte.entradas ? reporte.entradas.nombre : ""}
                      </td>
                      <td className="border-0">{reporte.fecha}</td>
                      <td className="border-0 text-nowrap">$ {reporte.unidad}</td>
                      <td className="border-0">{reporte.cantidad}</td>
                      <td className="border-0 text-nowrap">$ {reporte.unidad * reporte.cantidad}</td>
                      <td className="border-0 text-nowrap">
                        {reporte.descuento ? (
                          <div>
                            ${" "}
                            {((reporte.unidad * reporte.cantidad) /
                                100) *
                                reporte.descuento}
                          </div>
                        ) : (
                          "Sin descuento"
                        )}
                      </td>
                      <td className="border-0">
                      {reporte.descuento ? (
                          <div>
                            ${" "}
                            {reporte.unidad * reporte.cantidad -
                              ((reporte.unidad * reporte.cantidad) /
                                100) *
                                reporte.descuento}
                          </div>
                        ) : (
                          "Sin descuento"
                        )}
                      </td>
                      <td className="border-0">
                        {reporte.descuento? (
                          <div>{reporte.codigo_descuento}</div>
                        ) : (
                          ""
                        )}
                      </td>

                    </tr>
                  ))
                  }

                  <tr className="bg-secondary bg-opacity-50">
                    <td className="border-0" colSpan={10}>Totales</td>
                    <td className="border-0" colSpan={1}>{totalReportEvents(data).total_cantidad}</td>
                    <td className="border-0">{totalReportEvents(data).total_bruto}</td>
                    <td className="border-0">{totalReportEvents(data).total_descuento}</td>
                    <td className="border-0">{totalReportEvents(data).total_pagos}</td>
                    <td className="border-0">{totalReportEvents(data).use_cupon}</td>
                  </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
              </div>
            </div>
          
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary rounded-pill px-4 me-1"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                cerrar
              </button>
              <button
                class="btn btn-custom-amarillo text-white rounded-pill px-4 "
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDetalle