import { useContext } from "react";
import { authContext } from "../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import { useState } from "react";
import AlertWarning from "../../../Alerts/AlertWarning";
import { Checkout, TokenCard } from "../../../helpers/PagosHelper";
import useApi from "../../../hooks/useApi";
import {
  Totales,
  validacionDescuento,
  validacionEntradas,
} from "../../../Functions/FunctionPasarela";
import { Payment, PaymentFree } from "../../../helpers/DecidirHelper";
import { Cupon } from "../../../helpers/CuponHelper";
import ModalMembresia from "../Modals/ModalMembresia";
import { BtnMembreships } from "../../../Functions/FunctionBtnMembership";

const PasarelaDePagos = () => {
  const user = useContext(authContext);
  const { rol, comisiones } = user.user;
  const userData = user.user.data;

  //Encode data
  const { info } = useParams();
  let infoEncode = info.replace("-", "/");
  infoEncode = JSON.parse(decodeURIComponent(escape(atob(infoEncode))));
  const { entradas, evento, entradas_gratis } = infoEncode;

  //Tasa
  const { tasa } = evento;

  //states
  const [err_payment, setErrPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [steps, setSteps] = useState(0);
  const [statePayment, setStatePayment] = useState(false);
  const [cupon, setCupon] = useState({
    codigo: "",
    descuento: "",
    estado: "",
    fechas: "",
  });

  //parametros
  const [params, setParams] = useState({
    //static
    url: "api/SolicitarMedioPago",
  });

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);

  //formulario
  const [form, setForm] = useState({
    payment_method_id: "",
    card_number: "",
    card_expiration_month: "",
    card_expiration_year: "",
    security_code: "",
    card_holder_name: userData ? userData.nombre + " " + userData.apellido : "",
    type: "",
    numero: "",
    nombre: userData ? userData.nombre + " " + userData.apellido : "",
    email: userData ? userData.email : "",
    email_confirmation: "",
    telefono: userData ? userData.telefono : "",

    select_card: null,
    type_card: "",
  });

  //eventos
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "type_card":
        {
          setForm({
            ...form,
            [e.target.name]: value,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "select_card":
        {
          setForm({
            ...form,
            [e.target.name]: JSON.parse(e.target.value),
          });
        }
        break;
      case "telefono":
      case "email":
      case "email_confirmation":
      case "card_holder_name":
      case "card_expiration_year":
      case "card_expiration_month":
      case "security_code":
      case "card_number":
      case "type":
      case "numero":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //DESCUENTO
      case "codigoDescuento":
        {
          let cantidad = 0;

          entradas.map((entrada) => {
            cantidad = entrada.cantidad + cantidad;
          });

          let params = {
            cupon: e.target.value,
            cantidad: cantidad,
            id_evento: evento.id,
          };

          const axios = async () => {
            let response = await Cupon(params, setLoading);

            if (response == "INACTIVO") {
              setCupon({
                codigo: "",
                descuento: "",
                estado: response,
                fechas: "",
              });
            } else {
              setCupon({
                codigo: response.nombre,
                descuento: response.descuento,
                estado: response.status,
                fechas: response.fechas_funciones,
              });
            }
          };

          if (e.target.value.length > 4) {
            axios();
          } else {
            setCupon({
              codigo: "",
              descuento: "",
              estado: "",
              fechas: "",
            });
          }
        }
        break;
    }
  };

  const CheckOutPayments = async (e) => {
    //VALIDATION
    setLoading(true);

    if (form.card_holder_name.trim() == "") {
      setLoading(false);
      setAlert(true);
      return AlertWarning(
        "El nombre del titular es un dato obligatorio.",
        "Advertencia"
      );
    }

    if (form.email.trim() == "") {
      setLoading(false);
      setAlert(true);
      return AlertWarning("El correo es un dato obligatorio.", "Advertencia");
    }

    let validation_mails = form.email.split("@");
    validation_mails = validation_mails[1];

    if (
      [
        "gmail.com",
        "hotmail.com",
        "hotmail.com.ar",
        "live.com.ar",
        "live.com",
        "yahoo.com.ar",
        "yahoo.com",
        "protonmail.com",
        "outlook.com",
        "itemsa.com.ar"
      ].indexOf(validation_mails) < 0
    ) {
      setLoading(false);
      setAlert(true);
      return AlertWarning("No es un formato de correo valido.", "Advertencia");
    }

    if (form.email_confirmation.trim() == "") {
      setLoading(false);
      setAlert(true);
      return AlertWarning(
        "El correo de confirmacion es un dato obligatorio.",
        "Advertencia"
      );
    }

    if (form.email !== form.email_confirmation) {
      setLoading(false);
      setAlert(true);
      return AlertWarning("Su correo no coincide.", "Advertencia");
    }

    if (entradas_gratis.length > 0) {
      //crear formulario
      let formData = new FormData();

      formData.append("nombre", form.card_holder_name);
      formData.append("email", form.email);
      formData.append("id_evento", evento.id);
      formData.append("entradas", JSON.stringify(entradas_gratis));

      PaymentFree(formData, setLoading, entradas);
    }

    if (entradas.length > 0) {
      if (form.select_card) {
        if (form.card_number.length < form.select_card.longitud_pan) {
          setLoading(false);
          setAlert(true);
          return AlertWarning(
            "EL número de tarjeta no corresponde a la cantidad de caracteres permitidos",
            "Advertencia"
          );
        }

        if (form.security_code.length < form.select_card.formato_cvv) {
          setLoading(false);
          setAlert(true);
          return AlertWarning(
            "EL codigo de seguridad de la tarjeta no corresponde a la cantidad de caracteres permitidos",
            "Advertencia"
          );
        }
      }

      if (
        form.card_holder_name.trim() == "" ||
        form.numero.trim() == "" ||
        form.card_expiration_month.trim() == "" ||
        form.card_expiration_year.trim() == "" ||
        form.type.trim() == ""
      ) {
        setLoading(false);
        setAlert(true);
        return AlertWarning("Todos los campos son obligatorios", "Advertencia");
      }

      //Creacion de formulario
      let formData = {
        card_number: form.card_number,
        card_expiration_month: form.card_expiration_month,
        card_expiration_year: form.card_expiration_year,
        security_code: form.security_code,
        card_holder_name: form.card_holder_name,

        card_holder_identification: {
          type: form.type,
          number: form.dni,
        },

        fraud_detection: {
          device_unique_identifier: "12345",
        },

        ip_address: "192.168.100.1",
      };

      if (
        form.payment_method_id == 1 ||
        form.payment_method_id == 31 ||
        form.payment_method_id == 24 ||
        form.payment_method_id == 105 ||
        form.payment_method_id == 106 ||
        form.payment_method_id == 108
      ) {
        formData = {
          ...formData,
          card_holder_birthday: "01012020",
          card_holder_door_number: 333,
        };
      }

      let token = await TokenCard(formData, setLoading);

      //validacion de cupon
      if (cupon.estado == "ACTIVO") {
        entradas.map((entrada) => {
          let state = false;
          entrada.codigo_descuento = null;
          //concat fecha
          let funcion = entrada.fecha_funcion.split(" ");
          funcion =
            funcion[0] +
            " " +
            funcion[1] +
            " " +
            funcion[2] +
            " " +
            funcion[3] +
            " " +
            funcion[4] +
            " " +
            funcion[5];
          let fechas = JSON.parse(cupon.fechas);

          fechas.map((fecha) => {
            if (fecha.fechas[0] == funcion) {
              //confirma existencia
              state = true;
            }
          });

          //send codigo cupon
          if (state) {
            entrada.codigo_descuento = cupon.codigo;
          }
        });
      }

      //Validar Membresia
      //let membresia = null;

      //formulario
      formData = {
        nombre: form.card_holder_name,
        dni: form.numero,
        telefono: form.telefono,
        token: token.id,
        email: form.email,
        bin: token.bin,
        payment_method: form.select_card.id_medio_pago,
        //entradas
        id_evento: evento.id,
        entradas: JSON.stringify(entradas),
        //Membresia
        //membresia: membresia
      };

      Payment(formData, setLoading, setStatePayment, setErrPayment);
    }
  };

  if (err_payment) {
    return (
      <div className="">
        <div
          className="container p-5 shadow-sm text-center"
          style={{ borderRadius: "10px" }}
        >
          <div className="text-danger" style={{ fontSize: "100px" }}>
            <i class="bi bi-exclamation-diamond-fill"></i>
          </div>
          <div className="fs-5">
            <p className="fw-bold fs-4">Transacción invalida, consulte con su medio de pago.</p>
            <ul class="list-group">
              <li class="list-group-item border-0 text-danger">
                Estado: {err_payment.status}
              </li>
              <li class="list-group-item border-0 text-danger">
                Razón: {err_payment.reason}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {statePayment ? (
        <div className="">
          <div
            className="container p-5 shadow-sm text-center"
            style={{ borderRadius: "10px" }}
          >
            <div className="text-success" style={{ fontSize: "100px" }}>
              <i class="bi bi-cart-check"></i>
            </div>
            <div className="fs-5">
              <p className="fw-bold fs-4">¡Compra realizada con éxito!</p>
              <p>
                Se enviará un correo a la cuenta{" "}
                <span className="fw-bold">{form.email}</span> con los datos de
                su compra. Si tienes una cuenta registrada podras ver el
                historial de compras desde tu perfil.
              </p>
              {userData ? (
                <p>
                  <a className="fw-bold" href="/dashboard/espectador">
                    Ver mis entradas
                  </a>
                </p>
              ) : (
                <p>
                  <a className="fw-bold" href="/ingresar">
                    Inicia sesión en tu cuenta para acceder a tus entradas{" "}
                  </a>{" "}
                  o
                  <a className="fw-bold" href="/registro">
                    {" "}
                    Regístrate
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="container-md texto-normal">
          <div className="row">
            <div className="col-md-12 py-3 text-center">
              <span className="fs-3 fw-bold">Formulario de pago seguro</span>
              <span className="fs-5 text-muted d-block">
                Pago seguro con encriptado SSL
              </span>
            </div>
            <div className="col-md-6 mb-3">
              <div className="text-muted fs-7 text-center mb-2">
                Selecciona un tipo de tarjeta para seguir con el formulario
              </div>
              <div className="text-center">
                <button
                  className={
                    entradas.length > 0
                      ? form.type_card == "credito"
                        ? "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1 active"
                        : "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1"
                      : "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1 disabled"
                  }
                  name="type_card"
                  onClick={(event) => onClick(event, "credito")}
                >
                  <i className="bi bi-credit-card"></i> Paga con tarjeta de
                  credito{" "}
                </button>
                <button
                  className={
                    entradas.length > 0
                      ? form.type_card == "debito"
                        ? "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1 active"
                        : "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1"
                      : "btn btn-outline-primary mb-3 w-100 rounded-pill  py-4 px-3 mx-1 disabled"
                  }
                  name="type_card"
                  onClick={(event) => onClick(event, "debito")}
                >
                  <i className="bi bi-credit-card"></i> Paga con tarjeta de
                  debito{" "}
                </button>
              </div>

              <div>
                <label htmlFor="" className="text-muted">
                  Selecciona tu forma de pago
                </label>
                <select
                  className="form-select border  mb-3"
                  aria-label="Default select example"
                  name="select_card"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{form.payment_method_id}</option>
                  {data
                    ? data.map((id) => {
                        if (form.type_card == id.tipo) {
                          return (
                            <option value={JSON.stringify(id)}>
                              {id.marca + " - " + id.tipo}
                            </option>
                          );
                        }
                      })
                    : ""}
                </select>
              </div>

              {form.select_card ? (
                <div className="row w-100 m-0 p-0">
                  <div className="col-md-12 m-o p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Número de tarjeta
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Ingresa los{" "}
                          {form.select_card
                            ? form.select_card.longitud_pan
                            : "xx"}{" "}
                          digitos de tu tarjeta sin espacios ni guiones.
                        </span>
                        <input
                          className="form-control px-2 border"
                          type="number"
                          name="card_number"
                          onChange={(event) => onChange(event)}
                        />{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.card_number.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete su número de tarjeta{" "}
                        </span>
                      ) : form.card_number.length >
                        form.select_card.longitud_pan ? (
                        <span className="text-danger">
                          {" "}
                          El número ingresado es mayor al número de tarjeta
                          permitido{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-0 p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Fecha de vencimiento
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Ingresa la fecha de vencimiento de tu tarjeta en
                          formato MM/AA.
                        </span>
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select w-100 border me-1  mb-3"
                            aria-label="Default select example"
                            name="card_expiration_month"
                            onChange={(event) => onChange(event)}
                          >
                            <option selected>MM</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <select
                            className="form-select w-100 border me-1  mb-3"
                            aria-label="Default select example"
                            name="card_expiration_year"
                            onChange={(event) => onChange(event)}
                          >
                            <option selected>AAAA</option>
                            <option value="22">2022</option>
                            <option value="23">2023</option>
                            <option value="24">2024</option>
                            <option value="25">2025</option>
                            <option value="26">2026</option>
                            <option value="27">2027</option>
                            <option value="28">2028</option>
                            <option value="29">2029</option>
                            <option value="30">2030</option>
                            <option value="31">2031</option>
                            <option value="32">2032</option>
                            <option value="33">2033</option>
                            <option value="34">2034</option>
                            <option value="35">2035</option>
                            <option value="36">2036</option>
                            <option value="37">2037</option>
                            <option value="38">2038</option>
                            <option value="39">2039</option>
                            <option value="40">2040</option>
                            <option value="41">2041</option>
                            <option value="42">2042</option>
                            <option value="43">2043</option>
                            <option value="44">2044</option>
                          </select>
                        </div>
                      </span>
                    </div>
                    {alert ? (
                      form.card_expiration_month.trim() == "" ||
                      form.card_expiration_year.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete los datos de vencimiento de tu tarjeta{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-o p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Codigo de seguridad
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Iltimos{" "}
                          {form.select_card
                            ? form.select_card.formato_cvv
                            : "xxxx"}{" "}
                          digitos del reverso de tu tarjeta.
                        </span>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            className="form-control px-2 border me-2"
                            type="password"
                            name="security_code"
                            onChange={(event) => onChange(event)}
                          />
                          <i className="fs-2 bi bi-credit-card"></i>
                        </div>{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.security_code.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete su codigo de seguridad{" "}
                        </span>
                      ) : form.security_code.length >
                        form.select_card.longitud_pan ? (
                        <span className="text-danger">
                          {" "}
                          El número ingresado es mayor al número de seguridad de
                          la tarjeta{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-100 text-center mb-3">
                  {" "}
                  Seleccione el tipo de pago y tu tarjeta para continuar{" "}
                </div>
              )}

              <div className="text-bold fs-6 w-100 mb-3">
                Titular de la tarjeta
                <span className="fw-normal text-dark">
                  <span className="d-block fs-7 text-muted">
                    Ingresa el nombre y apellido como figura en la parte
                    posterior de la tarjeta.
                  </span>
                  <input
                    className="form-control px-2 border "
                    type="text"
                    defaultValue={
                      form.card_holder_name == ""
                        ? form.nombre
                        : form.card_holder_name
                    }
                    placeholder="Ingresa tu nombre y apellido..."
                    name="card_holder_name"
                    onChange={(event) => onChange(event)}
                  />{" "}
                </span>
              </div>

              <div className="col-md-12 m-0 p-0 mb-3 ">
                {alert ? (
                  form.card_holder_name.trim() == "" ? (
                    <span className="text-danger d-block">
                      Ingrese el nombre y apellido del titular de la tarjeta
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-12 p-0 m-0 mb-3">
                    <label htmlFor="" className="d-block ">
                      Tipo de documento
                    </label>

                    <div className="d-flex">
                      <select
                        className="form-select w-100 border me-1  mb-3"
                        aria-label="Default select example"
                        name="type"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>{form.type}</option>
                        <option value="dni">DNI</option>
                        <option value="cuil">CUIL</option>
                      </select>
                      <input
                        className="form-control  border  ms-1 mb-3"
                        type="number"
                        placeholder="Ingrese su número de DNI o CUIL"
                        name="numero"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    {alert ? (
                      form.type.trim() == "" || form.numero.trim() == "" ? (
                        <span className="text-danger">
                          Ingrese su tipo o número de documento
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

              <div className="col-md-12 m-0 p-0  mb-3">
                <div className="text-bold fs-6 w-100">
                  Email{" "}
                  <span className="fw-normal text-dark">
                    <span className="d-block fs-7 text-muted">
                      La información de operación se enviara a esta dirección.
                    </span>
                    <input
                      className="form-control px-2 border "
                      type="email"
                      defaultValue={form.email}
                      placeholder="Ingresa tu email..."
                      name="email"
                      onChange={(event) => onChange(event)}
                      required
                    />{" "}
                  </span>
                </div>
                {alert ? (
                  form.email.trim() == "" ? (
                    <span className="text-danger d-block">
                      Ingrese su email.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-12 m-0 p-0  mb-3">
                <div className="text-bold fs-6 w-100">
                  Repita su email
                  <span className="fw-normal text-dark">
                    <span className="d-block fs-7 text-muted">
                      Confirme su email.
                    </span>
                    <input
                      className="form-control px-2 border "
                      type="email"
                      autoComplete="off"
                      placeholder="Ingresa tu email..."
                      name="email_confirmation"
                      onChange={(event) => onChange(event)}
                      required
                    />{" "}
                  </span>
                </div>
                {alert ? (
                  form.email !== form.email_confirmation ? (
                    <span className="text-danger d-block">
                      Su email no coincide.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-12 m-0 p-0  mb-3">
                <div className="text-bold fs-6 w-100">
                  Telefono{" "}
                  <span className="fw-normal text-dark">
                    <span className="d-block fs-7 text-muted">
                      introduce tu número para que podamos contactarte.
                    </span>
                    <input
                      className="form-control px-2 border "
                      type="number"
                      defaultValue={form.telefono}
                      placeholder="Ingresa tu telefono..."
                      name="telefono"
                      onChange={(event) => onChange(event)}
                      disabled={userData ? true : false}
                    />{" "}
                  </span>
                </div>
                {alert ? (
                  form.telefono ? (
                    <span className="text-danger d-block">
                      Ingrese su número de telefono.
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              
            </div>

            <div className="col-md-6 mb-3">
              <ul className="list-group">
                <li className="list-group-item border-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={"https://antesala.com.ar/assets/img/eventos/" + evento.imagen}
                      className="img-fluid"
                      alt="IMAGEN NO ENCONTRADA"
                    />
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-center align-items-center border-0">
                  <span className="fs-4">{evento.nombre}</span>
                </li>

                <li className="list-group-item border-0 fw-bold">
                  <span>Detalles de tu compra</span>
                </li>

                {entradas_gratis.map((entrada) => (
                  <ul
                    className="list-group mb-3 border p-2"
                    style={{ borderRadius: "20px" }}
                  >
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Función</div>
                      <div>{entrada.fecha_funcion}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Entrada</div>
                      <div>
                        {entrada.nombre}
                        {entrada.multiplicador ? (
                          <span className="text-custom-naranja">
                            {" X" + entrada.multiplicador}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Cantidad</div>
                      <div>{entrada.cantidad}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Precio</div>
                      <div>
                        <span className="fs-6"></span> GRATIS
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">SubTotal</div>
                      <div className="text-nowrap d-flex">
                        <span className="fs-6"> </span>
                        <span className="ms-1">GRATIS</span>
                      </div>
                    </li>
                  </ul>
                ))}

                {validacionEntradas(entradas, cupon).map((entrada) => (
                  <ul
                    className="list-group mb-3 border p-2"
                    style={{ borderRadius: "20px" }}
                  >
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Función</div>
                      <div>{entrada.fecha_funcion}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Entrada</div>
                      <div>
                        {entrada.nombre}
                        {entrada.multiplicador ? (
                          <span className="text-custom-naranja">
                            {" X" + entrada.multiplicador}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Cantidad</div>
                      <div>{entrada.cantidad}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Precio</div>
                      <div>
                        <span className="fs-6">$</span> {entrada.precio}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">SubTotal</div>
                      <div className="text-nowrap d-flex">
                        <span className="fs-6">$ </span>
                        {cupon.estado == "ACTIVO" &&
                        entrada.total_descontado ? (
                          <div className="ms-2">
                            <span className="text-decoration-line-through">
                              {" "}
                              {" " + entrada.total}
                            </span>
                            <span> {" " + entrada.total_descontado}</span>
                          </div>
                        ) : (
                          <span className="ms-1">{entrada.total}</span>
                        )}
                      </div>
                    </li>
                  </ul>
                ))}

                <li className="list-group-item d-flex justify-content-between border-0">
                  {validacionDescuento(entradas, evento.cupones) ? (
                    <div class="mb-3 border p-4 w-100 bg-secondary "
                    style={{ borderRadius: "20px" }}>
                      <label
                        for="exampleFormControlInput1"
                        className="form-label text-white"
                      >
                        Ingresa tu cúpon de descuento
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Escriba su codigo de cúpon..."
                        name="codigoDescuento"
                        onChange={(event) => onChange(event)}
                      />
                      <span className="text-white">
                        {cupon.estado.trim() == ""
                          ? "Ingrese su cúpon de descuento"
                          : cupon.estado == "ACTIVO"
                          ? "Cúpon con descuento del " + cupon.descuento + "%"
                          : "Codigo invalido"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </li>

                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">SubTotal</div>
                  <div>
                    <span className="fs-6">$</span>{" "}
                    {Totales(entradas, cupon, tasa).subTotal}
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">
                    +Serv ({Totales(entradas, cupon, tasa).tipo})
                  </div>
                  <div>
                    <span className="fs-6">$</span>{" "}
                    {entradas.length > 0
                      ? Totales(entradas, cupon, tasa).serv
                      : "0"}
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0 ">
                  <div className="fw-bold">Total</div>
                  <div className="text-nowrap d-flex">
                    <span className="fs-6">$ </span>
                    {entradas.length > 0 ? (
                      Totales(entradas, cupon, tasa).total_descontado > 0 ? (
                        <div className="ms-2">
                          <span className="text-decoration-line-through">
                            {Totales(entradas, cupon, tasa).total}
                          </span>
                          <span>
                            {" " +
                              Totales(entradas, cupon, tasa).total_descontado}
                          </span>
                        </div>
                      ) : (
                        Totales(entradas, cupon, tasa).total
                      )
                    ) : (
                      " 0"
                    )}
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-12 my-3">
              <button
                className="btn btn-custom-amarillo  py-3 w-100"
                onClick={(event) => CheckOutPayments(event)}
                disabled={loading}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div class="spinner-grow mx-2" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <span>Procesando pago</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span>Confirmar pago</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PasarelaDePagos;
