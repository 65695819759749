import { useParams } from "react-router-dom"

const AcceptPago = () => {

  const {info, res} = useParams();

  //console.log(JSON.parse(res));

  return(
    <div className="min-vh-100">
    <div className="container p-5 shadow-sm text-center" style={{borderRadius: "10px"}}>  
    <div className="text-success" style={{fontSize: "100px"}}>
    <i class="bi bi-cart-check"></i>
    </div>
    <div className="fs-5">
      <p className="fw-bold fs-4">¡Compra realizada con éxito!</p>
      <p>Se enviará un correo a la cuenta <span className="fw-bold">{info}</span> con los datos de su compra,  y podrá ver el historial de compras desde su perfil.</p>
    </div>
  </div>
  </div>
  )
}

export default AcceptPago