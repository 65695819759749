const ModalInfoCliente = ({ onClick, data }) => {

  const { nombre_cliente, email_cliente, usuarios, telefono_cliente } = data;

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>DATOS DEL CLIENTE</h1>
              <span class="fs-6 text-muted">
                Los datos del cliente estan disponibles para obtener una
                comunicación directa.{" "}
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
              {
                usuarios
                ?   <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Nombre <span className="fw-normal">{usuarios.nombre}</span></div></li>
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Email <span className="fw-normal">{usuarios.email}</span></div></li>
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Telefono <span className="fw-normal">{usuarios.telefono}</span> <a title="Whatsapp" target="_blank" href={"https://wa.me/54"+usuarios.telefono}><i class="bi bi-whatsapp text-success ms-2"></i></a></div>  </li>
              </ul>
                :  <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Nombre <span className="fw-normal">{nombre_cliente}</span></div></li>
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Email <span className="fw-normal">{email_cliente}</span></div></li>
                <li class="list-group-item border-0 d-flex justify-content-between"><div className="fw-bold text-muted">Telefono <span className="fw-normal">{telefono_cliente}</span></div></li>
              </ul>
              }
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <button
                class="btn btn-secondary rounded-pill px-4"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInfoCliente;
