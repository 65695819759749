import { useEffect } from "react";
import Membresias from "../../page/membresias/membresias";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { contextProvider } from "../../../context/ContextProvider";
import { fetchMembresias, membresiaState } from "../../models/membresias/Index";
import { useState } from "react";
import MembresiaUsersModal from "../../page/membresias/modals/MembresiaUsersModal";
import MembresiaModal from "../../page/membresias/modals/MembresiaModal";
import RestApi from "../../../helpers/RestApi";
import Swal from "sweetalert2";
import ModalReporte from "../../../../components/Dashboard/Layout/membresias/modals/ModalReporte";
import MembresiaUsos from "../../page/membresias/modals/MembresiaUsos";
import Alerts from "../../components/Alerts/Alerts";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { format } from "date-fns";
import clienteAntesala from "../../../config/Config";

const MembresiasController = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { URL_MEMBRESIA } = URLs;
  const { FORM_MEMBRESIA } = FORMs;
  const { VALIDATION_FORM_MEMBRESIA } = VALIDATIONs;

  const _membresias = useSelector(membresiaState);
  const membresias = _membresias.membresias;

  const [modalReporte, setModalReporte] = useState(false);
  const [membresia, setMembresia] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalMembresia, setModalMembresia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    status: 1,
    search: "",
    year: "",
  });

  const fetchUsuarios = async (params) => {
    const response = await clienteAntesala
      .get("/usuarios", { params })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          return error.message;
        }
      });

    return response;
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "añadir-membresia":
        {
          let formData = new FormData();
          formData.append("users[0]", value.email);
          formData.append("membresia_id", value.id);

          const response = await RestApi({
            url: "/membresias/users",
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            return Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });
          } else {
            if (typeof response.data.msg === "string") {
              Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "200",
                type: "success",
              });
            }

            Object.keys(response.data.msg).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data.msg[key][0],
                code: "401",
                type: "error",
              });
            });
          }
        }
        break;
      case "quitar-membresia":
        {
          let formData = new FormData();
          formData.append("users[0]", value.email);
          formData.append("membresia_id", value.id);

          const response = await RestApi({
            url: "/membresias/users/delete",
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            return Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });
          } else {
            if (typeof response.data.msg === "string") {
              Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "200",
                type: "success",
              });
            }

            Object.keys(response.data.msg).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data.msg[key][0],
                code: "401",
                type: "error",
              });
            });
          }
        }
        break;
      case "duplicar":
        {
          async function double() {
            let formData = new FormData();
            formData.append("id", value.id);
            formData.append("name", value.name + " Duplicado");
            formData.append("description", value.description);
            formData.append("limit", value.limit);
            formData.append("limit_cash", value.limit_cash);
            formData.append("fecha_limite", value.fecha_limite);
            formData.append("descont", value.descont);
            formData.append("region", value.provincia);
            formData.append("terminos", value.terminos);
            formData.append("status_id", value.status_id);

            const response = await RestApi({
              url: URL_MEMBRESIA,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            if ((response.status == 200) | (response.status == 201)) {
              let parametros = {
                url: URL_MEMBRESIA,
                status: params.status,
              };
              return dispatch(fetchMembresias(parametros));
            }
          }

          Swal.fire({
            title: "¿Desea duplicar la membresia?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d0552a",
            cancelButtonColor: "#232323",
            confirmButtonText: "SI",
            cancelButtonText: "NO",
          }).then((result) => {
            if (result.isConfirmed) {
              double();
            }
          });
        }
        break;
      case "ciclo":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("id", value.id);
          formData.append("cycle", value.ciclo);

          const response = await RestApi({
            url: URL_MEMBRESIA,
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          if ((response.status == 200) | (response.status == 201)) {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });

            let parametros = {
              url: URL_MEMBRESIA,
              status: params.status,
            };
            return dispatch(fetchMembresias(parametros));
          }
        }
        break;
      case "year":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("id", value.id);
          formData.append("year", value.year);

          const response = await RestApi({
            url: URL_MEMBRESIA,
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          if ((response.status == 200) | (response.status == 201)) {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });

            let parametros = {
              url: URL_MEMBRESIA,
              status: params.status,
            };
            return dispatch(fetchMembresias(parametros));
          }
        }
        break;
      case "status":
        {
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("id", value.id);
          formData.append("status_id", value.status);

          const response = await RestApi({
            url: URL_MEMBRESIA,
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          if ((response.status == 200) | (response.status == 201)) {
            let parametros = {
              url: URL_MEMBRESIA,
              status: params.status,
            };
            return dispatch(fetchMembresias(parametros));
          }
        }
        break;
      case "reportes":
        {
          setMembresia(value);
          return setModalReporte(true);
        }
        break;
      case "usuarios":
        {
          let content = `<table id="usuarios-activos" ><thead><tr><th>mails</th></tr></thead>'><tbody>`;
          let params = {
            membership_code: value.code,
          };
          const response = await fetchUsuarios(params);
          console.log(response.data);
          if (response.status == 200) {
            let td = "";
            response.data.map((u, index) => {
              td = td + "<tr><td>" + u.email + "</td></tr>";
            });

            document.getElementById("content-usuarios-activos").innerHTML =
              content + td + "</tbody></table>";
            setTimeout(document.getElementById('btnDownloadExcelUsuariosActivos').click(), 2000)
          }
        }
        break;
      case "modal":
        {
          setMembresia(null);
          return setModalMembresia(true);
        }
        break;
      case "editar":
        {
          setMembresia(value);
          return setModalMembresia(true);
        }
        break;
      case "membresia_users":
        {
          return setModal(true);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "filtro-status":
        {
          setParams({
            ...params,
            status: e.target.value,
          });

          let parametros = {
            url: URL_MEMBRESIA,
            status: e.target.value,
          };
          dispatch(fetchMembresias(parametros));
        }
        break;
    }
  };

  useEffect(() => {
    if (!membresias) {
      let parametros = {
        url: URL_MEMBRESIA,
        status: params.status,
      };
      dispatch(fetchMembresias(parametros));
    }
  }, []);

  return (
    <>
      <label className="d-none">
        <div id="content-usuarios-activos"></div>
        <ReactHTMLTableToExcel
          id="btnDownloadExcelUsuariosActivos"
          className="text-dark btn btn-outline-success rounded-pill w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
          table="usuarios-activos"
          filename={"usuarios-activos-" + new Date().getFullYear()}
          sheet="pagina 1"
          buttonText="Exportar tabla actual"
        />
      </label>

      <Membresias
        data={membresias}
        onClick={onClick}
        onChange={onChange}
        params={params}
        setParams={setParams}
        dispatch={dispatch}
      />

      {modal ? (
        <MembresiaUsersModal
          setModal={setModal}
          membresias={membresias?.data.memberships}
        />
      ) : (
        ""
      )}

      {modalMembresia ? (
        <MembresiaModal setModal={setModalMembresia} data={membresia} />
      ) : (
        ""
      )}

      {modalReporte ? (
        <MembresiaUsos setModal={setModalReporte} membership={membresia} />
      ) : (
        ""
      )}
    </>
  );
};

export default MembresiasController;
