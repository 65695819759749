import { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import AlertWarning from "../../../Alerts/AlertWarning";
import { CheckoutFree } from "../../../helpers/PagosHelper";
//
import useApi from "../../../hooks/useApi";
import Evento from "./Evento";
//Date format
import { format, add, sub } from "date-fns";

const VistaEvento = () => {
  const [steps, setSteps] = useState(0);

  const { id } = useParams();

  const [params, setParams] = useState({
    id: id,
    search: "",
    sala: "",
    status: "",
    date_range: [],
    provincia: "",
    //static
    url: "api/Eventos/All",
    pagination: 50,
  });

  //Hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);

  //FORM

  const [form, setForm] = useState({
    nombre: "",
    email: "",
  });


  //STATE HELPER

  /*

  const dias_semana = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const [selectFechas, setFechas] = useState({});
  const [cantidad, setCantidad] = useState({});
  const [filterMonth, setMonth] = useState(["all"]);

  const hoy = new Date();
  const fecha =
    dias_semana[hoy.getDay()] +
    ", " +
    hoy.getDate() +
    " de " +
    meses[hoy.getMonth()] +
    " de " +
    hoy.getFullYear();
  const hour = hoy.getHours();
  const minute = hoy.getMinutes();

  //ARROW FUNCTION HELPER


  const finalizarEntradas = (fF, hF, hE, mE) => {
    let horaF = hF.slice(0, 2);
    let minuteF = hF.slice(3, 5);


    if (parseInt(hE) == 23) {
        if (hour == 23) {
          if (minute > 59) {
            return <option>La venta ha finalizado</option>;
          } else {
            return <option>{fF + " HORA: " + hF}</option>;
          }
        }
    }
    
    if (parseInt(hE) == 1) {
      if(hour > (parseInt(horaF)-1)){
        return <option>La venta ha finalizado</option>;
      } else {
        if (hour == (parseInt(horaF)-1)) {
          if (minute > parseInt(minuteF)) {
            return <option>La venta ha finalizado</option>;
          } else {
            return <option>{fF + " HORA: " + hF}</option>;
          }
        }
      }
    }

    if (parseInt(hE) == 0) {
      if(hour > parseInt(horaF)){
        return <option>La venta ha finalizado</option>;
      } else {
        if (hour == parseInt(horaF)) {
          if (minute > parseInt(minuteF)) {
            return <option>La venta ha finalizado</option>;
          } else {
            return <option>{fF + " HORA: " + hF}</option>;
          }
        }
      }
      
    }
    
    return <option>{fF + " HORA: " + hF}</option>;
  };

  const filtrarFechas = (fechas) => {
    let fechasFiltradas = [];
    let fechaFormat;
    let horaFormat;

    let filtro = new Date();
    let month = filtro.getMonth() + 1;
    let day = filtro.getDate();
    let hora = filtro.getHours();
    let minutos = filtro.getMinutes();

    fechas.fechas.map((item) => {
      fechaFormat = item.split(" ");
      horaFormat = fechas.hora.split(":");

      let numberMonth;

      switch (fechaFormat[3]) {
        case "enero":
          numberMonth = 1;
          break;
        case "febrero":
          numberMonth = 2;
          break;
        case "marzo":
          numberMonth = 3;
          break;
        case "abril":
          numberMonth = 4;
          break;
        case "mayo":
          numberMonth = 5;
          break;
        case "junio":
          numberMonth = 6;
          break;
        case "julio":
          numberMonth = 7;
          break;
        case "agosto":
          numberMonth = 8;
          break;
        case "septiembre":
          numberMonth = 9;
          break;
        case "octubre":
          numberMonth = 10;
          break;
        case "noviembre":
          numberMonth = 11;
          break;
        case "diciembre":
          numberMonth = 12;
          break;
      }

      //console.log(numberMonth);
      //console.log(month);

      if (numberMonth >= month) {
        if (numberMonth > month) {
          fechasFiltradas.push(item);
        } else {
          if (fechaFormat[1] > day) {
            fechasFiltradas.push(item);
          }
          if (fechaFormat[1] == day) {
            fechasFiltradas.push(item);
            /*
            if (hora < horaFormat[0] - 1) {
              fechasFiltradas.push(item);
            }
            if (hora == horaFormat[0] - 1) {
              if (minutos < horaFormat[1]) {
                fechasFiltradas.push(item);
              }
            }
          }
        }
      } else {
        //fechasFiltradas.push(item);
      }
    });

    filterMonth.map((day) => {
      if (day !== "all") {
        fechasFiltradas = fechasFiltradas.filter((date) => date.includes(day));
      }
    });

    let newObjFechas = {
      hora: fechas.hora,
      fechas: fechasFiltradas,
      stock: fechas.stock,
    };

    return newObjFechas;
  };

  const filtrarEntradas = (entradas, fechas) => {
    let habilitacion = {
      fecha: "",
      dia: "",
      mes: "",
    };

    let myEntradas = [];

    let filtro = new Date();
    let month = filtro.getMonth() + 1;
    let day = filtro.getDate();

    entradas.map((item) => {
      habilitacion.fecha = new Date(item.habilitacion);
      habilitacion.dia = habilitacion.fecha.getDate() + 1;
      habilitacion.mes = habilitacion.fecha.getMonth() + 1;

      let hora = "";
      let minuto = "";

      if (item.habilitacion_hora) {
        hora = item.habilitacion_hora.split(":");
        minuto = hora[1];
        hora = hora[0];
      }

      if (habilitacion.mes > month) {
        item.status = "ESPERA";
        myEntradas.push(item);
      } else {
        if (habilitacion.mes == month) {
          if (habilitacion.dia > day) {
            item.status = "ESPERA";
            myEntradas.push(item);
          } else {
            item.hora = hora;
            item.minuto = minuto;
            item.status = "ACTIVADO";
            myEntradas.push(item);
          }
        } else {
          item.hora = hora;
          item.minuto = minuto;
          item.status = "ACTIVADO";
          myEntradas.push(item);
        }
      }
    });

    //console.log(myEntradas);

    return myEntradas;
  };

  const concatHabilitacion = (habilitacion) => {
    let dia = new Date(habilitacion);
    dia.setMinutes(dia.getMinutes() + dia.getTimezoneOffset());

    let fechaFormat =
      dias_semana[dia.getDay()] +
      ", " +
      dia.getDate() +
      " de " +
      meses[dia.getMonth()] +
      " de " +
      dia.getUTCFullYear();

    return fechaFormat;
  };

  const sumarTotal = (entradas) => {
    let suma = 0;

    entradas.map((item, index) => {
      if (cantidad[index] > 0) {
        return (suma = parseInt(item.precio) * cantidad[index] + suma);
      }
      return "";
    });

    if (Object.keys(cantidad).length == 0) {
      return "";
    }

    return (
      <div className="text-end border-top">
        <div className="d-flex justify-content-between my-3">
          {" "}
          <div>SUBTOTAL </div> <div>ARS {suma}</div>{" "}
        </div>
        <div className="d-flex justify-content-between my-3">
          <div>+ARS SERV ({suma > 500 ? "10%" : suma == 0 ? "0" : "50"})</div>{" "}
          <div>ARS {suma > 500 ? (suma / 100) * 10 : suma == 0 ? 0 : 50}</div>
        </div>
        <div className="d-flex justify-content-between border-top py-3">
          <div>TOTAL</div>{" "}
          <div>
            ARS{" "}
            {suma > 500
              ? suma + (suma / 100) * 10
              : suma == 0
              ? suma
              : suma + 50}
          </div>
        </div>
      </div>
    );
  };

  const reportSale = (index, nombre) => {
    let suma = 0;
    if (reportes) {
      reportes.map((item) => {
        if (item.fecha_entrada == selectFechas[index]) {
          suma = item.cantidad + suma;
        }
      });
    }
    return suma;
  };

  const stock = (index, extraFecha) => {
    let suma = 0;

    extraFecha.map((fecha) => {
      let string = fecha.fechas + " HORA: " + fecha.hora;
      if (selectFechas[index] == string) {
        suma = fecha.stock - reportSale(index);
      }
    });

    return suma;
  };

  const lengthFechas = (index) => {
    let array = [];

    Object.keys(selectFechas).map((key) => {
      if (selectFechas[key] == selectFechas[index]) {
        array.push("1");
      }
    });

    //console.log(array);

    return array.length;
  };

  //BUTTON EVENTO

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close_modal":
        {
          setModalEntrada(false);
        }
        break;
      case "confirmarPedido":
        {
          if (form.nombre.trim() == "" || form.email.trim() == "") {
            return AlertWarning(
              "Todos los campos son obligatorios",
              "Advertencia"
            );
          }

          let arrayEntradas = [];
          value.entradas.map((item) => {
            let objItem = {
              id: item.id,
              cantidad: item.cantidad,
              fecha: item.fecha,
            };
            arrayEntradas.push(objItem);
          });

          let formData = {
            nombre: form.nombre,
            email: form.email,
            entradas: JSON.stringify(arrayEntradas),
          };

          let response = await CheckoutFree(formData, setLoading);
          setModalEntrada(false);
        }
        break;
    }
  };

  const onChange = (e, index) => {
    switch (e.target.name) {
      case "filtro_mes":
        {
          e.preventDefault();

          setMonth([e.target.value]);
        }
        break;
      case "select_fechas":
        {
          setFechas({ ...selectFechas, [index]: e.target.value });
          //setCantidad({});
        }
        break;
      case "sumar_cantidad":
        {
          e.preventDefault();

          let contador = cantidad[index] ? parseInt(cantidad[index]) : 0;

          if (
            contador <
            stock(index, extraFecha) / Object.keys(selectFechas).length
          ) {
            setCantidad({ ...cantidad, [index]: contador + 1 });
          }
        }
        break;
      case "restar_cantidad":
        {
          e.preventDefault();

          let contador = cantidad[index] ? parseInt(cantidad[index]) : 0;

          if (contador > 0) {
            setCantidad({ ...cantidad, [index]: contador - 1 });
          }
        }
        break;

      case "nombre":
      case "email": {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    }

    //setCantidad({ ...cantidad, [index]: e.target.value })
  };

  const onSave = (e, entradas, evento) => {
    let arrayFechas = [];
    let arrayCantidad = [];

    //VALIDATION

    Object.keys(cantidad).map((key) => {
      if (cantidad[key] == 0) {
        delete cantidad[key];
      } else {
        arrayCantidad.push(cantidad[key]);
      }
    });

    if (arrayCantidad.length == 0) {
      return AlertWarning("Seleccione más de 0 entradas", "Advertencia");
    }

    Object.keys(selectFechas).map((key) => {
      arrayFechas.push(selectFechas[key]);
    });

    if (arrayFechas.length == 0) {
      return AlertWarning("Selecciona al menos una fecha", "Advertencia");
    }

    let arrayEntradas = [];

    Object.keys(cantidad).map((key) => {
      entradas[key].cantidad = cantidad[key];
      entradas[key].fecha = selectFechas[key];
      arrayEntradas.push(entradas[key]);
    });

    let freeTicket = false;
    arrayEntradas.map((entrada) => {
      if (parseInt(entrada.precio) > 0) {
        arrayEntradas.map((item) => {
          if (parseInt(item.precio) == 0) {
            freeTicket = true;
          }
        });
      }
    });

    if (freeTicket) {
      return AlertWarning(
        "Debes seleccionar las entradas gratis por si solas",
        "Advertencia"
      );
    }

    let entrada = {
      cupones: evento.cupones,
      entradas: arrayEntradas,
      evento: {
        nombre: evento.nombre,
        imagen: evento.imagen_evento,
        id: evento.id,
      },
    };

    //FREE OPTION

    arrayEntradas.map((item) => {
      if (parseInt(item.precio) == 0) {
        setElement(entrada);
        setModalEntrada(true);
      } else {
        entrada = JSON.stringify(entrada);
        let encodeData = btoa(unescape(encodeURIComponent(entrada)));
        encodeData = encodeData.replace('/','-');
        return (window.location.href = "/mediodepago/" + encodeData + "/" + arrayEntradas.length);
      }
    });

    //let decodeData = atob(encodeData);
    //console.log(encodeData);
    //console.log( JSON.parse(decodeData));

    //return window.location.href = ('/medioPago/'+JSON.stringify(arrayEntradas)+"/"+evento.nombre+evento.imagen_evento);
  };

  let evento;
  let letCategorias;
  let fechas;
  let fechasGenerales;
  let fecha_start;
  let fecha_end;
  let ficha_tecnica;
  let entradas;
  let statusFechas = true;
  let extraFecha = [];
  let website;
  let link;

  if (dataEvento) {
    evento = dataEvento;
    letCategorias = evento.categorias;

    website = !evento.website_sitio ? "" : evento.website_sitio.split("/");

    fechas = JSON.parse(evento.fechas);

    fechas.map((item) => {
      let array = [];

      if (!Array.isArray(item.fechas)) {
        Object.keys(item.fechas).map((key) => {
          array.push(item.fechas[key]);
        });
        item.fechas = array;
      }
    });

    fechasGenerales = filtrarFechas(fechas[0]);

    fecha_start = fechas[0].fechas[0].split(" ");
    fecha_end = fechas[fechas.length - 1].fechas[0].split(" ");

    fechas.map((item) => {
      let result = filtrarFechas(item);
      extraFecha.push(result);
    });

    entradas = filtrarEntradas(evento.entradas, extraFecha);

    ficha_tecnica = JSON.parse(evento.ficha_tecnica);
    link = JSON.parse(evento.link);
  }

  */
  return (
    <>
    {
      data
      ? <Evento 
      data={data}
      />
      : <div></div>
    }
    </>
  );
};

export default VistaEvento;
