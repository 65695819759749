import { useContext, useEffect, useState } from "react";
import { authContext } from "../../../../context/auth/AuthContext";
import Boleteria from "../../page/boleteria/Boleteria"
import { useParams } from "react-router-dom";
import RestApi, { GetApi } from "../../../helpers/RestApi";
import { ActualizarReporte } from "../../../../helpers/ReporteHelper";
import clienteAntesala from "../../../config/Config";
import axios, { Axios } from "axios";
import AlertSuccess from "../../../../Alerts/AlertSucces";

const BoleteriaController = () => {
  const { user } = useContext(authContext);
  const { rol, status, auth } = user;

  const { id, shared_token } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });
  const [param, setParam] = useState({
    id_evento: id,
    search: "",
    sala: "",
    fecha_entrada: "",
    date_range: [],
    provincia: "",
    //static
    url: "/reportes",
    pagination: "50",
    cupon: "",
    estado: "ACTIVO",
  });
  const [evento, setEvento] = useState(null);
  const [reportes, setReportes] = useState(null);
  const [campos, setCampos] = useState({
    fecha_compra: null,
    dni_cliente: null,
    telefono_cliente: null,
    email_cliente: null,
    tipo: null,
    multiplicador: null,
    monto: null,
    total: null,
    descuento: null,
    total_descuento: null,
    codigo_descuento: null,

    state: false,
  });

  const onClick = async (e) => {
    switch (e.target.name) {
      case "compartir": {
        console.log(user);
        let params = {
          url: "/reportes/share",
          share: user.data.id
        }
        const response = await clienteAntesala
          .get(params.url, { params })
          .then((result) => {
            setLoading(false)
            return result.data;
          })
          .catch((error) => {
            if (error.response) {
              return error.response.data;
            } else if (error.request) {
              return error.request.data;
            } else {
              return error.message;
            }
          });

        let url = "https://antesala.com.ar/dashboard/boleteria/"+evento.id+"/" + response;
        let aux = document.createElement("input");
        aux.setAttribute("value", url);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        return AlertSuccess("Link Copiado en el portapapeles!")
      } break;
      case "ver_mas":
        {
          if (campos.state) {
            setCampos({
              fecha_compra: null,
              dni_cliente: null,
              telefono_cliente: null,
              email_cliente: null,
              tipo: null,
              multiplicador: null,
              monto: null,
              total: null,
              descuento: null,
              total_descuento: null,
              codigo_descuento: null,

              state: false,
            });
          } else {
            setCampos({
              fecha_compra: true,
              dni_cliente: true,
              telefono_cliente: true,
              email_cliente: true,
              tipo: true,
              multiplicador: true,
              monto: true,
              total: true,
              descuento: true,
              total_descuento: true,
              codigo_descuento: true,
              state: true
            });
          }
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "registro":
        {
          let dataForm = new FormData();
          let state = "";

          dataForm.append("id", String(value));

          if (e.target.checked) {
            state = "CONCURRIO";
          } else {
            state = "AUSENTE";
          }

          dataForm.append("registro", state);
          let response = await ActualizarReporte(dataForm, setLoading);

          fetchReportes(param)
        }
        break;
      //Filtro params
      case "nombre_cliente":
        {
          let params = param
          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              search: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParam({
                ...param,
                search: "",
              });

              params = { ...params, search: "" }
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              search: e.target.value,
            });
            if (e.target.value.length > 3) {
              setParam({
                ...param,
                search: e.target.value,
              });
              params = { ...params, search: e.target.value }
            }
          }


          fetchReportes(params)
        }
        break;
      case "fecha_entrada":
        {
          let params = {
            ...param,
            [e.target.name]: e.target.value,
          }
          setParam({
            ...param,
            [e.target.name]: e.target.value,
          });
          fetchReportes(params)
        }
        break;
      case "status":
        {
          setParam({
            ...param,
            estado: e.target.value,
          });
          let params = {
            ...param,
            [e.target.name]: e.target.value,
          }
          fetchReportes(params)
        }
        break;
    }
  };

  const fetchEvento = async (params) => {
    setLoading(true)
    console.log(shared_token);
    let token = shared_token ? shared_token : localStorage.getItem('token');
    let headers = { Authorization: `Bearer ${token}`, common: { 'Content-Type': "application/json" } }

    const response = await clienteAntesala
      .get(params.url, { params }, { headers })
      .then((result) => {
        setLoading(false)
        return result.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request.data;
        } else {
          return error.message;
        }
      });

    console.log(response)
    setEvento(response)
  }

  const fetchReportes = async (params) => {
    setLoading(true)
    console.log(params);
    if (!shared_token) {
      const response = await clienteAntesala
        .get(params.url, { params: params })
        .then((result) => {
          setLoading(false)
          return result.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data;
          } else if (error.request) {
            return error.request.data;
          } else {
            return error.message;
          }
        });
      setReportes(response)
    } else {
      let param = {
        ...params,
        url: "/share",
        token: shared_token
      }
      const response = await clienteAntesala
        .get("/share", { params: param })
        .then((result) => {
          setLoading(false)
          return result.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data;
          } else if (error.request) {
            return error.request.data;
          } else {
            return error.message;
          }
        });
      setReportes(response)
    }

  }
  //##Evento
  useEffect(() => {
    let params = {
      id: id,
      search: "",
      sala: "",
      fecha_entrada: "",
      date_range: [],
      provincia: "",
      //static
      url: "/Eventos/All",
      pagination: "50",
      cupon: "",
      estado: "ACTIVO",
    }
    fetchEvento(params)
  }, [])

  useEffect(() => {
    let params = {
      id_evento: id,
      search: "",
      sala: "",
      fecha_entrada: "",
      date_range: [],
      provincia: "",
      //static
      url: "/reportes",
      pagination: "50",
      cupon: "",
      estado: "ACTIVO",
    }
    if(!reportes){
      fetchReportes(params)
    }
  }, [])
  return (
    <Boleteria
      evento={evento}
      reportes={reportes}
      rol={rol}
      campos={campos}
      onClick={onClick}
      onChange={onChange}
    />
  )
}

export default BoleteriaController