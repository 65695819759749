import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionStateContext } from "../../../context/EventosState";

const Nota = () => {
  const context = useContext(CollectionStateContext);
  const { notas, state } = context;

  const { id } = useParams()

  //QUERY STATES


  const [dataNota, setNota] = useState(null);


  const concatDate = (fecha, select) => {
    if (!fecha) {
      return "Sin fecha asignada"
    }

    let result;

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];


    switch (select) {
      case "created_at": {
        let fh = new Date(fecha);

        result = dias[fh.getDay()] + " " + fh.getDate() + " de " + (mes[fh.getMonth()]) + " de " + fh.getFullYear()
      }
        break;
      case "funciones": {
        if (!fecha) {
          return ""
        }
        let fechas = JSON.parse(fecha);
        let fh_start = fechas[0].fechas[0].split(" ");
        let fh_end = fechas[fechas.length - 1].fechas[fechas[fechas.length - 1].fechas.length - 1].split(" ");

        result = "Desde el " + fh_start[0] + " " + fh_start[1] + " de " + fh_start[3] + " hasta el " + fh_end[0] + " " + fh_end[1] + " de " + fh_end[3]
      }
        break;
    }

    return result;
  }


  //FILTER

  const [filter_notas, setFilterNotas] = useState({
    id: id ? id : "",
    search: "",
    categoria: "",
    date_range: "",
    provincia: "",
    order: "",
    max: "false",
    url: "api/Notas",
  });

  useEffect(() => {
    notas(setNota, filter_notas);
  }, [filter_notas])

  let style_image = {
    backgroundImage: "url(" + 'https://antesala.com.ar/assets/img/notas/' + dataNota?.imagen + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: "600px"
  }
  return (
    <div>
      <section class="">
        <div className="w-100 d-flex justify-content-center align-items-end mb-5" style={style_image}>
          <div className="bg-white border-0 p-5 w-auto fw-bold fs-3 text-center" style={{
            borderRadius: "5px",
            position: "relative",
            bottom: "-50px",
          }}>
            {dataNota?.titulo}
            <p className="fs-6 mt-5" style={{ color: "rgba(0,0,0,0.4)" }}>Por {dataNota?.autor}</p>
          </div>
        </div>
      </section>
      <section className="container-md" style={{marginBottom: "150px"}}>
        <div className="fw-bold" style={{color: "#d0552a"}}>_</div>
        <div className="d-flex justify-content-start mb-4">
          {concatDate(dataNota?.created_at, "created_at")}
        </div>
        <div className="bg-white d-flex justify-content-center mx-auto">
          <div dangerouslySetInnerHTML={{ __html: dataNota?.contenido }}></div>
        </div>
      </section>

      {
        dataNota
          ? <section id="noticias" class="mt-100 pt-100 mb-5 d-none">
            <div class="container">
              <div class="row mb-5">
                <div class="col-md-7 d-flex flex-column justify-content-center">
                  <p>{concatDate(dataNota.created_at, "created_at")}</p>
                  <hr class="hr-black" />
                  <h2 class="fc-amarillo">"{dataNota.titulo}"</h2>
                  <p class="Grifter">Por {dataNota.autor}</p>
                  <strong>{dataNota.descripcion}</strong>
                </div>
                <div class="col-md-5 text-md-end mt-4 mt-md-0">
                  <img src={"https://antesala.com.ar/assets/img/notas/" + dataNota.imagen} width="80%" alt="" />
                </div>
              </div>
            </div>
          </section>
          : ""
      }

      {
        dataNota
          ?
          <section class="bg-gris-claro pt-100 pb-100 d-none">
            <div class="container">
              <div class="row">
                <div class="col-md-12" style={{ columnCount: "2" }}>
                  <div dangerouslySetInnerHTML={{ __html: dataNota.contenido }}></div>
                </div>
              </div>
            </div>
          </section>
          : ""
      }

    </div>
  )
}

export default Nota