import Swal from "sweetalert2"

const AlertWarning = (message, title) => {
  return Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: false,
  })
}

export default AlertWarning