const Input = ({label, value, defaultValue, name, type, onChange}) => {
  return (
    <div class="mb-3">
      <label class="form-label">
        {label}
      </label>
      <input
        defaultValue={defaultValue}
        type={type}
        class="form-control border-custom-naranja"

        name={name}
        onChange={(event) => onChange(event)}
      />
    </div>
  );
};

export default Input;
