import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { facebookAuth, googleAuth, iniciarSession } from "../../../helpers/AccessHelper";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from 'react-google-login';
import Swal from "sweetalert2";

const AccesLogin = () => {

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    msg: "",
    class: "d-none",
  });

  
const history = useNavigate(); 

  const [pass, setPass] = useState({
    icon: "bi bi-eye-slash",
    type: "password",
    status: false,
  });

  const showPassword = (e) => {
    e.preventDefault();

    if (pass.status === false) {
      setPass({
        ...pass,
        icon: "bi bi-eye",
        type: "text",
        status: true,
      });
    } else {
      setPass({
        ...pass,
        icon: "bi bi-eye-slash",
        type: "password",
        status: false,
      });
    }
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setAlert({
      msg: "",
      class: "d-none",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (form.email.trim() == "" || form.password.trim() == "") {
      setAlert({
        msg: "Todos los campos son obligatorios",
        class: "w-100 alert alert-danger text-start my-1",
      });
    } else {
      let dataForm = new FormData();
      dataForm.append("email", form.email);
      dataForm.append("password", form.password);

      
      iniciarSession(dataForm, setLoading, history);
    }
  };

  const responseFacebook = (response) => {
    let dataForm = new FormData;
    
    dataForm.append('nombre', response.name);
    dataForm.append('email', response.email);
    dataForm.append('facebook_user_id', response.id);
    
    let result; 

    const axios = async () => {
      result = await facebookAuth(dataForm);

      if(result.status == "DOBLE VALIDACION"){
        Swal.fire({
          title: 'Escribe tu contraseña para validar tu identidad',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          customClass: {
            input: '',
            confirmButton: 'btn btn-custom-amarillo w-100',
            cancelButton: 'btn btn-outline-secondary'
          },
          buttonsStyling: false,
          preConfirm: (password) => {
            dataForm.append('password',password);
            facebookAuth(dataForm);
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              text: "Iniciando sesión",
              timer: "1500",
              icon: 'success',
              position: "bottom",
              showConfirmButton: false,
              customClass: {
                container: "add-to-cart-alert-container",
                popup: "add-to-cart-alert",
              }
            })
          }
        })
      }


    }
    axios();
    
  };

  const responseGoogle = (response) => {
    //console.log(response);

    
    let dataForm = new FormData;

    dataForm.append('email', response.profileObj.email);
    dataForm.append('google_user_id', response.googleId);
    dataForm.append('nombre', response.givenName);
    dataForm.append('apellido', response.familyName);
    
    let result; 
    
    const axios = async () => {
      result = await googleAuth(dataForm);

      if(result.status == "DOBLE VALIDACION"){
        Swal.fire({
          title: 'Escribe tu contraseña para validar tu identidad',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          customClass: {
            input: '',
            confirmButton: 'btn btn-custom-amarillo w-100',
            cancelButton: 'btn btn-outline-secondary'
          },
          buttonsStyling: false,
          preConfirm: (password) => {
            dataForm.append('password',password);
            googleAuth(dataForm);
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              text: "Iniciando sesión",
              timer: "1500",
              icon: 'success',
              position: "bottom",
              showConfirmButton: false,
              customClass: {
                container: "add-to-cart-alert-container",
                popup: "add-to-cart-alert",
              }
            })
          }
        })
      }

    }
    axios();

  }

  return (
    <div className="container-md texto-normal d-flex justify-content-center">
      <div focus={true} name="upHTML" className="d-flex justify-content-center mx-auto pb-4">
        <form action="" className="p-0 p-md-4">
          <div className="text-center mb-3 mx-auto">
            <h1>
              <p>Ingresar a</p>
              <p>Antesala</p>
            </h1>

            <div className="w-100 text-center mb-3">
          <Link
              className="text-decoration-none"
              aria-label="Ingresar"
              to="/registro"
            >
              <span className="fw-light">¿No tienes una cuenta?</span>
              <span className="fw-bold fs-5"> Registrarse </span>
            </Link>
          </div>
          </div>

          <div className="mb-3">
            <label for="inputEmail" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control rounded-pill"
              id="inputEmail"
              placeholder="Ingrese su Email"
              name="email"
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="mb-3">
            <label for="inputPassoword" className="form-label">
              Contraseña
            </label>
            <div class="input-group mb-3">
              <button
                className="input-group-text  border-0 rounded-end rounded-pill px-3"
                id="basic-addon1"
                onClick={(event) => showPassword(event)}
              >
                <i className={pass.icon}></i>
              </button>
              <input
                type={pass.type}
                class="form-control rounded-start rounded-pill"
                placeholder="Ingrese su contraseña"
                aria-label="Password"
                aria-describedby="basic-addon1"
                name="password"
                onChange={(event) => onChange(event)}
              />
            </div>
          </div>

         <button
            className="btn btn-custom-amarillo w-100 mb-3 mx-1 "
            onClick={onSubmit}
            disabled={loading? true : false}
          > 
            {
              loading?
              <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
              :
              ""
            }
            Iniciar Sesion
          </button>

          
          
          
          <ul className="list-group mb-1">
            <li className="list-group-item border-0 text-muted p-0 m-0 text-center " style={{fontSize: "14px"}}>
              Ingresa con tu cuenta de Google o Facebook
            </li>
          </ul>


          <div className="row w-100 m-0 p-0 mb-3 d-none">
            <div className="col-md-6 col-12 m-0">
              <FacebookLogin
                autoLoad={false}
                appId="511205577158491"
                fields="name,email,picture"
                callback={responseFacebook}
                textButton=" Ingresar"
                icon="bi bi-facebook"
                cssClass="w-100 btn btn-primary text-white"
              />
            </div>
            <div className="col-md-6 col-12 m-0">
              <GoogleLogin
                clientId="187873083935-30e7ss9669ffhtqtev6jk7hajlhmmg8c.apps.googleusercontent.com"
                buttonText=" Ingreso"
                render={renderProps => (
                  <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-light w-100 text-center d-flex align-items-center justify-content-center">

                    <svg viewBox="0 0 24 24" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                  </g>
                </svg>  <span className="mx-1"> Ingresar</span></button>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>

          <div className="w-100 text-center">
            <Link className="text-decoration-none d-block" to="/TYP">
              <span className="text-dark">Al ingresar acepta nuestros</span>{" "}
              Terminos y Condiciones
            </Link>

            <Link className="text-decoration-none" to="/Restaurar">
              {" "}
              - Olvide mi contraseña{" "}
            </Link>
          </div>

          <div className={alert.class}>
            <p className="fw-bold">Atención.</p>
            <p>{alert.msg}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccesLogin;
