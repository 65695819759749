import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Que = ({
  onClick,
  onChange,
  setForm,
  form,
  categorias,
  evento,
  alert,
}) => {
  return (
    <div className="row">
      <div
        className={
          alert.datos
            ? form.imagen_evento == "" ||
              form.nombre.trim() == "" ||
              form.duracion.trim() == "" ||
              form.clase.trim() == "" ||
              form.grupo.trim() == "" ||
              form.categorias.length == 0
              ? "col-12  border border border-danger shadow-sm bg-white mb-3 p-2"
              : "col-12  border border-0 shadow-sm bg-white mb-3 p-2"
            : "col-12  border border-0 shadow-sm bg-white mb-3 p-2"
        }
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12 border-bottom d-flex justify-content-between">
            <div className="mb-3 fs-5 py-2 fw-bold ">
              Datos del evento
              <span className="fs-6 text-muted fw-normal d-block">
                Ingresa los datos del evento.
              </span>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex justify-content-center align-items-center">
            <label for="formImagenEvento" className="form-label">
              <div
                className="btn p-0 border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                style={{ height: "300px" }}
              >
                <img
                  src={
                    form.imagen_evento_url
                      ? form.imagen_evento_url
                      : evento
                      ? "/assets/img/eventos/" + evento.imagen_evento
                      : ""
                  }
                  className="img-fluid"
                  alt="IMAGEN OBLIGATORIA"
                />
                <div className="img-edit-pencil">
                  <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                  <span className="fs-7 d-block">
                    Formatos admitidos JPG PNG JPEG{" "}
                  </span>
                  <span className="fs-7">
                    Dimension sugerida 500x500 72DPI{" "}
                  </span>
                </div>
              </div>
            </label>
            <input
              className="form-control d-none"
              type="file"
              id="formImagenEvento"
              name="imagen_evento"
              onChange={(event) => onChange(event)}
            />
          </div>
          <div className="col-md-6 col-12 border-0">
            <ul className="list-group">
              <li className="list-group-item border-0 d-flex justify-content-between">
                <div className="mb-1 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Nombre de evento
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill w-100 border-custom-naranja"
                    id="exampleFormControlInput1"
                    placeholder="Ingresa el nombre de tu evento..."
                    defaultValue={evento ? evento.nombre : form.nombre}
                    name="nombre"
                    onChange={(event) => onChange(event)}
                  />
                  {alert.datos ? (
                    form.nombre.trim() == "" ? (
                      <span className="text-danger fs-7">
                        El nombre es obligatorio
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </li>
              <li className="list-group-item border-0 d-flex justify-content-between">
                <div className="mb-1 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Nombre de grupo(opcional)
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    placeholder="Ingresa el nombre de tu grupo..."
                    defaultValue={evento ? evento.nombre_grupo : form.nombre_grupo}
                    name="nombre_grupo"
                    onChange={(event) => onChange(event)}
                  />
                </div>
              </li>
              <li className="list-group-item border-0 d-flex justify-content-between">
                <div className="mb-1 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Duración del evento en minutos
                  </label>
                  <input
                    type="number"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    defaultValue={evento ? evento.duracion : form.duracion}
                    name="duracion"
                    onChange={(event) => onChange(event)}
                  />
                  {alert.datos ? (
                    form.duracion.trim() == "" ? (
                      <span className="text-danger fs-7">
                        La duración del evento es obligatorio
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </li>
              <li className="list-group-item border-0 d-flex justify-content-between">
                <div className="form-check form-switch fs-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    name="atp"
                    onChange={(event) => onChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    ATP(
                    {
                    form.atp.trim() == "NO"
                    ? evento
                    ? evento.atp
                    : form.atp
                    : form.atp
                    }
                    )
                  </label>
                </div>
              </li>
              <li className="list-group-item border-0 d-flex justify-content-between">
                <div className="mb-1 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Tipo de público
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="checkFlexAdultos"
                      name="grupo"
                      onFocus={(event) => onChange(event)}
                      value="ADULTOS"
                    />
                    <label
                      className={
                        form.grupo.trim() == ""
                          ? evento
                            ? evento.grupo == "ADULTOS"
                              ? "form-check-label text-custom-naranja"
                              : "form-check-label"
                            : ""
                          : form.grupo == "ADULTOS"
                          ? "form-check-label text-custom-naranja"
                          : "form-check-label" 
                      }
                      for="checkFlexAdultos"
                    >
                      ADULTOS
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="checkFlexInfancias"
                      name="grupo"
                      onFocus={(event) => onChange(event)}
                      value="INFANCIAS"
                    />
                    <label
                      className={
                        form.grupo.trim() == ""
                          ? evento
                            ? evento.grupo == "INFANCIAS"
                              ? "form-check-label text-custom-naranja"
                              : "form-check-label"
                            : ""
                          : form.grupo == "INFANCIAS"
                          ? "form-check-label text-custom-naranja"
                          : "form-check-label"
                      }
                      for="checkFlexInfancias"
                    >
                      INFANCIAS
                    </label>
                  </div>
                  {alert.datos ? (
                    form.grupo.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Seleccione un tipo de público
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-1 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Tipo de evento
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="checkFlexOnline"
                      name="clase"
                      onFocus={(event) => onChange(event)}
                      value="ONLINE"
                    />
                    <label
                      className={
                        form.grupo.trim() == ""
                          ? evento
                            ? evento.clase == "ONLINE"
                              ? "form-check-label text-custom-naranja"
                              : "form-check-label"
                            : ""
                          : form.grupo == "ONLINE"
                          ? "form-check-label text-custom-naranja"
                          : "form-check-label"
                      }
                      for="checkFlexOnline"
                    >
                      ONLINE
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="checkFlexPresencial"
                      name="clase"
                      onFocus={(event) => onChange(event)}
                      value="PRESENCIAL"
                    />
                    <label
                      className={
                        form.grupo.trim() == ""
                          ? evento
                            ? evento.clase == "PRESENCIAL"
                              ? "form-check-label text-custom-naranja"
                              : "form-check-label"
                            : ""
                          : form.grupo == "PRESENCIAL"
                          ? "form-check-label text-custom-naranja"
                          : "form-check-label"
                      }
                      for="checkFlexPresencial"
                    >
                      PRESENCIAL
                    </label>
                  </div>
                  {alert.datos ? (
                    form.clase.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Seleccione un tipo de evento
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="list-group m-0 p-0">
              <li className="list-group-item border-0 px-0">
                <span className="text-muted fs-6">
                  Selecciona tu categoria(Hasta 3 categorias)
                </span>
              </li>
              {categorias
                ? categorias.map((categoria) => (
                    <li className="list-group-item py-1 border-0 d-flex justify-content-between px-0">
                      <div className="form-check fs-6">

                        {
                          evento
                          ?<input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          value={categoria.nombre}
                          name="update_categorias"
                          onChange={(event) => onChange(event, categoria)}
                        />
                          :<input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          value={categoria.nombre}
                          name="categorias"
                          onChange={(event) => onChange(event)}
                        />
                        }
                        

                        <label
                          className={
                            evento
                              ? evento.categorias.filter(item => item.nombre == categoria.nombre).length > 0
                              ? "form-check-label text-custom-naranja"
                              : ""
                              : form.categorias.filter(item => item == categoria.nombre).length > 0
                              ? "form-check-label text-custom-naranja"
                              : ""
                          }
                        >
                          {categoria.nombre}
                        </label>
                      </div>
                    </li>
                  ))
                : ""}
              <li className="list-group-item border-0 px-0">
                {alert.datos ? (
                  form.categorias == 0 ? (
                    <span className="text-danger fs-7">
                      Seleccionar las categorias es un dato obligatorio
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className={
          alert.datos
            ? form.descripcion.trim() == ""
              ? "col-12  border border border-danger shadow-sm bg-white mb-3 pb-0 p-2"
              : "col-12  border border-0 shadow-sm bg-white mb-3 pb-0 p-2"
            : "col-12  border border-0 shadow-sm bg-white mb-3 pb-0 p-2"
        }
        style={{ borderRadius: "10px" }}
      >
        <div className="row pb-0 mb-0">
          <div className="col-md-12 col-12">
            <div className="mb-2 fs-5 py-2 fw-bold">
              Descripción de tu evento{" "}
              <span className="fs-6 text-muted fw-normal d-block">
                Describe tu evento o escribe una reseña sobre el, tambien puedes
                insertar videos de Youtube directamente con su URL en la
                descripción.
              </span>
            </div>
          </div>

          <div className="col-md-12 col-12 p-1 pb-0">
            <div className="" style={{ height: "auto" }}>
              <CKEditor
                editor={ClassicEditor}
                data={
                  form.descripcion
                    ? form.descripcion
                    : evento
                    ? evento.descripcion
                    : ""
                }
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log();
                }}
                onChange={(event, editor) => {
                  //const data = editor.getData();
                  setForm({
                    ...form,
                    descripcion: editor.getData(),
                  });
                }}
                onBlur={(event, editor) => {
                  console.log();
                  //console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  console.log();
                  //console.log( 'Focus.', editor );
                }}
              />
            </div>
          </div>

          <div className="col-md-12 my-4">
            <label htmlFor="" className="text-muted fs-6 mb-1">Insertar Trailer(opcional)</label>
            <input type="text" className="form-control border-custom-naranja" placeholder="Copia y pega el url de tu video de Youtube" defaultValue={evento? evento.url_youtube : form.url_youtube} name="url_youtube" onChange={(event) => onChange(event)}/>
          </div>

          <div className="col-md-12 d-flex justify-content-center mb-4">
              <iframe width="560" height="315" src={
                form.url_youtube.trim() == ""
                ? evento
                ? evento.url_youtube 
                : ""
                : form.url_youtube
                } title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div
        className={
          alert.ficha
            ? !form.ficha_tecnica
              ? "col-12  border border border-danger shadow-sm bg-white mb-3 pb-0 p-2"
              : "col-12  border border-0 shadow-sm bg-white mb-3 pb-0 p-2"
            : "col-12  border border-0 shadow-sm bg-white mb-3 pb-0 p-2"
        }
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="mb-3 fs-5 py-2 fw-bold border-bottom">
              Ficha técnica
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label fs-6 text-muted"
              >
                Ingresa el rol que tendra la persona
              </label>
              <input
                type="text"
                className="form-control border-custom-naranja rounded-pill"
                id="exampleFormControlInput1"
                placeholder="Técnico de luces..."
                name="ficha_key"
                onChange={(event) => onChange(event)}
              />
              {alert.ficha ? (
                form.ficha_key.trim() == "" ? (
                  <span className="text-danger fs-7">
                    El rol es obligatorio para agregar a la ficha técnica
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label fs-6 text-muted"
              >
                Ingresa su nombre
              </label>
              <input
                type="text"
                className="form-control border-custom-naranja rounded-pill"
                id="exampleFormControlInput1"
                placeholder="Marcos..."
                name="ficha_value"
                onChange={(event) => onChange(event)}
              />
              {alert.ficha ? (
                form.ficha_value.trim() == "" ? (
                  <span className="text-danger fs-7">
                    El nombre es obligatorio para agregar a la ficha técnica
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="mb-3 text-center">
              <button
                className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                name="addFichaTecnica"
                onClick={(event) => onClick(event, null)}
              >
                Agregar a la ficha técnica{" "}
                <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
              </button>
            </div>
          </div>

          <div className="col-md-6">
            <ul className="list-group border-0">
              {form.ficha_tecnica
                ? Object.keys(form.ficha_tecnica).map((ficha) => (
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 p-0 m-0 py-1">
                      <div>
                        <div className="fw-bold fs-6">{ficha}</div>
                        <span className="fw-normal">
                          {form.ficha_tecnica[ficha]}
                        </span>
                      </div>
                      <div>
                        <button
                          className="btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                          name="deleteFichaTecnica"
                          onFocus={(event) => onClick(event, ficha)}
                        >
                          {" "}
                          <i className="bi bi-x-lg"></i>{" "}
                        </button>
                      </div>
                    </li>
                  ))
                : evento
                ? Object.keys(JSON.parse(evento.ficha_tecnica)).map((ficha) => (
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 p-0 m-0 py-1">
                      <div>
                        <div className="fw-bold fs-6">{ficha}</div>
                        <span className="fw-normal">
                          {JSON.parse(evento.ficha_tecnica)[ficha]}
                        </span>
                      </div>
                      <div>
                        <button
                          className="btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                          name="deleteFichaTecnica"
                          onFocus={(event) => onClick(event, ficha)}
                        >
                          {" "}
                          <i className="bi bi-x-lg"></i>{" "}
                        </button>
                      </div>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      </div>

      <div
        className="col-12  border border-0 shadow-sm bg-white mb-5 pb-0 p-2 "
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="mb-3 fs-5 py-2 fw-bold border-bottom">
              Links sobre mi evento (Opcional)
              <span className="fs-6 text-muted fw-normal d-block">
                Puedes contener el URL de cualquier dirección en un nombre que
                se mostrara en el evento públicado.
              </span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label text-muted fs-6"
              >
                Asigna un nombre a tu link
              </label>
              <input
                type="email"
                className="form-control border-custom-naranja rounded-pill"
                id="exampleFormControlInput1"
                placeholder="Facebook..."
                name="link_key"
                onChange={(event) => onChange(event)}
              />
              {alert.link ? (
                form.link_key.trim() == "" ? (
                  <span className="text-danger fs-7">
                    El URL o direccion es obligatorio para cargar un nuevo Link
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label text-muted fs-6"
              >
                URL o Dirección completa
              </label>
              <input
                type="email"
                className="form-control border-custom-naranja rounded-pill"
                id="exampleFormControlInput1"
                placeholder="https://www.facebook.com.ar..."
                name="link_value"
                onChange={(event) => onChange(event)}
              />
              {alert.link ? (
                form.link_value.trim() == "" ? (
                  <span className="text-danger fs-7">
                    El URL o direccion es obligatorio para cargar un nuevo Link
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <div className="mb-3 text-center">
              <button
                className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                name="addLink"
                onClick={(event) => onClick(event, null)}
              >
                Agregar link <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
              </button>
            </div>
          </div>

          <div className="col-md-6">
            <ul className="list-group border-0">
              {form.links
                ? Object.keys(form.links).map((link) => (
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 p-0 m-0 py-1">
                      <div>
                        <div className="fw-bold fs-6">{link}</div>
                        <span className="fw-normal">{form.links[link]}</span>
                      </div>
                      <div>
                          <label className="btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7">
                              <i className="bi bi-x-lg"></i>
                        <button
                            className="d-none btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                            name="deleteLink"
                            onClick={(event) => onClick(event, link)}
                          >
                          </button>
                          </label>
                      </div>
                    </li>
                  ))
                : evento
                ? evento.link
                  ? Object.keys(JSON.parse(evento.link)).map((link) => (
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 p-0 m-0 py-1">
                        <div>
                          <div className="fw-bold fs-6">{link}</div>
                          <span className="fw-normal">
                            {JSON.parse(evento.link)[link]}
                          </span>
                        </div>
                        <div>
                          <label  className="btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7">
                              <i className="bi bi-x-lg"></i>
                          <button
                            className="d-none btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                            name="deleteLink"
                            onClick={(event) => onClick(event, link)}
                          >
                          </button>
                          </label>
                        </div>
                      </li>
                    ))
                  : ""
                : ""}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Que;
