import { convertMonth } from "./FunctionTickets";
//Date format
import { format, add, sub } from "date-fns";

export const textVentas = (fecha) => {
  let hoy = format(new Date(), "yyyy/MM/dd").toString();
  let funcion = (fecha.split(" "));
  funcion = funcion[5]+"/"+convertMonth(funcion[3])+"/"+funcion[1];

  if(new Date(funcion) >= new Date(hoy)){
    return "Por liquidar"
  } else {
    return "No hay entradas vendidas"
  }
}

//
export const fileDateReportEvents = (eventos, filtro) => {
  let result = [];
  let reportes = [];

  eventos.map((evento) => {
    let evento_agrupado = [];
    //concatenar fecha
    let fechas = JSON.parse(evento.fechas);

    fechas.map((fecha) => {
    let group = [];
    let group2 = [];
    let concat_fecha = fecha.fechas[0]+" "+"HORA: "+fecha.hora;
    //fecha filtro
    let funcion_fecha =  fecha.fechas[0].split(" ");
    funcion_fecha = funcion_fecha[5]+"/"+convertMonth(funcion_fecha[3])+"/"+funcion_fecha[1];
    
    //Agrupar reportes
    evento.reportes.map((reporte) => {
      if(filtro.status !== ""){
        if(reporte.fecha == concat_fecha && reporte.status == filtro.status){
          group.push(reporte);
        }
      } else {
        if(reporte.fecha == concat_fecha && reporte.status == "ACTIVO"){
          group.push(reporte);
        } 
        if(reporte.fecha == concat_fecha && reporte.status == "LIQUIDADO"){
          group2.push(reporte);
        }
      }
      
    })
    //end grup
      //Datos argentores
      let argentores_datos = {
        state: false,
        porcentaje: 0
      };
      //Filtro especial para argentores
        evento.liquidacion.map((liqui) => {
          if(liqui.porcentaje_argentores){
            if(liqui.porcentaje_argentores > 0){
              argentores_datos.state = true;
              argentores_datos.porcentaje = liqui.porcentaje_argentores;
            }
          }
        })

    //Filtro Todos
    if(filtro.status == "" && group2.length !== 0){
      let obj = {
        evento: {
          nombre: evento.nombre,
          atp: evento.atp,
          clase: evento.clase,
          grupo: evento.grupo,
          created_at: evento.created_at,
          updated_at: evento.updated_at,
          direccion: evento.direccion,
          localidad: evento.localidad,
          provincia: evento.provincia,
          entradas: evento.entradas,
          cupones: evento.cupones,
          descripcion: evento.descripcion,
          duracion: evento.duracion,
          email_sitio: evento.email_sitio,
          ficha_tecnica: evento.ficha_tecnica,
          historia: evento.historia,
          id: evento.id,
          imagen: evento.imagen,
          link: evento.link,
          maps_sitio: evento.mapos_sitio,
          nombre_grupo: evento.nombre_grupo,
          nombre_sitio: evento.nombre_sitio,
          status: evento.status,
          telefono_sitio: evento.telefono_sitio,
          url_youtube: evento.url_youtube,
          usuarios: evento.usuarios,
          usuarios_id: evento.usuarios_id,
          visitas: evento.visitas,
          website_sitio: evento.website_sitio,
          liquidacion: evento.liquidacion,
          argentores: argentores_datos
        },
        reportes: group2,
        fecha: concat_fecha  
      }
      evento_agrupado.push(obj);
    }
    //Guardar solo si existen reportes
    if(group.length !== 0 ){
      let obj = {
        evento: {
          nombre: evento.nombre,
          atp: evento.atp,
          clase: evento.clase,
          grupo: evento.grupo,
          created_at: evento.created_at,
          updated_at: evento.updated_at,
          direccion: evento.direccion,
          localidad: evento.localidad,
          provincia: evento.provincia,
          entradas: evento.entradas,
          cupones: evento.cupones,
          descripcion: evento.descripcion,
          duracion: evento.duracion,
          email_sitio: evento.email_sitio,
          ficha_tecnica: evento.ficha_tecnica,
          historia: evento.historia,
          id: evento.id,
          imagen: evento.imagen,
          link: evento.link,
          maps_sitio: evento.mapos_sitio,
          nombre_grupo: evento.nombre_grupo,
          nombre_sitio: evento.nombre_sitio,
          status: evento.status,
          telefono_sitio: evento.telefono_sitio,
          url_youtube: evento.url_youtube,
          usuarios: evento.usuarios,
          usuarios_id: evento.usuarios_id,
          visitas: evento.visitas,
          website_sitio: evento.website_sitio,
          liquidacion: evento.liquidacion,
          argentores: argentores_datos
        },
        reportes: group,
        fecha: concat_fecha  
      }
      evento_agrupado.push(obj);
    } else {
      if(filtro.status == "LIQUIDADO" ){
        let obj = {
          evento: {
            nombre: evento.nombre,
            atp: evento.atp,
            clase: evento.clase,
            grupo: evento.grupo,
            created_at: evento.created_at,
            updated_at: evento.updated_at,
            direccion: evento.direccion,
            localidad: evento.localidad,
            provincia: evento.provincia,
            entradas: evento.entradas,
            cupones: evento.cupones,
            descripcion: evento.descripcion,
            duracion: evento.duracion,
            email_sitio: evento.email_sitio,
            ficha_tecnica: evento.ficha_tecnica,
            historia: evento.historia,
            id: evento.id,
            imagen: evento.imagen,
            link: evento.link,
            maps_sitio: evento.mapos_sitio,
            nombre_grupo: evento.nombre_grupo,
            nombre_sitio: evento.nombre_sitio,
            status: evento.status,
            telefono_sitio: evento.telefono_sitio,
            url_youtube: evento.url_youtube,
            usuarios: evento.usuarios,
            usuarios_id: evento.usuarios_id,
            visitas: evento.visitas,
            website_sitio: evento.website_sitio,
            liquidacion: evento.liquidacion,
            argentores: argentores_datos
          },
          reportes: [],
          fecha: concat_fecha  
        }
  
        evento_agrupado.push(obj);
      }
    }
    })
    //end fecha

    result.push(evento_agrupado);
  })

  return result;
}

//totales
export const totalReportEvents = (reportes) => {
  let total_reportes = 0;
  let total_cantidad = 0;
  let total_serv = 0;
  //sin descuento
  let total_bruto = 0; 
  //con descuento
  let total_pagos = 0;
  //cupon
  let total_descuento = 0;
  let use_cupon = 0;
  let total_entrada_descuento = 0;

  let descuento = 0;

  reportes.map((reporte) => {
    if(reporte.status == "LIQUIDADO" || reporte.status == "ACTIVO"){
    //Pre calculo
    let subTotal = parseFloat(reporte.cantidad*reporte.unidad);
    let serv = subTotal > 500? subTotal/100*reporte.comision : subTotal + 75
    total_serv = (subTotal + serv) + total_serv;

    //bruto
    if(parseFloat(reporte.total) !== parseFloat(reporte.cantidad*reporte.unidad)){
      total_bruto = (reporte.cantidad*reporte.unidad)+total_bruto//parseFloat(reporte.total)+parseFloat(total_bruto);
    } else {
      total_bruto = (reporte.cantidad*reporte.unidad)+total_bruto;
    }

    //descuento
    if(reporte.codigo_descuento){
      subTotal = subTotal - subTotal/100*reporte.descuento;

      total_descuento = ((reporte.cantidad*reporte.unidad/100)*reporte.descuento)+total_descuento;
      use_cupon = use_cupon+1;
      total_entrada_descuento = reporte.cantidad+total_entrada_descuento;
    }
    
    let multiplicador = reporte.multiplicador? parseInt(reporte.multiplicador) : 1;
    total_cantidad = reporte.cantidad * multiplicador + total_cantidad ;

    total_pagos = parseFloat(subTotal)+total_pagos;


    }

  })

  return {
    total_reportes: total_reportes,
    total_pagos: total_pagos,
    total_cantidad: total_cantidad,
    total_serv: total_serv,
    //total sin bruto
    total_bruto: total_bruto,
    //cupon
    use_cupon: use_cupon,
    total_descuento: total_descuento,
    total_entrada_descuento: total_entrada_descuento
  }
}

//activo liquidados
export const filterReports = (reportes, status) => {
  let result = []

  
  reportes.map((reporte) => {
    console.log(reporte);
    if(reporte.status == "LIQUIDADO" || reporte.status == "ACTIVO"){
      result.push(reporte);
    }
  })

  return result;
}