import { Link, useParams } from "react-router-dom"

const CardVideo = ({onClick, data}) => {

  const {titulo, usuarios, descripcion, created_at, categorias, url, imagen, id, status} = data

  const concatDate = (fecha, select) => {
    if(!fecha){
      return "Sin fecha asignada"
    }

    let result;

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];


    switch (select) {
      case "created_at":{
        let fh = new Date(fecha);
        
        result = dias[fh.getDay()]+" "+fh.getDate()+" de "+(mes[fh.getMonth()])+" de "+fh.getFullYear()+" Hora: "+fh.getHours()+":"+fh.getMinutes()
      }
      break;
      case "funciones":{
        if(!fecha){
          return ""
        }
        let fechas = JSON.parse(fecha);
        let fh_start = fechas[0].fechas[0].split(" ");
        let fh_end = fechas[fechas.length-1].fechas[fechas[fechas.length-1].fechas.length-1].split(" ");

        result = "Desde el "+fh_start[0]+" "+fh_start[1]+" de "+fh_start[3]+" hasta el "+fh_end[0]+" "+fh_end[1]+" de "+fh_end[3]
      }
      break;
    }

    return result;
  }
  return (
    <div className="col-md-12  p-0 m-0 mb-3 bg-white hover-card border border-light" style={{ borderRadius: "10px" }}>
    <div className="row bg-transparent w-100 m-0 p-0">
    <div className="col-md-3  border-0 p-0 m-0 d-flex jusitfy-content-center align-items-center">
      <div className="w-100 border-0 d-flex justify-content-center align-items-center overflow-hidden" style={{height: "200px"}}>
      <img src={"/assets/img/videos/"+imagen} className="img-fluid text-center" alt="IMAGEN NO ENCONTRADA" />
      </div>
    </div>
    <div className="col-md-9  p-0 m-0 ">
      <ul className="list-group">
        <li className="list-group-item bg-transparent d-flex justify-content-between border-0 mb-0 pb-0 ">
          <div className="mt-2">{titulo}</div>
          <div>
          <Link to={"/videos/"+id} className="btn btn-custom-black text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 mt-2 px-2 py-1 fs-10">
            <i className="fs-8 bi bi-eye "></i> Vista Previa
          </Link>
          </div>
        </li>
        <li className="list-group-item bg-transparent m-0 py-0 border-0">
          <span className="text-muted fs-10">{descripcion}</span>
        </li>
        <li className="list-group-item bg-transparent m-0 py-0 border-0">
          <span className="text-muted fs-10">Autor</span>
          <span className="fs-10"> {usuarios.nombre}</span>
        </li>
        <li className="list-group-item bg-transparent m-0 py-0 border-0">
          <span className="text-muted fs-10">Responsable</span>
          <span className="fs-10"> {usuarios.nombre+" "+usuarios.apellido}</span>
          <span className="text-muted fs-10"> Email</span>
          <span className="fs-10"> {usuarios.email}</span>
        </li>
        <li className="list-group-item bg-transparent border-0">
               <iframe
                  width="100%"
                  height="200px"
                  src={url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
        </li>
        <li className="list-group-item bg-transparent border-0 d-flex justify-content-between">
          <div className="d-flex">
            <span className="text-muted fs-10 me-2">
              Comentarios <p className="text-muted fw-bold"></p>
            </span>
            <span className="text-muted fs-10 me-2">
              Visitas totales {" "}
              <p className="text-muted fw-bold">{}</p>
            </span>
          </div>
          <div className="d-flex">
            
          <div>
              <button className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10" name="editVideo" onClick={(event) => onClick(event, data)}>
                <i className="bi bi-pencil"></i> Editar
              </button>
            </div>

            <div class="dropdown">
                  <button
                    class="btn btn-custom-naranja text-white fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="">
                      <label className="btn m-0 p-0">
                        <i title="Activar video" class="text-success mx-1 bi bi-check-circle-fill"></i>
                        Activar
                        <button className="d-none"
                        name="statusVideo"
                        onClick={(event) => onClick(event, {video: data, status: "ACTIVO"})}></button>
                      </label>
                    </li>
                    <li> 
                      <label className="btn m-0 p-0"> 
                      <i title="Descativar video" class="mx-1 text-danger bi bi-x-circle"></i>
                        Desactivar
                        <button className="d-none" 
                        name="statusVideo"
                        onClick={(event) => onClick(event, {video: data, status: "INACTIVO"})}></button>
                      </label>
                    </li>
                  </ul>
                </div>

            <div className="dropdown">
              <button
                className="btn btn-custom-naranja text-white fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ver...
              </button>
              <ul
                className="dropdown-menu fs-8"
                aria-labelledby="dropdownMenuButton1"
              > <li>
              <a className="dropdown-item" href="#" name="select_status" onFocus={(event) => onClick(event, data)}>
                <i className=" mx-1 bi bi-gear"></i>Comentarios
              </a>
            </li>
              </ul>
            </div>

          </div>
        </li>
        <li className="list-group-item bg-transparent border-0 d-flex justify-content-between border-top">
          <div className="d-flex">
            <span className="text-muted fs-10 me-2">
              Fecha de creación
            </span>
            <span className="fs-10 fw-bold me-2">
              {concatDate(created_at, "created_at")}
            </span>
          </div>
          <div className="d-flex">
            <span className="text-muted fs-10 me-2">
              Estado del evento
            </span>
            <span className="fs-10 fw-bold me-2">{
              status == "ACTIVO"
              ? "ACTIVO"
              : status == "PAUSADO"
              ? "PAUSADO"
              : status == "ELIMINADO"
              ? "ELIMINADO"
              : status == "INACTIVO"
              ? "INACTIVO"
              : ""
            }</span>
          </div>
        </li>
      </ul>
    </div>
    </div>
</div>
  )
}

export default CardVideo