import { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ModalPlanilla = ({
  administradorUsuarios,
  contents,
  onClick,
  onChange,
  filter,
}) => {

  const [usuarios, setUsuarios] = useState(null);
  
  let filter_espectadores = {
    search: "",
    status: "1",
    provincia: filter?  filter.provincia : null,
    url: filter? filter.url : null,
    status_eventos: "",
    pagination: contents? contents.total : null
  };


  useEffect(() => {
    administradorUsuarios(setUsuarios, filter_espectadores);
  },[])

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "1000px" }}
      >
        <div className="modal-content bg-light">
          <div class="title bg-white m-0 p-4 d-flex justify-content-between">
            <div>
              <h1>Planilla de usuarios activos</h1>
              <span class="fs-6 text-muted">
                Descarga la planilla con la información de todos los usuarios.{" "}
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-md-4 p-2">
            <div className="p-3 bg-white mb-3 text-center" style={{borderRadius: "10px"}}>
              <ReactHTMLTableToExcel
                  id="buttonDownloadExcel"
                  className="btn btn-outline-success w-100 ms-2 rounded-pill p-0 m-0 px-2 py-1"
                  table="planillaUsuarios"
                  filename="planilla-usuarios"
                  sheet="pagina 1"
                  buttonText="Exportar Excel "
                />

                <div className="mt-2 fw-bold">TOTAL DE USUARIOS ACTIVOS {usuarios? usuarios.total : ""}</div>
            </div>
            <div className="bg-white p-2" style={{borderRadius: "10px"}}>
              <table id="planillaUsuarios" className="table table-hover align-middle m-0 p-0">
                <thead className="border-0">
                  <tr className="border-0">
                  <th className="border-0">Nombre</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Telefono</th>
                  <th className="border-0">Provincia</th>
                </tr>
                </thead>
                <tbody  className="border-0">
                {
                  usuarios
                  ? usuarios.data.map((usuario) => (
                    <tr>
                    <td className="border-0">{usuario.nombre}</td>
                    <td className="border-0">{usuario.email? usuario.email : usuario.prd_email}</td>
                    <td className="border-0">{usuario.telefono}</td>
                    <td className="border-0">{usuario.provincia}</td>
                    </tr>
                  ))
                  : ""
                }
              </tbody>
              <tfoot></tfoot>
            </table>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div class="d-flex">
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close_modal"
                  onFocus={(event) => onClick(event, null)}
                >
                  Cerrar
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPlanilla;
