import { Link, NavLink } from "react-router-dom";
import { cerrarSession } from "../../../helpers/AccessHelper";
import CardNotificacion from "../Card/CardNotificacion";

const NavSidebar = ({ children, usuario }) => {
  const { data, rol, status, auth } = usuario;

  const onClick = async (e) => {
    switch (e.target.name) {
      case "cerrar_sesion":
        {
          const response = cerrarSession();
        }
        break;
    }
  };

  return (
    <div className="container-fluid min-vh-100" style={{ zIndex: 100 }}>
      <div className="row flex-nowrap bg-light p-0 mb-0 h-100 min-vh-100">
        <div
          className="col-md-auto col-auto bg-dark p-0 m-0 me-0 ms-0 mb-0 min-vh-100"
          style={{ zIndex: 102 }}
        >
          <div
            id="sidebar"
            className="collapse collapse-horizontal h-100 border-end min-vh-100 overflow-hidden"
          >
            <div
              id="sidebar-nav"
              className="list-group bg-dark  border-0 rounded-0 text-sm-start h-100 min-vh-100"
            >
              <NavLink to="/inicio" className="nav-link  text-truncate">
                <img
                  src="/assets/img/antesala-logo-white.svg"
                  width="100px"
                  className="img-fluid d-md-block mx-auto py-3 d-none "
                  alt=""
                />
              </NavLink>

              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center nav-ul m-0 p-0"
                id="menu"
              >
                <li className="p-0 m-0 nav-hover w-100">
                  <NavLink
                    to="/dashboard/micuenta"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i className="text-light fs-7 bi-house"></i>
                    <span className="text-light fs-8 fw-normal ms-1 d-none d-sm-inline">
                      Mis datos
                    </span>
                  </NavLink>
                </li>

                {
                  rol == 1 || rol == 3 || rol == 4
                  ? <li className="p-0 m-0 nav-hover w-100">
                  <NavLink
                    to="/dashboard/eventos"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i className="text-light fs-7 bi bi-calendar2-check"></i>
                    <span className="text-light fs-8 fw-normal ms-1 d-none d-sm-inline">
                      Eventos
                    </span>
                  </NavLink>
                </li>
                :""
                }

                

                {
                  rol == 1
                  ? <li className="p-0 m-0 nav-hover w-100">
                  <NavLink
                    to="/dashboard/cupones"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i class="text-light fs-7 bi bi-ticket-perforated"></i>
                    <span className="text-light fs-8 fw-normal ms-1 d-none d-sm-inline">
                      Cupones
                    </span>
                  </NavLink>
                </li>
                :""
                }

                {
                  rol == 1
                  ? <li className="p-0 m-0 nav-hover w-100">
                  <NavLink
                    to="/dashboard/membresias"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i class="text-light fs-7 bi bi-ticket-perforated"></i>
                    <span className="text-light fs-8 fw-normal ms-1 d-none d-sm-inline">
                      Membresias
                    </span>
                  </NavLink>
                </li>
                :""
                }

                {rol == 1 ? (
                  <li className="nav-item nav-hover  py-1 ps-3 pe-5 pe-md-0 ps-md-0 pb-md-0 w-100 text-light">
                    <a
                      href="#submenu1"
                      className="nav-link d-flex justify-content-start align-items-center p-0 text-light ps-md-3 ps-0 pb-1 text-light"
                      data-bs-toggle="collapse"
                    >
                      <i className="text-light fs-7 bi bi-people"></i>
                      <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                        Usuarios
                      </span>
                      <div className="text-end w-100 pe-md-3 pe-3">
                        <i className="fs-7 bi bi-caret-down-fill text-light"></i>
                      </div>
                    </a>
                    <ul
                      className="collapse bg-dark nav list-group-item border-0 flex-column text-center p-0 m-0 w-100"
                      id="submenu1"
                      data-bs-parent="#menu"
                    >
                      <li className="p-0 m-0 w-100 nav-hover">
                        <NavLink
                          to="/dashboard/salas"
                          className={(navData) =>
                            navData.isActive
                              ? "nav-link active-sidebar-nav p-0 py-1 ps-4 pe-5 d-flex justify-content-start text-white align-items-center bg-light bg-opacity-25 fs-8"
                              : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 fs-8 d-flex justify-content-start align-items-center text-white"
                          }
                        >
                          <i className="fs-7 bi bi-bank text-white"></i>
                          <span className="ms-1 d-none d-sm-inline">Salas</span>
                        </NavLink>
                      </li>
                      <li className="p-0 m-0 w-100 nav-hover">
                        <NavLink
                          to="/dashboard/productores"
                          className={(navData) =>
                            navData.isActive
                              ? "nav-link active-sidebar-nav p-0 py-1 ps-4 pe-5 d-flex justify-content-start text-white align-items-center bg-light bg-opacity-25 fs-8"
                              : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 fs-8 d-flex justify-content-start align-items-center text-white"
                          }
                        >
                          <i className="fs-7 bi bi-person-lines-fill text-white"></i>
                          <span className="ms-1 d-none d-sm-inline position-relative">
                            Productores
                          </span>
                          <span class="badge bg-danger fs-11 ms-1 d-none">
                            Nuevo !
                          </span>
                        </NavLink>
                      </li>
                      <li className="p-0 m-0 w-100 nav-hover">
                        <NavLink
                          to="/dashboard/espectadores"
                          className={(navData) =>
                            navData.isActive
                              ? "nav-link active-sidebar-nav p-0 py-1 ps-4 pe-5 d-flex justify-content-start text-white align-items-center bg-light bg-opacity-25 fs-8"
                              : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 fs-8 d-flex justify-content-start align-items-center text-white"
                          }
                        >
                          <i className="fs-7 bi bi-person text-white"></i>
                          <span className="ms-1 d-none d-sm-inline">
                            Espectadores
                          </span>
                          <span class="badge bg-danger fs-11 ms-1 d-none">
                            Nuevo !
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}


                {
                  rol == 1 || rol == 3 || rol == 4
                  ?<li className="p-0 m-0 w-100 nav-hover">
                  <NavLink
                    to="/dashboard/cuentas"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  ><i class="text-light fs-7 fa-regular fa-folder-open"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Cuentas
                    </span>
                  </NavLink>
                </li>
                :""
                }

                {
                  rol == 1
                  ?<li className="p-0 m-0 w-100 nav-hover ">
                  <NavLink
                    to="/dashboard/reportes"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i class="text-light fs-7 bi bi-bar-chart-line"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Reportes
                    </span>
                  </NavLink>
                </li>
                :""
                }

                <li className="p-0 m-0 w-100 nav-hover">
                  <NavLink
                    to="/dashboard/miscompras"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i className="text-light fs-7 bi bi-cart3"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Mis compras
                    </span>
                  </NavLink>
                </li>
                
                {
                  rol == 1
                  ?<li className="p-0 m-0 w-100 nav-hover ">
                  <NavLink
                    to="/dashboard/notas"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i class="text-light fs-7 bi bi-tv"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Notas
                    </span>
                  </NavLink>
                </li>
                :""
                }



                {
                  rol == 1
                  ?  <li className="p-0 m-0 w-100 nav-hover d-none">
                  <NavLink
                    to="/dashboard/miscompras"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i className="text-light fs-7 bi bi-headphones"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Podcast
                    </span>
                  </NavLink>
                </li>
                  : ""
                }

               {
                 rol == 1
                 ?   <li className="p-0 m-0 w-100 nav-hover">
                 <NavLink
                   to="/dashboard/videos"
                   className={(navData) =>
                     navData.isActive
                       ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                       : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                   }
                 >
                   <i className="text-light fs-7 bi bi-camera-reels"></i>
                   <span className="text-light fs-8 ms-1  d-sm-inline d-none">
                     Videos
                   </span>
                 </NavLink>
               </li>
                 : ""
               }


                {
                  rol == 1
                  ? <li className="p-0 m-0 w-100 nav-hover">
                  <NavLink
                    to="/dashboard/Configuracion"
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center bg-light bg-opacity-25"
                        : "nav-link active-sidebar-nav p-0 py-1 ps-3 pe-5 d-flex justify-content-start align-items-center"
                    }
                  >
                    <i className="fs-7 text-light bi bi-gear"></i>
                    <span className="text-light fs-8 ms-1 d-none d-sm-inline">
                      Configuración
                    </span>
                  </NavLink>
                </li>
                  : ""
                }

               
              </ul>
            </div>
          </div>
        </div>

        <main className="col bg-light me-0 ms-0 h-100 min-vh-100" style={{backgroundColor: "#eaeaea"}}>
          <div
            className="d-flex py-1 bg-white justify-content-between align-items-center"
            style={{ zIndex: 103 }}
          >
            <div>
              <a
                href="#"
                data-bs-target="#sidebar"
                data-bs-toggle="collapse"
                className="btn border rounded-3 p-0 px-2 py-1 text-decoration-none"
              >
                <i className=" text-dark bi bi-list bi-lg p-0"></i>
              </a>
              <span className="btn btn-outline-dark border-0 rounded-1 d-none d-md-inline  fs-5 p-0 py-2 px-2 m-0 mx-1">
                {rol == 1 ? (
                  <span>Administrador</span>
                ) : rol == 3 ? (
                  <span>{data.datos.nombre}</span>
                ) : rol == 4 ? (
                  <span>{data.datos.nombre}</span>
                ) : (
                  ""
                )}
              </span>
            </div>

            <div className="d-flex justify-content-center align-items-center ">
              <div className="border-start h-100 py-3"></div>
              <div className="dropdown d-inline">
                <button
                  title="Notificaciones"
                  className="btn p-1 px-2 border-0"
                  type="button"
                  id="dropdownChats"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className=" fs-5 bi bi-chat-square"></i>
                </button>

                <ul className="dropdown-menu" aria-labelledby="dropdownChats">
                  <i className="dropdown-before text-white bi bi-caret-up-fill"></i>
                  <li>
                    <NavLink className="dropdown-item" to="/">
                      PROXIMAMENTE
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="dropdown d-inline position-relative">
                <button
                  title="Notificaciones"
                  className="btn px-2 p-1 border-0 position-relative"
                  type="button"
                  id="dropdownNotificaciones"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fs-5  bi bi-bell"></i>

                  {data.notificaciones.length == 0 ? (
                    ""
                  ) : (
                    <span
                      class="position-absolute start-1 translate-middle badge rounded-pill bg-danger"
                      style={{ fontSize: "8px", top: "8px" }}
                    >
                      {data.notificaciones.length}+
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  )}
                </button>

                <ul
                  className="dropdown-menu position-absolute me-1 px-1 pt-3 me-md-0 "
                  aria-labelledby="dropdownNotificaciones"
                  style={{ zIndex: 104, width: "350px" }}
                >
                  <i className="dropdown-before text-white bi bi-caret-up-fill"></i>
                  {data.notificaciones.length == 0 ? (
                    <li className="px-1">
                      <div className="text-nowrap px-1 py-1 alert alert-info m-0">
                        <i className="bi bi-emoji-neutral"></i> No hay
                        notificaciones nuevas.
                      </div>
                    </li>
                  ) : (
                    data.notificaciones.map((notificacion, index) => (
                      <CardNotificacion keys={index} data={notificacion} />
                    ))
                  )}
                </ul>
              </div>

              <button
                className="btn p-1 px-2 border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                {" "}
                <i className="fs-5  bi bi-megaphone"></i>
              </button>
              <div className="border-start h-100 py-3"></div>
              <div
                className="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <h5 id="offcanvasRightLabel">Novedades</h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">...</div>
              </div>

              <div className="dropdown d-inline">
                <button
                  className="btn p-1 px-2 border-0 d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fs-5  bi bi-person-circle"></i>
                  <span className="fs-6 ms-2 d-none d-md-inline">
                    {data.nombre + " " + data.apellido}
                  </span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <i className="dropdown-before text-white bi bi-caret-up-fill"></i>
                  <li>
                    <div className="dropdown-item">
                      {data.nombre + " " + data.apellido}
                    </div>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/dashboard/micuenta">
                      Mi perfil
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/dashboard/eventos">
                      Eventos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/dashboard/cuentas">
                      Cuentas
                    </NavLink>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      name="cerrar_sesion"
                      onClick={(event) => onClick(event)}
                    >
                      Cerrar sesion
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr />

          <div
            className="row d-flex  justify-content-center bg-transparent m-0 "
            style={{ zIndex: 102, backgroundColor: "#eaeaea" }}
          >
            <div className="col-12 bg-transparent">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NavSidebar;
