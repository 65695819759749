import clientAxios from "./Config";
import Swal from "sweetalert2";

const valueMonths = (mes) => {
  
  switch (mes) {
    case "enero":{ return 1 }break;
    case "febrero":{ return 2 }break;
    case "marzo":{ return 3 } break;    
    case "abril":{ return 4 }break;
    case "mayo":{ return 5 }break; 
    case "junio":{ return 6 }break;
    case "julio":{ return 7 }break;
    case "agosto":{ return 8 }break;
    case "septiembre":{ return 9 }break;
    case "octubre":{ return 10 }break;
    case "noviembre":{ return 11 }break;
    case "diciembre":{ return 12 }break;
    }
}

export const Eventos = async (
  filtro
) => {
  let params = {
    geolocation: filtro.geolocation,
    scroll_pagination: filtro.scroll_pagination,
  };

  if (filtro.filtro.trim() !== "") {
    params = { ...params, filtro: filtro.filtro };
  }

  if (filtro.id.trim() !== ""){
    params = {...params, id: filtro.id};
  }

  if(filtro.nombre.trim() !== ""){
    params = { ...params, nombre: filtro.nombre };
  }

  if(filtro.atp.trim() !== ""){
    params = { ...params, atp: filtro.atp };
  }

  if(filtro.categoria.length !== 0){
    params = { ...params, categoria: filtro.categoria };
  }

  if(filtro.espectaculo !== ""){
    params = { ...params, espectaculo: filtro.espectaculo };
  }

  if(filtro.date_range.length !== 0){
    params = { ...params, date_range: filtro.date_range };
  }

  if(filtro.localidad.trim() !== ""){
    params = { ...params, localidad: filtro.localidad };
  }

  if(filtro.sala.trim() !== ""){
    params = { ...params, sala: filtro.sala };

  }

  if(filtro.fecha.trim() !== ""){
    params = { ...params, fecha: filtro.fecha };

  }

  if(filtro.publico.trim() !== ""){
    params = { ...params, publico: filtro.publico };

  }

  if (filtro.provincia.trim() !== "") {
    params = { ...params, provincia: filtro.provincia };
  }

  if (filtro.stateOffer.trim() !== "") {
    params = { ...params, stateOffer: filtro.stateOffer };
  }

  const result = await clientAxios
    .get("/api/Eventos", { params })
    .then((result) => {
      return result.data;
    })
    .catch((result) => {
      return null;
    });

  if(!result){
    let initialState = {
      data: []
    }
    return initialState 
  }  


  if(filtro.id.trim() == ""){
  //ORDENAMIENTO POR FECHAS CERCANAS

  let data =  result.data 

  data = data.sort((eventoA, eventoB) => {
    eventoA = JSON.parse(eventoA.fechas)[0].fechas[0].split(" ");
    let compareA = [eventoA[1], valueMonths(eventoA[3])];
    
    eventoB = JSON.parse(eventoB.fechas)[0].fechas[0].split(" ");
    let compareB = [eventoB[1], valueMonths(eventoB[3])];

    if (Number(compareA[0]) == Number(compareB[0])) {
      return 0;
    }
    if (Number(compareA[0]) < Number(compareB[0])) {
      return -1;
    }
    return 1;
  })

  data = data.sort((eventoA, eventoB) => {
    eventoA = JSON.parse(eventoA.fechas)[0].fechas[0].split(" ");
    let compareA = [eventoA[1], valueMonths(eventoA[3])];
    
    eventoB = JSON.parse(eventoB.fechas)[0].fechas[0].split(" ");
    let compareB = [eventoB[1], valueMonths(eventoB[3])];


    if (Number(compareA[1]) == Number(compareB[1])) {
      return 0;
    }
    if (Number(compareA[1]) < Number(compareB[1])) {
      return -1;
    }
    return 1;
  })

  }
  
  return result;



};
