import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config"


export const SuscripcionCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/Administrador/Suscripciones/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);  
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error"); 
  });

  return response;
}

export const AdministradorSuscripcion = async (filter) => {

  const result  = await clientAxios.get("/api/auth/Administrador/Suscripciones").then((result) => {return result.data}).catch((result) => {return null});

  return result;
}