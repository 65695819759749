import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CollectionStateContext } from "../../../context/EventosState";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import Item from "./Item";

const Sala = () => {
  const context = useContext(CollectionStateContext);
  const { salas } = context;

  const { id } = useParams();

  //const history = useNavigate();
  //history(-1)

  const [dataSala, setSala] = useState(null);
  const [filter, setFilter] = useState({
    id: id,
    search: "",
    provincia: "",
    localidad: "",
    filtro: "false",
  });

  let imagenes = [];
  if (dataSala) {
    imagenes = [
      dataSala.imagen_desc_1,
      dataSala.imagen_desc_2,
      dataSala.imagen_desc_3,
      dataSala.imagen_int_1,
      dataSala.imagen_int_2,
      dataSala.imagen_desc_3,
    ];
  }

  //

  const options_imagen = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    navText: ["<div style='top: 1px; left: -30px;' class='d-md-block d-none position-absolute h-100 d-flex justify-content-start fs-1'> <img width='20px' height='100%' src='/assets/img/icon-prev-arrow.svg' /></div>", "<div style='top: 1px; right: -30px;' class='d-md-block d-none position-absolute h-100 d-flex justify-content-start fs-1'> <img width='20px' height='100%' src='/assets/img/icon-next-arrow.svg' /></div>"],
    autoplayTimeout: 6000,
    fluidSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 3,
      },
    },
  };

  const options_eventos = {
    loop: false,
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    navText: ["", ""],
    autoplayTimeout: 6000,
    fluidSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 3,
      },
    },
  };

  /*"/assets/img/salas/" + img*/

  useEffect(() => {
    if (!dataSala) {
      salas(
        setSala,
        filter.id,
        filter.search,
        filter.provincia,
        filter.localidad,
        filter.filtro
      );
    }
  }, []);

  console.log(dataSala) 
  return (
    <div className="min-vh-100">
      <section className="wow bounceInUp d-none">
        <div className="container">
          <div className="row">
            <div className="col-8 col-md-9 pe-0">
              <div className="card-ticket cardLeft d-flex overflow-hidden flex-column">
                <h1
                  className="fc-blanco text-nowrap"
                  style={{ textOverflow: "ellipsis;" }}
                >
                  {dataSala ? dataSala.nombre : ""}
                </h1>
                <div className="title mb-5">
                  <h2>Antesala</h2>
                  <span>sala</span>
                  <div className=""></div>
                </div>
                <div>
                  <div className="seat">
                    <h2>156</h2>
                    <span>asiento</span>
                  </div>
                  <div className="time">
                    <h2>12:00</h2>
                    <span>hora</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-md-3 ps-0">
              <div className="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                <div className="text-center">
                  <img src="/assets/img/iso-antesala.svg" alt="" />
                </div>
                <div className="number">
                  <h3>156</h3>
                  <span>asiento</span>
                </div>
                <div className="text-center">
                  <img
                    className="barchart"
                    src="/assets/img/barcode.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sala-info" className="bg-gris-claro pt-100 pb-100 mt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-1  d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-md-2 col-12">
                  <div className="sala-logo wow bounceInUp">
                    <img
                      src={
                        !dataSala
                          ? ""
                          : "/assets/img/usuarios/" + dataSala.imagen_logo
                      }
                      className="img-fluid"
                      alt="IMAGEN NO ENCONTRADA"
                    />
                  </div>
                </div>
                <div className="col-md-5 col-12 wow bounceInUp">
                  <div className="w-100">
                    <h4>
                      <span className="Grifter">
                        {!dataSala ? "" : dataSala.provincia}
                      </span>{" "}
                      | {!dataSala ? "" : dataSala.localidad}
                    </h4>
                    <h2 className="fc-amarillo mb-0 lh-08 ">
                      {!dataSala ? "" : dataSala.nombre}
                    </h2>
                  </div>

                  <div>
                  <p className="mb-0">
                    {!dataSala
                      ? ""
                      : dataSala.direccion +
                        " " +
                        dataSala.provincia +
                        " " +
                        dataSala.localidad}
                  </p>
                  <p className="mb-0">{!dataSala ? "" : dataSala.email}</p>
                  <p className="mb-0">{!dataSala ? "" : dataSala.telefono}</p>
                  <a href={!dataSala ? "" : dataSala.website}>
                    {!dataSala ? "" : dataSala.website}
                  </a>
                  </div>
                </div>

                <div className="col-md-4">
                <div className="d-flex w-100 overflow-hidden" style={{borderRadius: "20px"}}>
                    {imagenes ? (
                      <img
                        src={"/assets/img/salas/" + imagenes[0]}
                        className="img-fluid"
                        alt="IMAGEN NO ENCONTRADA"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow bounceInRight d-none">
              <img
                src="assets/img/img-el-cuento.png"
                width="100%"
                className="br-10"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100">
        <div className="container">
          <div className="row mb-0">
            <div className="col-12 mb-5 wow bounceInUp">
              <h2>descripción</h2>
              {!dataSala ? (
                ""
              ) : (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: dataSala.descripcion }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="eventos" className="mt-0">
        <div className="container wow bounceInUp">
          <div className="row">
            <div className="col-12 d-flex jusitfy-content-center">
              {!dataSala ? (
                ""
              ) : (
                <ReactOwlCarousel style={{ zIndex: "0" }} {...options_imagen}>
                  {imagenes.length == 0
                    ? ""
                    : imagenes.map((img) => {
                        if (img) {
                          return (
                            <div className="item">
                              <div className="col-md-4 w-100 border-0 overflow-hidden" style={{borderRadius: "20px", height: "500px"}}>
                                <img
                                  className="img-fluid w-100"
                                  src={"/assets/img/salas/" + img}
                                />
                              </div>
                            </div>
                          );
                        }
                      })}
                </ReactOwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="eventos" className="my-5">
        <div className="container">
          <div className="w-100 fs-5 fw-bold Grifter text-center mb-1 ">
            Próximos espectáculos
          </div>
          {!dataSala ? (
            ""
          ) : (
            <ReactOwlCarousel
              style={{ zIndex: "0" }}
              className="owl-theme"
              {...options_eventos}
            >
              {dataSala.eventos.map((item, index) => (
                <Item key={index} data={item} />
              ))}

              {dataSala.eventos_otros.map((item, index) => (
                <Item key={index} data={item} />
              ))}
            </ReactOwlCarousel>
          )}
        </div>
      </section>

      <section id="eventos" className="my-5">
        <div className="container">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item ">
              <h2 class="accordion-header text-center" id="flush-headingOne">
                <button
                  class="accordion-button collapsed bg-white text-dark fs-5 w-100 d-flex jusitfy-content-center"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <div className="w-100 text-center">Espectáculos anteriores</div>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                {!dataSala ? (
            ""
          ) : (
            <ReactOwlCarousel
              style={{ zIndex: "0" }}
              className="owl-theme"
              {...options_eventos}
            >
              {dataSala.historial_eventos.map((item, index) => (
                <Item key={index} data={item} />
              ))}
            </ReactOwlCarousel>
          )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="eventos" className="my-5">
        <div className="container">
                {
                  dataSala
                  ?<iframe
                  src={
                    "https://maps.google.com/?q=" +
                    encodeURI(
                      dataSala.direccion +
                        " " +
                        dataSala.provincia +
                        " " +
                        dataSala.localidad
                    ) +
                    "&z=14&t=m&output=embed"
                  }
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  height="400px"
                  className="my-3 w-100"
                ></iframe>
                  : ""
                }
        </div>
      </section>

      
    </div>
  );
};

export default Sala;
