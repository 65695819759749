import { useState, useContext } from "react";
//Custom hooks
import useApi from "../../../../App/Hooks/useApi";
//Date format
import { format, add, sub } from "date-fns";
//Librerias
import ReactHTMLTableToExcel from "react-html-table-to-excel";
//
import { authContext } from "../../../../context/auth/AuthContext";
import {
  fileDateReportEvents,
  totalReportEvents,
} from "../../../../Functions/FunctionReportes";
import FileReport from "./Cards/FileReport";
import Filtro from "./Filtro";
import { CollectionStateContext } from "../../../../context/EventosState";
import ModalInfoDecidir from "../../Modals/ModalInfoDecidir";
import ModalAddReporte from "./Modals/ModalAddReporte";
import TablaEventos from "./Tablas/TablaEventos";
import ModalInformeMes from "./Modals/ModalInformeMes";
import { totalReportCuenta } from "../../../../Functions/FunctionInformes";
import ModalDetalle from "./Modals/ModalDetalle";
import { ActualizarReporte, PDFliquidado, ReeEmail } from "../../../../helpers/ReporteHelper";
import { convertMonth } from "../../../../Functions/FunctionTickets";
import AlertWarning from "../../../../Alerts/AlertWarning";

const Reportes = () => {
  const { user } = useContext(authContext);
  const { rol, status, auth } = user;

  const context = useContext(CollectionStateContext);
  const { state } = context;

  //states
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [search_decidir, setSearchDecidir] = useState("");
  const [vista, setVista] = useState({
    tipo: "vista_tabla",
  });

  /* 
    vista_tabla
    vista_box
  */

  //date range

  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mes = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const [fecha, onFecha] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  //modals
  const [modal_informe_mes, setModalInformeMes] = useState(false);
  const [modal_decidir, setModalDecidir] = useState(false);
  const [modal_add_reporte, setModalAddReporte] = useState(false);
  const [modal_detalle, setModalDetalle] = useState(false);
  const [modal_liquidar, setModalLiquidar] = useState(false);

  //provincias

  const LSProvincias = localStorage.getItem("provincias");

  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );

  //formularios
  const [form, setForm] = useState({
    //Api decidir
    api_establecimiento_id: "",
  });

  //Parametros
  const [filtro, setFiltro] = useState({
    status: "",
    mes: "",
  });

  const [initialStateParams, setInitialStateParams] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState({
    id: "",
    search: "",
    sala: "",
    status: "1",
    bill: "1",
    date_range: [
      format(
        add(new Date(), { months: -12 }),
        "yyyy/MM/dd"
      ).toString(),
      format(new Date(), "yyyy/MM/dd").toString(),
    ],
    /*[
      format(new Date(), "yyyy/MM/dd").toString(),
      format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
    ],
    */
    provincia: "",
    //static
    url: "api/auth/Eventos/All",
    pagination: "1000",
  });

  //eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      //reportes
      case "comprobante_liquidacion":{
        let reportes_id = [];

        let formData = new FormData();

        value.reportes.map((reporte, index) => {
          let isString = "id_reportes["+index+"]";

          formData.append(isString, reporte.id);
        })

        
        formData.append('id_evento', value.evento.id);
        formData.append('fecha', value.fecha);

        const response = await PDFliquidado(formData, setLoading);

        let binaryString = atob(response);

        let binaryLen = binaryString.length;
        
        let bytes = new Uint8Array(binaryLen);
        
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        
        let blob = new Blob([bytes], {type: "application/pdf"});
        
        let link = document.createElement('a');
        
        let url = window.URL.createObjectURL(blob);
        
        return window.open(url, "_blank");

      }
      break;
      case "liquidar_reportes":{

        let dataForm = new FormData();
        let stringId;
        let hoy = format(new Date(), "yyyy/MM/dd").toString();
        let state = false;

        value.reportes.map((reporte, index) => {
          let fecha = (reporte.fecha.split(" "));
          fecha = fecha[5]+"/"+convertMonth(fecha[3])+"/"+fecha[1];

          console.log(fecha);
          console.log(hoy);
          
          if(new Date(fecha) >= new Date(hoy)){
            state = true;
          } else {
            stringId = "id[" + index + "]";
            dataForm.append(stringId, reporte.id);
          }
        })

        dataForm.append('id_evento', value.evento.id);
        dataForm.append('fecha', value.fecha);

        if(state){
          return AlertWarning("El reporte no se puede liquidar porque la fecha del evento no paso.","Advertencia");
        }

        dataForm.append("status", "LIQUIDADO"); 
        const response = await ActualizarReporte(dataForm, setLoading);  

        setSteps(steps+1);

      }
      break;
      case "reenvio_email":{
        let reportes_id = [];

        let formData = new FormData();

        value.reportes.map((reporte, index) => {
          let isString = "id_reportes["+index+"]";

          formData.append(isString, reporte.id);
        })

        formData.append('id_evento', value.evento.id);
        formData.append('fecha', value.fecha);

        const response = await ReeEmail(formData, setLoading);

        setSteps(steps+1);
      }
      break;
      //label
      case "vista_tabla":
      case "vista_box":
        {
          setVista({
            tipo: e.target.name,
          });
        }
        break;
      //modals
      case "search_decidir":
        {
          setForm({
            ...form,
            api_establecimiento_id: search_decidir,
          });
        }
        break;
      case "add_reporte":
        {
          setModalAddReporte(true);
        }
        break;
      case "info_decidir":
        {
          setModalDecidir(true);
        }
        break;
      case "informe_mes":
        {
          setModalInformeMes(true);
        }
        break;
      case "liquidar":
        {
        }
        break;
      case "detalle":
        {
          setModalDetalle(value);
        }
        break;
      case "close":
        {
          setModalDetalle(false);
          setModalLiquidar(false);
          setModalDecidir(false);
          setModalAddReporte(false);
          setModalInformeMes(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search_decidir":
        {
          setSearchDecidir(e.target.value);
        }
        break;
      case "clasificar_eventos":
        {
          switch (e.target.value) {
            case "anteriores":
              {
                let array = [
                  format(
                    add(new Date(), { months: -12 }),
                    "yyyy/MM/dd"
                  ).toString(),
                  format(new Date(), "yyyy/MM/dd").toString(),
                ];

                setParams({
                  ...params,
                  date_range: array,
                });

                //recharge
                setSteps(steps + 1);
              }
              break;
            case "proximos":
              {
                let array = [
                  format(new Date(), "yyyy/MM/dd").toString(),
                  format(
                    add(new Date(), { months: 12 }),
                    "yyyy/MM/dd"
                  ).toString(),
                ];

                setParams({
                  ...params,
                  date_range: array,
                });

                //recharge
                setSteps(steps + 1);
              }
              break;
          }
        }
        break;
      //params
      case "status":
      case "provincia":
        {
          setParams({
            ...params,
            [e.target.name]: e.target.value,
          });

          setSteps(steps + 1);
        }
        break;
      case "search":
        {
          if (e.target.value.length < initialStateParams.search.length) {
            setInitialStateParams({
              ...initialStateParams,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateParams({
              ...initialStateParams,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3 && !loading) {
              setLoading(true);

              let expect = setTimeout(() => {
                setParams({
                  ...params,
                  [e.target.name]: e.target.value,
                });

                //recharge
                setSteps(steps + 1);

                setLoading(false);
              }, 2000);
            }
          }
        }
        break;
      //filtro de reportes
      case "clasificar_reportes":
        {
          setFiltro({
            ...filtro,
            status: e.target.value,
          });
        }
        break;
    }
  };

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);

  return (
    <>
      {data ? (
        <div
          className="container-md texto-normal"
          style={{ maxWidth: "3000px" }}
        >
          <div className="row">
            <div class="col-md-3 mb-3">
              <h1>Cuentas</h1>
              <span class="text-muted">Estado de tus cuentas.</span>
            </div>

            <div className="col-md-3 mb-3">
              <div
                className="bg-white p-3 text-center"
                style={{ borderRadius: "20px" }}
              >
                <div className="d-block fw-bold text-muted">
                  Total de ventas
                </div>
                <div>
                  <span className="fs-6">$</span>{" "}
                  {totalReportCuenta(data.data, filtro).total_pagos}
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div
                className="bg-white p-3 text-center"
                style={{ borderRadius: "20px" }}
              >
                <div className="d-block fw-bold text-muted">
                  Total de entradas
                </div>
                <div>
                  {" "}
                  {totalReportCuenta(data.data, filtro).total_cantidad}
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div
                className="bg-white p-3 text-center"
                style={{ borderRadius: "20px" }}
              >
                <div className="d-block fw-bold text-muted">
                  Total de descuento
                </div>
                <div>
                  <span className="fw-bold text-muted">Bruto</span> ${" "}
                  {totalReportCuenta(data.data, filtro).total_bruto}
                </div>
                <div>
                  <span className="fw-bold text-muted">Descontado</span> ${" "}
                  {totalReportCuenta(data.data, filtro).total_descuento}
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <Filtro
                rol={rol}
                onClick={onClick}
                onChange={onChange}
                loading={loading}
                provincias={dataProvincias}
                params={params}
                setParams={setParams}
                fecha={fecha}
                onFecha={onFecha}
                steps={steps}
                setSteps={setSteps}
              />
            </div>

            <div className="col-md-12 text-center">
              <span className="fs-2 text-muted fw-bold opacity-50">
                Desde {params.date_range[0]} hasta {params.date_range[1]}
              </span>
            </div>

            {
              //Vistas box
              vista.tipo == "vista_box" ? (
                <div className="col-md-12">
                  <div className="row">
                    {fileDateReportEvents(data.data, filtro).map((evento) => (
                      <div className="col-md-12 col-12">
                        <div className="row">
                          {evento.map((item) => (
                            <FileReport
                              data={item}
                              rol={rol}
                              onClick={onClick}
                              onChange={onChange}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                    <div className="col-md-12 d-none">
                      <TablaEventos
                        data={fileDateReportEvents(data.data, filtro)}
                        total={data}
                        filtro={filtro}
                        rol={rol}
                        onClick={onClick}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              ) : //vista tabla
              vista.tipo == "vista_tabla" ? (
                <div className="col-md-12">
                  <TablaEventos
                    data={fileDateReportEvents(data.data, filtro)}
                    total={data}
                    filtro={filtro}
                    rol={rol}
                    onClick={onClick}
                    onChange={onChange}
                  />
                </div>
              ) : (
                <div></div>
              )
            }
          </div>

          {modal_decidir ? (
            <ModalInfoDecidir
              onClick={onClick}
              onChange={onChange}
              data={form}
            />
          ) : (
            ""
          )}

          {modal_liquidar ? "" : ""}

          {modal_detalle ? (
            <ModalDetalle
              data={modal_detalle}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            ""
          )}

          {modal_add_reporte ? (
            <ModalAddReporte
              onClick={onClick}
              onChange={onChange}
              form={form}
              setForm={setForm}
            />
          ) : (
            ""
          )}

          {modal_informe_mes ? (
            <ModalInformeMes onClick={onClick} onChange={onChange} />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div class="d-flex justify-content-center  align-items-center min-vh-100">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Reportes;
