import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const CrearEvento = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Eventos/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)
    return (window.location.href = "/dashboard/eventos/editar/" + result.data.data.id);
  }).catch((result) => {
    setLoading(false);
    return AlertError("Ocurrio un error con la carga de su evento","Error");
  });

  return response
}