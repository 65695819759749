const ModalEditarStatusReporte = ({onChange, onClick, onSave, data}) => {

  return (
    <div class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{zIndex: 104}}
      >
      
    <div class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal" style={{maxWidth: "800px"}}>
      
    <div className="modal-content bg-light">
      <div class="title bg-white p-4 m-0 d-flex justify-content-between">
        <div>
          <h1>Modificar estado</h1>
        <span class="fs-6 text-muted">Modifica el estado de los reportes, el reporte se encontraria definido por el tipo de estado. </span>
        </div>
        <div>
          <button class="border-0 bg-transparent" name="close_modal" onFocus={(event) => onClick(event, null)}><i class="bi bi-x-lg"></i></button>
        </div>
      </div>
      <div class="body p-md-4 p-2">

        <div class="bg-white p-4 mb-3">
          <select class="form-select border-custom-naranja rounded-pill" aria-label="Default select example" name="status" onChange={(event) => onChange(event)}>
            <option selected>Seleccionar nuevo estado</option>
            <option value="ACTIVO">ACTIVAR</option>
            <option value="REEMBOLSO">REEMBOLSAR</option>
            <option value="INACTIVO">INACTIVO</option>
            <option value="RECHAZADO">RECHAZADO</option>
            <option value="LIQUIDADO">LIQUIDAR</option>
            <option value="PAUSADO">PAUSAR</option>
          </select>

          {
            data[0].status == "ACTIVO"?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">ACTIVO</span> <span class="text-muted"> Reporte activo a la espera de ser liquidado.</span>
          </div>
          :
          data[0].status == "REEMBOLSO"?
          <div class="my-2">
          <span class="text-muted">Estado actual </span> <span class="fw-bold">REEMBOLSO</span> <span class="text-muted"> Reporte reembolsado al usuario cliente, no se muestra en los reportes de la Sala.</span>
        </div>
            :
            data[0].status == "INACTIVO"?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">INACTIVO</span> <span class="text-muted"> Reporte con motivo de error sin reembolsar, no se muestra en los reportes de la Sala.</span>
          </div>
          :
          data[0].status == "PAUSADO"?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">PAUSADO</span> <span class="text-muted"> Reporte a espera de aprobación o con motivos para ser pausado, este reporte si se muestra en la sala con su motivo de estado.</span>
          </div>
          :
          data[0].status == "RECHAZADO"?
          <div class="my-2">
          <span class="text-muted">Estado actual </span> <span class="fw-bold">RECHAZADO</span> <span class="text-muted"> Reporte rechazado por el usuario o la sala, no se muestra en los reportes de la Sala.</span>
        </div>
        :
        data[0].status == "LIQUIDADO"?
        <div class="my-2">
        <span class="text-muted">Estado actual </span> <span class="fw-bold">LIQUIDADO</span> <span class="text-muted"> Reporte liquidado para la sala, se muestran en los reportes de la sala con su motivo de estado.</span>
      </div>
      : ""
          }

          
        </div>

        <div className="bg-white p-4 ">  
          <label htmlFor="" className="text-muted fs-6 mb-1">
            Escribe el motivo del cambio de estado(opcional)
          </label>
          <textarea className="form-control border-custom-naranja" name="motivo" onChange={(event) => onChange(event)}></textarea>

        </div>

      </div>
      <div class="footer bg-white p-4">
        <div class="d-flex justify-content-between">
          <div></div>
          <div class="">
          <button class="btn btn-secondary rounded-pill px-4" name="close_modal" onClick={(event) => onClick(event, null)}>Cerrar</button>
          <button class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4" onClick={(event) => onSave(event, data)}>Aplicar</button>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>

  )
}

export default ModalEditarStatusReporte