import { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import { registro } from "../../../helpers/AccessHelper";
import { obtenerProvincias, obtenerMunicipios } from "../../../helpers/NormGeograficoHelper"

const validationPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

const AccesRegister = () => {


  const [apiProvincia, setApiProvincia] = useState(null);
  const [apiLocalidad, setApiLocalidad] = useState({
    class: "d-none",
    content: null,
  });


  const [alert, setAlert] = useState({
    msg: "",
    class: "d-none",
  });

  const [pass, setPass] = useState({
    icon: "bi bi-eye-slash",
    type: "password",
    status: false
  });

  const [formGlobal, setFormGlobal] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    direccion: "",
    provincia: "",
    localidad: "",
    password: "",
    password_confirmation: ""
  });

  const showPassword = (e) => {
    e.preventDefault();

    if (pass.status === false) {
      setPass({
        ...pass,
        icon: "bi bi-eye",
        type: "text",
        status: true
      });
    } else {
      setPass({
        ...pass,
        icon: "bi bi-eye-slash",
        type: "password",
        status: false
      });
    }

  }

  const onChange = (e) => {

    switch (e.target.name) {
      case "direccion":
      case "password_confirmation":
      case "password":
      case "email":
      case "telefono":
      case "apellido":
      case "provincia":
      case "nombre": {
        setFormGlobal({
          ...formGlobal,
          [e.target.name]: e.target.value,
        });
      }
        break;
      case "Selectlocalidad": {
        setFormGlobal({
          ...formGlobal,
          localidad: e.target.value,
        });

        setApiLocalidad({
          class: "d-none",
          content: null,
        })
      }
        break;
      case "SearchLocalidad": {
        setFormGlobal({
          ...formGlobal,
          localidad: e.target.value,
        });

        const axios = async () => {
          let result = await obtenerMunicipios(formGlobal.provincia, e.target.value);
          setApiLocalidad({
            class: "form-control",
            content: result
          });
        }
        axios();
      }
        break;
    }



  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      formGlobal.nombre.trim() === "" ||
      formGlobal.apellido.trim() === "" ||
      formGlobal.email.trim() === ""  ||
      formGlobal.telefono.trim() === "" 
      //formGlobal.direccion.trim() === "" ||
      //formGlobal.provincia.trim() === "" ||
      //formGlobal.localidad.trim() === ""
    ) {
      return setAlert({
        msg: "Todos los campos son obligatorios",
        class: "w-100 alert alert-danger text-start my-1"
      });
    };

    if (!validationPassword.test(formGlobal.password)) {
      return setAlert({
        msg: "Las contraseñas debe contener al menos una mayúscula (A-Z) y un número (0-9) y tienen que estar conformadas con 6 o más caracteres.",
        class: "w-100 alert alert-danger text-start my-1"
      });
    };

    if (formGlobal.password.trim() !== formGlobal.password_confirmation.trim()) {
      return setAlert({
        msg: "Las contraseñas no coinciden.",
        class: "w-100 alert alert-danger text-start my-1"
      });
    };

    let dataForm = new FormData();

    dataForm.append('nombre', formGlobal.nombre);
    dataForm.append('apellido', formGlobal.apellido);
    dataForm.append('email', formGlobal.email);
    dataForm.append('telefono', formGlobal.telefono);
    dataForm.append('direccion', formGlobal.direccion);
    dataForm.append('provincia', formGlobal.provincia);
    dataForm.append('localidad', formGlobal.localidad);
    dataForm.append('password', formGlobal.password);
    dataForm.append('password_confirmation', formGlobal.password_confirmation);

    registro(dataForm)

  }

  useEffect(() => {
    const axios = async () => {
      let result = await obtenerProvincias()
      setApiProvincia(result);
    }
    axios()
  }, [])

  return (
    <div className="container-md texto-normal min-vh-100 d-flex justify-content-center">

      <form action="" className="width-md py-5" >

        <div className="text-center mb-3">
          <h1>Obtener cuenta</h1>
        </div>

        <div className="mb-3">
          <label for="inputNombre" className="form-label">Nombre</label>
          <input
            type="text"
            className="form-control rounded-pill"
            id="inputNombre"
            placeholder="Ingrese su Nombre"
            name="nombre"
            onChange={(event) => onChange(event)}
          />
        </div>

        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">Apellido</label>
          <input
            type="text"
            className="form-control rounded-pill"
            id="exampleFormControlInput1"
            placeholder="Ingrese su Apellido"
            name="apellido"
            onChange={(event) => onChange(event)}
          />
        </div>

        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">Email</label>
          <input
            type="email"
            className="form-control rounded-pill"
            id="exampleFormControlInput1"
            placeholder="Ingrese su Email"
            name="email"
            onChange={(event) => onChange(event)}
          />
        </div>


        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">Telefono</label>
          <div className="input-group">
          <div
              className="input-group-text btn px-2  btn-custom-naranja text-white"
              id="basic-addon1"
              onClick={(event) => showPassword(event)}
            >
             +54
            </div>
            <input
            type="number"
            className="form-control col-md-9 rounded-start rounded-pill"
            id="exampleFormControlInput1"
            placeholder="Ingrese su número de Telefono"
            name="telefono"
            onChange={(event) => onChange(event)}
          />
          </div>
        </div>

        <ul className="list-group mb-3">
          <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">
             Ingrese el número solamente con la caracteristica urbana.
          </li>
        </ul>

        <div className="mb-3 d-none">
          <label for="exampleFormControlInput1" className="form-label">Direccion</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Ingrese su Direccion"
            name="direccion"
            onChange={(event) => onChange(event)}
          />
        </div>

        <div className="mb-3 d-none">
        <label htmlFor="">Provincia</label>
        <select
          className="form-select mb-3"
          aria-label="Default select example"
          name="provincia"
          onChange={(event) => onChange(event)}
        >
          <option selected>Seleccion una provincia</option>

          {
            !apiProvincia ?

              <option value="null">...</option>

              :

              apiProvincia.map((provincia) =>
              (
                <option value={provincia.nombre}>{provincia.nombre}</option>
              ))

          }

        </select>
        </div>

        {
          formGlobal.provincia == "" ?
            ""
            :
            <div className="mb-3">
              <label htmlFor="">Localidad</label>
              <input className="form-control" type="text" value={formGlobal.localidad} name="SearchLocalidad" onChange={(event) => onChange(event)} autocomplete="nope" />
              <div className="overflow-hidden">
                {
                  !apiLocalidad.content ?

                    ""
                    :

                    <ul class="list-group border-0 border-end border-start border-bottom">
                    {
                    apiLocalidad.content.map((municipios) => (
                      <li class="list-group-item border-0 p-0"><button className="bg-transparent border-0 w-100" name="Selectlocalidad" value={municipios.nombre} onClick={(event) => onChange(event)}>{municipios.nombre}</button></li>
                    ))
                    }
                    </ul>

                }
              </div>
            </div>
        }

        <div className="mb-3">
          <label for="password" className="form-label">Contraseña</label>
          <div class="input-group mb-3">
            <button
              className="input-group-text btn-custom-naranja text-white border-0 px-3"
              id="basic-addon1"
              onClick={(event) => showPassword(event)}
            >
              <i className={pass.icon}></i>
            </button>
            <input
              type={pass.type}
              class="form-control rounded-start rounded-pill"
              placeholder="Ingrese su contraseña"
              aria-label="Password"
              aria-describedby="basic-addon1"
              name="password"
              onChange={(event) => onChange(event)}
            />
          </div>
        </div>

        <ul className="list-group mb-3">
          <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">
            Debe contener al menos una mayúscula (A-Z) y un número (0-9)
          </li>
          <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">Tiene que estar conformado con 6 o más caracteres</li>
        </ul>

        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">Confirmar contraseña</label>
          <div class="input-group mb-3">
            <button
              className="input-group-text btn-custom-naranja text-white border-0 px-3"
              id="basic-addon1"
              onClick={(event) => showPassword(event)}
            >
              <i className={pass.icon}></i>
            </button>
            <input
              type={pass.type}
              class="form-control rounded-start rounded-pill "
              placeholder="Confirme su contraseña"
              aria-label="Password"
              aria-describedby="basic-addon1"
              name="password_confirmation"
              onChange={(event) => onChange(event)}
            />
          </div>
        </div>

        <button
          name="registro"
          className="btn btn-custom-amarillo w-100 mb-3 "
          onClick={(event) => onSubmit(event)}
        >
          Registrarse
        </button>

        
        <div className="w-100 text-center">
        <Link className="text-decoration-none d-block" to="/TYP"><span className="text-dark">Al registrarse acepta nuestros</span> Terminos y Condiciones</Link>
          <Link className="text-decoration-none" to="/ingresar"><span className="fw-light">¿Ya tienes una cuenta?</span><span className="fw-normal"> Ingresa.</span></Link>
        </div>

        <div className={alert.class}>
          <p className="fw-bold">Atencion.</p>
          <p>{alert.msg}</p>
        </div>

      </form>

    </div>
  );
}

export default AccesRegister;