import { Link } from "react-router-dom";
import ComprarEntradas from "../components/evento/ComprarEntradas";

const Entrada = ({
  setStep,
  step,
  entradas,
  onClick,
  comission,
  form,
  evento,
  label
}) => {
  return (
    <main className="m-0" style={{ paddingTop: "100px" }}>
      <section id="ticket-info" className="bg-gris-claro pt-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Link
                href=""
                className="btn btn-outline-dark py-3"
                style={{ borderRadius: "20px" }}
              >
                <i className="bi bi-chevron-left"></i>
                Volver
              </Link>
            </div>
            <div className="col-md-6 wow bounceInLeft d-none d-md-block">
              <div
                className="border h-100"
                style={{
                  backgroundImage: "url(/assets/img/img-teatro-01.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "20px",
                }}
              ></div>
            </div>
            <div className="col-md-6">
              <p
                className="bg-naranja text-white px-3 py-2 d-inline-block wow bounceInRight"
                style={{ borderRadius: "20px" }}
              >
                Teatro
              </p>
              <h3 className="GothamBold text-uppercase wow bounceInUp">
                Mi Nombre es Eva Duarte
              </h3>
              <h4 className="text-uppercase mb-2 wow bounceInUp">
                Grupo el Cuenco
              </h4>
              <div className="border d-block d-md-none">
                <img
                  src="/static/img/local/evento.png"
                  className="img-fluid"
                  style={{ borderRadius: "20px" }}
                  alt=""
                />
              </div>
              <span style={{ fontSize: "10px" }}>Proxima función</span>
              <p className="text-uppercase wow bounceInUp">
                Viernes, 25 de mayo 22hrs
              </p>
              <ul className="list-group mt-3 wow bounceInUp">
                <span className="fw-bold mb-2" style={{ fontSize: "10px" }}>
                  LUGAR
                </span>
                <li className="GothamLight text-uppercase d-flex mb-2 fw-bold">
                  <i className="fa-solid fa-masks-theater me-2 fs-5"></i>
                  EL CUENCO TEATRO
                </li>
                <span className="fw-bold mb-2" style={{ fontSize: "10px" }}>
                  UBICACIÓN
                </span>
                <li className="GothamLight text-uppercase d-flex mb-2 fw-bold">
                  <i className="bi bi-geo-alt-fill me-2 fw-bold"></i>
                  Mendoza 2063, Córdoba, Córdoba
                </li>
              </ul>
              <span className="fw-bold" style={{ fontSize: "10px" }}>
                PRECIO DESDE
              </span>
              <div className="fs-3 fw-bold text-custom-naranja">
                $ 1.700{" "}
                <span className="fs-6 fw-normal">(+15% por compra online)</span>
              </div>
              <div>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item border-0 fw-bold bg-transparent">
                    <i className="bi bi-clock me-2"></i>
                    70 min
                  </li>
                  <li className="list-group-item border-0 fw-bold bg-transparent">
                    <i className="bi bi-person-standing me-2"></i>
                    ADULTOS
                  </li>
                  <li className="list-group-item border-0 fw-bold bg-transparent">
                    <i className="bi bi-tags me-2"></i>
                    TEATRO, TITERES, OTRO
                  </li>
                </ul>
              </div>
              <button
                className="fs-3 btn btn-custom-amarillo mt-3 wow bounceInUp py-3 w-100"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                comprar entradas
              </button>

              <ComprarEntradas
              setStep={setStep}
              step={step}
              entradas={entradas}
              onClick={onClick}
              comission={comission}
              form={form}
              evento={evento}
              fechas={evento.fechas}
              label={label}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="ticket-detail" className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5 wow bounceInUp">
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat, vel
                illum dolore eu feugiat nulla facilisis at vero eros et accumsan
                et iusto odio dignissim qui blandit praesent luptatum zzril
                delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum
                dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                nibh euismod tincidunt ut laoreet dolore magna aliquam erat
                volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
                tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
                commodo consequat. Lorem ipsum dolor sit amet, consectetuer
                adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                minim veniam, quis nostrud exerci tation ullamcorper suscipit
                lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel
                eum iriure dolor in hendrerit in vulputate velit esse molestie
                consequat, vel illum dolore eu feugiat nulla facilisis at vero
                eros et accumsan et iusto odio dignissim qui blandit praesent
                luptatum zzril delenit augue duis dolore te feugait nulla
                facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat, vel illum dolore eu feugiat nulla facilisis
                at vero eros et accumsan et iusto odio dignissim qui blandit
                praesent luptatum zzril delenit augue duis dolore te feugait
                nulla facilisi.
              </p>
            </div>
            <div className="col-6 position-relative wow bounceInUp">
              <h3>FICHA TÉCNICA</h3>
              <p className="mb-2">
                <strong>lorem:</strong> ipsum
              </p>
              <p className="mb-2">
                <strong>lorem:</strong> ipsum
              </p>
              <p className="mb-2">
                <strong>lorem:</strong> ipsum
              </p>
              <p className="mb-2">
                <strong>lorem:</strong> ipsum
              </p>
            </div>
            <div className="col-6 position-relative wow bounceInUp">
              <h3>LINKS</h3>
              <div className="mb-3">
                <div className="fw-bold">NOTA DIARIA</div>
                <a href="#" className="mb-2">
                  https://
                </a>
              </div>
              <div className="mb-3">
                <div className="fw-bold">NOTA DIARIA</div>
                <a href="#" className="mb-2">
                  https://
                </a>
              </div>
            </div>
            <div className="col-12 wow bounceInUp mt-5 mb-5">
              <h3>trailers</h3>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/JWRlTezTF2k?controls=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Entrada;
