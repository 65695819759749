import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ModalEditarSala = ({
  onChange,
  onClick,
  onSave,
  loading,
  data,
  setForm,
  form,
}) => {
  const imagenes = [
    data.imagen_desc_1,
    data.imagen_desc_2,
    data.imagen_desc_3,
    data.imagen_int_1,
    data.imagen_int_2,
    data.imagen_int_3,
  ];

  return (
    <div
      className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: "104" }}
    >
      <div
        className="bg-light modal-dialog position-relative animation-modal my-md-5 overflow-hidden texto-normal"
        style={{ maxWidth: "1000px", zIndex: "106" }}
      >
        <div className="modal-content bg-light">
          <div className="title bg-white p-4 m-0 d-flex justify-content-between">
            <div>
              <h1>Sala</h1>
              <span className="fs-6 text-muted">
                Edita la sala, y puedes dejar una notificacion de los cambios
                realizados.{" "}
                <button className="btn btn-warning rounded-pill text-white p-0 m-0 px-2">
                  Notificación
                </button>
              </span>
            </div>
            <div>
              <button
                className="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="body p-md-4 p-2">
            <ul className="list-group">
              <li
                className="list-group-item d-flex justify-content-between m-0  border-0 shadow-sm"
                style={{ borderRadius: "10px 10px 0px 0px" }}
              >
                <div></div>
                <button
                  className="btn  btn-custom-naranja text-white fw-normal GothamBold  fs-9 rounded-pill m-0 py-1 px-3 pb-1 text-nowrap"
                  disabled
                >
                  <i className="bi bi-pencil "></i> Editar{" "}
                </button>
              </li>
              <li
                className="list-group-item d-md-flex m-0 mb-3 border-0 shadow-sm"
                style={{ borderRadius: "0px 0px 10px 10px" }}
              >
                <label for="formImagenLogo" className="form-label">
                  <div
                    className="btn p-0 border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                    style={{
                      width: "300px",
                      height: "300px",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={
                        form.imagen_logo_url
                          ? form.imagen_logo_url
                          : "/assets/img/usuarios/" + data.imagen_logo
                      }
                      className="img-fluid"
                      alt="IMAGEN NO ENCONTRADA"
                    />
                    <div className="img-edit-pencil">
                      <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                      <span className="fs-7 d-block">
                        Formatos admitidos JPG PNG JPEG{" "}
                      </span>
                      <span className="fs-7">
                        Dimension sugerida 500x500 72DPI{" "}
                      </span>
                    </div>
                  </div>
                </label>
                <input
                  className="form-control d-none"
                  type="file"
                  id="formImagenLogo"
                  name="imagen_logo"
                  onChange={(event) => onChange(event)}
                />
                <div className="p-3">
                  <div className="text-muted fs-6 mb-1">
                    Nombre{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.nombre}
                      name="nombre"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  <div className="text-muted fs-6 mb-1">
                    Razon Social{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.razon_social}
                      name="razon_social"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  <div className="text-muted fs-6 mb-1">
                    Email
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-0 border-0 bg-white"
                      type="email"
                      defaultValue={data.email}
                      disabled
                    />
                  </div>
                  <div className="text-muted fs-6 mb-1">
                    Telefono{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="number"
                      defaultValue={data.telefono}
                      name="telefono"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  <div className="text-muted fs-6 mb-1 d-flex">
                    <div>
                      Dirección{" "}
                      <input
                        className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                        type="text"
                        defaultValue={data.direccion}
                        name="direccion"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="ms-3">
                      Provincia{" "}
                      <input
                        className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                        type="text"
                        defaultValue={data.provincia}
                        name="provincia"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                  </div>
                </div>
              </li>

              <li
                className="list-group-item m-0 mb-3 py-4 border-0 shadow-sm"
                style={{ borderRadius: "10px;" }}
              >
                <div className=" w-100 fs-5 fw-bold border-bottom d-block mb-3">
                  Links Sociales
                  <span className="fs-6 text-muted fw-normal d-block">
                    Ingresa las URLs de tus principales redes sociales.
                  </span>
                </div>
                <div className="d-md-flex justify-content-between">
                  <div className="text-muted fs-8 mb-1">
                    URL Facebook{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.url_facebook}
                      name="url_facebook"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  <div className="text-muted fs-8 mb-1">
                    URL Instagram{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.url_instagram}
                      name="url_instagram"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                  <div className="text-muted fs-8 mb-1">
                    URL Sitio Web{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.website}
                      name="website"
                      onChange={(event) => onChange(event)}
                    />
                  </div>
                </div>
              </li>

              <li
                className="list-group-item py-4 m-0 mb-3 px-0 pb-0 border-0 shadow-sm"
                style={{ borderRadius: "10px " }}
              >
                <div className=" w-100 fs-5 fw-bold d-block mb-3 px-2">
                  Descripción
                  <span className="fs-6 text-muted fw-normal d-block">
                    Escribe una reseña o descripción de tu sala.
                  </span>
                </div>

                <div className="" style={{ height: "auto" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data.descripcion}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log();
                    }}
                    onChange={(event, editor) => {
                      //const data = editor.getData();
                      setForm({
                        ...form,
                        descripcion: editor.getData(),
                      });
                    }}
                    onBlur={(event, editor) => {
                      console.log();
                      //console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                      console.log();
                      //console.log( 'Focus.', editor );
                    }}
                  />
                </div>
              </li>

              <li
                className="list-group-item py-4  m-0 mb-3 border-0 shadow-sm"
                style={{ borderRadius: "10px" }}
              >
                <div className="w-100 fs-5 fw-bold border-bottom d-block mb-3">
                  Galeria de imagenes
                  <span className="fs-6 text-muted fw-normal d-block">
                    Sube imagenes que muestren lo mejor de tu Sala.
                  </span>
                </div>
                <div className="row w-100 border-0 bg-white mb-3 m-0 p-md-2 p-0">
                  {imagenes.map((imagen, index) => (
                    <div className="col-md-4 col-12 d-flex justify-content-center mb-2 border-0 py-2">
                      <label for={"formImg_" + index} className="form-label">
                        <div
                          className="btn border-0 d-flex align-items-center position-relative overflow-hidden w-100"
                          style={{ height: "200px" }}
                        >
                          <img
                            src={
                              form["imagen_" + (index + 1) + "_url"]
                                ? form["imagen_" + (index + 1) + "_url"]
                                : "/assets/img/salas/" + imagen
                            }
                            className="img-fluid"
                            alt="IMAGEN NO ENCONTRADA"
                          />
                          <div className="img-edit-pencil">
                            <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                            <span className="fs-7 d-block">
                              Formatos admitidos JPG PNG JPEG{" "}
                            </span>
                          </div>
                        </div>
                      </label>

                      <input
                        className="form-control d-none"
                        type="file"
                        id={"formImg_" + index}
                        name={"imagen_" + (index + 1)}
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </div>
          <div className="footer bg-white p-4">
            <div className="d-flex justify-content-between">
              <div></div>
              <div className="">
                <button
                  className="btn btn-secondary rounded-pill px-4"
                  name="close_modal"
                  onClick={(event) => onClick(event, null)}
                >
                  Cerrar
                </button>
                <button
                  className="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                  onClick={(event) => onSave(event, data)}
                  disabled={
                    loading?
                    true
                    :
                    false
                  }
                >
                  {
                    loading?
                    <span className="spinner-grow spinner-grow-sm mx-1" role="status" aria-hidden="true"></span>
                    :
                    ""
                  }
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditarSala;
