import { useContext, useEffect, useState } from "react";
import AlertWarning from "../../../../Alerts/AlertWarning";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import { AdministradorCrearNotificacion } from "../../../../helpers/NotificacionHelper";
import { AdministradorActualizarSalas } from "../../../../helpers/SalaHelper";
import ModalEditarSala from "../../Modals/ModalEditarSala";
import ModalEditarStatus from "../../Modals/ModalEditarStatusUsuario";
import ModalHistorial from "../../Modals/ModalHistorial";
import ModalInforme from "../../Modals/ModalInforme";
import ModalNotificacion from "../../Modals/ModalNotificacion";
import ModalPlanilla from "../../Modals/ModalPlanilla";
import TableResponsive from "./Tables/TableResponsive";

const ControlSalas = () => {
  const { user } = useContext(authContext);
  const { data, rule, ruleUser, auth } = user;

  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    notificacion: false,
  });

  //QUERY STATEEVENT

  const context = useContext(CollectionStateContext);
  const { administradorSalas, provincias, state } = context;

  const localStorageProvincias = localStorage.getItem("provincias");
  const [dataProvincias, setProvincias] = useState(
    localStorageProvincias
      ? JSON.parse(localStorageProvincias)
      : state.provincias
  );

  const [dataSalas, setSalas] = useState(state.salasAdm);
  const [selectElement, setSelect] = useState(null);

  //FORM

  const [form, setForm] = useState({
    id: "",
    imagen_logo: "",
    nombre: "",
    razon_social: "",
    telefono: "",
    descripcion: "",
    direccion: "",
    provincia: "",
    localidad: "",
    usuario_estados_id: "",
    url_facebook: "",
    url_instagram: "",
    website: "",
    descripcion: "",

    imagen_1: "",
    imagen_2: "",
    imagen_3: "",
    imagen_4: "",
    imagen_5: "",
    imagen_6: "",
  });

  const [formNotification, setFormNotification] = useState({
    nombre_notificacion: "",
    descripcion_notificacion: "",
    tipo_notificacion: "",
    fecha_expiracion: "",
  });

  //MODALS CONTENT

  const [modal_planilla_user, setModalPlanillaUser] = useState(false);
  const [modal_edit_content, setModalEdit] = useState(false);
  const [modal_edit_status, setModalEditStatus] = useState(false);
  const [modal_notificacion, setModalNotificacion] = useState(false);
  const [modal_historial, setModalHistorial] = useState(false);
  const [modal_informe, setModalInforme] = useState(false);

  //CONST FILTER

  const [filter, setFilter] = useState({
    search: "",
    status: "",
    provincia: "",

    status_eventos: "",

    url: "/api/auth/Administrador/Salas",
    pagination: 30,
  });

  //TABLE RESPONSIVE CONTENT

  const campos = [
    "id",
    "Nombre",
    "Razon Social",
    "Email",
    "Telefono",
    "Direccion",
  ];

  //PAGINATION

  const nextPage = async () => {
    setFilter({
      ...filter,
      url: dataSalas.next_page_url,
    });
  };

  const prevPage = async () => {
    setFilter({
      ...filter,
      url: dataSalas.prev_page_url,
    });
  };

  const links = async (e, value) => {
    e.preventDefault();
    setFilter({
      ...filter,
      url: value,
    });
  };

  //BUTTON EVENT

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "planilla":
        {
          setModalPlanillaUser(true);
        }
        break;
      case "select_edit":
        {
          setSelect(value);
          setModalEdit(true);
        }
        break;
      case "select_status":
        {
          let formData = new FormData();

          formData.append("id_sala", value.content.id);
          formData.append("usuarios_estados_id", value.status);

          let response = await AdministradorActualizarSalas(formData, setLoading);

          setSteps(steps + 1);
          setLoading(response);
        }
        break;
      case "notificacion":
        {
          setSelect(value);
          setModalNotificacion(true);
        }
        break;
      case "historial":
        {
          setSelect(value);
          setModalHistorial(true);
        }
        break;
      case "select_informe":
        {
          setSelect(value);
          setModalInforme(true);
        }
        break;
      case "close_modal":
        {
          setModalEditStatus(false);
          setModalEdit(false);
          setModalNotificacion(false);
          setModalHistorial(false);
          setModalInforme(false);
          setModalPlanillaUser(false);
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      //FILTER
      case "f_provincia":
        {
          setFilter({
            ...filter,
            provincia: e.target.value,
          });
        }
        break;
      case "status":
      case "search":
        {
          setFilter({
            ...filter,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "usuario_estados_id":
      case "website":
      case "url_instagram":
      case "url_facebook":
      case "telefono":
      case "direccion":
      case "provincia":
      case "razon_social":
      case "nombre":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "imagen_1":
      case "imagen_2":
      case "imagen_3":
      case "imagen_4":
      case "imagen_5":
      case "imagen_6":
      case "imagen_logo":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            [e.target.name + "_url"]: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
      case "tipo_notificacion":
      case "descripcion_notificacion":
      case "fecha_expiracion":
      case "nombre_notificacion":
        {
          setFormNotification({
            ...formNotification,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const onSave = async (e, data) => {
    setSelect(data);

    let formData = new FormData();

    formData.append("id_sala", data.id);

    if (form.nombre.trim() !== "") {
      formData.append("nombre", form.nombre);
    }

    if (form.razon_social.trim() !== "") {
      formData.append("razon_social", form.razon_social);
    }

    if (form.telefono.trim() !== "") {
      formData.append("telefono", form.telefono);
    }

    if (form.direccion.trim() !== "") {
      formData.append("direccion", form.direccion);
    }

    if (form.localidad.trim() !== "") {
      formData.append("localidad", form.localidad);
    }

    if (form.provincia.trim() !== "") {
      formData.append("provincia", form.provincia);
    }

    if (form.url_facebook.trim() !== "") {
      formData.append("url_facebook", form.url_facebook);
    }

    if (form.url_instagram.trim() !== "") {
      formData.append("url_instagram", form.url_instagram);
    }

    if (form.website.trim() !== "") {
      formData.append("website", form.website);
    }

    if (form.descripcion.trim() !== "") {
      formData.append("descripcion", form.descripcion);
    }

    if (form.usuario_estados_id.trim() !== "") {
      formData.append("usuarios_estados_id", form.usuario_estados_id);
    }

    if (form.imagen_logo !== "") {
      formData.append("imagen_logo", form.imagen_logo);
    }

    if (form.imagen_1 !== "") {
      formData.append("imagen_1", form.imagen_1);
    }

    if (form.imagen_2 !== "") {
      formData.append("imagen_2", form.imagen_2);
    }

    if (form.imagen_3 !== "") {
      formData.append("imagen_3", form.imagen_3);
    }

    if (form.imagen_4 !== "") {
      formData.append("imagen_4", form.imagen_4);
    }

    if (form.imagen_5 !== "") {
      formData.append("imagen_5", form.imagen_5);
    }

    if (form.imagen_6 !== "") {
      formData.append("imagen_6", form.imagen_6);
    }

    let response = await AdministradorActualizarSalas(formData, setLoading);

    setSteps(steps + 1);
    setLoading(response);
    setModalEdit(false);
    setModalNotificacion(true);
  };

  const onSend = async (e) => {
    let dataForm = new FormData();

    if (
      formNotification.fecha_expiracion.trim() == "" ||
      formNotification.nombre_notificacion.trim() == "" ||
      formNotification.descripcion_notificacion.trim() == "" ||
      formNotification.tipo_notificacion.trim() == ""
    ) {
      setAlert({
        ...alert,
        notificacion: true,
      });
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    dataForm.append("id_user_destinatario", selectElement.usuarios.id);
    dataForm.append("nombre", formNotification.nombre_notificacion);
    dataForm.append("descripcion", formNotification.descripcion_notificacion);
    dataForm.append("tipo", formNotification.tipo_notificacion);
    dataForm.append("fecha_expiracion", formNotification.fecha_expiracion);

    const response = AdministradorCrearNotificacion(dataForm, setLoading);

    setModalNotificacion(false);
  };

  //EFFECT DOM

  useEffect(() => {
    administradorSalas(setSalas, filter);
  }, [steps, filter]);

  useEffect(() => {
    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, [dataProvincias]);
  return (
    <div className="row container-md me-0 ps-0 p-0 m-0 mx-md-auto texto-normal">
      <div className="col-md-12 col-12 d-flex justify-content-between m-0 p-0">
        <div className="fs-5"></div>
        <button
          className="btn btn-custom-naranja text-white fw-normal GothamBold me-0 mx-1 my-4 px-2 py-2 fs-10"
          disabled
        >
          <i className="bi bi-plus-lg"></i> Agregar Usuario
        </button>
      </div>
      <div className="col-md-6 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label text-muted fs-9 p-0 px-1 border-primary"
        >
          Realiza una busqueda rapida ingresando el email o nombre de tu sala.
        </label>
        <input
          type="text"
          className=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1"
          id="exampleFormControlInput1"
          placeholder="name@example.com"
          name="search"
          onChange={(event) => onChange(event)}
        />
      </div>
      <div className="col-md-2 col-12 m-0 p-0"></div>
      <div className="col-md-4 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label  text-muted fs-9 p-0 px-2 border-primary"
        >
          Filtrar según el estado de las salas.
        </label>
        <select
          className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
          aria-label="Default select example"
          name="status"
          onChange={(event) => onChange(event)}
        >
          <option selected>Según Estado: </option>
          <option value="1">Activos</option>
          <option value="3">Pausados</option>
          <option value="2">Inactivos</option>
        </select>
      </div>

      <div className="col-md-3 mb-3  text-center">
        <label
          for="exampleFormControlInput1"
          className=" form-label text-muted fs-9 p-0 px-2 border-0 text-nowrap"
        >
          Exportar planilla de salas
        </label>
        <button
          className="btn btn-outline-success w-100 fs-7 rounded-pill"
          name="planilla"
          onClick={(event) => onClick(event)}
        >
          Descargar planilla de salas
        </button>
      </div>

      <div className="col-md-5"></div>

      <div className="col-md-4 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label  text-muted fs-9 p-0 px-2 border-primary"
        >
          Filtrar según la provincia.
        </label>
        <select
          className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
          aria-label="Default select example"
          name="f_provincia"
          onChange={(event) => onChange(event)}
        >
          <option selected>{filter.provincia}</option>
          <option value="">Todas las provincias</option>
          {dataProvincias
            ? dataProvincias.map((provincia) => (
                <option value={provincia.nombre}>{provincia.nombre}</option>
              ))
            : ""}
        </select>
      </div>

      <div className="col-md-12 col-auto ">
        {dataSalas ? (
          <TableResponsive
            campos={campos}
            contents={dataSalas}
            onClick={onClick}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
          />
        ) : (
          ""
        )}

        {modal_edit_content ? (
          <ModalEditarSala
            onChange={onChange}
            onClick={onClick}
            onSave={onSave}
            loading={loading}
            data={selectElement}
            form={form}
            setForm={setForm}
          />
        ) : (
          ""
        )}

        {modal_edit_status ? (
          <ModalEditarStatus
            onClick={onClick}
            onChange={onChange}
            onSave={onSave}
            loading={loading}
            data={selectElement}
          />
        ) : (
          ""
        )}

        {modal_notificacion ? (
          <ModalNotificacion
            onClick={onClick}
            onChange={onChange}
            onSend={onSend}
            loading={loading}
            alert={alert}
            data={selectElement}
            formNotification={formNotification}
          />
        ) : (
          ""
        )}

        {modal_historial ? (
          <ModalHistorial
            onClick={onClick}
            data={selectElement.usuarios.historia}
          />
        ) : (
          ""
        )}

        {modal_informe ? (
          <ModalInforme onClick={onClick} data={selectElement} />
        ) : (
          ""
        )}

        {modal_planilla_user ? (
          dataSalas ? (
            <ModalPlanilla
              administradorUsuarios={administradorSalas}
              contents={dataSalas}
              onClick={onClick}
              onChange={onClick}
              filter={filter}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ControlSalas;
