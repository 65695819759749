import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import CardEvent from "../../Card/CardEvent";
import TableResponsive from "./Tables/TableResponsive";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import ModalEditarStatusReporte from "../../Modals/ModalEditarStatusReporte";
import { ActualizarReporte } from "../../../../helpers/ReporteHelper";
import AlertWarning from "../../../../Alerts/AlertWarning";
import TableGroup from "./Tables/TableGroup";
import { DateRange } from "react-date-range";
import ModalInfoCliente from "../../Modals/ModalInfoCliente";
import ModalInfoDecidir from "../../Modals/ModalInfoDecidir";
import ModalNotificacion from "../../Modals/ModalNotificacion";
import ModalHistorial from "../../Modals/ModalHistorial";
import { AdministradorCrearNotificacion } from "../../../../helpers/NotificacionHelper";

const Facturacion = () => {
  const { user } = useContext(authContext);
  const { data, rule, ruleUser, auth } = user;

  const context = useContext(CollectionStateContext);
  const {
    salas,
    administradorEventos,
    administradorReportes,
    eventoReportes,
    usuarioEventos,
    state,
  } = context;

  const { idEvento } = useParams();

  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mes = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const [fecha, onFecha] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [searchDecidir, setSearchDecidir] = useState("");
  const [stateTable, setStateTable] = useState(idEvento ? true : false);
  const [selectElement, setSelect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [alert, setAlert] = useState({
    notificacion: false,
  });
  const [options_sala, setOptionSala] = useState({
    loop: false,
    margin: 20,
    responsiveClass: true,
    nav: false,
    autoplay: false,
    navText: ["", ""],
    //autoplayTimeout: 6000,
    //fluidSpeed: 7000,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 8,
      },
    },
    dots: true,
  });

  const [options, setOptions] = useState({
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    navText: ["", ""],
    autoplayTimeout: 6000,
    fluidSpeed: 6000,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 1,
      },
    },
    dots: true,
  });

  //QUERY STATE

  const LSProvincias = localStorage.getItem("provincias");

  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );
  const [dataReportes, setReportes] = useState(null);
  const [dataEventosState, setEventosState] = useState(null);
  const [dataEventos, setEventos] = useState(null);
  const [dataSalas, setSalas] = useState(null);

  //FILTER

  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [filter, setFilter] = useState({
    id: idEvento ? idEvento : "",
    search: "",
    sala: "",
    status: "1",
    date_range: [], //dateInitialState(new Date(), 7),
    url:
      rule == 1
        ? "/api/auth/Administrador/Eventos"
        : "/api/auth/Usuario/Eventos",
    pagination: 50,
    provincia: "",
  });

  const [filter_salas, setFilterSalas] = useState({
    id: "",
    value: "",
    searchSala: dataEventos
      ? dataEventos.data
        ? ""
        : dataEventos.nombre_sitio
      : "",
    provincia: "",
    localidad: "",
    filtro: "true",
  });

  const [filter_report, setFilterReport] = useState({
    id_evento: idEvento ? idEvento : "",
    id_user: "",
    id_entrada: "",
    date_range: [],
    tipo_entrada: "",
    fecha_entrada: "",
    nombre_cliente: "",
    estado: "ACTIVO",
    cupon: "",
    state_descuento: "",
    url:
      rule == 1
        ? "/api/auth/Administrador/Reportes"
        : "/api/auth/Evento/Reportes",
    pagination: 50,
  });

  //FORM

  const [form_reporte, setFormReporte] = useState({
    id: "",
    api_establecimiento_id: "",
    status: "",
    motivo: "",
    check_all: false,
    check_reporte: [],
    pagination: 50,
  });

  const [formNotification, setFormNotification] = useState({
    nombre_notificacion: "",
    descripcion_notificacion: "",
    tipo_notificacion: "",
    fecha_expiracion: "",
  });

  //ARROW FUNCTION HELPER

  const valueMonths = (mes) => {
    switch (mes) {
      case "enero":
        {
          return 1;
        }
        break;
      case "febrero":
        {
          return 2;
        }
        break;
      case "marzo":
        {
          return 3;
        }
        break;
      case "abril":
        {
          return 4;
        }
        break;
      case "mayo":
        {
          return 5;
        }
        break;
      case "junio":
        {
          return 6;
        }
        break;
      case "julio":
        {
          return 7;
        }
        break;
      case "agosto":
        {
          return 8;
        }
        break;
      case "septiembre":
        {
          return 9;
        }
        break;
      case "octubre":
        {
          return 10;
        }
        break;
      case "noviembre":
        {
          return 11;
        }
        break;
      case "diciembre":
        {
          return 12;
        }
        break;
    }
  };

  const arrayFilterFechas = (value) => {
    let arrayFechas = [];

    value.map((reporte) => {
      arrayFechas.push(reporte.fecha_entrada);
    });

    let result = arrayFechas.filter((item, index) => {
      return arrayFechas.indexOf(item) === index;
    });

    return result;
  };

  const arrayReportesFechas = () => {
    let arrayFechas = [];

    dataReportes.data.map((reporte) => {
      arrayFechas.push(reporte.fecha_entrada);
    });

    let result = arrayFechas.filter((item, index) => {
      return arrayFechas.indexOf(item) === index;
    });

    return result;
  };

  //MODALS

  const [modal_planilla_facturacion, setModalPlanillaFacturacion] =
    useState(false);
  const [modal_ver_reportes, setModalVerReportes] = useState(false);
  const [modal_add_reportes, setModalAddReportes] = useState(false);
  const [modal_edit_status, setModalStatus] = useState(false);
  const [modal_info_cliente, setModalInfoCliente] = useState(false);
  const [modal_info_decidir, setModalInfoDecidir] = useState(false);
  const [modal_notificacion, setModalNotificacion] = useState(false);
  const [modal_historial, setModalHistorial] = useState(false);

  //BUTTON EVENT

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modal_planilla_facturacion":
        {
          setModalPlanillaFacturacion(true);
        }
        break;
      case "select_sala":
        {
          setFilter({
            ...filter,
            sala: value.nombre,
          });
        }
        break;
      case "ver_reportes":
        {
          setSelect(value);
          setModalVerReportes(true);
        }
        break;
      case "info_cliente":
        {
          setSelect(value);
          setModalInfoCliente(true);
        }
        break;
      case "editar_status":
        {
          setSelect(value);
          setModalStatus(true);
        }
        break;
      case "notificacion":
        {
          setSelect(value);
          setModalNotificacion(true);
        }
        break;
      case "historial":
        {
          setSelect(value);
          setModalHistorial(true);
        }
        break;
      case "info_decidir":
        {
          setFormReporte({
            ...form_reporte,
            api_establecimiento_id: value,
          });
          setModalInfoDecidir(true);
        }
        break;
      case "api_establecimiento_id":
        {
          setFormReporte({
            ...form_reporte,
            api_establecimiento_id: searchDecidir,
          });
        }
        break;
      case "close_modal":
        {
          setModalStatus(false);
          setModalNotificacion(false);
          setModalHistorial(false);
          setModalVerReportes(false);
          setModalInfoCliente(false);
          setModalInfoDecidir(false);
          setModalPlanillaFacturacion(false);
        }
        break;
      case "stateTable":
        {
          if (stateTable) {
            setStateTable(false);
          } else {
            setStateTable(true);
          }
        }
        break;
      //BOLETERIA
      case "linkBoleteria":
        {
          if (filter_report.fecha_entrada.trim() == "") {
            return AlertWarning(
              "Si no tiene seleccionado una fecha para las entradas no puede acceder a la boleteria",
              "Advertencia"
            );
          }

          let boleteria = {
            nombreEvento: dataEventos
              ? dataEventos.data
                ? ""
                : dataEventos.nombre
              : "",
            idEvento: dataEventos
              ? dataEventos.data
                ? ""
                : dataEventos.id
              : "",
            fecha:
              filter_report.fecha_entrada.trim() == ""
                ? ""
                : filter_report.fecha_entrada,
            fullPagination: dataReportes.total,
          };

          boleteria = JSON.stringify(boleteria);
          console.log(boleteria);

          let encodeData = btoa(boleteria);

          return (window.location.href = "/dashboard/boleteria/" + encodeData);
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      //FILTER REPORTE
      case "date_range":
        {
        }
        break;
      case "estado":
      case "tipo_entrada":
      case "fecha_entrada":
        {
          setFilterReport({
            ...filter_report,
            [e.target.name]: e.target.value,
          });

          if (e.target.value == "all") {
            setFilterReport({
              ...filter_report,
              [e.target.name]: "",
            });
          }
        }
        break;
      case "nombre_cliente":
        {
          if (
            e.target.value.length < initialStateFilter.nombre_cliente.length
          ) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setFilterReport({
                ...filter_report,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {
              setFilterReport({
                ...filter_report,
                [e.target.name]: e.target.value,
              });
            }
          }
        }
        break;
      //FORM REPORTE
      case "status":
      case "motivo":
        {
          setFormReporte({
            ...form_reporte,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "check_all":
        {
          if (e.target.checked) {
            setFormReporte({
              ...form_reporte,
              [e.target.name]: true,
            });
          } else {
            setFormReporte({
              ...form_reporte,
              [e.target.name]: false,
            });
          }
        }
        break;
      case "check_reporte":
        {
          let array = form_reporte.check_reporte;

          if (e.target.checked) {
            array.push(value);
            setFormReporte({
              ...form_reporte,
              [e.target.name]: array,
            });
          } else {
            form_reporte.check_reporte.map((reporte, index) => {
              if (reporte.id == value.id) {
                delete array[index];
              }
            });

            array = array.filter((item) => {
              return item != null;
            });
            setFormReporte({
              ...form_reporte,
              [e.target.name]: array,
            });
          }
        }
        break;
      case "searchDecidir": {
        setSearchDecidir(e.target.value);
      }
      //FILTER EVENT
      case "provincia":
        {
          setFilter({
            ...filter,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "search":
        {
          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setFilter({
                ...filter,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3 && !loading) {
              setLoading(true);

              let expect = setTimeout(() => {
                setFilter({
                  ...filter,
                  [e.target.name]: e.target.value,
                });

                setLoading(false);
              }, 2000);
            }
          }
        }
        break;
      case "fecha_desde":
        {
          let fecha = e.target.value;
          fecha = fecha.split(" ");
          fecha = [
            fecha[1] + " " + valueMonths(fecha[3]),
            fecha[1] + " " + valueMonths(fecha[3]),
          ];

          setFilter({
            ...filter,
            date_range: fecha,
          });
        }
        break;
      case "fecha_hasta":
        {
          let fecha = e.target.value;
          fecha = fecha.split(" ");

          if (filter.date_range.length == 0) {
            return AlertWarning(
              "Seleccione primero una fecha desde",
              "Advertencia"
            );
          }

          fecha = [
            filter.date_range[0],
            fecha[1] + " " + valueMonths(fecha[3]),
          ];

          setFilter({
            ...filter,
            date_range: fecha,
          });
        }
        break;
      case "status_evento":
        {
          setFilter({
            ...filter,
            status: e.target.value,
          });
        }
        break;
    }
  };

  const onSave = async (e, value) => {
    let dataForm = new FormData();

    if (form_reporte.status.trim() == "") {
      return AlertWarning(
        "Debe seleccionar el nuevo estado para cambiarlo",
        "Advertencia"
      );
    }

    if (form_reporte.check_all) {
      dataReportes.data.map((check, index) => {
        let stringId = "id[" + index + "]";
        dataForm.append(stringId, check.id);
      });

      dataForm.append("status", form_reporte.status);
      if (form_reporte.motivo.trim() !== "") {
        dataForm.append("motivo", form_reporte.motivo);
      }

      const response = await ActualizarReporte(dataForm, setLoading);
    } else {
      if (form_reporte.check_reporte.length !== 0) {
        form_reporte.check_reporte.map((check, index) => {
          let stringId = "id[" + index + "]";
          dataForm.append(stringId, check.id);
        });

        dataForm.append("status", form_reporte.status);
        if (form_reporte.motivo.trim() !== "") {
          dataForm.append("motivo", form_reporte.motivo);
        }

        const response = await ActualizarReporte(dataForm, setLoading);
      } else {
        if (value.length > 1) {
          let arrayReporte = [];

          value.map((reporte) => {
            if (reporte.status == "ACTIVO" || reporte.status == "LIQUIDADO") {
              arrayReporte.push(reporte);
            }
          });

          arrayReporte.map((reporte, index) => {
            let stringId = "id[" + index + "]";
            dataForm.append(stringId, reporte.id);
          });

          dataForm.append("status", form_reporte.status);
          if (form_reporte.motivo.trim() !== "") {
            dataForm.append("motivo", form_reporte.motivo);
          }

          const response = await ActualizarReporte(dataForm, setLoading);
        } else {
          dataForm.append("id[0]", value[0].id);
          dataForm.append("status", form_reporte.status);

          if (form_reporte.motivo.trim() !== "") {
            dataForm.append("motivo", form_reporte.motivo);
          }

          const response = await ActualizarReporte(dataForm, setLoading);
        }
      }
    }

    setSteps(steps + 1);
    setModalStatus(false);
  };

  const onSend = async (e) => {
    let dataForm = new FormData();

    if (
      formNotification.fecha_expiracion.trim() == "" ||
      formNotification.nombre_notificacion.trim() == "" ||
      formNotification.descripcion_notificacion.trim() == "" ||
      formNotification.tipo_notificacion.trim() == ""
    ) {
      setAlert({
        ...alert,
        notificacion: true,
      });
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    dataForm.append("id_user_destinatario", selectElement.usuarios.id);
    dataForm.append("nombre", formNotification.nombre_notificacion);
    dataForm.append("descripcion", formNotification.descripcion_notificacion);
    dataForm.append("tipo", formNotification.tipo_notificacion);
    dataForm.append("fecha_expiracion", formNotification.fecha_expiracion);

    const response = await AdministradorCrearNotificacion(dataForm, setLoading);

    setModalNotificacion(false);
    setLoading(false);
  };

  //PAGINATION

  const nextPage = async () => {
    setFilterReport({
      ...filter_report,
      url: dataReportes.next_page_url,
    });
  };

  const prevPage = async () => {
    setFilterReport({
      ...filter_report,
      url: dataReportes.prev_page_url,
    });
  };

  const links = async (e, value) => {
    e.preventDefault();
    setFilterReport({
      ...filter_report,
      url: value,
    });
  };

  const nextPageEvent = async () => {
    setFilter({
      ...filter,
      url: dataEventos.next_page_url,
    });
  };

  const prevPageEvent = async () => {
    setFilter({
      ...filter,
      url: dataEventos.prev_page_url,
    });
  };

  const linksEvent = async (e, value) => {
    e.preventDefault();
    setFilter({
      ...filter,
      url: value,
    });
  };

  //EFFECT DOM

  useEffect(() => {
    salas(
      setSalas,
      filter_salas.id,
      filter_salas.searchSala,
      filter_salas.provincia,
      filter_salas.localidad,
      filter_salas.filtro
    );
  }, [filter_salas]);

  useEffect(() => {
    if (rule == 1) {
      administradorEventos(setEventos, filter);
    } else {
      usuarioEventos(
        setEventos,
        filter.id,
        filter.search,
        filter.sala,
        filter.status,
        filter.date_range,
        filter.url
      );
    }
  }, [steps, filter]);

  useEffect(() => {
    if (rule == 1) {
      administradorReportes(setReportes, filter_report);
    } else {
      eventoReportes(
        setReportes,
        filter_report.id_evento,
        filter_report.id_user,
        filter_report.id_entrada,
        filter_report.date_range,
        filter_report.tipo_entrada,
        filter_report.fecha_entrada,
        filter_report.nombre_cliente,
        filter_report.estado,
        filter_report.url,
        filter_report.pagination
      );
    }
  }, [filter_report, steps]);
  return (
    <div className="container-md texto-normal" style={{ maxWidth: "3000px" }}>
      <div className="row">
        <div class="col-md-12 mb-3">
          <h1>Facturacion</h1>
          <span class="text-muted">
            Puedes ver los reportes de ventas de tu evento, verificar su estado
            y detalles.
          </span>
        </div>

        {dataEventos ? (
          dataEventos.data ? (
            ""
          ) : (
            <CardEvent
              keys={0}
              rule={rule}
              content={dataEventos}
              onClick={onClick}
              setModalNotificacion={setModalNotificacion}
            />
          )
        ) : (
          ""
        )}

        <div className="col-md-12 d-flex justify-content-between">
          <div className="fs-5"></div>
          <div>
            {dataEventos ? (
              dataEventos.data ? (
                ""
              ) : (
                  <button
                    className="btn btn-custom-naranja text-white fw-normal GothamBold mx-1 rounded-pill my-4 px-2 py-1 fs-10"
                    name="linkBoleteria"
                    onClick={(event) => onClick(event)}
                  >
                    {" "}
                    Filtra la fecha y accede a la boleteria{" "}
                    <i class="bi bi-arrow-right-circle "></i>
                  </button>
              )
            ) : (
              ""
            )}

            {rule == 1 ? (
              stateTable ? (
                <button
                  className="btn btn-custom-naranja text-white fw-normal GothamBold mx-1 rounded-pill my-4 px-2 py-1 fs-10"
                  name="info_decidir"
                  onClick={(event) => onClick(event, "")}
                >
                  {" "}
                  <i class="mx-1 bi bi-cash-coin"></i>Consultar reporte Decidir
                </button>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {rule == 1 ? (
              dataEventos ? (
                dataEventos.data ? (
                  ""
                ) : (
                  <button className="btn btn-custom-naranja text-white fw-normal GothamBold mx-1 rounded-pill my-4 px-2 py-1 fs-10">
                    <i className="fs-8 fw-light bi bi-plus"></i> Agregar reporte
                  </button>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>

        {dataEventos ? (
          dataEventos.data ? (
            rule == 1 ? (
              stateTable ? (
                <div className="col-md-6 my-2">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
                  >
                    Realiza una busqueda rapida ingresando el nombre del cliente
                    o codigo de la entrada.
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                      placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="nombre_cliente"
                      onChange={(event) => onChange(event)}
                    />
                    <button
                      class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                      id="basic-addon2"
                    >
                      <i class="bi bi-search"></i>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="col-md-6 my-2">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
                  >
                    Realiza una busqueda rapida ingresando el nombre del evento.
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                      placeholder="Busca tu reporte según el nombre del evento o la sala"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      onChange={(event) => onChange(event)}
                    />
                    <button
                      class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                      id="basic-addon2"
                    >
                      {loading ? (
                        <div
                          class="spinner-grow spinner-grow-sm text-light"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i class="bi bi-search"></i>
                      )}
                    </button>
                  </div>
                </div>
              )
            ) : (
              ""
            )
          ) : (
            <div className="col-md-6 my-2">
              <label
                for="exampleFormControlInput1"
                className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
              >
                Realiza una busqueda rapida ingresando el nombre del cliente o
                codigo de la entrada.
              </label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                  placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="nombre_cliente"
                  onChange={(event) => onChange(event)}
                />
                <button
                  class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                  id="basic-addon2"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          )
        ) : (
          ""
        )}

        <div className="col-md-2"></div>
        {rule == 1 ? (
          stateTable ? (
            <div className="col-md-4 my-2">
              <label
                for="exampleFormControlInput1"
                className="form-label  text-muted fs-9 p-0 px-2 border-0"
              >
                Filtrar según el estado del reporte.
              </label>
              <select
                className="form-select fs-9 p-0 px-2 py-1 rounded-pill border-custom-naranja"
                aria-label="Default select example"
                name="estado"
                onChange={(event) => onChange(event)}
              >
                <option selected>Según Estado: </option>
                <option value="ACTIVO">ACTIVOS</option>
                <option value="LIQUIDADO">LIQUIDADO</option>
                <option value="INACTIVO">INACTIVOS</option>
                <option value="RECHAZADO">RECHAZADOS</option>
                <option value="PAUSADO">PAUSADOS</option>
                <option value="REEMBOLSO">REEMBOLSADOS</option>
              </select>
            </div>
          ) : (
            <div className="col-md-4 my-2">
              <label
                for="exampleFormControlInput1"
                className="form-label fs-10 text-muted mb-0"
              >
                Filtra según el estado.
              </label>
              <select
                className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
                aria-label="Default select example"
                name="status_evento"
                onChange={(event) => onChange(event)}
              >
                <option value="all">Según Estado: </option>
                <option value="1">Activos</option>
                <option value="2">Pausados</option>
                {rule == 1 ? <option value="3">Eliminados</option> : ""}
                <option value="4">Borradores</option>
              </select>
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <div className="row">
        {dataEventos ? (
          dataEventos.data ? (
            ""
          ) : (
            <div className="col-md-3 my-2">
              <label
                for="exampleFormControlInput1"
                className="form-label  text-muted fs-9 p-0 px-2 border-0"
              >
                Filtrar según el tipo de entrada.
              </label>
              <select
                className="form-select fs-9 p-0 px-2 py-1 rounded-pill border-custom-naranja"
                aria-label="Default select example"
                name="tipo_entrada"
                onChange={(event) => onChange(event)}
              >
                <option value="all">Según entrada: </option>
                {dataEventos
                  ? dataEventos.data
                    ? ""
                    : dataEventos.entradas.map((entrada) => (
                        <option value={entrada.nombre}>{entrada.nombre}</option>
                      ))
                  : ""}
              </select>
            </div>
          )
        ) : (
          ""
        )}

        {rule == 1 ? (
          stateTable ? (
            <div className="col-md-3 my-2">
              <div className="mb-3 text-start">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
                >
                  Fija un rango de fechas para los reportes
                </label>
                <button className="fs-10 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 m-p px-2 py-2 w-100">
                  {" "}
                  <i className="bi bi-calendar2-event"></i> Filtra por rango de
                  fecha
                </button>
                <div className="filter_date_range d-flex justify-content-center w-100 border-0">
                  <DateRange
                    onChange={(item) => {
                      onFecha([item.selection]);
                      setFilterReport({
                        ...filter_report,
                        date_range: [
                          item.selection.startDate.getDate() +
                            " " +
                            (item.selection.startDate.getMonth() + 1),
                          item.selection.endDate.getDate() +
                            " " +
                            (item.selection.endDate.getMonth() + 1),
                        ],
                      });
                    }}
                    moveRangeOnFirstSelection={false}
                    locale={locale}
                    ranges={fecha}
                    className={"fs-7"}
                    style={{ fontSize: "12px" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-3 my-2">
              <div className="mb-3 text-start">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
                >
                  Fija un rango de fechas para los eventos
                </label>
                <button className="fs-10 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 m-p px-2 py-2 w-100">
                  {" "}
                  <i className="bi bi-calendar2-event"></i> Filtra por rango de
                  fecha
                </button>
                <div className="filter_date_range d-flex justify-content-center w-100 border-0">
                  <DateRange
                    onChange={(item) => {
                      onFecha([item.selection]);
                      setFilter({
                        ...filter,
                        date_range: [
                          item.selection.startDate.getDate() +
                            " " +
                            (item.selection.startDate.getMonth() + 1),
                          item.selection.endDate.getDate() +
                            " " +
                            (item.selection.endDate.getMonth() + 1),
                        ],
                      });
                    }}
                    moveRangeOnFirstSelection={false}
                    locale={locale}
                    ranges={fecha}
                    className={"fs-7"}
                    style={{ fontSize: "12px" }}
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}

        <div className="col-md-3 my-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-0 text-center"
          >
            Filtrar según rango de fecha
          </label>
          {dataEventos ? (
            dataEventos.data ? (
              <span className="d-block fs-7">
                No esta disponible para reportes desagrupados
              </span>
            ) : (
              <select
                className="form-select fs-9 p-0 px-2 py-1 m-0 rounded-pill border-custom-naranja"
                aria-label="Default select example"
                name="fecha_entrada"
                onChange={(event) => onChange(event)}
              >
                <option value="all">Según fecha: </option>
                {arrayFilterFechas(dataEventos.reportes).map((fecha) => (
                  <option value={fecha}>{fecha}</option>
                ))}
                {/*JSON.parse(dataEventos.fechas).map((fecha) => (
                    <>
                      {fecha.fechas.map((dia) => (
                        <option value={dia + " HORA: " + fecha.hora}>
                          {dia + " HORA: " + fecha.hora}
                        </option>
                      ))}
                    </>
                      )) */}
              </select>
            )
          ) : (
            ""
          )}
        </div>

        <div className="col-md-3 my-2  text-center">
          <div class="dropdown">
            <label
              for="exampleFormControlInput1"
              className=" form-label text-muted fs-9 p-0 px-2 border-0 text-nowrap"
            >
              Exporta la tabla en un archivo .XLS
            </label>
            <button
              class="btn btn-outline-success rounded-pill fs-7 w-100  dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Exportar excel
            </button>
            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
              <li className="d-flex">
                <ReactHTMLTableToExcel
                  id="buttonDownloadExcel"
                  className="btn border-0 w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
                  table="reporteVentas"
                  filename="reporteDeVentas"
                  sheet="pagina 1"
                  buttonText="Exportar tabla actual"
                />
                <i class="bi bi-file-earmark"></i>
              </li>
              {rule == 1 ? (
                <li className="d-flex">
                  <button
                    className="btn fs-7 p-0 m-0 px-2 py-1"
                    name="modal_planilla_facturacion"
                    onClick={(event) => onClick(event)}
                  >
                    Exportar excel por mes
                  </button>
                  <i class="bi bi-collection"></i>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>

        {rule == 1 ? (
          dataEventos ? (
            dataEventos.data ? (
              <div className="col-md-3 my-2 text-center">
                <div className="text-center">
                  <label
                    for="exampleFormControlInput1"
                    className=" form-label text-muted fs-9 p-0 px-2 border-0 text-nowrap"
                  >
                    Agrupa los reportes por eventos
                  </label>
                  <button
                    className={
                      stateTable
                        ? "fs-10 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 m-p px-2 py-2 w-100"
                        : "fs-10 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 m-p px-2 py-2 w-100 bg-dark"
                    }
                    name="stateTable"
                    onClick={(event) => onClick(event)}
                  >
                    {stateTable ? "Agrupar tabla" : "Desagrupar tabla"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {rule == 1 ? (
          <div className="col-md-4 col-12 mb-3 m-0 p-0">
            <label
              for="exampleFormControlInput1"
              className="form-label  text-muted fs-9 p-0 px-2 border-primary"
            >
              Filtrar según la provincia.
            </label>
            <select
              className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
              aria-label="Default select example"
              name="provincia"
              onChange={(event) => onChange(event)}
            >
              <option selected>{filter.provincia}</option>
              {dataProvincias
                ? dataProvincias.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))
                : ""}
            </select>
          </div>
        ) : (
          ""
        )}
      </div>

      {dataEventos && dataReportes ? (
        stateTable ? (
          <TableResponsive
            rule={rule}
            evento={dataEventos.data ? null : dataEventos}
            reportes={dataReportes}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
            onClick={onClick}
            onChange={onChange}
            form_reporte={form_reporte}
          />
        ) : (
          <TableGroup
            eventos={dataEventos}
            onChange={onChange}
            onClick={onClick}
            nextPage={nextPageEvent}
            prevPage={prevPageEvent}
            links={linksEvent}
          />
        )
      ) : (
        <div className="w-100">
          <div className="col-md-12 d-flex justify-content-center align-items-center py-5">
            <span className="Grifter text-muted opacity-25 fs-2">
              No selecciono ningun evento
            </span>
          </div>
        </div>
      )}

      {modal_edit_status ? (
        <ModalEditarStatusReporte
          onClick={onClick}
          onChange={onChange}
          onSave={onSave}
          data={selectElement}
        />
      ) : (
        ""
      )}

      {modal_notificacion ? (
        <ModalNotificacion
          onClick={onClick}
          onChange={onChange}
          onSend={onSend}
          loading={loading}
          alert={alert}
          data={selectElement}
          formNotification={formNotification}
        />
      ) : (
        ""
      )}

      {modal_historial ? (
        <ModalHistorial onClick={onClick} data={selectElement.historia} />
      ) : (
        ""
      )}

      {modal_info_cliente ? (
        <ModalInfoCliente onClick={onClick} data={selectElement} />
      ) : (
        ""
      )}

      {modal_info_decidir ? (
        <ModalInfoDecidir
          onClick={onClick}
          onChange={onChange}
          data={form_reporte}
          setData={setFormReporte}
        />
      ) : (
        ""
      )}

     
      
    </div>
  );
};

export default Facturacion;
