//Date format
import { format, add, sub } from "date-fns";
import { fileDateReportEvents } from "./FunctionReportes";

export const convertMesStartEnd = (mes, año) => {
  let result = [];
  const hoy = new Date();
  const year = año.trim() !== "" ? año : hoy.getFullYear();



  switch (mes) {
    case "Enero":
      {
        let monthDayStart = format(
          new Date(year+"/01/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 1, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Febrero":
      {
        let monthDayStart = format(
          new Date(year+"/02/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 2, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Marzo":
      {
        let monthDayStart = format(
          new Date(year+"/03/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 3, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Abril":
      {
        let monthDayStart = format(
          new Date(year+"/04/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 4, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Mayo":
      {
        let monthDayStart = format(
          new Date(year+"/05/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 5, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Junio":
      {
        let monthDayStart = format(
          new Date(year+"/06/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 6, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Julio":
      {
        let monthDayStart = format(
          new Date(year+"/07/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 7, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Agosto":
      {
        let monthDayStart = format(
          new Date(year+"/08/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 8, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Septiembre":
      {
        let monthDayStart = format(
          new Date(year+"/09/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 9, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Octubre":
      {
        let monthDayStart = format(
          new Date(year+"/10/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 10, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Noviembre":
      {
        let monthDayStart = format(
          new Date(year+"/11/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 11, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
    case "Diciembre":
      {
        let monthDayStart = format(
          new Date(year+"/12/01"),
          "yyyy/MM/dd"
        ).toString();
        let monthDayEnd = format(
          new Date(year, 12, 0),
          "yyyy/MM/dd"
        ).toString();

        result.push(monthDayStart);
        result.push(monthDayEnd);
      }
      break;
  }

  return result;
};

//total reportes cuentas
export const totalReportCuenta = (reportes, filtro) => {
  let total_reportes = 0;
  let total_cantidad = 0;
  let total_serv = 0;
  //sin descuento
  let total_bruto = 0;
  //con descuento
  let total_pagos = 0;
  //cupon
  let total_descuento = 0;
  let use_cupon = 0;

  let descuento = 0;

  fileDateReportEvents(reportes, filtro).map((evento) => {
    //console.log(reporte);

    evento.map((fecha) => {
      fecha.reportes.map((reporte) => {
        //reportes totales
        total_reportes = total_reportes + 1;

        let subTotal = parseFloat(reporte.cantidad * reporte.unidad);

        //bruto
        if (
          parseFloat(reporte.total) !==
          parseFloat(reporte.cantidad * reporte.unidad)
        ) {
          total_bruto = reporte.cantidad * reporte.unidad + total_bruto; //parseFloat(reporte.total)+parseFloat(total_bruto);
        } else {
          total_bruto = reporte.cantidad * reporte.unidad + total_bruto;
        }

        //descuento
        if (reporte.codigo_descuento) {
          subTotal = subTotal - (subTotal / 100) * reporte.descuento;

          total_descuento =
            ((reporte.cantidad * reporte.unidad) / 100) * reporte.descuento +
            total_descuento;
          use_cupon = use_cupon + 1;
        }

        total_cantidad = reporte.cantidad + total_cantidad;

        total_pagos = parseFloat(subTotal) + total_pagos;
      });
    });
  });

  return {
    total_reportes: total_reportes,
    total_pagos: total_pagos,
    total_cantidad: total_cantidad,
    total_serv: total_serv,
    //total sin bruto
    total_bruto: total_bruto,
    //cupon
    use_cupon: use_cupon,
    total_descuento: total_descuento,
  };
};
