import { has } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AlertError from "../../../Alerts/AlertError";
import AlertWarning from "../../../Alerts/AlertWarning";
import { authContext } from "../../../context/auth/AuthContext";
import { Cupon } from "../../../helpers/CuponHelper";
import { medioDePago } from "../../../helpers/MedioDePago";
import { Checkout, TokenCard } from "../../../helpers/PagosHelper";

const MedioDePago = () => {
  const user = useContext(authContext);
  const { data, role, userRole } = user.user;

  const { info, deco } = useParams();

  let base64 = info.replace('-','/');
  let decodeData = JSON.parse(decodeURIComponent(escape(atob(base64))));

  for (let i = 1; i < deco; i++) {
    decodeData = JSON.parse(decodeData);
  }

  const { entradas, evento, cupones } = decodeData;

  let statusFechas = false;
  let oldSubTotal = 0;
  let subTotal = 0;
  let total = 0;

  const [steps, setSteps] = useState(0);
  const [fechasDescuento, setFechasDescuento] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [descuento, setDescuento] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const [statePayment, setStatePayment] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  //HELPER

  const descont = (entrada, index) => {
    let fechaEntrada = entrada.fecha.split(" ");
    fechaEntrada =
      fechaEntrada[0] +
      fechaEntrada[1] +
      fechaEntrada[2] +
      fechaEntrada[3] +
      fechaEntrada[4] +
      fechaEntrada[5];
    let status = false;

    cupones.map((cupon) => {
      JSON.parse(cupon.fechas_funciones).map((fecha) => {
        let fechaConcat = fecha.fechas[0].split(" ").join("");
        if (fechaConcat == fechaEntrada) {
          status = true;
        }
      });
    });

    let statusFechas = false;

    if (fechasDescuento) {
      if (fechasDescuento.hasOwnProperty(index) && fechasDescuento[index]) {
        fechasDescuento[index].map((fechas) => {
          let fechaConcat = fechas.fechas[0].split(" ").join("");
          if (fechaEntrada == fechaConcat) {
            statusFechas = true;
          }
        });
      }
    }

    if (status && statusFechas) {
      if (descuento.hasOwnProperty(index) && descuento[index] !== "INACTIVO" && descuento[index]) {
        return entrada.precio - (entrada.precio / 100) * descuento[index];
      } else {
        return entrada.precio;
      }
    }

    return entrada.precio;
  };

  const verifyDescont = (entrada, index) => {
    let fechaEntrada = entrada.fecha.split(" ");
    fechaEntrada =
      fechaEntrada[0] +
      fechaEntrada[1] +
      fechaEntrada[2] +
      fechaEntrada[3] +
      fechaEntrada[4] +
      fechaEntrada[5];
    let status;
    let statusFechas = false;

    cupones.map((cupon) => {
      JSON.parse(cupon.fechas_funciones).map((fecha) => {
        let fechaConcat = fecha.fechas[0].split(" ").join("");
        if (fechaConcat == fechaEntrada) {
          status = true;
        }
      });
    });

    if (status) {
      return (
        <li
          className="list-group-item border-0 bg-violet"
          style={{ borderRadius: "10px" }}
        >
          <label className="text-center text-white mb-1" htmlFor="">
            Ingrese su código de descuento
          </label>
          <input
            type="text"
            name="codigoDescuento"
            onChange={(event) => onChange(event, index)}
            className="form-control"
          />
          {fechasDescuento
            ? fechasDescuento.hasOwnProperty(index) 
              ? fechasDescuento[index] && fechasDescuento[index] !== "INACTIVO"
                ? fechasDescuento[index].map((fechas) => {
                  let fechaConcat = fechas.fechas[0].split(" ").join("");
                  if (fechaEntrada == fechaConcat) {
                    statusFechas = true;
                  }
                })
                : ""
              : ""
            : ""
            }
          {descuento && fechasDescuento  ? (
            statusFechas ? (
              descuento[index] == "INACTIVO" ? (
                <span className="text-white">
                  El codigo se encuentra inactivo
                </span>
              ) : descuento.hasOwnProperty(index) ? 
                descuento[index]
                ?(
                <span className="text-white">
                  Código activo con un {descuento[index]}% de descuento
                </span>
              ) : (
                ""
              ) : ""
            ) : fechasDescuento.hasOwnProperty(index) ? 
                fechasDescuento[index] && fechasDescuento[index] !== "INACTIVO"
                ?(
              <span className="text-white">
                El codigo se encuentra inactivo para esta funcón
              </span>
            ) : (
              <span className="text-white">
                El codigo ingresado no existe
              </span>
            ): ""
          ) : (
            ""
          )}
        </li>
      );
    } else {
      return "";
    }
  };

  //FORM

  const [form, setForm] = useState({
    payment_method_id: "",
    card_number: "",
    card_expiration_month: "",
    card_expiration_year: "",
    security_code: "",
    card_holder_name: data ? data.nombre + " " + data.apellido : "",
    type: "",
    numero: "",
    nombre: data ? data.nombre + " " + data.apellido : "",
    email: data ? data.email : "",
    email_confirmation: "",
    telefono: data ? data.telefono : "",

    select_card: null,
    type_card: "",
  });

  //BUTTON EVENT

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "type_card":
        {
          setForm({
            ...form,
            [e.target.name]: value,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "select_card":
        {
          setForm({
            ...form,
            [e.target.name]: JSON.parse(e.target.value),
          });
        }
        break;
      case "telefono":
      case "email":
      case "email_confirmation":
      case "card_holder_name":
      case "card_expiration_year":
      case "card_expiration_month":
      case "security_code":
      case "card_number":
      case "type":
      case "numero":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //DESCUENTO
      case "codigoDescuento":
        {
          let descuentoObjt = descuento ? descuento : {};
          let codigoObjt = codigo ? codigo : {};
          let fechasObjt = fechasDescuento ? fechasDescuento : {};

          const axios = async () => {
            let response = await Cupon(e.target.value, setLoading);


            setFechasDescuento({
              ...fechasObjt,
              [value]: response.fechas_funciones
                ? JSON.parse(response.fechas_funciones)
                : response,
            });

            setDescuento({
              ...descuentoObjt,
              [value]: response.descuento ? response.descuento : response,
            });

            setCodigo({
              ...codigoObjt,
              [value]: response.codigo ? response.codigo : response,
            });

          };

          if (e.target.value.length > 4) {
            axios();
          }

          descuentoObjt[value] = null;
          codigoObjt[value] = null;
          fechasObjt[value] = null;

          setFechasDescuento(Object.keys(fechasObjt).length == 0? null : fechasObjt); 
          setDescuento(Object.keys(descuentoObjt).length == 0? null : descuentoObjt);
          setCodigo(Object.keys(codigoObjt).length == 0? null : codigoObjt);
          
          setSteps(steps+1)
        }
        break;
    }
  };

  const CheckOutPayments = async (e) => {
    //VALIDATION

    if (form.email !== form.email_confirmation) {
      setAlert(true);
      return AlertWarning(
        "Su correo no coincide.",
        "Advertencia"
      );
    }

    if (form.select_card) {
      if (form.card_number.length < form.select_card.longitud_pan) {
        setAlert(true);
        return AlertWarning(
          "EL número de tarjeta no corresponde a la cantidad de caracteres permitidos",
          "Advertencia"
        );
      }

      if (form.security_code.length < form.select_card.formato_cvv) {
        setAlert(true);
        return AlertWarning(
          "EL codigo de seguridad de la tarjeta no corresponde a la cantidad de caracteres permitidos",
          "Advertencia"
        );
      }
    }

    if (
      form.card_holder_name.trim() == "" ||
      form.numero.trim() == "" ||
      form.card_expiration_month.trim() == "" ||
      form.card_expiration_year.trim() == "" ||
      form.telefono.trim() == "" ||
      form.type.trim() == ""
    ) {
      setAlert(true);
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    setLoading(true);
    //FORM CREATION

    let formData = {
      card_number: form.card_number,
      card_expiration_month: form.card_expiration_month,
      card_expiration_year: form.card_expiration_year,
      security_code: form.security_code,
      card_holder_name: form.card_holder_name,

      card_holder_identification: {
        type: form.type,
        number: form.dni,
      },

      fraud_detection: {
        device_unique_identifier: "12345",
      },

      ip_address: "192.168.100.1",
    };

    if (
      form.payment_method_id == 1 ||
      form.payment_method_id == 31 ||
      form.payment_method_id == 24
    ) {
      formData = {
        ...formData,
        card_holder_birthday: "01012017",
        card_holder_door_number: 333,
      };
    }

    let token = await TokenCard(formData, setLoading);

    let arrayEntradas = [];
    entradas.map((entrada, index) => {
      let objItem = {
        id: entrada.id,
        cantidad: entrada.cantidad,
        fecha: entrada.fecha,
        codigo_descuento: codigo
          ? codigo.hasOwnProperty(index)
            ? codigo[index]
            : null
          : null,
      };
      arrayEntradas.push(objItem);
    });


    formData = {
      id_evento: evento.id,
      nombre: form.card_holder_name,
      dni: form.numero,
      telefono: form.telefono,
      token: token.id,
      email: form.email,
      bin: token.bin,
      payment_method: form.select_card.id_medio_pago,
      entradas: JSON.stringify(arrayEntradas),
    };

    let response = await Checkout(formData, setLoading, setStatePayment);
  };

  //console.log(cupones);
  //console.log(entradas);
  //console.log(evento);

  useEffect(() => {
    const axios = async () => {
      const response = await medioDePago();
      setPaymentId(response);
    };
    axios();
  }, [steps]);
  return (
    <>
      {statePayment ? (
        <div className="">
          <div
            className="container p-5 shadow-sm text-center"
            style={{ borderRadius: "10px" }}
          >
            <div className="text-success" style={{ fontSize: "100px" }}>
              <i class="bi bi-cart-check"></i>
            </div>
            <div className="fs-5">
              <p className="fw-bold fs-4">¡Compra realizada con éxito!</p>
              <p>
                Se enviará un correo a la cuenta{" "}
                <span className="fw-bold">{form.email}</span> con los datos de
                su compra. Si tienes una cuenta registrada podras ver el
                historial de compras desde tu perfil.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-md texto-normal">
          <div className="row">
            <div className="col-md-12 py-3 text-center">
              <span className="fs-3 fw-bold">Formulario de pago seguro</span>
              <span className="fs-5 text-muted d-block">
                Pago seguro con encriptado SSL
              </span>
            </div>
            <div className="col-md-6 mb-3">
              <div className="text-muted fs-7 text-center mb-2">
                Selecciona un tipo de tarjeta para seguir con el formulario
              </div>
              <div className="text-center">
                <button
                  className={
                    form.type_card == "credito"
                      ? "btn btn-outline-primary mb-3 w-100  py-4 px-3 mx-1 active"
                      : "btn btn-outline-primary mb-3 w-100  py-4 px-3 mx-1"
                  }
                  name="type_card"
                  onClick={(event) => onClick(event, "credito")}
                >
                  <i className="bi bi-credit-card"></i> Paga con tarjeta de
                  credito{" "}
                </button>
                <button
                  className={
                    form.type_card == "debito"
                      ? "btn btn-outline-primary mb-3 w-100  py-4 px-3 mx-1 active"
                      : "btn btn-outline-primary mb-3 w-100  py-4 px-3 mx-1"
                  }
                  name="type_card"
                  onClick={(event) => onClick(event, "debito")}
                >
                  <i className="bi bi-credit-card"></i> Paga con tarjeta de
                  debito{" "}
                </button>
              </div>

              <div>
                <label htmlFor="" className="text-muted">
                  Selecciona tu forma de pago
                </label>
                <select
                  className="form-select border  mb-3"
                  aria-label="Default select example"
                  name="select_card"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{form.payment_method_id}</option>
                  {paymentId
                    ? paymentId.map((id) => {
                        if (form.type_card == id.tipo) {
                          return (
                            <option value={JSON.stringify(id)}>
                              {id.marca + " - " + id.tipo}
                            </option>
                          );
                        }
                      })
                    : ""}
                </select>
              </div>

              {form.select_card ? (
                <div className="row w-100 m-0 p-0">
                  <div className="col-md-12 m-0 p-0 mb-3 ">
                    <div className="text-bold fs-6 w-100 ">
                      Titular de la tarjeta{" "}
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Nombre y apellido tal cual aparece al frente de tu
                          tarjeta.
                        </span>
                        <input
                          className="form-control px-2 border "
                          type="text"
                          defaultValue={
                            form.card_holder_name == ""
                              ? form.nombre
                              : form.card_holder_name
                          }
                          placeholder="Ingresa tu nombre y apellido..."
                          name="card_holder_name"
                          onChange={(event) => onChange(event)}
                        />{" "}
                      </span>
                    </div>

                    {alert ? (
                      form.card_holder_name.trim() == "" ? (
                        <span className="text-danger d-block">
                          Ingrese el nombre y apellido del titular de la tarjeta
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-0 p-0  mb-3">
                    <div className="text-bold fs-6 w-100">
                      Email{" "}
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          La información de operación se enviara a esta
                          dirección.
                        </span>
                        <input
                          className="form-control px-2 border "
                          type="text"
                          defaultValue={form.email}
                          placeholder="Ingresa tu email..."
                          name="email"
                          onChange={(event) => onChange(event)}
                        />{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.email.trim() == "" ? (
                        <span className="text-danger d-block">
                          Ingrese su email.
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-0 p-0  mb-3">
                    <div className="text-bold fs-6 w-100">
                      Repita su email
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Confirme su email.
                        </span>
                        <input
                          className="form-control px-2 border "
                          type="text"
                          defaultValue={form.email}
                          placeholder="Ingresa tu email..."
                          name="email_confirmation"
                          onChange={(event) => onChange(event)}
                        />{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.email !== form.email_confirmation  ? (
                        <span className="text-danger d-block">
                          Su email no coincide.
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-0 p-0  mb-3">
                    <div className="text-bold fs-6 w-100">
                      Telefono{" "}
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          introduce tu número para que podamos contactarte.
                        </span>
                        <input
                          className="form-control px-2 border "
                          type="number"
                          defaultValue={form.telefono}
                          placeholder="Ingresa tu telefono..."
                          name="telefono"
                          onChange={(event) => onChange(event)}
                          disabled={data ? true : false}
                        />{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.telefono.trim() == "" ? (
                        <span className="text-danger d-block">
                          Ingrese su número de telefono.
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 p-0 m-0 mb-3">
                    <label htmlFor="" className="d-block ">
                      Tipo de número de documento
                    </label>

                    <div className="d-flex">
                      <select
                        className="form-select w-100 border me-1  mb-3"
                        aria-label="Default select example"
                        name="type"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>{form.type}</option>
                        <option value="dni">DNI</option>
                        <option value="cuil">CUIL</option>
                      </select>
                      <input
                        className="form-control  border  ms-1 mb-3"
                        type="number"
                        placeholder="Ingrese su número de DNI o CUIL"
                        name="numero"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    {alert ? (
                      form.type.trim() == "" || form.numero.trim() == "" ? (
                        <span className="text-danger">
                          Ingrese su tipo o número de documento
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-o p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Número de tarjeta
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Ingresa los{" "}
                          {form.select_card
                            ? form.select_card.longitud_pan
                            : "xx"}{" "}
                          digitos de tu tarjeta sin espacios ni guiones.
                        </span>
                        <input
                          className="form-control px-2 border"
                          type="number"
                          name="card_number"
                          onChange={(event) => onChange(event)}
                        />{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.card_number.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete su número de tarjeta{" "}
                        </span>
                      ) : form.card_number.length >
                        form.select_card.longitud_pan ? (
                        <span className="text-danger">
                          {" "}
                          El número ingresado es mayor al número de tarjeta
                          permitido{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-0 p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Fecha de vencimiento
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Ingresa la fecha de vencimiento de tu tarjeta en
                          formato MM/AA.
                        </span>
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select w-100 border me-1  mb-3"
                            aria-label="Default select example"
                            name="card_expiration_month"
                            onChange={(event) => onChange(event)}
                          >
                            <option selected>MM</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <select
                            className="form-select w-100 border me-1  mb-3"
                            aria-label="Default select example"
                            name="card_expiration_year"
                            onChange={(event) => onChange(event)}
                          >
                            <option selected>AAAA</option>
                            <option value="22">2022</option>
                            <option value="23">2023</option>
                            <option value="24">2024</option>
                            <option value="25">2025</option>
                            <option value="26">2026</option>
                            <option value="27">2027</option>
                            <option value="28">2028</option>
                            <option value="29">2029</option>
                            <option value="30">2030</option>
                            <option value="31">2031</option>
                            <option value="32">2032</option>
                            <option value="33">2033</option>
                            <option value="34">2034</option>
                            <option value="35">2035</option>
                            <option value="36">2036</option>
                            <option value="37">2037</option>
                            <option value="38">2038</option>
                            <option value="39">2039</option>
                            <option value="40">2040</option>
                            <option value="41">2041</option>
                            <option value="42">2042</option>
                            <option value="43">2043</option>
                            <option value="44">2044</option>
                          </select>
                        </div>
                      </span>
                    </div>
                    {alert ? (
                      form.card_expiration_month.trim() == "" ||
                      form.card_expiration_year.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete los datos de vencimiento de tu tarjeta{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 m-o p-0 mb-3">
                    <div className="text-bold fs-6 w-100">
                      Codigo de seguridad
                      <span className="fw-normal text-dark">
                        <span className="d-block fs-7 text-muted">
                          Iltimos{" "}
                          {form.select_card
                            ? form.select_card.formato_cvv
                            : "xxxx"}{" "}
                          digitos del reverso de tu tarjeta.
                        </span>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            className="form-control px-2 border me-2"
                            type="password"
                            name="security_code"
                            onChange={(event) => onChange(event)}
                          />
                          <i className="fs-2 bi bi-credit-card"></i>
                        </div>{" "}
                      </span>
                    </div>
                    {alert ? (
                      form.security_code.trim() == "" ? (
                        <span className="text-danger">
                          {" "}
                          Complete su codigo de seguridad{" "}
                        </span>
                      ) : form.security_code.length >
                        form.select_card.longitud_pan ? (
                        <span className="text-danger">
                          {" "}
                          El número ingresado es mayor al número de seguridad de
                          la tarjeta{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-100 text-center">
                  {" "}
                  Seleccione el tipo de pago y tu tarjeta para continuar{" "}
                </div>
              )}
            </div>

            <div className="col-md-6 px-4 shadow-sm mb-3">
              <ul className="list-group">
                <li className="list-group-item border-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={"/assets/img/eventos/" + evento.imagen}
                      className="img-fluid"
                      alt="IMAGEN NO ENCONTRADA"
                    />
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-center align-items-center border-0">
                  <span className="fs-4">{evento.nombre}</span>
                </li>

                <li className="list-group-item border-0 fw-bold">
                  <span>Detalles de tu compra</span>
                </li>
                {entradas.map((entrada, index) => (
                  <ul className="list-group mb-3 border p-2">
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Función</div>
                      <div>{entrada.fecha}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Entrada</div>
                      <div>{entrada.nombre}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Cantidad</div>
                      <div>{entrada.cantidad}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Precio</div>
                      <div className="text-nowrap d-flex">
                        ARS{"  "}
                        {descuento && fechasDescuento ? ( 
                            descuento.hasOwnProperty(index) &&
                            fechasDescuento.hasOwnProperty(index) && fechasDescuento[index] && descuento[index] && fechasDescuento[index] !== "INACTIVO"? (
                              <>
                                {fechasDescuento[index].map((fechas) => {
                                  let fechaEntrada = entrada.fecha.split(" ");
                                  fechaEntrada =
                                    fechaEntrada[0] +
                                    fechaEntrada[1] +
                                    fechaEntrada[2] +
                                    fechaEntrada[3] +
                                    fechaEntrada[4] +
                                    fechaEntrada[5];
                                  let fechaConcat = fechas.fechas[0]
                                    .split(" ")
                                    .join("");
                                  if (fechaEntrada == fechaConcat) {
                                    statusFechas = true;
                                  }
                                })}
                                {statusFechas ? (
                                  <div className="text-nowrap d-flex ms-1">
                                    <span className="text-decoration-line-through mx-1">
                                      {entrada.precio}
                                    </span>
                                    {Math.round(descont(entrada, index))}
                                  </div>
                                ) : (
                                  Math.round(entrada.precio)
                                )}
                              </>
                            ) : (
                              Math.round(entrada.precio)
                            )
                        ) : (
                          Math.round(entrada.precio)
                        )}
                      </div>
                    </li>
                    { verifyDescont(entrada, index) }
                  </ul>
                ))}
                <li className="list-group-item d-flex justify-content-between border-0 border-top d-flex">
                  <div className="fw-bold">SubTotal</div>
                  <div className="d-flex">
                    ARS{"  "}
                    {entradas.map((entrada, index) => {
                        if (descuento && fechasDescuento) {
                          if (
                            descuento.hasOwnProperty(index) &&
                            descuento[index] !== "INACTIVO" &&
                            fechasDescuento.hasOwnProperty(index) && fechasDescuento[index] && descuento[index] && fechasDescuento[index] !== "INACTIVO"
                          ) {
                            let fechaEntrada = entrada.fecha.split(" ");
                            fechaEntrada =
                              fechaEntrada[0] +
                              fechaEntrada[1] +
                              fechaEntrada[2] +
                              fechaEntrada[3] +
                              fechaEntrada[4] +
                              fechaEntrada[5];
                            let status = false;
                            let statusFechas = false;

                            cupones.map((cupon) => {
                              JSON.parse(cupon.fechas_funciones).map(
                                (fecha) => {
                                  let fechaConcat = fecha.fechas[0]
                                    .split(" ")
                                    .join("");
                                  if (fechaConcat == fechaEntrada) {
                                    status = true;
                                  }
                                }
                              );
                            });

                            fechasDescuento[index].map((fechas) => {
                              let fechaConcat = fechas.fechas[0]
                                .split(" ")
                                .join("");
                              if (fechaEntrada == fechaConcat) {
                                statusFechas = true;
                              }
                            });

                            if (status && statusFechas) {
                              if (entrada.precio * entrada.cantidad > 500) {
                                oldSubTotal =
                                  entrada.cantidad * entrada.precio +
                                  oldSubTotal;

                                subTotal =
                                  ((entrada.cantidad * entrada.precio) -
                                  ((entrada.cantidad * entrada.precio / 100) * descuento[index])) +
                                  subTotal;
                              } else {
                                oldSubTotal =
                                  entrada.cantidad * entrada.precio +
                                  oldSubTotal;

                                subTotal =
                                ((entrada.cantidad * entrada.precio) -
                                ((entrada.cantidad * entrada.precio / 100) * descuento[index])) +
                                  subTotal -
                                  (50 - (50 / 100) * (100 - descuento[index])) ;
                              }
                            } else {
                              oldSubTotal =
                                entrada.cantidad * entrada.precio + oldSubTotal;

                              subTotal =
                                entrada.cantidad * entrada.precio + subTotal;
                            }
                          } else {
                            oldSubTotal =
                              entrada.cantidad * entrada.precio + oldSubTotal;
                            subTotal =
                              entrada.cantidad * entrada.precio + subTotal;
                          }
                        } else {
                          oldSubTotal =
                            entrada.cantidad * entrada.precio + oldSubTotal;
                          subTotal =
                            entrada.cantidad * entrada.precio + subTotal;
                        }
                    })}
                    {descuento && fechasDescuento ? (
                      <div className="ms-1">
                        <span className="text-decoration-line-through me-1">
                          {oldSubTotal == subTotal? "" : Math.round(oldSubTotal)}
                        </span>
                        {subTotal}
                      </div>
                    ) : (
                      Math.round(oldSubTotal)
                    )}
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">
                    +ARS SERV({oldSubTotal > 500 ? "10%" : "50"})
                  </div>
                  <div>
                    ARS {oldSubTotal > 500 ? (subTotal / 100) * 10 : "50"}
                  </div>
                </li>
                {descuento && fechasDescuento
                  ? Object.keys(descuento).map((keys) => {
                      let statusFechas = false;
                      let fechaEntrada = entradas[keys].fecha.split(" ");
                      fechaEntrada =
                        fechaEntrada[0] +
                        fechaEntrada[1] +
                        fechaEntrada[2] +
                        fechaEntrada[3] +
                        fechaEntrada[4] +
                        fechaEntrada[5];


                      if(fechasDescuento[keys] && fechasDescuento[keys] !== "INACTIVO"){
                        fechasDescuento[keys].map((fechas) => {
                          let fechaConcat = fechas.fechas[0].split(" ").join("");
                          if (fechaEntrada == fechaConcat) {
                            statusFechas = true;
                          }
                        });
                      }

                      if (statusFechas) {
                        return (
                          <li className="list-group-item d-flex justify-content-between border-0">
                            <div className="fw-bold">Descuento</div>
                            <div>
                              { 
                                descuento[keys]
                                ? descuento[keys] +
                                "% para la entrada " +
                                entradas[keys].nombre
                                : ""
                              }
                            </div>
                          </li>
                        );
                      }
                    })
                  : ""}
                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">Total</div>
                  <div>
                    ARS{" "}
                    {oldSubTotal > 500
                      ? Math.round(subTotal + (subTotal / 100) * 10)
                      : Math.round((subTotal + 50))}
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-12 my-3">
              <button
                className="btn btn-custom-amarillo  py-3 w-100"
                onClick={(event) => CheckOutPayments(event)}
                disabled={loading}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div class="spinner-grow mx-2" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <span>Procesando pago</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span>Confirmar pago</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MedioDePago;
