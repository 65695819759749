const ModalAddGaleria = ({
  data,
  form,
  setForm,
  provincias,
  onClick,
  setSteps,
  steps,
  alert,
  loading
}) => {
  const initialState = {
    id: "",
    created_at: "",
    imagen: "",
    posicion: "",
    provincia: "",
    nombre: "",
    stated: "",
    url: "",
    archivo: "",
    vigencia: null,
  };

  const {
    id,
    created_at,
    imagen,
    posicion,
    provincia,
    nombre,
    stated,
    url,
    vigencia,
    archivo,
    descripcion,
  } = data ? data : initialState;

  //Eventos
  const onChange = (e) => {
    switch (e.target.name) {
      case "url":
      case "descripcion":
      case "provincia":
      case "posicion":
      case "estado":
      case "nombre":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value
          })
        }
        break;
      case "imagen":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            imagen_url: URL.createObjectURL(e.target.files[0]),
          })
        }
        break;
      case "archivo":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            archivo_url: URL.createObjectURL(e.target.files[0]),
          })
        }
        break;
      case "vigencia_start":{
        let array = form.vigencia
        ? form.vigencia
        : vigencia? JSON.parse(vigencia) : []
        array[0] = e.target.value;

        setForm({
          ...form,
          vigencia: array
        })
      } break;
      case "vigencia_end":{
        let array = form.vigencia
        ? form.vigencia
        : vigencia? JSON.parse(vigencia) : []

        array[1] = e.target.value;

        setForm({
          ...form,
          vigencia: array
        })
      }break;
    }
  };

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div className="">
              <h1 className="fs-2">Agrega nuevo contenido a tus secciones</h1>
              <span class="fs-6 text-muted">
                Agrega contenido a cada seccion
              </span>
            </div>
            <div>
              <label class="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none border-0 bg-transparent"
                  name="close_modal"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4">
            <div className="row bg-white p-3" style={{ borderRadius: "20px" }}>
              <div class="mb-3 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Nombre
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Escribe tu nombre"
                  defaultValue={nombre}
                  name="nombre"
                  onChange={(event) => onChange(event)}
                />
                {
                  data
                  ? ""
                  : alert
                  ? form.nombre == ""
                  ? <span className="text-danger">Falta completar el campo nombre</span>
                  : ""
                  : ""
                }
              </div>
              <div class="mb-3 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Url
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Url direccionado"
                  defaultValue={url}
                  name="url"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div class="mb-3 col-md-12">
                <label for="exampleFormControlInput1" class="form-label">
                  Describe tu item
                </label>
                <textarea
                  type="email"
                  class="form-control"
                  placeholder="Descripcion..."
                  defaultValue={descripcion}
                  name="descripcion"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Provincia
                </label>
                <select class="form-select" aria-label="Default select example"
                name="provincia"
                onChange={(event) => onChange(event)}>
                  <option selected>
                    {provincia == "" ? "Selecciona una provincia" : provincia}
                  </option>
                  {provincias.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))}
                </select>
                {
                  data
                  ? ""
                  : alert
                  ? form.provincia == ""
                  ? <span className="text-danger">Falta completar el campo provincia</span>
                  : ""
                  : ""
                }
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Posición
                </label>
                <select class="form-select" aria-label="Default select example"
                name="posicion"
                onChange={(event) => onChange(event)}>
                  <option selected>
                    {posicion == "" ? "Selecciona una posición" : posicion}
                  </option>
                  <option value="CARUSEL">CARUSEL</option>
                  <option value="FESTIVAL">FESTIVAL</option>
                </select>
                {
                  data
                  ? ""
                  : alert
                  ? form.posicion == ""
                  ? <span className="text-danger">Falta completar el campo posición</span>
                  : ""
                  : ""
                }
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Estado
                </label>
                <select class="form-select" aria-label="Default select example"
                name="estado"
                onChange={(event) => onChange(event)}
                >
                  <option selected>
                    {stated == "" ? "Selecciona un estado" : stated}
                  </option>
                  <option value="ACTIVO">Activo</option>
                  <option value="INACTIVO">Inactivo</option>
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <img
                  src={
                    imagen == ""
                      ? form.imagen_url == ""
                        ? "https://antesala.com.ar/assets/img/galeria/" + imagen
                        : form.imagen_url
                      : form.imagen_url == ""
                      ? "https://antesala.com.ar/assets/img/galeria/" + imagen
                      : form.imagen_url
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-6 text-center mb-3">
                {archivo || form.archivo_url !== "" ? (
                  <a
                    href={
                      archivo
                        ? form.archivo_url
                          ? form.archivo_url
                          : "/assets/pdf/" + archivo
                        : form.archivo_url
                        ? form.archivo_url
                        : "/assets/pdf/" + archivo
                    }
                    target="_blank"
                    className="btn btn-dark rounded-pill"
                  >
                    Descargar archivo
                  </a>
                ) : (
                  <button className="btn btn-dark rounded-pill">
                    Sin archivo
                  </button>
                )}
              </div>

              <div className="col-md-6">
                <div class="mb-3">
                  <label for="formFile" class="form-label">
                    Sube tu imagen
                  </label>
                  <input class="form-control" type="file" 
                  name="imagen"
                  onChange={(event) => onChange(event)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div class="mb-3">
                  <label for="formFile" class="form-label">
                    Sube tu archivo. PDF
                  </label>
                  <input class="form-control" type="file" 
                  name="archivo"
                  onChange={(event) => onChange(event)}
                  />
                </div>
              </div>

              <div class="mb-3 col-md-6 col-12">
                <label for="exampleFormControlInput1" class="form-label">
                  Vigencia
                </label>
                <input
                  type="date"
                  class="form-control mb-2"
                  defaultValue={vigencia ? JSON.parse(vigencia)[0] : ""}
                  name="vigencia_start"
                  onChange={(event) => onChange(event)}
                />
                <input
                  type="date"
                  class="form-control"
                  defaultValue={vigencia ? JSON.parse(vigencia)[1] : ""}
                  name="vigencia_end"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <label class="btn btn-secondary rounded-pill px-4">
                  cerrar
                  <button
                    class="d-none btn btn-secondary rounded-pill px-4"
                    name="close_modal"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                {data ? (
                  <label className="ms-2 btn btn-custom-amarillo text-white">
                    Aplicar cambios
                    <button
                      name="update"
                      onClick={(event) => onClick(event)}
                      className="d-none"
                    ></button>
                  </label>
                ) : 
                  loading
                  ? <label className="ms-2 btn btn-custom-amarillo text-white disabled">
                    Cargando...
                    <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                    </div>
                </label>
                  : <label className="ms-2 btn btn-custom-amarillo text-white">
                  Guardar
                  <button
                    name="create"
                    onClick={(event) => onClick(event)}
                    className="d-none"
                  ></button>
                </label>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddGaleria;
