import clientAxios from "./Config";
import Swal from "sweetalert2";
import AlertSuccess from "../Alerts/AlertSucces";


export const AdministradorCrearNotificacion = async (dataForm, setLoading) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Notificaciones/Crear',
    data: dataForm
  }).then(async (result) => {
    setLoading(true);
    AlertSuccess("Enviando notificacion...")

    return true
  }).catch((result) => {
    setLoading(false);

    return Swal.fire({
      text: "Ocurrio un error, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

  return response;


}