import { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { CambiarContraseña } from "../../../helpers/AccessHelper";
import Swal from "sweetalert2";

const RestaurarPassword = () => {
  const { token } = useParams();
  const [pass, setPass] = useState({
    icon: "bi bi-eye-slash",
    type: "password",
    status: false
  });

  const [form, setForm] = useState({
    token: token,
    email: "",
    password: "",
    password_confirmation: ""
  })

  const showPassword = (e) => {
    e.preventDefault();

    if (pass.status === false) {
      setPass({
        ...pass,
        icon: "bi bi-eye",
        type: "text",
        status: true
      });
    } else {
      setPass({
        ...pass,
        icon: "bi bi-eye-slash",
        type: "password",
        status: false
      });
    }

  }

  const onChange = (e) => {
  e.preventDefault()

  setForm({
    ...form,
    [e.target.name]: e.target.value
  })

  }

  const onSubmit = (e) => {
    e.preventDefault()

    if(
      form.token.trim() == "" ||
      form.email.trim() == "" ||
      form.password.trim() == "" ||   
      form.password_confirmation.trim() == ""
      ){
        return Swal.fire({
          text: "Algunos campos se encuentran vacios!",
          timer: "2000",
          icon: "alert",
          position: "bottom",
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })
      }

      let dataForm = new FormData();

      dataForm.append("token", form.token);
      dataForm.append("email", form.email);
      dataForm.append("password", form.password);
      dataForm.append("password_confirmation", form.password_confirmation);

      CambiarContraseña(dataForm);
  }

  return (
    <div className="container-md texto-normal min-vh-100 d-flex justify-content-center">

      <div className="d-flex justify-content-center mx-auto py-5">

        <form action="" className="p-0 p-md-4">
          <div className="text-center mb-3 mx-auto">
            <h1><p>Recupera tu</p><p>contraseña</p></h1>
          </div>

          <div className="mb-3">
            <label for="inputEmail" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Ingrese su Email"
              name="email"
              onChange={(event) => onChange(event)}
            />
          </div>

          <div className="mb-3">
            <label for="password" className="form-label">Nueva contraseña</label>
            <div class="input-group mb-3">
              <button
                className="input-group-text bg-light b"
                id="basic-addon1"
                onClick={(event) => showPassword(event)}
              >
                <i className={pass.icon}></i>
              </button>
              <input
                type={pass.type}
                class="form-control"
                placeholder="Ingrese su contraseña"
                aria-label="Password"
                aria-describedby="basic-addon1"
                name="password"
                onChange={(event) => onChange(event)}
              />
            </div>
          </div>

          <ul className="list-group mb-3">
            <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">
              Debe contener al menos una mayúscula (A-Z) y un número (0-9)
            </li>
            <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">Tiene que estar conformado con 6 o más caracteres</li>
          </ul>

          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">Confirmar nueva contraseña</label>
            <div class="input-group mb-3">
              <button
                className="input-group-text bg-light b"
                id="basic-addon1"
                onClick={(event) => showPassword(event)}
              >
                <i className={pass.icon}></i>
              </button>
              <input
                type={pass.type}
                class="form-control"
                placeholder="Confirme su contraseña"
                aria-label="Password"
                aria-describedby="basic-addon1"
                name="password_confirmation"
                onChange={(event) => onChange(event)}
              />
            </div>
          </div>

          <button
            className="btn btn-custom-amarillo w-100 mb-3"
            onClick={onSubmit}
          >
            Recuperar Contraseña
          </button>


          <div className="w-100 text-center">
            <a className="text-decoration-none d-block" href=""><span className="text-dark">Al ingresar acepta nuestros</span> Terminos y Condiciones</a>

            <Link className="text-decoration-none" aria-label="Ingresar"
              to="/registro"><span className="fw-light">¿No tienes una cuenta?</span><span className="fw-normal"> Obtener una cuenta </span></Link>
          </div>




        </form>

      </div>
    </div>
  );
}

export default RestaurarPassword