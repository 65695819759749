import fi from "date-fns/esm/locale/fi/index.js";
import { useContext, useEffect, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { CollectionStateContext } from "../../../context/EventosState";
import { normalizedProvince } from "../../../Functions/FunctionLocation";
import { GeolocationIp, GeolocationStack } from "../../../helpers/GeolocationHelper";
import Geolocation from "../../Dashboard/Layout/Configuracion/geolocation/geolocation";

const Cookie = () => {
  const context = useContext(CollectionStateContext);
  const { provincias,  state } = context;

  //QUERY STATE

  const [styleButtonAccept, setStyleButtonAccept] = useState("d-none btn-custom-naranja text-white me-2 mb-3 my-1 w-100 border-0");
  const [geo, setGeo] = useState({
    latitud: "",
    longitud: "",
    loads: false,
  });
  const [geolocationIp, setGeolocationIp] = useState(null);
  const LSProvincias =  localStorage.getItem("provincias");

  const [dataProvincias, setProvincias] = useState(LSProvincias? JSON.parse(LSProvincias) : state.provincias);


  //

  const onChange = (e) => {
    localStorage.setItem("geolocation", e.target.value);
    setStyleButtonAccept("btn-custom-naranja text-white me-2 mb-3 my-1 w-100 border-0")
  };

  useEffect(() => {
    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, []);


  const axios = async () => {
    let response = await GeolocationIp(); 
    response = response.hasOwnProperty("regionName")? response.regionName :  "Córdoba"
  

    let provincia = normalizedProvince(dataProvincias, response);

    if(!provincia){
      return ""
    }
    
    //localStorage.setItem("geolocation", provincia);
    //setGeolocationIp(provincia);
    setStyleButtonAccept("btn-custom-naranja text-white me-2 mb-3 my-1 w-100 border-0")
  }

  useEffect(() => {
   if(dataProvincias){
    axios()
   }
  },[])

  /*
  useEffect(() => {
    const axios = async () => {
      if(geo.loads){
        let coordinates = geo.latitud+","+geo.longitud;

        const response = await GeolocationStack(coordinates); 
        localStorage.setItem("geolocation", response.data[0].region );

        setGeolocationApi(response.data[0]);

      }
    }
    axios()
  },[geo])
  */
  return (
      <CookieConsent
        disableButtonStyles={true}
        disableStyles={true}
        enableDeclineButton={true}
        location="none"
        declineButtonText="NO"
        buttonText="Si, Acepto"
        cookieName="geolocation"
        expires={150}
        style={{zIndex: 5, maxWidth: "100%", maxHeight: "100%", top: 0,}}
        containerClasses="col-md-12 fixed-top overflow-auto min-vh-100 w-100 h-100 "
        buttonStyle={{
          maxWidth: "200px",
        }}
        declineButtonStyle={{
          maxWidth: "200px",
        }}
        buttonWrapperClasses="bg-white"
        declineButtonClasses="btn-secondary rounded-pill text-white px-3 py-2 border-0 mx-1"
        buttonClasses={styleButtonAccept}
      >
        
        <div className="modal-content bg-light border-0 texto-normal">
          <div class="m-0 bg-white p-md-4 p-1 d-flex">
            <div>
            <h1>¿Nos permites usar cookies?</h1>
          <span class="fs-6 text-muted">El uso de cookies nos permite mejorar su navegación en la web, nos otorga información de futuros problemas que puedan ocurrir. </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
              >
              </button>
            </div>
          </div>
          <div class="body p-md-4 p-1">

          <div>
            {
              geo.loads
              ? ""
              : <Geolocation setGeo={setGeo} />  
            }
          </div>  

          <div class="bg-white p-md-3 p-1">
              <label for="" class="text-muted">
                <p> Al aceptar nuestra política de servicio, aceptas que guardemos cokies con información de su región. Esto lo hacemos para mejorar la experiencia de usuario. </p>
              </label>
              <select
            className="form-select form-select border-custom-naranja rounded-pill"
            onChange={(event) => onChange(event)}
          >
            <option selected>{geo.loads? geolocationIp? geolocationIp : "Su provincia no se encuentra geo localizada, por favor seleccione uno de la lista." : geolocationIp? geolocationIp : "Su provincia no se encuentra geo localizada, por favor seleccione uno de la lista."}</option>
            {dataProvincias
              ? dataProvincias.map((item) => (
                  <option value={item.nombre}>{item.nombre}</option>
                ))
              : ""}
          </select>
            </div>


        

          </div>
          <div class="footer bg-white p-4">
        </div>
      </div>
 
    
      </CookieConsent>
  );
};

export default Cookie;
