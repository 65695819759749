import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertWarning from "../Alerts/AlertWarning";
import clientAxios from "./Config";




export const AdministradorProductores = async (
  filter_productores
) => {
  let params = {}

  if(filter_productores.search.trim() !== ""){
    params = {...params, search: filter_productores.search}
  }

  if(filter_productores.status.trim() !== ""){
    params = {...params, status: filter_productores.status}
  }

  if(filter_productores.provincia.trim() !== ""){
    params = {...params, provincia: filter_productores.provincia}
  }

  if(!filter_productores.pagination == ""){
    params.pagination = filter_productores.pagination;
  }

  const result  = await clientAxios.get(filter_productores.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(!result.data){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;
}

export const AdministradorActualizarProductores = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Productores/Actualizar',
    data: dataForm
  }).then(async (result) => {
    AlertSuccess(result.data.msg);
    return false;
  }).catch((result) => {
    setLoading(false);
    return AlertError(result.response.data.msg,"ERROR" )
  });

  return response;

}