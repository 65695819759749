import ReactHTMLTableToExcel from "react-html-table-to-excel";
//components
import TablaReportes from "./Tablas/TablaReportes";
import { totalReportEvents } from "../../../../Functions/FunctionReportes";

const Boleteria = ({
  evento,
  reportes,
  onClick,
  onChange,
  rol,
  campos
}) => {

  return (
    <>
      <div className="container-md texto-normal" style={{ maxWidth: "3000px" }}>
        <div className="row">
          <div class="col-md-4 col-12 mb-3">
            <h4>Boleteria</h4>
            <h1>{evento ? evento.nombre : ""}</h1>
          </div>

          <div className="col-md-4">
            <div
              className="bg-white p-3 text-center"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-block fw-bold text-muted">TOTAL DE VENTAS</div>
              <div>
                <span className="fs-10">ARS</span>{" "}
                {totalReportEvents(reportes ? reportes : []).total_pagos}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="bg-white p-3 text-center"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-block fw-bold text-muted">
                TOTAL DE ENTRADAS
              </div>
              <div> {totalReportEvents(reportes ? reportes : []).total_cantidad}</div>
            </div>
          </div>

          <div className="col-md-4 col-12 mb-3">
            <div class="input-group mb-3">
              <input
                type="text"
                class="rounded-pill form-control fs-6 p-0 px-2 py-3"
                name="nombre_cliente"
                onChange={(event) => onChange(event)}
              />
              <button
                class="input-group-text fs-6 px-2 p-0 border-0 bg-transparent text-dark"
                id="basic-addon2"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>

          <div className="col-md-4 col-12 mb-3">
            <select
              class="form-select rounded-pill fs-9 p-0 fs-6 px-2 py-3"
              aria-label="Default select example"
              name="fecha_entrada"
              onChange={(event) => onChange(event)}
            >
              {evento
                ? JSON.parse(evento.fechas).map((fecha) => (
                  <option value={fecha.fechas[0] + " HORA: " + fecha.hora}>
                    {fecha.fechas[0] + " HORA: " + fecha.hora}
                  </option>
                ))
                : ""}
            </select>
          </div>

          <div className="col-md-4 col-12 mb-3">
            <select
              class="form-select rounded-pill fs-6 py-3"
              aria-label="Default select example"
              name="status"
              onChange={(event) => onChange(event)}
            >
              <option selected>Seleccionar estado</option>
              <option value="ACTIVO">Activos</option>
              <option value="REEMBOLSO">Reembolsados</option>
              <option value="INACTIVO">Inactivos</option>
              <option value="PAUSADO">Pausados</option>
              <option value="LIQUIDADO">Liquidados</option>
              <option value="RECHAZADO">Rechazados</option>
            </select>
          </div>


          <div className="col-md-12 col-12 mb-3">
            <label className="btn btn-outline-success me-2">
              <i class="bi bi-filetype-xls"></i>
              <ReactHTMLTableToExcel
                id="buttonDownloadExcel"
                className="d-none"
                table="boleteriaVentas"
                filename="reporteDeVentas"
                sheet="pagina 1"
                buttonText="Exportar tabla actual"
              />
            </label>

            <label className="btn btn-outline-dark me-2">
              <i class="bi bi-share-fill"></i>
              <button className="d-none"
                name="compartir"
                onClick={(event) => onClick(event)}></button>
            </label>

            <label className="text-center btn btn-outline-dark">
                {campos.state ? "Ver menos datos" : "Ver mas datos"}
                <button
                  className="d-none"
                  name="ver_mas"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
          </div>

          {reportes ? (
            <div className="col-md-12">
              <TablaReportes
                campos={campos}
                data={reportes}
                rol={rol}
                onChange={onChange}
                onClick={onClick}
              />
            </div>
          ) : reportes == undefined ? (
            <div class="d-flex my-5 justify-content-center">
              <h2>No existen entradas vendidas para este evento</h2>
            </div>
          ) : (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          <div className="d-none" id="reader"></div>
        </div>
      </div>
    </>
  );
};

export default Boleteria;
