import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const AdministradorCupon = async (url, params) => {


  const result  = await clientAxios.get(url, {params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;
}

export const Cupon = async (data, setLoading) => {
  setLoading(true)
  let params = { codigo: data.cupon, cantidad: data.cantidad, id_evento: data.id_evento}

  const result  = await clientAxios.get("api/Cupon",{params}).then((result) => { setLoading(false); return result.data}).catch((result) => {setLoading(false); return null});

  return result;
}

export const AdministradorCuponCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Cupones/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);  
    setLoading(false)

    return result.data
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response

}


export const AdministradorCuponActualizar = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Cupones/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)

    return result.data
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}

