import clientAxios from "../helpers/Config";

const ApiRest = async ({
url,
form,
setLoading
}) => {
  setLoading(true);

  const response = await clientAxios({
    method: "post",
    url: url,
    data: form,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }).then((result) => {
    setLoading(false);
    return result
  }).catch((error) => {
    setLoading(false);
    if(error.response){
      return error.response;
    } else if (error.request){
      return error.request;
    } else {
      return error.message;
    }
  })

  return response;
}

export default ApiRest