import { useEffect, useReducer } from "react";
import { TYPES } from "../../../actions/ActionsStates";
import ObtenerCategorias from "../../../helpers/CategoriaHelper";
import ObtenerDatoFiscal from "../../../helpers/DatoFiscalHelper";
import ObtenerTodosEventos from "../../../helpers/EventHelper";
import { StateInitialState, stateReducer } from "../../../reducers/ReducerStates";
import Cookie from "../Cookie/Cookie";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Suscriptions from "../Home/Sections/Suscriptions/Suscriptions";

const Body = ({outSession, children }) => {

  //<Cookie/>
  
  return(
    <>
    <Header outSession={outSession} />
    <Cookie/>  
    <main className="m-0">
      <div className="d-md-none d-block"  style={{height: "100px"}}></div>
      <div className="d-none d-md-block"  style={{height: "200px"}}></div>
      {children}
    </main>
    <Suscriptions /> 
    <Footer />
    </>
  );
}

export default Body;