import { useContext } from "react";
import { authContext } from "../../../../context/auth/AuthContext";

import useApi from "../../../../App/Hooks/useApi";

import { useState } from "react";
import TablaReportes from "./Tablas/TablaReportes";
import Filtros from "./Filtros/Filtros";
import { ActualizarReporte } from "../../../../helpers/ReporteHelper";
import { totalReportEvents } from "../../../../Functions/FunctionReportes";
import ModalInfoDecidir from "../../Modals/ModalInfoDecidir";

const Estadisticas = () => {
  //Referencia a  datos de reportes
  const { user } = useContext(authContext);
  const { rol, status, auth } = user;

  //States
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);

  //formularios
  const [form, setForm] = useState({
    //Api decidir
    api_establecimiento_id: "",
  });

  //Modals
  const [modal_decidir, setModalDecidir] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  //filtros
  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  //Parametros
  const [params, setParams] = useState({
    id_evento: "",
    search: "",
    sala: "",
    fecha_entrada: "",
    date_range: [],
    provincia: "",
    //static
    url: "api/auth/Reportes/All",
    pagination: "50",
    cupon: "",
    estado: "ACTIVO",
  });

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);

  const nPages = data ? Math.ceil(data.length / recordsPerPage) : 0;
  let currentData = data
    ? data.length > 0
    ? data.slice(indexOfFirstRecord, indexOfLastRecord)
    : [] 
    : [];
  //Eventos

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          

          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });

              setSteps(steps + 1);
            }
          }

          if(e.target.value == ""){
            setParams({
              ...params,
              [e.target.name]: "",
            });

            setSteps(steps + 1);
          }
        }
        break;
      case "status":
        {
          setParams({
            ...params,
            estado: e.target.value,
          });

          setSteps(steps + 1);
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      //Modals
      case "info_decidir":
        {
          setForm({
            ...form,
            api_establecimiento_id: value,
          })

          setModalDecidir(true);
        }
        break;
      case "close":
        {
          setModalDecidir(false);
        }
        break;
      //paginate
      case "next_page":
        {
          if (currentPage !== nPages) setCurrentPage(currentPage + 1);
        }
        break;
      case "prev_page":
        {
          if (currentPage !== 1) setCurrentPage(currentPage - 1);
        }
        break;
      //params
      case "change_status":
        {
          let dataForm = new FormData();

          let stringId = "id[" + 0 + "]";
          dataForm.append(stringId, value.id);

          dataForm.append("status", value.status);
          const response = await ActualizarReporte(dataForm, setLoading);

          setSteps(steps + 1);
        }
        break;
    }
  };

  return (
    <>
      <div className="container-md texto-normal" style={{ maxWidth: "3000px" }}>
        <div className="row">
          <div class="col-md-3 mb-3">
            <h2>Reportes</h2>
            <span class="text-muted">Reportes generales de tus eventos.</span>
          </div>

          <div className="col-md-3 mb-3">
            <div
              className="bg-white p-3 text-center"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-block fw-bold text-muted">Total de ventas</div>
              <div>
                <span className="fs-6">$</span>{" "}
                {totalReportEvents(data ? data : []).total_pagos.toFixed(2)}
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-3">
            <div
              className="bg-white p-3 text-center"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-block fw-bold text-muted">
                Total de entradas
              </div>
              <div> {totalReportEvents(data ? data : []).total_cantidad}</div>
            </div>
          </div>

          <div className="col-md-3 mb-3">
            <div
              className="bg-white p-3 text-center"
              style={{ borderRadius: "20px" }}
            >
              <div className="d-block fw-bold text-muted">
                Total de descuento
              </div>
              <div>
                <span className="fw-bold text-muted">Bruto</span> ${" "}
                {totalReportEvents(data ? data : []).total_bruto.toFixed(2)}
              </div>
              <div>
                <span className="fw-bold text-muted">Descontado</span> ${" "}
                {totalReportEvents(data ? data : []).total_descuento.toFixed(2)}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <Filtros onChange={onChange} onClick={onClick} />
          </div>

          <div className="col-md-12">
            <div className="d-flex justify-content-end">
              <label className="btn text-custom-naranja p-0 fs-1 me-1">
                <i class="bi bi-arrow-up-square-fill"></i>
                <button
                  className="d-none"
                  name="prev_page"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
              <label className="btn text-custom-naranja p-0 fs-1">
                <i class="bi bi-arrow-down-square-fill"></i>
                <button
                  className="d-none"
                  name="next_page"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
            </div>
          </div>

          {data 
          ? data.length > 0 
          ?  (
            <div className="col-md-12">
              <TablaReportes
                data={currentData}
                onClick={onClick}
                onChange={onChange}
              />
            </div>
          ) 
          : (
            <div class="d-flex justify-content-center  align-items-center min-vh-100">
              <span>No existen entradas</span>
          </div>
          )
          : (
            <div class="d-flex justify-content-center  align-items-center min-vh-100">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div className="col-md-12">
            <div className="d-flex justify-content-end">
              <label className="btn text-custom-naranja p-0 fs-1 me-1">
                <i class="bi bi-arrow-up-square-fill"></i>
                <button
                  className="d-none"
                  name="prev_page"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
              <label className="btn text-custom-naranja p-0 fs-1">
                <i class="bi bi-arrow-down-square-fill"></i>
                <button
                  className="d-none"
                  name="next_page"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>
            </div>
          </div>
        </div>

        {modal_decidir ? (
          <ModalInfoDecidir onClick={onClick} onChange={onChange} data={form} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Estadisticas;
