import { useParams } from "react-router-dom"

const ConfirmRegistro= () => {

  const {info} = useParams();

  //console.log(info);

  return(
    <div className="min-vh-100">
    <div className="container p-5 shadow-sm text-center" style={{borderRadius: "10px"}}>  
    <div className="text-success" style={{fontSize: "100px"}}>
    <i class="bi bi-envelope-check"></i>
    </div>
    <div className="fs-5">
      <p className="fw-bold fs-4">¡Registro realizado con exito!</p>
      <p>Se enviará un correo de confirmación a la cuenta <span className="fw-bold">{info}</span>.</p>
    </div>
  </div>
  </div>
  )
}

export default ConfirmRegistro