import { useEffect, useState } from "react";
import AlertWarning from "../Alerts/AlertWarning";
import clientAxios from "../helpers/Config";

const useApi = (url, parametros, steps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null); 
  const [data, setData] = useState("");

  let params = {};

  Object.keys(parametros).map((p) => {
    if(p !== "date_range"){
      if(String(parametros[p]).trim() !== ""){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    } else {
      if(parametros[p].length !== 0){
        params = {
          ...params,
          [p]: parametros[p] 
        } 
      }
    }
  })

  const doAxios = async () => {
    const response = await clientAxios.get(url, { params })
    .then((result) => {
      setIsLoading(false);

      let array = [];

      if (result.data.hasOwnProperty('data')) {
       if(!result.data.data.length){
        Object.keys(result.data.data).map((key) => {
          array.push(result.data.data[key]);
        });
    
        result.data.data = array;
      }
      }

      setData(result.data);
      return "";
    })
    .catch((result) => {
      setIsLoading(false);
      setMessage(result.response.data.msg);
      return AlertWarning(result.response.data.msg, "Advertencia");
    });

    

  }


  useEffect(() => {
    doAxios()
  },[steps, parametros])
  return {
    data,
    message,
    isLoading,
  }
}

export default useApi