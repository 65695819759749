import { useContext, useEffect, useReducer, useState } from "react";
import { CollectionStateContext } from "../../../../../context/EventosState";
import { Link } from "react-router-dom";

const Actualidad = () => {
  /*
  const context = useContext(CollectionStateContext);
  const { salas, state } = context;
  const geolocation = localStorage.getItem("geolocation");
  const [dataSalas, setSalas] = useState(state.salas);
  const [filter, setFilter] = useState({
    id: "",
    value: "",
    search: "",
    provincia: geolocation? geolocation : "",
    localidad: "",
    filtro: "true",
    select_id: "",
});
    const [search, setSearch] = useState(false); 

  const onChange = (e, id) => {
    switch (e.target.name) {
      case "search":
        {
          setFilter({
            ...filter,
            [e.target.name]: e.target.value,
            filtro: "true",
          });

          if(e.target.value.trim() == ""){
            setSearch(false)
          } else {
              setSearch(true)
          }
        }
        break;
        case "select_id":
        {
          setFilter({
            ...filter,
            search: e.target.value,
            [e.target.name]: id,
          });

          setSearch(false)
        }
        break;
    }
  };

  useEffect(() => {
    salas(
      setSalas,
      filter.id,
      filter.search,
      filter.provincia,
      filter.localidad,
      filter.filtro
    );
  }, [filter]);
  */
  return null
  /*return (
    <section id="actualidad" class="pt-5 pb-5">
      <div class="container">
        <div class="row pb-3 texto-normal d-none">
          <div
            class="btn col-md-6 pt-md-5 pb-md-5 py-3 bg-naranja text-center wow bounceInLeft"
            title="Ir a la sala"
            style={{borderRadius: "20px"}}
          >
              <h1 class="text-white fs-1 mb-0">CARTELERA</h1>
              <p class="Grifter fc-blanco mb-0">POR SALAS</p>
          </div>

          <div 
            style={{borderRadius: "20px"}} class="col-md-6 pt-md-5 pb-md-5 position-relative py-5  d-flex align-items-center  bg-gris pe-4 ps-4  wow bounceInRight">
            <input
              className="form-control form-control-xl"
              type="text"
              name="search"
              value={filter.search}
              placeholder="Busca tu sala..."
              autocomplete="off"
              onChange={(event) => onChange(event)}
            />
            <Link to={"/sala/"+filter.select_id} className="btn btn-custom-naranja text-white form-search px-3 ms-1" style={{borderRadius: "10px"}}>
            <i class="bi bi-search"></i>
            </Link>
              {
              !search? (
                ""
              ) : (
                <div
                className="w-100 pe-4 ps-4 border-0  position-absolute overflow-hidden"
                style={{ top: "145px", left: "0px", zIndex: "2" }}
              >
                <ul class="list-group border-0 border-end border-start border-bottom bg-white">
                  {
                  !dataSalas?
                  ""
                  :
                  dataSalas.map((item) => (
                    <li class="list-group-item list-group-item-action border-0 p-0">
                      <button
                        className="bg-transparent border-0 w-100"
                        name="select_id"
                        value={item.nombre}
                        onClick={(event) => onChange(event, item.id)}
                      >
                        {item.nombre}
                      </button>
                    </li>
                  ))}
                </ul>
          </div>
              )}
            </div>
        </div>

        <div class="row d-none">
          <div class="col-12">
            <h1 class="mb-4 wow bounceInLeft">Actualidad</h1>
          </div>
          <div class="col-md-4 mb-4 wow bounceInUp">
            <div class="card">
              <img src="assets/img/img-box-01.png" width="100%" />
              <div class="card-body">
                <h1 class="fc-blanco">La cochera gana un premio konex</h1>
              </div>
              <div class="card-overlay">
                <div class="card-overlay-inner">
                  <p class="GothamLight fc-blanco m-0">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore et
                    accumsan et iusto odio dignissim qui blandit praesent
                    luptatum zzril
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4 wow bounceInUp">
            <div class="card">
              <img src="assets/img/img-box-01.png" width="100%" />
              <div class="card-body">
                <h1 class="fc-blanco">La cochera gana un premio konex</h1>
              </div>
              <div class="card-overlay">
                <div class="card-overlay-inner">
                  <p class="GothamLight fc-blanco m-0">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore et
                    accumsan et iusto odio dignissim qui blandit praesent
                    luptatum zzril
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4 wow bounceInUp">
            <div class="card">
              <img src="assets/img/img-box-01.png" width="100%" />
              <div class="card-body">
                <h1 class="fc-blanco">La cochera gana un premio konex</h1>
              </div>
              <div class="card-overlay">
                <div class="card-overlay-inner">
                  <p class="GothamLight fc-blanco m-0">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore et
                    accumsan et iusto odio dignissim qui blandit praesent
                    luptatum zzril
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-none">
          <div class="col-12 text-center pb-100 pt-100 wow bounceInUp">
            <h1>
              ¿Querés saber más? <br />
              Toda la info en un solo lugar
            </h1>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="podcast.php"
              class="circulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">podcast</h4>
            </a>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="#"
              class="circulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">videos</h4>
            </a>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="#"
              class="circulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">
                entrevistas y notas
              </h4>
            </a>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="#"
              class="circulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">cartelera</h4>
            </a>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="#"
              class="circulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">salas</h4>
            </a>
          </div>
          <div class="col-md-2 d-flex justify-content-center wow bounceInUp d-none d-md-block">
            <a
              href="#"
              class="circulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">cursos</h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="podcast.php"
              class="rectangulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">podcast</h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="#"
              class="rectangulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">videos</h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="#"
              class="rectangulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">
                entrevistas y notas
              </h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="#"
              class="rectangulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">cartelera</h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="#"
              class="rectangulo-enlace bg-black d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">salas</h4>
            </a>
          </div>
          <div class="col-12 d-flex justify-content-center mb-4 wow bounceInUp d-block d-md-none">
            <a
              href="#"
              class="rectangulo-enlace bg-naranja d-flex align-items-center justify-content-center"
            >
              <h4 class="Grifter fc-blanco text-center m-0">cursos</h4>
            </a>
          </div>
        </div>
      </div>
    </section>
  );*/
};

export default Actualidad;
