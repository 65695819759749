import { useContext } from "react";
import { authContext } from "../../context/auth/AuthContext";
import { Navigate } from "react-router-dom";

import NavSidebar from "./NavBar/NavSidebar"

const Dashboard = ({children}) => {
  let user = useContext(authContext); 
  const usuario = user.user;

  if(usuario.data == null){
    return <Navigate to="/ingresar" />
  }

  return (
    <>
    <NavSidebar usuario={usuario}>
      <>{children}</>
    </NavSidebar>
    </>
  )
}

export default Dashboard