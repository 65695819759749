import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

const Cookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(() => {
    if(!cookies.user){
        Swal.fire({
            position: "bottom-end",
            title: "¿Nos permitís usar cookies?",
            html: '<p>El uso de cookies nos permite mejorar su navegación en la web, nos otorga información de futuros problemas que puedan ocurrir.<p><p>Al aceptar nuestra <a href="">Politica de servicio</a>, aceptás que guardemos cookies con informacion de tu región. Esto lo hacemos para mejorar tu experiencia en nuestro sitio.</p>',
            customClass: {
              container: "w-100 text-start",
              popup: "w-100 bg-dark text-white text-start",
              confirmButton: "btn bg-custom-naranja rounded-pill py-3 px-3",
              denyButton: "btn btn-dark border rounded-pill py-3 px-3",
            },
            denyButtonColor: "#232323",
            confirmButtonColor: "#d0552a",
            showDenyButton: true,
            confirmButtonText: "Aceptar todas",
            denyButtonText: "Rechazar todas",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              return setCookie("user", true);
            } else if (result.isDenied) {
              return null;
            }
          });
    }
  }, []);

  return null;
};

export default Cookie;
