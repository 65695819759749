import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import AlertWarning from "../../../Alerts/AlertWarning";
import { authContext } from "../../../context/auth/AuthContext";
import {
  Totales,
  validacionEntradas,
} from "../../../Functions/FunctionPasarela";
import { Payment } from "../../../helpers/DecidirHelper";
import Alerts from "../../../resource/web/components/Alerts/Alerts";
import RestApi from "../../../resource/helpers/RestApi";

const UsoMembresia = () => {
  const user = useContext(authContext);
  const { rol, comisiones } = user.user;
  const userData = user.user.data;
  const membresias = userData ? userData.membresias : [];

  //Encode data
  const { info } = useParams();
  let infoEncode = info.replace("-", "/");
  infoEncode = JSON.parse(decodeURIComponent(escape(atob(infoEncode))));
  const { entradas, evento } = infoEncode;

  //Tasa
  const { tasa } = evento;

  //states
  const [err_payment, setErrPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [steps, setSteps] = useState(0);
  const [statePayment, setStatePayment] = useState(false);
  const [cupon, setCupon] = useState({
    codigo: "",
    descuento: "",
    estado: "",
    fechas: "",
  });

  //formulario
  const [form, setForm] = useState({
    nombre: userData ? userData.nombre + " " + userData.apellido : "",
    email: userData ? userData.email : "",
    telefono: userData ? userData.telefono : "Telefono no registrado",
    membresia: null,
    membresia_id: "",
  });

  //
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "confirmar":
        {
          //setLoading(true);

          if (!form.membresia) {
            setLoading(false);
            setAlert(true);
            return AlertWarning(
              "Todos los campos son obligatorios",
              "Advertencia"
            );
          }

          console.log(entradas);

          //formulario

          let formData = new FormData();
          formData.append("codigo", form.membresia.code);

          entradas.map((e, index) => {
            formData.append("entradas[" + index + "][id]", e.id);
            formData.append("entradas[" + index + "][cantidad]", e.cantidad);
            formData.append("entradas[" + index + "][fecha]", e.fecha_funcion);
          });

          const response = await RestApi({
            url: "/payments/membership",
            formData: formData,
            method: "post",
            setLoading: setLoading,
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            setStatePayment(true);
            return Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });
          } else {
            if (typeof response.data.msg === "string") {
              setErrPayment({
                status: "404",
                reason: response.data.msg,
              });

              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "200",
                type: "error",
              });
            }

            Object.keys(response.data.msg).map((key) => {
              setErrPayment({
                status: "404",
                reason: response.data.msg[key][0],
              });

              return Alerts({
                msg: "Alert",
                title: response.data.msg[key][0],
                code: "401",
                type: "error",
              });
            });
          }

          /*
          let formData = {
            nombre: form.nombre,
            dni: form.numero,
            telefono: form.telefono? form.telefono : "Telefono no registrado",
            token: "sin token",
            email: form.email,
            bin: "sin token",
            dni: "Dni sin registrar",
            payment_method: 0,
            //entradas
            id_evento: evento.id,
            entradas: JSON.stringify(entradas),
            //Membresia
            membresia: form.membresia.code
          };
          

          Payment(formData, setLoading, setStatePayment, setErrPayment);
          */
        }
        break;
      case "membresia":
        {
          setForm({
            ...form,
            membresia: value,
            membresia_id: value.id,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;
    }
  };

  useEffect(() => {
    if (!userData) {
      return (window.location.href = "/");
    }
  }, []);

  if (err_payment) {
    return (
      <div className="">
        <div
          className="container p-5 shadow-sm text-center"
          style={{ borderRadius: "10px" }}
        >
          <div className="text-danger" style={{ fontSize: "100px" }}>
            <i class="bi bi-exclamation-diamond-fill"></i>
          </div>
          <div className="fs-5">
            <p className="fw-bold fs-4">Entrada rechazada</p>
            <ul class="list-group">
              <li class="list-group-item border-0 text-danger">
                Estado: {err_payment.status}
              </li>
              <li class="list-group-item border-0 text-danger">
                Razón: {err_payment.reason}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {statePayment ? (
        <div className="">
          <div
            className="container p-5 shadow-sm text-center"
            style={{ borderRadius: "10px" }}
          >
            <div className="text-success" style={{ fontSize: "100px" }}>
              <i class="bi bi-cart-check"></i>
            </div>
            <div className="fs-5">
              <p className="fw-bold fs-4">¡Compra realizada con éxito!</p>
              <p>
                Se enviará un correo a la cuenta{" "}
                <span className="fw-bold">{form.email}</span> con los datos de
                su compra. Si tienes una cuenta registrada podras ver el
                historial de compras desde tu perfil.
              </p>
              {userData ? (
                <p>
                  <a className="fw-bold" href="/dashboard/espectador">
                    Ver mis entradas
                  </a>
                </p>
              ) : (
                <p>
                  <a className="fw-bold" href="/ingresar">
                    Inicia sesión en tu cuenta para acceder a tus entradas{" "}
                  </a>{" "}
                  o
                  <a className="fw-bold" href="/registro">
                    {" "}
                    Regístrate
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="container-md texto-normal">
          <div className="row">
            <div className="col-md-12 py-3 text-center">
              <span className="fs-3 fw-bold">Formulario de pago seguro</span>
              <span className="fs-5 text-muted d-block">
                Pago seguro con encriptado SSL
              </span>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row w-100 m-0 p-0">
                <div className="col-md-12 m-0 p-0 mb-3 ">
                  <div className="text-bold fs-6 w-100 ">
                    Titular
                    <span className="fw-normal text-dark">
                      <span className="d-block fs-7 text-muted">
                        Nombre y apellido tal cual aparece al frente de tu
                        tarjeta.
                      </span>
                      <input
                        className="form-control px-2 border "
                        type="text"
                        defaultValue={
                          form.card_holder_name == ""
                            ? form.nombre
                            : form.card_holder_name
                        }
                        placeholder="Ingresa tu nombre y apellido..."
                        disabled={true}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-md-12 m-0 p-0  mb-3">
                  <div className="text-bold fs-6 w-100">
                    Email
                    <span className="fw-normal text-dark">
                      <span className="d-block fs-7 text-muted">
                        La información de operación se enviara a esta dirección.
                      </span>
                      <input
                        className="form-control px-2 border "
                        type="text"
                        defaultValue={form.email}
                        placeholder="Ingresa tu email..."
                        name="email"
                        onChange={(event) => onChange(event)}
                        disabled={true}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-md-12 m-0 p-0  mb-3">
                  <div className="text-bold fs-6 w-100">
                    Telefono{" "}
                    <span className="fw-normal text-dark">
                      <span className="d-block fs-7 text-muted">
                        introduce tu número para que podamos contactarte.
                      </span>
                      <input
                        className="form-control px-2 border "
                        type="number"
                        defaultValue={form.telefono}
                        placeholder="Ingresa tu telefono..."
                        name="telefono"
                        onChange={(event) => onChange(event)}
                        disabled={true}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 fw-bold fs-5 mb-1">
                      Lista de membresias activas
                    </div>
                    {membresias.map((me) => {
                      if (form.membresia_id == me.id) {
                        return (
                          <div className="col-md-12">
                            <label
                              className="btn border border-custom-naranja bg-naranja hover-card w-100"
                              style={{ borderRadius: "10px" }}
                              title="seleccionar membresia"
                            >
                              <ul class="list-group bg-transparent border-0">
                                <li class=" text-white list-group-item bg-transparent border-0 p-1 text-start">
                                  Nombre: {me.name}
                                </li>
                                <li class=" text-white list-group-item bg-transparent border-0 p-1 text-start">
                                  Limite: {me.limit}
                                </li>
                                <li class=" text-white list-group-item bg-transparent border-0 p-1 text-start">
                                  Provincia: {me.region}
                                </li>
                              </ul>
                              <button
                                className="d-none"
                                name="membresia"
                                onClick={(event) => onClick(event, me)}
                              ></button>
                            </label>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-md-12">
                            <label
                              className="btn border border-custom-naranja hover-card w-100"
                              style={{ borderRadius: "10px" }}
                              title="seleccionar membresia"
                            >
                              <ul class="list-group border-0">
                                <li class="list-group-item border-0 p-1 text-start">
                                  Nombre: {me.name}
                                </li>
                                <li class="list-group-item border-0 p-1 text-start">
                                  Limite: {me.limit}
                                </li>
                                <li class="list-group-item border-0 p-1 text-start">
                                  Provincia: {me.region}
                                </li>
                              </ul>
                              <button
                                className="d-none"
                                name="membresia"
                                onClick={(event) => onClick(event, me)}
                              ></button>
                            </label>
                          </div>
                        );
                      }
                    })}

                    {alert ? (
                      !form.membresia ? (
                        <span className="text-danger d-block">
                          Seleccione una membresia disponible
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <ul className="list-group">
                <li className="list-group-item border-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={"/assets/img/eventos/" + evento.imagen}
                      className="img-fluid"
                      alt="IMAGEN NO ENCONTRADA"
                    />
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-center align-items-center border-0">
                  <span className="fs-4">{evento.nombre}</span>
                </li>

                <li className="list-group-item border-0 fw-bold">
                  <span>Detalles de tu compra</span>
                </li>
                {validacionEntradas(entradas, cupon).map((entrada) => (
                  <ul
                    className="list-group mb-3 border p-2"
                    style={{ borderRadius: "20px" }}
                  >
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Función</div>
                      <div>{entrada.fecha_funcion}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Entrada</div>
                      <div>
                        {entrada.nombre}
                        {entrada.multiplicador ? (
                          <span className="text-custom-naranja">
                            {" X" + entrada.multiplicador}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Cantidad</div>
                      <div>{entrada.cantidad}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">Precio</div>
                      <div>
                        <span className="fs-6">$</span> {entrada.precio}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between border-0">
                      <div className="fw-bold">SubTotal</div>
                      <div className="text-nowrap d-flex">
                        <span className="fs-6">$ </span>
                        {cupon.estado == "ACTIVO" &&
                        entrada.total_descontado ? (
                          <div className="ms-2">
                            <span className="text-decoration-line-through">
                              {" "}
                              {" " + entrada.total}
                            </span>
                            <span> {" " + entrada.total_descontado}</span>
                          </div>
                        ) : (
                          <span className="ms-1">{entrada.total}</span>
                        )}
                      </div>
                    </li>
                  </ul>
                ))}

                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">SubTotal</div>
                  <div>
                    <span className="fs-6">$</span>{" "}
                    {Totales(entradas, cupon, tasa).subTotal}
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="fw-bold">
                    +Serv ({Totales(entradas, cupon, tasa).tipo})
                  </div>
                  <div>
                    <span className="fs-6">$</span>{" "}
                    {Totales(entradas, cupon, tasa).serv}
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0 ">
                  <div className="fw-bold">Total</div>
                  <div className="text-nowrap d-flex">
                    <span className="fs-6">$</span>
                    {Totales(entradas, cupon, tasa).total_descontado > 0 ? (
                      <div className="ms-2">
                        <span className="text-decoration-line-through">
                          {Totales(entradas, cupon, tasa).total}
                        </span>
                        <span>
                          {" " +
                            Totales(entradas, cupon, tasa).total_descontado}
                        </span>
                      </div>
                    ) : (
                      Totales(entradas, cupon, tasa).total
                    )}
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-12 my-3">
              <label
                className={
                  loading
                    ? "btn btn-custom-amarillo  py-3 w-100 disabled"
                    : "btn btn-custom-amarillo  py-3 w-100"
                }
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div class="spinner-grow mx-2" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <span>Procesando entrada</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span>Confirmar entrada</span>
                  </div>
                )}
                <button
                  className="d-none"
                  name="confirmar"
                  onClick={(event) => onClick(event)}
                  disabled={loading}
                ></button>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsoMembresia;
