import { useContext } from "react";
import { authContext } from "../../../context/auth/AuthContext";
import { select_item } from "../../../Functions/FunctionGaleria";

const Fiestas = () => {
  const { user } = useContext(authContext);
  const { galeria } = user;

  const festivales = select_item(galeria, "FESTIVAL");

  console.log(festivales);
  return (
    <div>
      <section class="wow bounceInUp">
        <div class="container">
          <div class="row">
            <div class="col-8 col-md-9 pe-0">
              <div class="card-ticket cardLeft d-flex flex-column">
                <h1 class="fc-blanco">Festivales</h1>
                <div class="title mb-5">
                  <h2>Antesala</h2>
                  <span>sala</span>
                </div>
                <div>
                  <div class="seat">
                    <h2>156</h2>
                    <span>asiento</span>
                  </div>
                  <div class="time">
                    <h2>12:00</h2>
                    <span>hora</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-3 ps-0">
              <div class="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                <div class="text-center">
                  <img src="assets/img/iso-antesala.svg" alt="" />
                </div>
                <div class="number">
                  <h3>156</h3>
                  <span>asiento</span>
                </div>
                <div class="text-center">
                  <img class="barchart" src="assets/img/barcode.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="podcast" class="mt-100 mb-5">
        <div class="container">
          <div className="fs-1 w-100 text-center fw-bold">
            Festivales actuales
          </div>

          {festivales.new.map((festival) => (
            <div class="bg-naranja box-multimedia mb-4 wow bounceInUp ">
              <div class="row">
                <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                  <div>
                    <p className="text-white  fs-5 mb-3">
                      {festival.vigencia
                        ? "Desde " +
                          JSON.parse(festival.vigencia)[0] +
                          " hasta " +
                          JSON.parse(festival.vigencia)[1]
                        : ""}
                    </p>
                    <h2 class="fc-blanco mb-0 fs-2">{festival.nombre}</h2>
                    <hr class="hr-white-01" />
                    <p className="text-white fs-6 d-none d-md-block">
                      {festival.descripcion}
                    </p>
                  </div>
                  <div class="text-center">
                    <a
                      class="btn btn-custom-black fs-4"
                      aria-current="page"
                      href={"/assets/pdf/" + festival.archivo}
                      target="blank"
                    >
                      Descargar grilla
                    </a>
                  </div>
                </div>
                <div class="col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-centers">
                  <img
                    className="img-fluid"
                    src={"/assets/img/galeria/" + festival.imagen}
                    width="100%"
                    alt="Imagen no encontrada"
                  />
                </div>
              </div>
            </div>
          ))}

          <section className="my-5">
            <div className="container">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item ">
                  <h2
                    class="accordion-header text-center"
                    id="flush-headingOne"
                  >
                    <button
                      class="accordion-button collapsed bg-white text-dark fs-5 w-100 d-flex jusitfy-content-center"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <div className="w-100 text-center">
                        Festivales anteriores
                      </div>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      {festivales.old.map((festival) => (
                        <div class="bg-naranja box-multimedia mb-4 wow bounceInUp ">
                          <div class="row">
                            <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                              <div>
                                <p className="text-white  fs-5 mb-3">
                                  {festival.vigencia
                                    ? "Desde " +
                                      JSON.parse(festival.vigencia)[0] +
                                      " hasta " +
                                      JSON.parse(festival.vigencia)[1]
                                    : ""}
                                </p>
                                <h2 class="fc-blanco mb-0 fs-2">
                                  {festival.nombre}
                                </h2>
                                <hr class="hr-white-01" />
                                <p className="text-white fs-6 d-none d-md-block">
                                  {festival.descripcion}
                                </p>
                              </div>
                              <div class="text-center">
                                <a
                                  class="btn btn-custom-black fs-4"
                                  aria-current="page"
                                  href={"/assets/pdf/" + festival.archivo}
                                  target="blank"
                                >
                                  Descargar grilla
                                </a>
                              </div>
                            </div>
                            <div class="col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-centers">
                              <img
                                className="img-fluid"
                                src={"/assets/img/galeria/" + festival.imagen}
                                width="100%"
                                alt="Imagen no encontrada"
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                      <div class="bg-naranja box-multimedia mb-4 wow bounceInUp ">
                        <div class="row">
                          <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                            <div>
                              <p className="text-white  fs-5 mb-3">
                                Desde 01/07/2022 hasta 31/07/2022
                              </p>
                              <h2 class="fc-blanco mb-0 fs-2">
                                Programación Julio 2022 Teatro Independiente
                                Ciudad de Córdoba
                              </h2>
                              <hr class="hr-white-01" />
                            </div>
                            <div class="text-center">
                              <a
                                class="btn btn-custom-black fs-4"
                                aria-current="page"
                                href="/assets/pdf/Porgramación Julio 2022 Teatro Independiente Ciudad de Córdoba.pdf"
                                target="blank"
                              >
                                Descargar grilla
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-centers">
                            <img
                              className="img-fluid"
                              src="/assets/img/temp-invierno-cordoba.jpg"
                              width="100%"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div class="bg-gris-oscuro box-multimedia mb-4 wow bounceInUp ">
                        <div class="row">
                          <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                            <div>
                              <p className="text-white  fs-5 mb-3">
                                Desde 21/07/2022 hasta 27/07/2022
                              </p>
                              <h2 class="fc-blanco mb-0 fs-2">
                                Ciclo "Evas en Escena"
                              </h2>
                              <hr class="hr-white-01" />
                              <p className="text-white fs-6 d-none d-md-block">
                                Del jueves 21 al miércoles 27 de Julio de 2022.
                                En el marco del mes aniversario de su
                                fallecimiento entre ESPECTÁCULOS escénicos,
                                PERFORMANCES, DESMONTAJE y otras sorpresas más…
                                nos adentramos en el universo Evita. Con el lema
                                Evita multiplica presentamos diversas miradas de
                                Eva Duarte de Perón desde la perspectiva de las
                                artistas investigadoras de la escena. Los
                                diferentes abordajes de esta temática pretenden
                                ser homenaje y crítica, mito y deconstrucción,
                                desde una mirada desobediente, situada y
                                sensible. Nos reúne la necesidad de seguir
                                visibilizando y resonando los aspectos liminales
                                y las aristas incapturables- que desde nuestra
                                perspectiva de género y disidencias sexuales,
                                artistas investigadoras y militantes- se
                                multiplican hoy desde EVA.
                              </p>
                            </div>
                            <div class="text-center">
                              <a
                                class="btn btn-custom-naranja text-white fs-4"
                                aria-current="page"
                                href="/assets/pdf/Ciclo Evas en Escena.pdf"
                                target="blank"
                              >
                                Descargar grilla
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 order-1 order-lg-2  d-flex justify-content-center align-items-centers">
                            <img
                              className="img-fluid"
                              src="/assets/img/grilla-eva.jpg"
                              width="100%"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div class="bg-naranja box-multimedia mb-4 wow bounceInUp ">
                        <div class="row">
                          <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                            <div>
                              <p className="text-white  fs-5 mb-3">
                                Desde 17/07/2022 hasta 24/07/2022
                              </p>
                              <h2 class="fc-blanco mb-0 fs-2">
                                Festival monster
                              </h2>
                              <hr class="hr-white-01" />
                              <p className="text-white fs-6 d-none d-md-block">
                                Del 17 al 24 de julio se desarollará en Córdoba
                                capital el Festival Monster ¡Tan divertido que
                                asusta!, organizado por Santa Calma junto a
                                Córdoba Obras y Servicios (Coys). Desde las
                                15,30 hasta las 18 hs, Santa Calma Restó (Av.
                                Deodoro Roca 1137) invita a chicos y grandes a
                                disfrutar del jardín más lindo del Parque
                                Sarmiento de Córdoba, con las mejores propuestas
                                artísticas para estas vacaciones de invierno.
                                Todos los días habrá shows en vivo, talleres de
                                reciclaje para los más pequeños, animación, djs
                                en vivo, exposición de economía circular y una
                                feria de artesanos recicladores. Espectáculos
                                para las infancias y animación de la mano de
                                Juan Rugani. Además se realizarán talleres,
                                reciclaje y exposiciones de economía circular.
                              </p>
                            </div>
                            <div class="text-center">
                              <a
                                class="btn btn-custom-black fs-4"
                                aria-current="page"
                                href="/assets/pdf/prensa + grilla festi monster.pdf"
                                target="blank"
                              >
                                Descargar grilla
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-centers">
                            <img
                              className="img-fluid"
                              src="/assets/img/monster-festival.jpg"
                              width="100%"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div class="bg-gris-oscuro box-multimedia mb-4 wow bounceInUp ">
                        <div class="row">
                          <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                            <div>
                              <p className="text-white  fs-5 mb-3">
                                Desde 11/07/2022 hasta 17/07/2022
                              </p>
                              <h2 class="fc-blanco mb-0 fs-2">
                                11ª Festival de Teatro y Títeres "MascaChicos"
                              </h2>
                              <hr class="hr-white-01" />
                              <p className="text-white fs-6 d-none d-md-block"></p>
                            </div>
                            <div class="text-center">
                              <a
                                class="btn btn-custom-naranja text-white fs-4"
                                aria-current="page"
                                href="/assets/pdf/Mascachicos Festival.pdf"
                                target="blank"
                              >
                                Descargar grilla
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 order-1 order-lg-2  d-flex justify-content-center align-items-centers">
                            <img
                              className="img-fluid"
                              src="/assets/img/Mascachicos.jpg"
                              width="100%"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div class="bg-naranja box-multimedia mb-4 wow bounceInUp ">
                        <div class="row">
                          <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                            <div>
                              <p className="text-white  fs-5 mb-3">
                                Desde 21/07/2022 hasta 30/07/2022
                              </p>
                              <h2 class="fc-blanco mb-0 fs-2">
                                9º Festival Internacional de Teatro Breve
                              </h2>
                              <hr class="hr-white-01" />
                              <p className="text-white fs-6 d-none d-md-block">
                                Del 21 al 30 de Julio. La 9ª edición del
                                Festival Internacional de Teatro Breve presenta
                                8 obras de teatro que comienzan en simultáneo
                                con una duración de 15 minutos. El público
                                disfruta de 4 obras en una misma noche y también
                                es albergado en un espacio común donde se
                                encuentran todos, artistas y espectadores, antes
                                de las funciones, en los intervalos y al
                                finalizar. Un escenario con presentadores,
                                personajes guías que organizan, explican el
                                funcionamiento y dan comienzo a un movilización
                                que se completa en una manifestación
                                intercultural teatral única, donde interviene el
                                teatro, la música, la innovación creativa y la
                                danza contemporánea.
                              </p>
                            </div>
                            <div class="text-center">
                              <a
                                class="btn btn-custom-black fs-4"
                                aria-current="page"
                                href="/assets/pdf/Grilla Antesala- festival Breve.pdf"
                                target="blank"
                              >
                                Descargar grilla
                              </a>
                            </div>
                          </div>
                          <div class="col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-centers">
                            <img
                              className="img-fluid"
                              src="/assets/img/teatro-brave.jpg"
                              width="100%"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Fiestas;
