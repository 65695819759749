import Card from "./card/Index";

const Membresias = ({ data, params, onClick, onChange }) => {

  return (
    <div className="min-vh-100" style={{ backgroundColor: "#eaeaea" }}>
      <div className="container-md">
        <h2>Membresias</h2>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-end mb-3">
            <label className="me-2 btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
              Añadir Membresia
              <button
                className="d-none"
                name="modal"
                onClick={(event) => onClick(event)}
              ></button>
            </label>

            <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
              Añadir Usuarios a Membresia
              <button
                className="d-none"
                name="membresia_users"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>

          <div className="col-md-12 mb-3">
            <div className="row w-100">
              <div className="col-md-4">
                <label className="text-muted mb-2">Filtrar por estado</label>
                <select class="form-select" aria-label="Default select example"
                name="filtro-status"
                onChange={(event) => onChange(event)}>
                  {params.status == 1 ? <option selected>Activos</option> : <option value="1">Activos</option>}
                  {params.status == 2 ? <option selected>Inactivos</option> : <option value="2">Inactivos</option>}
                </select>
              </div>
            </div>
          </div>

          {data
            ? data.data.memberships.map((m, index) => (
                <Card
                  index={index}
                  data={m}
                  grafic={data.data.grafic.filter((g) => g.code == m.code)}
                  onClick={onClick}
                />
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Membresias;
