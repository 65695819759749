import axios from "axios";

const url = process.env.REACT_APP_API_URL;

let clienteAntesala = axios.create({
  baseURL: url
})

//HEADERS AXIOS

clienteAntesala.defaults.headers.common["Content-Type"] = "application/json";

clienteAntesala.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  return config;
});


export default clienteAntesala