import { DateRange } from "react-date-range";
import { format, add, sub } from "date-fns";
//Librerias
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Filtro = ({
  rol,
  onClick,
  onChange,
  loading,
  provincias,
  params,
  setParams,
  fecha,
  onFecha,
  steps,
  setSteps,
}) => {
  //date range
  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        {rol == 1 ? (
          <div className="d-flex ">
            <div class="dropdown m-0 p-0 me-1 d-flex justify-content-center align-items-center">
              <button
                class="btn btn-outline-dark dropdown-toggle m-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Reportes
              </button>
              <ul class="dropdown-menu">
                <li className="p-2">
                  <label className="dropdown-item p-0 m-0 btn fw-normal GothamBold mx-1 rounded-pill py-1 fs-6">
                    <i class="me-2 fa-solid fa-money-check-dollar"></i>Consultar reporte
                      Decidir
                    <button
                      className="d-none"
                      name="info_decidir"
                      onClick={(event) => onClick(event, "")}
                    >
                    </button>
                  </label>
                </li>
                <li className="p-2">
                  <label className="dropdown-item p-0 m-0 btn fw-normal GothamBold mx-1 rounded-pill py-1 fs-6">
                  <i class="me-2 fa-solid fa-file-invoice-dollar"></i>Crear reporte de venta
                  <button
              className="d-none"
              name="add_reporte"
              onClick={(event) => onClick(event, "")}
            >
            </button>
                  </label>
                </li>
                <li className="p-2">
                  <label className="dropdown-item p-0 m-0 btn fw-normal GothamBold mx-1 rounded-pill py-1 fs-6">
                  <i class="me-2 fa-solid fa-calendar-week"></i>Descargar informe por mes
                  <button
              className="d-none"
              name="informe_mes"
              onClick={(event) => onClick(event, "")}
            >
            </button>
                  </label>
                </li>
              </ul>
            </div>

           

            <label
              className="btn btn-outline-dark fw-normal GothamBold me-1  my-4 px-2 py-1 fs-5"
              title="Ver como tabla"
            >
              <i class="fa-solid fa-table-list"></i>
              <button
                className="d-none"
                name="vista_tabla"
                onClick={(event) => onClick(event)}
              ></button>
            </label>

            <label
              className="btn btn-outline-dark fw-normal GothamBold me-1  my-4 px-2 py-1 fs-5"
              title="Vista por archivo"
            >
              <i class="fa-regular fa-object-group"></i>
              <button
                className="d-none"
                name="vista_box"
                onClick={(event) => onClick(event)}
              ></button>
            </label>

            
            <label
              className="btn btn-outline-dark fw-normal GothamBold me-1  my-4 px-2 py-1 fs-5"
              title="Descargar tabla"
            >
              <i class="bi bi-filetype-xls"></i>
              <ReactHTMLTableToExcel
                id="buttonDownloadExcel"
                className="d-none btn btn-outline-success rounded-pill w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
                table="reportes_eventos"
                filename="reportes"
                sheet="pagina 1"
                buttonText="Exportar tabla actual"
              />
            </label>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-md-4 my-2">
        <label
          for="exampleFormControlInput1"
          className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
        >
          Realiza una busqueda rapida ingresando el nombre del evento o sala.
        </label>
        <div class="input-group mb-3">
          <input
            type="text"
            class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del evento o la sala"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            name="search"
            onChange={(event) => onChange(event)}
          />
          <button
            class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            {loading ? (
              <div
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <i class="bi bi-search"></i>
            )}
          </button>
        </div>
      </div>

      <div className="col-md-2">
        {rol == 1 ? (
          <div className="p-2">
            <label
              for="exampleFormControlInput1"
              className="form-label  text-muted fs-9 p-0 px-2 border-primary"
            >
              Filtrar según la provincia.
            </label>
            <select
              className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
              aria-label="Default select example"
              name="provincia"
              onChange={(event) => onChange(event)}
            >
              <option selected>{params.provincia}</option>
              {provincias
                ? provincias.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))
                : ""}
            </select>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-md-2 ">
        <div className="p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Clasificar reportes.
          </label>
          <select
            class="form-select  fs-9 p-0 px-2 py-1  border-custom-naranja GothamBold  rounded-pill"
            aria-label="Default select example"
            name="clasificar_reportes"
            onChange={(event) => onChange(event)}
          >
            <option value="">Todos</option>
            <option value="LIQUIDADO">Liquidados</option>
            <option value="ACTIVO">Activos</option>
          </select>
        </div>
      </div>

      <div className="col-md-2 ">
        <div className="p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Ordenar
          </label>
          <select
            class="form-select  fs-9 p-0 px-2 py-1  border-custom-naranja GothamBold  rounded-pill"
            aria-label="Default select example"
            name="clasificar_reportes"
            onChange={(event) => onChange(event)}
          >

            <option value="cercana">Fecha más cercana</option>
            <option value="lejana">Fecha más antigua</option>
          </select>
        </div>
      </div>

      <div className="col-md-3 mb-2 p-0 py-2 m-0">
        <div className="mb-3 text-start p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Rango de fecha.
          </label>
          <button className="fs-9 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 px-2 py-2 w-100">
            {" "}
            <i className="bi bi-calendar2-event"></i> Filtra por rango de fecha
          </button>
          <div className="filter_date_range d-flex justify-content-center w-100 border-0">
            <DateRange
              onChange={(item) => {
                onFecha([item.selection]);
                setParams({
                  ...params,
                  date_range: [
                    format(item.selection.startDate, "yyyy/MM/dd").toString(),
                    format(item.selection.endDate, "yyyy/MM/dd").toString(),
                  ],
                });

                setSteps(steps + 1);
              }}
              moveRangeOnFirstSelection={false}
              locale={locale}
              ranges={fecha}
              className={"fs-7"}
              style={{ fontSize: "12px" }}

              
              color={"#d0552a"}
              showDateDisplay={false}
              rangeColors={["#d0552a", "#d0552a", "#d0552a"]}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Filtro;
