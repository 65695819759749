const ModalEditarStatusCupon = ({onChange, onClick, onSave, data}) => {



  return (
<div class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{zIndex: 104}}
      >
      
    <div class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal" style={{maxWidth: "800px"}}>
      
    <div className="modal-content bg-light">
      <div class="title bg-white p-4 m-0 d-flex justify-content-between">
        <div>
          <h1>Modificar estado</h1>
        <span class="fs-6 text-muted">Modifica el estado de tu cúpon. </span>
        </div>
        <div>
          <button class="border-0 bg-transparent" name="close_modal" onFocus={(event) => onClick(event, null)}><i class="bi bi-x-lg"></i></button>
        </div>
      </div>
      <div class="body p-md-4 p-2">

        <div class="bg-white p-4">
          <select class="form-select border-custom-naranja rounded-pill" aria-label="Default select example" name="stated" onChange={(event) => onChange(event)}>
            <option selected>Seleccionar nuevo estado</option>
            <option value="ACTIVO">Activar</option>
            <option value="INACTIVO">Desactivar</option>
            <option value="ELIMINADO">Eliminar</option>
          </select>

          { 
           data?
            data.stated == "ACTIVO"?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">ACTIVO</span> <span class="text-muted"> Permite la creación de eventos y la visualización dentro del inicio, también se incluye como parámetro de sala habilitada para rellenado automático.</span>
          </div>
            :
            data.stated == "INACTIVO"?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">INACTIVO</span> <span class="text-muted"> bloquea la creacion de eventos nuevos, no permite su visualización dentro del inicio.</span>
          </div>
            : ""
            : ""
          }

          
        </div>

      </div>
      <div class="footer bg-white p-4">
        <div class="d-flex justify-content-between">
          <div></div>
          <div class="">
          <button class="btn btn-secondary rounded-pill px-4" name="close_modal" onClick={(event) => onClick(event, null)}>Cerrar</button>
          <button class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4" onClick={(event) => onSave(event, data)}>Aplicar</button>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
  )
}

export default ModalEditarStatusCupon