import { filter } from "draft-js/lib/DefaultDraftBlockRenderMap";
import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";


export const Notas = async (filter_notas) => {
  let params = {}

  if(filter_notas.id !== ""){
    params = {...params, id: filter_notas.id}
  }

  if(filter_notas.search.trim() !== ""){
    params = {...params, search: filter_notas.search}
  }

  if(filter_notas.max.trim() !== ""){
    params = {...params, max: filter_notas.max}
  }


  if(filter_notas.provincia.trim() !== ""){
    params = {...params, provincia: filter_notas.provincia}
  }

  const result  = await clientAxios.get(filter_notas.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(filter_notas.id !== ""){
    localStorage.setItem("adress",result.cookie);
    return result.data
  }

  if(!result.hasOwnProperty("data")){
    return result
  }
 
  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;
}

export const AdministradorCrearNotas = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Notas/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)

    return (window.location.href = "/dashboard/notas/editar/" + result.data.id); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}

export const AdministradorNotas = async (filter_notas, setLoading) => {

  let params = {}

  if(filter_notas.id !== ""){
    params = {...params, id: filter_notas.id}
  }
  
  if(filter_notas.search.trim() !== ""){
    params = {...params, search: filter_notas.search}
  }

  
  if(filter_notas.provincia.trim() !== ""){
    params = {...params, provincia: filter_notas.provincia}
  }

  
  if(filter_notas.status.trim() !== ""){
    params = {...params, status: filter_notas.status}
  }

  
  if(filter_notas.date_range.length !== 0){
    params = {...params, date_range: filter_notas.date_range}
  }

  const result  = await clientAxios.get(filter_notas.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(!result){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;


}

export const AdministradorActualizarNotas = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Notas/Actualizar',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    AlertSuccess(result.data.msg); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}