import { useEffect } from "react";
import Evento from "../../page/eventos/Evento"
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { contextProvider } from "../../../context/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { categoriaState, fetchCategoria } from "../../models/categorias/Index";
import { salaState } from "../../models/salas/Index";
import RestApi, { GetApi } from "../../../helpers/RestApi";
import { Validate, attachEvento, porcentaje } from "../../../utils/EventoUtils";
import SpinnerScreen from "../../components/loads/SpinnerScreen";
import { fetchMe, meState } from "../../models/users/Index";
import Alerts from "../../components/Alerts/Alerts";

const EventoController = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const _salas = useSelector(salaState)
  const _categorias = useSelector(categoriaState);
  const _me = useSelector(meState);
  const me = _me.me;
  const categorias = _categorias.categorias;
  const search = _salas.search;

  const { URL_ME, URL_EVENTO_STORE, URL_EVENTO_SHOW, URL_EVENTO_UPDATE, URL_EVENTO_INDEX, URL_PROVINCIA_INDEX, URL_CATEGORIAS_INDEX } = URLs;
  const { FORM_EVENTO, FORM_EVENTO_ENTRADA, FORM_EVENTO_LIQUIDACION, FORM_EVENTO_SALA } = FORMs;
  const { VALIDATION_FORM_EVENTO, VALIDATION_FORM_EVENTO_ENTRADA, VALIDATION_FORM_EVENTO_LIQUIDACION, VALIDATION_FORM_EVENTO_SALA } = VALIDATIONs;

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_EVENTO)));
  const [validation, setValidation] = useState(JSON.parse(JSON.stringify(VALIDATION_FORM_EVENTO)));

  const [loading, setLoading] = useState(false);
  const [evento, setEvento] = useState(false);

  /* Label */
  const [labels, setLabels] = useState([
    "¿Qué?",
    "¿Dónde?",
    "¿Cuándo?",
    "Entradas",
    "Liquidación"
  ]);

  const [label, setLabel] = useState(0);

  /* Modals */
  const [modal, setModal] = useState({
    liquidacion: {
      status: false,
      data: {}
    },
    entrada: {
      status: false,
      data: {}
    },
  })

  /* Fechas */
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const [fecha, setFecha] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection",
  }]);


  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar": {
        //#console.log('Estado ' + value);
        //#console.log(form);

        //Delete property
        const input_validation = Validate(form, validation, null);
        setValidation(input_validation);
        //Validaciones
        if (input_validation.error.status) {
          return Alerts({
            msg: "Warning",
            title: input_validation.error.msg,
            code: "201",
            type: "error",
          });
        }

        let formData = new FormData;

        /*Evento*/
        formData.append("status_id", value);
        formData.append("imagen", form.imagen[0]);
        formData.append("nombre", form.nombre[0]);
        formData.append("nombre_grupo", form.nombre_grupo[0]);
        formData.append("descripcion", form.descripcion[0]);
        formData.append("duracion", form.duracion[0]);
        formData.append("clase", form.clase[0]);
        formData.append("grupo", form.grupo[0]);
        //formData.append("",);

        /* : Categorias */
        form.categorias[0].map((c, index) => {
          formData.append("categorias[" + index + "]", c);
        });

        /* : JSON Object */
        formData.append("fechas", JSON.stringify(form.fechas[0]));

        let link = {};
        form.link[0].map((e) => {
          link = {
            ...link,
            [e.nombre]: e.url
          }
        })

        formData.append("links", JSON.stringify(link));

        let ficha_tecnica = {};
        form.ficha_tecnica[0].map((e) => {
          ficha_tecnica = {
            ...ficha_tecnica,
            [e.rol]: e.nombre
          }
        })


        formData.append("ficha_tecnica", JSON.stringify(ficha_tecnica));

        /* : Sala */
        formData.append("sala_id", form.sala[0].id[0]);
        formData.append("sala[direccion]", form.sala[0].direccion[0]);
        formData.append("sala[provincia]", form.sala[0].provincia[0]);
        formData.append("sala[localidad]", form.sala[0].localidad[0]);
        formData.append("sala[nombre]", form.sala[0].nombre[0]);
        formData.append("sala[email]", form.sala[0].email[0]);
        formData.append("sala[telefono]", form.sala[0].telefono[0]);
        formData.append("sala[website]", "");

        /* : Entrada */
        form.entradas[0].map((e, index) => {

          formData.append("entradas[" + index + "][nombre]", e.nombre[0]);
          formData.append("entradas[" + index + "][precio]", e.precio[0]);
          formData.append("entradas[" + index + "][habilitacion_fecha]", e.habilitacion_fecha[0]);
          formData.append("entradas[" + index + "][habilitacion_hora]", e.habilitacion_hora[0]);
          formData.append("entradas[" + index + "][stock]", e.stock[0]);
          formData.append("entradas[" + index + "][multiplicador]", e.multiplicador[0]);
          formData.append("entradas[" + index + "][status_id]", e.status_id[0]);

        })

        /* : Liquidacion */

        if (porcentaje(form.liquidacion[0], 0) < 0) {
          return Alerts({
            msg: "Alert",
            title: "Porcentaje de ganancia superado",
            code: "401",
            type: "warning",
          });
        }

        form.liquidacion[0].map((l, index) => {
          formData.append("liquidacion[" + index + "][usuario_id]", l.usuario_id[0]);
          formData.append("liquidacion[" + index + "][porcentaje]", l.porcentaje[0]);
          formData.append("liquidacion[" + index + "][entidad]", l.entidad[0]);
          formData.append("liquidacion[" + index + "][cbu]", l.cbu[0]);
          formData.append("liquidacion[" + index + "][comprobante_cbu]", l.comprobante_cbu[0]);
          formData.append("liquidacion[" + index + "][comprobante_afip]", l.comprobante_afip[0]);
          formData.append("liquidacion[" + index + "][comprobante_entidad]", l.comprobante_entidad[0]);
          formData.append("liquidacion[" + index + "][dato_fiscal]", l.dato_fiscal[0]);
          formData.append("liquidacion[" + index + "][email]", l.email[0]);
        });

        /* POST */

        const response = await RestApi({
          url: URL_EVENTO_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.success == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "200",
            type: "success",
          });

          return navigation('/v/evento/' + response.data.data.id);
        }
        else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;
      case "aplicar": {
        let formData = new FormData;
        formData.append("_method", 'PUT');
        formData.append("id", form.id[0]);

        /*Evento*/
        formData.append("status_id", form.status_id[0]);
        formData.append("nombre", form.nombre[0]);
        formData.append("nombre_grupo", form.nombre_grupo[0]);
        formData.append("descripcion", form.descripcion[0]);
        formData.append("duracion", form.duracion[0]);
        formData.append("clase", form.clase[0]);
        formData.append("grupo", form.grupo[0]);

        if(form.imagen[0] !== "") formData.append("imagen", form.imagen[0]);

        /* : Categorias */
        form.categorias[0].map((c, index) => {
          formData.append("categorias[" + index + "]", c);
        });

        /* : JSON Object */
        formData.append("fechas", JSON.stringify(form.fechas[0]));

        let link = {};
        form.link[0].map((e) => {
          link = {
            ...link,
            [e.nombre]: e.url
          }
        })

        formData.append("links", JSON.stringify(link));

        let ficha_tecnica = {};
        form.ficha_tecnica[0].map((e) => {
          ficha_tecnica = {
            ...ficha_tecnica,
            [e.rol]: e.nombre
          }
        })

        formData.append("ficha_tecnica", JSON.stringify(ficha_tecnica));

        /* : Sala */
        //formData.append("sala_id", form.sala[0].id[0]);
        formData.append("sala[direccion]", form.sala[0].direccion[0]);
        formData.append("sala[provincia]", form.sala[0].provincia[0]);
        formData.append("sala[localidad]", form.sala[0].localidad[0]);
        formData.append("sala[nombre]", form.sala[0].nombre[0]);
        formData.append("sala[email]", form.sala[0].email[0]);
        formData.append("sala[telefono]", form.sala[0].telefono[0]);
        formData.append("sala[website]", "");

        const response = await RestApi({
          url: URL_EVENTO_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;
      case "status": {
        let formData = new FormData;
        formData.append("_method", 'PUT');
        formData.append("id", form.id[0]);
        
        /*Evento*/
        formData.append("status_id", value);

        const response = await RestApi({
          url: URL_EVENTO_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });


        //Error
        if (response.status == 201 || response.status == 200) {

          let input = form;
          input['status_id'][0] = value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });

          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }

      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "": {

      } break;
    }
  }

  useEffect(() => {
    if (id > 0) {
      const fetch = async () => {
        let params = {
          id: id
        }
        const response = await GetApi({ url: URL_EVENTO_SHOW + id, params: params, setLoading: setLoading });

        attachEvento(response, setForm);
        setEvento(true);
      }

      if (!evento) {
        fetch();
      }
    }
  }, [])

  useEffect(() => {
    if (!categorias) {
      let params = {
        url: URL_CATEGORIAS_INDEX,
        tipo: "evento"
      };
      dispatch(fetchCategoria(params));
    }
  }, [])

  useEffect(() => {
    if (!me) {
      let params = {
        url: URL_ME
      }
      dispatch(fetchMe(params))
    }
  }, [])

  useEffect(() => {
    if (me && !form.id[0]) {
      let input = form;

      let liquidacion = {
        id: [null, "required"],
        evento_id: [null, "required"],
        usuario_id: [me.id, "required"],
        porcentaje: [me.facturacion[0].porcentaje, "required"],
        created_at: ["", "required"],
        updated_at: ["", "required"],
        entidad: [me.facturacion[0].cbu, "required"],
        cbu: [me.facturacion[0].cbu, "required"],
        identificador: [null, "required"],
        comprobante_cbu: ['', "required",],
        comprobante_afip: ['', "required",],
        comprobante_entidad: ['', "required",],
        comprobante_cbu_url: [me.facturacion[0].comprobante_cbu, "required",],
        comprobante_afip_url: [me.facturacion[0].comprobante_afip, "required",],
        comprobante_entidad_url: [me.facturacion[0].comprobante_entidad, "required",],
        dato_fiscal: [me.facturacion[0].dato_fiscal, ''],
        email: [me.email, ''],
        porcentaje_argentores: [null, "required"],
      };

      input.liquidacion[0].push(liquidacion);

      setForm({
        ...form,
        liquidacion: input.liquidacion,
      });
    }
  }, [me])
  return (
    <>
      {
        id == 0
          ? <Evento
            setLabel={setLabel}
            label={label}
            labels={labels}
            categorias={categorias}
            id={id}
            search={search}
            locale={locale}
            fecha={fecha}
            setFecha={setFecha}
            modal={modal}
            setModal={setModal}

            setForm={setForm}
            form={form}
            setValidation={setValidation}
            validation={validation}

            onChange={onChange}
            onClick={onClick}
          />
          : !evento
            ? <SpinnerScreen />
            : <Evento
              setLabel={setLabel}
              label={label}
              labels={labels}
              categorias={categorias}
              id={id}
              search={search}
              locale={locale}
              fecha={fecha}
              setFecha={setFecha}
              modal={modal}
              setModal={setModal}

              setForm={setForm}
              form={form}
              setValidation={setValidation}
              validation={validation}

              onChange={onChange}
              onClick={onClick}
            />
      }
    </>
  )
}

export default EventoController