import { Link } from "react-router-dom";

const Item = ({ data }) => {
  const { id, nombre, descripcion, nombre_sitio, entradas, cupones, imagen, categorias, provincia, localidad } =
    data;

  console.log(entradas);

  return (
    <div className="item">
       <div class="box-eventos text-center">
        <div class="img-evento">
          <span class="tag-evento" style={{fontSize: "12px"}}>{categorias[0].nombre}</span>
          {
            entradas.map((entrada) => {
              if(cupones.length !== 0 ){
                if(cupones[0].status == "ACTIVO"){
                return <span class="tag-descuento">{cupones[0].descuento}%</span>
                }
              }
            })
          }
          <img src={"/assets/img/eventos/"+imagen} width="100%" alt="" />
        </div>
        <p class="teatro mt-4 mb-0">{nombre_sitio}</p>
      <p className="m-0 text-muted opacity-75 fs-8">{provincia} - {localidad}</p>
        <h1 class="fs-5 mt-2">{nombre}</h1>
        <p class="precio fs-4 mt-2">{entradas.length > 0 ?  parseInt(entradas[0].precio) == 0? "Gratis" : '$'+entradas[0].precio : "Sin entradas"}</p>
        <Link to={"/evento/"+id} class="btn btn-custom-amarillo mb-4">comprar</Link>
      </div>
    </div>
  );
};

export default Item;
