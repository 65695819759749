const Filtros = ({
onClick,
onChange
}) => {



  return ( 
    <div className="row">
      
      <div className="col-md-4 col-12 mb-3">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                  placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search"
                  onChange={(event) => onChange(event)}
                />
                <button
                  class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                  id="basic-addon2"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>

    <div className="col-md-4">
    <select class="form-select rounded-pill border-custom-naranja fs-9 py-1 px-2" aria-label="Default select example"
    name="status"
    onChange={(event) => onChange(event)}>
  <option selected>Seleccionar estado</option>
  <option value="">Todos</option>
  <option value="ACTIVO">Activos</option>
  <option value="REEMBOLSO">Reembolsados</option>
  <option value="INACTIVO">Inactivos</option>
  <option value="PAUSADO">Pausados</option>
  <option value="LIQUIDADO">Liquidados</option>
  <option value="RECHAZADO">Rechazados</option>
</select>
    </div>
    </div>

  )
}

export default Filtros