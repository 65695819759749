import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";



export const AdministradorCrearCategoriasContenido = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/CategoriasContenido/Crear',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg); 
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}

export const AdministradorCategoriasContenido = async (filter_categoria) => {
  let params = {}

  const result  = await clientAxios.get(filter_categoria.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(!result.data){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;

}

export const AdministradorActualizarCategoriasContenido = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/CategoriasContenido/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}