import { useEffect, useState } from "react";
import Entrada from "../page/Entrada";
import Header from "../components/body/Header";
import Footer from "../components/body/Footer";

const EntradaController = () => {
  const [step, setStep] = useState(0);
  const [comission, setComission] = useState(15);
  const [evento, setEvento] = useState({
    reportes: [],
    fechas: [
      {
        fechas: ["martes, 15 de octubre de 2024"],
        hora: "10:00",
        stock: "10",
      },
      {
        fechas: ["domingo, 20 de octubre de 2024"],
        hora: "21:00",
        stock: "10",
      },
    ],
    ficha_tecnica: {
      "En Escena": "Rodo Ramos",
      "Voz en Off": "Tomás Leaño",
      Dramaturgia: "Rodo Ramos",
      "Diseño de Iluminación": "Rodrigo Brunelli",
      "Diseño y Realización Escenográfica": "Rodo Ramos - Rodrigo Brunelli",
      "Diseño de Sonido": "Horacio Fierro",
      "Operación de Video": "Raquel Ambrosino",
      Vestuario: "Lucas Mana",
      "Fotografía y Diseño Gráfica": "Rodrigo Brunelli",
      Producción: "El Cuenco Teatro",
      Dirección: "Gastón Mori",
    },
    comisiones: {
      tasa: 15,
      id: 2,
      provincia: "Córdoba",
      status_id: 1,
    },
    entradas: [
      {
        id: 2,
        nombre: "General",
        tipo: "VENTA",
        precio: "800",
        habilitacion_fecha: "2024-10-01",
        eventos_id: 2,
        created_at: "2022-03-19T13:57:45.000000Z",
        updated_at: "2022-03-26T00:18:19.000000Z",
        stock: 0,
        status: "ACTIVO",
        habilitacion_hora: "10:00",
        multiplicador: null,
      },
      {
        id: 3,
        nombre: "Estudiantil",
        tipo: "VENTA",
        precio: "500",
        habilitacion_fecha: "2024-10-01",
        eventos_id: 2,
        created_at: "2022-03-19T13:57:45.000000Z",
        updated_at: "2022-03-26T00:18:19.000000Z",
        stock: 0,
        status: "ACTIVO",
        habilitacion_hora: "10:00",
        multiplicador: null,
      },
    ],
  });
  const [form, setForm] = useState({
    fecha: null,
    stock: 0,
  });

  const label = (step) => {
    switch (step) {
      case 0:
        {
          return (
            <label
              className={`btn btn-custom-naranja text-white fw-normal py-4 fs-5 items-align-center ${
                form.fecha ? "" : "disabled"
              }`}
              style={{ width: "230px" }}
            >
              {step == 1 ? "Pagar" : "Siguiente"}
              <i class="bi bi-chevron-right fs-5"></i>
              <button
                className="d-none"
                onClick={(e) => {
                  let int = step + 1;
                  switch (int) {
                    case 0:
                      {
                        document.getElementById("nav-fecha-tab").click();
                      }
                      break;
                    case 1:
                      {
                        document.getElementById("nav-cantidad-tab").click();
                      }
                      break;
                    case 2:
                      {
                        document.getElementById("nav-pagar-tab").click();
                        let entradas = {
                          select: form,
                          evento: evento,
                        };

                        localStorage.setItem(
                          "entradas",
                          JSON.stringify(entradas)
                        );
                        return (window.location.href = "/pagar");
                      }
                      break;
                    default:
                      break;
                  }
                  setStep(int);
                }}
              ></button>
            </label>
          );
        }
        break;
      case 1:
        {
          return (
            <label
              className={`btn btn-custom-naranja text-white fw-normal py-4 fs-5 items-align-center ${
                evento.entradas.filter((e) => e.quantity).length > 0
                  ? ""
                  : "disabled"
              }`}
              style={{ width: "230px" }}
            >
              Siguiente
              <i class="bi bi-chevron-right fs-5"></i>
              <button
                className="d-none"
                onClick={(e) => {
                  let int = step + 1;
                  switch (int) {
                    case 0:
                      {
                        document.getElementById("nav-fecha-tab").click();
                      }
                      break;
                    case 1:
                      {
                        document.getElementById("nav-cantidad-tab").click();
                      }
                      break;
                    case 2:
                      {
                        document.getElementById("nav-pagar-tab").click();
                        let entradas = {
                          select: form,
                          evento: evento,
                        };

                        localStorage.setItem(
                          "entradas",
                          JSON.stringify(entradas)
                        );
                        //return (window.location.href = "/pagar");
                      }
                      break;
                    default:
                      break;
                  }
                  setStep(int);
                }}
              ></button>
            </label>
          );
        }
        break;
      case 2: {
        return (
          <label
            className={`btn btn-custom-naranja text-white fw-normal py-4 fs-5 items-align-center ${
              evento.entradas.filter((e) => e.quantity).length > 0
                ? ""
                : "disabled"
            }`}
            style={{ width: "230px" }}
          >
            Pagar
            <i class="bi bi-chevron-right fs-5"></i>
            <button
              className="d-none"
              onClick={(e) => {
                localStorage.removeItem("entradas");
                let entradas = {
                  select: form,
                  evento: evento,
                };

                localStorage.setItem("entradas", JSON.stringify(entradas));
                return (window.location.href = "/v/checkout");
              }}
            ></button>
          </label>
        );
      }
      default:
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "add-date":
        {
          setForm({
            ...form,
            fecha: value.select,
            stock: value.stock,
          });
        }
        break;
      case "remove-date":
        {
          setForm({
            ...form,
            fecha: null,
            stock: 0,
          });
        }
        break;
      case "remove":
        {
          let set = evento.entradas;
          let quantity = set[value.index]?.quantity
            ? set[value.index].quantity - 1
            : 0;
          if (quantity != 0) {
            set[value.index]["quantity"] = quantity;
            set[value.index]["pricexquantity"] = quantity * value.precio;
            set[value.index]["remain"] = form.stock + 1;

            setEvento({
              ...evento,
              entradas: set,
            });
          } else {
            set[value.index]["quantity"] = 0;
            set[value.index]["pricexquantity"] = value.precio;
            setEvento({
              ...evento,
              entradas: set,
            });
          }
        }
        break;
      case "add":
        {
          let set = evento.entradas;
          let quantity = set[value.index]?.quantity
            ? set[value.index].quantity + 1
            : 1;

          set[value.index]["quantity"] = quantity;
          set[value.index]["pricexquantity"] = quantity * value.precio;
          set[value.index]["serv"] =
            (set[value.index]["pricexquantity"] / 100) * comission;
          set[value.index]["remain"] =
            form.stock - set[value.index]["quantity"];

          if (set[value.index]["remain"] < 0) {
            return "";
          }

          setEvento({
            ...evento,
            entradas: set,
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":
        {
          console.log(e);
        }
        break;
    }
  };

  const fetch = async () => {};

  useEffect(() => {}, []);
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <div className="container-md min-vh-100">
        <Entrada
          setStep={setStep}
          step={step}
          entradas={evento.entradas}
          onClick={onClick}
          comission={comission}
          form={form}
          evento={evento}
          label={label}
        />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default EntradaController;
