import { createSlice } from "@reduxjs/toolkit";
import clienteAntesala from "../../../config/Config";

const initialState = {
  notas: null,
};

export const notaSlice = createSlice({
  name: "notas",
  initialState: initialState,
  reducers: {
    setNotas: (state, action) => {
      state.notas = action.payload
    }
  },
});

//Async functions
export const fetchNotas = (params) => (dispatch) => {
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setNotas(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setNotas } = notaSlice.actions;

export const notaState = (state) => state.notas;

export default notaSlice.reducer;
