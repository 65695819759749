export const VALIDATIONs = {
  VALIDATION_FORM_LOGIN: {
    email: { msg: "", status: false },
    password: { msg: "", status: false },
    error: { msg: "", status: false },
  },
  VALIDATION_FORM_REGISTER: {
    email: { msg: "", status: false },
    nombre: { msg: "", status: false },
    apellido: { msg: "", status: false },
    telefono: { msg: "", status: false },
    direccion: { msg: "", status: false },
    localidad: { msg: "", status: false },
    provincia: { msg: "", status: false },
    password: { msg: "", status: false },
    confirm_password: { msg: "", status: false },
    rol: { msg: "", status: false },
    error: { msg: "", status: false },
  },

  /* EVENTO */
  VALIDATION_FORM_EVENTO: {
    id: { msg: "", status: false },
    usuarios_id: { msg: "", status: false },
    status_id: { msg: "", status: false },
    imagen: { msg: "", status: false },
    imagen_url: { msg: "", status: false },
    //url_youtube: { msg: "", status: false },

    nombre: { msg: "", status: false },
    nombre_grupo: { msg: "", status: false },
    descripcion: { msg: "", status: false },
    nombre_sitio: { msg: "", status: false },
    maps_sitio: { msg: "", status: false },
    duracion: { msg: "", status: false },
    atp: { msg: "", status: false },
    clase: { msg: "", status: false },
    grupo: { msg: "", status: false },
    direccion: { msg: "", status: false },
    provincia: { msg: "", status: false },
    localidad: { msg: "", status: false },
    email_sitio: { msg: "", status: false },
    telefono_sitio: { msg: "", status: false },
    website_sitio: { msg: "", status: false },
    historia: { msg: "", status: false },
    visitas: { msg: "", status: false },

    created_at: { msg: "", status: false },
    update_at: { msg: "", status: false },

    categorias: { msg: "", status: false },

    fechas: { msg: "", status: false },
    ["fecha-fecha"]: { msg: "", status: false },
    ["fecha-hora"]: { msg: "", status: false },
    ["fecha-stock"]: { msg: "", status: false },

    link: { msg: "", status: false },
    ['link-nombre']: { msg: "", status: false },
    ['link-url']: { msg: "", status: false },

    ficha_tecnica: { msg: "", status: false },
    ["ficha_tecnica-nombre"]: { msg: "", status: false },
    ["ficha_tecnica-rol"]: { msg: "", status: false },

    entradas: { msg: "", status: false },
    ["entrada-tipo"]: { msg: "", status: false },
    ["entrada-precio"]: { msg: "", status: false },
    ["entrada-cierre"]: { msg: "", status: false },
    ["entrada-multiplicador"]: { msg: "", status: false },
    ["entrada-habilitacion"]: { msg: "", status: false },
    ["entrada-stock"]: { msg: "", status: false },

    liquidacion: { msg: "", status: false },
    ['liquidacion-cuit']: { msg: "", status: false },
    ['liquidacion-fiscal']: { msg: "", status: false },
    ['liquidacion-comprobante_afip']: { msg: "", status: false },
    ['liquidacion-comprobante_cuit']: { msg: "", status: false },
    ['liquidacion-comprobante_cbu']: { msg: "", status: false },
    ['liquidacion-cbu']: { msg: "", status: false },
    ['liquidacion-email']: { msg: "", status: false },
    ['liquidacion-porcentaje']: { msg: "", status: false },

    sala: { msg: "", status: false },
    error: { msg: "", status: false },
  },

  VALIDATION_FORM_EVENTO_ENTRADA: {
    id: { msg: "", status: false },
    evento_id: { msg: "", status: false },
    nombre: { msg: "", status: false },
    tipo: { msg: "", status: false },
    precio: { msg: "", status: false },
    habilitacion_hora: { msg: "", status: false },
    habilitacion_fecha: { msg: "", status: false },
    stock: { msg: "", status: false },
    multiplicador: { msg: "", status: false },
    status_id: { msg: "", status: false },
    created_at: { msg: "", status: false },
    update_at: { msg: "", status: false },
    error: { msg: "", status: false },
  },

  VALIDATION_FORM_EVENTO_SALA: {
    id: { msg: "", status: false },
    evento_id: { msg: "", status: false },
    nombre: { msg: "", status: false },
    email: { msg: "", status: false },
    direccion: { msg: "", status: false },
    localidad: { msg: "", status: false },
    provincia: { msg: "", status: false },
    error: { msg: "", status: false },
  },

  VALIDATION_FORM_EVENTO_LIQUIDACION: {
    id: { msg: "", status: false },
    evento_id: { msg: "", status: false },
    porcentaje: { msg: "", status: false },
    created_at: { msg: "", status: false },
    updated_at: { msg: "", status: false },
    entidad: { msg: "", status: false },
    cbu: { msg: "", status: false },
    identificador: { msg: "", status: false },
    comprobante_identificador: { msg: "", status: false },
    comprobante_afip: { msg: "", status: false },
    comprobante_entidad: { msg: "", status: false },
    dato_fiscal: { msg: "", status: false },
    email: { msg: "", status: false },
    porcentaje_argentores: { msg: "", status: false },
    error: { msg: "", status: false },
  },
  /* END */
  /* NOTAS */


  VALIDATION_FORM_NOTA: {
    imagen: { msg: "", status: false },
    imagen_url: { msg: "", status: false },
    titulo: { msg: "", status: false },
    descripcion: { msg: "", status: false },
    contenido: { msg: "", status: false },
    categorias: { msg: "", status: false },
    autor: { msg: "", status: false },
    provincia: { msg: "", status: false },
    localidad: { msg: "", status: false },
    url_youtube: { msg: "", status: false },
    categorias: { msg: "", status: false },
    error: { msg: "", status: false },
  },

  VALIDATION_FORM_CATEGORIA: {
    nombre: { msg: "", status: false },
    tipo: { msg: "", status: false },
    error: { msg: "", status: false },
  },

   /* END */
  /* NOTAS */

  FORM_MEMBRESIA: {
    id: { msg: "", status: false },
    nombre: { msg: "", status: false },
    descripcion: { msg: "", status: false },
    limite: { msg: "", status: false },
    limite_compra: { msg: "", status: false },
    descuento: { msg: "", status: false },
    region: { msg: "", status: false },
    terminos: { msg: "", status: false },
    fecha_limite: { msg: "", status: false },
    status_id: { msg: "", status: false },
  }
}