export const Month_Days = () => {
  let actual = new Date();
  let dias = new Date(actual.getYear(), actual.getMonth(), 0).getDate();
  let array = [];

  for (let i = 1; i < dias; i++) {
    array.push(i+"/"+actual.getMonth());
  }
  return {
    t_dias: dias,
    a_dias: array
  }
}

export const Result_line = (g) => {

  let input = []
  let limite = []
  let acumulado = []
  let acum = 0;

  g.map((r) => {
    input.push(r.valor_reportes)
    limite.push(r.limite)

    acum = parseFloat(r.valor_reportes) + acum
    acumulado.push(acum)
  })

  return {
    input: input,
    limite: limite,
    acumulado: acumulado
  }
}

export const cycle_active = (c) => {
  let mes = "";
  switch (c) {
    case 1:{
      mes = "Enero"
    }break;
    case 2:{
      mes = "Febrero"
    }break;
    case 3:{
      mes = "Marzo"
    }break;
    case 4:{
      mes = "Abril"
    }break;
    case 5:{
      mes = "Mayo"
    }break;
    case 6:{
      mes = "Junio"
    }break;
    case 7:{
      mes = "Julio"
    }break;
    case 8:{
      mes = "Agosto"
    }break;
    case 9:{
      mes = "Septiembre"
    }break;
    case 10:{
      mes = "Octubre"
    }break;
    case 11:{
      mes = "Noviembre"
    }break;
    case 12:{
      mes = "Diciembre"
    }break;
  }

  return mes;
}

export const rangeYears = () => {
  let max = 2023;
  let years = [2023];

  for (let i = 1; i < 2; i++) {
      max = max + 1
      console.log(max);
      years.push(max)
  }

  return years
}