import { useContext, useState } from "react";
import { Validate } from "../../../../utils/EventoUtils";
import Alerts from "../../../components/Alerts/Alerts";
import Input from "../../../components/custom/forms/Input";
import EntradaModal from "../modals/EntradaModal";
import { contextProvider } from "../../../../context/ContextProvider";
import RestApi from "../../../../helpers/RestApi";
import CheckBox from "../../../components/custom/forms/CheckBox";

const Entradas = ({
  setForm,
  form,
  setValidation,
  validation,
  setModal,
  modal,
}) => {
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { VALIDATION_FORM_EVENTO } = VALIDATIONs;
  const { URL_ENTRADA_UPDATE } = URLs;

  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "entrada-editar":
        {
          let input = form;

          input["entrada-id"][0] = input.entradas[0][value].id[0];
          input["entrada-tipo"][0] = input.entradas[0][value].nombre[0];
          input["entrada-precio"][0] = input.entradas[0][value].precio[0];
          input["entrada-habilitacion"][0] =
            input.entradas[0][value].habilitacion_fecha[0];
          input["entrada-cierre"][0] =
            input.entradas[0][value].habilitacion_hora[0];
          input["entrada-multiplicador"][0] =
            input.entradas[0][value].multiplicador[0];
          input["entrada-stock"][0] = input.entradas[0][value].stock[0];
          input["entrada-status"][0] = input.entradas[0][value].status_id[0];

          setForm({
            ...input,
            ["entrada-id"]: input["entrada-id"],
            ["entrada-tipo"]: input["entrada-tipo"],
            ["entrada-precio"]: input["entrada-precio"],
            ["entrada-habilitacion"]: input["entrada-habilitacion"],
            ["entrada-cierre"]: input["entrada-cierre"],
            ["entrada-multiplicador"]: input["entrada-multiplicador"],
            ["entrada-stock"]: input["entrada-stock"],
            ["entrada-status"]: input["entrada-status"],
          });

          setModal({
            ...modal,
            entrada: {
              status: true,
              data: value,
            },
          });
        }
        break;
      case "entrada-status":
        {
          let input = form;
          let result = input.entradas[0];

          result[value].status_id[0] = result[value].status_id[0] == 1 ? 2 : 1;

          setForm({
            ...form,
            entradas: [result, "required"],
          });

          if (form.id[0]) {
            /* UPDATE */
            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('id', result[value].id[0]);
            formData.append('status_id', result[value].status_id[0]);

            const response = await RestApi({
              url: URL_ENTRADA_UPDATE,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "success",
              });
            } else {
              if (typeof response.data.msg === "string") {
                Alerts({
                  msg: "Alert",
                  title: response.data.msg,
                  code: "200",
                  type: "success",
                });
              }

              Object.keys(response.data.msg).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data.msg[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          }
        }
        break;
      case "entrada-delete":
        {
          let input = form;
          let result = input.entradas[0];

          delete result[value];
          result = result.filter((i) => i != null);

          setForm({
            ...form,
            entradas: [result, "required"],
          });
        }
        break;
      case "entrada-añadir":
        {
          const input_validation = Validate(
            form,
            JSON.parse(JSON.stringify(VALIDATION_FORM_EVENTO)),
            "entrada"
          );
          setValidation(input_validation);
          //Validaciones
          if (input_validation.error.status) {
            return Alerts({
              msg: "Warning",
              title: input_validation.error.msg,
              code: "404",
              type: "error",
            });
          }

          let result = form.entradas[0];

          let input = {
            id: [null, "required"],
            evento_id: [null, "required"],
            nombre: [form["entrada-tipo"][0], "required"],
            precio: [
              form["entrada-precio"][0] > 0 ? "VENTA" : "GRATIS",
              "required",
            ],
            precio: [form["entrada-precio"][0], "required"],
            habilitacion_hora: [form["entrada-cierre"][0], "required"],
            habilitacion_fecha: [form["entrada-habilitacion"][0], "required"],
            stock: [form["entrada-stock"][0], "required"],
            multiplicador: [form["entrada-multiplicador"][0], "required"],
            status_id: [form["entrada-status"][0], "required"],
            created_at: [new Date(), "required"],
            update_at: [new Date(), "required"],
          };

          result.push(input);



          if (form.id[0]) {
            /* UPDATE */
            let formData = new FormData();
            formData.append('evento_id', form.id[0]);
            formData.append('status_id', form["entrada-status"][0]);
            formData.append('nombre', form["entrada-tipo"][0]);
            formData.append('precio', form["entrada-precio"][0]);
            formData.append('stock', form["entrada-stock"][0]);
            formData.append('habilitacion_fecha', form["entrada-habilitacion"][0]);
            formData.append('habilitacion_hora', form["entrada-cierre"][0]);
            if (form["entrada-multiplicador"][0]) formData.append('multiplicador', form["entrada-multiplicador"][0]);

            const response = await RestApi({
              url: URL_ENTRADA_UPDATE,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            //Error
            if (response.status == 201 || response.status == 200) {


              result[result.length - 1].id[0] = response.data.data.id;

              setForm({
                ...form,
                entradas: [result, ""],
                ["entrada-id"]: [null, ""],
                ["entrada-status"]: [1, ""],
                ["entrada-tipo"]: ["", ""],
                ["entrada-precio"]: [0, ""],
                ["entrada-cierre"]: ["00:00", ""],
                ["entrada-multiplicador"]: [null, ""],
                ["entrada-habilitacion"]: ["", ""],
                ["entrada-stock"]: [null, ""],
              });

              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "success",
              });
            } else {
              if (typeof response.data.msg === "string") {
                Alerts({
                  msg: "Alert",
                  title: response.data.msg,
                  code: "200",
                  type: "success",
                });
              }

              Object.keys(response.data.msg).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data.msg[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          } else {
            setForm({
              ...form,
              entradas: [result, ""],
              ["entrada-id"]: [null, ""],
              ["entrada-status"]: [1, ""],
              ["entrada-tipo"]: ["", ""],
              ["entrada-precio"]: [0, ""],
              ["entrada-cierre"]: ["00:00", ""],
              ["entrada-multiplicador"]: [null, ""],
              ["entrada-habilitacion"]: ["", ""],
              ["entrada-stock"]: [null, ""],
            });

            Alerts({
              msg: "Warning",
              title: "Creando entrada",
              code: "404",
              type: "success",
            });
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "entrada-tipo":
      case "entrada-precio":
      case "entrada-habilitacion":
      case "entrada-cierre":
      case "entrada-multiplicador":
      case "entrada-stock":
        {
          let input = form;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
    }
  };
  return (
    <div className="container-md  w-100">
      <div
        className="card shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-12 mb-3 px-1">
            <h5 className="">Entradas</h5>
            <span>
              Carga tus entradas, coloca un precio y una fecha de habilitación.
            </span>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mb-2">
              <Input
                onChange={onChange}
                label={"Nombre de entrada"}
                name={"entrada-tipo"}
                type={"text"}
                value={form["entrada-tipo"][0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={validation["entrada-tipo"]}
                disabled={false}
              />
            </div>
            <div className="mb-2">
              <label className="form-label text-muted" style={{ fontSize: "14px" }}>Precio</label>
              <div className="d-flex align-items-center">
                <Input
                  onChange={onChange}
                  label={null}
                  name={"entrada-precio"}
                  type={"number"}
                  value={form["entrada-precio"][0]}
                  svg={
                    ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                  }
                  validate={validation["entrada-precio"]}
                  disabled={false}
                />
                <div className="ms-2">
                  <CheckBox
                    onChange={onChange}
                    label={"Gratis"}
                    name={"entrada-gratis"}
                    type={"checkbox"}
                    value={""}
                    disabled={false}
                  />
                </div>
                <div className="ms-2">
                  <CheckBox
                    onChange={onChange}
                    label={"A la gorra"}
                    name={"entrada-gratis"}
                    type={"checkbox"}
                    value={""}
                    disabled={false}
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <Input
                onChange={onChange}
                label={"Descuento"}
                name={"entrada-descuento"}
                type={"number"}
                value={form["entrada-precio"][0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={validation["entrada-precio"]}
                disabled={false}
              />
            </div>

            <div className="mb-3">
              <Input
                onChange={onChange}
                label={"Fecha de habilitación"}
                name={"entrada-habilitacion"}
                type={"date"}
                value={form["entrada-habilitacion"][0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={validation["entrada-habilitacion"]}
                disabled={false}
              />
            </div>

            <div className="mb-3 d-flex justify-content-between">
              <div class="form-check form-switch" style={{width: "300px"}}>
                <div>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    onChange={function (event) {
                      if (event.target.checked) {
                        document
                          .getElementById("stock-unico")
                          .classList.remove("d-none");
                      } else {
                        document
                          .getElementById("stock-unico")
                          .classList.add("d-none");
                      }
                    }}
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">
                    Habilitar Stock unico
                  </label>
                </div>
                <span style={{fontSize: "11px"}}>
                  El stock único es para poder asignar un stock individual a cada tipo de entrada,
                  en base al stock general establecido para la fecha del evento.
                  *Aplica para todas las fechas del evento*
                </span>
              </div>
              <div id="stock-unico" className="d-none">
                <Input
                  onChange={onChange}
                  label={"Stock"}
                  name={"entrada-stock"}
                  type={"number"}
                  value={form["entrada-stock"][0]}
                  svg={
                    ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                  }
                  validate={validation["entrada-stock"]}
                  disabled={false}
                />
              </div>
            </div>

            <div className="mb-2">
              <label
                for="email"
                className="form-label text-muted"
                style={{ fontSize: "14px" }}
              >
                Hora de cierre
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                name="entrada-cierre"
                onChange={(event) => onChange(event)}
              >
                <option value="01:00">Una hora antes de la función</option>
                <option value="00:00">Misma hora de función</option>
                <option value="23:00">Al finalizar el día</option>
              </select>
            </div>

            <div className="mb-2">
              <label
                for="email"
                className="form-label text-muted"
                style={{ fontSize: "14px" }}
              >
                Multiplicador de entradas
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                name="entrada-multiplicador"
                onChange={(event) => onChange(event)}
              >
                <option selected></option>
                <option value="2">X2</option>
                <option value="3">X3</option>
                <option value="4">X4</option>
              </select>
            </div>

            <div className="mb-2">
              <label className="btn btn-custom-naranja fw-normal GothamBold text-white w-100 py-2">
                Confirmar Entrada
                <button
                  className="d-none"
                  name="entrada-añadir"
                  onClick={(event) => onClick(event)}
                ></button>
              </label>

              {validation.entradas.status ? (
                <div className="text-danger">{validation.entradas.msg}</div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-md-6">
            {validation.entradas.status ? (
              <div className="alert alert-warning fs-5 fw-bold text-center">
                {validation.entradas.msg == "El campo entradas es requerido."
                  ? "Agregue una entrada para continuar"
                  : ""}
              </div>
            ) : (
              ""
            )}
            <ul class="list-group">
              {form.entradas[0].map((e, index) => (
                <li class="list-group-item mb-4 border-0 text-muted  shadow-sm">
                  <div className="w-100 d-flex justify-content-between">
                    <div>
                      <ul class="list-group ">
                        <li class="list-group-item border-0 mb-1 p-0">
                          <span className="fw-bold">Tipo: </span>
                          {e.nombre[0]}
                        </li>
                        <li class="list-group-item border-0 mb-1 p-0">
                          <span className="fw-bold">Precio: </span>
                          {"$" + e.precio[0]}
                        </li>
                        <li class="list-group-item border-0 mb-1 p-0">
                          <span className="fw-bold">Stock: </span>
                          {e.stock[0] ? e.stock[0] : "Stock General"}
                        </li>
                        {e.multiplicador[0] ? (
                          <li class="list-group-item border-0 mb-1 p-0">
                            <span className="fw-bold">Multiplicador: </span>
                            {e.multiplicador[0]}
                          </li>
                        ) : (
                          ""
                        )}
                        <li class="list-group-item border-0 mb-1 p-0">
                          <span className="fw-bold">Habilitación: </span>
                          {e.habilitacion_fecha[0]}
                          <span className="ms-1 fw-bold">Hora de cierre: </span>
                          {e.habilitacion_hora[0] == "00:00"
                            ? "Misma hora de función"
                            : e.habilitacion_hora[0] == "01:00"
                              ? "Una hora antes de la función"
                              : e.habilitacion_hora[0] == "23:00"
                                ? "Al finalizar el día"
                                : ""}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <label className="btn btn-outline-success mb-2 fs-5 d-flex justify-content-center align-items-center">
                        <i class="bi bi-pencil"></i>
                        <button
                          className="d-none"
                          name="entrada-editar"
                          onClick={(event) => onClick(event, index)}
                        ></button>
                      </label>
                      <label
                        className={
                          e.status_id[0] == 1
                            ? "btn btn-outline-secondary mb-2 fs-5 d-flex justify-content-center align-items-center"
                            : "btn btn-secondary mb-2 fs-5 d-flex justify-content-center align-items-center"
                        }
                      >
                        {e.status_id[0] == 1 ? (
                          <i class="bi bi-eye"></i>
                        ) : (
                          <i class="bi bi-eye-slash"></i>
                        )}
                        <button
                          className="d-none"
                          name="entrada-status"
                          onClick={(event) => onClick(event, index)}
                        ></button>
                      </label>

                      {e.id[0] ? (
                        ""
                      ) : (
                        <label className="btn btn-outline-danger fs-5 d-flex justify-content-center align-items-center">
                          <i class="bi bi-trash"></i>
                          <button
                            className="d-none"
                            name="entrada-delete"
                            onClick={(event) => onClick(event, index)}
                          ></button>
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="text-end w-100">
                    {e.id[0] ? (
                      <span className="" style={{ fontSize: "10px" }}>
                        Entrada Nº{e.id[0]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {modal.entrada.status ? (
        <EntradaModal
          modal={modal}
          setModal={setModal}
          data={modal.entrada.data}
          validation={validation}
          form={form}
          setForm={setForm}

          url={URL_ENTRADA_UPDATE}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Entradas;
