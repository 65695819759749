export const BtnMembreships = (
membresias
) => {
  let result = false;

  membresias.map((mem) => {
    if(mem.limit > 0){
      result = true;
    }
  })

  return result
}