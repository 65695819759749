import Input from "../../components/custom/forms/Input";

const Categorias = ({ onChange, onClick, form, validation }) => {
  return (
    <>
      <div className="my-3 w-100">
        <Input
          onChange={onChange}
          label={"Nueva categoria"}
          name={"nombre"}
          type={"text"}
          value={form.nombre[0]}
          validate={{ msg: "", status: false }}
        />
        <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-2 d-flex justify-content-center align-items-center" style={{maxHeight: "30px"}}>
          Añadir Categoria
          <button className="d-none"
          name="guardar"
          onClick={(event) => onClick(event)}></button>
        </label>
      </div>
    </>
  );
};

export default Categorias;
