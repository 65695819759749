import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import AlertWarning from "../../../Alerts/AlertWarning";
import { authContext } from "../../../context/auth/AuthContext";
import { BtnMembreships } from "../../../Functions/FunctionBtnMembership";
import {
  habilitacion,
  habilitacion_fecha,
  habilitacion_hora,
  Total,
  TotalReport,
} from "../../../Functions/FunctionTickets";
import EntradaGratis from "../Modals/EntradaGratis";

const PrePago = ({ evento, entradas, reportes, fechas }) => {
  const { user } = useContext(authContext);
  const { data } = user;
  //const { comisiones } = user;
  const { tasa } = evento.comisiones;

  const [list, setList] = useState({});
  const [listFree, setListFree] = useState({});

  //modals
  const [modalFree, setModalFree] = useState(false);

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "addDate":
        {
          let result;
          let fecha = JSON.parse(e.target.value);

          entradas.map((entrada, index) => {
            if (value == index && entrada.precio > 0) {
              entrada.codigo_descuento = null;
              entrada.cantidad = 0;
              entrada.total = 0;
              entrada.comision = 0;
              entrada.fecha_funcion = fecha.fechas[0] + " HORA: " + fecha.hora;

              entrada.maxStock = TotalReport(
                entrada.fecha_funcion,
                fecha.stock,
                reportes
              ).stock;

              entrada.stock = TotalReport(
                entrada.fecha_funcion,
                fecha.stock,
                reportes
              ).stock;

              result = entrada;

              setList({
                ...list,
                [value]: result,
              });
            }
            //entradas gratis
            if (value == index && entrada.precio == 0) {
              entrada.fecha_funcion = fecha.fechas[0] + " HORA: " + fecha.hora;

              entrada.maxStock = TotalReport(
                entrada.fecha_funcion,
                fecha.stock,
                reportes
              ).stock;

              entrada.stock = TotalReport(
                entrada.fecha_funcion,
                fecha.stock,
                reportes
              ).stock;

              entrada.codigo_descuento = null;
              entrada.cantidad = 0;
              entrada.total = 0;
              entrada.comision = 0;
              entrada.maxStock = entrada.maxStock;
              entrada.stock = entrada.stock;
              entrada.fecha_funcion = fecha.fechas[0] + " HORA: " + fecha.hora;
              result = entrada;

              setListFree({
                ...listFree,
                [value]: result,
              });
            }
          });
        }
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "add":
        {
          let result = list;
          let resultFree = listFree;

          entradas.map((entrada, index) => {
            let multiplicador = entrada.multiplicador
              ? entrada.multiplicador
              : 1;

            if (value == index && entrada.precio > 0) {
              entrada.cantidad =
                entrada.cantidad > 0 && result[value].stock > 0
                  ? entrada.cantidad + 1
                  : result[value].stock > 0
                  ? 1
                  : entrada.cantidad;

              entrada.stock =
                result[value].stock > 0
                  ? entrada.stock - multiplicador
                  : entrada.stock;

              entrada.total = entrada.cantidad * entrada.precio;

              entrada.comision =
                entrada.total > 500 ? (entrada.total / 100) * 15 : 75;
              //: entrada.total + 75;

              result[value] = entrada;

              setList({
                ...list,
                [value]: result[value],
              });
            }
            //add entrada gratis
            if (value == index && entrada.precio == 0) {
              entrada.cantidad =
                entrada.cantidad > 0 && resultFree[value].stock > 0
                  ? entrada.cantidad + 1
                  : resultFree[value].stock > 0
                  ? 1
                  : entrada.cantidad;

              entrada.stock =
                resultFree[value].stock > 0
                  ? entrada.stock - multiplicador
                  : entrada.stock;

              entrada.total = entrada.cantidad * entrada.precio;
              entrada.comision = 0;

              resultFree[value] = entrada;

              setListFree({
                ...listFree,
                [value]: resultFree[value],
              });
            }
          });
        }
        break;
      case "put":
        {
          let result = list;
          //entrada gratis
          let resultFree = listFree;
          entradas.map((entrada, index) => {
            if (value == index && entrada.precio > 0) {
              entrada.cantidad =
                entrada.cantidad > 0 ? entrada.cantidad - 1 : 0;
              entrada.stock =
                entrada.cantidad > 0 ? entrada.stock + 1 : entrada.maxStock;
              entrada.total = entrada.cantidad * entrada.precio;
              result[value] = entrada;

              setList({
                ...list,
                [value]: result[value],
              });
            }
            //put entrada gratis
            if (value == index && entrada.precio == 0) {
              entrada.cantidad =
                entrada.cantidad > 0 ? entrada.cantidad - 1 : 0;
              entrada.stock =
                entrada.cantidad > 0 ? entrada.stock + 1 : entrada.maxStock;
              entrada.total = entrada.cantidad * entrada.precio;
              resultFree[value] = entrada;

              setListFree({
                ...listFree,
                [value]: resultFree[value],
              });
            }
          });
        }
        break;
      case "sendPayment":
        {
          /*
          if (Object.keys(list).length == 0) {
            return console.log("No existe lista de entradas");
          }
          */
          //validaciones
          let result = [];

          Object.keys(list).map((key) => {
            //Si la cantidad es mayor a 1
            if (list[key].cantidad > 0) {
              result.push(list[key]);
            }
          });

          let result_free = []; 

          Object.keys(listFree).map((key) => {
            //Si la cantidad es mayor a 1
            if (listFree[key].cantidad > 0) {
              result_free.push(listFree[key]);
            }
          });

          if (result.length == 0 && result_free.length == 0) {
            return AlertWarning(
              "No selecciono ninguna cantidad",
              "Advertencia"
            );
          }

          //formulario de compra
          let form = {
            evento: {
              id: evento.id,
              imagen: evento.imagen,
              nombre: evento.nombre,
              cupones: evento.cupones,
              tasa: evento.comisiones.tasa,
            },
            entradas: result,
            entradas_gratis: result_free
          };

          //to string
          form = JSON.stringify(form);
          //to base64
          form = btoa(unescape(encodeURIComponent(form)));
          //replace / for -
          form = form.replace("/", "-");

          //
          let cantidad_entradas = 0;
          result.map((entrada) => {
            cantidad_entradas = cantidad_entradas + entrada.cantidad;
          });

          //Validar membresia
          const membresia = data ? BtnMembreships(data.membresias) : false;

          if (membresia) {
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });

            swalWithBootstrapButtons
              .fire({
                title: "Tienes una membresía activa",
                text: "Tus membresías sirven para obtener entradas gratis o descuentos exclusivos!",
                icon: "warning",
                customClass: {
                  confirmButton: "mx-1 btn btn-dark",
                  cancelButton:
                    "btn btn btn-custom-naranja text-normal text-white rounded-1",
                },
                showCancelButton: true,
                confirmButtonText: "Seguir con el pago",
                cancelButtonText: "Aplicar membresia",
                reverseButtons: true,
              })
              .then((response) => {
                if (response.isConfirmed) {
                  //envio de formulario al checkout
                  return (window.location.href = "/pasarela/" + form);
                } else if (
                  /* Read more about handling dismissals below */
                  response.dismiss === Swal.DismissReason.cancel
                ) {
                  if (cantidad_entradas > 2) {
                    return AlertWarning(
                      "No se puede transaccionar más de dos entradas a la vez por membresía",
                      "Advertencia"
                    );
                  }

                  return (window.location.href = "/pasarela/membresia/" + form);
                }
              });
          } else {
            //envio de formulario al checkout
            return (window.location.href = "/pasarela/" + form);
          }
        }
        break;
      case "sendPaymentFree":
        {
          setModalFree(true);
        }
        break;
      //modals
      case "close":
        {
          setModalFree(false);
        }
        break;
    }
  };

  //Logs
  //console.log(listFree);
  return (
    <div className="row mb-5 wow bounceInUp overflow-hidden">
      <div className="col-md-12 table-responsive mt-5 wow bounceInUp">
        <div className="row border-bottom mb-3">
          <div className="col-md-2 fw-bold text-start py-3 fs-6">Entradas</div>
          <div className="col-md-3 fw-bold text-start py-3 fs-6 d-none d-md-block">
            Fecha
          </div>
          <div className="col-md-3 fw-bold text-start py-3 fs-6 d-none d-md-block">
            Precio
          </div>
          <div className="col-md-2 fw-bold text-start py-3 fs-6 d-none d-md-block">
            Cantidad
          </div>
          <div className="col-md-2 fw-bold text-end py-3 fs-6 d-none d-md-block">
            Total
          </div>
        </div>

        {habilitacion(entradas).map((entrada, index) => {
          if (entrada.habilitado) {
            return (
              <div className="row mb-3">
                <div className="col-md-2 col-12 mb-3">
                  <span className="d-block fw-bold fs-5">{entrada.nombre}</span>
                  <span className="d-block fw-bold fs-5">
                    {entrada.multiplicador
                      ? entrada.multiplicador + "X" + 1
                      : ""}
                  </span>
                </div>
                <div className="col-md-3 col-12 mb-3">
                  <select
                    class="form-select border border-custom-naranja rounded-pill fs-7"
                    aria-label="Default select example"
                    name="addDate"
                    onChange={(event) => onChange(event, index)}
                  >
                    <option selected></option>
                    {habilitacion_fecha(fechas, entrada.habilitacion_hora).map(
                      (fecha) => (
                        <option value={JSON.stringify(fecha)}>
                          {fecha.fechas[0] + " HORA: " + fecha.hora}
                        </option>
                      )
                    )}
                  </select>
                  <span className="d-block">
                    {habilitacion_hora(entrada.habilitacion_hora)}
                  </span>
                </div>

                {entrada.precio > 0 ? (
                  <div className="col-md-3 col-12 mb-3">
                    <span className="d-block">ARS {entrada.precio}</span>
                    <span className="d-block fs-7 text-dark">
                      +ARS{" "}
                      {list.hasOwnProperty(index) ? list[index].comision : 0}{" "}
                      Serv
                      {list.hasOwnProperty(index)
                        ? list[index].total > 500
                          ? " " + tasa + "%"
                          : " ARS 75"
                        : ""}{" "}
                    </span>
                  </div>
                ) : (
                  <div className="col-md-3">
                    <span className="fw-bold">GRATIS</span>
                  </div>
                )}

                <div className="col-md-2">

                  {
                  entrada.precio > 0 ? (
                    list.hasOwnProperty(index) ? (
                      list[index].stock > 0 ? (
                        <div
                          className="btn-group border"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            name="put"
                            onClick={(event) => onClick(event, index)}
                            type="button"
                            className="btn btn-white border-0"
                            disabled={
                              entrada.precio > 0
                                ? list.hasOwnProperty(index)
                                  ? false
                                  : true
                                : listFree.hasOwnProperty(index)
                                ? false
                                : true
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="form-control bg-white text-center w-50 px-1 mx-0 border-0 rounded-0"
                            value={
                              entrada.precio > 0
                                ? list.hasOwnProperty(index)
                                  ? list[index].cantidad
                                  : 0
                                : listFree.hasOwnProperty(index)
                                ? listFree[index].cantidad
                                : 0
                            }
                            disabled
                          />
                          <button
                            name="add"
                            onClick={(event) => onClick(event, index)}
                            type="button"
                            className="btn btn-white border-0"
                            disabled={
                              entrada.precio > 0
                                ? list.hasOwnProperty(index)
                                  ? false
                                  : true
                                : listFree.hasOwnProperty(index)
                                ? false
                                : true
                            }
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <div className="btn text-nowrap btn-custom-naranja text-white">
                          Entradas agotadas
                        </div>
                      )
                    ) : (
                      <div
                        className="btn-group border"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          name="put"
                          onClick={(event) => onClick(event, index)}
                          type="button"
                          className="btn btn-white border-0"
                          disabled={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? false
                                : true
                              : listFree.hasOwnProperty(index)
                              ? false
                              : true
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="form-control bg-white text-center w-50 px-1 mx-0 border-0 rounded-0"
                          value={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? list[index].cantidad
                                : 0
                              : listFree.hasOwnProperty(index)
                              ? listFree[index].cantidad
                              : 0
                          }
                          disabled
                        />
                        <button
                          name="add"
                          onClick={(event) => onClick(event, index)}
                          type="button"
                          className="btn btn-white border-0"
                          disabled={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? false
                                : true
                              : listFree.hasOwnProperty(index)
                              ? false
                              : true
                          }
                        >
                          +
                        </button>
                      </div>
                    )
                  ) : listFree.hasOwnProperty(index) ? (
                    listFree[index].stock > 0 ? (
                      <div
                        className="btn-group border"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          name="put"
                          onClick={(event) => onClick(event, index)}
                          type="button"
                          className="btn btn-white border-0"
                          disabled={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? false
                                : true
                              : listFree.hasOwnProperty(index)
                              ? false
                              : true
                          }
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="form-control bg-white text-center w-50 px-1 mx-0 border-0 rounded-0"
                          value={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? list[index].cantidad
                                : 0
                              : listFree.hasOwnProperty(index)
                              ? listFree[index].cantidad
                              : 0
                          }
                          disabled
                        />
                        <button
                          name="add"
                          onClick={(event) => onClick(event, index)}
                          type="button"
                          className="btn btn-white border-0"
                          disabled={
                            entrada.precio > 0
                              ? list.hasOwnProperty(index)
                                ? false
                                : true
                              : listFree.hasOwnProperty(index)
                              ? false
                              : true
                          }
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <div className="btn text-nowrap px-3 btn-custom-naranja text-white mb-2">
                        Entradas agotadas
                      </div>
                    )
                  ) : (
                    <div
                      className="btn-group border"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        name="put"
                        onClick={(event) => onClick(event, index)}
                        type="button"
                        className="btn btn-white border-0"
                        disabled={
                          entrada.precio > 0
                            ? list.hasOwnProperty(index)
                              ? false
                              : true
                            : listFree.hasOwnProperty(index)
                            ? false
                            : true
                        }
                      >
                        -
                      </button>
                      <input
                        type="number"
                        className="form-control bg-white text-center w-50 px-1 mx-0 border-0 rounded-0"
                        value={
                          entrada.precio > 0
                            ? list.hasOwnProperty(index)
                              ? list[index].cantidad
                              : 0
                            : listFree.hasOwnProperty(index)
                            ? listFree[index].cantidad
                            : 0
                        }
                        disabled
                      />
                      <button
                        name="add"
                        onClick={(event) => onClick(event, index)}
                        type="button"
                        className="btn btn-white border-0"
                        disabled={
                          entrada.precio > 0
                            ? list.hasOwnProperty(index)
                              ? false
                              : true
                            : listFree.hasOwnProperty(index)
                            ? false
                            : true
                        }
                      >
                        +
                      </button>
                    </div>
                  )
                  }

                  <div className="fs-6 text-nowrap mx-auto w-100 text-dark fw-bold">
                    {entrada.precio > 0
                      ? list.hasOwnProperty(index)
                        ? list[index].stock > 0
                          ? "Stock " + list[index].stock
                          : ""
                        : ""
                      : listFree.hasOwnProperty(index)
                      ? listFree[index].stock > 0
                        ? "Stock " + listFree[index].stock
                        : ""
                      : ""
                      }
                  </div>
                  <span></span>
                </div>

                <div className="col-md-2 text-end">
                  <span className="d-block">
                    {entrada.precio > 0 ? (
                      list.hasOwnProperty(index) ? (
                        "ARS " + list[index].total
                      ) : (
                        "ARS " + 0
                      )
                    ) : (
                      <button
                        name="sendPaymentFree"
                        onClick={(event) => onClick(event)}
                        className="btn btn-custom-amarillo fs-6  d-none"
                      >
                        <i class="bi bi-ticket-perforated me-2"></i>
                        Obtener
                      </button>
                    )}
                  </span>
                  <span className="d-block"></span>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row">
                <div className="col-md-12 text-center">
                  La entrada {entrada.nombre} se habilita el próximo{" "}
                  {entrada.habilitacion_fecha}
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="row p-0 mx-auto">
        <div className="col-md-8 "></div>

        <div className="col-md-4 col-12">
          <ul class="list-group">
            <li class="list-group-item border-0  d-flex justify-content-between">
              <div>SubTotal</div>
              <div>ARS {Total(list, tasa).subTotal}</div>
            </li>
            <li class="list-group-item  border-0 d-flex justify-content-between">
              <div>+Serv {Total(list, tasa).type}</div>
              <div>ARS {Total(list, tasa).serv}</div>
            </li>
            <li class="list-group-item  border-0 d-flex justify-content-between">
              <div>Total</div>
              <div>ARS {Total(list, tasa).total}</div>
            </li>
          </ul>
        </div>

        <div className="col-md-8 mt-4">
          <span className="text-muted" style={{ fontSize: "12px" }}></span>
        </div>
        <div className="col-md-4 text-end mt-4 wow bounceInUp">
          <button
            className="btn btn-custom-black w-100"
            name="sendPayment"
            onClick={(event) => onClick(event)}
          >
            Ir a pagar
          </button>
        </div>
      </div>

      {modalFree ? (
        <EntradaGratis
          onClick={onClick}
          onChange={onChange}
          data={listFree}
          evento={evento}
          setModalFree={setModalFree}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PrePago;
