import { useContext, useEffect, useState } from "react";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import CardEvent from "../../Card/CardEvent";
import ModalEditarStatus from "../../Modals/ModalEditarStatusEvento";
import { ActualizarMisEventos } from "../../../../helpers/EventUserHelper";
import ModalNotificacion from "../../Modals/ModalNotificacion";
import ModalHistorial from "../../Modals/ModalHistorial";
import { AdministradorCrearNotificacion } from "../../../../helpers/NotificacionHelper";
//router
import { Link } from "react-router-dom";
//Api
import AlertWarning from "../../../../Alerts/AlertWarning";
import useApi from "../../../../hooks/useApi";
//Date format
import { format, add, sub } from "date-fns";
//Components
import Filtros from "./Filtros/Filtros";
//Styles
import "./css/styles.css";
import "./css/default.css";

const Eventos = () => {
  const { user } = useContext(authContext);
  const { rol, status, auth } = user;
  const usuario = user.data.datos;

  const context = useContext(CollectionStateContext);
  const { salas, usuarioEventos, provincias, administradorEventos, state } =
    context;

  //STATE HELPER

  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mes = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const [fecha, onFecha] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [selectElement, setSelect] = useState(null);
  const [alert, setAlert] = useState({
    notificacion: false,
    cupon: false,
  });

  //QUERY STATE

  const LSProvincias = localStorage.getItem("provincias");

  const [dataCupones, setCupones] = useState(null);
  const [dataSalas, setSalas] = useState(state.salas);
  const [dataEventosSala, setEventosSala] = useState(null);
  //const [dataEventos, setEventos] = useState(state.eventosAdm);
  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );

  //MODALS

  const [modal_decidir, setModalDecidir] = useState(false);
  const [modal_reporte_cupon, setModalReporteCupon] = useState(false);
  const [modal_status_cupon, setModalStatusCupon] = useState(false);
  const [modal_cupon, setModalCupon] = useState(false);
  const [modal_informe, setModalInforme] = useState(false);
  const [modal_status_evento, setModalStatus] = useState(false);
  const [modal_notificacion, setModalNotificacion] = useState(false);
  const [modal_historial, setModalHistorial] = useState(false);

  //FORM

  const [form, setForm] = useState({
    evento_estado_id: "",
  });

  const [formNotification, setFormNotification] = useState({
    nombre_notificacion: "",
    descripcion_notificacion: "",
    tipo_notificacion: "",
    fecha_expiracion: "",
  });

  const [formCupones, setFormCupones] = useState({
    nombre_cupon: "",
    descuento_cupon: "",
    stated: "",

    tipo_cupon: null,
    salas_habilitadas: [],
    eventos_habilitados: [],
    limite: "",

    fechas_funciones: "",

    fecha_expiracion_cupon: "",
    fecha_habilitacion_cupon: "",

    dayFilter: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    array_fechas: [],
  });

  //Filtros

  const [initialStateParams, setInitialStateParams] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState({
    id: "",
    search: "",
    sala: "",
    status: "1",
    bill: "",
    date_range: [
      format(new Date(), "yyyy/MM/dd").toString(),
      format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
    ],
    provincia: "",
    //static
    url: "api/auth/Eventos/All",
    pagination: 50,
  });

  //PAGINATION

  const nextPage = async () => {
    setParams({
      ...params,
      url: data.next_page_url,
    });
  };

  const prevPage = async () => {
    setParams({
      ...params,
      url: data.prev_page_url,
    });
  };

  const links = async (e, value) => {
    e.preventDefault();
    setParams({
      ...params,
      url: value,
    });
  };

  //BUTTON EVENTS

  const onClick = (e, value) => {
    e.preventDefault();
    switch (e.target.name) {
      case "report_cupon":
        {
          setModalReporteCupon(true);
          setSelect(value);
        }
        break;
      case "select_status":
        {
          setSelect(value);
          setModalStatus(true);
        }
        break;
      case "notificacion":
        {
          setSelect(value);
          setModalNotificacion(true);
        }
        break;
      case "historial":
        {
          setSelect(value);
          setModalHistorial(true);
        }
        break;
      case "cupon":
        {
          setModalCupon(true);
        }
        break;
      case "close_modal":
        {
          setModalStatus(false);
          setModalNotificacion(false);
          setModalHistorial(false);
          setModalCupon(false);
          setSelect(null);
          setModalReporteCupon(false);
          setModalStatusCupon(false);
        }
        break;
      // cupones
      case "deleteFechaCupon":
        {
          let object;

          if (formCupones.array_fechas.length == 0 && selectElement) {
            object = JSON.parse(selectElement.fechas_funciones);
          } else {
            object = formCupones.array_fechas;
          }

          /*
          let errorFecha = "FALSE";
          evento.reportes.map((reporte) => {
            console.log(reporte.fecha_entrada);
            console.log(object[value].fechas[0]+" HORA: "+object[value].hora);
            if (reporte.fecha_entrada == object[value].fechas[0]+" HORA: "+object[value].hora) {
              errorFecha = "TRUE";
            }
          });
          if (errorFecha == "TRUE") {
            return AlertWarning(
              "No se puede borrar esta fecha porque contiene entradas vendidas",
              "Advertencia"
            );
          }
          */

          delete object[value];

          object = object.filter((item) => {
            return item != null;
          });

          if (selectElement) {
            selectElement.fechas_funciones = JSON.stringify(object);
          }

          setFormCupones({
            ...formCupones,
            array_fechas: object,
          });
        }
        break;
      case "editCupon":
        {
          setModalCupon(true);
          setSelect(value);
        }
        break;
      case "estadoCupon":
        {
          setModalStatusCupon(true);
          setSelect(value);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "clasificar_eventos":
        {
          switch (e.target.value) {
            case "anteriores":
              {
                let array = [
                  format(
                    add(new Date(), { months: -12 }),
                    "yyyy/MM/dd"
                  ).toString(),
                  format(new Date(), "yyyy/MM/dd").toString(),
                ];

                setParams({
                  ...params,
                  date_range: array,
                });
              }
              break;
            case "proximos":
              {
                let array = [
                  format(new Date(), "yyyy/MM/dd").toString(),
                  format(
                    add(new Date(), { months: 12 }),
                    "yyyy/MM/dd"
                  ).toString(),
                ];

                setParams({
                  ...params,
                  date_range: array,
                });
              }
              break;
          }
        }
        break;
      //params
      case "status":
      case "provincia":
        {
          setParams({
            ...params,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "search":
        {
          if (e.target.value.length < initialStateParams.search.length) {
            setInitialStateParams({
              ...initialStateParams,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateParams({
              ...initialStateParams,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3 && !loading) {
              setLoading(true);

              let expect = setTimeout(() => {
                setParams({
                  ...params,
                  [e.target.name]: e.target.value,
                });

                setLoading(false);
              }, 2000);
            }
          }
        }
        break;
      //Notificaciones
      case "tipo_notificacion":
      case "descripcion_notificacion":
      case "fecha_expiracion":
      case "nombre_notificacion":
        {
          setFormNotification({
            ...formNotification,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //Cupones
      case "limite_fecha":
        {
          let array;
          array = formCupones.array_fechas;

          array[value].limite = e.target.value;

          setFormCupones({
            ...formCupones,
            array_fechas: array,
          });
        }
        break;
      case "day":
        {
          let array = formCupones.dayFilter;

          if (e.target.checked) {
            let deletValue = formCupones["dayFilter"].filter(function (f) {
              return f !== e.target.value;
            });

            setFormCupones({
              ...formCupones,
              dayFilter: deletValue,
            });
          } else {
            array.push(e.target.value);

            setFormCupones({
              ...formCupones,
              dayFilter: array,
            });
          }
        }
        break;
      case "salas_habilitadas":
        {
          let value = formCupones[e.target.name];

          if (e.target.checked) {
            value.push(e.target.value);
            setFormCupones({
              ...formCupones,
              [e.target.name]: value,
            });
          } else {
            let deletValue = formCupones[e.target.name].filter(function (f) {
              return f !== e.target.value;
            });
            setFormCupones({
              ...formCupones,
              [e.target.name]: deletValue,
            });
          }
        }
        break;
      case "eventos_habilitados":
        {
          let value = formCupones[e.target.name];

          if (e.target.checked) {
            value.push(e.target.value);
            setFormCupones({
              ...formCupones,
              [e.target.name]: value,
            });
          } else {
            let deletValue = formCupones[e.target.name].filter(function (f) {
              return f !== e.target.value;
            });
            setFormCupones({
              ...formCupones,
              [e.target.name]: deletValue,
            });
          }
        }
        break;
      case "tipo_cupon":
        {
          setFormCupones({
            ...formCupones,
            [e.target.name]: e.target.value,
            eventos_habilitados: [],
            salas_habilitadas: [],
          });
        }
        break;
      case "descuento_cupon":
      case "limite":
      case "fecha_habilitacion_cupon":
      case "fecha_expiracion_cupon":
      case "stated":
      case "nombre_cupon":
        {
          setFormCupones({
            ...formCupones,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const onSave = async (e, value) => {
    let dataForm = new FormData();

    let evento = value.evento;
    let status = value.status;

    if (evento.entradas.length == 0 && status == 1) {
      return AlertWarning(
        "Faltan datos sobre entradas, complete los datos y vuelva a activar su evento.",
        "Advertencia"
      );
    }

    if (evento.liquidacion.length == 0 && status == 1) {
      return AlertWarning(
        "Falta completar datos de liquidacion para activar su evento",
        "Advertencia"
      );
    }

    dataForm.append("id_evento", evento.id);
    dataForm.append("status", status);

    let result = await ActualizarMisEventos(dataForm, setLoading);
    setSteps(steps + 1);
    /*
    if (rol == 1) {
      setModalNotificacion(true);
    }
    */
  };

  const onSend = async (e) => {
    let dataForm = new FormData();

    if (
      formNotification.fecha_expiracion.trim() == "" ||
      formNotification.nombre_notificacion.trim() == "" ||
      formNotification.descripcion_notificacion.trim() == "" ||
      formNotification.tipo_notificacion.trim() == ""
    ) {
      setAlert({
        ...alert,
        notificacion: true,
      });
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    dataForm.append("id_user_destinatario", selectElement.usuarios.id);
    dataForm.append("nombre", formNotification.nombre_notificacion);
    dataForm.append("descripcion", formNotification.descripcion_notificacion);
    dataForm.append("tipo", formNotification.tipo_notificacion);
    dataForm.append("fecha_expiracion", formNotification.fecha_expiracion);

    const response = await AdministradorCrearNotificacion(dataForm, setLoading);

    setModalNotificacion(false);
    setLoading(false);
  };

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);
  //Logs
  //console.log(usuario)
  return (
    <div
      className="row container-md mx-auto p-0 m-0 border-0 texto-normal"
      style={{ maxWidth: "2000px" }}
    >
      <div className="col-md-12">
        <div className="row">
          <div class="col-md-12 mb-3">
            <h1>Eventos</h1>
            <span class="text-muted">
              Puedes crear eventos nuevos, visualizarlas y editarlas.
            </span>
          </div>

          {
            usuario
            ? usuario.provincia == "Córdoba"
            ?  <div className="col-md-12 d-flex d-none">
            <span className="alert alert-info w-100 fs-5 fw-bold">
              <i class="bi bi-exclamation-circle"></i> “Nuevos Términos y
              Condiciones: Desde el 3/10/22, se cobrará un costo por uso del
              servicio a productores y/o salas correspondiente al 2% del
              bordereaux de cada función”
            </span>
          </div>
            : ""
            : ""
          }
         

          <div className="col-md-12 p-0 d-flex justify-content-between">
            <div className="fs-5"></div>
            {user.data.datos ? (
              usuario.usuario_estados_id == 1 ? (
                <Link
                  to="/dashboard/eventos/crear"
                  className="btn btn-custom-naranja text-white fw-normal GothamBold me-0 mx-1 my-4 px-2 py-2 fs-6"
                >
                  <i className="bi bi-plus-lg"></i> Agregar Evento
                </Link>
              ) : usuario.usuario_estados_id == 2 ? (
                <span className="alert alert-danger w-100 fs-7">
                  <i class="bi bi-exclamation-circle"></i> Su cuenta se
                  encuentra inactiva, no puede publicar eventos nuevos. tampoco
                  esta habilitada para mostrarse en el home de salas. Para
                  activarla contacta a un administrador con el siguiente
                  formulario
                  <a
                    target={"_blank"}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe7ECnF4fwLanTbndvSwM3ltTjq_PDWjsfGO7tCEOCh1BV_1A/viewform"
                    className="GothamLight fw-bold"
                  >
                    {" "}
                    antesala.cordoba@gmail.com
                  </a>
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          <div className="col-md-12">
            {
              <Filtros
                onClick={onClick}
                onChange={onChange}
                data={data}
                rolUser={user.data.datos}
                rule={rol}
                params={params}
                setParams={setParams}
                fecha={fecha}
                onFecha={onFecha}
                provincias={dataProvincias}
                loading={loading}
              />
            }
          </div>

          <div className="col-md-12 text-center mb-3">
            <span className="Grifter text-muted opacity-25 fs-4  fw-bold text-uppercase">
              {dias[fecha[0].startDate.getDay()] +
                " " +
                fecha[0].startDate.getDate() +
                " de " +
                mes[fecha[0].startDate.getMonth()] +
                " hasta el "}
              {fecha[0].endDate
                ? dias[fecha[0].endDate.getDay()] +
                  " " +
                  fecha[0].endDate.getDate() +
                  " de " +
                  mes[fecha[0].endDate.getMonth() + 1]
                : ""}
            </span>
          </div>

          <div className="col-md-12 col-12 d-md-flex justify-content-between">
            <div></div>
            <div>
              <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                <ul class="pagination bg-transparent">
                  <li class="page-item">
                    <button
                      style={{ borderRadius: "10px" }}
                      className={
                        data
                          ? data.current_page == 1
                            ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                            : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                          : "d-none"
                      }
                      onClick={prevPage}
                    >
                      Anterior
                    </button>
                  </li>
                  {data
                    ? data.links.map((item, index) => {
                        if (
                          (item.label !== "&laquo; Previous" &&
                            item.label !== "Next &raquo;" &&
                            item.active == true) ||
                          item.label == data.current_page + 1 ||
                          item.label == data.current_page + 2 ||
                          item.label == 1 ||
                          item.label == data.per_page ||
                          item.label == data.last_page
                        ) {
                          return (
                            <li
                              style={{ borderRadius: "5px" }}
                              className={
                                item.active == true
                                  ? "page-item bg-naranja me-1 fs-8 text-white"
                                  : "page-item me-1 fs-8 text-dark"
                              }
                            >
                              <button
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true
                                    ? "btn fs-8 border-0 bg-transparent border-custom-naranja text-white"
                                    : "btn fs-8 border-0 bg-transparent text-custom-naranja border-custom-naranja"
                                }
                                onClick={(event) => links(event, item.url)}
                              >
                                {item.label}
                              </button>
                            </li>
                          );
                        }
                      })
                    : ""}
                  <li class="page-item">
                    <button
                      style={{ borderRadius: "10px" }}
                      className={
                        data
                          ? data.current_page == data.last_page
                            ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                            : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                          : "d-none"
                      }
                      onClick={nextPage}
                    >
                      Siguiente
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="col-md-12 col-12 row p-0 m-0 px-md-2 border-0 texto-normal">
            {data
              ? data.data.map((eventos, index) => (
                  <CardEvent
                    keys={index}
                    rol={rol}
                    content={eventos}
                    onClick={onClick}
                    onSave={onSave}
                    setModalNotificacion={setModalNotificacion}
                  />
                ))
              : ""}

            {modal_status_evento ? (
              <ModalEditarStatus
                onChange={onChange}
                onClick={onClick}
                onSave={onSave}
                data={selectElement}
                rol={rol}
              />
            ) : (
              ""
            )}

            {modal_notificacion ? (
              <ModalNotificacion
                onClick={onClick}
                onChange={onChange}
                onSend={onSend}
                loading={loading}
                alert={alert}
                data={selectElement}
                formNotification={formNotification}
              />
            ) : (
              ""
            )}

            {modal_historial ? (
              <ModalHistorial onClick={onClick} data={selectElement.historia} />
            ) : (
              ""
            )}
          </div>

          <div className="col-md-12 col-12 d-md-flex justify-content-between">
            <div></div>
            <div>
              <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                <ul class="pagination bg-transparent">
                  <li class="page-item">
                    <button
                      style={{ borderRadius: "10px" }}
                      className={
                        data
                          ? data.current_page == 1
                            ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                            : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                          : ""
                      }
                      onClick={prevPage}
                    >
                      Anterior
                    </button>
                  </li>
                  {data
                    ? data.links.map((item, index) => {
                        if (
                          (item.label !== "&laquo; Previous" &&
                            item.label !== "Next &raquo;" &&
                            item.active == true) ||
                          item.label == data.current_page + 1 ||
                          item.label == data.current_page + 2 ||
                          item.label == data.per_page ||
                          item.label == 1 ||
                          item.label == data.last_page
                        ) {
                          return (
                            <li
                              style={{ borderRadius: "5px" }}
                              className={
                                item.active == true
                                  ? "page-item bg-naranja me-1 fs-8 text-white"
                                  : "page-item me-1 fs-8 text-dark"
                              }
                            >
                              <button
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true
                                    ? "btn fs-8 border-0 bg-transparent border-custom-naranja text-white"
                                    : "btn fs-8 border-0 bg-transparent text-custom-naranja border-custom-naranja"
                                }
                                onClick={(event) => links(event, item.url)}
                              >
                                {item.label}
                              </button>
                            </li>
                          );
                        }
                      })
                    : ""}
                  <li class="page-item">
                    <button
                      style={{ borderRadius: "10px" }}
                      className={
                        data
                          ? data.current_page == data.last_page
                            ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                            : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                          : ""
                      }
                      onClick={nextPage}
                    >
                      Siguiente
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventos;
