import { useContext, useEffect, useState } from "react";
import {
  NavLink,
  Link,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { CollectionStateContext } from "../../../context/EventosState";
import HeaderPerfil from "./HeaderPerfil";
import clientAxios from "../../../helpers/Config";
import axios from "axios";

const Header = ({ outSession }) => {
  const context = useContext(CollectionStateContext);
  const { provincias, state } = context;
  
  const geolocation = localStorage.getItem("geolocation");
  const LSProvincias = localStorage.getItem("provincias");

  const [stateCollapse, setCollapse] = useState("collapse navbar-collapse");

  const { pathname } = useLocation();

  //QUERY STATE

  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );

  //

  const [search, setSearch] = useState();
  const [cupon, setCupon] = useState(null);

  const onChange = (e) => {
    switch (e.target.name) {
      case "search":
        {
          setSearch(e.target.value);
        }
        break;
      case "geolocation":
        {
          localStorage.setItem("geolocation", e.target.value);

          return window.location.reload();
        }
        break;
    }
  };

  const onClick = (e) => {
    switch (e.target.name) {
      case "search":
        {
          e.preventDefault();
          window.location.href = "/entradas/" + search;
        }
        break;
      case "menu":
        {
          if (stateCollapse == "collapse navbar-collapse show") {
            setCollapse("collapse navbar-collapse");
          } else {
            setCollapse("collapse navbar-collapse show");
          }
        }
        break;
    }
  };

  useEffect(() => {
    setCollapse("collapse navbar-collapse");
  }, [pathname]);

  useEffect(() => {
    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const response = clientAxios.get("/api/Cupones");

      setCupon(response.data);
    };

    if (!cupon) {
      fetch();
    }
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg p-1 custom-navbar fixed-top d-block d-md-none"
        style={{ zIndex: 4 }}
      >
        <div className="container">
          <div className="d-flex justify-content-between">
            <a className="navbar-brand" href="/">
              <img
                src="/assets/img/marca/antesala-logo.svg"
                width="100px"
                alt=""
              />
            </a>
            <button
              className="btn "
              type="button"
              id="manu"
              name="menu"
              onClick={(event) => onClick(event)}
            >
              <label htmlFor="menu">
                <img
                  src="/assets/img/nav-icon.png"
                  className=""
                  width={"50px"}
                  alt=""
                  name="menu"
                  onClick={(event) => onClick(event)}
                />
              </label>
            </button>
          </div>
          <div className={stateCollapse}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/inicio"
                >
                  Inicio
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className={
                    pathname == "/entradas" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  href="/entradas"
                >
                  Entradas
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/somos"
                >
                  Somos
                </NavLink>
              </li>
              <li className="nav-item d-none">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/cartelera"
                >
                  Cartelera
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  to="/contenido"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Contenidos
                </NavLink>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link dropdown-item active"
                          : "nav-link dropdown-item d-none"
                      }
                      to="/contenido/podcast"
                    >
                      Podcast
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link dropdown-item active"
                          : "nav-link dropdown-item"
                      }
                      to="/contenido/notas"
                    >
                      Notas
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link dropdown-item active"
                          : "nav-link dropdown-item"
                      }
                      to="/contenido/videos"
                    >
                      Videos
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/salas"
                >
                  Salas
                </NavLink>
              </li>

              {geolocation ? (
                geolocation == "Córdoba" ? (
                  <li className="nav-item">
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "nav-link active" : "nav-link"
                      }
                      aria-current="page"
                      to="/festivales"
                    >
                      Festivales
                    </NavLink>
                  </li>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {cupon ? (
                cupon.length > 0 ? (
                  <li className="nav-item my-3">
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "nav-link active" : "nav-link"
                      }
                      aria-current="page"
                      to="/entradas/OFERTAS"
                    >
                      <div style={{ width: "100px", height: "30px" }}>
                        <div class="bg-violet h-100 d-flex justify-content-center align-items-center text-white position-relative">
                          <div
                            class="circle-white-top-left bg-white rounded-pill"
                            style={{ width: "20px", height: "20px" }}
                          ></div>
                          <div
                            class="circle-white-bottom-left bg-white rounded-pill"
                            style={{ width: "20px", height: "20px" }}
                          ></div>
                          <span className="fs-10">Descuentos % </span>
                          <div
                            class="circle-white-top-right bg-white rounded-pill"
                            style={{ width: "20px", height: "20px" }}
                          ></div>
                          <div
                            class="circle-white-bottom-right bg-white rounded-pill"
                            style={{ width: "20px", height: "20px" }}
                          ></div>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <li className="nav-item d-none">
                <Link className="nav-link" aria-current="page" to="/contacto">
                  Contacto
                </Link>
              </li>
              <HeaderPerfil outSession={outSession} />
            </ul>
            <hr />
            <select
              className="form-select form-select"
              name="geolocation"
              onChange={(event) => onChange(event)}
            >
              {geolocation ? (
                <option value={geolocation} selected>
                  {geolocation}
                </option>
              ) : (
                ""
              )}
              {dataProvincias
                ? dataProvincias.map((item) => (
                    <option value={item.nombre}>{item.nombre}</option>
                  ))
                : ""}
            </select>
            <div className="input-group flex-nowrap mt-4">
              <input
                className="form-control"
                type="search"
                placeholder="Buscar evento..."
                aria-label="Search"
                name="search"
                onChange={(event) => onChange(event)}
              />
              <button
                className="input-group-text"
                id="addon-wrapping"
                name="search"
                onClick={(event) => onClick(event)}
              >
                <img src="/assets/img/marca/search.png" width="20px" alt="" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className="custom-navbar d-none d-md-block fixed-top"
        style={{ zIndex: "1" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <a href="/">
                <img
                  src="/assets/img/marca/antesala-logo.svg"
                  width="200px"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-8 d-flex flex-column justify-content-between">
              <form className="d-flex position-relative me-auto ms-auto">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Buscar evento..."
                  aria-label="Search"
                  name="search"
                  onChange={(event) => onChange(event)}
                />
                <button
                  className="btn btn-custom"
                  type="submit"
                  name="search"
                  onClick={(event) => onClick(event)}
                >
                  Filtrar
                </button>
              </form>
              <ul className="navbar-nav flex-row justify-content-center mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    to="/inicio"
                  >
                    Inicio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      pathname == "/entradas" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    href="/entradas"
                  >
                    Entradas
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    to="/somos"
                  >
                    Somos
                  </NavLink>
                </li>
                <li className="nav-item d-none">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    to="/cartelera"
                  >
                    Cartelera
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link dropdown-toggle"
                        : "nav-link dropdown-toggle"
                    }
                    to="/contenido"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Contenidos
                  </NavLink>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <NavLink
                        className={(navData) =>
                          navData.isActive
                            ? "nav-link dropdown-item active"
                            : "nav-link dropdown-item d-none"
                        }
                        to="/contenido/podcast"
                      >
                        Podcast
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={(navData) =>
                          navData.isActive
                            ? "nav-link dropdown-item active"
                            : "nav-link dropdown-item"
                        }
                        to="/contenido/notas"
                      >
                        Notas
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={(navData) =>
                          navData.isActive
                            ? "nav-link dropdown-item active"
                            : "nav-link dropdown-item"
                        }
                        to="/contenido/videos"
                      >
                        Videos
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    to="/salas"
                  >
                    Salas
                  </NavLink>
                </li>

                {geolocation ? (
                  geolocation == "Córdoba" ? (
                    <li className="nav-item">
                      <NavLink
                        className={(navData) =>
                          navData.isActive ? "nav-link active" : "nav-link"
                        }
                        aria-current="page"
                        to="/festivales"
                      >
                        Festivales
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {cupon ? (
                  cupon.length > 0 ? (
                    <li className="nav-item">
                      <a aria-current="page" href="/entradas/OFERTAS">
                        <div style={{ width: "100px", height: "30px" }}>
                          <div class="bg-violet h-100 d-flex justify-content-center align-items-center text-white position-relative">
                            <div
                              class="circle-white-top-left bg-white rounded-pill"
                              style={{ width: "20px", height: "20px" }}
                            ></div>
                            <div
                              class="circle-white-bottom-left bg-white rounded-pill"
                              style={{ width: "20px", height: "20px" }}
                            ></div>
                            <span className="fs-10">DESCUENTOS % </span>
                            <div
                              class="circle-white-top-right bg-white rounded-pill"
                              style={{ width: "20px", height: "20px" }}
                            ></div>
                            <div
                              class="circle-white-bottom-right bg-white rounded-pill"
                              style={{ width: "20px", height: "20px" }}
                            ></div>
                          </div>
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                <li className="nav-item d-none">
                  <Link className="nav-link" aria-current="page" to="/contacto">
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 d-flex flex-column justify-content-between align-items-end">
              <select
                className="form-select form-select"
                name="geolocation"
                onChange={(event) => onChange(event)}
              >
                {geolocation ? (
                  <option value={geolocation} selected>
                    {geolocation}
                  </option>
                ) : (
                  <option selected></option>
                )}
                {dataProvincias
                  ? dataProvincias.map((item) => (
                      <option value={item.nombre}>{item.nombre}</option>
                    ))
                  : ""}
              </select>
              <ul className="navbar-nav flex-row justify-content-center mb-2 mb-lg-0">
                <HeaderPerfil outSession={outSession} />
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
