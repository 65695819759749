const CardGaleria = ({ data, onClick, onChange }) => {
  const {
    id,
    created_at,
    imagen,
    posicion,
    provincia,
    nombre,
    stated,
    url,
    archivo,
  } = data;

  const vigencia = data.vigencia? JSON.parse(data.vigencia) : null;

  return (
    <div className="col-md-6 col-12 mb-3 p-3 m-0 hover-card">
      <div
        className="row overflow-hidden bg-white shadow-sm"
        style={{ borderRadius: "10px" }}
      >
        <div className="col-md-12  d-flex justify-content-between align-items-center bg-naranja py-2 bg-opacity-25">
          <div>
            <span>
              <i class="fs-4 text-light me-2 bi bi-flag"></i>
            </span>
            <span className="fs-8 text-light">Multimedia {posicion}</span>
          </div>
          <div>
            <label className="btn  btn-dark text-white rounded-pill border-0">
              Editar
              <button className="d-none"
              name="modal_multimedia"
              onClick={(event) => onClick(event, data)}></button>
            </label>
          </div>
        </div>
        <div className="col-md-3 d-flex align-items-center overflow-hidden" style={{heigth: "100px"}}>
          <img
            src={"https://antesala.com.ar/assets/img/galeria/" + imagen}
            className="w-100"
            alt="IMAGEN OBLIGATORIA"
          />
        </div>
        <div className="col-md-9 p-0">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between border-0 mb-0 pb-0">
              <div></div>
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0">
              <span className="text-muted fs-10">{}</span>
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10">
              <span className="text-muted fw-bold fs-10">Nombre</span> {nombre}
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10">
              <span className="text-muted fw-bold fs-10">
                <a href={url}>Link</a>
              </span>
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10">
              <span className="text-muted fw-bold fs-10">
                <a href={"/assets/pdf/" + archivo}>Archivo</a>
              </span>
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10">
              <span className="text-muted fw-bold fs-10">Provincia</span>{" "}
              {provincia}
            </li>
            <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10">
              <div class="dropdown">
                <button
                  class="btn btn-outline-dark py-1 fs-10 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {stated}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <label className="btn w-100 text-start">
                      Activar
                      <button className="d-none"
                      name="change_status" 
                      onClick={(event) => onClick(event, {data: data, status: "ACTIVO"})}></button>
                    </label>
                  </li>
                  <li>
                    <label className="btn w-100 text-start">
                      Desactivar
                      <button className="d-none"
                      name="change_status" 
                      onClick={(event) => onClick(event, {data: data, status: "INACTIVO"})}></button>
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li className="list-group-item border-0 d-flex justify-content-between border-top">
              <div className="d-flex">
                <span className="text-muted fw-bold fs-10 me-2">Vigencia</span>
                {
                  vigencia
                  ? <span className="fs-10 fw-bold me-2">{"Desde "+vigencia[0]+" Hasta "+vigencia[1]}</span>
                  : <span className="fs-10 fw-bold me-2">Sin fecha asignada</span>
                }
              </div>
              <div className="d-flex d-none">
                <span className="text-muted fs-10 me-2">Estado del evento</span>
                <span className="fs-10 fw-bold me-2">Activo</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardGaleria;
