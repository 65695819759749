import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useContext } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { authContext } from "../../../../../context/auth/AuthContext";
import { Link } from "react-router-dom";

const options = {
  loop: true,
  dots: false,
  margin: 40,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  navText: ["<", ">"],
  autoplayTimeout: 6000,
  fluidSpeed: 5000,
  //smartSpeed: 10000,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
  },
};

const SliderHome = () => {
  const { user } = useContext(authContext);
  const { galeria } = user;

  const arrayImg = () => {
    let array = [];
    galeria.map((img) => {
      if(img.posicion == "CARUSEL"){
        array.push(img);
      }
    })
    return array
  };

  return (
    <section id="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              galeria
              ? <ReactOwlCarousel
              style={{ zIndex: "0" }}
              className="owl-theme"
              {...options}
            >
              {arrayImg().map((img) => (
                <div className="item">
                  <a target="_blank" href={img.url}><img src={"https://antesala.com.ar/assets/img/galeria/"+img.imagen} /></a>
                </div>
              ))}
              
            </ReactOwlCarousel>
              : ""
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderHome;
