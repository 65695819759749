import { useEffect, useState } from "react";
import Input from "../../../components/custom/forms/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { format } from "date-fns";
import ApiRest from "../../../../../hooks/useRest";
import Alerts from "../../../components/Alerts/Alerts";

const MembresiaModal = ({ setModal, data }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(data ? data : {
    code: "",
    created_at: "",
    cycle: "",
    descont: "",
    description: "",
    fecha_limite: "",
    history_events: "",
    id: "",
    limit: "",
    limit_cash: "",
    name: "",
    region: "",
    status_id: "",
    suscriptos: "",
    terminos: "",
    updated_at: "",
  })

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar":
        {
          console.log({form});

          let formData = new FormData();
          formData.append("name", form.name);
          formData.append("description", form.description);
          formData.append("limit", form.limit);
          formData.append("limit_cash", form.limit_cash);
          formData.append("descont", form.descont);
          formData.append("region", "Cordoba");
          formData.append("cycle", form.cycle);
          formData.append("fecha_limite", form.fecha_limite);
          formData.append("terminos", form.terminos);

          const response = await ApiRest({
            url: "api/membresias",
            form: formData,
            setLoading: setLoading,
          });

          //Error
          if (response.status == 201 || response.status == 200) {
            return Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });
          } else {
            if (typeof response.data.msg === "string") {
              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "200",
                type: "success",
              });
            }

            Object.keys(response.data.msg).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data.msg[key][0],
                code: "401",
                type: "error",
              });
            });
          }

        }
        break;
      case "aplicar": {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("id", form.id);

        if (form.name) formData.append("name", form.name);

        if (form.description) formData.append("description", form.description);

        if (form.limit) formData.append("limit", form.limit);

        if (form.limit_cash) formData.append("limit_cash", form.limit_cash);

        if (form.descont) formData.append("descont", form.descont);

        if (form.region) formData.append("region", form.region);

        if (form.terminos) formData.append("terminos", form.terminos);

        if (form.fecha_limite) formData.append("fecha_limite", form.fecha_limite);

        if (form.cycle) formData.append("cycle", form.cycle);

        const response = await ApiRest({
          url: "api/membresias",
          form: formData,
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {
          if (typeof response.data.msg === "string") {
            return Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "error",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });
        }
      } break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "description":
      case "limit":
      case "limit_cash":
      case "descont":
      case "region":
      case "cycle":
      case "fecha_limite":
      case "terminos":
      case "name":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value
          })
        }
        break;
    }
  };

  let tym = form.terminos;
  const ciclos = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ]

  console.log(form);
  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Membresia</h1>
              <span class="fs-6 text-muted">Agrega o edita tu membresia. </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => setModal(false)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="row">
              <div className="col-md-6">
                <Input
                  onChange={onChange}
                  label={"Nombre"}
                  name={"name"}
                  type={"text"}
                  value={form.name}
                  svg={""}
                  validate={{ msg: "", status: false }}
                />
              </div>
              <div className="col-md-6">
                <Input
                  onChange={onChange}
                  label={"Descuento por entrada %%"}
                  name={"descont"}
                  type={"number"}
                  value={form.descont}
                  svg={""}
                  validate={{ msg: "", status: false }}
                  disabled={data ? true : false}
                />
              </div>
              <div className="col-md-6">
                <label
                  className="form-label text-muted mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Provincia
                </label>
                <select class="form-select" aria-label="Default select example">
                  <option select>{form.region}</option>
                </select>
              </div>
              <div className="col-md-6">
                <label
                  className="form-label text-muted mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Ciclo Activo
                </label>
                <select class="form-select" aria-label="Default select example"
                name="cycle" onChange={(event) => onChange(event)}>
                  {
                    ciclos.map((c, index) => {
                      if (form.ciclos == c.id) {
                        return <option selected>{c.mes}</option>
                      } else {
                        return <option value={c.id}>{c.mes}</option>
                      }
                    })
                  }
                </select>
              </div>
              <div className="col-md-6">
                <Input
                  onChange={onChange}
                  label={"Limite de entradas / Cantidad"}
                  name={"limit"}
                  type={"number"}
                  value={form.limit}
                  svg={""}
                  validate={{ msg: "", status: false }}
                  disabled={false}
                />
              </div>
              <div className="col-md-6">
                <Input
                  onChange={onChange}
                  label={"Limite de gasto / Monto"}
                  name={"limit_cash"}
                  type={"number"}
                  value={form.limit_cash}
                  svg={""}
                  validate={{ msg: "", status: false }}
                  disabled={false}
                />
              </div>
              <div className="col-md-6 d-none">
                <Input
                  onChange={onChange}
                  label={"Fecha limite"}
                  name={"fecha_limite"}
                  type={"date"}
                  value={form.fecha_limite}
                  svg={""}
                  validate={{ msg: "", status: false }}
                  disabled={false}
                />
              </div>
              <div className="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Descripción
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="description" 
                  onChange={(event) => onChange(event)}
                  value={form.description}
                ></textarea>
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="">Terminos y condiciones</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={tym}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log();
                  }}
                  onChange={(event, editor) => {
                    //const data = editor.getData();
                    setForm({
                      ...form,
                      terminos: editor.getData(),
                    });
                  }}
                  onBlur={(event, editor) => {
                    console.log();
                    //console.log( 'Blur.', editor );
                  }}
                  onFocus={(event, editor) => {
                    console.log();
                    //console.log( 'Focus.', editor );
                  }}
                />
              </div>
            </div>
          </div>

          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                {
                  data
                    ?
                    <label className=" btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
                      <i class="bi bi-chevron-double-left"></i> Aplicar
                      <button
                        className="d-none"
                        name="aplicar"
                        onClick={(event) => onClick(event, data)}
                      ></button>
                    </label>
                    :
                    <label className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
                      <i class="bi bi-chevron-double-left"></i> Guardar
                      <button
                        className="d-none"
                        name="guardar"
                        onClick={(event) => onClick(event, data)}
                      ></button>
                    </label>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembresiaModal;
