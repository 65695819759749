import { useState } from "react";
import { Decidir } from "../../../../../helpers/DecidirHelper";
//Date format
import { format, add, sub } from "date-fns";
import useApi from "../../../../../App/Hooks/useApi";
import { useEffect } from "react";

const ModalAddReporte = ({
  onClick,
  setForm,
  form,
}) => {
  const [decidir, setDecidir] = useState(null);
  const [steps, setSteps] = useState(0);

  //parametros
  const [params, setParams] = useState({
    id: "",
    search: "",
    sala: "",
    status: "1",
    date_range: [
      format(new Date(), "yyyy/MM/dd").toString(),
      format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
    ],
    provincia: "",
    //static
    url: "api/auth/Eventos/All",
    pagination: "1000",
  });

  const onChange= (e) => {
    switch (e.target.name) {
      case "id_decidir":{
        setForm({
          ...form,
          api_establecimiento_id: e.target.value
        })
      }
      break;
    }
  }

  
  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps); 

  useEffect(() => { 
    const axios = async () => {
      const result = await Decidir(form.api_establecimiento_id); 
      setDecidir(result);
    }
    axios()
  },[form])
  return (  
  <div
    class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style={{ zIndex: 104 }}
  >
    <div
      class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
      style={{ maxWidth: "900px!important;" }}
    >
      <div className="modal-content bg-light">
        <div class="title m-0 bg-white p-4 d-flex justify-content-between">
          <div>
            <h1>Crear un reporte nuevo</h1>
            <span class="fs-6 text-muted">
              Verifica los datos de tu reporte, es importante que mantengas datos correctos y sean validados por un administrador.
            </span>
          </div>
          <div>
            <button
              class="border-0 bg-transparent"
              name="close"
              onFocus={(event) => onClick(event, null)}
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
        <div class="body p-4">

        <div class="row bg-white ">
            <div class="col-md-12 py-2 mb-3">
              <label for="" class="text-muted"
                >Ingresa un número de seguimiento de decidir para solicitar un comprobante, si no se realizo la compra por medios de pagos decidir, entonces puede dejar vacio este campo.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
                <button class="input-group-text btn btn-custom-naranja text-white" id="basic-addon2"
                 name="search_decidir" onClick={(event) => onClick(event)} ><i class="bi bi-search"></i></button
                >
              </div>
              {
                decidir
                ? decidir.results.map((dato) => (
              <ul class="list-group">
              <li class="list-group-item border-0">
                <div class="text-muted pb-2">
                  Id de transacción <span class="fw-bold"> {dato.id}</span>
                </div>
                <div class="text-muted pb-2">
                  Tipo de tarjeta <span class="fw-bold"> {dato.card_brand}</span>
                </div>
                <div class="text-muted pb-2">
                  Monto <span class="fw-bold"> {dato.amount}</span>
                </div>
                <div class="text-muted pb-2">
                  Nombre del cliente
                  <span class="fw-bold"> {dato.customer.id}</span>
                </div>
                <div class="text-muted pb-2">
                  Email del cliente
                  <span class="fw-bold"> {dato.customer.email}</span>
                </div>
                <div class="text-muted pb-2">
                  Estado del pago <span class="fw-bold"> {dato.status}</span>
                </div>
                <div class="text-muted pb-2">
                  Detalles <span class="fw-bold">{dato.status_details.error? dato.status_details.error.reason.description : "No existen errores" }</span>
                </div>
              </li>
            </ul>
                ))
                : ""
              }
            </div>
            <div className="col-md-12 mb-3">
              <span className="fs-6">Datos del Cliente </span>
            </div>
            <div className="col-md-12  mb-3">
            <label for="" class="text-muted"
                >Nombre / cliente.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
              </div>
            </div> 
            <div className="col-md-12  mb-3">
            <label for="" class="text-muted"
                >Telefono / cliente.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
              </div>
            </div> 
            <div className="col-md-12  mb-3">
            <label for="" class="text-muted"
                >Email / cliente.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
              </div>
            </div>
            
            <div className="col-md-12 mb-3">
              <span className="fs-6 fw-bold text-muted">Datos de la entrada </span>
            </div>
            <div className="col-md-6  mb-3">
            <label for="" class="text-muted"
                >Evento.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
              </div>
            </div>
            <div className="col-md-6  mb-3">
            <label for="" class="text-muted"
                >Entradas disponibles.</label>
              <div class="input-group mb-3">
              <select class="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
              </select>
              </div>
            </div> 
          </div>
        
        </div>
        <div class="footer bg-white p-4">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-secondary rounded-pill px-4 me-1"
              name="close"
              onFocus={(event) => onClick(event, null)}
            >
              cerrar
            </button>
            <button
              class="btn btn-custom-amarillo text-white rounded-pill px-4 "
              name="close"
              onFocus={(event) => onClick(event, null)}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ModalAddReporte