import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { authContext } from "../context/auth/AuthContext";

export const RouteGuest = ({children}) => {
  const {user} = useContext(authContext);

  if(user.auth){
    return <Navigate to="/" />
  } else {
    return children
  }
  

  return null

}

export default RouteGuest;