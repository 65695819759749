import axios from "axios";
import clientAxios from "../helpers/Config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertWarning from "../Alerts/AlertWarning";


//authenticated
export const Me = async (filter) =>{
  let params = {}
  let token = localStorage.getItem('token');
  let usuario = null;

  if(filter.provincia.trim() !== ""){
    params = {...params, provincia: filter.provincia}
  }

  if (token) {
    usuario  = await clientAxios.get('/api/auth/Usuario').then((result) => {return result.data}).catch((result) => {return null});
  }

  const galeria  = await clientAxios.get('/api/Galeria',{params}).then((result) => {return result.data}).catch((result) => {return null});

  const comisiones  = await clientAxios.get('/api/Comisiones',{params}).then((result) => {return result.data}).catch((result) => {return null});

  let user = {
    data: usuario ? usuario.data : null,
    auth: usuario ? usuario.Authenticated : null,
    rol: usuario ? usuario.data.usuarioTipo : null,
    status: usuario ? usuario.data.status.id : null,
    galeria: galeria ? galeria : null,
    comisiones: comisiones? comisiones : null
  }

  return user;
}
//

export const iniciarSession = async (dataForm, setLoading, history) => {

  setLoading(true)

  const response = await clientAxios({
    method: 'post',
    url: '/api/Login',
    data: dataForm
  }).then(async (result) => {
    switch (result.status) {
      case 200:{
      localStorage.setItem('token', result.data.token);
        Swal.fire({
          text: result.data.msg,
          timer: "1500",
          icon: 'success',
          position: "bottom",
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })


        //return history(-2);
        
       switch (result.data.rule[0].tipos_id) {
         case 1:{
          return window.location.href = '/dashboard/micuenta'; 
         } 
         break;
         case 2:{
          return window.location.href = '/dashboard/espectador'; 
        } 
        break;
        case 3:{
          return window.location.href = '/dashboard/micuenta'; 
        } 
        break;
         case 4:{
          return window.location.href = '/dashboard/micuenta'; 
         }
         break;
       }
        
      }
      break;
      case 201:{
        setLoading(false)

        return Swal.fire({
          text: result.data.msg,
          timer: "1500",
          icon: 'error',
          position: "bottom",
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })
      }
      break;
    }
    
      

  }).catch((result) => {
    setLoading(false)
    
    return Swal.fire({
      text: "Ocurrio un error al intentar iniciar sesion, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

  return response

}

export const googleAuth = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/Users/Google/Redirect',
    data: dataForm
  }).then(async (result) => {
    switch (result.status) {
      case 202:{
        return result.data;
      }
      break;
      case 200:{
      localStorage.setItem('token', result.data.token);
        Swal.fire({
          text: result.data.msg,
          timer: "5000",
          icon: 'success',
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })

       switch (result.data.rule[0].tipos_id) {
         case 1:{
          return window.location.href = '/dashboard/administrador'; 
         } 
         break;
         case 2:{
          return window.location.href = '/dashboard/espectador'; 
        } 
        break;
        case 3:{
          return window.location.href = '/dashboard/sala'; 
        } 
        break;
         case 4:{
          return window.location.href = '/dashboard/productor'; 
         }
         break;
       } 

       
      }
      break;
      case 201:{
        Swal.fire({
         text: result.data.msg,
         timer: "2000",
         icon: 'error',
         position: "bottom",
         showConfirmButton: false,
         customClass: {
           container: "add-to-cart-alert-container",
           popup: "add-to-cart-alert",
         }
       })
       return result.data;
      }
      break;
    }

  }).catch((result) => {
    return Swal.fire({
      text: "Ocurrio un error al intentar iniciar sesion, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

  return response
}

export const facebookAuth = async (dataForm) => {

  
  const response = await clientAxios({
    method: 'post',
    url: '/api/Users/Facebook/Redirect',
    data: dataForm
  }).then(async (result) => {
    switch (result.status) {
      case 202:{
        return result.data;
      }
      break;
      case 200:{
      localStorage.setItem('token', result.data.token);
        Swal.fire({
          text: result.data.msg,
          timer: "1500",
          icon: 'success',
          position: "bottom",
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })

       switch (result.data.rule[0].tipos_id) {
         case 1:{
          return window.location.href = '/dashboard/administrador'; 
         } 
         break;
         case 2:{
          return window.location.href = '/dashboard/espectador'; 
        } 
        break;
        case 3:{
          return window.location.href = '/dashboard/sala'; 
        } 
        break;
         case 4:{
          return window.location.href = '/dashboard/productor'; 
         }
         break;
       } 

       
      }
      break;
      case 201:{
         Swal.fire({
          text: result.data.msg,
          timer: "2000",
          icon: 'error',
          position: "bottom",
          showConfirmButton: false,
          customClass: {
            container: "add-to-cart-alert-container",
            popup: "add-to-cart-alert",
          }
        })
        return result.data;
      }
      break;
    }

  }).catch((result) => {
    return Swal.fire({
      text: "Ocurrio un error al intentar iniciar sesion, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

  return response
}

export const cerrarSession = async () => {

  const response = await clientAxios.get('/api/auth/Logout');

  return AlertSuccess("Cerrando sesion..."), 
  sessionStorage.clear(), 
  localStorage.removeItem("token"),
  window.location.href = '/'; 

}

export const registro = async (dataForm) => {

  const response = await clientAxios({
    method: 'post',
    url: '/api/Registro',
    data: dataForm
  }).then((result) => {
    Swal.fire({
      text: result.data.msg,
      icon: 'success',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/alert/confirmRegistro/'+result.data.info;  
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el registro. Vuelve a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}

export const upperRegistro = async (dataForm) => 
{
  
  const response = await clientAxios({
    method: 'post',
    url: '/api/UpperRegistro',
    data: dataForm,
  }).then((result) => {
    Swal.fire({
      text: result.data.msg,
      icon: 'success',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/alert/confirmRegistro/'+result.data.info; 
  }).catch((result) => {
    console.log(result.response.data);
    return AlertWarning(result.response.data.msg, "Advertencia");
  });

}

export const RestaurarContraseña = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/RestaurarContraseña',
    data: dataForm
  }).then((result) => {
    Swal.fire({
      text: result.data.msg,
      timer: "1500",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    }) 
    return window.location.href = '/alert/restorePassword/'+result.data.info;
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el registro. Vuelve a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}

export const CambiarContraseña = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/Restaurar',
    data: dataForm
  }).then((result) => {
    Swal.fire({
      text: result.data.msg,
      timer: "1500",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/ingresar'
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! El enlace ya no sirve, vuelva a enviar el correo de recuperación.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}
