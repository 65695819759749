import clientAxios from "./Config";
import Swal from "sweetalert2";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertError from "../Alerts/AlertError";

export const ActualizarUsuario = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/ActualizarUsuario',
    data: dataForm
  }).then(async (result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg);
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error")
  });

  return response
}

export const ActualizarUsuarioAlto = async (dataForm) => 
{
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/ActualizarUsuarioAlto',
    data: dataForm
  }).then(async (result) => {
    return Swal.fire({
      text: result.data.msg,
      icon: 'success',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  }).catch((result) => {
    return Swal.fire({
      text: "Ocurrio un error al intentar iniciar sesion, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

}

export const ActualizarRole = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Usuario/Role/Actualizar',
    data: dataForm
  }).then(async (result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg);
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error")
  });

  return response;

}


//ADMINISTRADOR

export const AdministradorUsuarios = async (
  filter_espectadores
) => {
  let params = {};

  if(filter_espectadores.search.trim() !== ""){
    params = {...params, search: filter_espectadores.search}
  }

  if(filter_espectadores.status.trim() !== ""){
    params = {...params, status: filter_espectadores.status}
  }

  if(filter_espectadores.provincia.trim() !== ""){
    params = {...params, provincia: filter_espectadores.provincia}
  }

  
    if(filter_espectadores.pagination !== ""){
      params = {...params, pagination: filter_espectadores.pagination}
    }
  

  const result  = await clientAxios.get(filter_espectadores.url, {params}).then((result) => {return result.data}).catch((result) => {return null});
  
  if(!result){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;
}

export const AdministradorActualizarUsuarios = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Espectadores/Actualizar',
    data: dataForm
  }).then(async (result) => {
    setLoading(false)
    AlertSuccess(result.data.msg);
    return false;
  }).catch((result) => {
    setLoading(false);
    return AlertError(result.response.data.msg,"ERROR" )
  });

  return response;
}

export const AdministradorUsuariosFilter = async (nombre, status) => {
  let params = {};

  if(nombre.trim() !== ""){
    params.nombre = nombre;
  }

  if(status.trim() !== ""){
    params.status = status;
  }

  const result  = await clientAxios.get("/api/auth/Administrador/Espectadores",{params}).then((result) => {return result.data}).catch((result) => {return null});
  return result;
}

export const AdministradorUsuariosPagination = async (url) => {
  const result  = await clientAxios.get('/api/auth/Administrador/Espectadores').then((result) => {return result.data}).catch((result) => {return null});
  return result;
}