const ModalEditarStatus = ({onChange, onClick, onSave, data, rule}) => {
  return (
    <div class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{zIndex: 104}}
      >
      
    <div class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal" style={{maxWidth: "800px"}}>
      
    <div className="modal-content bg-light">
      <div class="title bg-white p-4 m-0 d-flex justify-content-between">
        <div>
          <h1>Modificar estado</h1>
        <span class="fs-6 text-muted">Modifica el estado de tu evento, cada estado permite ciertos atributos especiales. </span>
        </div>
        <div>
          <button class="border-0 bg-transparent" name="close_modal" onFocus={(event) => onClick(event, null)}><i class="bi bi-x-lg"></i></button>
        </div>
      </div>
      <div class="body p-md-4 p-2">

        <div class="bg-white p-4">
          <select class="form-select border-custom-naranja rounded-pill" aria-label="Default select example" name="evento_estado_id" onChange={(event) => onChange(event)}>
            <option selected>Seleccionar nuevo estado</option>
            <option value="1">ACTIVAR</option>
            <option value="2">PAUSAR</option>
            {
              rule == 1
              ? <option value="3">ELIMINAR</option>
              : ""
            }
            <option value="4">BORRADOR</option>
          </select>

          {
            data.eventos_estados_id == 1?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">ACTIVO</span> <span class="text-muted"> El evento se encuento publicado, listo para vender entradas y generar reportes.</span>
          </div>
            :
            data.eventos_estados_id == 2?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">PAUSADO</span> <span class="text-muted"> El evento puede ser pausado luego de publicarse.</span>
          </div>
          :
          data.eventos_estados_id == 3?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">ELIMINADO</span> <span class="text-muted"> Evento eliminado, ya no se mostrara en el perfil de las salas.</span>
          </div>
          :
          data.eventos_estados_id == 4?
            <div class="my-2">
            <span class="text-muted">Estado actual </span> <span class="fw-bold">BORRADOR</span> <span class="text-muted"> Evento en espera para ser publicado.</span>
          </div>
          : ""
          }

          
        </div>

      </div>
      <div class="footer bg-white p-4">
        <div class="d-flex justify-content-between">
          <div></div>
          <div class="">
          <button class="btn btn-secondary rounded-pill px-4" name="close_modal" onClick={(event) => onClick(event, null)}>Cerrar</button>
          <button class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4" onClick={(event) => onSave(event, data)}>Aplicar</button>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
  )
}

export default ModalEditarStatus