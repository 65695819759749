export const TYPES = {
  READ_ALL_CATEGORIAS: "READ_ALL_DATA",
  READ_ALL_EVENTS: "READ_ALL_EVENTS",
  READ_ALL_MY_EVENTS: "READ_ALL_MY_EVENTS",
  READ_ALL_DATO_FISCAL: "READ_ALL_DATO_FISCAL",
  READ_ALL_CATEGORIAS: "READ_ALL_CATEGORIAS",
  READ_ALL_SALAS: "READ_ALL_SALAS",
  READ_ALL_PROVINCIAS: "READ_ALL_PROVINCIAS",
  READ_APIPROVINCIAS: "READ_ALL_APIPROVINCIAS",
  READ_ALL_NOTAS: "READ_ALL_NOTAS",
  READ_ALL_VIDEOS: "READ_ALL_VIDEOS",
  READ_ALL_PODCAST: "READ_ALL_PODCAST",
  READ_ALL_CATEGORIASCONTENIDO: "READ_ALL_CATEGORIASCONTENIDO",
  READ_ALL_GALERIA_IMAGENES: "READ_ALL_GALERIA_IMAGENES",

  LOADER_DATA: "LOADER_DATA",

  SELECT_EVENT: "SELECT_EVENT",

  //ACTION ADMINISTRATOR

  READ_ALL_SALAS_ADM : "READ_ALL_SALAS_ADM",
  READ_ALL_EVENTS_ADM : "READ_ALL_EVENTS_ADM",
  READ_ALL_PRODUCERS_ADM : "READ_ALL_PRODUCERS_ADM",
  READ_ALL_ESPECTATOR_ADM : "READ_ALL_ESPECTATOR_ADM",
  READ_ALL_NOTAS_ADM: "READ_ALL_NOTAS_ADM",
  READ_ALL_VIDEOS_ADM: "READ_ALL_VIDEOS_ADM",
  READ_ALL_PODCAST_ADM: "READ_ALL_PODCAST_ADM",
  READ_ALL_CATEGORIASCONTENIDO_ADM: "READ_ALL_CATEGORIASCONTENIDO_ADM",
  READ_ALL_GALERIAIMAGENES: "READ_ALL_GALERIAIMAGENES"

}

