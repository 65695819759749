import clientAxios from "./Config";



export const ObtenerDatoFiscal = async () => {

  const result  = await clientAxios.get('/api/auth/ObtenerDatoFiscal').then((result) => {return result.data}).catch((result) => {return null});

  return result; 

}


export default (
  ObtenerDatoFiscal
)