import Suscriptions from "../Home/Sections/Suscriptions/Suscriptions"
import Contenido from "./Contenido/Contenido"

const Somos = () => {

  return (
   <>
    <Contenido/>
   </> 
  )
}

export default Somos