import { useContext } from 'react';
import {NavLink, Link } from 'react-router-dom';
import { authContext } from '../../../context/auth/AuthContext';

const HeaderPerfil = ({outSession }) => {
  const {user} = useContext(authContext);

  switch (user.rol) {
    case null: {
      return (
        
          <li className="nav-item">

          <NavLink
            aria-current="page"
            to="/ingresar"
            className={(navData) => (navData.isActive? 'nav-link text-nowrap active' : 'nav-link text-nowrap')}
          > 
            Iniciar Sesion
          </NavLink>
          </li>
      )
    }
    break;
    case 1: {
      return (<li className="list-group-item border-0 m-0 p-0 nav-item dropdown">
      
      <Link
        className="nav-link"
        to="/dashboard/micuenta"
        target={"_blank"}
      >
        Administrador
      </Link>

      { /*

      <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown">
   
      <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/eventos" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Eventos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/productores" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item  text-nowrap')}>
            Productores
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/salas" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item  text-nowrap')}>
            Salas
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/usuarios" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item  text-nowrap')}>
            Usuarios
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/configuracion" className={(navData) => (navData.isActive? 'dropdown-item text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Configuracion
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/administrador/usuario" className={(navData) => (navData.isActive? 'dropdown-item text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Datos
          </NavLink>
        </li>

        <li>
          <a className="dropdown-item text-nowrap" onClick={outSession}>
            Cerrar Sesion
          </a>
        </li>
      </ul>
      
        */}

    </li>
    )
    }
      break;

    case 2: {
      return (
      <li className="list-group-item border-0 m-0 p-0 nav-item dropdown text-nowrap">
      
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Mi perfil
      </a>

      <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown">
      
      <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/espectador" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap d-none')}>
            Mis Eventos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/espectador" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Datos
          </NavLink>
        </li>

        <li>
          <a className="dropdown-item" onClick={outSession}>
            Cerrar Sesion
          </a>
        </li>

      </ul>

    </li>
    )};
      break;
      
    case 3: {
      return (<li className="list-group-item border-0 m-0 p-0 nav-item dropdown text-nowrap">
      
      <Link
        className="nav-link "
        to="/dashboard/micuenta"
        id="navbarDropdown"
        role="button"
      >
        Mi cuenta
      </Link>

     { /*
      <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown">
      
      <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/sala/eventos" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Eventos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/sala/usuario" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Datos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/sala/ticket" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Ticket
          </NavLink>
        </li>

        <li>
          <a className="dropdown-item" onClick={outSession}>
            Cerrar Sesion
          </a>
        </li>

      </ul>
      */}

    </li>)
      
    }
      break;
      
    case 4: {
      return (
      
      <li className="list-group-item border-0 m-0 p-0 nav-item dropdown text-nowrap">
      
      <Link
        className="nav-link"
        to="/dashboard/micuenta"
        id="navbarDropdown"
        role="button"
      >
        Mi cuenta
      </Link>

     { /*
      <ul className="dropdown-menu shadow border-0 p-0" aria-labelledby="navbarDropdown">
      
        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/productor/eventos" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Eventos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/productor/usuario" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Datos
          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Mi Perfil" to="/dashboard/productor/ticket" className={(navData) => (navData.isActive? 'dropdown-item  text-nowrap text-custom-naranja' : 'dropdown-item text-nowrap')}>
            Mis Ticket
          </NavLink>
        </li>

        <li>
          <a className="dropdown-item" onClick={outSession}>
            Cerrar Sesion
          </a>
        </li>

      </ul>
      */
      }

    </li>
    )
    }
      break;
  }

  return null


}

export default HeaderPerfil