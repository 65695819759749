import Evento from "../components/eventos/Evento";
import Filtro from "../components/eventos/Filtro";

const Entradas = () => {
  return (
    <main className="m-0 p-0">
      <section id="eventos" style={{paddingTop: "120px", paddingBottom: "50px"}}>
        <div className="container-md">
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            data-bs-backdrop="false"
            tabindex="-1"
            id="offcanvasScrolling"
            aria-labelledby="offcanvasScrollingLabel"
          >
            <div className="offcanvas-header ">
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <Filtro />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 d-none d-md-block" style={{ width: "400px" }}>
              <Filtro />
            </div>

            <div className="col-md-8">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center me-4 wow bounceInUp d-block d-md-none">
                    <label className="btn bg-naranja text-white">
                      Filtrar
                      <button
                        className="d-none"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasScrolling"
                        aria-controls="offcanvasScrolling"
                      ></button>
                    </label>
                  </div>
                  <div className="d-flex align-items-center me-4 wow bounceInUp">
                    <p className="mb-0 me-2 text-nowrap">Ordenar Por</p>
                    <select className="form-select form-select form-select-sm">
                      <option selected>Seleccionar</option>
                      <option value="1">Mayor precio</option>
                      <option value="2">Menor precio</option>
                      <option value="3">Más recientes</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="wow bounceInUp" />
                </div>
                {[1, 2, 3, 4].map((e, index) => (
                  <div className="col-md-6 col-lg-4 mb-3">
                    <Evento />
                  </div>
                ))}
                <div className="d-grid gap-2 wow bounceInUp">
                  <label className="btn btn-custom-amarillo">ver más +</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Entradas;
