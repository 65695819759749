const CardVideo = ({ data, onClick, concatDate }) => {
  const { created_at, id, imagen, url, titulo, descripcion } = data;

  return (
    <div class="col-lg-4">
      <div class="bg-gris-oscuro box-multimedia mb-4 wow bounceInUp">
        <div class="header-box position-relative d-flex align-items-center justify-content-center">
          <label className="btn">
            <img src={"/assets/img/videos/" + imagen} className="w-100" />
            <button
              class="lottie-player d-none"
              name="reproducir"
              onClick={(event) => onClick(event, data)}
            ></button>
          </label>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <iframe
                    width="100%"
                    height="500px"
                    src={url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-md-1">
          <div>
            <p class="fc-blanco">{concatDate(created_at, "created_at")}</p>
            <hr class="hr-white-01" />
            <h2 class="fc-amarillo mb-0">{titulo}</h2>
            <p class="fc-blanco">{descripcion}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardVideo;
