import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

/*
export const crearEvento = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/CrearEvento',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)

    return result.data.id;
  }).catch((result) => {
    Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "3000",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return ""; //window.location.reload(true);
  });

  return response
}
*/


export const CrearEntrada = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Entradas/Crear',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}

export const UpdateEntrada = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Entradas/Actualizar',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}

export const EliminarEntrada = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Entradas/Eliminar',
    data: dataForm,
  }).then((result) => {
    //setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}

