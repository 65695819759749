import { Link } from 'react-router-dom';
import useScript from '../../../hooks/useScript';

const Footer = () => {
    return (
        <footer className="bg-black pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 d-flex align-self-center justify-content-center justify-content-md-starts mb-4 mb-md-0">
                        <img
                            src="/assets/img/marca/antesala-logo-white.svg"
                            width="50%"
                            alt=""
                        />
                    </div>
                    <div className="col-md-3">
                        <h1 className="GothamLight fc-blanco mt-4 mt-md-0">Enlaces</h1>
                        <ul className="mb-0 p-0">
                            <li className="pb-2">
                                <a
                                    aria-label="Quienes Somos"
                                    href="/somos#"
                                    className="GothamLight fc-blanco"
                                >
                                    Quienes Somos
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    aria-label="Como Comprar"
                                    href="/"
                                    className="GothamLight fc-blanco"
                                >
                                    Como Comprar?
                                </a>
                            </li>
                            <li className="pb-2">
                                <div >
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/tarjeta-naranja.svg" alt="" />
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/mastercard.svg" alt="" />
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/maestro.svg" alt="" />
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/cabal.svg" alt="" />
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/amex.svg" alt="" />
                                <img style={{height: "20px"}} className='img-fluid me-1' src="/assets/img/visa.svg" alt="" />
                                </div>
                            </li>
                            <li className="pb-2">
                                <a
                                    href="/cliente/registro" className="GothamLight fc-blanco">Publicá tu evento</a>
                            </li>
                            <li className="pb-2">
                                <a
                                    target={"_blank"}
                                    href="/assets/img/Porpuesta-Comercial-Grupo Antesala-SAS.pdf" 
                                    className="GothamLight fc-blanco">Pautá con nosotrxs</a>
                            </li>
                            <li>
                                <a target={"_blank"} href="https://docs.google.com/forms/d/e/1FAIpQLSe7ECnF4fwLanTbndvSwM3ltTjq_PDWjsfGO7tCEOCh1BV_1A/viewform" className="GothamLight fc-blanco d-none">Contáctanos</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h1 className="GothamLight fc-blanco mt-4 mt-md-0">Mi Cuenta</h1>
                        <ul className="p-0">
                            <li className="pb-2">
                            <a
                                    aria-label="Ingresar"
                                    href="/ingresar"
                                    className="GothamLight fc-blanco"
                                >
                                    Mis entradas
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    aria-label="recuperar"
                                    href="/restaurar"
                                    className="GothamLight fc-blanco"
                                >
                                    Recuperar cuenta
                                </a>
                            </li>
                        </ul>
                        <h1 className="GothamLight fc-blanco mt-4 mt-md-0">Salas y Productores</h1>
                        <ul className="mb-0 p-0">
                            <li className="pb-2">
                            <a
                                    aria-label="Ingresar"
                                    href="/ingresar"
                                    className="GothamLight fc-blanco"
                                >
                                    Iniciar Sesion
                                </a>
                                </li>
                            <li><a
                                    aria-label="Registrate"
                                    href="/cliente/registro"
                                    className="GothamLight fc-blanco"
                                >
                                    Registrate
                                </a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <ul className="mb-0 p-0 mt-4 mt-md-0">
                            <li className="pb-2"><a href="/typ" className="GothamLight fc-blanco">Terminos y Condiciones</a></li>
                            <li className="pb-2"><a href="/typ" className="GothamLight fc-blanco">Políticas de Privacidad</a></li>
                            <li className="pb-2"><a href="/typ" className="GothamLight fc-blanco">Preguntas Frecuentes</a></li>
                            <li><a  target={"_blank"} href="https://docs.google.com/forms/d/e/1FAIpQLSe7ECnF4fwLanTbndvSwM3ltTjq_PDWjsfGO7tCEOCh1BV_1A/viewform" className="GothamLight fc-blanco">antesala.cordoba@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white mt-100 p-4">
                <p className="Grifter m-0">© Copyright - 2022 | Antesala.</p>
            </div>
        </footer>
    );

}

export default Footer;