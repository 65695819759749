import Suscriptions from "../Home/Sections/Suscriptions/Suscriptions"
import Container from "./sections/Container/Container"
import SalasHeader from "./sections/Header/Header"

const Salas = () => {

  return(
    <>
      <SalasHeader/> 
      <Container/>
    </>
  )
}

export default Salas