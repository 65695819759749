const Apoyo = () => {
  return (
    <section id="apoyo" className="m-0 wow bounceInUp p-0 m-0 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mb-1 d-flex align-items-center">
            <div className="text-center w-100 bg-gris-oscuro br-10 pt-50 pb-50 pe-4 ps-4">
              <h1 className="text-white fs-2 m-0">contamos con el apoyo de</h1>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <div className="wrapper">
              <div className="carousel">
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <a href="http://www.inteatro.gob.ar/" target={"_blank"}>
                      <img src="/assets/img/logo-01.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <a
                      href="https://www.instagram.com/reddesalascordoba/?hl=es"
                      target={"_blank"}
                    >
                      <img src="/assets/img/logo-02.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <img src="/assets/img/logo-03.png" alt="" />
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <img src="/assets/img/logo-04.png" alt="" />
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <a
                      href="https://www.instagram.com/elcuencoteatro/?hl=es"
                      target={"_blank"}
                    >
                      <img src="/assets/img/logo-05.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <a
                      href="https://nomegrites.com.ar/producciones/"
                      target={"_blank"}
                    >
                      <img src="/assets/img/logo-nmg.svg" alt="" />
                    </a>
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <img src="/assets/img/logo-01.png" alt="" />
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <img src="/assets/img/logo-02.png" alt="" />
                  </div>
                </div>
                <div className="carousel__item">
                  <div className="carousel__item-body">
                    <img src="/assets/img/logo-03.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apoyo;
