import { useEffect, useState } from "react"
import clientAxios from "../../helpers/Config";

const useApi = (url, parametros, steps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState("");

  let params = {};

  Object.keys(parametros).map((p) => {
    if(p !== "date_range"){
      if(parametros[p].trim() !== ""){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    } else {
      if(parametros[p].length !== 0){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    }
    
  })

  const doAxios = async () => {
    const response = await clientAxios.get(url, { params })
    .then((result) => {
      setIsLoading(false);
      setData(result.data);
    })
    .catch((result) => {
      setIsLoading(false);
      setData(result.data);
      setMessage(result.response.data.msg);
    });
  }


  useEffect(() => {
    doAxios()
  },[steps])
  return {
    data,
    message,
    isLoading,
  }
}

export default useApi