import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GraficoLineal = ({
  labels,
  title,
  name_item,
  input,
  b_color,
  bg_color,

  comparativa,
  c_name_item,
  c_input,
  c_b_color,
  c_bg_color,

  acumulativo_item,
  acumulativo_input,
  acumulativo_b_color,
  acumulativo_bg_color,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: name_item,
        data: input,
        borderColor: b_color,
        backgroundColor: bg_color,
      },
      {
        label: c_name_item,
        data: c_input,
        borderColor: c_b_color,
        backgroundColor: c_bg_color,
      },
      {
        label: acumulativo_item,
        data: acumulativo_input,
        borderColor: acumulativo_b_color,
        backgroundColor: acumulativo_bg_color,
      },
    ],
  };

  return (
    <div className="" style={{ borderRadius: "10px" }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default GraficoLineal;
