import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";


export const CrearLiquidacion = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Liquidacion/Crear',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}


export const UpdateLiquidacion = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Liquidacion/Actualizar',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error");
  });

  return response
}

export const EliminarLiquidacion = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Liquidacion/Eliminar',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg)
  }).catch((result) => {
    setLoading(false)
    return AlertError("Ocurrio un error en el servidor","Error"); 
  });

  return response
}