import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { CollectionStateContext } from "../../../context/EventosState";
import { VideosHome } from "../../../helpers/VideosHelper";
import useApi from "../../../hooks/useApi";
import CardVideo from "./CardVideo";
import ModalVideo from "./Modals/ModalVideo";

const Videos = () => {

  const [steps, setSteps] = useState(0);
  const [modalVideo, setModalVideo] = useState(false);
  const geolocation = localStorage.getItem("geolocation");

  //params
  const [params, setParams] = useState({
    id: "",
    provincia: "",
    url: "/api/Multimedia",
  });

  
  const concatDate = (fecha, select) => {
    if(!fecha){
      return "Sin fecha asignada"
    }

    let result;

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];


    switch (select) {
      case "created_at":{
        let fh = new Date(fecha);
        
        result = dias[fh.getDay()]+" "+fh.getDate()+" de "+(mes[fh.getMonth()])+" de "+fh.getFullYear()+" Hora: "+fh.getHours()+":"+fh.getMinutes()
      }
      break;
      case "funciones":{
        if(!fecha){
          return ""
        }
        let fechas = JSON.parse(fecha);
        let fh_start = fechas[0].fechas[0].split(" ");
        let fh_end = fechas[fechas.length-1].fechas[fechas[fechas.length-1].fechas.length-1].split(" ");

        result = "Desde el "+fh_start[0]+" "+fh_start[1]+" de "+fh_start[3]+" hasta el "+fh_end[0]+" "+fh_end[1]+" de "+fh_end[3]
      }
      break;
    }

    return result;
  }

  const onClick = (e, value) =>  {
    switch (e.target.name) {
        case "reproducir":{
            setModalVideo(value);
        } break;
        case "close": {
            setModalVideo(false);
        } break;
    }
  }


  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps); 
  return (
    <>
    <section class="wow bounceInUp d-none">
                <div class="container">
                    <div class="row">
                        <div class="col-8 col-md-9 pe-0">
                            <div class="card-ticket cardLeft d-flex flex-column">
                                <h1 class="fc-blanco">Videos</h1>
                                <div class="title mb-5">
                                    <h2>Antesala</h2>
                                    <span>sala</span>
                                </div>
                                <div>
                                    <div class="seat">
                                        <h2>156</h2>
                                        <span>asiento</span>
                                    </div>
                                    <div class="time">
                                        <h2>12:00</h2>
                                        <span>hora</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-md-3 ps-0">
                            <div class="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                                <div class="text-center">
                                    <img src="/assets/img/iso-antesala.svg"/>
                                </div>
                                <div class="number">
                                    <h3>156</h3>
                                    <span>asiento</span>
                                </div>
                                <div class="text-center">
                                    <img class="barchart" src="/assets/img/barcode.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="video" class="mt-100 mb-5 overflow-hidden">
                <div class="container">
                    <div class="row">

                        {
                            data
                            ? data.length !== 0 
                            ? <div class="col-lg-6">
                            <div class="bg-naranja box-multimedia mb-4 wow bounceInUp">
                                <div class="header-box position-relative d-flex align-items-center justify-content-center">
                                    <label className="btn">
                                    <img src={"/assets/img/videos/"+data[0].imagen} className="w-100" />
                                    <button class="lottie-player d-none"  name="reproducir" onClick={(event) => onClick(event, data[0])}>
                                    </button>
                                    </label>
                                </div>
                                <div class="d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-md-1">
                                    <div>
                                        <p class="fc-blanco">{concatDate(data[0].created_at, "created_at")}</p>
                                        <hr class="hr-white-01" />
                                        <h2 class="fc-blanco mb-0">{data[0].titulo}</h2>
                                        <p class="fc-blanco">{data[0].descripcion}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            : ""
                            : ""
                        }

                        {
                            data
                            ? data.length > 1 
                            ? <div class="col-lg-6">
                            <div class="bg-gris-oscuro box-multimedia mb-4 wow bounceInUp">
                                <div class="header-box position-relative d-flex align-items-center justify-content-center">
                                    <label className="btn">
                                    <img src={"/assets/img/videos/"+data[1].imagen} className="w-100" />
                                    <button class="lottie-player d-none"  name="reproducir" onClick={(event) => onClick(event, data[1])}>
                                    </button>
                                    </label>
                                </div>
                                <div class="d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-md-1">
                                    <div>
                                        <p class="fc-blanco">{concatDate(data[1].created_at, "created_at")}</p>
                                        <hr class="hr-white-01" />
                                        <h2 class="fc-amarillo mb-0">{data[1].titulo}</h2>
                                        <p class="fc-blanco">{data[1].descripcion}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                        : ""
                        }

                    </div>

                    <div class="row">

                        {
                            data
                            ? data.length > 0
                            ? data.map((video, index) => {
                                if(index > 1){
                                    return <CardVideo keys={index} data={video} onClick={onClick}  concatDate={concatDate} />
                                }
                            })
                            : ""
                            : ""
                        }

                        <div class="d-grid gap-2 wow bounceInUp d-none">
                            <a href="#" class="btn btn-custom-naranja">ver más +</a>
                        </div>
                    </div>  
                </div>
                <img src="/assets/img/bg-06.svg" class="img-fluid position-absolute" style={{width: "100%", top: "50%", zIndex: -1}}/>
            </section>

            {
                modalVideo
                ? <ModalVideo 
                   data={modalVideo}
                   onClick={onClick}
                />
                : ""
            }
    </>
    
  )
}

export default Videos