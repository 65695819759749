const TableResponsive = ({
  rule,
  evento,
  reportes,
  onChange,
  onClick,
  nextPage,
  prevPage,
  links,
  form_reporte,
}) => {
  const concatDate = (fecha) => {
    let fh = new Date(fecha);

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    return (
      dias[fh.getDay()] +
      " " +
      fh.getDate() +
      " de " +
      mes[fh.getMonth()] +
      " de " +
      fh.getFullYear() +
      " Hora: " +
      fh.getHours() +
      ":" +
      fh.getMinutes()
    );
  };

  const maxTickets = (e) => {
    let suma = 0;

    switch (e) {
      case "cantidad":
        {
          reportes.data.map((reporte) => {
            suma = reporte.cantidad + suma;
          });
          return suma;
        }
        break;
      case "precio":
        {
          reportes.data.map((reporte) => {
            suma = reporte.cantidad * reporte.precio_unitario + suma;
          });
          return suma;
        }
        break;
      case "precioServ":
        {
            reportes.data.map((reporte) => {
            if((reporte.cantidad*reporte.precio_unitario) == parseInt(reporte.precio_total)){
            if((reporte.cantidad*reporte.precio_unitario) > 500){
            suma = (reporte.cantidad*reporte.precio_unitario) + (((reporte.cantidad*reporte.precio_unitario)/100)*10) + suma;
            } else {
            suma = (reporte.cantidad*reporte.precio_unitario) + suma + 50;
            }
            } else {
            suma = parseInt(reporte.precio_total) + suma;
            }
          });

          return suma;
        }
        break;
      case "descuento":
        {
          reportes.data.map((reporte) => {
            if((reporte.cantidad*reporte.precio_unitario) == reporte.precio_total){
            if(reporte.stated_descuento == "ON"){
            suma = (reporte.cantidad*reporte.precio_unitario) - ((parseInt(reporte.precio_total)/100)*reporte.value_descuento) + suma;
            } else {
            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
            }
            } else {
            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
            }
          });

          return suma;
        }
        break;

    }
  };

  const liquiPorcentaje = (porcentaje) => {
    let resto =
      (maxTickets("precio") / 100) *
      evento.liquidacion[0].porcentaje_argentores;
    resto = maxTickets("precio") - resto;

    return (resto / 100) * porcentaje;
  };

  const check = (id) => {
    form_reporte.check_reporte.map((check) => {
      if (check.id == id) {
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <div
      className="table-responsive bg-white my-3"
      style={{ borderRadius: "10px" }}
    >
      <table
        className="table table-hover align-middle border-0"
        id="reporteVentas"
      >
        <thead className="fs-10 text-start border-0">
          <tr>
            <th className="border-0">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                name="check_all"
                onChange={(event) => onChange(event)}
              />
            </th>
            <th className="border-0 text-nowrap">Id</th>
            <th className="border-0 text-nowrap">Fecha de compra</th>

            <th className="border-0 text-nowrap">
              {" "}
              <i class="bi bi-stars"></i> Nombre evento{" "}
            </th>

            {
              rule  == 1
              ? <th className="border-0 text-nowrap">
              <i class="bi bi-bank"></i> Nombre sitio
            </th>
            : ""
            }

            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Nombre cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> DNI cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Telefono cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Email cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Codigo
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Tipo
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Fecha de entrada
            </th>
            <th className="border-0 text-nowrap">Monto Unidad</th>
            <th className="border-0 text-nowrap">Cantidad</th>
            <th className="border-0 text-nowrap">Monto Total</th>

            {rule == 1 ? (
              <th className="border-0 text-nowrap">Monto Total +Serv</th>
            ) : (
              ""
            )}

           <th className="border-0 text-nowrap">Descuento</th>
           <th className="border-0 text-nowrap">Codigo descuento</th>

            <th className="border-0"></th>
            <th className="border-0"></th>
            <th className="border-0"></th>
          </tr>
        </thead>
        <tbody className="fs-9 border-0">
          {reportes.data.map((reporte, index) => (
            <tr>
              <td className="border-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="check_reporte"
                  onChange={(event) => onChange(event, reporte)}
                  checked={
                    form_reporte.check_all
                      ? true
                      : form_reporte.check_reporte.length == 0
                      ? false
                      : check(reporte.id)
                  }
                />
              </td>
              <td className="border-0">{index + 1}</td>
              <td className="border-0">{concatDate(reporte.created_at)}</td>
              <td className="border-0">
                {reporte.eventos
                  ? reporte.eventos.nombre
                  : evento
                  ? evento.nombre
                  : ""}
              </td>
              {
                rule == 1
                ? 
                <td className="border-0">
                  {reporte.eventos
                    ? reporte.eventos.nombre_sitio
                    : evento
                    ? evento.nombre_sitio
                    : ""}
                </td>
                : ""
              }
              <td className="border-0 text-nowrap">{reporte.nombre_cliente}</td>
              <td className="border-0">{reporte.dni_cliente}</td>
              <td className="border-0">{reporte.telefono_cliente? reporte.telefono_cliente : reporte.usuarios? reporte.usuarios.telefono : "" }</td>
              <td className="border-0">{reporte.email_cliente? reporte.email_cliente : reporte.usuarios? reporte.usuarios.email : "" }</td>
              <td className="border-0">{reporte.codigo}</td>

              <td className="border-0">
                {reporte.entradas ? reporte.entradas.nombre : ""}
              </td>
              <td className="border-0">{reporte.fecha_entrada}</td>
              <td className="border-0">{reporte.precio_unitario}</td>
              <td className="border-0">{reporte.cantidad}</td>
              <td className="border-0">
                {reporte.cantidad * reporte.precio_unitario}
              </td>

              {rule == 1 ? (
                <td className="border-0">{
                  reporte.precio_total == reporte.cantidad * reporte.precio_unitario
                  ? parseInt(parseInt(reporte.precio_total)+((reporte.precio_total/100)*10))
                  : reporte.precio_total
                  }</td>
              ) : (
                ""
              )}

              <td className="border-0 text-nowrap">
                {
                reporte.stated_descuento == "ON"
                ? <div>
                  ARS {(reporte.precio_unitario*reporte.cantidad)-((reporte.precio_unitario*reporte.cantidad)/100)*reporte.value_descuento}
                  <div>
                   % {reporte.value_descuento} %
                  </div>
                  </div>
                : "Sin descuento"
                }
              </td>
                
              <td className="border-0">
                {
                reporte.stated_descuento == "ON"
                ? <div>
                {reporte.codigo_descuento}
                </div>
                : ""
                }
              </td>

              {rule == 1 ? (
                <td className="border-0 p-0 px-1 text-nowrap">
                  <button
                    className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0"
                    name="editar_status"
                    onClick={(event) => onClick(event, [reporte])}
                  >
                    <i className="bi bi-three-dots"></i> Modificar Estado
                  </button>
                </td>
              ) : (
                ""
              )}

            {rule == 1 
            ?  <td className="border-0 p-0 px-1 text-nowrap">
            <div className="dropdown">
              <button
                className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={form_reporte.check_all ? true : false}
              >
                Estado de pago...
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                  <li>
                    <a className="dropdown-item" href="#" name="info_decidir" onClick={(event) => onClick(event, reporte.api_establecimiento_id)}>
                      <i class="mx-1 bi bi-cash-coin"></i>
                      Detalles Decidir
                    </a>
                  </li>
                <li>
                  <a className="dropdown-item" href="#" name="info_cliente" onFocus={(event) => onClick(event, reporte)}>
                    <i className="mx-1 bi bi-person"></i>Perfil del cliente
                  </a>
                </li>
              </ul>
            </div>
          </td>
            : <td className="border-0">
              <a className="text-dark" title="Perfil del cliente" href="#" name="info_cliente" onFocus={(event) => onClick(event, reporte)}>
              <i class="fs-6 bi bi-person-plus"></i>
              </a>
            </td>
            }
             

              <td className="border-0 p-0">
                {reporte.status == "ACTIVO" ? (
                  <i
                    title="Activo"
                    className="btn fs-6 text-success bi bi-check-circle"
                  ></i>
                ) : reporte.status == "REEMBOLSO" ? (
                  <i
                    title="Reembolsado"
                    class="btn fs-6 text-warning bi bi-exclamation-triangle"
                  ></i>
                ) : reporte.status == "INACTIVO" ? (
                  <i
                    title="Inactivo"
                    class="btn fs-6 text-danger bi bi-x-circle"
                  ></i>
                ) : reporte.status == "RECHAZADO" ? (
                  <i
                    title="Rechazado"
                    class="btn fs-6 text-danger bi bi-x-circle"
                  ></i>
                ) : reporte.status == "LIQUIDADO" ? (
                  <i
                    title="Liquidado"
                    class="btn fs-6 text-success bi bi-coin"
                  ></i>
                ) : reporte.status == "PAUSADO" ? (
                  <i
                    title="Pausado"
                    class="btn fs-6 text-secondary bi bi-slash-circle"
                  ></i>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
          <tr className="bg-secondary bg-opacity-10 border-0">
            <td colspan="13" className="border-0">
              <span className="fs-6">Total bruto</span>
            </td>
            <td colspan="1" className="border-0 text-nowrap">
              <span className="fs-6">
                <i className="bi bi-ticket-perforated"></i>{" "}
                {maxTickets("cantidad")}
              </span>
            </td>
            <td className="border-0 text-nowrap">
              <span className="fs-6">ARS {maxTickets("precio")}</span>
            </td>
            {
              rule == 1
              ? <td className="border-0 text-nowrap">
              <span className="fs-6">ARS {maxTickets("precioServ")}</span>
              </td>
              : ""
            }
            
            <td className="border-0 text-nowrap">
              <span className="fs-6">ARS {maxTickets("descuento")}</span>
            </td>
            
            {
              rule == 1
              ? <td colspan="1" className="border-0"></td>
              : ""
            }
          </tr>

          {evento 
          ? rule == 1 || rule == 2
          ? (
            <tr className="bg-secondary bg-opacity-10 border-0">
              <td colspan="13" className="border-0">
                <span className="fs-6">Argentores</span>
              </td>
              <td colspan="1" className="border-0 text-nowrap">
                <span className="fs-6">
                  <i class="bi bi-percent"></i>
                  {evento
                    ? evento.liquidacion.length == 0
                      ? ""
                      : evento.liquidacion[0].porcentaje_argentores
                    : ""}
                </span>
              </td>
              <td colspan="1" className="border-0 text-nowrap">
                <span className="fs-6">
                  ARS{" "}
                  {evento
                    ? evento.liquidacion.length == 0
                      ? ""
                      : (maxTickets("precio") / 100) *
                        evento.liquidacion[0].porcentaje_argentores
                    : ""}
                </span>
              </td>
              {
                rule == 1 || rule == 2
                ? <td colspan="3" className="border-0"></td>
                : ""
              }
            </tr>
          ) : (
            ""
          ) : (
            ""
          )}

          <tr className="bg-secondary bg-opacity-10 border-0">
            <td colspan="13" className="border-0">
              <span className="fs-6">Total neto</span>
            </td>
            <td colspan="1" className="border-0 text-nowrap">
              <span className="fs-6">
                <i className="bi bi-ticket-perforated"></i>{" "}
                {maxTickets("cantidad")}
              </span>
            </td>
            <td className="border-0 text-nowrap">
              <span className="fs-6">
                ARS{" "}
                {evento
                  ? evento.liquidacion.length == 0
                    ? maxTickets("precio")
                    : maxTickets("precio") -
                      (maxTickets("precio") / 100) *
                        evento.liquidacion[0].porcentaje_argentores
                  : maxTickets("precio")}
              </span>
            </td>
            {
              rule == 1
              ? <td className="border-0"></td>
              : ""
            }
            <td className="border-0 text-nowrap">
              <span className="fs-6">ARS {maxTickets("descuento")}</span>
            </td>
            {
              rule == 1
              ? <td colspan="1" className="border-0"></td>
              : ""
            }

          </tr>

          {
            evento
            ?  <tr className="border-0 fs-10">
            <th colspan="4" className="border-0">
              CBU
            </th>
            <th colspan="4" className="border-0">
              CUIL/CUIT
            </th>
            <th colspan="3" className="border-0">
              Situación Fiscal
            </th>
            <th colspan="2" className="border-0">
              Porcentaje
            </th>
            <th colspan="1" className="border-0 text-nowrap">
              Monto a pagar
            </th>
            <th colspan="3" className="border-0"></th>
          </tr>
            : ""
          }
         

          {evento
            ? evento.liquidacion.map((liqui) => (
                <tr>
                  <td colspan="4" className="border-0">
                    <div className="text-start">
                      {liqui.cbu}
                      <a
                        href={
                          "https://antesala.com.ar/assets/img/comprobantes/" +
                          liqui.comprobante_cbu
                        }
                        className="btn border-custom-naranja fw-normal text-custom-naranja GothamBold w-100 fs-8 text-nowrap p-0 m-0 px-2 mx-auto rounded-pill"
                      >
                        <i className=" bi bi-file-earmark-pdf"></i>
                        Descargar archivo
                      </a>
                    </div>
                  </td>
                  <td colspan="4" className="border-0">
                    <div className="text-start">
                      {liqui.entidad}
                      <a
                        href={
                          "https://antesala.com.ar/assets/img/comprobantes/" +
                          liqui.comprobante_entidad
                        }
                        className="btn border-custom-naranja fw-normal text-custom-naranja GothamBold w-100 fs-8 text-nowrap p-0 m-0 px-2 mx-auto rounded-pill"
                      >
                        <i className=" bi bi-file-earmark-pdf"></i>
                        Descargar archivo
                      </a>
                    </div>
                  </td>
                  <td colspan="3" className="border-0">
                    <div className="text-start">
                      {liqui.dato_fiscal}
                      <a
                        href={
                          "https://antesala.com.ar/assets/img/comprobantes/" +
                          liqui.comprobante_afip
                        }
                        className="btn border-custom-naranja fw-normal text-custom-naranja GothamBold w-100 fs-8 text-nowrap p-0 m-0 px-2 mx-auto rounded-pill"
                      >
                        <i className=" bi bi-file-earmark-pdf"></i>
                        Descargar archivo
                      </a>
                    </div>
                  </td>
                  <td colspan="2" className="border-0">
                    {liqui.porcentaje}
                  </td>
                  <td colspan="1" className="border-0 text-nowrap">
                    ARS {liquiPorcentaje(liqui.porcentaje)}
                  </td>
                  <td colspan="3" className="border-0"></td>
                </tr>
              ))
            : ""}
        </tbody>
        <tfoot className="mb-0 p-0 border-0">
          <tr>
            <td colspan="14" className="border-0 m-0 px-1 pt-4">
              <div className="d-flex justify-content-end">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      <button
                        style={{ borderRadius: "10px" }}
                        className={
                          reportes
                            ? reportes.current_page == 1
                              ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                              : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                            : "d-none"
                        }
                        onClick={prevPage}
                      >
                        Anterior
                      </button>
                    </li>
                    {reportes
                      ? reportes.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == reportes.current_page + 1 ||
                            item.label == reportes.current_page + 2 ||
                            item.label == reportes.per_page ||
                            item.label == reportes.last_page
                          ) {
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true
                                    ? "page-item bg-naranja me-1 fs-8 text-white"
                                    : "page-item me-1 fs-8 text-dark"
                                }
                              >
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn fs-8 border-0 bg-transparent border-custom-naranja text-white"
                                      : "btn fs-8 border-0 bg-transparent text-custom-naranja border-custom-naranja"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      <button
                        style={{ borderRadius: "10px" }}
                        className={
                          reportes
                            ? reportes.current_page == reportes.last_page
                              ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                              : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                            : "d-none"
                        }
                        onClick={nextPage}
                      >
                        Siguiente
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableResponsive;
