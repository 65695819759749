import "./App.css";
import { cerrarSession } from "./helpers/AccessHelper";

import {
  Link,
  NavLink,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Body from "./components/layout/Body/Body";
import Maintenance from "./components/layout/Loads/Maintenance";

import AccesLogin from "./components/layout/Access/AccesLogin";
import AccesRegister from "./components/layout/Access/AccesRegister";
import UpperRegister from "./components/layout/Access/UpperRegister";

import AuthProvider from "./context/auth/AuthContext";
import RouteGuest from "./middleware/Guest";
import Role from "./middleware/Role";

import VistaEvento from "./components/layout/Ticket/Vista";

import Home from "./components/layout/Home/Home";
import RestaurarPassword from "./components/layout/Access/RestaurarPassword";
import ForgotPassword from "./components/layout/Access/ForgotPassword";
import EventosState from "./context/EventosState";
import Entradas from "./components/layout/Entradas/Entradas";
import Salas from "./components/layout/Salas/Salas";
import Sala from "./components/layout/Sala/Sala";
import Somos from "./components/layout/Somos/Somos";
import TYP from "./components/layout/TYP/TYP";
import AcceptPago from "./components/layout/Alert/AcceptPago";
import ErrorCard from "./components/layout/Alert/ErrorCard";
import ConfirmRegistro from "./components/layout/Alert/ConfirmRegistro";
import RestorePassword from "./components/layout/Alert/RestorePassword";
import Cookie from "./components/layout/Cookie/Cookie";
import MedioDePago from "./components/layout/MedioDePago/MedioDePago";
import Notas from "./components/layout/Noticias/Notas";
import Nota from "./components/layout/Noticias/Nota";
import Videos from "./components/layout/Videos/Videos";
import Fiestas from "./components/layout/Fiestas/Fiestas";

/*Dashboard routes*/
import Dashboard from "./components/Dashboard/Dashboard";
import MiCuenta from "./components/Dashboard/Layout/MiCuenta/MiCuenta";
import Eventos from "./components/Dashboard/Layout/Eventos/Eventos";
import ControlSalas from "./components/Dashboard/Layout/Salas/ControlSalas";
import FormEvento from "./components/Dashboard/Layout/Eventos/Add/FormEvento";
import Facturacion from "./components/Dashboard/Layout/Facturacion/Facturacion";
import MisCompras from "./components/Dashboard/Layout/MisCompras/MisCompras";
import ControlProductores from "./components/Dashboard/Layout/Productores/ControlProductores";
import ControlUsuarios from "./components/Dashboard/Layout/Usuarios/ControlUsuarios";
import ControlNotas from "./components/Dashboard/Layout/Notas/ControlNotas";
import FormNotas from "./components/Dashboard/Layout/Notas/Add/FormNotas";
import Configuracion from "./components/Dashboard/Layout/Configuracion/Configuracion";
import Boleteria from "./components/Dashboard/Layout/Boleteria/Boleteria";
import ControlVideos from "./components/Dashboard/Layout/Videos/ControlVideos";
import Cupones from "./components/Dashboard/Layout/Cupones/Cupones";

/*APK ROUTES*/
import Apk from "./App/Layout/Apk";
//Access
import Login from "./App/Layout/Login/Login";
import Box from "./App/Layout/Box/Box";
import PasarelaDePagos from "./components/layout/MedioDePago/PasarelaDePagos";
import Reportes from "./components/Dashboard/Layout/Reportes/Reportes";
import Estadisticas from "./components/Dashboard/Layout/Estadisticas/Estadisticas";
import Espectador from "./components/Dashboard/Layout/Espectador/Index";
import MasterProvider from "./providers/MasterProvider";
import Membresias from "./components/Dashboard/Layout/membresias/Index";
import UsoMembresia from "./components/layout/MedioDePago/UsoMembresia";

//Nueva pagina
import LoginController from "./resource/web/controllers/auth/LoginController";
import StoreProvider from "./resource/providers/StoreProvider";
import { ContextProvider } from "./resource/context/ContextProvider";
import RegisterController from "./resource/web/controllers/auth/RegisterController";
import EventoController from "./resource/web/controllers/eventos/EventoController";
import UsuarioController from "./resource/web/controllers/usuarios/UsuarioController";
import NotaController from "./resource/web/controllers/notas/NotaController";
import EventosController from "./resource/web/controllers/eventos/EventosController";
import NotasController from "./resource/web/controllers/notas/NotasController";
import MembresiasController from "./resource/web/controllers/membresias/MembresiasController";
import BoleteriaController from "./resource/web/controllers/eventos/BoleteriaController";
import InicioController from "./resource/public/controllers/InicioController";
import EntradaController from "./resource/public/controllers/EntradaController";
import PagoController from "./resource/public/controllers/PagoController";
import EntradasController from "./resource/public/controllers/EntradasController";
import SalaController from "./resource/public/controllers/SalaController";
import SalasController from "./resource/public/controllers/SalasController";

function App() {
  //<Route  exact path='/' element={<Maintenance/>} />
  //<Route exact path='/' element={<Body outSession={cerrarSession}><Home /></Body>} />

  const mantenimiento = false;

  return (
    <MasterProvider>
      <AuthProvider>
        <StoreProvider>
          <ContextProvider>
            <Router>
              <EventosState>
                {mantenimiento ? (
                  <Routes>
                    <Route path="/" element={<Maintenance />} />
                    <Route path="/:mantenimiento" element={<Maintenance />} />
                    <Route
                      path="/:mantenimiento/:mantenimiento/:mantenimiento"
                      element={<Maintenance />}
                    />
                  </Routes>
                ) : (
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <Body outSession={cerrarSession}>
                          <Home />
                        </Body>
                      }
                    />
                    <Route
                      exact
                      path="/home"
                      element={
                        <Body outSession={cerrarSession}>
                          <Home />
                        </Body>
                      }
                    />

                    <Route
                      exact
                      path="/inicio"
                      element={
                        <Body outSession={cerrarSession}>
                          <Home />
                        </Body>
                      }
                    />

                    {/* Eventos */}
                    <Route
                      exacto
                      path="/evento/:id"
                      element={
                        <Body outSession={cerrarSession}>
                          <VistaEvento />
                        </Body>
                      }
                    />

                    {/* Sdk Decidir */}

                    <Route
                      path="/pasarela/:info"
                      element={
                        <Body outSession={cerrarSession}>
                          <PasarelaDePagos />
                        </Body>
                      }
                    />

                    <Route
                      path="/pasarela/membresia/:info"
                      element={
                        <Body outSession={cerrarSession}>
                          <UsoMembresia />
                        </Body>
                      }
                    />

                    <Route
                      path="/mediodepago/:info/:deco"
                      element={
                        <Body outSession={cerrarSession}>
                          <MedioDePago />
                        </Body>
                      }
                    />

                    {/* Entradas */}

                    <Route
                      exact
                      path="/entradas/"
                      element={
                        <Body outSession={cerrarSession}>
                          <Entradas />
                        </Body>
                      }
                    />
                    <Route
                      exact
                      path="/entradas/:search"
                      element={
                        <Body outSession={cerrarSession}>
                          <Entradas />
                        </Body>
                      }
                    />
                    <Route
                      exact
                      path="/entradas/:search/:dateRange"
                      element={
                        <Body outSession={cerrarSession}>
                          <Entradas />
                        </Body>
                      }
                    />

                    {/* Alerts */}

                    <Route
                      exacto
                      path="/alert/confirmPago/:info/:res"
                      element={
                        <Body outSession={cerrarSession}>
                          <AcceptPago />
                        </Body>
                      }
                    />
                    <Route
                      exacto
                      path="/alert/errorPago/:error"
                      element={
                        <Body outSession={cerrarSession}>
                          <ErrorCard />
                        </Body>
                      }
                    />
                    <Route
                      exacto
                      path="/alert/confirmRegistro/:info"
                      element={
                        <Body outSession={cerrarSession}>
                          <ConfirmRegistro />
                        </Body>
                      }
                    />
                    <Route
                      exacto
                      path="/alert/restorePassword/:info"
                      element={
                        <Body outSession={cerrarSession}>
                          <RestorePassword />
                        </Body>
                      }
                    />

                    <Route
                      exact
                      path="/salas"
                      element={
                        <Body outSession={cerrarSession}>
                          <Salas />
                        </Body>
                      }
                    />
                    <Route
                      path="/sala/:id"
                      element={
                        <Body outSession={cerrarSession}>
                          <Sala />
                        </Body>
                      }
                    />

                    <Route
                      path="/somos"
                      element={
                        <Body outSession={cerrarSession}>
                          <Somos />
                        </Body>
                      }
                    />

                    <Route
                      path="/typ"
                      element={
                        <Body outSession={cerrarSession}>
                          <TYP />
                        </Body>
                      }
                    />

                    <Route
                      path="/festivales"
                      element={
                        <Body outSession={cerrarSession}>
                          <Fiestas />
                        </Body>
                      }
                    />

                    <Route
                      path="/contenido/notas"
                      element={
                        <Body outSession={cerrarSession}>
                          <Notas />
                        </Body>
                      }
                    />
                    <Route
                      path="/contenido/nota/:id"
                      element={
                        <Body outSession={cerrarSession}>
                          <Nota />
                        </Body>
                      }
                    />

                    <Route
                      path="/contenido/videos"
                      element={
                        <Body outSession={cerrarSession}>
                          <Videos />
                        </Body>
                      }
                    />
                    <Route
                      path="/contenido/video/:id"
                      element={<Body outSession={cerrarSession}></Body>}
                    />

                    <Route
                      exact
                      path="/ingresar"
                      element={
                        <Body outSession={cerrarSession}>
                          <RouteGuest>
                            <AccesLogin />
                          </RouteGuest>
                        </Body>
                      }
                    />
                    <Route
                      exact
                      path="/registro"
                      element={
                        <Body outSession={cerrarSession}>
                          <RouteGuest>
                            <AccesRegister />
                          </RouteGuest>
                        </Body>
                      }
                    />
                    <Route
                      exact
                      path="/cliente/registro"
                      element={
                        <Body outSession={cerrarSession}>
                          <RouteGuest>
                            <UpperRegister />
                          </RouteGuest>
                        </Body>
                      }
                    />

                    <Route
                      path="/restaurar"
                      element={
                        <Body outSession={cerrarSession}>
                          <RouteGuest>
                            <ForgotPassword />
                          </RouteGuest>
                        </Body>
                      }
                    />
                    <Route
                      path="/restaurar/:token"
                      element={
                        <Body outSession={cerrarSession}>
                          <RouteGuest>
                            <RestaurarPassword />
                          </RouteGuest>
                        </Body>
                      }
                    />

                    {/* Back Office Espectador */}

                    <Route
                      exact
                      path="/dashboard/espectador"
                      element={
                        <Body outSession={cerrarSession}>
                          <Espectador />
                        </Body>
                      }
                    />

                    {/* Back Office */}

                    <Route
                      exact
                      path="/dashboard/micuenta"
                      element={
                        <Dashboard>
                          <MiCuenta />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/eventos"
                      element={
                        <Dashboard>
                          <Eventos />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/eventos/crear"
                      element={
                        <Dashboard>
                          <FormEvento />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/eventos/editar/:id"
                      element={
                        <Dashboard>
                          <FormEvento />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/facturacion"
                      element={
                        <Dashboard>
                          <Facturacion />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/facturacion/:idEvento"
                      element={
                        <Dashboard>
                          <Facturacion />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/miscompras"
                      element={
                        <Dashboard>
                          <MisCompras />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/salas"
                      element={
                        <Dashboard>
                          <ControlSalas />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/productores"
                      element={
                        <Dashboard>
                          <ControlProductores />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/espectadores"
                      element={
                        <Dashboard>
                          <ControlUsuarios />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/cupones"
                      element={
                        <Dashboard>
                          <Cupones />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/videos"
                      element={
                        <Dashboard>
                          <ControlVideos />
                        </Dashboard>
                      }
                    />

                    <Route
                      exact
                      path="/dashboard/configuracion"
                      element={
                        <Dashboard>
                          <Configuracion />
                        </Dashboard>
                      }
                    />

                    {/* Back Office Reportes */}

                    <Route
                      exact
                      path="/dashboard/cuentas"
                      element={
                        <Dashboard>
                          <Reportes />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/reportes"
                      element={
                        <Dashboard>
                          <Estadisticas />
                        </Dashboard>
                      }
                    />

                    {/* <Route
                      exact
                      path="/app/login"
                      element={
                        <Apk>
                          <Login />
                        </Apk>
                      }
                    />
                    <Route
                      exact
                      path="/app/box"
                      element={
                        <Apk>
                          <Box />
                        </Apk>
                      }
                    />
                    */}

                    {/* WEB PUBLICA NUEVA */}

                    <Route
                      exact
                      path="/v/inicio"
                      element={<InicioController />}
                    />

                    <Route
                      exact
                      path="/v/entradas"
                      element={<EntradasController />}
                    />

                    <Route
                      exact
                      path="/v/entrada/:name"
                      element={<EntradaController />}
                    />

                    <Route
                      exact
                      path="/v/checkout"
                      element={<PagoController />}
                    />

                    <Route
                      exact
                      path="/v/salas"
                      element={<SalasController />}
                    />

                    <Route
                      exact
                      path="/v/sala/:id"
                      element={<SalaController />}
                    />

                    {/* ADMINISTRADOR NUEVO */}

                    {/* AUTH */}

                    <Route
                      exact
                      path="/v/login"
                      element={<LoginController />}
                    />

                    <Route
                      exact
                      path="/v/registro/:tipo"
                      element={<RegisterController />}
                    />

                    {/* USUARIOS */}

                    <Route
                      exact
                      path="/v/perfil"
                      element={<UsuarioController />}
                    />
                    <Route exact path="/v/configuracion" element={""} />
                    <Route exact path="/v/usuarios/:tipo" element={""} />

                    {/* EVENTOS */}

                    <Route
                      exact
                      path="/v/evento/:id"
                      element={<EventoController />}
                    />
                    <Route
                      exact
                      path="/v/eventos"
                      element={<EventosController />}
                    />
                    <Route exact path="/v/reportes" element={""} />
                    <Route
                      exact
                      path="/dashboard/boleteria/:id"
                      element={
                        <Dashboard>
                          <BoleteriaController />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/boleteria/:id/:shared_token"
                      element={<BoleteriaController />}
                    />

                    {/* NOTAS */}

                    <Route
                      exact
                      path="/dashboard/nota/:id"
                      element={
                        <Dashboard>
                          <NotaController />
                        </Dashboard>
                      }
                    />
                    <Route
                      exact
                      path="/dashboard/notas"
                      element={
                        <Dashboard>
                          <NotasController />
                        </Dashboard>
                      }
                    />

                    {/* MEMBRESIAS */}

                    <Route
                      exact
                      path="/dashboard/membresias"
                      element={
                        <Dashboard>
                          <MembresiasController />
                        </Dashboard>
                      }
                    />
                  </Routes>
                )}
              </EventosState>
            </Router>
          </ContextProvider>
        </StoreProvider>
      </AuthProvider>
    </MasterProvider>
  );
}

export default App;
