import { useEffect, useState } from "react";
import { totalReportEvents } from "../../../../../Functions/FunctionReportes";
import useApi from "../../../../../hooks/useApi";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ConcatDate } from "../../../../../App/Functions/ConcatDate";
import { Chart } from "react-google-charts";
import {
  ConcatLimitCash,
  ConcatReportMembership,
} from "../../../../../helpers/ConcatLimitCatsh";
import { cycle_active } from "../../../../utils/MembresiaUtils";
import { GetApi } from "../../../../helpers/RestApi";
import clientAxios from "../../../../../helpers/Config";
import clienteAntesala from "../../../../config/Config";
import RoadSpinner from "../../../components/loads/RoadSpinner";

const MembresiaUsos = ({ membership, setModal }) => {
  const [reportes, setReportes] = useState(null);
  const [loading, setLoading] = useState(false);

  //FILTER

  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState();


  //BUTTON EVENTS

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "nombre_cliente":
        {
          if(e.target.value.length > 3){
            
            let params = {
              membresia_id: membership.id,
              search: e.target.value
            }

            const res = await clientAxios.get("api/membresias/reportes", {
              params,
            });

            if(res.data.length == 0){
              return setReportes(null);
            }

            setReportes(res.data)
          }

          if(e.target.value.trim() == ""){
            
            let params = {
              membresia_id: membership.id,
            }

            const res = await clientAxios.get("api/membresias/reportes", {
              params,
            });

            if(res.data.length == 0){
              return setReportes(null);
            }

            setReportes(res.data)
          }
        }
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
    }
  };

  //hooks
  //const { data, message, isLoading } = useApi(params.url, params, steps);
  //const reportes = data ? (data.length > 0 ? data : null) : null;

  //const diffdata = {
  // old: dataOld,
  // new: dataNew,
  //};


  useEffect(() => {
    const fetch = async () => {

      let params = {
        membresia_id: membership.id
      }

      const res = await clientAxios.get("api/membresias/reportes", {
        params,
      });
      setReportes(res.data)
    }

    fetch()
  }, []);
  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "3000px", zIndex: "106" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Reportes - CICLO {cycle_active(membership.cycle)} </h1>
              <span class="fs-6 text-muted">
                Reportes de membresia
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div
              className="container-md texto-normal"
              style={{ maxWidth: "3000px" }}
            >
              <div className="row">
                <div class="col-md-3 col-12 mb-3">
                  <span class="text-muted">
                    <h2>{reportes ? reportes[0].membership_code : ""}</h2>
                  </span>
                </div>

                <div className="col-md-2 mb-3">
                  <div
                    className="bg-white p-3 text-center"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="d-block fw-bold text-muted">
                      Total de ventas + Servicio
                    </div>
                    <div>
                      $ {totalReportEvents(reportes ? reportes : []).total_serv}
                    </div>
                  </div>
                </div>

                <div className="col-md-2 mb-3">
                  <div
                    className="bg-white p-3 text-center"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="d-block fw-bold text-muted">
                      Total de entradas
                    </div>
                    <div>
                      {" "}
                      {
                        totalReportEvents(reportes ? reportes : [])
                          .total_cantidad
                      }
                    </div>
                  </div>
                </div>

                <div className="col-md-12"></div>

                <div className="col-md-3 col-12">
                  <ReactHTMLTableToExcel
                    id="buttonDownloadExcel"
                    className="text-dark btn btn-outline-success rounded-pill w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
                    table="boleteriaVentas"
                    filename="reporteDeVentas"
                    sheet="pagina 1"
                    buttonText="Exportar tabla actual"
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                      placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="nombre_cliente"
                      onChange={(event) => onChange(event)}
                    />
                    <button
                      class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                      id="basic-addon2"
                    >
                      <i class="bi bi-search"></i>
                    </button>
                  </div>
                </div>

                <div
                  className="table-responsive bg-white my-3"
                  style={{ borderRadius: "10px" }}
                >
                  <table
                    className="table table-hover align-middle border-0"
                    id="boleteriaVentas"
                  >
                    <thead className="border-0">
                      <tr>
                        <th className="border-0 text-nowrap">Id</th>
                        <th className="border-0 text-nowrap">
                          Fecha de compra
                        </th>
                        <th className="border-0 text-nowrap">Evento</th>

                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Nombre cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Dni cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Telefono cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Email cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Codigo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Tipo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Fecha de
                          entrada
                        </th>

                        <th className="border-0 text-nowrap">Monto Unidad</th>
                        <th className="border-0 text-nowrap">Cant</th>
                        <th className="border-0 text-nowrap">Total</th>
                        <th className="border-0 text-nowrap">Total + Serv</th>

                        <th className="border-0">Estado</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {
                      reportes
                        ? reportes.map((reporte, index) => (
                          <tr>
                            <td className="border-0">{reporte.id}</td>
                            <td className="border-0">
                              {ConcatDate(reporte.created_at, "created_at")}
                            </td>
                            <td className="border-0 text-dark">
                              {reporte.eventos.nombre}
                            </td>
                            <td className="border-0">
                              <div className="fw-bold fs-7">
                                <span className="fw-normal text-dark">
                                  {reporte.nombre_cliente}
                                </span>
                              </div>
                            </td>
                            <td className="border-0">
                              <div className="fw-bold fs-7">
                                <span className="fw-normal text-dark">
                                  {reporte.dni_cliente}
                                </span>
                              </div>
                            </td>
                            <td className="border-0">
                              <div className="fw-bold fs-7">
                                <span className="fw-normal text-dark">
                                  {reporte.telefono_cliente
                                    ? reporte.telefono_cliente
                                    : reporte.usuarios
                                      ? reporte.usuarios.telefono
                                      : ""}
                                </span>
                              </div>
                            </td>
                            <td className="border-0 text-dark">
                              {reporte.email_cliente
                                ? reporte.email_cliente
                                : reporte.usuarios
                                  ? reporte.usuarios.email
                                  : ""}
                            </td>
                            <td className="border-0">{reporte.codigo}</td>
                            <td className="border-0">
                              {reporte.entradas
                                ? reporte.entradas.nombre
                                : ""}
                            </td>
                            <td className="border-0">{reporte.fecha}</td>
                            <td className="border-0 text-nowrap">
                              $ {reporte.unidad}
                            </td>
                            <td className="border-0">{reporte.cantidad}</td>
                            <td className="border-0 text-nowrap">
                              $ {reporte.total}
                            </td>

                            <td className="border-0 text-nowrap">
                              ${" "}
                              {reporte.total > 500
                                ? parseFloat(reporte.total) +
                                (reporte.total / 100) * reporte.comision
                                : parseFloat(reporte.total) + 75}
                            </td>

                            <td className="border-0">
                              {reporte.status == "ACTIVO" ? (
                                <i class="text-success fs-4 bi bi-check-circle-fill"></i>
                              ) : reporte.status == "INACTIVO" ? (
                                <i class="text-danger bi bi-x-circle-fill"></i>
                              ) : reporte.status == "REEMBOLSO" ? (
                                <i class="text-warning bi bi-exclamation-diamond-fill"></i>
                              ) : reporte.status == "RECHAZADO" ? (
                                <i class="text-danger bi bi-exclamation-circle-fill"></i>
                              ) : reporte.status == "LIQUIDADO" ? (
                                <i class="text-success fa-solid fa-hand-holding-dollar"></i>
                              ) : reporte.status == "PAUSADO" ? (
                                <i class="text-secondary bi bi-slash-circle-fill"></i>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                        : <div className="dlfex justify-content-center">
                          <RoadSpinner/> 
                        </div>
                      }
                      <tr className="bg-secondary bg-opacity-50">
                        <td className="border-0" colSpan={11}>
                          Totales
                        </td>
                        <td className="border-0" colSpan={1}>
                          {
                            totalReportEvents(reportes ? reportes : [])
                              .total_cantidad
                          }
                        </td>
                        <td className="border-0">
                          $
                          {
                            totalReportEvents(reportes ? reportes : [])
                              .total_bruto
                          }
                        </td>
                        <td className="border-0">
                          $
                          {
                            totalReportEvents(reportes ? reportes : [])
                              .total_serv
                          }
                        </td>
                        <td className="border-0"></td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close"
                  onFocus={(event) => onClick(event, null)}
                >
                  cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembresiaUsos;
