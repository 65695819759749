import { createSlice } from "@reduxjs/toolkit";
import clienteAntesala from "../../../config/Config";

const initialState = {
  search: [],
};

export const salaSlice = createSlice({
  name: "salas",
  initialState: initialState,
  reducers: {
    setSearchSala: (state, action) => {
      state.search = action.payload
    }
  },
});

//Async functions
export const fetchSearchSala = (params) => (dispatch) => {
  dispatch(setSearchSala(null))
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setSearchSala(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setSearchSala } = salaSlice.actions;

export const salaState = (state) => state.salas;

export default salaSlice.reducer;
