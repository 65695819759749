import { useEffect } from "react"
import Eventos from "../../page/eventos/Eventos"

const EventosController = () => {


  const onClick = (e, value) => {
    switch (e.target.name) {
      case "":{
        
      }break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "":{

      }break;
    }
  }

  useEffect(() => {

  },[])
  return (
    <Eventos 
    />
  )
}

export default EventosController