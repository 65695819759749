import { createSlice } from "@reduxjs/toolkit";
import clienteAntesala from "../../../config/Config";

const initialState = {
  provincias: null,
};

export const provinciaSlice = createSlice({
  name: "provincias",
  initialState: initialState,
  reducers: {
    setProvincias: (state, action) => {
      state.provincias = action.payload
    }
  },
});

//Async functions
export const fetchProvincias = (params) => (dispatch) => {
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setProvincias(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setProvincias } = provinciaSlice.actions;

export const provinciaState = (state) => state.provincias;

export default provinciaSlice.reducer;
