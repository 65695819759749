import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { CollectionStateContext } from "../../../context/EventosState";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useApi from "../../../hooks/useApi";
import { ConcatDate } from "../../../App/Functions/ConcatDate";
import { totalReportEvents } from "../../../Functions/FunctionReportes";

const ModalReportCupon = ({ onClick, cupon }) => {
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);

  

  //FILTER

  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState({
    id_evento: "",
    estado: "ACTIVO",
    search: "",
    sala: "",
    fecha_entrada: "",
    date_range: [],
    provincia: "",
    cupon: cupon.nombre,
    //static
    url: "api/auth/Reportes/All",
    pagination: cupon.limite,
  });

  
  //BUTTON EVENTS

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "nombre_cliente":
        {
          if (
            e.target.value.length < initialStateFilter.search.length
          ) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });

              setSteps(steps+1);
            }
          }
        }
        break;
    }
  };

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);
  const reportes = data? data.length > 0 ? data : null : null; 
  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "3000px", zIndex: "106" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Reportes </h1>
              <span class="fs-6 text-muted">
                Visualiza cuantas veces se uso tu cúpon.
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div
              className="container-md texto-normal"
              style={{ maxWidth: "3000px" }}
            >
              <div className="row">
                <div class="col-md-3 col-12 mb-3">
                  <span class="text-muted">
                    <h2>{reportes? reportes[0].codigo_descuento: ""}</h2>
                  </span>
                </div>

                
            <div className="col-md-2 mb-3">
              <div className="bg-white p-3 text-center" style={{borderRadius: "20px"}}>
                  <div className="d-block fw-bold text-muted">Total de ventas</div>
                  <div>$ {totalReportEvents(reportes? reportes : []).total_pagos}</div>
              </div>
            </div>

            <div className="col-md-2 mb-3"> 
            <div className="bg-white p-3 text-center" style={{borderRadius: "20px"}}>
                  <div className="d-block fw-bold text-muted">Total de entradas</div>
                  <div> {totalReportEvents(reportes? reportes : []).total_cantidad}</div>
              </div>
            </div>

            
            <div className="col-md-2 mb-3"> 
            <div className="bg-white p-3 text-center" style={{borderRadius: "20px"}}>
                  <div className="d-block fw-bold text-muted">Total de descuento</div>
                  <div> ${totalReportEvents(reportes? reportes : []).total_descuento}</div>
              </div>
            </div>

            <div className="col-md-2 mb-3"> 
            <div className="bg-white p-3 text-center" style={{borderRadius: "20px"}}>
                  <div className="d-block fw-bold text-muted">Usos del cupon</div>
                  <div> {totalReportEvents(reportes? reportes : []).use_cupon}</div>
              </div>
            </div>

                <div className="col-md-3 col-12">
                  <ReactHTMLTableToExcel
                    id="buttonDownloadExcel"
                    className="btn btn-outline-success rounded-pill w-100 rounded-pill fs-7 p-0 m-0 px-2 py-1"
                    table="boleteriaVentas"
                    filename="reporteDeVentas"
                    sheet="pagina 1"
                    buttonText="Exportar tabla actual"
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                      placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="nombre_cliente"
                      onChange={(event) => onChange(event)}
                    />
                    <button
                      class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                      id="basic-addon2"
                    >
                      <i class="bi bi-search"></i>
                    </button>
                  </div>
                </div>

                <div
                  className="table-responsive bg-white my-3"
                  style={{ borderRadius: "10px" }}
                >
                  <table
                    className="table table-hover align-middle border-0"
                    id="boleteriaVentas"
                  >
                    <thead className="border-0">
                      <tr>
                        <th className="border-0 text-nowrap">Id</th>
                        <th className="border-0 text-nowrap">
                          Fecha de compra
                        </th>
                        <th className="border-0 text-nowrap">Evento</th>

                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Nombre cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Dni cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Telefono cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Email cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Codigo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Tipo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Fecha de
                          entrada
                        </th>

                        <th className="border-0 text-nowrap">Monto Unidad</th>
                        <th className="border-0 text-nowrap">Cant</th>
                        <th className="border-0 text-nowrap">total</th>
                        <th className="border-0 text-nowrap">Descuento</th>
                        <th className="border-0 text-nowrap">
                          Total con descuento
                        </th>
                        <th className="border-0 text-nowrap">
                          Codigo descuento
                        </th>

                        <th className="border-0">Estado</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      data
                      {reportes
                        ? reportes.map((reporte, index) => (
                            <tr>
                              <td className="border-0">{reporte.id}</td>
                              <td className="border-0">
                                {ConcatDate(reporte.created_at, "created_at")}
                              </td>
                              <td className="border-0">
                                {reporte.eventos.nombre}
                              </td>
                              <td className="border-0">
                                <div className="fw-bold fs-7">
                                  <span className="fw-normal">
                                    {reporte.nombre_cliente}
                                  </span>
                                </div>
                              </td>
                              <td className="border-0">
                                <div className="fw-bold fs-7">
                                  <span className="fw-normal">
                                    {reporte.dni_cliente}
                                  </span>
                                </div>
                              </td>
                              <td className="border-0">
                                <div className="fw-bold fs-7">
                                  <span className="fw-normal">
                                    {reporte.telefono_cliente
                                      ? reporte.telefono_cliente
                                      : reporte.usuarios
                                      ? reporte.usuarios.telefono
                                      : ""}
                                  </span>
                                </div>
                              </td>
                              <td className="border-0">
                                {reporte.email_cliente
                                  ? reporte.email_cliente
                                  : reporte.usuarios
                                  ? reporte.usuarios.email
                                  : ""}
                              </td>
                              <td className="border-0">{reporte.codigo}</td>
                              <td className="border-0">
                                {reporte.entradas
                                  ? reporte.entradas.nombre
                                  : ""}
                              </td>
                              <td className="border-0">{reporte.fecha}</td>
                              <td className="border-0 text-nowrap">
                                $ {reporte.unidad}
                              </td>
                              <td className="border-0">{reporte.cantidad}</td>
                              <td className="border-0 text-nowrap">
                                $ {reporte.total}
                              </td>
                              <td className="border-0 text-nowrap">
                                {reporte.descuento ? (
                                  <div>
                                    ${" "}
                                    {((reporte.unidad * reporte.cantidad) /
                                      100) *
                                      reporte.descuento}
                                  </div>
                                ) : (
                                  "Sin descuento"
                                )}
                              </td>
                              <td className="border-0">
                                {reporte.descuento ? (
                                  <div>
                                    ${" "}
                                    {reporte.unidad * reporte.cantidad -
                                      ((reporte.unidad * reporte.cantidad) /
                                        100) *
                                        reporte.descuento}
                                  </div>
                                ) : (
                                  "Sin descuento"
                                )}
                              </td>
                              <td className="border-0">
                                {reporte.descuento ? (
                                  <div>{reporte.codigo_descuento}</div>
                                ) : (
                                  ""
                                )}
                              </td>

                              <td className="border-0">
                                {reporte.status == "ACTIVO" ? (
                                  <i class="text-success fs-4 bi bi-check-circle-fill"></i>
                                ) : reporte.status == "INACTIVO" ? (
                                  <i class="text-danger bi bi-x-circle-fill"></i>
                                ) : reporte.status == "REEMBOLSO" ? (
                                  <i class="text-warning bi bi-exclamation-diamond-fill"></i>
                                ) : reporte.status == "RECHAZADO" ? (
                                  <i class="text-danger bi bi-exclamation-circle-fill"></i>
                                ) : reporte.status == "LIQUIDADO" ? (
                                  <i class="text-success fa-solid fa-hand-holding-dollar"></i>
                                ) : reporte.status == "PAUSADO" ? (
                                  <i class="text-secondary bi bi-slash-circle-fill"></i>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))
                        : ""}
                      <tr className="bg-secondary bg-opacity-50">
                        <td className="border-0" colSpan={11}>
                          Totales
                        </td>
                        <td className="border-0" colSpan={1}>
                          {totalReportEvents(reportes? reportes: []).total_cantidad}
                        </td>
                        <td className="border-0">
                          {totalReportEvents(reportes? reportes: []).total_bruto}
                        </td>
                        <td className="border-0">
                          {totalReportEvents(reportes? reportes: []).total_descuento}
                        </td>
                        <td className="border-0">
                          {totalReportEvents(reportes? reportes: []).total_pagos}
                        </td>
                        <td className="border-0">
                          {totalReportEvents(reportes? reportes: []).use_cupon}
                        </td>
                        <td className="border-0"></td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close_modal"
                  onFocus={(event) => onClick(event, null)}
                >
                  cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReportCupon;
