import { createContext } from "react";
import { FORMs } from "../const/Forms";
import { VALIDATIONs } from "../const/Validations";
import { URLs } from "../const/urls";

export const contextProvider = createContext();



export const ContextProvider = ({children}) => {


  return (
    <contextProvider.Provider value={{
      FORMs: FORMs,
      VALIDATIONs: VALIDATIONs,
      URLs: URLs,
    }}>
      {children}
    </contextProvider.Provider>
  )
}