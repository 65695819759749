import { useContext, useEffect, useReducer, useState } from "react";
import { CollectionStateContext } from "../../../../../context/EventosState";
import EventosItem from "./EventosItem";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useParams } from "react-router-dom";
import Filtro from "../Filtros/Filtro";
import { obtenerMunicipios } from "../../../../../helpers/NormGeograficoHelper";
import useApi from "../../../../../hooks/useApi";
//Date format
import { format, add, sub } from "date-fns";
import { GetPublicApi } from "../../../../../resource/helpers/PublicApi";

const Eventos = () => {
  const context = useContext(CollectionStateContext);
  const {
    obtenerCategorias,
    //obtenerApiProvincias,
    provincias,
    //geolocationApi,
    salas,
    //eventos,
    state,
  } = context;

  //
  //const [steps, setSteps] = useState(0);
  const [timeout, setTimeoutId] = useState(0);
  const [loadingTimeout, setLoadingTimeout] = useState(true);
  //Params
  const { search, dateRange } = useParams();
  //Local Storage date
  const geolocation = localStorage.getItem("geolocation");

  //position
  const [scroll_pagination, setScroll_pagination] = useState(0);
  //const [dataEventos, setEventos] = useState(null);
  const [eventos, setEventos] = useState(null);
  const [loading, setLoading] = useState(false);
  //Recharger Provincias
  const lSProvincias = localStorage.getItem("provincias");

  //const [dataEventos, setEventos] = useState(eventos);
  const [dataProvincias, setProvincias] = useState(
    lSProvincias ? JSON.parse(lSProvincias) : state.provincias
  );
  const [dataCategorias, setCategorias] = useState(state.categorias);
  const [dataLocalidad, setLocalidad] = useState({
    class: "d-none",
    content: null,
  });
  const [dataSalas, setSalas] = useState(state.salas);

  const [fecha, onFecha] = useState([
    {
      startDate: dateRange
        ? new Date()
        : new Date(),
      endDate: null,
      key: "selection",
    },
  ]);


  //paramentros
  const [params, setParams] = useState({
    id: "",
    search: search && search !== "NONE" && search !== "OFERTAS" ? search : "",
    sala: "",
    status: 1,
    date_range: dateRange ? JSON.parse(atob(dateRange)) : [
      format(new Date(), "yyyy/MM/dd").toString(),
      format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
    ],
    provincia: geolocation ? geolocation : "",
    scroll_pagination: scroll_pagination,
    offer: search && search !== "NONE" ? (search == "OFERTAS" ? "OFFERTAS" : "") : "",
    categoria: [],
    //static
    url: "Eventos/All",
    pagination: 50,
  });

  //end

  const [params_sala, setParamsSala] = useState({
    id: "",
    search: "",
    provincia: geolocation ? geolocation : "",
    localidad: "",
    filtro: "true",
    scroll_pagination: 0
  });

  const [params_categorias, setParamsCategorias] = useState({
    nombre: "",
    fecha: "",
    url: "api/ObtenerCategorias",
  });

  //

  const onClick = (e) => {
    switch (e.target.name) {
      case "ver_mas":
        {
          if (params.scroll_pagination < eventos.total) {
            setParams({
              ...params,
              scroll_pagination: scroll_pagination + 50,
            });
            setScroll_pagination(scroll_pagination + 50)
            let param = {
              ...params,
              scroll_pagination: scroll_pagination + 50,
            }
            fetch(param);
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "provincia":
        {
          let param = params
          if (e.target.value == "ALL") {
            setParams({
              ...params,
              [e.target.name]: "",
            });
            let param = {
              ...params,
              [e.target.name]: "",
            }
          } else {
            setParams({
              ...params,
              [e.target.name]: e.target.value,
            });
            let param = {
              ...params,
              [e.target.name]: e.target.value,
            }
            setParamsSala({
              ...params_sala,
              provincia: e.target.value,
            });
          }

          fetch(param)
        }
        break;
      case "Selectlocalidad":
        {
          let param = {
            ...params,
            localidad: e.target.value,
          }

          setParams({
            ...params,
            localidad: e.target.value,
          });

          setLocalidad({
            class: "d-none",
            content: null,
          });

          fetch(param)
        }
        break;
      case "SearchLocalidad":
        {
          let param = {
            ...params,
            localidad: e.target.value,
          }
          setParams({
            ...params,
            localidad: e.target.value,
          });

          const axios = async () => {
            let result = await obtenerMunicipios(
              params.provincia,
              e.target.value
            );
            setLocalidad({
              class: "form-control",
              content: result,
            });
          };
          axios();
          fetch(param)
        }
        break;
      case "espectaculo":
        {
          let param = params
          if (e.target.checked) {
            setParams({
              ...params,
              [e.target.name]: e.target.value,
            });
            param = {
              ...param,
              [e.target.name]: e.target.value,
            }
          } else {
            setParams({
              ...params,
              [e.target.name]: "",
            });
            param = {
              ...param,
              [e.target.name]: "",
            }
          }
          fetch(param)
        }
        break;
      case "publico":
      case "search":
        {
          let param = params
          if (e.target.value == "ALL") {
            setParams({
              ...params,
              [e.target.name]: "",
            });
            param = {
              ...param,
              [e.target.name]: "",
            }
          } else {
            setParams({
              ...params,
              [e.target.name]: e.target.value,
            });
            param = {
              ...param,
              [e.target.name]: e.target.value,
            }
          }
          fetch(param)
        }
        break;
      case "atp":
        {
          let param = params
          if (e.target.checked) {
            setParams({
              ...params,
              [e.target.name]: e.target.value,
            });
            param = {
              ...param,
              [e.target.name]: e.target.value,
            }
          } else {
            setParams({
              ...params,
              [e.target.name]: "",
            });
            param = {
              ...param,
              [e.target.name]: "",
            }
          }

          fetch(param)
        }
        break;
      case "categoria":
        {
          let array = params.categoria;
          let param = params;

          if (array.length == 0) {
            array.push(e.target.value);
            setParams({
              ...params,
              [e.target.name]: array,
            });

            param = {
              ...param,
              [e.target.name]: array,
            }
          } else {
            if (e.target.checked) {
              array.push(e.target.value);
              setParams({
                ...params,
                [e.target.name]: array,
              });
              param = {
                ...param,
                [e.target.name]: array,
              }
            } else {
              let newArray = array.filter(function (f) {
                return f !== e.target.value;
              });
              setParams({
                ...params,
                [e.target.name]: newArray,
              });
              param = {
                ...param,
                [e.target.name]: newArray,
              }
            }
          }


          fetch(param)
        }
        break;
    }
  };

  /*
  const orderToparams = (e) => {
    let order = dataEventos;

    switch (e.target.value) {
      case "a<b":
        {
          order.data = dataEventos.data.sort((a, b) => {
            if (Number(a.entradas[0].precio) == Number(b.entradas[0].precio)) {
              return 0;
            }
            if (Number(a.entradas[0].precio) < Number(b.entradas[0].precio)) {
              return -1;
            }
            return 1;
          });
        }
        break;
      case "a>b":
        {
          order.data = dataEventos.data.sort((a, b) => {
            if (Number(a.entradas[0].precio) == Number(b.entradas[0].precio)) {
              return 0;
            }
            if (Number(a.entradas[0].precio) > Number(b.entradas[0].precio)) {
              return -1;
            }
            return 1;
          });
        }
        break;
      case "all":
        {
          order.data = dataEventos.data.sort((a, b) => {
            if (Number(a.id) == Number(b.id)) {
              return 0;
            }
            if (Number(a.id) > Number(b.id)) {
              return -1;
            }
            return 1;
          });
        }
        break;
    }

    setEventos(order);
    //Arregla bugs de recarga de components
    setRecharge(recharge + 1);
    //
  };
  */

  function isScrolling() {
    var y = getOffset(document.getElementById("paginationAdd")).top;
    if (
      window.innerHeight + document.documentElement.scrollTop > parseInt(y) && !loading && eventos.total > params.scroll_pagination
    ) {
      setParams({
        ...params,
        scroll_pagination: params.scroll_pagination + 50
      })
      let param = {
        ...params,
        scroll_pagination: params.scroll_pagination + 50
      }
      fetch(param)
    }
  }

  function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  //hooks
  //const { data, message, isLoading } = useApi(params.url, params, steps); 
  useEffect(() => {
    if (dataSalas.length == 0) {
      salas(
        setSalas,
        params_sala.id,
        params_sala.search,
        params_sala.provincia,
        params_sala.localidad,
        params_sala.filtro
      );
    }
    if (!dataCategorias) {
      obtenerCategorias(setCategorias, params_categorias);
    }

    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, [params.scroll_pagination, loading, scroll_pagination, eventos]);

  const fetch = async (params) => {
    const response = await GetPublicApi({
      url: params.url,
      params: params,
      setLoading,
    })
    if (response.status == 200 || response.status == 201) {
      setEventos(response.data);
    }
  }
  useEffect(() => {
    let params = {
      search: search && search !== "NONE" && search !== "OFERTAS" ? search : "",
      status: 1,
      date_range: dateRange ? JSON.parse(atob(dateRange)) : [
        format(new Date(), "yyyy/MM/dd").toString(),
        format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
      ],
      provincia: geolocation ? geolocation : "",
      scroll_pagination: scroll_pagination,
      offer: search && search !== "NONE" ? (search == "OFERTAS" ? "OFFERTAS" : "") : "",
      categoria: [],
      //static
      url: "Eventos/All",
      pagination: 50,
    }
    if (!eventos) {
      fetch(params);
    }
  }, [])
  return (
    <section id="eventos" class="mt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-md-3 d-none d-md-block">
            <Filtro
              onChange={onChange}
              categorias={dataCategorias}
              provincias={dataProvincias}
              salas={dataSalas}
              params={params}
              dataLocalidad={dataLocalidad}
              fecha={fecha}
              onFecha={onFecha}
              setParams={setParams}
            />
          </div>

          <div className="col-md-3 d-md-none">
            <button
              class="btn btn-custom-naranja text-white w-100 mb-3"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              Filtro
            </button>

            <div
              class="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                <button
                  type="button"
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <Filtro
                  onChange={onChange}
                  categorias={dataCategorias}
                  provincias={dataProvincias}
                  salas={dataSalas}
                  dateRange={dateRange}
                  params={params}
                  dataLocalidad={dataLocalidad}
                  setParams={setParams}
                  fecha={fecha}
                  onFecha={onFecha}
                  geolocation={geolocation}
                />
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="row">
              <div class="col-12 d-flex justify-content-end">
                <div class="d-flex align-items-center me-4 wow bounceInUp">
                  <p class="mb-0 me-2 text-nowrap">Ordenar Por</p>
                  <select
                    class="form-select form-select form-select-sm"
                    onChange={""/*(event) => */}
                  >
                    <option value="all" selected>
                      Recientes
                    </option>
                    <option value="a>b">Mayor precio</option>
                    <option value="a<b">Menor precio</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <hr class="wow bounceInUp" />
              </div>

              {!eventos
                ? <div className="row g-2">
                  {
                    ["","","","","","","","","","","","","","","","","","","",""].map(() => (
                     <div className="col-md-4 col-12 p-2">
                       <div className="placeholder-home-events d-flex justify-content-center align-items-center" style={{ height: "350px" }}>
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <span className="text-dark ms-1">Cargando evento</span>
                      </div>
                     </div>
                    ))
                  }
                </div>
                : eventos.data.length !== 0
                  ? eventos.data.map((item) => (
                    <EventosItem key={item.id} eventos={item} />
                  ))
                  : ""}

              <div class="d-grid gap-2 wow bounceInUp">
                <button
                  id="paginationAdd"
                  className="btn btn-custom-amarillo"
                  name="VERMAS"
                  onClick={(event) => onClick(event)}
                >
                  ver más +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Eventos;
