import { useState } from "react";
import Input from "../../../components/custom/forms/Input";
import Papa from "papaparse";
import RestApi from "../../../../helpers/RestApi";
import Alerts from "../../../components/Alerts/Alerts";

const MembresiaUsersModal = ({ setModal, membresias }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    file: ["", "required"],
    id: ["", "required"],

    resetear: [false, "required"],
  });

  const allowedExtensions = ["csv"];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "enviar":
        {
          const input_file = form.file[0];
          const fileExtension = input_file?.type.split("/")[1];
          if (!allowedExtensions.includes(fileExtension)) {
            console.log("Ingrese un Archivo CSV");
            return;
          }

          if (!input_file) return console.log("Ingrese un Archivo Valido");

          const reader = new FileReader();

          reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;

            fetch(parsedData);
          };

          reader.readAsText(input_file);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "file":
        {
          let input = form;
          input.file[0] = e.target.files[0];

          setForm({
            ...form,
            file: input.file,
          });
        }
        break;
      case "id":
        {
          let input = form;
          input.id[0] = e.target.value;

          setForm({
            ...form,
            id: input.id,
          });
        }
        break;
    }
  };

  const fetch = async (file) => {
    let formData = new FormData();

    file.map((d, index) => {
      formData.append("users[" + index + "]", d.mails);
    });
    formData.append("membresia_id", form.id[0]);
    //formData.append("resetear", 1);

    const response = await RestApi({
      url: "/users/files",
      formData: formData,
      method: "post",
      setLoading: setLoading,
    });

    //Error
    if (response.status == 201 || response.status == 200) {
      return Alerts({
        msg: "Alert",
        title: response.data.msg,
        code: "401",
        type: "success",
      });
    } else {
      if (typeof response.data.msg === "string") {
        Alerts({
          msg: "Alert",
          title: response.data.msg,
          code: "200",
          type: "success",
        });
      }

      Object.keys(response.data.msg).map((key) => {
        return Alerts({
          msg: "Alert",
          title: response.data.msg[key][0],
          code: "401",
          type: "error",
        });
      });
    }
  };

  console.log(form);
  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h2>Membresia / Usuarios</h2>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    onChange={onChange}
                    label={"Archivo"}
                    name={"file"}
                    type={"file"}
                    value={""}
                    svg={""}
                    validate={{ msg: "", status: false }}
                    disabled={false}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    className="form-label text-muted mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Selecciona una membresia
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="id"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected></option>
                    {membresias
                      ? membresias.map((m) => {
                          return <option value={m.id}>{m.name}</option>;
                        })
                      : ""}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close"
                  onFocus={(event) => onClick(event)}
                >
                  Cancelar
                </button>
                <button
                  name="enviar"
                  onClick={(event) => onClick(event)}
                  className="ms-2 btn btn-custom-amarillo text-white"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembresiaUsersModal;
