import { createSlice } from "@reduxjs/toolkit";
import clienteAntesala from "../../resource/config/Config";

const initialState = {
  categorias: null,
};

export const categoriaSlice = createSlice({
  name: "categorias",
  initialState: initialState,
  reducers: {
    setCategorias: (state, action) => {
      state.categorias = action.payload
    }
  },
});

//Async functions
export const fetchCategoria = (params) => (dispatch) => {
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setCategorias(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setCategorias } = categoriaSlice.actions;

export const categoriaState = (state) => state.categorias;

export default categoriaSlice.reducer;
