import { invertDate } from "../../../../../Functions/ConvertDate";
import { totalReportCuenta } from "../../../../../Functions/FunctionInformes";
import {
  textVentas,
  totalReportEvents,
} from "../../../../../Functions/FunctionReportes";
import { convertMonth } from "../../../../../Functions/FunctionTickets";

const TablaEventos = ({ data, total, filtro, rol, onClick, onChange }) => {
  //Argentores
  let argentores = 0;

  const zero = () => {
    argentores = 0;
  };

  //LOGS
  //console.log(data);
  return (
    <div className="table-responsive">
      <table className="table" id="reportes_eventos">
        <thead className="border-0 border-bottom">
          <tr>
            <th className="border-0"></th>
            <th className="Border-0">Id</th>
            <th className="Border-0">Nombre Evento</th>
            <th className="Border-0">Nombre Sitio</th>
            <th className="Border-0">Email responsable</th>
            <th className="Border-0">Fecha de función</th>
            <th className="Border-0">Entradas vendidas</th>
            <th className="Border-0">SubTotal</th>
            <th className="Border-0">%</th>
            {
              rol == 1
              ? <th className="Border-0 text-nowrap">2% Servicio</th>
              : ""
            }
            <th className="Border-0">Monto a abonar</th>
            <th className="Border-0">CBU</th>
            <th className="Border-0">CUIT</th>
            <th className="Border-0">Email</th>
            <th className="Border-0">Descuento</th>
            <th className="Border-0">Entradas con descuento</th>
            <th className="border-0"></th>
            <th className="border-0"></th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((evento) => (
            <>
              {evento.map((item) => (
                <>
                  {item.evento.liquidacion.length !== 0
                    ? item.evento.liquidacion.map((liqui, index) => (
                        <tr>
                          <td className="border-bottom"></td>
                          <td className="border-bottom">
                            {index > 0 ? "" : item.evento.id}
                          </td>
                          <td className="border-bottom">
                            {index > 0 ? "" : item.evento.nombre}
                          </td>
                          <td className="border-bottom">
                            {index > 0 ? "" : item.evento.nombre_sitio}
                          </td>
                          <td className="border-bottom">
                            {index > 0 ? "" : item.evento.usuarios.email}
                          </td>
                          <td className="border-bottom">
                            {index > 0 ? "" : item.fecha}
                          </td>
                          <td className="border-bottom">
                            {index > 0
                              ? ""
                              : totalReportEvents(item.reportes).total_cantidad}
                          </td>
                          <td className="border-bottom text-nowrap">
                            {index > 0
                              ? ""
                              : "$" +
                                totalReportEvents(
                                  item.reportes
                                ).total_pagos.toFixed(2)}
                          </td>
                          <td className="border-bottom">{liqui.porcentaje}</td>
                          {
                            rol == 1
                            ? <td className="border-bottom text-nowrap">
                            
                            {
                               //Condiciones nuevo 2%
                            new Date("2022-10-03") < new Date(item.fecha.split(" ")[5]+"-"+convertMonth(item.fecha.split(" ")[3])+"-"+item.fecha.split(" ")[1]) &&  item.evento.provincia == "Córdoba"
                            ? "$"+(
                              (((totalReportEvents(item.reportes).total_pagos -
                                argentores) /
                                100) *
                              liqui.porcentaje) / 100 * 2
                            ).toFixed(2)
                            : "Sin costo de servicio"
                            }
                          </td>
                            : ""
                          }
                          <td className="border-bottom text-nowrap">
                            $
                            {
                            //Condiciones nuevo 2%
                            new Date("2022-10-03") < new Date(item.fecha.split(" ")[5]+"-"+convertMonth(item.fecha.split(" ")[3])+"-"+item.fecha.split(" ")[1])  &&  item.evento.provincia == "Córdoba"
                            ? (
                              ((totalReportEvents(item.reportes).total_pagos -
                                argentores) /
                                100) *
                              liqui.porcentaje
                              - (((totalReportEvents(item.reportes).total_pagos -
                              argentores) /
                              100) * liqui.porcentaje) / 100 * 2
                            ).toFixed(2)
                            : (
                              ((totalReportEvents(item.reportes).total_pagos -
                                argentores) /
                                100) *
                              liqui.porcentaje
                            ).toFixed(2)
                            }
                          </td>
                          <td className="border-bottom text-nowrap">
                            CBU {liqui.cbu}
                          </td>
                          <td className="border-bottom text-nowrap">
                            {liqui.entidad}
                          </td>
                          <td className="border-bottom text-nowrap">
                            {item.evento.email_sitio}
                          </td>
                          <td className="border-bottom text-nowrap">
                            $ {totalReportEvents(item.reportes).total_descuento}
                          </td>
                          <td className="border-bottom text-nowrap">
                            {
                              totalReportEvents(item.reportes)
                                .total_entrada_descuento
                            }
                          </td>
                          <td className="border-bottom">
                            {item.reportes.length !== 0 ? (
                              item.reportes[0].status == "ACTIVO" ? (
                                rol == 1 ? (
                                  <label className="w-100 fs-7 btn p-1 px-2 btn-custom-amarillo">
                                    Liquidar
                                    <i class="d-block fa-solid fa-handshake"></i>
                                    <button
                                      className="d-none"
                                      name="liquidar_reportes"
                                      onClick={(event) =>
                                        onClick(event, {
                                          reportes: item.reportes,
                                          evento: item.evento,
                                          fecha: item.fecha,
                                        })
                                      }
                                    ></button>
                                  </label>
                                ) : (
                                  <label className="fs-7 w-100 btn p-1 btn-outline-dark rounded-pill disabled">
                                    Por liquidar
                                    <i class="d-block fa-solid fa-handshake"></i>
                                    <button className="d-none"></button>
                                  </label>
                                )
                              ) : item.reportes[0].status == "LIQUIDADO" ? (
                                <label className="fs-7 w-100 btn p-1 btn-info text-dark">
                                  Comprobante de liquidación
                                  <i class="d-block fa-solid fa-handshake"></i>
                                  <button
                                    className="d-none"
                                    name="comprobante_liquidacion"
                                    onClick={(event) =>
                                      onClick(event, {
                                        reportes: item.reportes,
                                        evento: item.evento,
                                        fecha: item.fecha,
                                      })
                                    }
                                  ></button>
                                </label>
                              ) : (
                                ""
                              )
                            ) : (
                              textVentas(item.fecha)
                            )}
                          </td>
                          <td className="border-bottom">
                            {rol == 1 ? (
                              <div class="dropdown">
                                <button
                                  class="btn btn-outline-dark rounded-pill px-3 py-1 dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Opciones
                                </button>
                                <ul class="dropdown-menu">
                                  <li>
                                    <label className="btn text-nowrap">
                                      <i class="fs-4 me-2 bi bi-envelope-check"></i>
                                      Enviar Email
                                      <button
                                        className="d-none"
                                        name="reenvio_email"
                                        onClick={(event) =>
                                          onClick(event, {
                                            reportes: item.reportes,
                                            evento: item.evento,
                                            fecha: item.fecha,
                                          })
                                        }
                                      ></button>
                                    </label>
                                  </li>
                                  <li>
                                    <label className="text-nowrap btn rounded-pill w-100 d-flex justify-content-center items-align-center p-3">
                                        <i class="fa-regular fa-folder-closed fs-4 me-2"></i>
                                      Ver detalles
                                      <button
                                        className="d-none"
                                        name="detalle"
                                        onClick={(event) =>
                                          onClick(event, item.reportes)
                                        }
                                        title="Detalle"
                                      >
                                      </button>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : ""}
                </>
              ))}
            </>
          ))}
          <tr className="bg-secondary bg-opacity-25">
            <td className="border-0" colSpan={6}>
              Total
            </td>
            <td className="border-0">
              {totalReportCuenta(total.data, filtro).total_cantidad}
            </td>
            <td className="border-0" colSpan={10}>
              $ {totalReportCuenta(total.data, filtro).total_pagos}
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default TablaEventos;
