import clienteAntesala from "../config/Config";


const RestApi = async ({
  url,
  formData,
  setLoading,
  method
}) => {
  setLoading(true);

  const response = await clienteAntesala({
    method: method,
    url: url,
    data: formData,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }).then((result) => {
    setLoading(false);
    return result
  }).catch((error) => {
    setLoading(false);
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  })


  return response;
}

export default RestApi


export const GetApi = async ({
  url,
  params,
  setLoading,
}) => {
  setLoading(true)

  const response = await clienteAntesala
    .get(url, { params })
    .then((result) => {
      setLoading(false)
      return result.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });

  return response
}