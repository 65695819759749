const normalize = (function() {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç", 
      to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};
 
  for(var i = 0, j = from.length; i < j; i++ )
      mapping[ from.charAt( i ) ] = to.charAt( i );
 
  return function( str ) {
      var ret = [];
      for( var i = 0, j = str.length; i < j; i++ ) {
          var c = str.charAt( i );
          if( mapping.hasOwnProperty( str.charAt( i ) ) )
              ret.push( mapping[ c ] );
          else
              ret.push( c );
      }      
      return ret.join( '' );
  }
 
})();

export const normalizedProvince = (provincias, value) => {

  let result = "";
  let state = false;


  provincias.map((pro) => {

    let comp_1 = normalize(pro.nombre).toUpperCase().replace(/\s+/g, '');
    let comp_2 = normalize(value).toUpperCase().replace(/\s+/g, '');

    if(comp_1 === comp_2){
      result = pro.nombre;
      state = true;
    } 

  })

  if(!state){
    result = false;
  }


  return result
}