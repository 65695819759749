import { useState } from "react";
import CardTicket from "../../../Card/CardTicket";

const Entradas = ({ data }) => {
  const { historial_compras } = data;

  const totalPages = historial_compras.length;
  const [currentPage, setCurrentPage] = useState(5);
  const [page, setPage] = useState(0);
  const dataPaginated = historial_compras.slice(page, currentPage);

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "next":
        {
          if(currentPage < totalPages){
          setPage(page+5)
          setCurrentPage(currentPage+5);
          }
        }
        break;
      case "back":
        {
          if(page !== 0){
            setPage(page-5)
            setCurrentPage(currentPage-5);
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":{

      }break;
    }
  }

  return (
    <div className="">
      <h1>Tus entradas</h1>

      <div className="row">
        {historial_compras.length !== 0 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    name="search"
                    onChange={(event) => onChange(event)}
                  />
                  <span class="input-group-text btn btn-custom-naranja" id="basic-addon2">
                  <i class="bi bi-search text-white"></i>
                  </span>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end mb-3">
                <label className="btn btn-custom-naranja text-white me-2">
                  <i class="bi bi-arrow-90deg-left"></i>
                  <button
                    className="d-none"
                    name="back"
                    onClick={(event) => onClick(event)}
                    disabled={page !== 0? false : true}
                  ></button>
                </label>
                <label className="btn btn-custom-naranja text-white">
                  <i class="bi bi-arrow-90deg-right"></i>
                  <button
                    className="d-none"
                    name="next"
                    onClick={(event) => onClick(event)}
                    disabled={currentPage < totalPages? false : true}
                  ></button>
                </label>
              </div>
              <div className="col-md-12">
                <div className="fw-bold fs-4">{page} entradas de {totalPages} </div>
              </div>
              {dataPaginated.map((reporte, index) => (
                <CardTicket keys={index + 1} data={reporte} />
              ))}
            </div>
          </div>
        ) : (
          <div className="p-5 text-center">
            <h1>No posee un historial de compras</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Entradas;
