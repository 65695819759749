import { useContext, useEffect, useState } from "react"
import { CollectionStateContext } from "../../../context/EventosState";
import CardNota from "./CardNota";
import { Link } from "react-router-dom";

const Notas = () => {
  const context = useContext(CollectionStateContext);
  const { notas,  state } = context;

//QUERY STATES
  

const [dataNota, setNota] = useState(null);
const [dataNotas, setNotas] = useState(state.notas);

//ARROW FUNCTION

const concatDate = (fecha, select) => {
    if(!fecha){
      return "Sin fecha asignada"
    }

    let result;

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];


    switch (select) {
      case "created_at":{
        let fh = new Date(fecha);
        
        result = dias[fh.getDay()]+" "+fh.getDate()+" de "+(mes[fh.getMonth()])+" de "+fh.getFullYear()
      }
      break;
      case "funciones":{
        if(!fecha){
          return ""
        }
        let fechas = JSON.parse(fecha);
        let fh_start = fechas[0].fechas[0].split(" ");
        let fh_end = fechas[fechas.length-1].fechas[fechas[fechas.length-1].fechas.length-1].split(" ");

        result = "Desde el "+fh_start[0]+" "+fh_start[1]+" de "+fh_start[3]+" hasta el "+fh_end[0]+" "+fh_end[1]+" de "+fh_end[3]
      }
      break;
    }

    return result;
  }

//FILTER

const [filter_nota, setFilterNota] = useState({ 
    id: "",
    search: "",
    categoria: "",
    date_range: "",
    provincia: "",
    order: "",
    max: "true",
    url: "api/Notas",
});


const [filter_notas, setFilterNotas] = useState({ 
    id: "",
    search: "",
    categoria: "",
    date_range: "",
    provincia: "",
    order: "",
    max: "false",
    url: "api/Notas",
});


useEffect(() => {
    notas(setNotas, filter_notas);
},[filter_notas])


useEffect(() => {
    notas(setNota, filter_nota);
},[filter_nota])
  return (
   <div className="container-fluid p-0">
      <section class="wow bounceInUp d-none" >
                <div class="container-md">
                    <div class="row">
                        <div class="col-8 col-md-9 pe-0">
                            <div class="card-ticket cardLeft d-flex flex-column">
                                <h1 class="fc-blanco">Notas</h1>
                                <div class="title">
                                    <h2>Antesala</h2>
                                    <span>sala</span>
                                </div>
                                <div>
                                    <div class="seat">
                                        <h2>156</h2>
                                        <span>asiento</span>
                                    </div>
                                    <div class="time">
                                        <h2>12:00</h2>
                                        <span>hora</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-md-3 ps-0">
                            <div class="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                                <div class="text-center">
                                    <img src="/assets/img/iso-antesala.svg" alt="" />
                                </div>
                                <div class="number">
                                    <h3>156</h3>
                                    <span>asiento</span>
                                </div>
                                <div class="text-center">
                                    <img class="barchart" src="assets/img/barcode.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-amarillo pt-100  pb-100 mt-100 position-relative wow bounceInUp" >
                <img src="/assets/img/bg-03.svg" className="bg-03-notas" />
                <img src="/assets/img/bg-01.svg" className="bg-01-notas" />
                <div class="container-md">
                   {
                       dataNotas
                       ?  <div class="bg-blanco box-multimedia mb-4 wow bounceInUp position-relative">
                       <div class="row">
                           <div class="col-lg-8 d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-md-1">
                               <div>
                                   <p>{concatDate(dataNotas.data[dataNotas.data.length-1].created_at, "created_at")}</p>
                                   <hr class="hr-black" />
                                   <h2 class="fc-amarillo">"{dataNotas.data[dataNotas.data.length - 1].titulo}"</h2>
                                   <p class="Grifter">Por {dataNotas.data[dataNotas.data.length - 1].autor}</p>
                                   <p>{dataNotas.data[dataNotas.data.length - 1].descripcion}</p>
                               </div>
                               <div class="text-center">
                                        <Link to={"/contenido/nota/"+dataNotas.data[dataNotas.data.length - 1].id} class="btn btn-custom-black">leer</Link>
                               </div>
                           </div>
                           <div class="col-lg-4 order-1 order-md-2">
                                    <img src={"https://antesala.com.ar/assets/img/notas/" +dataNotas.data[dataNotas.data.length - 1].imagen} width="100%" alt="IMAGEN NO ENCONTRADA" />
                           </div>
                       </div>
                   </div>
                   : ""
                   }

                    <div class="row" id="video">

                            {
                                dataNotas
                                ? dataNotas.data.map((nota, index) => {
                                    if(index != dataNotas.data.length - 1){
                                        return <CardNota keys={index} data={nota} />
                                    }
                                })
                                : ""
                            }
  
                    <div class="d-grid gap-2 wow bounceInUp d-none">
                        <a href="#" class="btn btn-custom-black">ver más +</a>
                    </div>  
                    </div>

                    </div>
            </section> 
   </div>
  )
}

export default Notas