const Membresia = ({ data }) => {
  const { historial_compras, membresias } = data;

  console.log(membresias);
  return (
    <div className="">
      <h1>Membresias</h1>

      <div className="row">
        {membresias.map((member) => (
          <div className="col-md-4 content-hidden-text">
            <ul class="list-group border p-3" style={{ borderRadius: "10px" }}>
              <li class="list-group-item border-0 p-0 fw-bold text-center">
                {
                  member.monto_limite > 0 
                  ? <span class="btn btn-success rounded-pill p-0 px-4">ACTIVO</span>
                  : <span class="btn btn-alert rounded-pill py-1">SIN STOCK</span>
                }
              
              </li>
              <li class="list-group-item border-0 p-0 fw-bold text-center">
                {member.name}
              </li>
              <li class="list-group-item border-0 p-0 fw-bold fs-7 text-dark text-center">
                {member.description}
              </li>
              <li class="list-group-item border-0 p-0 text-center">
                <span className="fs-7 text-dark ">Entradas disponibles</span>
                <div className="text-center border-0 fw-bold fs-3">
                  {member.limit}
                </div>
              </li>
              <li class="list-group-item border-0 p-0 text-center d-none">
                <span className="fs-7 text-dark ">Descuento</span>
                <div className="text-center border-0 fs-3 fw-bold">
                  {member.descont}%
                </div>
              </li>
              <li className="list-group-item border-0 p-0 d-none">
                <span className="fs-9">
                  Membresia habilitada solo para eventos de {member.region}
                </span>
              </li>
              <li className="hidden-text">
                <div className=""  dangerouslySetInnerHTML={{__html: member.terminos}}>
                                  </div>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Membresia;
