import Swal from "sweetalert2"

const AlertSuccessXl = (msg, title) => {

return Swal.fire({
  icon: 'success',
  title: title,
  text: msg,
  showConfirmButton: false,
})

}

export default AlertSuccessXl