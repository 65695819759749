import clientAxios from "./Config";
import Swal from "sweetalert2";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertError from "../Alerts/AlertError";

export const CreateFactura = async (dataForm, setLoading) => {
  setLoading(true)

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/CrearFacturacion',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    return AlertSuccess(result.data.msg);
  }).catch((result) => {
    setLoading(false)
    return AlertError(result.data.msg);
  });

}

export default (
  CreateFactura
  );