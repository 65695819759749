import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link }  from 'react-router-dom';

const EventosItem = ({eventos}) => {
  const { id, nombre, descripcion, nombre_sitio, entradas, cupones, imagen, categorias, provincia, localidad } = eventos;


  const menor_precio = (entradas) => {
    entradas.sort((x, y) => x.precio - y.precio);
    return entradas[0]
  }

  return (
    <div class="col-md-4 wow bounceInUp mb-4 pe-3 ps-3">
     <div class="box-eventos text-center">
        <div class="img-evento">
          <span class="tag-evento" style={{fontSize: "12px"}}>{categorias[0].nombre}</span>
          {
            /*
            entradas.map((entrada) => {
              if(cupones.length !== 0 ){
                if(cupones[0].status == "ACTIVO"){
                return <span class="tag-descuento">{cupones[0].descuento}%</span>
                }
              }
            })
              */
          }
           <LazyLoadImage
              key={id}
              src={"https://antesala.com.ar/assets/img/eventos/"+imagen}
              alt={`Image Alt-${id}`}
              className="img-lazy"
              width={"100%"} 
              placeholderSrc={""}
              effect='blur' // opacity | black-and-white
            />
        </div>
        <p class="teatro mt-4 mb-0">{nombre_sitio}</p>
      <p className="m-0 text-muted opacity-75 fs-8">{provincia} - {localidad}</p>
        <h1 class="fs-5 mt-2">{nombre}</h1>
        <p class="precio fs-4 mt-2">{entradas.length !== 0
      ? menor_precio(entradas).precio == 0? "Gratis" : '$'+menor_precio(entradas).precio
      : ""}</p>
        <Link to={"/evento/"+id} class="btn btn-custom-amarillo mb-4">comprar</Link>
      </div>
    </div>
  )
}

export default EventosItem