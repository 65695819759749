const Filtro = () => {


    return (
        <div>
          <h4 class="GothamBold wow bounceInUp">Filtrar</h4>
          <hr class="wow bounceInUp" />
          <div class="d-flex align-items-center mb-4" id="calendar-home">
            <label className="btn bg-naranja text-white">
              Aplicar filtro
              <button className="d-none"></button>
            </label>
          </div>
          <div className="mb-4">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">
                Desde
              </label>
              <input
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">
                Hasta
              </label>
              <input
                type="date"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
              />
            </div>
          </div>
          <hr class="wow bounceInUp" />
          <div class="mb-4">
            <span class="GothamBold mb-0 mb-2 wow bounceInUp">Ubicación</span>
            <select class="form-select form-select wow bounceInUp">
              <option selected>Córdoba</option>
              <option value="1">Rosario</option>
              <option value="2">Buenos Aires</option>
              <option value="3">Santa Fé</option>
            </select>
          </div>
          <div class="mb-4">
            <span class="GothamBold mb-0 mb-2 wow bounceInUp">Sala</span>
            <select class="form-select form-select wow bounceInUp">
              <option selected>Uno</option>
              <option value="1">Dos</option>
              <option value="2">Tres</option>
              <option value="3">Cuatro</option>
            </select>
          </div>
          <div class="mb-4">
            <span class="GothamBold mb-0 mb-2 wow bounceInUp">Público</span>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Adulto</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">ATP</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Infancias</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
          </div>
          <div class="mb-4">
            <span class="GothamBold mb-0 mb-2 wow bounceInUp">
              Tipo de Espectáculo
            </span>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Teatro</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Música</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Circo</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Danza</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Stand Up</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Impro</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Títeres</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Otro</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
          </div>
          <div class="mb-4">
            <span class="GothamBold mb-0 mb-2 wow bounceInUp">
              Modo de Espectáculo
            </span>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Presencial</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
            <div class="form-check mb-2 wow bounceInUp">
              <label class="form-check-label">Online</label>
              <input class="form-check-input" type="checkbox" value="" />
            </div>
          </div>
          <div>
            <label className="btn bg-naranja text-white">
              Aplicar filtro
              <button className="d-none"></button>
            </label>
          </div>
        </div>
    )
}

export default Filtro