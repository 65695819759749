import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // estilos
import "react-date-range/dist/theme/default.css"; // tema
import Evento from "../components/eventos/Evento";
import { PATH_GALERIA_PRODUCTION } from "../../const/PATH";

const Inicio = ({
  CalendarProps,
  banners,
  eventosRecomendados,
  eventosDescuento,
}) => {
  const markedDates = [
    new Date(2024, 9, 1),
    new Date(2024, 9, 15),
    new Date(2024, 9, 25),
  ];

  const isDateMarked = (date) => {
    return markedDates.some(
      (markedDate) =>
        date.getDate() === markedDate.getDate() &&
        date.getMonth() === markedDate.getMonth() &&
        date.getFullYear() === markedDate.getFullYear()
    );
  };

  console.log({banners});
  
  return (
    <main className="m-0" style={{ paddingTop: "100px" }}>
      <section className="mt-1 mb-3 p-0">
        <div class="container wow bounceInUp ">
          <div>
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 1,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {banners.map((banner) => (
                <div
                  className="bg-light"
                  style={{
                    height: "350px",
                    borderRadius: "20px",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src={`${PATH_GALERIA_PRODUCTION}${banner.imagen}`}
                      className="img-fluid"
                      alt=""
                      style={{ height: "350px" }}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section id="calendar" class="position-relative pb-100">
        <img src="assets/img/bg-02.svg" class="img" alt="" />
        <div class="container">
          <div class="row">
            <div className="col-md-6 d-flex justify-content-center flex-column mb-4 align-items-center wow bounceInLeft">
              <h1>Qué hacer?</h1>
              <div className="p-0 p-md-0 d-flex justify-content-center">
                <DateRange
                  onChange={(item) => {
                    console.log(item);
                    //CalendarProps.setState([{...item}])
                  }}
                  locale={CalendarProps.locale}
                  minDate={new Date()}
                  ranges={CalendarProps.state}
                  color={"#3d3d3d"}
                  rangeColors={["white"]}
                  showDateDisplay={false}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  dayContentRenderer={(date) => {
                    const marked = isDateMarked(date);
                    const clases = marked
                      ? "bg-naranja rounded-md text-white px-2 py-0 GothamBold position-relative"
                      : "GothamBold";
                    return (
                      <div
                        className={`${clases} d-flex align-items-center`}
                        style={{ borderRadius: "10px", height: "25px" }}
                      >
                        {date.getDate()}
                        {marked ? (
                          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-amarillo">
                            10+
                            <span class="visually-hidden">unread messages</span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-start d-flex wow bounceInRight">
              <a
                href={"#"}
                class="big-button today d-flex align-items-center justify-content-center"
                style={{ width: "15%", height: "350px" }}
              >
                <h1>hoy</h1>
              </a>
              <a
                href={"#"}
                class="big-button weekend d-flex align-items-center justify-content-center"
                style={{ width: "15%", height: "350px" }}
              >
                <h1>el finde</h1>
              </a>
              <a
                href={"#"}
                class="big-button month d-flex align-items-center justify-content-center"
                style={{ width: "15%", height: "350px" }}
              >
                <h1>este mes</h1>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="eventos" class="mb-5">
        <div class="container text-center">
          <h2 className="fs-4">Obras recomendadas</h2>
          <div class="row">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {[1, 2, 3, 4, 5].map(() => (
                <div
                  class="item p-3"
                  style={{
                    background: "none",
                    width: "95%",
                    borderRadius: "20px",
                  }}
                >
                  <Evento />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section id="eventos" class="mb-5">
        <div class="container text-center">
          <h2 className="fs-4">Obras con descuento</h2>
          <div class="row">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {[1, 2, 3, 4, 5].map(() => (
                <div
                  class="item p-3"
                  style={{
                    background: "none",
                    width: "95%",
                    borderRadius: "20px",
                  }}
                >
                  <Evento descount={true} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section id="apoyo" class="wow bounceInUp p-0 m-0 mb-5 d-none">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mb-200">
              <div class="text-center bg-gris-oscuro br-10 pt-50 pb-50 pe-4 ps-4">
                <h1 class="fc-blanco m-0">contamos con el apoyo de</h1>
              </div>
            </div>
            <div class="col-md-4 d-flex align-items-center justify-content-center">
              <div class="wrapper">
                <div class="carousel">
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-04.png" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-05.png" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-nmg.svg" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-01.png" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-02.png" alt="" />
                    </div>
                  </div>
                  <div class="carousel__item">
                    <div class="carousel__item-body">
                      <img src="assets/img/logo-03.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Inicio;
