import axios from "axios"
import clientAxios from "./Config";
import Swal from "sweetalert2";





export const ObtenerToken = async (formData) => {


  const urlDev = "https://developers.decidir.com/api/v2/tokens"
  //const urlPrd = "https://live.decidir.com/api/v2/tokens"
  const apiKeyDev = "uRtPzTm6qqGl7NnTMPepwVTW7LqBKmIr";
  //const apiKeyPrd = "5b66cfc46dd144e49d816484e5ee652d"

  const response = await axios({
    method: "post",
    url: urlDev,
    headers: {
      apikey: apiKeyDev,
    },
    data: formData
  }).then((result) =>{
    return result.data 
  }).catch((result) => {
    return "error";
  })

  return response

}

export const RealizarPago = async (formData) =>{

  const response = await clientAxios({
    method: "post",
    url: "/api/SolicitarPago",
    data: formData
  }).then((result) =>{
    if(result.data.msg == "Error del sistema"){
      Swal.fire({
        text: result.data.msg,
        icon: 'error',
        timer: "1500",
        position: "bottom",
        showConfirmButton: false,
        customClass: {
          container: "add-to-cart-alert-container",
          popup: "add-to-cart-alert",
        }
      })
    return window.location.href = '/alert/errorPago/'+JSON.stringify(result.data.error);
    }
    if(result.data.msg == "Ocurrio un error con su tarjeta, por favor vuelva a intentarlo!"){
       Swal.fire({
        text: result.data.msg,
        icon: 'error',
        timer: "2000",
        position: "bottom",
        showConfirmButton: false,
        customClass: {
          container: "add-to-cart-alert-container",
          popup: "add-to-cart-alert",
        }
      })
    return window.location.href = '/alert/errorPago/'+JSON.stringify(result.data.error);
    }
    Swal.fire({
      text: result.data.msg,
      icon: 'success',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/alert/confirmPago/'+result.data.info+'/'+JSON.stringify(result.data.res);
  }).catch((result) => {
    Swal.fire({
      text: "Operacion invalida.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/alert/errorPago/'+JSON.stringify(result.data);
  })

  return response.data;
}

export const PagoFree = async (formData) => {

  const response = await clientAxios({
    method: "post",
    url: "/api/SolicitarPagoFree",
    data: formData
  }).then((result) =>{
    if(result.data.msg == "¡La entrada no es gratis!"){
      return Swal.fire({
        text: result.data.msg,
        icon: 'error',
        timer: "1500",
        position: "bottom",
        showConfirmButton: false,
        customClass: {
          container: "add-to-cart-alert-container",
          popup: "add-to-cart-alert",
        }
      })
    }
    Swal.fire({
      text: result.data.msg,
      icon: 'success',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return window.location.href = '/alert/confirmPago/'+result.data.info+'/'+JSON.stringify(result.data.res);
  }).catch((result) => {
    return result; //console.log(result);
  })

  return response.data;

}

export const medioDePago = async () => {

  const result = await clientAxios.get('/api/SolicitarMedioPago');

  return result.data;

}



export default(
  ObtenerToken,
  RealizarPago,
  medioDePago
)