const Filtros = ({ data, onClick, onChange }) => {
  return (
    <div className="row">
      <div className="col-md-3">
        <select
          class="form-select border-custom-naranja rounded-pill"
          aria-label="Default select example"
          name="select_status"
          onChange={(event) => onChange(event)}
        >
          <option selected>Selecciona un estado</option>
          <option value="ACTIVO">ACTIVOS</option>
          <option value="INACTIVO">INACTIVOS</option>
        </select>
      </div>
    </div>
  );
};

export default Filtros;
