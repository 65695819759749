import Card from "./cards/Index";

const Notas = ({ data, onClick }) => {
  return (
    <div className="min-vh-100" style={{ backgroundColor: "#eaeaea" }}>
      <div
        className="row container-md mx-auto p-0 m-0 border-0 shadown-sm texto-normal"
        style={{ maxWidth: "800px" }}
      >
        <div class="col-md-12">
          <h1>Notas</h1>
        </div>

        <div className="col-md-12 d-flex justify-content-end mb-3">
          <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
            Añadir Nota
            <button
              className="d-none"
              name="nuevo_evento"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
        </div>

        <div className="col-md-12">
          {data
            ? data.data.map((d, index) => <Card data={d} onClick={onClick} />)
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Notas;
