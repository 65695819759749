const CardCupon = ({data, onChange, onClick, eventos, salas}) => {

  return (
    <div className="row justify-content-center m-0 border-0  p-0 min-wh-100">


    
    <div className="col-md-12">
      <div className="row bg-transparent">
          {
            data
            ? data.map((cupon) => (
              <div className="col-md-4 col-12 p-0 hover-card">
              <ul className="list-group bg-white mb-3 me-2 py-1" style={{borderRadius: "20px"}}>
            <li className="list-group-item border-0 d-flex justify-content-between">
              <button className="w-100 btn-outline-dark bg-white border-custom-naranja text-custom-naranja  fs-10 rounded-pill pt-1 p-0 m-0 px-2 me-1" name="estadoCupon" onClick={(event) => onClick(event, cupon)}> Cambiar estado...</button>
            <button className="w-100 btn-outline-dark bg-white border-custom-naranja text-custom-naranja  fs-10 rounded-pill pt-1 p-0 m-0 px-2" name="editCupon" onClick={(event) => onClick(event, cupon)}><i className="bi bi-pencil"></i> Editar</button></li>
            
            <li className="list-group-item border-0 fs-6">El cúpon se encuentra <span className="fw-bold">{cupon.status}</span>
            </li>
            <li className="list-group-item border-0 fs-6"><i className="bi bi-ticket"></i> {cupon.nombre}</li>
            <li className="list-group-item border-0 fs-6"><i className="bi bi-upc"></i> {cupon.codigo}</li>
            <li className="list-group-item border-0 fs-6">Descuento {cupon.descuento}%</li>
            <li className="list-group-item border-0 fs-6">Limite {cupon.limite}</li>
            <li className="list-group-item border-0 fs-6">Habilitación {cupon.fecha_habilitacion}</li>
            <li className="list-group-item border-0 fs-6">Expira {cupon.fecha_expiracion}</li>


            <li className="list-group-item border-0 p-0">
              <div className="accordion accordion-flush p-0" id="accordionFlushExample">
              <div className="accordion-item p-0">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed py-1 fs-6 fw-normal text-dark bg-white" type="button" data-bs-toggle="collapse" data-bs-target={"#Collapse-Eventos"+cupon.id} aria-expanded="false" aria-controls="flush-collapseOne">
                    {cupon.tipo_aplicacion} habilitados
                  </button>
                </h2>
                <div id={"Collapse-Eventos"+cupon.id} className="accordion-collapse collapse p-0" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body p-0">
                    <ul className="list-group p-0 m-0">
                     {
                       cupon.tipo_aplicacion == "eventos"
                       ?  
                        cupon.eventos.map((evento) => (
                          <li className="list-group-item border-0 text-break d-flex justify-content-between fs-6" style={{overflowWrap: ""}}><div>{evento.nombre}</div></li>
                        ))
                      : cupon.tipo_aplicacion == "salas"
                      ?  cupon.salas.map((sala) => (
                          <li className="list-group-item border-0 text-break d-flex justify-content-between fs-6" style={{overflowWrap: ""}}><div>{sala.nombre}</div></li>
                        ))
                      : ""
                     }
                    </ul>
                  </div>
                </div>
              </div>
              </div>
          </li>

          <li className="list-group-item border-0 p-0">
              <div className="accordion accordion-flush p-0" id="accordionFlushExample">
              <div className="accordion-item p-0">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed py-1 fs-6 fw-normal text-dark bg-white" type="button" data-bs-toggle="collapse" data-bs-target={"#Collapse-Fechas"+cupon.id} aria-expanded="false" aria-controls="flush-collapseOne">
                    Fechas habilitadas
                  </button>
                </h2>
                <div id={"Collapse-Fechas"+cupon.id} className="accordion-collapse collapse p-0" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body p-0">
                    <ul className="list-group p-0 m-0">
                      {
                        JSON.parse(cupon.fechas_funciones).map((funciones) => (
                          <li className="list-group-item border-0 text-break d-flex justify-content-between fs-6" style={{overflowWrap: ""}}><div>{funciones.fechas[0]}</div></li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
              </div>
          </li>

          <li className="list-group-item border-0 p-2">
            <button className="btn btn-custom-naranja text-white p-0 m-0 py-2 GothamBold fs-7 rounded-pill w-100" name="report_cupon" onClick={(event) => onClick(event, cupon)}>Consultar uso</button>
          </li>

          </ul>
            </div>
            ))
            : ""
          }
        </div>
    </div>

  </div>
  )
}

export default CardCupon