import React, { useContext, useEffect, useState } from "react";
import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import { CollectionStateContext } from "../../../../../context/EventosState";
//Api 
import useApi from "../../../../../hooks/useApi";
//Date format
import { format, add, sub } from "date-fns";
//Components
import HomeEventosItem from "./HomeEventosItem";
import { GetApi } from "../../../../../resource/helpers/RestApi";
import { GetPublicApi } from "../../../../../resource/helpers/PublicApi";

const HomeEventos = () => {
  const context = useContext(CollectionStateContext);
  const { /*eventos*/ state } = context;

  const options = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    navText: ["<div style='top: 1px; left: -30px;' class='d-md-block d-none position-absolute h-100 d-flex justify-content-start fs-1'> <img width='20px' height='100%' src='/assets/img/icon-prev-arrow.svg' /></div>", "<div style='top: 1px; right: -30px;' class='d-md-block d-none position-absolute h-100 d-flex justify-content-start fs-1'> <img width='20px' height='100%' src='/assets/img/icon-next-arrow.svg' /></div>"],
    autoplayTimeout: 6000,
    fluidSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 3,
      },
    },
    dots: false
  };

  const geolocation = localStorage.getItem("geolocation");
  const [eventos, setEventos] = useState(null);
  const [loading, setLoading] = useState(null);

  const fetch = async (params) => {
    const response = await GetPublicApi({
      url: params.url,
      params: params,
      setLoading,
    })
    if (response.status == 200 || response.status == 201) {
      setEventos(response.data)
    }
  }
  useEffect(() => {
    let params = {
      //id: "",
      //search: "",
      //sala: "",
      status: 1,
      date_range: [
        format(new Date(), "yyyy/MM/dd").toString(),
        format(add(new Date(), { months: 12 }), "yyyy/MM/dd").toString(),
      ],
      provincia: geolocation ? geolocation : "",
      //static
      url: "Eventos/All",
      pagination: 20,
    }
    if (!eventos) {
      fetch(params)
    }
  }, [])
  return (
    <section id="eventos">
      <div className="container pb-1 pt-1">
        <div className="w-100 text-center"><h1 className="fs-3">Obras Recomendadas</h1></div>
        {
          eventos ?
            eventos.data.length !== 0 ?
              <ReactOwlCarousel style={{ zIndex: "0" }} className="owl-theme"  {...options}>
                {eventos.data.map((item, index) => <HomeEventosItem key={index} data={item} />)}
              </ReactOwlCarousel>
              :
              ""
            :
            <div className="w-100 placeholder-home-events d-flex justify-content-center align-items-center" style={{ height: "350px" }}>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span className="text-dark ms-1">Cargando eventos</span>
            </div>
        }
      </div>
    </section>
  );
};

export default HomeEventos;
