import { createSlice } from "@reduxjs/toolkit";
import clienteAntesala from "../../../config/Config";

const initialState = {
  membresias: null,
  suscriptor: null
};

export const membresiaSlice = createSlice({
  name: "membresias",
  initialState: initialState,
  reducers: {
    setMembresias: (state, action) => {
      state.membresias = action.payload
    },
    setSuscriptor: (state, action) => {
      state.membresias = action.payload
    }
  },
});

//Async functions
export const fetchMembresias = (params) => (dispatch) => {
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setMembresias(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchSuscriptor = (params) => (dispatch) => {
  clienteAntesala
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setSuscriptor(result.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setMembresias,  setSuscriptor } = membresiaSlice.actions;

export const membresiaState = (state) => state.membresias;

export default membresiaSlice.reducer;
