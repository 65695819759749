import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertSuccessXl from "../Alerts/AlertSuccessXl";
import AlertWarning from "../Alerts/AlertWarning";
import clientAxios from "./Config";

export const VideosHome = async (filter) => {
  let params = {}

  if(filter.id !== ""){
    params = {...params, id: filter.id}
  }

  if(filter.provincia.trim() !== ""){
    params = {...params, provincia: filter.provincia}
  }

  const result  = await clientAxios.get(filter.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;
}

export const AdministradorVideosCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Multimedia/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    //return (window.location.href = "/dashboard/notas/editar/" + result.data.id); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}

export const AdministradorVideos = async (filter) => {

  let params = {}

  if(filter.id !== ""){
    params = {...params, id: filter.id}
  }

  if(filter.provincia.trim() !== ""){
    params = {...params, provincia: filter.provincia}
  }

  const result  = await clientAxios.get(filter.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;

}

export const AdministradorVideosActualizar = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Multimedia/Actualizar',
    data: dataForm,
  }).then((result) => {
    setLoading(false)
    AlertSuccess(result.data.msg); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}