const CardNotificacion = ({ keys, data }) => {
  const { nombre, descripcion, fecha_expiracion, tipo } = data;

  return (
    <li
      className={
        tipo == "ERROR"
          ? "px-0 py-2 shadow-sm border-custom-naranja  w-100 btn text-start mb-2"
          : tipo == "AVISO"
          ? "px-0 py-2 d-flex shadow-sm border-info  w-100 btn text-start mb-2 shadow-sm"
          : tipo == "IMPORTANTE"
          ? "px-0 py-2 shadow-sm border-warning  w-100 btn text-start mb-2"
          : ""
      }
    >

      <div className="row w-100 m-0">
        <div className="col-md-10 col-8">
        <span className="d-block fw-bold fs-8">{nombre}</span>
            <span className="d-block fs-8 text-break">{descripcion}</span>
        </div>
        <div className="col-md-2 col-4 fs-4 d-flex justify-content-center align-items-center">
             {tipo == "ERROR"
              ? <i class="text-danger bi bi-exclamation-circle"></i>
              : tipo == "AVISO"
              ? <i className="bi text-info bi-info-circle"></i>
              : tipo == "IMPORTANTE"
              ? <i class="bi text-warning bi-exclamation-triangle"></i>
              : ""}
        </div>

        <div className="col-md-12 mb-0 p-0 px-3">
        <span className="d-block fs-10 mt-2 mb-0 w-100">La notificacion expira el {fecha_expiracion}</span>
        </div>
      </div>

      


    </li>
  );
};

export default CardNotificacion;
