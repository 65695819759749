import Sala from "../../../components/layout/Sala/Sala";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";

const SalaController = () => {
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <Sala />
      
      <div className="container-md min-vh-100"></div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default SalaController;
