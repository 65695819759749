import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CheckBox from "../../components/custom/forms/CheckBox";
import Input from "../../components/custom/forms/Input";
import TextTarea from "../../components/custom/forms/TextTarea";
//import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import CategoriaController from "../../controllers/categorias/CategoriaController";

const Nota = ({
  id,
  form,
  setForm,
  validation,
  setValidation,
  onChange,
  onClick,
  loading,
  setLoading,
  categorias,
  provincias,
}) => {

  let contenido = form.contenido[0];
  return (
    <div className="min-vh-100" style={{ backgroundColor: "#eaeaea" }}>
      <div className="container-md  w-100">
        <div
          className="card shadow-sm  p-3 mb-2"
          style={{ borderRadius: "1rem" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="w-100 d-flex justify-content-between">
                <h3 className="">Nota</h3>

                {id > 0 ? (
                  <div className="d-flex justify-content-end w-100 my-2">
                    <div class="dropdown me-2">
                      <button
                        class="btn btn-outline-dark dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {form.status_id[0] == 1 ? (
                          <span className="text-success">
                            Publicado
                            <i class="bi bi-check-all"></i>
                          </span>
                        ) : form.status_id[0] == 2 ? (
                          <span className="text-info">
                            Borrador
                            <i class="ms-2 bi bi-recycle"></i>
                          </span>
                        ) : form.status_id[0] == 3 ? (
                          <span className="text-danger">
                            Eliminado
                            <i class="ms-2 bi bi-trash"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                      <ul class="dropdown-menu">
                        {form.status_id[0] == 1 ? (
                          ""
                        ) : (
                          <li>
                            <label
                              class="dropdown-item text-success btn btn-outline-dark border-0"
                              href="#"
                            >
                              Publicar
                              <i class="bi bi-check-all"></i>
                              <button
                                className="d-none"
                                name="status"
                                onClick={(event) => onClick(event, 1)}
                              ></button>
                            </label>
                          </li>
                        )}
                        {form.status_id[0] == 2 ? (
                          ""
                        ) : (
                          <li>
                            <label
                              class="dropdown-item text-info btn btn-outline-dark border-0"
                              href="#"
                            >
                              Borrador
                              <i class="ms-2 bi bi-recycle"></i>
                              <button
                                className="d-none"
                                name="status"
                                onClick={(event) => onClick(event, 2)}
                              ></button>
                            </label>
                          </li>
                        )}
                        {form.status_id[0] == 3 ? (
                          ""
                        ) : (
                          <li>
                            <label
                              class="dropdown-item text-danger btn btn-outline-dark border-0"
                              href="#"
                            >
                              Eliminado
                              <i class="ms-2 bi bi-trash"></i>
                              <button
                                className="d-none"
                                name="status"
                                onClick={(event) => onClick(event, 3)}
                              ></button>
                            </label>
                          </li>
                        )}
                      </ul>
                    </div>
                    <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
                      Aplicar cambios
                      <button
                        className="d-none"
                        name="aplicar"
                        onClick={(event) => onClick(event, null)}
                      ></button>
                    </label>
                  </div>
                ) : (
                  <div>
                    <label className="btn btn-outline-dark fw-normal GothamBold text-custom-naranja border-custom-naranja px-4 me-1">
                      Publicar Nota
                      <button
                        className="d-none"
                        name="guardar"
                        onClick={(event) => onClick(event, 1)}
                      ></button>
                    </label>
                    <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
                      Guardar
                      <button
                        className="d-none"
                        name="guardar"
                        onClick={(event) => onClick(event, 2)}
                      ></button>
                    </label>
                  </div>
                )}
              </div>
              <hr />

              <div
                className="w-100 overflow-hidden mb-2"
                style={{ height: "500px", borderRadius: "10px" }}
              >
                <img
                  src={
                    form.imagen_url[0]
                      ? form.imagen_url[0]
                      : "/assets/images/organizaciones.jpg"
                  }
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <Input
                onChange={onChange}
                label={"Imagen principal"}
                name={"imagen"}
                type={"file"}
                value={""}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={validation.imagen_url}
                disabled={false}
              />
            </div>

            <div className="col-md-8 mb-3">
              <div className="mb-2">
                <Input
                  onChange={onChange}
                  label={"Titulo"}
                  name={"titulo"}
                  type={"text"}
                  value={form.titulo[0]}
                  validate={validation.titulo}
                  disabled={false}
                />
              </div>
              <div className="mb-2">
                <TextTarea
                  onChange={onChange}
                  label={"Descripción corta"}
                  name={"descripcion"}
                  type={"text"}
                  value={form.descripcion[0]}
                  validate={validation.descripcion}
                />
              </div>
              <div className="mb-2">
                <Input
                  onChange={onChange}
                  label={"Autor"}
                  name={"autor"}
                  type={"text"}
                  value={form.autor[0]}
                  validate={validation.autor}
                  disabled={false}
                />
              </div>
              <div className="mb-2">
                <label
                  for="email"
                  className="form-label text-muted"
                  style={{ fontSize: "14px" }}
                >
                  Provincia
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="provincia"
                  onChange={(event) => onChange(event)}
                >
                  {form.provincia[0] == "" ? <option selected></option> : ""}
                  {provincias
                    ? provincias.map((p) => {
                        if (p.nombre == form.provincia[0]) {
                          return <option selected>{p.nombre}</option>;
                        } else {
                          return <option value={p.nombre}>{p.nombre}</option>;
                        }
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-3">
              <div className="mb-2">
              <span className="text-muted">Selecciona una categoria</span>
              {categorias
                ? categorias.map((c, index) => (
                    <CheckBox
                      onChange={onChange}
                      label={c.nombre}
                      name={"categorias"}
                      type={"checkbox"}
                      value={
                        form.categorias[0].indexOf(c.id) < 0 ? false : true
                      }
                      uniqueKey={index}
                      disabled={false}
                    />
                  ))
                : ""}

              {validation.categorias.status ? (
                <div className="text-danger">{validation.categorias.msg}</div>
              ) : (
                ""
              )}
              </div>
              <div className="mb-2">
                <CategoriaController 
                tipo={"nota"}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="card shadow-sm  p-3 mb-2"
          style={{ borderRadius: "1rem" }}
        >
          <div className="row">
            <div className="col-md-12 mb-3">
              <h3>Contenido</h3>
              <hr />
              <CKEditor
                editor={ClassicEditor}
                data={contenido}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  let input = form;
                  input.contenido[0] = data;

                  setForm({
                    ...form,
                    contenido: input.contenido,
                  });
                  //console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  //console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  //console.log("Focus.", editor);
                }}
              />
            </div>
            {validation.contenido.status ? (
              <div className="text-danger">{validation.contenido.msg}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nota;
