const TYP = () => {

  return (
    <div className="min-vh-100 container-md">
      <h1 className="fs-2">Condiciones de uso de Antesala</h1>
      <h2 className="fs-5"> Última actualización: 06 Diciembre 2022 </h2>
      <p>
        Los presentes términos y condiciones rigen las condiciones de uso del sitio  web www. antelasa.com.ar (en adelante “el sitio “), y las relaciones  entre la administradora  del sitio Grupo Antesala S.A.S. (en adelante la administración  y/o indistintamente “Antesala”), el Productor de eventos (en adelante el Usuario Productor  y/ o indistintamente Productor), y quienes acceden, usen, y contraten en  el mismo (en adelante, Usuario Espectador y/ o indistintamente “Espectador”). El simple acceso al sitio web implica la aceptación de todas las condiciones que se mencionan a continuación.  En caso de no estar de acuerdo con estas condiciones sugerimos que te abstengas de realizar interacciones en el sitio.

        Estas condiciones están organizadas por secciones en la “A” encontraras toda la información relativa al uso del sitio, en la “B”  te contamos cómo usamos los datos que recabamos cuando interactúas en éste sitio, en la “C” toda la información que tenes que saber si sos un Usuario Espectador y la “D” todas las condiciones aplicables a cada Usuario  Sala / Productor.
      </p>

      <h1 className="fs-2">Sección A</h1>
      <h2 className="fs-5">Condiciones Generales de uso del sitio </h2>

      <p>
        <strong>A.1 Política de uso de claves en este sitio:</strong> Cada Usuario Espectador y cada Usuario Productor  que se registre será responsable en todo momento de la custodia de su contraseña, asumiendo en consecuencia cualquier daño y perjuicio que pudiera derivarse de su uso, así como el uso indebido, revelación o extravío de la misma. A estos efectos, todo  acceso / o uso de los servicios y contenidos del sitio,  realizado bajo la contraseña que generaste,  se reputarán realizados por vos, y deberás responder por las consecuencias que se generen  por dicho   acceso y uso.
      </p>
      <p>
        <strong>A. 2 Validez de la información proporcionada:</strong> Para darse de alta cada Espectador y Productor deberán completar los datos que exige el sitio,  los cuales se considerarán que fueron proporcionados en carácter de declaración jurada, debiendo responder cada titular de los mismos por su  veracidad y exactitud. La falta de ingreso de los mismos  de manera completa  impedirá el alta en el sitio y uso del mismo.
      </p>
      <p>
        <strong>A.3 Uso del sitio conforme su fin: </strong> No se  garantiza que este sitio esté libre de errores e interrupciones por lo cual Antesala  se exime de cualquier daño derivado de la interrupción del mismo  o de cualquier otro daño generado por la presencia de elementos que impidan una correcta navegación. Antesala es un sitio interactivo y abierto a la comunidad, cuyo fin es vincular al Espectador con cada Productor de evento, el uso del mismo se deberá realizar conforme a los presentes términos y condiciones, por lo cual Antesala, previa notificación al email declarado en el sitio,  se reserva la facultad de suspender tu cuenta (a) si infringes o incumples estos términos; (b) si haces un uso incorrecto o mal uso de los Servicios, o usas los Servicios de una manera no prevista o permitida por Antesala (c) si el acceso y uso de los Servicios infringe cualquier ley, reglamentación y regulación local, estatal, provincial, nacional y de otro tipo, o expondría a Antesala a responsabilidad legal.  (d) Cualquier otra razón o motivo justificado que devenga imposible la continuidad de la relación o uso del sitio.
      </p>
        <p>
        <strong>A. 4 Propiedad Intelectual: </strong> Asimismo se deja expresamente establecido que el  desarrollo, contenido del sitio, software, diseño del sitio y el nombre es de propiedad de Grupo Antesala S.A.S. CUIT 27299632100, queda estrictamente prohibido copiar, utilizar, y/o reproducir  el contenido  de este sitio. Todo el material que se expone en el sitio  está protegido por derechos de autor a favor del Antesala, y en el caso de pertenecer a terceros, se entenderá que Antesala  tiene la debida autorización para utilizarlo como se haya convenido con el autor. La oferta de servicios en este sitio  no se debe interpretar como la concesión de licencia o derecho alguno para usar cualquier marca comercial o fotografía  puesta a disposición en el sitio.
Cada Productor autoriza al administrador  a incluir el nombre de  las mismas y de  cada evento en todas  las publicaciones que se realicen en el sitio y  es responsable por los contenidos que se publicitan en este, y declara tener las autorizaciones correspondientes para la producción y difusión  de cada evento, debiendo mantener indemne a Antesala por cualquier reclamo de terceros vinculado a la propiedad intelectual en relación a los contenidos publicitados en el sitio.
      </p>

      <p>
        <strong>A.5 Aceptación de condiciones,Modificaciones, y Notificaciones:</strong> Antesala se reserva el derecho de modificar en cualquier momento las presentes condiciones de uso, y las políticas de privacidad notificando dichas modificaciones mediante la publicación de las condiciones de uso actualizadas en este sitio web. Al comienzo de estos términos y condiciones podes visualizar la última fecha de actualización. Si cualquier cláusula incluida en las presentes condiciones de uso fuese declarada total o parcialmente, nula o ineficaz, tal nulidad o ineficacia tan sólo afectará a dicha disposición o a la parte de la misma que resulte nula o ineficaz, siendo plenamente aplicables estos términos y condiciones. En caso de no estar de acuerdo con estas condiciones sugerimos abstenerse de utilizar el sitio.
Toda y cualquier notificación podrá llevarse a cabo y será válida cuando se realice al email registrado en el sitio.  El Usuario Espectador y el Usuario Productor aceptan que la aceptación de los términos y condiciones por este medio es plenamente válida. En caso de que se modifique el email registrado en este sitio es tu responsabilidad notificar tal cambio luego de cinco días de efectuado el mismo. 

      </p>

      <h1 className="fs-2">Sección B</h1>
      <h2 className="fs-5">Política de Protección de Datos Personales</h2>

      <p>
        <strong>B.1  Políticas de privacidad y uso de cookies: Normativas que aplicamos sobre los datos y condiciones del tratamiento de la información brindada:</strong>
        Este sitio cumple con lo estipulado en la ley 25. 326 y su decreto reglamentario. Asimismo,  adoptamos todas las medidas de técnicas y organizativas de carácter razonable para proteger la seguridad y confidencialidad de la información que nos compartiste. Podes consultar los  datos del responsable escaneando el código QR que  figura en esta página.
      </p>

      <p>
        <strong>B. 2  Políticas que aplicamos sobre los datos:</strong>Al interactuar  o realizar transacciones en este sitio estás compartiendo tus datos personales,  por lo cual estas  prestando  su consentimiento conforme a lo establecido en la ley 25.326 de Protección de Datos Personales.  Por lo que declaras conocer y aceptar que tus datos personales (nombre completo, documento nacional de identidad, domicilio, teléfono, fecha de nacimiento, género y dirección de correo electrónico) se almacenan  e  integran en  la base de datos de Antesala. Por lo cual, otorgas mediante la aceptación de los presentes términos y condiciones, autorización expresa para: (i) el tratamiento automatizado de dichos datos (ii) su utilización para servicios actuales o futuros que desarrolle Antesala, (iii) uso para fines publicitarios, (iv) compartir tus datos  con cada Productor del evento o cualquier otro tercero, a los fines de que estos puedan verificar tu identidad o proporcionarte el servicio contratado o realizar cualquier otra acción vinculada a la prestación correcta del servicio, incluyendo en esta prestación el uso de datos para fines publicitarios,(v) compartir tus datos de navegación en el sitio con terceros que a través de herramientas de analítica nos brinden servicio para mejorar y optimizar nuestro sitio web  (vi) acceder  a información de geo localización (vii) acceder a información para la  validación de identidad (viii) compartir los datos que sean necesarios con los terceros procesadores del pago. Asimismo mediante la aceptación de las presentes condiciones, nos das tu conformidad para que en caso de sea necesario realizar una modificación relacionada con este punto te notifiquemos la misma al email declarado en el alta del sitio.
      </p>

      <p>
        <strong>B. 3 Publicidad:</strong>Te hacemos saber que con tus datos de navegación, compras y demás interacción con el sitio crearemos un perfil de acuerdo a tus intereses  y gustos. Aceptando estos términos nos estás dando tu consentimiento para que compartamos novedades y te hagamos recomendaciones que podrían ser de tu agrado al email declarado en tu registro. En el caso que no quieras recibir publicidad o desees cambiar el medio, podes escribirnos a antesala.cordoba@gmail.com.
      </p>

      <p>
        <strong>B. 4 ¿Cuánto tiempo guardamos tus datos? </strong> En el caso de los datos personales suministrados para facturación y compra, serán guardados por el tiempo legalmente aplicable. En el caso de los datos personales suministrados para publicidad y envío de información,  será por el tiempo que el titular de los datos desee permanecer en la lista de suscripción, por lo que podrás darte  de baja en el momento que así lo desees, de forma automática  escribiendo a antesala.cordoba@gmail.com.
      </p>


     <p><strong>B. 5  Derecho de acceso:</strong>  Te contamos que como titular de los datos tenés la  facultad de ejercer el derecho de acceso a los mismos en forma gratuita y a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto,  conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. El titular de los datos, tiene además la posibilidad de ejercer los derechos de rectificación, y supresión de los datos conforme artículo 6, inciso “e” de la ley 25.326. Citamos los artículos para una  mejor comprensión:
<strong>Ley 25.326- Artículo 27- inciso 3. — (Archivos, registros o bancos de datos con fines de publicidad). “El titular podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el presente artículo”.
Decreto 1558/01 –Anexo I- Artículo.- 3º párrafo. “En toda comunicación con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del responsable o usuario del banco de datos que proveyó la información.</strong>
Tu derecho de acceso lo podes ejercer escribiendo a antesala.cordoba@gmail.com, te enviaremos una respuesta dentro de los cinco días hábiles a contar de la recepción de la solicitud.</p><p>
La Agencia de la información es el Órgano  de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. </p>
<p>
<strong>B.6  Uso de Cookies

</strong> Las cookies son pequeños fragmentos de información que se alojan en el terminal del usuario visitante del sitio a través de su navegador. Nos reservamos el derecho de utilizar cookies para, entre otros aspectos, facilitar la navegabilidad en nuestro sitio y el acceso más conveniente a la información. El uso de cookies permite  ofrecer un servicio más personalizado dado que esto permite entre otras cosas, ofrecer campañas publicitarias y de promoción, como a la vez medir el número de visitantes y el flujo de tráfico en las secciones del sitio.  Las mismas se guardan en cada navegador, (Internet Explorer, Safari, Chrome, Firefox, etc.). Para comprobar esto puedes entrar en el mismo dominio web y verificar en dos navegadores diferentes, viendo que las preferencias pueden ser diferentes en cada uno, o en la ventana privada dentro del mismo navegador.
Para tu información te contamos que no son cookies los archivos de virus, spam, troyanos, gusanos, spyware, ni programación publicitaria ya sea estática en la página o en formato de ventana emergente (pop-up).
</p>

<h1>Sección C </h1>
<h2>Condiciones de uso para Usuario Espectador</h2>
<strong>
C.1- Registro en el sitio  y compra  de entradas </strong>
<p>
C.1.1 Para realizar una compra: es condición ser mayor de dieciocho años y  que te registres en el sitio para efectuar la misma, podes loguearte con  tu email  personal o generando un registro en el sitio.
C.1.2 Previo a cada compra: Te sugerimos revisar atentamente la descripción  del evento dispuesta por cada Productor, recordá que tenés disponible la opción de navegar en el sitio www.antesala.com.ar,  a los fines de ingresar a la información completa de cada contenido. En la descripción del evento dispondrás de toda la información relativa al evento: como lugar, día, hora, política aplicable a menores y  precio final (incluido el costo del servicio y los impuestos que correspondan, el importe de los incrementos o descuentos que sean de aplicación).  Para la aprobación de la compra de entradas o en su caso del servicio que contrates, tenes que ingresar los datos personales y los  datos del medio de pago utilizado de manera correcta.  Los datos de la fecha, espectáculo y hora  ingresados al momento de la compra, de ser erróneos son imposibles de modificar.
C.1.3 Finalización de la compra: la compra será confirmada en pantalla con un mensaje indicándote tal aprobación y las entradas estarán disponible en tu perfil de usuario espectador a los fines de que descargues las mismas, asimismo serán enviadas al email que indicaste.
</p>
<strong>C.2- Responsabilidad ante Espectadores</strong><p>
Antesala es la administradora del sitio y actúa como una mera  intermediaria en la venta de entradas y/o contenidos, no ejerciendo ningún control ni incidencia sobre las condiciones comerciales que se definan para cada evento. Cada Productor será responsable de la información que publique  y del evento que se llevará a cabo. Por tanto todo reclamo sobre la realización, suspensión, modificación, y/o cancelación  del evento contratado es responsabilidad de cada Productor. Asimismo bajo ninguna circunstancia  Antesala será responsable por cualquier daño o lesiones sufridas por el Espectador o cualquier tercero   en ocasión del evento. En ningún caso Antesala será responsable por daño directo, lucro cesante, o por cualquier otro daño y/o perjuicio que hayan  podido sufrir los Espectadores. Antesala no será responsable por órdenes, instrucciones, solicitudes de procesamiento de Pago y/o pagos equivocados o incompletos causados por la introducción errónea de los datos identificatorios de la cuenta de cada Usuario Espectador/ o del medio de pago utilizados. Cada Espectador reconoce y acepta su total responsabilidad por las pérdidas que se originen, en especial si a través de su nombre se impartieron instrucciones fraudulentas, duplicadas, no autorizadas o meramente erróneas, así como por los eventuales perjuicios se puedan causar-
</p>
<strong>C. 3. Servicio de Atención al Usuario Espectador</strong><p>
A los fines de que puedas realizar tus consultas o enviarnos sugerencias podes escribirnos a antesala.cordoba@gmail.com. También podes canalizar tus dudas a través de las preguntas frecuentes disponibles en el sitio. Asimismo en cada publicación del evento estarán disponibles los datos del Productor para que lo contactes por cualquier consulta. 
</p>

<strong>C.4. Política de Reembolso</strong> <p>
Podes realizar la devolución de las entradas hasta 72 hs antes de la fecha del evento con un costo del 15% sobre el valor de tu ticket. El reintegro del valor se realizará mediante el medio de pago que utilizaste para la compra. Lo podes solicitar escribiendo a: antesala.cordoba@gmail.com. Queda estrictamente prohibida la reventa de entradas.En caso de caso fortuito o fuerza mayor, como por ejemplo la disposición de nuevas medidas en el marco de COVID 19, cancelación o reprogramación del evento, será de exclusiva responsabilidad de cada Productor del evento informarte sobre estas circunstancias. La información será publicada en este sitio, no obstante podrás contactarte escribiendo al email de cada Productor, quien tiene tiene la responsabilidad de comunicar de manera completa y en tiempo toda la información vinculada a las políticas de cancelación. A modo informativo te contamos que la solución debe ser un valor equivalente a la entrada del evento cancelado. </p>

<strong>C.5. Revocación Te contamos que como Usuario Espectador que contrata a través del sitio web www.antesala.com.ar</strong> <p>
 te asiste el derecho de cinco días corridos a partir de la fecha en que recibiste las entradas para efectuar la revocación de la compra. Dado que Antesala actúa como intermediaria y por cuenta y orden de cada Productor esta devolución deberá ser ejercida ante quien organizó y público el evento. Los gastos que implique la misma serán a cargo de cada Espectador.
 </p>

<h1>Sección D</h1>
<h2>Condiciones de uso para Usuario Productor </h2>
<p>
<strong>D.1 Registro y Adhesión de Usuario Productor</strong>
D.1.1.Alta en el sitio: Cada Usuario Productor   que  quiera publicar y promocionar eventos en el sitio deberá registrarse y aceptar los presentes términos y condiciones tildando el casillero disponible al momento del registro. Para darse de alta cada Usuario Productor deberá completar los datos que exige el sitio,  los cuales se considerarán que fueron proporcionados en carácter de declaración jurada, debiendo responder cada Usuario Productor por la veracidad y exactitud de los mismos. La falta de ingreso de los mismos  de manera completa  impedirá el alta en el sitio. Para cargar eventos debes seguir los pasos que indica el sitio, lo podrás hacer una vez que estés dado de alta. Con tu Usuario Productor podrás editar y realizar todas aquellas actividades vinculadas a la correcta publicación del evento.
</p><p>
  
D.1.2 Información de costos y gastos: El costo y comisiones por el uso de este sitio se podrán consultar en este enlace:
<p>
<a href="
https://drive.google.com/drive/folders/1fAaHbGP4-ALjQQ9kFO6KbODvs1ipyIAP">Costos Antesala.com.ar</a></p>
</p><p>
Toda modificación de los mismos se notificará con quince días de anticipación a la entrada en vigencia al email declarado en este sitio.</p>
<p>
<strong>D.1.3 Liquidación: </strong>
Por medio de la aceptación de las presentes condiciones, previo a cada depósito de la liquidación en la cuenta ingresada en el registro, autorizas de manera expresa a Antesala a que retenga  los costos que correspondan según las ventas que se hubieran realizado en el sitio. Además Antesala podrá descontar impuestos que sean exigidos por ley, y cualquier otro gasto que hubiere tenido lugar, como por ejemplo en el caso de que hubiera tenido que realizar algún reembolso. Tenés hasta unos quince días hábiles para revisar la liquidación, a contar desde que fue puesta a disposición en el sitio,  y hacer las observaciones que consideres, pasada esa fecha sin haber realizado observación alguna la misma se tendrá por aceptada.</p>

<p>
<strong>D.2: Tareas a cargo de cada Usuario Productor: </strong>
Es responsabilidad de  cada Productor
</p>
<p>D.2.1: Notificar dentro de cinco días hábiles desde que se produjo cualquier cambio en los datos registrados en el sitio</p>
<p>D.2.2: Contar con todas las habilitaciones correspondientes y tener contratados los seguros necesarios para llevar a cabo las actividades. </p>
<p>D.2.3: Informar de manera precisa y detallada la descripción del evento y desarrollar los contenidos de cada evento de acuerdo a las condiciones ofrecidas en la publicidad del evento. </p>
<p>
D.2.4: Respetar en todos los términos la política de  suspensión y cancelación del evento 
de acuerdo a lo expresado en el apartado cinco y las políticas de privacidad y uso de cookies conforme lo detallado en el apartado sexto.</p>
<p>
D.2.5: Aplicar e informar la política de menores en cada sala
D.2.6: Contar con espacio para discapacidad y respetar las condiciones establecidas en la ley 3546 y cualquier otra que en un futuro se cree en relación a la participación de personas con discapacidad en Espectáculos Públicos. </p>
<p>D.3: Tareas a cargo de Antesala</p>
<p>D.3.1: Depositar el monto de la liquidación  en tiempo y forma a la cuenta indicada, conforme lo establecido en el punto 1.3</p>
<p>
D.3.2: Informar los costos de gestión  en el sitio y sus incrementos o nuevos cargos con quince días de anticipación a la  entrada en vigencia de los mismos. Estos se tendrán por aceptados con la nueva publicación de eventos que realices en el sitio. Asimismo este incremento estará modificados en el anexo que te informamos los costos.</p>
<p>D.4: Responsabilidad </p>
<p>D.4.1: Cada Productor   del evento es responsable de todas las condiciones comerciales sobre el evento publicado, por lo cual se compromete a mantener indemne al administrador del sitio frente a cualquier reclamo que tenga su origen en el incumplimiento de  algunas de las condiciones del evento, o que tengan lugar por la modificación, cancelación, suspensión del mismo.</p>
<p>D.4.2: Bajo ninguna circunstancia  Antesala será responsable por cualquier daño o lesiones sufridas por cada Espectador  o cualquier tercero   en ocasión del evento. En ningún caso Antesala será responsable por daño directo, lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir cada Espectador.</p>
<p>D.4.3: En el caso de que sea demandada o codemandada, Antesala   tendrá derecho a elegir la estrategia de su defensa,y deberá ser reembolsada de todos los gastos que tal defensa hubiera generado. Asimismo en caso de que haya  importes para rendir,   Antesala queda autorizada expresamente  mediante la presente para retener lo  que le correspondan en conceptos de los gastos en que  hubiera incurrido por solventar tales defensas. </p>
<p>D.4.4: Antesala no será responsable por órdenes, instrucciones, Solicitudes de Procesamiento de Pago y/o pagos equivocados o incompletos causados por la introducción errónea de los datos identificatorios de la cuenta del Usuario Espectador.</p>
<p>D.4.5: Antesala no garantiza las condiciones y el contenido de cada evento.  En el caso de eventos transmitidos vía streaming, cada Productor deberá garantizar las condiciones de reproducción de los mismos, bajo ninguna circunstancia Antesala será responsable por una transmisión deficiente o de baja calidad.</p>
<p>D.5 Políticas de Cancelación: 
Somos conscientes de que las políticas de reembolsos varían en función del tipo de evento y de cada Productor. Por ese motivo, ofrecemos flexibilidad a los Productores para publicar sus propias políticas respecto de sus eventos, siempre y cuando cumplan con los siguientes requisitos mínimos:(a)Garantizar una solución mediante la cual se hagan cargo de sus obligaciones ante cada Espectador, (b) La solución debe ser un valor equivalente a la entrada del evento cancelado,(c) Cada Productor tiene la responsabilidad de comunicar de manera completa y en tiempo toda la información vinculada a las políticas de cancelación.</p>
<p>Asimismo si por alguna razón el Usuario Productor  no cumple con un crédito u otra solución, Antesala tendrá derecho a evaluar y procurar una solución a los consumidores afectados.</p>
<p>D. 6 Baja de la cuenta del Usuario Productor: Antesala se reserva la facultad de dar de baja el registro del Usuario Productor, sin notificación previa,  en caso de verificar el  incumplimiento de algunas de las presentes condiciones.</p>


<p><strong>Legislación aplicable y Fuero</strong>
Jurisdicción. Para la resolución de cualquier controversia vinculada a estas condiciones, las Partes se someten a la jurisdicción de los tribunales ordinarios correspondientes a la ciudad de Córdoba o la jurisdicción correspondiente al domicilio cada espectador según corresponda. Ley Aplicable: estas condiciones  están regidas  en todos sus puntos por las leyes vigentes en la Argentina.</p>




    </div>
  )
}

export default TYP