import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Geolocation from "./geolocation/geolocation";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import AlertWarning from "../../../../Alerts/AlertWarning";
import {
  GeolocationIp,
  GeolocationStack,
  ProvinciasActualizar,
  ProvinciasCrear,
} from "../../../../helpers/GeolocationHelper";
import {
  AdministradorCategoriaActualizar,
  AdministradorCategoriaCrear,
} from "../../../../helpers/CategoriaHelper";
import {
  AdministradorActualizarCategoriasContenido,
  AdministradorCrearCategoriasContenido,
} from "../../../../helpers/CategoriasContenidoHelper";
import CardImagen from "../../Card/CardImagen";
import {
  AdministradorImagenCrear,
  AdministradorImagenesActualizar,
} from "../../../../helpers/ImagenesHelper";
import CardGaleria from "./cards/CardGaleria";
import Galeria from "./secciones/galeria/Galeria";

const Map = () => {
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = useMemo(() => ({ lat: 44, lng: -80 }), []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={10}
      center={center}
      mapContainerClassName="map-container"
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

const Configuracion = () => {
  const { user } = useContext(authContext);
  const { data, rule, ruleUser, auth } = user;

  const context = useContext(CollectionStateContext);
  const {
    geolocationApi,
    provincias,
    administradorGaleriaImagenes,
    administradorCategoriasContenido,
    obtenerCategorias,
    state,
  } = context;

  //STATE HELPERS

  const [searchMap, setSearchMap] = useState("");
  const [geolocationIp, setGeolocationIp] = useState(null);
  const [geolocationEstack, setGeolocationApi] = useState(null);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [geo, setGeo] = useState({
    latitud: "",
    longitud: "",
    loads: false,
  });

  //QUERY STATES

  let apiProvincias = localStorage.getItem("apiProvincias");
  let localStorageProvincias = localStorage.getItem("provincias");
  const [dataProvincias, setProvincias] = useState(
    localStorageProvincias
      ? JSON.parse(localStorageProvincias)
      : state.provincias
  );
  const [dataGeolocation, setGeolocation] = useState(
    apiProvincias ? JSON.parse(apiProvincias) : state.apiProvincias
  );
  const [dataCategorias, setCategorias] = useState(state.categorias);
  const [dataCategoriasContenido, setCategoriasContenido] = useState(
    state.categoriasContenido
  );
  const [dataImagenes, setImagenes] = useState();


  //FORM

  const [form, setForm] = useState({
    //PROVINCIA
    select_provincia: "",
    //CATEGORIAS
    nombre_categoria: "",
    nombre_categoria_contenido: "",
    //IMAGENES
    state_add_imagen: false,
    state_edit_imagen: false,
    nombre_imagen: "",
    imagen: "",
    posicion_imagen: "CARUSEL",
    provincia_imagen: "",
    stated_imagen: "ACTIVO",
    imagen_url: "",
    url: "",
    descripcion: "",
    archivo: "",

    edit_descripcion: "",
    edit_archivo: "",
    edit_nombre_imagen: "",
    edit_imagen: "",
    edit_posicion_imagen: "",
    edit_provincia_imagen: "",
    edit_stated_imagen: "",
    edit_url: "",
  });

  //FILTER

  const [filter_categorias, setFilterCategorias] = useState({
    nombre: "",
    fecha: "",
    url: "/api/auth/Administrador/Categorias",
  });

  const [filter_categoria, setFilterCategoria] = useState({
    nombre: "",
    fecha: "",
    url: "/api/auth/Administrador/CategoriasContenido",
  });

  const [filter_imagenes, setFilterImagenes] = useState({
    id: "",
    provincia: "",
    stated: "",
    url: "/api/auth/Administrador/Galeria",
  });

  //BUTTON EVENTS

  const onChange = (e) => {
    switch (e.target.name) {
      case "select_provincia":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "nombre_categoria":
      case "nombre_categoria_contenido":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //MAPS
      case "searchMap":
        {
          setSearchMap(encodeURI(e.target.value));
        }
      break; 
      }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "addProvincia":
        {
          if (form.select_provincia.trim() == "") {
            return AlertWarning(
              "No selecciono ninguna provincia",
              "Advertencia"
            );
          }

          let dataForm = new FormData();

          dataForm.append("nombre", form.select_provincia);

          let response = await ProvinciasCrear(dataForm, setLoading);
          setSteps(steps + 1);
        }
        break;
      case "updatedProvincia":
        {
          if (e.target.checked) {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "ACTIVO");

            let response = await ProvinciasActualizar(dataForm, setLoading);
            setSteps(steps + 1);
          } else {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "INACTIVO");

            let response = await ProvinciasActualizar(dataForm, setLoading);
            setSteps(steps + 1);
          }
        }
        break;
      //CATEGORIAS
      case "addCategoriaContenido":
        {
          if (form.nombre_categoria_contenido.trim() == "") {
            return AlertWarning(
              "Escribe en el campo el nombre de tu categoria",
              "Advertencia"
            );
          }

          let dataForm = new FormData();
          dataForm.append("nombre", form.nombre_categoria_contenido);

          let response = await AdministradorCrearCategoriasContenido(
            dataForm,
            setLoading
          );
          setSteps(steps + 1);
        }
        break;
      case "updatedCategoriaContenido":
        {
          if (e.target.checked) {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "ACTIVO");

            let response = await AdministradorActualizarCategoriasContenido(
              dataForm,
              setLoading
            );
            setSteps(steps + 1);
          } else {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "INACTIVO");

            let response = await AdministradorActualizarCategoriasContenido(
              dataForm,
              setLoading
            );
            setSteps(steps + 1);
          }
        }
        break;
      case "addCategoria":
        {
          if (form.nombre_categoria.trim() == "") {
            return AlertWarning(
              "Escribe en el campo el nombre de tu categoria",
              "Advertencia"
            );
          }

          let dataForm = new FormData();
          dataForm.append("nombre", form.nombre_categoria);

          let response = await AdministradorCategoriaCrear(
            dataForm,
            setLoading
          );
          setSteps(steps + 1);
        }
        break;
      case "updatedCategoria":
        {
          if (e.target.checked) {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "ACTIVO");

            let response = await AdministradorCategoriaActualizar(
              dataForm,
              setLoading
            );
            setSteps(steps + 1);
          } else {
            let dataForm = new FormData();
            dataForm.append("id", value);
            dataForm.append("stated", "INACTIVO");

            let response = await AdministradorCategoriaActualizar(
              dataForm,
              setLoading
            );
            setSteps(steps + 1);
          }
        }
        break;
      //IMAGEN
      case "state_add_imagen":
        {
          if (form.state_add_imagen) {
            setForm({
              ...form,
              [e.target.name]: false,
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: true,
            });
          }
        }
        break;
      case "addImagen": {
        if (
          form.nombre_imagen.trim() == "" ||
          form.imagen == "" ||
          form.provincia_imagen.trim() == "" ||
          form.posicion_imagen.trim() == "" ||
          form.stated_imagen.trim() == ""
        ) {
          return AlertWarning(
            "Todos los campos deben ser completados",
            "Advertencia"
          );
        }

        let dataForm = new FormData();

        dataForm.append("nombre", form.nombre_imagen);
        dataForm.append("provincia", form.provincia_imagen);
        dataForm.append("imagen", form.imagen);
        dataForm.append("posicion", form.posicion_imagen);
        dataForm.append("stated", form.stated_imagen);
        //
        if (form.descripcion.trim() !== "") {
          dataForm.append("descripcion", form.descripcion);
        }

        if (form.archivo !== "") {
          dataForm.append("archivo", form.archivo);
        }

        if (form.url.trim() !== "") {
          dataForm.append("url", form.url);
        }

        let response = await AdministradorImagenCrear(dataForm, setLoading);
        setSteps(steps + 1);
        //Reset form
        setForm({
          ...form,
          //PROVINCIA
          select_provincia: "",
          //CATEGORIAS
          nombre_categoria: "",
          nombre_categoria_contenido: "",
          //IMAGENES
          state_add_imagen: false,
          state_edit_imagen: false,
          nombre_imagen: "",
          imagen: "",
          posicion_imagen: "CARUSEL",
          provincia_imagen: "",
          stated_imagen: "ACTIVO",
          imagen_url: "",
          url: "",
          descripcion: "",
          archivo: "",

          edit_descripcion: "",
          edit_archivo: "",
          edit_nombre_imagen: "",
          edit_imagen: "",
          edit_posicion_imagen: "",
          edit_provincia_imagen: "",
          edit_stated_imagen: "",
          edit_url: "",
        });
      }
      case "state_edit_imagen":
        {
          if (form.state_add_imagen) {
            setForm({
              ...form,
              [e.target.name]: false,
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: value,
            });
          }
        }
        break;
      case "editImagen":
        {
          let dataForm = new FormData();

          dataForm.append("id", value);

          if (form.edit_imagen !== "") {
            dataForm.append("imagen", form.edit_imagen);
          }

          if (form.edit_nombre_imagen.trim() !== "") {
            dataForm.append("nombre", form.edit_nombre_imagen);
          }

          if (form.edit_url.trim() !== "") {
            dataForm.append("url", form.edit_url);
          }

          if (form.edit_provincia_imagen.trim() !== "") {
            dataForm.append("provincia", form.edit_provincia_imagen);
          }

          if (form.edit_posicion_imagen.trim() !== "") {
            dataForm.append("posicion", form.edit_posicion_imagen);
          }

          if (form.edit_stated_imagen.trim() !== "") {
            dataForm.append("stated", form.edit_stated_imagen);
          }

          if (form.edit_archivo !== "") {
            dataForm.append("archivo", form.edit_archivo);
          }

          if (form.edit_descripcion.trim() !== "") {
            dataForm.append("descripcion", form.edit_descripcion);
          }

          let response = await AdministradorImagenesActualizar(
            dataForm,
            setLoading
          );
          setSteps(steps + 1);
          //Reset form
          setForm({
            ...form,
            //PROVINCIA
            select_provincia: "",
            //CATEGORIAS
            nombre_categoria: "",
            nombre_categoria_contenido: "",
            //IMAGENES
            state_add_imagen: false,
            state_edit_imagen: false,
            nombre_imagen: "",
            imagen: "",
            posicion_imagen: "CARUSEL",
            provincia_imagen: "",
            stated_imagen: "ACTIVO",
            imagen_url: "",
            url: "",
            descripcion: "",
            archivo: "",

            edit_descripcion: "",
            edit_archivo: "",
            edit_nombre_imagen: "",
            edit_imagen: "",
            edit_posicion_imagen: "",
            edit_provincia_imagen: "",
            edit_stated_imagen: "",
            edit_url: "",
          });
        }
        break;
    }
  };

  useEffect(() => {
    const axios = async () => {
      const response = await GeolocationIp();

      setGeolocationIp(response);
    };
    axios();
  }, []);

  useEffect(() => {
    if (!dataGeolocation) {
      setTimeout(
        geolocationApi(
          setGeolocation,
          "https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre"
        ),
        5000
      );
    }
  }, []);

  useEffect(() => {
    obtenerCategorias(setCategorias, filter_categorias);
    administradorCategoriasContenido(setCategoriasContenido, filter_categoria);
  }, [steps]);

  useEffect(() => {
    provincias(setProvincias);
  }, [steps]);

  useEffect(() => {
    administradorGaleriaImagenes(setImagenes, filter_imagenes);
  }, [steps]);

  useEffect(() => {
    const axios = async () => {
      if (geo.loads) {
        let coordinates = geo.latitud + "," + geo.longitud;

        const response = await GeolocationStack(coordinates);
        setGeolocationApi(response.data[0]);
      }
    };
    axios();
  }, [geo]);
  return (
    <div
      className="row container-md mx-md-auto p-0 m-0 border-0 texto-normal"
      style={{ maxWidth: "1200px" }}
    >
      <div className="col-md-12 mb-4">
        <h1>Configuraciones</h1>
        <span className="text-muted">
          Configura las provincias habilitadas, agrega y edita categorias.
          Tambien puedes customizar la galeria de imagenes.
        </span>
      </div>

      <div className="col-md-12 py-1 mb-4">
        <div className="row">
          <div className="col-md-12 col-12 mb-3">
            <div className="mb-3 fs-4 py-2 fw-bold border-0">
              Geolocalización
              <span className="d-block fs-8 fw-normal text-muted">
                Prueba la Geolocalización en tiempo real!
              </span>
            </div>
            <div className="mb-2">
              {geo.loads ? (
                <ul class="list-group">
                  <li className="list-group-item border-0 p-0 d-none">
                    <label htmlFor="">Busqueda en tiempo real</label>
                    <input
                      className="form-control border-custom-naranja rounded-pill"
                      type="text"
                      name="searchMap"
                      onChange={(event) => onChange(event)}
                    />
                  </li>
                  <li className="list-group-item border-0 p-0 d-none">
                    <iframe
                      src={
                        "https://maps.google.com/?q=" +
                        searchMap +
                        "&z=14&t=m&output=embed"
                      }
                      width="100%"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      className="my-3"
                    ></iframe>
                  </li>

                  <li class="list-group-item text-muted border-0 p-0">
                    Coordenadas de mi ubicación actual
                    <span className="fw-bold">Latitud {geo.latitud}</span>{" "}
                    <span className="fw-bold">Longitud {geo.longitud}</span>
                  </li>
                  <li className="list-group-item border-0 p-0">
                    <iframe
                      src={
                        "https://maps.google.com/?ll=" +
                        geo.latitud +
                        "," +
                        geo.longitud +
                        "&z=14&t=m&output=embed"
                      }
                      width="100%"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      className="my-3"
                    ></iframe>
                  </li>
                </ul>
              ) : (
                <Geolocation setGeo={setGeo} />
              )}
            </div>
            <div className="mb-2">
              <span className="fw-bold d-block">
                Direccion geolocalizada ApiRest
              </span>
              {geolocationEstack ? (
                <ul class="list-group">
                  <li class="list-group-item text-muted border-0">
                    Region{" "}
                    <span className="fw-bold">{geolocationEstack.region}</span>
                  </li>
                  <li class="list-group-item text-muted border-0">
                    País{" "}
                    <span className="fw-bold">{geolocationEstack.country}</span>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="mb-2">
              <span className="fw-bold d-block">
                Direccion geolocalizada Ip Global
              </span>
              {geolocationIp ? (
                <ul class="list-group">
                  <li class="list-group-item text-muted border-0">
                    Region{" "}
                    <span className="fw-bold">{geolocationIp.regionName}</span>
                  </li>
                  <li class="list-group-item text-muted border-0">
                    País{" "}
                    <span className="fw-bold">{geolocationIp.country}</span>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <div className="mb-3 fs-4 py-2 fw-bold border-0">
              Provincias habilitadas
              <span className="d-block fs-8 fw-normal text-muted">
                Agrega y edita la habilitacion de las provincias para que el
                filtro se aplique en los eventos.
              </span>
            </div>
            <div className="mb-3">
              <ul className="list-group">
                {dataProvincias
                  ? dataProvincias.map((provincia) => (
                      <li className="list-group-item border-0 d-flex" 
                      style={{ borderRadius: "10px" }}>
                        {provincia.nombre}
                        {provincia.stated == "ACTIVO" ? (
                          <i className="mx-3 bi text-success bi-check-circle"></i>
                        ) : provincia.stated == "INACTIVO" ? (
                          <i class="mx-3 text-danger bi bi-x-circle"></i>
                        ) : (
                          ""
                        )}
                        <div className=" form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            name="updatedProvincia"
                            onChange={(event) => onClick(event, provincia.id)}
                            checked={
                              provincia.stated == "ACTIVO" ? true : false
                            }
                          />
                        </div>
                      </li>
                    ))
                  : ""}

                <li className="list-group-item border-0">
                  <div class="input-group mb-3">
                    <button
                      class="input-group-text rounded-pill btn btn-custom-naranja text-white rounded-end fs-7"
                      id="basic-addon1"
                      name="addProvincia"
                      onClick={(event) => onClick(event)}
                    >
                      Agregar provincia
                    </button>
                    <select
                      class="form-select fs-7"
                      aria-label="Default select example"
                      name="select_provincia"
                      onChange={(event) => onChange(event)}
                    >
                      <option selected>{form.select_provincia}</option>
                      {dataGeolocation
                        ? dataGeolocation.map((provincia) => (
                            <option value={provincia.nombre}>
                              {provincia.nombre}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 fs-4 py-2 fw-bold border-0">
                  Categorias Eventos
                  <span className="d-block fs-8 fw-normal text-muted">
                    Modifica tus categorias, y habilita para que aparezcan en
                    los editores de eventos, filtros y más
                  </span>
                </div>
                <div className="mb-3">
                  <ul className="list-group">
                    {dataCategorias
                      ? dataCategorias.map((categoria) => (
                          <li className="list-group-item border-0 d-flex" 
                          style={{ borderRadius: "10px" }}>
                            {categoria.nombre}
                            {categoria.stated == "ACTIVO" ? (
                              <i className="mx-3 bi text-success bi-check-circle"></i>
                            ) : categoria.stated == "INACTIVO" ? (
                              <i class="mx-3 text-danger bi bi-x-circle"></i>
                            ) : (
                              ""
                            )}

                            <div className=" form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                name="updatedCategoria"
                                onChange={(event) =>
                                  onClick(event, categoria.id)
                                }
                                checked={
                                  categoria.stated == "ACTIVO" ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))
                      : ""}

                    <li className="list-group-item border-0">
                      <div class="input-group mb-3">
                        <button
                          class="input-group-text rounded-pill btn btn-custom-naranja text-white rounded-end fs-7"
                          id="basic-addon1"
                          name="addCategoria"
                          onClick={(event) => onClick(event)}
                        >
                          Agregar categoria
                        </button>
                        <input
                          className="form-control"
                          type="text"
                          name="nombre_categoria"
                          onChange={(event) => onChange(event)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 fs-4 py-2 fw-bold border-0">
                  Categorias Contenidos
                  <span className="d-block fs-8 fw-normal text-muted">
                    Modifica tus categorias, y habilita para que aparezcan en
                    los editores de contenidos, filtros y más
                  </span>
                </div>
                <div className="mb-3">
                  <ul className="list-group" 
        style={{ borderRadius: "10px" }}>
                    {dataCategoriasContenido
                      ? dataCategoriasContenido.map((categoria) => (
                          <li className="list-group-item border-0 d-flex">
                            {categoria.nombre}
                            {categoria.stated == "ACTIVO" ? (
                              <i className="mx-3 bi text-success bi-check-circle"></i>
                            ) : categoria.stated == "INACTIVO" ? (
                              <i class="mx-3 text-danger bi bi-x-circle"></i>
                            ) : (
                              ""
                            )}

                            <div className=" form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                name="updatedCategoriaContenido"
                                onChange={(event) =>
                                  onClick(event, categoria.id)
                                }
                                checked={
                                  categoria.stated == "ACTIVO" ? true : false
                                }
                              />
                            </div>
                          </li>
                        ))
                      : ""}

                    <li className="list-group-item border-0">
                      <div class="input-group mb-3">
                        <button
                          class="input-group-text rounded-pill btn btn-custom-naranja text-white rounded-end fs-7"
                          id="basic-addon1"
                          name="addCategoriaContenido"
                          onClick={(event) => onClick(event)}
                        >
                          Agregar categoria
                        </button>
                        <input
                          className="form-control"
                          type="text"
                          name="nombre_categoria_contenido"
                          onChange={(event) => onChange(event)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

                                
        </div>
      </div>

      {
        dataImagenes
        ? <Galeria 
        provincias={dataProvincias}
        data={dataImagenes}
        steps={steps}
        setSteps={setSteps}
      />
        : <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      }
      
    </div>
  );
};

export default Configuracion;
