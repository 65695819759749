export const URLs = {
  /* Auth Controller */
  URL_LOGIN: "login",
  URL_ME: "auth/me",

  
  /* Event Controller */
  URL_EVENTO_STORE: "eventos",
  URL_EVENTO_SHOW: "eventos/",
  URL_EVENTO_UPDATE: "eventos",
  URL_EVENTO_INDEX: "eventos",

  URL_ENTRADA_UPDATE: "entradas/",
  URL_LIQUIDACION_UPDATE: "liquidaciones/",
  
  URL_CATEGORIAS_INDEX: "categorias",
  URL_CATEGORIAS_STORE: "categorias",
  URL_SALA_SEARCH: "salas",
  URL_PROVINCIA_INDEX: "provincias",

  /* Nota Controller */
  URL_NOTA_STORE: "notas",
  URL_NOTA_SHOW: "notas/",
  URL_NOTA_UPDATE: "notas",
  URL_NOTA_INDEX: "notas",

  /* Membresias */
  URL_MEMBRESIA: "membresias",
  URL_MEMBRESIA_SHOW: "membresias/"
}