import React from "react";
import { geolocated } from "react-geolocated";

class Demo extends React.Component {
  
  render() {

    if (this.props.isGeolocationAvailable) {
      if (this.props.isGeolocationEnabled) {
        if (this.props.coords) {
          console.log(this.props.coords.latitude);
          this.props.setGeo({
            latitud: this.props.coords.latitude,
            longitud: this.props.coords.longitude,
            loads: true
          });
        } else {
      console.log("No hay datos de geolocacion");
        }
      } else {
      console.log("La geolocacion no esta activada");
      }
    } else {
      console.log("Tu navegador no soporta geolocation");
    }

    return ""
   
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  watchPosition: false,
  userDecisionTimeout: 5000,
})(Demo);
