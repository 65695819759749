export const attachNota = (data, setForm) => {
  console.log(data);

  let categorias = [];

  data.categorias.map((c, index) => {
    categorias.push(c.id)
  })

  let input = {
    id: [data.id, ''],
    imagen: ["", "required"],
    imagen_url: ["https://antesala.com.ar/assets/img/notas/"+data.imagen, "required"],
    titulo: [data.titulo, "required"],
    descripcion: [data.descripcion, "required"],
    contenido: [data.contenido, "required"],
    autor: [data.autor, "required"],
    provincia: [data.provincia, "required"],
    //localidad: ["", "required"],
    //url_youtube: ["", ""],
    categorias: [categorias, "required"],
    status_id: [data.status_id, ''],
  }

  return setForm(input)
}