const Card = ({ data, onClick }) => {
  const {
    autor,
    contenido,
    created_at,
    updated_at,
    descripcion,
    id,
    imagen,
    localidad,
    provincia,
    stated,
    status_id,
    titulo,
    usuario_id,
    visitas,
    usuarios,
    categorias,
  } = data;

  return (
    <div
      className="col-md-12  p-0 m-0 mb-3 bg-white hover-card border border-light"
      style={{ borderRadius: "10px" }}
    >
      <div className="row bg-transparent w-100 m-0 p-0">
        <div className="col-md-3  border-0 p-0 m-0 d-flex jusitfy-content-center align-items-center">
          <div
            className="w-100 border-0 d-flex justify-content-center align-items-center overflow-hidden"
            style={{ height: "200px" }}
          >
            <img
              src={"https://antesala.com.ar/assets/img/notas/" + imagen}
              className="img-fluid text-center"
              alt="IMAGEN NO ENCONTRADA"
            />
          </div>
        </div>
        <div className="col-md-9  p-0 m-0 ">
          <ul className="list-group">
            <li className="list-group-item bg-transparent d-flex justify-content-between border-0 mb-0 pb-0 ">
              <div className="mt-2">{titulo}</div>
              <div className="d-none">
                <a
                  href={"/ticket/" + id}
                  className="btn btn-custom-black text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 mt-2 px-2 py-1 fs-10"
                >
                  <i className="fs-8 bi bi-eye "></i> Vista Previa
                </a>
              </div>
            </li>
            <li className="list-group-item bg-transparent m-0 py-0 border-0">
              <span className="text-muted fs-10">{descripcion}</span>
            </li>
            <li className="list-group-item bg-transparent m-0 py-0 border-0">
              <span className="text-muted fs-10">Autor</span>
              <span className="fs-10"> {autor}</span>
            </li>
            <li className="list-group-item bg-transparent m-0 py-0 border-0">
              <span className="text-muted fs-10">Responsable</span>
              <span className="fs-10">
                {" "}
                {usuarios.nombre + " " + usuarios.apellido}
              </span>
              <span className="text-muted fs-10"> Email</span>
              <span className="fs-10"> {usuarios.email}</span>
            </li>
            <li className="list-group-item bg-transparent border-0">
              {categorias.map((c) => (
                <span className="btn btn-dark p-0 px-2 mx-1 rounded-1 fs-10">
                  {c.nombre}
                </span>
              ))}
            </li>
            <li className="list-group-item bg-transparent border-0 d-flex justify-content-between">
              <div className="d-flex">
                <span className="text-muted fs-10 me-2 d-none">
                  Comentarios <p className="text-muted fw-bold"></p>
                </span>
                <span className="text-muted fs-10 me-2">
                  Visitas totales{" "}
                  <p className="text-muted fw-bold">{visitas}</p>
                </span>
              </div>
              <div className="d-flex">
                <div>
                  <label className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
                    <i className="bi bi-pencil"></i> Editar
                    <button
                      className="d-none"
                      name="editar"
                      onClick={(event) => onClick(event, data.id)}
                    ></button>
                  </label>
                </div>

                <div className="dropdown">
                  <button
                    className="btn btn-custom-naranja text-white fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado...
                  </button>
                  <ul
                    className="dropdown-menu fs-8"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {status_id == 1 ? (
                      ""
                    ) : (
                      <li>
                        <label
                          class="dropdown-item text-success btn btn-outline-dark border-0"
                          href="#"
                        >
                          Publicar
                          <i class="bi bi-check-all"></i>
                          <button
                            className="d-none"
                            name="status"
                            onClick={(event) =>
                              onClick(event, { id: id, status: 1 })
                            }
                          ></button>
                        </label>
                      </li>
                    )}
                    {status_id == 2 ? (
                      ""
                    ) : (
                      <li>
                        <label
                          class="dropdown-item text-info btn btn-outline-dark border-0"
                          href="#"
                        >
                          Borrador
                          <i class="ms-2 bi bi-recycle"></i>
                          <button
                            className="d-none"
                            name="status"
                            onClick={(event) =>
                              onClick(event, { id: id, status: 2 })
                            }
                          ></button>
                        </label>
                      </li>
                    )}
                    {status_id == 3 ? (
                      ""
                    ) : (
                      <li>
                        <label
                          class="dropdown-item text-danger btn btn-outline-dark border-0"
                          href="#"
                        >
                          Eliminado
                          <i class="ms-2 bi bi-trash"></i>
                          <button
                            className="d-none"
                            name="status"
                            onClick={(event) =>
                              onClick(event, { id: id, status: 3 })
                            }
                          ></button>
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
            <li className="list-group-item bg-transparent border-0 d-flex justify-content-between border-top">
              <div className="d-flex d-none">
                <span className="text-muted fs-10 me-2 ">
                  Fecha de creación
                </span>
                <span className="fs-10 fw-bold me-2">{created_at}</span>
              </div>
              <div className="d-flex">
                {status_id == 1 ? (
                  <span className="text-success">
                    Publicado
                    <i class="bi bi-check-all"></i>
                  </span>
                ) : status_id == 2 ? (
                  <span className="text-info">
                    Borrador
                    <i class="ms-2 bi bi-recycle"></i>
                  </span>
                ) : status_id == 3 ? (
                  <span className="text-danger">
                    Eliminado
                    <i class="ms-2 bi bi-trash"></i>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Card;
