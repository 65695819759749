import { useEffect, useState } from "react";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Inicio from "../page/Inicio";
import Nota from "../components/notas/Nota";
import Video from "../components/videos/Video";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import apiAntesala from "../../config/ConfigPublic";

const InicioController = () => {
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection1",
    },
  ]);

  const commission = 15;
  const [eventosRecomendados, setEventosRecomendados] = useState(null);
  const [eventosDescuento, setEventosDescuento] = useState(null);
  const [notas, setNotas] = useState(null);
  const [videos, setVideos] = useState(null);
  const [banners, setBanners] = useState(null);

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, params);
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };
  
  /*
  useEffect(() => {
    if (!eventosRecomendados) {
      return fetch(setEventosRecomendados, {}, "/eventos");
    }
  }, []);
  useEffect(() => {
    if (!eventosDescuento) {
      return fetch(setEventosDescuento, {}, "/eventos");
    }
  }, []);
  useEffect(() => {
    if (!notas) {
      return fetch(setNotas, {}, "/notas");
    }
  }, []);
  useEffect(() => {
    if (!videos) {
      return fetch(setVideos, {}, "/videos");
    }
  }, []);
  useEffect(() => {
    if (!banners) {
      return fetch(setBanners, {}, "/banners");
    }
  }, []);*/

  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <div className="container-md min-vh-100">
        <Inicio
          CalendarProps={{ days, months, locale, state, setState }}
          eventosRecomendados={eventosRecomendados ?? []}
          eventosDescuento={eventosDescuento ?? []}
          banners={banners?.data ?? []}
        />
      </div>

      <section id="podcast" class="pb-5 pt-5 mb-5 ">
        <div class="container">
          <div className="">
            <h2
              className="text-dark border-0 text-center mx-auto"
              style={{ width: "300px", borderRadius: "0px 20px 20px 20px" }}
            >
              Notas
            </h2>
          </div>
          <div className="border-0 w-100 w-carousel-home">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 3,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 3,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {[1, 2, 3, 4, 5].map(() => (
                <div class="item p-1">
                  <Nota />
                </div>
              ))}
            </Carousel>
          </div>

          <div className="w-100 d-flex justify-content-center my-5">
            <label
              className="btn btn-outline-dark"
              style={{ borderRadius: "20px" }}
            >
              Ver todas las notas
              <button className="d-none"></button>
            </label>
          </div>
        </div>
      </section>

      <section id="podcast" class="pb-5 pt-5 mb-5  w-100">
        <div className="text-white d-flex justify-content-center align-items-center px-0 mx-0">
          <h2
            className="text-dark border-0 text-center mx-auto"
            style={{ width: "300px", borderRadius: "0px 20px 20px 20px" }}
          >
            Videos
          </h2>
        </div>
        <div class="container d-flex">
          <div className="w-100 w-carousel-home">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 4,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
            >
              {[1, 2, 3, 4, 5].map(() => (
                <div class="item p-1">
                  {" "}
                  <Video />
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="w-100 d-flex justify-content-center my-5">
          <label
            className="btn btn-outline-dark"
            style={{ borderRadius: "20px" }}
          >
            Ver todos los videos
            <button className="d-none"></button>
          </label>
        </div>
      </section>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default InicioController;
