import { TYPES } from "../actions/ActionsStates";

export const StateInitialState = {
  //API HOME
  eventos: null,
  evento: null,
  provincias: null,
  imagenes: null,

  salas: [],
  sala: null,
  notas: null,
  videos: null,
  podcast: null,

  //API GEOLOCATION
  ApiProvincias: null,

  //API USER EVENTS 
  usuarioEventos: null,
  selectEvent: null,

  //API RESOURCE
  datoFiscal: [],
  categorias: null,
  categoriasContenido: null,

  //API ADMINISTRATOR
  salasAdm: null,
  eventosAdm: null,
  producersAdm: null,
  espectatorAdm: null,
  notasAdm: null,
  videosAdm: null,
  podcastAdm: null,
  categoriasContenidoAdm: null,
  galeriaImagenes: null
}

export function stateReducer(state, action){
  switch (action.type) {
    case TYPES.READ_ALL_CATEGORIAS:{
      return {...state,
        categorias: action.value}
    }
    break;
    case TYPES.SELECT_EVENT:{
      state.selectEvent = action.value
      return state
    }
    break;
    case TYPES.READ_ALL_EVENTS:{
      return {...state,
        eventos: action.value}
    }
    break;
    case TYPES.READ_ALL_MY_EVENTS:{
      return {...state,
        usuarioEventos: action.value}
    }
    break;
    case TYPES.READ_ALL_DATO_FISCAL:{
      return {...state,
       datoFiscal: action.value}
    }
    break;
    case TYPES.READ_ALL_SALAS:{
      return {...state,
       salas: action.value}
    }
    break;
    case TYPES.READ_ALL_PROVINCIAS:{
      return {...state,
       provincias: action.value}
    }
    break; 
    case TYPES.READ_APIPROVINCIAS:{
      return {...state,
       apiProvincias: action.value}
    }
    break;
    case TYPES.READ_ALL_NOTAS:{
      return{
        ...state,
        notas:action.value
      }
    }
    break;
    case TYPES.READ_ALL_PODCAST:{
      return{
        ...state,
        podcast:action.value
      }
    }
    break;
    case TYPES.READ_ALL_VIDEOS:{
      return{
        ...state,
        videos:action.value
      }
    }
    break;
    case TYPES.READ_ALL_GALERIA_IMAGENES:{
      return{
        ...state,
        imagenes: action.value
      }
    }
    break;
    //ACTIONS ADMINISTRATOR
    case TYPES.READ_ALL_EVENTS_ADM:{
      return {...state,
       eventosAdm: action.value}
    }
    break;
    case TYPES.READ_ALL_SALAS_ADM:{
      return {...state,
       salasAdm: action.value}
    }
    break;
    case TYPES.READ_ALL_PRODUCERS_ADM:{
      return {
        ...state, producersAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_ESPECTATOR_ADM:{
      return{
        ...state,
        espectatorAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_NOTAS_ADM:{
      return {
        ...state,
        notasAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_VIDEOS_ADM:{
      return {
        ...state,
        videosAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_PODCAST_ADM:{
      return{
        ...state,
        podcastAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_CATEGORIASCONTENIDO_ADM:{
      return{
        ...state,
        categoriasContenidoAdm: action.value
      }
    }
    break;
    case TYPES.READ_ALL_GALERIAIMAGENES:{
      return{
        ...state,
        galeriaImagenes: action.value
      }
    }
    break;
  }}