export const validacionEntradas = (entradas, cupon) => {
  let result = []

  entradas.map((entrada) => {
    let subTotal = entrada.precio*entrada.cantidad;

    if(
      cupon.estado == "ACTIVO"
    ){
      //Aplicar descuento 
      let state = false;
      //concat fecha
      let funcion = entrada.fecha_funcion.split(" ");
      funcion = funcion[0]+" "+funcion[1]+" "+funcion[2]+" "+funcion[3]+" "+funcion[4]+" "+funcion[5];
      let fechas = JSON.parse(cupon.fechas);


      fechas.map((fecha) => {
        if(fecha.fechas[0] == funcion){
          //confirma existencia
          state = true;
        }
      })

      //aplicar descuento
      if(state){
        entrada.total_descontado = subTotal - subTotal/100*cupon.descuento;
        entrada.valor_descontado = subTotal/100*cupon.descuento;
      }

      result.push(entrada);
    } else {
      result.push(entrada);
    }

  })

  return result
}

export const validacionDescuento = (entradas, cupones) => {
  let state = false;

  if(cupones.length == 0){
    state = false;
  }

  cupones.map((cupon) => {
    let fechas = JSON.parse(cupon.fechas_funciones);

    fechas.map((fecha) => {
      entradas.map((entrada) => {
        //concat fecha
        let funcion = entrada.fecha_funcion.split(" ");
        funcion = funcion[0]+" "+funcion[1]+" "+funcion[2]+" "+funcion[3]+" "+funcion[4]+" "+funcion[5];
        //fecha habilitada
        if(funcion == fecha.fechas[0]){
          state = true;
        }
      })
    })

  })
  

  return state;
}

export const Totales = (entradas, cupon, tasa) => {
let tipo = "";
let serv = 0;
let subTotal = 0;
let total = 0;
//
let total_descontado = 0;

entradas.map((entrada) => {
  subTotal = entrada.cantidad*entrada.precio+subTotal;
  
 
  if(
      cupon.estado == "ACTIVO"
    ){
      //Aplicar descuento 
      let state = false;
      //concat fecha
      let funcion = entrada.fecha_funcion.split(" ");
      funcion = funcion[0]+" "+funcion[1]+" "+funcion[2]+" "+funcion[3]+" "+funcion[4]+" "+funcion[5];
      let fechas = JSON.parse(cupon.fechas);
      fechas.map((fecha) => {
        if(fecha.fechas[0] == funcion){
          //confirma existencia
          state = true;
        }
      })

      //aplicar descuento
      if(state){
        total_descontado = entrada.cantidad*entrada.precio - (((entrada.cantidad*entrada.precio)/100)*cupon.descuento) + total_descontado;
      } else {
        total_descontado = entrada.cantidad*entrada.precio + total_descontado; 
      }
    } 
})


if(subTotal > 500){
  tipo = tasa+"%";
  serv = subTotal/100*tasa
  total = subTotal+serv;
  total_descontado = total_descontado > 0? total_descontado + total_descontado/100*tasa : 0;
} else {
  tipo = "ARS 75"
  serv = 75;
  total = subTotal+serv;
  total_descontado = total_descontado > 0? total_descontado+50 : 0;
}

return {
  tipo: tipo,
  serv: serv,
  subTotal: subTotal,
  total: total,
  total_descontado: total_descontado
}
}