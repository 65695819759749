import { useState } from "react";
import Pago from "../page/Pagar";

const regex = /^[0-9/]*$/;

const PagoController = () => {
  const [info, setInfo] = useState({
    numero: "",
    titular: "",
    vencimiento: "",
    codigo: "",
    tipo: null,
    focusCVV: false,
    email: "",
    telefono: ""
  });

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "vencimiento":
        {
          
          let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos

          if (value.length >= 2) {
            value = value.slice(0, 2) + "/" + value.slice(2); // Añadir la barra
          }

          if (regex.test(value)) {
            return setInfo({
              ...info,
              [e.target.name]: value,
            });
          }
        }
        break;
      case "titular":
      case "codigo":
        {
          setInfo({
            ...info,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "numero":
        {
          if (isNaN(e.target.value)) {
            return null;
          }

          let tipo = null;
          if(e.target.value[0] == '4'){
            tipo = 'visa'
          }

          
          if(['51','52','53','54','55'].includes((e.target.value[0] + e.target.value[0]))){
            tipo = 'mastercard'
          }

          if(['50','56','69'].includes((e.target.value[0] + e.target.value[1]))){
            tipo = 'maestro'
          }

          if(['34','37'].includes((e.target.value[0] + e.target.value[1]))){
            tipo = 'amex'
          }
          

          setInfo({
            ...info,
            [e.target.name]: e.target.value.replace(
              new RegExp(" ".replace(/\./g, "\\."), "gi"),
              ""
            ),
            tipo: tipo
          });
        }
        break;
      default:
        break;
    }
  };

  return <Pago info={info} setInfo={setInfo} onChange={onChange} />;
};

export default PagoController;
