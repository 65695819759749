import { useEffect, useState } from "react";
import useApi from "../../../../../hooks/useApi";
import ModalVideo from "../../../Videos/Modals/ModalVideo";
import { GetPublicApi } from "../../../../../resource/helpers/PublicApi";

const Videos = () => {
  const [steps, setSteps] = useState(0);
  const [modalVideo, setModalVideo] = useState(false);
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(null);
  //const geolocation = localStorage.getItem("geolocation");

  //params
  /*const [params, setParams] = useState({
    id: "",
    provincia: "",
    url: "/api/Multimedia",
  });*/

  const concatDate = (fecha, select) => {
    if (!fecha) {
      return "Sin fecha asignada";
    }

    let result;

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    switch (select) {
      case "created_at":
        {
          let fh = new Date(fecha);

          result =
            dias[fh.getDay()] +
            " " +
            fh.getDate() +
            " de " +
            mes[fh.getMonth()] +
            " de " +
            fh.getFullYear() +
            " Hora: " +
            fh.getHours() +
            ":" +
            fh.getMinutes();
        }
        break;
      case "funciones":
        {
          if (!fecha) {
            return "";
          }
          let fechas = JSON.parse(fecha);
          let fh_start = fechas[0].fechas[0].split(" ");
          let fh_end =
            fechas[fechas.length - 1].fechas[
              fechas[fechas.length - 1].fechas.length - 1
            ].split(" ");

          result =
            "Desde el " +
            fh_start[0] +
            " " +
            fh_start[1] +
            " de " +
            fh_start[3] +
            " hasta el " +
            fh_end[0] +
            " " +
            fh_end[1] +
            " de " +
            fh_end[3];
        }
        break;
    }

    return result;
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "reproducir":
        {
          setModalVideo(value);
        }
        break;
      case "close":
        {
          setModalVideo(false);
        }
        break;
    }
  };

  
  //hooks
  //const { data, message, isLoading } = useApi(params.url, params, steps);
  const fetch = async (params) => {
    const response = await GetPublicApi({
      url : params.url,
      params : params,
      setLoading,
    })
    if(response.status == 200 || response.status == 201){
      setVideos(response.data)
    }
  }
  useEffect(() => {
    let params = {
      url: "Multimedia",
    }
    if (!videos) {
      fetch(params)
    }
  }, [])
  return (
    <section
      className="bg-black container-md p-5"
      style={{ borderRadius: "20px", marginTop: "100px", marginBottom: "150px" }}
    >
      <div class="row" id="podcast">
        <div class="col-lg-12">
          <div class="col-12 text-center mb-4">
            <h2 class="fc-blanco wow bounceInUp">Últimos Videos</h2>
          </div>

          {videos ? (
            videos.length !== 0 ? (
              <div class="bg-gris-oscuro box-multimedia mb-4 wow bounceInUp">
                <div class="row">
                  <div class="col-lg-8  d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                    <div>
                      <p class="fc-blanco">
                        {concatDate(videos[0].created_at, "created_at")}
                      </p>
                      <hr class="hr-white-01" />
                      <h2 class="fc-amarillo mb-0">{videos[0].titulo}</h2>
                      <h3 class="fc-amarillo"></h3>
                      <p class="fc-blanco">{videos[0].descripcion}</p>
                    </div>
                    <div class="text-center mt-3">
                      <label className="btn py-1 btn-custom-amarillo">
                      <i class="fs-2 bi bi-play-circle"></i>
                      <button
                        class="lottie-player d-none"
                        name="reproducir"
                        onClick={(event) => onClick(event, videos[0])}
                      ></button>
                    </label>
                    </div>
                  </div>
                  <div class="col-lg-4 order-1 order-lg-2 header-box d-flex justify-content-center align-items-centers">
                    <label className="btn">
                      <img
                        src={"https://antesala.com.ar/assets/img/videos/" + videos[0].imagen}
                        className="w-100"
                      />
                      <button
                        class="lottie-player d-none"
                        name="reproducir"
                        onClick={(event) => onClick(event, videos[0])}
                      ></button>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {videos
            ? videos.length > 0
              ? videos.map((video, index) => {
                  if (index > 0) {
                    return (
                      <div class="bg-naranja box-multimedia mb-4 wow bounceInUp">
                      <div class="row">
                        <div class="col-lg-8  d-flex flex-column justify-content-between p-4 p-md-5 order-2 order-lg-1">
                          <div>
                            <p class="fc-blanco">
                              {concatDate(video.created_at, "created_at")}
                            </p>
                            <hr class="hr-white-01" />
                            <h2 class="text-white mb-0">{video.titulo}</h2>
                            <h3 class="fc-amarillo"></h3>
                            <p class="fc-blanco">{video.descripcion}</p>
                          </div>
                          <div class="text-center mt-3">
                            <label className="btn py-1 btn-custom-amarillo">
                            <i class="fs-2 bi bi-play-circle"></i>
                            <button
                              class="lottie-player d-none"
                              name="reproducir"
                              onClick={(event) => onClick(event, video)}
                            ></button>
                          </label>
                          </div>
                        </div>
                        <div class="col-lg-4 order-1 order-lg-2 header-box d-flex justify-content-center align-items-centers">
                          <label className="btn">
                            <img
                              src={"https://antesala.com.ar/assets/img/videos/" + video.imagen}
                              className="w-100"
                            />
                            <button
                              class="lottie-player d-none"
                              name="reproducir"
                              onClick={(event) => onClick(event, video)}
                            ></button>
                          </label>
                        </div>
                      </div>
                    </div>
                    );
                  }
                })
              : ""
            : ""}

          

          <div class="d-grid gap-2 wow bounceInUp">
            <a href="/contenido/videos" class="btn btn-custom-amarillo">
              ver más +
            </a>
          </div>
        </div>

        <div class="col-lg-4 d-none" id="video">
          <div class="col-12 text-center mb-4 mt-4 mt-md-0">
            <h2 class="fc-blanco wow bounceInUp"></h2>
          </div>

          <div class="bg-naranja box-multimedia mb-4 wow bounceInUp">
            <div class="header-box position-relative d-flex align-items-center justify-content-center">
              <img src="assets/img/img-multimedia.png" width="100%" alt="" />
              <a
                class="lottie-player"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></a>
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <iframe
                        width="100%"
                        height="500px"
                        src="https://www.youtube.com/embed/JWRlTezTF2k?controls=0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between p-4 order-2 order-md-1">
              <div>
                <p class="fc-blanco">12 de enero de 2022</p>
                <hr class="hr-white-01" />
                <h2 class="fc-blanco mb-0">
                  Recorrido virtual por el cuenco teatro
                </h2>
                <p class="fc-blanco">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Omnis rerum sapiente accusantium, facilis labore
                  exercitationem odio perferendis cum veniam quam!
                </p>
              </div>
            </div>
          </div>

          <div class="d-grid gap-2 wow bounceInUp">
            <a href="videos.php" class="btn btn-custom-amarillo">
              ver más +
            </a>
          </div>
        </div>
      </div>

      {modalVideo ? <ModalVideo data={modalVideo} onClick={onClick} /> : ""}
    </section>
  );
};

export default Videos;
