import { createContext,useReducer } from "react";
import { TYPES } from "../actions/ActionsStates";
import ObtenerCategorias from "../helpers/CategoriaHelper";
import ObtenerDatoFiscal from "../helpers/DatoFiscalHelper";
import { Eventos } from "../helpers/EventHelper";
import {
  AdministradorSalas,
  Salas,
} from "../helpers/SalaHelper";
import { obtenerProvincias } from "../helpers/NormGeograficoHelper";
import { StateInitialState, stateReducer } from "../reducers/ReducerStates";
import {
  UsuarioEventos,
  AdministradorEventos,
} from "../helpers/EventUserHelper";
import { AdministradorUsuarios } from "../helpers/UserHelper";
import { AdministradorReportes, EventoReportes, UsuarioReportes } from "../helpers/ReporteHelper";
import { GeolocationApi, Provincias } from "../helpers/GeolocationHelper";
import { AdministradorProductores } from "../helpers/ProducersHelper";
import { AdministradorNotas, Notas } from "../helpers/NotasHelper";
import { AdministradorCategoriasContenido, AdministradorCrearCategoriasContenido } from "../helpers/CategoriasContenidoHelper";
import { AdministradorSuscripcion } from "../helpers/SuscripcionHelper";

export const CollectionStateContext = createContext();

export const EventosState = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, StateInitialState);

  //STATE HOME

  const eventos = async (
    setEventos,
    filtro 
  ) => {
    const eventos = await Eventos(
     filtro
    );

    if(filtro.id.trim() == ""){
      dispatch({ type: TYPES.READ_ALL_EVENTS, value: eventos });
    }

    return setEventos(eventos);

  }

  const salas = async (
    setSalas,
    id,
    search,
    provincia,
    localidad,
    filtro
    ) => {

    const salas = await Salas(
    id,
    search,
    provincia,
    localidad,
    filtro
    );

    if(id.trim() == ""){
      dispatch({ type: TYPES.READ_ALL_SALAS, value: salas })
    }

    return setSalas(salas);
  };

  const provincias = async (setProvincias) => {

    const provincias = await Provincias();

    localStorage.setItem("provincias", JSON.stringify(provincias));

    dispatch({ type: TYPES.READ_ALL_PROVINCIAS, value: provincias });

    return setProvincias(provincias);

  }

  const imagenes = async (setImagenes, filter_imagenes) => {

    //const imagenes = await (filter_imagenes);

    //dispatch({ type: TYPES.READ_ALL_GALERIA_IMAGENES, value: imagenes });

    //return setImagenes(imagenes);

  }

  const notas = async (setNotas, filter_notas) => {
    const notas = await Notas(filter_notas);

    if(filter_notas.id == "" && filter_notas.max == "false"){
    dispatch({ type: TYPES.READ_ALL_NOTAS, value: notas });
    }

    return setNotas(notas);
  }


  //STATE USER

  const usuarioEventos = async (
    setEventos,
    id,
    search,
    sala,
    status,
    date_range,
    url
  ) => {
    const eventos = await UsuarioEventos(
      id,
      search,
      sala,
      status,
      date_range,
      url
    );

    if(id.trim() == ""){
        dispatch({ type: TYPES.READ_ALL_MY_EVENTS, value: eventos });
    }

    return setEventos(eventos);
  }

  const eventoReportes = async (
    setReportes,
    id_evento,
    id_user,
    id_entrada,
    date_range,
    tipo_entrada,
    fecha_entrada,
    nombre_cliente,
    estado,
    url,
    pagination
  )  => {

    const reportes = await EventoReportes(
    id_evento,
    id_user,
    id_entrada,
    date_range,
    tipo_entrada,
    fecha_entrada,
    nombre_cliente,
    estado,
    url,
    pagination
    )
    
    return setReportes(reportes);

  }

  const obtenerCategorias = async (setCategorias, filter_categorias) => {
    const categorias = await ObtenerCategorias(filter_categorias);
    dispatch({ type: TYPES.READ_ALL_CATEGORIAS, value: categorias });
    return setCategorias(categorias);
  };

  const obtenerDatoFiscal = async (setDatoFiscal) => {
    const datoFiscal = await ObtenerDatoFiscal();

    dispatch({ type: TYPES.READ_ALL_DATO_FISCAL, value: datoFiscal });
    return setDatoFiscal(datoFiscal);
  };

  //API GEOLOCATION

  const geolocationApi = async (setGeolocation, url) => {
    const geolocation = await GeolocationApi(url);
    
    
    localStorage.setItem("apiProvincias", JSON.stringify(geolocation));
    dispatch({ type: TYPES.READ_APIPROVINCIAS, value: geolocation });
    return setGeolocation(geolocation);

  }


  //STATE ADMINISTRADOR

  const administradorUsuariosSuscriptos = async (
    setSuscriptores
  ) => {
    
    const suscriptores = await AdministradorSuscripcion();

    return setSuscriptores(suscriptores);
  }

  const administradorUsuarios = async (
    setUsuarios,
    filter_espectadores
    ) => {

    const espectadores = await AdministradorUsuarios(
      filter_espectadores
    );

    return setUsuarios(espectadores);
  };

  const administradorSalas = async (
    setSalas,
    filter
  ) => {
    const salas = await AdministradorSalas(
      filter
    ); 

    dispatch({ type: TYPES.READ_ALL_SALAS_ADM, value: salas });

    return setSalas(salas);
  };

  const administradorProductores = async (
    setProductores,
    filter_productores
  ) => {

    const productores = await AdministradorProductores(
     filter_productores
    )

    return setProductores(productores);

  }

  const administradorEventos = async (
    setEventos,
    filter
    ) => {

    const eventos = await AdministradorEventos(
      filter
    );


    if(filter.id.trim() == ""){
      dispatch({ type: TYPES.READ_ALL_EVENTS_ADM, value: eventos });
    }
    
    return setEventos(eventos);

  }

  const administradorGaleriaImagenes = async (
    setImagenes,
    filter_imagenes
  ) => {

    const imagenes = await AdministradorCategoriasContenido(filter_imagenes);

    dispatch({ type: TYPES.READ_ALL_CATEGORIASCONTENIDO_ADM, value: imagenes });

    return setImagenes(imagenes);

  }

  const administradorCategoriasContenido = async (setCategorias, filter_categoria) => {
    
    const categorias = await AdministradorCategoriasContenido(filter_categoria);

    dispatch({ type: TYPES.READ_ALL_CATEGORIASCONTENIDO_ADM, value: categorias });

    return setCategorias(categorias);
  }

  const administradorReportes = async (
    setReportes,
    filter_report
  ) => {

    const reportes = await AdministradorReportes(
      filter_report
    )

    return setReportes(reportes);

  }

  const administradorNotas = async (
    setNotas,
    filter_notas
  ) => {

    const notas = await AdministradorNotas(filter_notas);

    if(filter_notas.id == ""){
      dispatch({ type: TYPES.READ_ALL_NOTAS_ADM, value: notas });
    }

    return setNotas(notas);

  }



  return (
    <CollectionStateContext.Provider
      value={{
        salas,
        eventos,
        provincias,
        imagenes,
        notas,

        usuarioEventos,
        eventoReportes,

        obtenerCategorias,
        obtenerDatoFiscal,
        geolocationApi,
        
        administradorSalas,
        administradorProductores,
        administradorUsuarios,
        administradorUsuariosSuscriptos,
        administradorEventos,
        administradorReportes,
        administradorNotas,
        administradorCategoriasContenido,
        administradorGaleriaImagenes,

        state,
      }}
    >
      {children}
    </CollectionStateContext.Provider>
  );
};

export default EventosState;
