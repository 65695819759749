import { totalReportEvents } from "../../../../../Functions/FunctionReportes";

const FileReport = ({ data, rol, onClick, onChange }) => {
  const { evento, reportes, fecha } = data;
  const reporte = totalReportEvents(reportes);

  return (
    <div className="col-md-3 col-12 p-3 hover-card">
      <div className={reportes.length !== 0? reportes[0].status == "LIQUIDADO"? "row border p-1 border-info shadow-sm" : "row border-0 shadow-sm" : "row border-0 shadow-sm" } style={{ borderRadius: "20px" }}>
        <div className="col-md-12">
          <button className="btn btn-outline-dark rounded-pill w-100 d-flex justify-content-center items-align-center p-3"
          name="detalle"
          onClick={(event) => onClick(event, reportes)}
          title="Detalle">
            <i class="fa-regular fa-folder-closed fs-4"></i>
          </button>
        </div>
        <div className="col-md-12">
          <ul class="list-group">
            <li class="list-group-item bg-transparent border-0 fs-7 p-1">
              {evento.nombre}
            </li>
            <li class="list-group-item bg-transparent border-0 fs-7 p-1">
              {fecha}
            </li>
            <li class="list-group-item bg-transparent border-0 d-flex justify-content-center fw-bold fs-7 p-1 text-muted">
              Ventas
            </li>
            <li class="list-group-item bg-transparent border-0 d-flex justify-content-between fs-7 p-1">
              <div className="text-muted fw-bold">Entradas</div>
              <div>{reporte.total_cantidad}</div>
            </li>
            <li class="list-group-item bg-transparent border-0 d-flex justify-content-between fs-7 p-1">
              <div className="text-muted fw-bold">Monto</div>
              <div>
                <span className="fs-9">ARS </span>
                {reporte.total_pagos.toFixed(2)}
              </div>
            </li>
            {evento.liquidacion.map((liqui) => (
              <li
                class="list-group-item fs-11 bg-transparent border fs-10 p-2 mb-1"
                style={{ borderRadius: "20px" }}
              >
                <div className="text-nowrap text-muted fw-bold">
                  CBU <span className="fw-normal">{liqui.cbu}</span>
                </div>
                <div className="text-nowrap text-muted fw-bold">
                  % <span className="fw-normal">{liqui.porcentaje}</span>{" "}
                </div>
                <div className="text-nowrap text-muted fw-bold">
                  Monto a pagar{" "}
                  <span className="fw-normal">
                    {((reporte.total_pagos / 100) * liqui.porcentaje).toFixed(2)}
                  </span>
                </div>
              </li>
            ))}
            <li class="list-group-item bg-transparent border-0 d-flex justify-content-center fs-7 p-1">
              {
                reportes.length !== 0
                ? reportes[0].status == "ACTIVO"
                ? rol == 1
                ? <label className="fs-7 w-100 btn p-1 btn-custom-amarillo">
                Liquidar
                <i class="d-block fa-solid fa-handshake"></i>
                <button className="d-none" 
                name="liquidar_reportes"
                onClick={(event) => onClick(event, reportes)}></button>
                </label>
                : <label className="fs-7 w-100 btn p-1 btn-outline-dark rounded-pill disabled">
                Por liquidar
                <i class="d-block fa-solid fa-handshake"></i>
                <button className="d-none" ></button>
                </label>
                : reportes[0].status == "LIQUIDADO"
                ? <label className="fs-7 w-100 btn p-1 btn-info">
                Comprobante de liquidación
                <i class="d-block fa-solid fa-handshake"></i>
                <button className="d-none"
                name="comprobante_liquidacion"
                onClick={(event) => onClick(event, {reportes: reportes, evento: evento, fecha: fecha})}></button>
              </label>
                : ""
                : "No hay ventas"
              }
            </li>

            {evento.liquidacion.map((liqui) => (
              <li
                class="list-group-item bg-transparent border fs-11 p-1 mb-1 d-flex justify-content-center"
                style={{ borderRadius: "20px" }}
              >
                <a
                  href={
                    "https://antesala.com.ar/assets/img/comprobantes/" +
                    liqui.comprobante_cbu
                  }
                  className="border-custom-naranja rounded-pill p-1 me-1"
                >
                  CBU
                </a>
                <a
                  href={
                    "https://antesala.com.ar/assets/img/comprobantes/" +
                    liqui.comprobante_entidad
                  }
                  className="border-custom-naranja rounded-pill p-1 me-1"
                >
                  CUIT
                </a>
                <a
                  href={
                    "https://antesala.com.ar/assets/img/comprobantes/" +
                    liqui.comprobante_afip
                  }
                  className="border-custom-naranja rounded-pill p-1"
                >
                  AFIP
                </a>
              </li>
            ))}
            <span className="fs-11 text-center">Archivos</span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FileReport;
