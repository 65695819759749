export const FORMs = {
  /* AUTH */
  FORM_LOGIN: {
    email: ["", "required"],
    password: ["", "required"]
  },

  FORM_REGISTER: {
    email: ["", "required"],
    nombre: ["", "required"],
    apellido: ["", "required"],
    telefono: ["", "required"],
    direccion: ["", ""],
    localidad: ["", ""],
    provincia: ["", ""],
    password: ["", "required"],
    confirm_password: ["", "required"],
    rol: ["", "required"],
  },

  FORM_USUARIO: {
    id: ["", "required"],
    nombre: ["", "required"],
    apellido: ["", "required"],
    email: ["", "required"],
    email_verified_at: ["", "required"],
    telefono: ["", "required"],
    password: ["", "required"],
    direccion: ["", "required"],
    provincia: ["", "required"],
    localidad: ["", "required"],
    imagen: ["", "required"],
    status_id: ["", "required"],
    user_google_id: ["", "required"],
    user_facebook_id: ["", "required"],
    historia: ["", "required"],
    online: ["", "required"],
    token_verify: ["", "required"],
    recordar_token: ["", "required"],

    roles: [[], "required"],

    created_at: ["", "required"],
    updated_at: ["", "required"],
  },
  /* END */
  /* EVENTO */
  FORM_EVENTO: {
    id: [null, ""],
    usuarios_id: ["", ""],
    status_id: ["", ""],
    imagen: ["", "required"],
    imagen_url: [null, "required"],
    //url_youtube: ["", ""],
    nombre: ["", "required"],
    nombre_grupo: ["", "required"],
    duracion: ["", "required"],

    clase: ["PRESENCIAL", "required"],
    grupo: ["", "required"],

    descripcion: ["", "required"],
    nombre_sitio: ["", ""],
    maps_sitio: ["", ""],
    atp: ["", ""],
    direccion: ["", ""],
    provincia: ["", ""],
    localidad: ["", ""],
    email_sitio: ["", ""],
    telefono_sitio: ["", ""],
    website_sitio: ["", ""],
    historia: ["", ""],
    visitas: ["", ""],

    categorias: [[], "required"],
    created_at: ["", ""],
    update_at: ["", ""],

    fechas: [[], "required"],
    ["fecha-day"]: [[
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ], ""],
    ["fecha-hora"]: ["", "required"],
    ["fecha-stock"]: ["", "required"],

    link: [[], ""],
    ['link-nombre']: ["", "required"],
    ['link-url']: ["", "required"],

    ficha_tecnica: [[], "required"],
    ["ficha_tecnica-nombre"]: ["", "required"],
    ["ficha_tecnica-rol"]: ["", "required"],

    ['sala-search']: ['', ''],
    sala: [null, 'required'],

    entradas: [[], 'required'],
    ["entrada-id"]: [null, ""],
    ["entrada-status"]: [1, ""],
    ["entrada-tipo"]: ["", "required"],
    ["entrada-precio"]: [0, ""],
    ["entrada-cierre"]: ["00:00", ""],
    ["entrada-multiplicador"]: [null, ""],
    ["entrada-habilitacion"]: ["", "required"],
    ["entrada-stock"]: [0, ""],


    liquidacion: [[], 'required'],
    ['liquidacion-id']: [null, ""],
    ['liquidacion-cuit']: ["", "required"],
    ['liquidacion-fiscal']: ["", "required"],
    ['liquidacion-comprobante_afip']: ["", "required"],
    ['liquidacion-comprobante_cuit']: ["", "required"],
    ['liquidacion-comprobante_cbu']: ["", "required"],
    ['liquidacion-comprobante_afip_url']: ["", "required"],
    ['liquidacion-comprobante_cuit_url']: ["", "required"],
    ['liquidacion-comprobante_cbu_url']: ["", "required"],
    ['liquidacion-cbu']: ["", "required"],
    ['liquidacion-email']: ["", "required"],
    ['liquidacion-porcentaje']: [0, "required"],

    usuario: [null, '']
  },
  FORM_EVENTO_ENTRADA: {
    id: [null, "required"],
    evento_id: ["", "required"],
    nombre: ["", "required"],
    tipo: ["", "required"],
    precio: ["", "required"],
    habilitacion_hora: ["", "required"],
    habilitacion_fecha: ["", "required"],
    stock: [0, "required"],
    multiplicador: ["", "required"],
    status_id: ["", "required"],
    created_at: ["", "required"],
    update_at: ["", "required"],
  },
  FORM_EVENTO_SALA: {
    id: ["", "required"],
    evento_id: ["", "required"],
    razon_social: ["", ""],
    nombre: ["", "required"],
    email: ["", "required"],
    telefono: ["", "required"],
    direccion: ["", "required"],
    localidad: ["", "required"],
    provincia: ["", "required"],
  },
  FORM_EVENTO_LIQUIDACION: {
    id: ["", "required"],
    usuario_id: ['', ''],
    evento_id: ["", "required"],
    porcentaje: ["", "required"],
    created_at: ["", "required"],
    updated_at: ["", "required"],
    entidad: ["", "required"],
    cbu: ["", "required"],
    identificador: ["", "required"],

    comprobante_cbu: ["", "required"],
    comprobante_afip: ["", "required"],
    comprobante_entidad: ["", "required"],
    comprobante_cbu_url: ["", "required"],
    comprobante_afip_url: ["", "required"],
    comprobante_entidad_url: ["", "required"],

    dato_fiscal: ["", "required"],
    email: ["", "required"],
    porcentaje_argentores: ["", "required"],
  },
  /* END */
  /* NOTAS */

  FORM_NOTA: {
    id: [null, ""],
    imagen: ["", "required"],
    imagen_url: ["", "required"],
    titulo: ["", "required"],
    descripcion: ["", "required"],
    contenido: ["", "required"],
    categorias: ["", "required"],
    autor: ["", "required"],
    provincia: ["", "required"],
    //localidad: ["", "required"],
    //url_youtube: ["", ""],
    categorias: [[], "required"],
    status_id: [null, ''],
  },

  FORM_CATEGORIA:{
    nombre: ["", "required"],
    tipo: ["", "required"],
  },

  /* END */
  /* NOTAS */

  FORM_MEMBRESIA: {
    id: [null, ""],
    nombre: ["", "required"],
    descripcion: ["", "required"],
    limite: ["", "required"],
    limite_compra: ["", "required"],
    descuento: ["", "required"],
    region: ["", "required"],
    terminos: ["", "required"],
    fecha_limite: ["", "required"],
    status_id: ["", "required"]
  }


}