import Header from "./header/Header"
import Cuando from "./secciones/Cuando";
import Donde from "./secciones/Donde";
import Entradas from "./secciones/Entradas";
import Liquidacion from "./secciones/Liquidacion";
import Que from "./secciones/Que";

const Evento = ({
  setLabel,
  label,
  labels,
  id,
  locale,
  fecha,
  setFecha,
  search,
  categorias,
  form,
  setForm,
  setValidation,
  validation,
  onClick,
  onChange,
  modal,
  setModal,
}) => {


  const set_label = (label) => {
    switch (label) {
      /* ¿Qué? */
      case 0: {
        return <Que
          setForm={setForm}
          form={form}
          setValidation={setValidation}
          validation={validation}
          categorias={categorias}
        />
      } break;
      /* ¿Donde? */
      case 1: {
        return <Donde
          setForm={setForm}
          form={form}
          setValidation={setValidation}
          validation={validation}
          search={search}
        />
      } break;
      /* ¿Cuando? */
      case 2: {
        return <Cuando
          setForm={setForm}
          form={form}
          setValidation={setValidation}
          validation={validation}
          locale={locale}
          fecha={fecha}
          setFecha={setFecha}
        />
      } break;
      /* Entradas */
      case 3: {
        return <Entradas
          setForm={setForm}
          form={form}
          setValidation={setValidation}
          validation={validation}
          modal={modal}
          setModal={setModal}
        />
      } break;
      /* Liquidacion */
      case 4: {
        return <Liquidacion
          setForm={setForm}
          form={form}
          setValidation={setValidation}
          validation={validation}
          modal={modal}
          setModal={setModal}
        />
      } break;
    }
  }

  const pagination = () => {

    switch (label) {
      case 0: return <div className="container-md d-flex justify-content-between mt-3">
        <label className="btn btn-dark w-100 ms-2">
          Siguiente <i class="bi bi-chevron-right"></i>
          <button className="d-none"
            onClick={() => setLabel(label + 1)}></button>
        </label>
      </div>
      case 1:
      case 2:
      case 3: return <div className="container-md d-flex justify-content-between mt-3"><label className="btn btn-dark w-100 me-2">
      <i class="bi bi-chevron-left"></i> Volver
      <button className="d-none"
        onClick={() => setLabel(label - 1)}></button>
    </label>
      <label className="btn btn-dark w-100 ms-2">
        Siguiente <i class="bi bi-chevron-right"></i>
        <button className="d-none"
          onClick={() => setLabel(label + 1)}></button>
      </label>
    </div>
      case 4: return <div className="container-md d-flex justify-content-between mt-3"><label className="btn btn-dark w-100 me-2">
      <i class="bi bi-chevron-left"></i> Volver
      <button className="d-none"
        onClick={() => setLabel(label - 1)}></button>
    </label>
    </div>

    }
  }
  return (
    <div className="min-vh-100 pb-5" style={{ backgroundColor: "#eaeaea" }}>
      <Header
        onClick={onClick}
        labels={labels}
        setLabel={setLabel}
        label={label}
        id={id}
        form={form}
        setForm={setForm}
      />
      {set_label(label)}
      {pagination()}
    </div>
  )
}

export default Evento