import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Cuando = ({
  onClick,
  onChange,
  dateRange,
  setForm,
  form,
  categorias,
  evento,
  alert,
  locale,
  fecha,
  onFecha,
}) => {
  return (
    <div className="row">
      <div
        className="col-12 border border-0 shadow-sm bg-white mb-3 p-2"
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12 border-bottom d-flex justify-content-between ">
            <div className="mb-3 fs-5 py-2 fw-bold ">
              Fecha y horario
              <span className="fs-6 text-muted fw-normal d-block">
                Puedes seleccionar una o varias fechas para tu función.
              </span>
            </div>
            <div className="form-check form-switch py-2 fs-7"></div>
          </div>

          <div className="col-md-6 col-12 border-0 ">
            <ul className="list-group">
              <li className="list-group-item border-0">
                <div className="mb-4  w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Selecciona el rango de fechas
                  </label>

                  <div className="d-flex justify-content-center">
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => onFecha([item.selection])}
                      moveRangeOnFirstSelection={false}
                      locale={locale}
                      minDate={addDays(new Date(), 0)}
                      ranges={fecha}
                      style={{ fontSize: "12px" }}
                      color={"#d0552a"}
                      showDateDisplay={false}
                      rangeColors={["#d0552a", "#d0552a", "#d0552a"]}
                    />
                  </div>

                  {alert.fecha ? (
                    !fecha[0].endDate ? (
                      <span className="text-danger fs-7">
                        Seleccione un rango de fecha
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3">
                  <div className="mx-1  d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-domingo"
                      autocomplete="off"
                      name="day"
                      value="domingo"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-domingo"
                    >
                      Domingo
                    </label>
                  </div>

                  <div className="mx-1 d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-lunes"
                      autocomplete="off"
                      name="day"
                      value="lunes"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-lunes"
                    >
                      Lunes
                    </label>
                  </div>

                  <div className="mx-1 d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-martes"
                      autocomplete="off"
                      name="day"
                      value="martes"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-martes"
                    >
                      Martes
                    </label>
                  </div>

                  <div className="mx-1  d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-miercoles"
                      autocomplete="off"
                      name="day"
                      value="miércoles"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-miercoles"
                    >
                      Miércoles
                    </label>
                  </div>

                  <div className="mx-1  d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-jueves"
                      autocomplete="off"
                      name="day"
                      value="jueves"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-jueves"
                    >
                      Jueves
                    </label>
                  </div>

                  <div className="mx-1   d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-viernes"
                      autocomplete="off"
                      name="day"
                      value="viernes"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-viernes"
                    >
                      Viernes
                    </label>
                  </div>

                  <div className="mx-1  d-inline mx-md-1">
                    <input
                      type="checkbox"
                      class="btn-check"
                      id="check-sabado"
                      autocomplete="off"
                      name="day"
                      value="sábado"
                      onChange={(event) => onChange(event)}
                    />
                    <label
                      class="btn btn-outline-dark text-nowrap mb-2"
                      for="check-sabado"
                    >
                      Sábado
                    </label>
                  </div>
                </div>

                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Hora disponible
                  </label>
                  <input
                    type="time"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    name="time"
                    onChange={(event) => onChange(event)}
                  />
                  {alert.fecha ? (
                    form.time.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Ingrese un horario para su rango de fecha
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Asigne un Stock a cada funcón
                  </label>
                  <input
                    type="number"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    placeholder="0"
                    name="stock"
                    onChange={(event) => onChange(event)}
                  />
                  {alert.fecha ? (
                    form.stock.trim() == "" ? (
                      <span className="text-danger fs-7">Ingrese el stock</span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3 text-center">
                  <button
                    className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                    onClick={(event) => dateRange(event)}
                  >
                    Agregar fecha{" "}
                    <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-12 border-0 mb-3">
            <ul className="list-group border-0">
              {evento ? (
                <li className="list-group-item border-0 p-0 m-0 px-2 py-1 mb-2 mt-1">
                  <span>
                    Si la fecha de la función genero entradas vendidas ya no
                    podra ser eliminada y solo podra cambiar su stock.
                  </span>
                </li>
              ) : (
                ""
              )}
              {form.array_fechas.length == 0
                ? evento
                  ? evento.fechas
                    ? JSON.parse(evento.fechas).map((fechas, index) => (
                        <li
                          className="list-group-item border-0 shadow-sm p-0 m-0 px-2 py-1 mb-2 mt-1"
                          style={{ borderRadius: "10px" }}
                        >
                          <button
                            className=" fs-6 border-0 bg-white text-danger w-100 text-end text-nowrap fs-7"
                            name="deleteFecha"
                            onFocus={(event) => onClick(event, index)}
                          >
                            {" "}
                            <i className="bi bi-x-lg"></i>{" "}
                          </button>
                          <div className="fw-bold d-flex justify-content-between align-items-center fs-6 mb-1">
                            <div className="text-center w-100">
                              Fecha y hora
                            </div>
                            <div className="text-center w-100">Stock</div>
                          </div>

                          <div className="fw-normal w-100 d-flex align-items-center justify-content-between">
                            <div className="w-100 text-center border-0">
                              {fechas.fechas.map((dias) => (
                                <span className="d-block mb-1 border-bottom">
                                  {dias + " HORA: " + fechas.hora}
                                </span>
                              ))}
                            </div>
                            <div className="w-100 border-0 px-4">
                              <input
                                type="number"
                                defaultValue={fechas.stock}
                                className="form-control border-custom-naranja"
                                name="stock_fecha"
                                onChange={(event) => onChange(event, index)}
                              />
                            </div>
                          </div>
                        </li>
                      ))
                    : ""
                  : ""
                : form.array_fechas.map((fechas, index) => (
                    <li
                      className="list-group-item border-0 shadow-sm p-0 m-0 px-2 py-1 mb-2 mt-1"
                      style={{ borderRadius: "10px" }}
                    >
                      <label className="btn fs-6 border-0 bg-white text-danger w-100 text-end text-nowrap fs-7">
                        <i className="bi bi-x-lg"></i>
                        <button
                          className="d-none fs-6 border-0 bg-white text-danger w-100 text-end text-nowrap fs-7"
                          name="deleteFecha"
                          onClick={(event) => onClick(event, index)}
                        >
                          {" "}
                          <i className="bi bi-x-lg"></i>{" "}
                        </button>
                      </label>
                      <div className="fw-bold d-flex justify-content-between align-items-center fs-6 mb-1">
                        <div className="text-center w-100">Fecha y hora</div>
                        <div className="text-center w-100">Stock</div>
                      </div>

                      <div className="fw-normal w-100 d-flex align-items-center justify-content-between">
                        <div className="w-100 text-center border-0">
                          {fechas.fechas.map((dias) => (
                            <span className="d-block mb-1 border-bottom">
                              {dias + " HORA: " + fechas.hora}
                            </span>
                          ))}
                        </div>
                        <div className="w-100 border-0 px-4">
                          <input
                            type="number"
                            defaultValue={fechas.stock}
                            className="form-control border-custom-naranja"
                            name="stock_fecha"
                            onChange={(event) => onChange(event, index)}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cuando;
