import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const HomeEventosItem = ({ data }) => {

  const { nombre, categorias, entradas, nombre_sitio, cupones, imagen, id, provincia, localidad } =
    data;

  const menor_precio = (entradas) => {
    entradas.sort((x, y) => x.precio - y.precio);
    return entradas[0]
  }


  return (
    <div className="item">
      <div className="box-eventos text-center">
        <div className="img-evento">
          <span className="tag-evento" style={{ fontSize: "12px" }}>{categorias[0].nombre}</span>
          {
            entradas.map((entrada) => {
              if (cupones.length !== 0) {
                if (cupones[0].status == "ACTIVO") {
                  return <span class="tag-descuento">{cupones[0].descuento}%</span>
                }
              }
            })
          }
          <span className="tag-descuento d-none">%20</span>
          
          <LazyLoadImage
              key={id}
              src={"https://antesala.com.ar/assets/img/eventos/"+imagen}
              alt={`Image Alt-${id}`}
              className="img-lazy"
              width={"100%"} 
              placeholderSrc={""}
              effect='blur' // opacity | black-and-white
            />
        </div>
        <p className="teatro mt-4 mb-0">{nombre_sitio}</p>
        <p className="m-0 text-muted opacity-75 fs-8">{provincia} - {localidad}</p>
        <h1 className="fs-5 mt-2">{nombre}</h1>
        <p className="precio fs-4 mt-2">{entradas.length !== 0
          ? menor_precio(entradas).precio == 0 ? "Gratis" : '$' + menor_precio(entradas).precio
          : ""}</p>
        <Link to={"/evento/" + id} className="btn btn-custom-amarillo mb-4">comprar</Link>
      </div>
    </div>
  )
}

export default HomeEventosItem;