import { addDays } from "date-fns";

const Entradas = ({ onClick, onChange, loading, form, evento, alert }) => {
  const date = new Date();
  const mes = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  return (
    <div className="row">
      <div
        className="col-12  border border-0 shadow-sm bg-white mb-3 p-2"
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12 border-bottom d-flex justify-content-between">
            <div className="mb-3 fs-5 py-2 fw-bold ">
              Entradas
              <span className="fs-6 text-muted fw-normal d-block">
                Carga tus entradas, coloca un precio y una fecha de
                habilitación.
              </span>
            </div>
          </div>

          <div className="col-md-6 col-12 border-0">
            <ul className="list-group">
              <li className="list-group-item border-0">
                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Tipo de entrada
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    placeholder="Ingresa el nombre de tu entrada..."
                    defaultValue={
                      form.nombre_entrada.trim() == ""
                        ? ""
                        : form.nombre_entrada
                    }
                    name="nombre_entrada"
                    onChange={(event) => onChange(event)}
                  />
                  <span className="text-muted fs-8">
                    No debe contener caracteres especiales ( / _ : . = ;{" "}
                    {"&& )."}
                  </span>
                  {alert.entrada ? (
                    form.nombre_entrada.trim() == "" ? (
                      <span className="text-danger fs-7 d-block">
                        Escriba un nombre adecuado para su entrada
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Precio
                  </label>
                  <input
                    type="number"
                    className="form-control rounded-pill border-custom-naranja"
                    id="exampleFormControlInput1"
                    placeholder="0"
                    defaultValue={
                      form.precio_entrada.trim() == ""
                        ? ""
                        : form.precio_entrada
                    }
                    name="precio_entrada"
                    onChange={(event) => onChange(event)}
                  />
                  <span className="text-muted fs-8">
                    El precio no debe contener decimales. Cuando el precio es 0
                    la entrada automáticamente será vista como gratis.
                  </span>
                  {alert.entrada ? (
                    form.precio_entrada.trim() == "" ? (
                      <span className="text-danger fs-7 d-block">
                        Ingresa un precio para tu entrada
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3 w-100 d-none">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6 d-block  text-muted"
                  >
                    <div className="form-check form-switch fs-6 mb-3">
                      <input
                        className="form-check-input border-custom-naranja"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        name="estado_descuento"
                        onChange={(event) => onChange(event)}
                        checked={form.estado_descuento ? true : false}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Aplicar descuento %
                      </label>
                    </div>
                  </label>

                  {form.estado_descuento ? (
                    <input
                      type="number"
                      className="form-control rounded-pill border-custom-naranja d-none"
                      id="exampleFormControlInput1"
                      placeholder="0"
                      value={form.porcentaje_descuento}
                      name="porcentaje_descuento"
                      onChange={(event) => onChange(event)}
                    />
                  ) : (
                    ""
                  )}

                  <span className="text-muted fs-8 d-none">
                    Habilite un cupón de descuento en la entrada, si el evento
                    se encuentro con un cupón de descuento habilitado este
                    aparecera como opción de compra.
                  </span>
                </div>

                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label w-100  text-muted"
                  >
                    <div
                      class="accordion accordion-flush w-100"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item w-100 border-0">
                        <div class="fw-normal" id="flush-headingOne">
                          <button
                            class="w-100 border-0 fs-6 p-0 m-0 bg-white fw-normal d-flex justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <div
                              className="text-muted"
                              style={{ fontWeight: "normal" }}
                            >
                              Hora de cierre(Opcional)
                            </div>{" "}
                            <i class="ms-2 bi bi-info-circle"></i>
                          </button>
                        </div>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body alert alert-info">
                            - Si haces ventas a la gorra puedes seleccionar el
                            horario 23:59.
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  <select
                    class="form-select  rounded-pill border-custom-naranja"
                    aria-label="Default select example"
                    name="hora_habilitacion_entrada"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected></option>
                    <option value="00:00">Misma hora de función</option>
                    <option value="01:00">Una hora antes de la función</option>
                    <option value="23:00">Al finalizar el día</option>
                  </select>
                  <span className="text-muted fs-8">
                    La hora de cierre puede ser desde las 00:00 hasta las 23:59
                    de ese mismo día, si no colocas un horario, la venta se
                    cerrará una hora antes de comenzar el evento.
                  </span>
                </div>

                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label w-100  text-muted"
                  >
                    <div
                      class="accordion accordion-flush w-100"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item w-100 border-0">
                        <div class="fw-normal" id="flush-headingOne">
                          <button
                            class="w-100 border-0 fs-6 p-0 m-0 bg-white fw-normal d-flex justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <div
                              className="text-muted"
                              style={{ fontWeight: "normal" }}
                            >
                              Multiplicador de entradas(Opcional)
                            </div>{" "}
                            <i class="ms-2 bi bi-info-circle"></i>
                          </button>
                        </div>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body alert alert-info">
                            - Multiplica tus entradas hasta 4 veces.
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  <select
                    class="form-select  rounded-pill border-custom-naranja"
                    aria-label="Default select example"
                    name="multiplicador_entrada"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected></option>
                    <option value="2">X2</option>
                    <option value="3">X3</option>
                    <option value="4">X4</option>
                  </select>
                  <span className="text-muted fs-8">
                    Si realizas ofertas en tus entradas puedes colocar cuantas
                    entradas quieres generar a partir de una compra, es decir
                    que si seleccionas X2 cada entrada comprada será igual a
                    dos.
                  </span>
                </div>

                <div className="mb-3 w-100">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6  text-muted"
                  >
                    Fecha de habilitación
                  </label>
                  <input
                    type="date"
                    className="form-control rounded-pill border-custom-naranja"
                    format="dd-mm-yyyy"
                    id="exampleFormControlInput1"
                    placeholder="0"
                    defaultValue={
                      form.habilitacion_entrada.trim() == ""
                        ? ""
                        : form.habilitacion_entrada
                    }
                    name="habilitacion_entrada"
                    onChange={(event) => onChange(event)}
                  />
                  {alert.entrada ? (
                    form.habilitacion_entrada.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Confirmar una fecha de habilitacion antes de agregar una
                        entrada
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3 text-center">
                  {evento ? (
                    <button
                      className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                      name="addEntradaEvento"
                      onClick={(event) => onClick(event)}
                    >
                      Agregar entrada{" "}
                      <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
                    </button>
                  ) : (
                    <button
                      className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                      name="addEntrada"
                      onClick={(event) => onClick(event)}
                    >
                      Agregar entrada{" "}
                      <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-12 border-0">
            <ul className="list-group border-0 p-1">
              {evento ? (
                <li className="list-group-item border-0 d-md-flex align-items-center justify-content-between p-0 m-0 py-2 px-3 mb-3 fs-6 text-muted">
                  <span>
                    Cuando editas un evento, la forma de agregar o quitar
                    entradas es diferente y por eso antes debes habilitar el
                    estado de edición.
                  </span>
                </li>
              ) : (
                ""
              )}

              {evento
                ? evento.entradas.map((entrada, index) => {
                    if (form.stateEditarEntradas == entrada.id) {
                      return (
                        <li className="list-group-item border-0 d-md-flex align-items-center justify-content-between p-0 m-0 py-2 px-3 mb-3 shadow-sm row">
                          <i className="fs-3 me-2 text-custom-naranja bi bi-ticket-perforated"></i>
                          <span className="text-dark fs-6 d-block my-1">
                            <label className="mb-1">Nombre</label>
                            <input
                              type="text"
                              className="me-1 fs-7 form-control border-custom-naranja rounded-pill"
                              name="updateEntradaNombre"
                              onChange={(event) => onChange(event, index)}
                              defaultValue={entrada.nombre}
                            />
                          </span>

                          <span className="text-dark fs-6 d-block my-1">
                            <label className="mb-1">Precio</label>
                            <input
                              type="text"
                              className="me-1 fs-7 form-control border-custom-naranja rounded-pill"
                              name="updateEntradaPrecio"
                              onChange={(event) => onChange(event, index)}
                              defaultValue={entrada.precio}
                            />
                          </span>

                          <span className="text-dark fs-6 d-block my-1">
                            <label className="mb-1">Habilitacion</label>
                            <input
                              type="date"
                              className="form-control mb-1 border-custom-naranja"
                              defaultValue={entrada.habilitacion}
                              name="updateEntradaHabilitacion"
                              onChange={(event) => onChange(event, index)}
                            />
                            <label className="mb-1">Hora de cierre</label>
                            <select
                              class="form-select mb-1 border-custom-naranja"
                              aria-label="Default select example"
                              name="updateEntradaHoraCierre"
                              onChange={(event) => onChange(event)}
                            >
                              <option selected>
                                {entrada.habilitacion_hora
                                  ? parseInt(
                                      entrada.habilitacion_hora.slice(0, 2)
                                    ) == 1
                                    ? "Una hora antes de la función"
                                    : parseInt(
                                        entrada.habilitacion_hora.slice(0, 2)
                                      ) == 0
                                    ? "Misma hora de función"
                                    : parseInt(
                                        entrada.habilitacion_hora.slice(0, 2)
                                      ) == 1
                                    ? "Al finalizar el día"
                                    : ""
                                  : ""}
                              </option>
                              <option value="00:00">
                                Misma hora de función
                              </option>
                              <option value="01:00">
                                Una hora antes de la función
                              </option>
                              <option value="23:00">Al finalizar el día</option>
                            </select>

                            <label className="mb-1">Multiplicador</label>
                            <select
                              class="form-select mb-1 border-custom-naranja"
                              aria-label="Default select example"
                              name="multiplicador_entrada"
                              onChange={(event) => onChange(event)}
                            >
                              <option selected>
                                
                                {entrada.multiplicador? "X "+entrada.multiplicador : "Sin multiplicador"}
                              </option>
                              <option value={"null"}>Sin multiplicador</option>
                              <option value="2">X2</option>
                              <option value="3">X3</option>
                              <option value="4">X4</option>
                            </select>
                          </span>

                          <div className="d-xl-flex justify-content-between align-items-center">
                            <div className="text-nowrap">
                              <button
                                className={
                                  entrada.status == "ACTIVO"
                                    ? "btn btn-custom-naranja mx-1 rounded-0 text-white fw-normal GothamBold rounded-pill fs-6"
                                    : "btn btn-dark mx-1 rounded-0 text-white fw-normal GothamBold rounded-pill fs-6"
                                }
                                name="statedUpdatedEntrada"
                                onFocus={(event) => onClick(event, entrada)}
                                disabled={loading ? true : false}
                              >
                                {loading ? (
                                  <span
                                    className="spinner-grow spinner-grow-sm mx-1"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}

                                {entrada.status == "ACTIVO" ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </button>

                              <button
                                className="btn me-1 btn-custom-naranja rounded-0 text-white fw-normal GothamBold rounded-pill  fs-6"
                                name="updateEntradaEvento"
                                onClick={(event) => onClick(event, entrada)}
                                disabled={loading ? true : false}
                              >
                                {loading ? (
                                  <span
                                    className="spinner-grow spinner-grow-sm mx-1"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}
                                Guardar
                              </button>

                              <button
                                className="btn btn-secondary rounded-0 text-white fw-normal GothamBold rounded-pill"
                                name="cancelEntradaEvento"
                                onClick={(event) => onClick(event)}
                                disabled={loading ? true : false}
                              >
                                {loading ? (
                                  <span
                                    className="spinner-grow spinner-grow-sm mx-1"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    } else {
                      return (
                        <div className="row mb-3 shadow-sm">
                          <div className="col-md-1 mb-1">
                          <i className="fs-5 text-custom-naranja bi bi-ticket-perforated"></i>
                          </div>
                          <div className="col-md-4 mb-1 fs-5">
                              {entrada.nombre}
                          </div>
                          <div className="col-md-3 mb-1 fs-5 text-custom-naranja">
                              {
                              entrada.multiplicador
                              ? " X"+entrada.multiplicador
                              : ""
                              }
                          </div>
                          <div className="col-md-4">
                          <button
                              className={
                                form.stateEditarEntradas == entrada.id
                                  ? "d-none"
                                  : "btn btn-success fs-6 border-0 bg-white text-success rounded-pill text-nowrap fs-7 mx-2"
                              }
                              name="stateEditarEntradas"
                              onFocus={(event) => onClick(event, entrada.id)}
                            >
                              <i class="bi bi-pencil"></i>
                            </button>
                          </div>
                          <div className="col-md-12 mb-1 fs-6">
                              Precio
                              {" $"+entrada.precio}
                          </div>
                          <div className="col-md-12 mb-1 fs-6">
                              <span className="text-muted fw-bold">Habilitación</span>
                              {" "+entrada.habilitacion_fecha+" "}
                              <span className="text-muted fw-bold">Hora de cierre</span>
                              {" "+entrada.habilitacion_hora}
                          </div>
                        </div>
                      );
                    }
                  })
                : !form.entradas.length == 0
                ? form.entradas.map((entrada, index) => (
                  
                  <div className="row mb-3 shadow-sm">
                  <div className="col-md-1 mb-1">
                  <i className="fs-5 text-custom-naranja bi bi-ticket-perforated"></i>
                  </div>
                  <div className="col-md-4 mb-1 fs-5">
                      {entrada.nombre}
                  </div>
                  <div className="col-md-3 mb-1 fs-5 text-custom-naranja">
                      {
                      entrada.multiplicador
                      ? " X"+entrada.multiplicador
                      : ""
                      }
                  </div>
                  <div className="col-md-4">
                        <button
                          className="btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                          name="deleteEntrada"
                          onFocus={(event) => onClick(event, index)}
                          disabled={loading ? true : false}
                        >
                          <i className="bi bi-x-lg"></i>
                        </button>
                  </div>
                  <div className="col-md-12 mb-1 fs-6">
                      Precio
                      {" $"+entrada.precio}
                  </div>
                  <div className="col-md-12 mb-1 fs-6">
                      <span className="text-muted fw-bold">Habilitación</span>
                      {" "+entrada.fecha+" "}
                      <span className="text-muted fw-bold">Hora de cierre</span>
                      {" "+entrada.hora}
                  </div>
                </div>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entradas;
