import Swal from "sweetalert2"

const AlertError = (message, title) => {


  return Swal.fire({
    icon: 'error',
    title: title,
    text: message,
    showConfirmButton: false,
  })
}

export default AlertError