import GraficoLineal from "./graficos/GraficoLineal";

const Eventos = ({
  onClick,
  onChange
}) => {
  return (
    <div className="min-vh-100" style={{ backgroundColor: "#eaeaea" }}>
      <div className="container-md">
        <h2>Eventos</h2>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-end mb-3">

            <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
              Añadir Evento
              <button
                className="d-none"
                name="aplicar"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>

          <div className="col-md-12">
            <GraficoLineal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventos;
