import { ConcatDate } from "../../../Functions/ConvertDate";
import PrePago from "./PrePago";

const Evento = ({ data }) => {
  const {
    atp,
    categorias,
    clase,
    cupones,
    descripcion,
    direccion,
    duracion,
    email_sitio,
    entradas,
    fechas,
    ficha_tecnica,
    grupo,
    id,
    imagen,
    maps_sitio,
    link,
    localidad,
    nombre,
    nombre_grupo,
    provincia,
    reportes,
    telefono_sitio,
    update_at,
    url_youtube,
    usuarios,
    visitas,
    website_sitio,
    nombre_sitio,
  } = data;

  const onSave = () => {};

  return (
    <div className="container min-vh-100">

      <section
        id="ticket-info"
        className="bg-gris-claro pt-100 pb-100 mt-100"
        style={{ borderRadius: "20px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="GothamBold d-md-none text-uppercase wow bounceInUp">
                {nombre}
              </h3>
              <p className="tag-evento d-md-none d-inline-block wow bounceInRight">
                {categorias[0].nombre}
              </p>
              {cupones.length > 0 ? 
                cupones[0].status == "ACTIVO"
                ?(
                <span class="bg-violet d-md-none d-inline-block px-3 py-3 ms-1 text-white  text-uppercase"
                style={{ borderRadius: "10px" }}>
                  {cupones[0].descuento + "%"}
                </span>
              ) :(
                ""
              ): (
                ""
              )}
            </div>
            <div className="col-md-6 wow bounceInLeft overflow-hidden">
              <img
                src={"https://antesala.com.ar/assets/img/eventos/" + imagen}
                width="100%"
                className="br-10"
                alt=""
              />
            </div>
            <div className="col-md-6">
              {categorias.map((categoria) => (
                <p className="tag-evento d-none d-md-inline-block wow bounceInRight">
                  {categoria.nombre}
                </p>
              ))}
              {/*cupones.length > 0 ? 
              cupones[0].status == "ACTIVO"
              ?(
              <span class="bg-violet d-none d-md-inline-block px-3 py-3 ms-1 text-white  text-uppercase"
              style={{ borderRadius: "10px" }}>
                {cupones[0].descuento + "%"}
              </span>
            ) :(
              ""
            ) : (
                ""
              )*/}
              <h3 className="GothamBold d-none d-md-block text-uppercase wow bounceInUp">
                {nombre}
              </h3>
              <h4 className="text-uppercase mb-2 wow bounceInUp">
                {nombre_grupo}
              </h4>
              <p className="text-uppercase wow bounceInUp">
                {/* ConcatDate(fechas, "funciones") */}
              </p>
              <ul className="list-group mt-3 wow bounceInUp">
                <li className="GothamLight text-uppercase d-flex mb-2">
                  <i class="fa-solid fa-masks-theater me-2 fs-5"></i>
                  {nombre_sitio}
                </li>
                <li className="GothamLight text-uppercase d-flex mb-2">
                  {grupo == "ADULTOS" ? (
                    <i class="fa-solid fa-person me-2 fs-5"></i>
                  ) : (
                    <i class="fa-solid fa-child me-2 fs-5"></i>
                  )}
                  {grupo}
                </li>
                <li className="GothamLight text-uppercase d-flex mb-2">
                  {clase == "ONLINE" ? (
                    <i class="fa-solid fa-globe me-2 fs-5"></i>
                  ) : (
                    <i class="fa-solid fa-person-shelter me-2 fs-5"></i>
                  )}
                  {clase}
                </li>
                <li className="GothamLight text-uppercase d-flex mb-2">
                  <i class="bi bi-tags-fill me-2 fs-5"></i>
                  {categorias.map((categoria) => categoria.nombre)}
                </li>
                <li className="GothamLight text-uppercase d-flex mb-2">
                  <i class="fa-solid fa-people-group fs-5 me-2"></i>
                  {"ATP(" + atp + ")"}
                </li>
                <li className="GothamLight text-uppercase d-flex mb-2">
                  <i class="fa-solid fa-clock fs-5 me-2"></i>
                  {duracion}
                </li>
              </ul>
              <ul className="list-group d-flex flex-row mt-2 wow bounceInUp">
                <li>
                  <a href="#"></a>
                </li>
                <li>
                  <a href="#"></a>
                </li>
                <li>
                  <a href="#"></a>
                </li>
                <li>
                  <a href="#"></a>
                </li>
              </ul>
              <a
                href="#pagoEntradas"
                className="btn btn-custom-amarillo mt-3 wow bounceInUp"
              >
                comprar entradas
              </a>
              <div className="col-12 mt-4 wow bounceInUp">
                <p className="mb-0">
                  {direccion + " - " + provincia + " - " + localidad}
                </p>
                <p className="mb-0">{email_sitio}</p>
                <p className="mb-0">{telefono_sitio}</p>
                <a target="_blank" href="">
                  {website_sitio}
                </a>
                {link
                ? Object.keys(JSON.parse(link)).map((value) => (
                  <a
                    target="_blank"
                    className="d-block"
                    href={JSON.parse(link)[value]}
                  >
                    {value}
                  </a>
                ))
               : ""}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5" id="ticket-detail" >
        <div dangerouslySetInnerHTML={{ __html: descripcion }}></div>

        <div className="wow bounceInUp mt-5 mb-5">
          {url_youtube ? <h3>trailers</h3> : ""}
          {url_youtube ? (
            <iframe
              width="100%"
              height="500px"
              src={url_youtube}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ) : (
            ""
          )}
        </div>

        <div className="position-relative wow bounceInUp" id="pagoEntradas">
          <h3>ficha técnica</h3>
          {Object.keys(JSON.parse(ficha_tecnica)).map(function (key, index) {
            return (
              <p className="mb-2">
                <strong>{key}:</strong> {JSON.parse(ficha_tecnica)[key]}
              </p>
            );
          })}
          <img src="/assets/img/bg-01.svg" className="img" alt="" />
        </div>
      </section>

      <section >
        <PrePago
          evento={data}
          entradas={entradas}
          reportes={reportes}
          fechas={JSON.parse(fechas)}
        />
      </section>

      <section id="ticket-detail" className="pt-100 pb-100">
        <div className="container">
          <div className="text-center my-5 p-1">
            <iframe
              src={
                "https://maps.google.com/?q=" +
                encodeURI(direccion + " " + provincia + " " + localidad) +
                "&z=14&t=m&output=embed"
              }
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              height="400px"
              className="my-3 w-100"
            ></iframe>
          </div>
        </div>
      </section>

      {/*modal_entrada ? (
        <EntradaGratis
          entradas={selectElement}
          onClick={onClick}
          onChange={onChange}
        />
      ) : (
        ""
      )*/}
    </div>
  );
};

export default Evento;
