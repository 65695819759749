import { useState } from "react";
import { format, add, sub, addDays } from "date-fns";
import "react-date-range/dist/theme/default.css";

import { DateRange } from "react-date-range";

const Filtro = ({onChange, setParams, categorias, provincias, salas, params, dataLocalidad, fecha, onFecha}) => {

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  //console.log(fecha);

  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mes = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return(
    <div>
      <h4 className="GothamBold wow bounceInUp">Filtrar</h4>
            <hr className="wow bounceInUp" />
            <div className="d-flex justify-content-center mb-4 p-0 ">
              <DateRange
                onChange={(item) => {
                onFecha([item.selection])
                setParams({
                  ...params,
                  date_range: [
                    format(item.selection.startDate, "yyyy/MM/dd").toString(),
                    format(item.selection.endDate, "yyyy/MM/dd").toString(),
                  ],
                });
                }}
                moveRangeOnFirstSelection={false}
                locale={locale}
                minDate={addDays(new Date(), 0)}
                ranges={fecha}
                style={{ fontSize: "12px" }}
                
                color={'#d0552a'}
                showDateDisplay={false}
                rangeColors={['#d0552a', '#d0552a', '#d0552a']}
              />
            </div>
            <div className="text-center">
             
            </div>
            <hr className="wow bounceInUp" />
            <div className="mb-4">
              <p className="GothamBold mb-0 mb-2 wow bounceInUp">Ubicación</p>
              <select
                className="form-select form-select wow bounceInUp mb-2"
                name="provincia"
                onChange={(event) => onChange(event)}
              > 
                 <option value="ALL">Todas las provincias</option>
                 {
                  !params.provincia == ""
                  ? <option value={params.provincia} selected>{params.provincia}</option>
                  : ""
                }
                {provincias
                  ? provincias.map((provincia) => {
                    if(provincia.stated == "ACTIVO"){
                      return (
                        <option value={provincia.nombre}>{provincia.nombre}</option>
                      )
                    }
                  })
                  : ""}
              </select>


              {params.filtro_provincia == "" ? (
                ""
              ) : (
                <div className="mb-4 texto-normal">
                  <label htmlFor="" className="fw-bold">Localidad</label>
                  <input
                    className="form-control"
                    type="text"
                    value={params.localidad}
                    name="SearchLocalidad"
                    onChange={(event) => onChange(event)}
                    autocomplete="nope"
                  />
                  <div className="overflow-hidden">
                    {!dataLocalidad.content ? (
                      ""
                    ) : (
                      <ul className="list-group rounded-0 border-0 border-end border-start border-bottom border-top">
                        {dataLocalidad.content.map((municipios) => (
                          <li className="list-group-item border-0 p-0">
                            <button
                              className="bg-transparent border-0 w-100"
                              name="Selectlocalidad"
                              value={municipios.nombre}
                              onClick={(event) => onChange(event)}
                            >
                              {municipios.nombre}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}

            </div>
            <div className="mb-4 texto-normal">
              <p className="GothamBold mb-0 mb-2 wow bounceInUp">Sala</p>
              <select
                className="form-select form-select wow bounceInUp"
                name="search"
                onChange={(event) => onChange(event)}
              >
                <option value="ALL" selected>
                  Todas las salas
                </option>
                {salas
                  ? salas.map((item) => (
                      <option value={item.nombre}>{item.nombre}</option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="mb-4">
              <p className="GothamBold mb-0 mb-2 wow bounceInUp">Público</p>
                <div className="form-check d-flex justify-content-between">
                  <label className="form-check-label">
                    ATP
                  </label>

                 <div className="me-2">
                  <span>SI</span>
                  <input
                    className="form-check-input mx-1"
                    type="checkbox"
                    name="atp"
                    value="SI"
                    onChange={(event) => onChange(event, "SI")}
                  />
                 </div>
                 <div>
                  <span>NO</span>
                  <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  name="atp"
                  value="NO"
                  onChange={(event) => onChange(event, "NO")}
                  />
                 </div>
                </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="publico"
                  id="exampleRadios1"
                  value="ADULTOS"
                  onChange={(event) => onChange(event)}
                />
                <label className="form-check-label" for="exampleRadios1">
                  Adultos
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="publico"
                  id="exampleRadios2"
                  value="INFANCIAS"
                  onChange={(event) => onChange(event)}
                />
                <label className="form-check-label" for="exampleRadios2">
                  Infancias
                </label>
              </div>
            </div>
            <div className="mb-4">
              <p className="GothamBold mb-0 mb-2 wow bounceInUp">
                Modo de Espectáculo
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="espectaculo"
                  id="exampleRadios1"
                  value="ONLINE"
                  onChange={(event) => onChange(event)}
                />
                <label className="form-check-label" for="exampleRadios1">
                  Online
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="espectaculo"
                  id="exampleRadios2"
                  value="PRESENCIAL"
                  onChange={(event) => onChange(event)}
                />
                <label className="form-check-label" for="exampleRadios2">
                  Presencial
                </label>
              </div>
            </div>
            <div className="mb-4">
              <p className="GothamBold mb-0 mb-2 wow bounceInUp">
                Tipo de Espectáculo
              </p>
              {categorias
                ? categorias.map((item) => (
                    <div className="form-check mb-2 wow bounceInUp">
                      <label className="form-check-label">{item.nombre}</label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="categoria"
                        value={item.nombre}
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                  ))
                : ""}
            </div>
            <div className="mb-4 d-none">
              <button
                className="btn btn-custom-amarillo w-100"
              >
                Aplicar Filtro
              </button>
            </div>
    </div>
  )
}

export default Filtro