export const select_item = (
  data,
  type
) => {
  let hoy = new Date();

  switch (type) {
    case "FESTIVAL":
      let obj = {
        prx: [],
        new: [],
        old: []
      }

      let result = [];

      data.map((item) => {
        if(item.posicion == type)
        {
          let vigencia = JSON.parse(item.vigencia);
          let start = new Date(vigencia[0]);
          let end = new Date(vigencia[1]);
          //Festivales proximos 
          if(start > hoy){
            obj = {
              ...obj,
              prx: [...obj.prx, item]
            }
          } else {
            //Festivales actuales
            if(start <= hoy && end > hoy){
              obj = {
                ...obj,
                new: [...obj.new, item]
              }
            }
            //Festivales pasados
            if(end < hoy){
              obj = {
                ...obj,
                old: [...obj.old, item]
              }
            }
          }
        }
      })

      console.log(obj);
  
      return obj;
    break;
  }

   
}