import { ConcatDate } from "../../../../../App/Functions/ConcatDate";

const TablaReportes = ({ data, onClick, onChange }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th className="border-0 text-nowrap">Id</th>
            <th className="border-0 text-nowrap">Fecha de compra</th>
            <th className="border-0 text-nowrap">Evento</th>

            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Nombre cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Dni cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Telefono cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-person"></i> Email cliente
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Codigo
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Tipo
            </th>
            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Fecha de entrada
            </th>

            <th className="border-0 text-nowrap">Monto Unidad</th>
                <th className="border-0 text-nowrap">Cant</th>
                <th className="border-0 text-nowrap">total</th>
                <th className="border-0 text-nowrap">Descuento</th>
                <th className="border-0 text-nowrap">Total con descuento</th>
                <th className="border-0 text-nowrap">Codigo descuento</th>

            <th className="border-0">Estado</th>
            <th className="border-0"></th>
            <th className="border-0">Decidir</th>
            <th className="border-0">Reportes</th>
          </tr>
        </thead>
        <tbody>
          {data.map((reporte, index) => (
            <tr>
              <td className="border-0">{reporte.id}</td>
              <td className="border-0">
                {ConcatDate(reporte.created_at, "created_at")}
              </td>
              <td className="border-0">{reporte.eventos.nombre}</td>
              <td className="border-0">
                  <span className="text-dark">{reporte.nombre_cliente}</span>
              </td>
              <td className="border-0">
                  <span className="fw-normal">{reporte.dni_cliente}</span>
              </td>
              <td className="border-0">
                  <span className="fw-normal">
                    {reporte.telefono_cliente
                      ? reporte.telefono_cliente
                      : reporte.usuarios
                      ? reporte.usuarios.telefono
                      : ""}
                  </span>
              </td>
              <td className="border-0">
                {reporte.email_cliente
                  ? reporte.email_cliente
                  : reporte.usuarios
                  ? reporte.usuarios.email
                  : ""}
              </td>
              <td className="border-0">{reporte.codigo}</td>
              <td className="border-0">
                {reporte.entradas ? reporte.entradas.nombre : ""}
              </td>
              <td className="border-0">{reporte.fecha}</td>
              <td className="border-0 text-nowrap">$ {reporte.unidad}</td>
                      <td className="border-0">{reporte.cantidad}</td>
                      <td className="border-0 text-nowrap">$ {reporte.total}</td>
                      <td className="border-0 text-nowrap">
                        {reporte.descuento ? (
                          <div>
                            ${" "}
                            {((reporte.unidad * reporte.cantidad) /
                                100) *
                                reporte.descuento}
                          </div>
                        ) : (
                          "Sin descuento"
                        )}
                      </td>
                      <td className="border-0">
                      {reporte.descuento ? (
                          <div>
                            ${" "}
                            {reporte.unidad * reporte.cantidad -
                              ((reporte.unidad * reporte.cantidad) /
                                100) *
                                reporte.descuento}
                          </div>
                        ) : (
                          "Sin descuento"
                        )}
                      </td>
                      <td className="border-0">
                        {reporte.descuento? (
                          <div>{reporte.codigo_descuento}</div>
                        ) : (
                          ""
                        )}
                      </td>
                      

              <td className="border-0">
                {reporte.status == "ACTIVO" ? (
                  <i class="text-success fs-4 bi bi-check-circle-fill"></i>
                ) : reporte.status == "INACTIVO" ? (
                  <i class="text-danger fs-4 bi bi-x-circle-fill"></i>
                ) : reporte.status == "REEMBOLSO" ? (
                  <i class="text-warning fs-4 bi bi-exclamation-diamond-fill"></i>
                ) : reporte.status == "RECHAZADO" ? (
                  <i class="text-danger fs-4 bi bi-exclamation-circle-fill"></i>
                ) : reporte.status == "LIQUIDADO" ? (
                  <i class="text-success fs-4 fa-solid fa-hand-holding-dollar"></i>
                ) : reporte.status == "PAUSADO" ? (
                  <i class="text-secondary fs-4 bi bi-slash-circle-fill"></i>
                ) : (
                  ""
                )}
              </td>

              <td className="border-0">
                <div class="dropdown">
                  <button
                    class="btn btn-custom-amarillo dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado...
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <label className="btn">
                        <i class="text-success fs-4 me-2 bi bi-check-circle-fill"></i>
                        Activo
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, { id: reporte.id, status: "ACTIVO" })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        <i class="text-danger  fs-4 me-2 bi bi-x-circle-fill"></i>
                        Inactivo
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, {
                              id: reporte.id,
                              status: "INACTIVO",
                            })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        <i class="text-warning  fs-4 me-2 bi bi-exclamation-diamond-fill"></i>
                        Reembolsado
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, {
                              id: reporte.id,
                              status: "REEMBOLSO",
                            })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        <i class="text-success  fs-4 me-2 fa-solid fa-hand-holding-dollar"></i>
                        Liquidado
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, {
                              id: reporte.id,
                              status: "LIQUIDADO",
                            })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        <i class="text-danger  fs-4 me-2 bi bi-exclamation-circle-fill"></i>
                        Rechazado
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, {
                              id: reporte.id,
                              status: "RECHAZADO",
                            })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        <i class="text-secondary  fs-4 me-2 bi bi-slash-circle-fill"></i>
                        Pausado
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, {
                              id: reporte.id,
                              status: "PAUSADO",
                            })
                          }
                        ></button>
                      </label>
                    </li>
                  </ul>
                </div>
              </td>

              <td className="border-0">
                  <label className="btn btn-dark rounded-pill">
                    {reporte.decidir_id}
                    <button className="d-none" 
                      name="info_decidir"
                      onClick={(event) => onClick(event, reporte.decidir_id)}></button>
                  </label>
              </td>
              
              <td className="border-0">
                <a 
                className="btn btn-custom-naranja" 
                href={"https://antesala.com.ar/web/backend/public/api/Ticket?nombreEvento="+reporte.eventos.nombre+"&&direccionEvento="+reporte.eventos.direccion+"&&provinciaEvento="+reporte.eventos.provincia+"&&localidadEvento="+reporte.eventos.localidad+"&&precioEntrada="+reporte.entradas.precio+"&&fechaEntrada="+reporte.fecha+"&&nombre_cliente="+reporte.nombre_cliente+"&&dni_cliente="+reporte.dni_cliente+"&&cantidadEntrada="+reporte.cantidad+"&&codigo="+reporte.codigo+"&&id_user="+reporte.eventos.usuarios_id+"&&nombreEntrada="+reporte.entradas.nombre+"&&nombreSitio="+reporte.eventos.nombre_sitio}
                target="_blank"
                >

                </a>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default TablaReportes;
