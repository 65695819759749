import { useContext, useState } from "react";
import { Validate, porcentaje } from "../../../../utils/EventoUtils";
import Alerts from "../../../components/Alerts/Alerts";
import Input from "../../../components/custom/forms/Input";
import LiquidacionModal from "../modals/LiquidacionModal";
import { contextProvider } from "../../../../context/ContextProvider";
import RestApi from "../../../../helpers/RestApi";

const Liquidacion = ({
  setForm,
  form,
  setValidation,
  validation,
  modal,
  setModal,
}) => {
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { VALIDATION_FORM_EVENTO } = VALIDATIONs;
  const { URL_LIQUIDACION_UPDATE } = URLs;

  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "liquidacion-editar":
        {
          let input = form;

          input["liquidacion-comprobante_afip_url"][0] =
            input.liquidacion[0][value].comprobante_afip[0];

          input["liquidacion-comprobante_cuit_url"][0] =
            input.liquidacion[0][value].comprobante_entidad[0];

          input["liquidacion-comprobante_cbu_url"][0] =
            input.liquidacion[0][value].comprobante_cbu[0];

          input["liquidacion-id"][0] = input.liquidacion[0][value].id[0];
          input["liquidacion-cuit"][0] = input.liquidacion[0][value].entidad[0];
          input["liquidacion-cbu"][0] = input.liquidacion[0][value].cbu[0];
          input["liquidacion-fiscal"][0] =
            input.liquidacion[0][value].dato_fiscal[0];
          input["liquidacion-email"][0] = input.liquidacion[0][value].email[0];
          input["liquidacion-porcentaje"][0] =
            input.liquidacion[0][value].porcentaje[0];

          setForm({
            ...form,
            ["liquidacion-id"]: input["liquidacion-id"],
            ["liquidacion-cuit"]: input["liquidacion-cuit"],
            ["liquidacion-fiscal"]: input["liquidacion-fiscal"],
            ["liquidacion-comprobante_afip_url"]:
              input["liquidacion-comprobante_afip_url"],
            ["liquidacion-comprobante_cuit_url"]:
              input["liquidacion-comprobante_cuit_url"],
            ["liquidacion-comprobante_cbu_url"]:
              input["liquidacion-comprobante_cbu_url"],
            ["liquidacion-cbu"]: input["liquidacion-cbu"],
            ["liquidacion-email"]: input["liquidacion-email"],
            ["liquidacion-porcentaje"]: input["liquidacion-porcentaje"],
          });

          setModal({
            ...modal,
            liquidacion: {
              status: true,
              data: value,
            },
          });
        }
        break;
      case "liquidacion-delete":
        {
          let input = form;
          let result = input.liquidacion[0];

          if (form.id[0]) {
            return Alerts({
              msg: "Alert",
              title:
                "No se pueden eliminar datos de CBU, porque su evento se encuentra publicado",
              code: "401",
              type: "warning",
            });
          }

          if (result.length == 1) {
            return Alerts({
              msg: "Alert",
              title:
                "No se pueden eliminar todos los CBUs, debe especificar uno para publicar el evento",
              code: "401",
              type: "warning",
            });
          }

          delete result[value];
          result = result.filter((i) => i != null);

          setForm({
            ...form,
            liquidacion: [result, "required"],
          });
        }
        break;
      case "liquidacion-añadir":
        {
          let input = form;

          const input_validation = Validate(
            form,
            JSON.parse(JSON.stringify(VALIDATION_FORM_EVENTO)),
            "liquidacion"
          );
          setValidation(input_validation);
          //Validaciones
          if (input_validation.error.status) {
            return Alerts({
              msg: "Warning",
              title: input_validation.error.msg,
              code: "404",
              type: "error",
            });
          }

          let result = {
            id: [null, "required"],
            evento_id: [null, "required"],
            porcentaje: [form["liquidacion-porcentaje"][0], "required"],
            created_at: ["", "required"],
            updated_at: ["", "required"],
            entidad: [form["liquidacion-cuit"][0], "required"],
            cbu: [form["liquidacion-cbu"][0], "required"],
            identificador: [null, "required"],
            comprobante_cbu: [
              form["liquidacion-comprobante_cbu"][0],
              "required",
            ],
            comprobante_afip: [
              form["liquidacion-comprobante_afip"][0],
              "required",
            ],
            comprobante_entidad: [
              form["liquidacion-comprobante_cuit"][0],
              "required",
            ],
            comprobante_cbu_url: [
              form["liquidacion-comprobante_cbu_url"][0],
              "required",
            ],
            comprobante_afip_url: [
              form["liquidacion-comprobante_afip_url"][0],
              "required",
            ],
            comprobante_entidad_url: [
              form["liquidacion-comprobante_cuit_url"][0],
              "required",
            ],
            dato_fiscal: [form["liquidacion-fiscal"][0], "required"],
            email: [form["liquidacion-email"][0], "required"],
            porcentaje_argentores: [null, "required"],
          };

          input.liquidacion[0].push(result);

          console.log(form);

          if (form.id[0]) {
            /* CREATE */
            let formData = new FormData();
            formData.append("evento_id", form.id[0]);
            formData.append("dato_fiscal", form["liquidacion-fiscal"][0]);
            formData.append("cbu", form["liquidacion-cbu"][0]);
            formData.append("entidad", form["liquidacion-cuit"][0]);
            formData.append("email", form["liquidacion-email"][0]);
            formData.append("porcentaje", form["liquidacion-porcentaje"][0]);
            formData.append(
              "comprobante_cbu",
              form["liquidacion-comprobante_cbu"][0]
            );
            formData.append(
              "comprobante_afip",
              form["liquidacion-comprobante_afip"][0]
            );
            formData.append(
              "comprobante_entidad",
              form["liquidacion-comprobante_cuit"][0]
            );

            const response = await RestApi({
              url: URL_LIQUIDACION_UPDATE,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              input.liquidacion[0][input.liquidacion[0].length - 1]["id"][0] =
                response.data.data.id;

              setForm({
                ...form,
                liquidacion: input.liquidacion,
                ["liquidacion-id"]: [null, ""],
                ["liquidacion-cuit"]: ["", "required"],
                ["liquidacion-fiscal"]: ["", "required"],
                ["liquidacion-comprobante_afip"]: ["", "required"],
                ["liquidacion-comprobante_cuit"]: ["", "required"],
                ["liquidacion-comprobante_cbu"]: ["", "required"],
                ["liquidacion-comprobante_afip_url"]: ["", "required"],
                ["liquidacion-comprobante_cuit_url"]: ["", "required"],
                ["liquidacion-comprobante_cbu_url"]: ["", "required"],
                ["liquidacion-cbu"]: ["", "required"],
                ["liquidacion-email"]: ["", "required"],
                ["liquidacion-porcentaje"]: [0, "required"],
              });

              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "success",
              });
            } else {
              if (typeof response.data.msg === "string") {
                Alerts({
                  msg: "Alert",
                  title: response.data.msg,
                  code: "200",
                  type: "success",
                });
              }

              Object.keys(response.data.msg).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data.msg[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          } else {
            setForm({
              ...form,
              liquidacion: input.liquidacion,
              ["liquidacion-id"]: [null, ""],
              ["liquidacion-cuit"]: ["", "required"],
              ["liquidacion-fiscal"]: ["", "required"],
              ["liquidacion-comprobante_afip"]: ["", "required"],
              ["liquidacion-comprobante_cuit"]: ["", "required"],
              ["liquidacion-comprobante_cbu"]: ["", "required"],
              ["liquidacion-comprobante_afip_url"]: ["", "required"],
              ["liquidacion-comprobante_cuit_url"]: ["", "required"],
              ["liquidacion-comprobante_cbu_url"]: ["", "required"],
              ["liquidacion-cbu"]: ["", "required"],
              ["liquidacion-email"]: ["", "required"],
              ["liquidacion-porcentaje"]: [0, "required"],
            });

            Alerts({
              msg: "Alert",
              title: "Datos de liquidación añadidos",
              code: "401",
              type: "success",
            });
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "liquidacion-comprobante_afip":
      case "liquidacion-comprobante_cuit":
      case "liquidacion-comprobante_cbu": {
        let input = form;
        input[e.target.name][0] = e.target.files[0];
        input[e.target.name + "_url"][0] = URL.createObjectURL(
          e.target.files[0]
        );

        setForm({
          ...form,
          [e.target.name]: input[e.target.name],
          [e.target.name + "_url"]: input[e.target.name + "_url"],
        });
      } break;
      case "liquidacion-porcentaje":
        {
          let input = form;

          if (porcentaje(form.liquidacion[0], e.target.value) < 0) {
            return Alerts({
              msg: "Alert",
              title: "Porcentaje de ganancia superado",
              code: "401",
              type: "warning",
            });
          }

          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
      case "liquidacion-fiscal":
      case "liquidacion-email":
      case "liquidacion-cbu":
      case "liquidacion-cuit":
        {
          let input = form;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
    }
  };

  return (
    <div className="container-md  w-100">
      <div
        className="card shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-12 mb-3 px-1">
            <h5 className="">Datos de liquidación</h5>
            <span>
              Añade datos de liquidacion a tu evento</span>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end mb-3">
            <div></div>
            <label
              className="btn btn-outline-dark border-custom-naranja text-custom-naranja fw-normal GothamBold px-4 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Nuevo CBU
              <button
                className="d-none"
                name="guardar"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>

          <div className="col-md-12">
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Cuil/Cuit del títular"}
                        name={"liquidacion-cuit"}
                        type={"text"}
                        value={form["liquidacion-cuit"][0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-cuit"]}
                        disabled={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Email Títular"}
                        name={"liquidacion-email"}
                        type={"email"}
                        value={form["liquidacion-email"][0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-email"]}
                        disabled={false}
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        className="form-label text-muted"
                        style={{ fontSize: "14px" }}
                      >
                        Dato fiscal
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="liquidacion-fiscal"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected></option>
                        <option value="Responsable Inscripto">
                          Responsable Inscripto
                        </option>
                        <option value="IVA Exento">IVA Exento</option>
                        <option value="Monotributo">Monotributo</option>
                      </select>

                      {validation["liquidacion-fiscal"].status ? (
                        <div className="text-danger">
                          {validation["liquidacion-fiscal"].msg}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Número de CBU"}
                        name={"liquidacion-cbu"}
                        type={"text"}
                        value={form["liquidacion-cbu"][0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-cbu"]}
                        disabled={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Porcentaje de cobro"}
                        name={"liquidacion-porcentaje"}
                        type={"number"}
                        value={form["liquidacion-porcentaje"][0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-porcentaje"]}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Comprobante de CBU"}
                        name={"liquidacion-comprobante_cbu"}
                        type={"file"}
                        //value={""}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-comprobante_cbu"]}
                        disabled={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Comprobante de CUIT"}
                        name={"liquidacion-comprobante_cuit"}
                        type={"file"}
                        //value={""}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-comprobante_cuit"]}
                        disabled={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Input
                        onChange={onChange}
                        label={"Comprobante de AFIP"}
                        name={"liquidacion-comprobante_afip"}
                        type={"file"}
                        //value={""}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["liquidacion-comprobante_afip"]}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end">
                    <label className="btn btn-custom-naranja fw-normal GothamBold text-white py-2">
                      Añadir CBU
                      <button
                        className="d-none"
                        name="liquidacion-añadir"
                        onClick={(event) => onClick(event)} 
                      ></button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-2 text-center border py-2">
            <span className="fw-bold fs-5">
              Porcentaje de ganancia restante{" "}
              {porcentaje(form.liquidacion[0], 0)}%
            </span>
          </div>

          {form.liquidacion[0].map((l, index) => (
            <div className="col-md-12 mt-3">
              <ul class="list-group border">
                <li class="list-group-item d-flex justify-content-end border-0">
                  <label className="btn btn-outline-success me-2">
                    <i class="bi bi-pencil"></i>
                    <button
                      className="d-none"
                      name="liquidacion-editar"
                      onClick={(event) => onClick(event, index)}
                    ></button>
                  </label>
                  <label className="btn btn-outline-danger">
                    <i class="bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="liquidacion-delete"
                      onClick={(event) => onClick(event, index)}
                    ></button>
                  </label>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div className="me-2">
                    <span className="fw-bold">Titular:</span> {l.entidad[0]}
                  </div>
                  <div>
                    <span className="fw-bold">Email:</span> {l.email[0]}
                  </div>
                </li>
                <li class="list-group-item border-0">
                  <span className="fw-bold">Dato Fiscal:</span>{" "}
                  {l.dato_fiscal[0]}
                </li>
                <li class="list-group-item border-0">
                  <span className="fw-bold">CBU:</span> {l.cbu[0]}
                </li>
                <li class="list-group-item border-0 fs-5">
                  <span className="fw-bold">Porcentaje de cobro:</span>{" "}
                  {l.porcentaje[0]}%
                </li>
                <li class="list-group-item border-0">
                  <a
                    className="btn btn-outline-success me-2"
                    href={l.comprobante_cbu_url[0]}
                    target="blank"
                  >
                    Comprobante CBU <i class="bi bi-arrow-bar-down"></i>
                  </a>
                  <a
                    className="btn btn-outline-success me-2"
                    href={l.comprobante_entidad_url[0]}
                    target="blank"
                  >
                    Comprobante CUIT <i class="bi bi-arrow-bar-down"></i>
                  </a>
                  <a
                    className="btn btn-outline-success me-2"
                    href={l.comprobante_afip_url[0]}
                    target="blank"
                  >
                    Comprobante AFIP <i class="bi bi-arrow-bar-down"></i>
                  </a>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>

      {modal.liquidacion.status ? (
        <LiquidacionModal
          modal={modal}
          setModal={setModal}
          data={modal.liquidacion.data}
          validation={validation}
          form={form}
          setForm={setForm}
          url={URL_LIQUIDACION_UPDATE}
          setLoading={setLoading}
          loading={loading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Liquidacion;
