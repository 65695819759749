import { Link, useParams } from "react-router-dom";

const ContainerItem = ({data}) => {
  const {id, nombre, imagen_logo, provincia, localidad} = data;

  return (
    <div class="col-md-3 d-flex flex-column align-items-center mb-4 wow bounceInUp">
      <Link to={"/sala/"+id} class="logo-sala">
        <img src={"/assets/img/usuarios/"+imagen_logo} width="100%" alt="" />
      </Link>
      <p class="mt-2 mb-0"><span class="Grifter">{provincia}</span> | {localidad}</p>
      <h3>{nombre}</h3>
    </div>
  )
}

export default ContainerItem