import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Entradas from "../page/Entradas";

const EntradasController = () => {
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <div className="container-md min-vh-100">
        <Entradas />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default EntradasController;
