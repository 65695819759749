const CardTicket = ({data}) => {
  const {cantidad, codigo, entradas, eventos, fecha, unidad, dni_cliente, nombre_cliente, status} = data
 
  const url = "https://antesala.com.ar/backend/public/api/Ticket?nombreEvento="+eventos.nombre+"&&direccionEvento="+eventos.direccion+"&&provinciaEvento="+eventos.provincia+"&&localidadEvento="+eventos.localidad+"&&precioEntrada="+entradas.precio+"&&fechaEntrada="+fecha+"&&nombre_cliente="+nombre_cliente+"&&dni_cliente="+dni_cliente+"&&cantidadEntrada="+cantidad+"&&codigo="+codigo+"&&id_user="+eventos.usuarios_id+"&&nombreEntrada="+entradas.nombre+"&&nombreSitio="+eventos.nombre_sitio;

  return (
    <div className="col-md-12 mb-3 overflow-hidden row p-0 m-0 bg-white shadow-sm" style={{borderRadius: "10px"}}>
    <div className="col-md-12  d-flex justify-content-start align-items-center bg-naranja bg-opacity-25">
        <span><i className="fs-4 text-light bi bi-ticket-perforated me-2"></i></span>
        <span className="fs-8 text-light"> Entrada valida hasta la fecha del evento </span>
    </div>
    <div className="col-md-3 d-flex align-items-center">
      <img src={"/assets/img/eventos/"+eventos.imagen} className="img-fluid" alt="IMAGEN NO ENCONTRADA"/>
    </div>
    <div className="col-md-9 p-0">
      <ul className="list-group">
        <li className="list-group-item d-flex justify-content-between border-0 mb-0 pb-0">
          <div>{eventos.nombre}</div>
          <a href={url} target="_blank" className="btn btn-custom-black text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 mt-1 px-2 py-1 fs-10"><i className="bi bi-file-earmark-pdf"></i> Descargar PDF </a>
        </li>
        <li className="list-group-item m-0 py-0 mb-1 border-0"><span className="text-muted fs-10">{fecha}</span></li>
        <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10"><span className="text-muted fs-10">Entrada</span> {entradas.nombre} </li>
        <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10"><span className="text-muted fs-10">Cantidad</span> {cantidad}</li>
        <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10"><span className="text-muted fs-10">Precio</span> ARS {unidad} </li>
        <li className="list-group-item m-0 py-0 mb-1 border-0 fs-10"><span className="text-muted fs-10">Codigo</span> {codigo} </li>
        <li className="list-group-item border-0 d-flex justify-content-between border-top">
          <div className="d-flex">
            <span className="text-muted fs-10 me-2">Total</span>
            <span className="fs-10 fw-bold me-2">ARS {cantidad*unidad}</span>
          </div>
          <div className="d-flex ">
            <span className="text-muted fs-10 me-2"></span>
            <span className="fs-10 fw-bold me-2">
                {
                  status == "ACTIVO"
                  ? "Activo"
                  : ""
                }
            </span>
          </div>
        </li>
      </ul>
    </div>
</div>
  )
}

export default CardTicket