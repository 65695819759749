import BtnAccept from "../../../../forms/BtnAccept";

const Sidebar = ({ data, label, setLabel }) => {
  const {imagen_logo, apellido, nombre, email, status, historial_compras, membresias} = data;

  const style = {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  };

  const total_entradas = historial_compras.length
  const total_membresias = membresias.length;

  const img = imagen_logo? "/assets/img/usuarios/"+imagen_logo : "/assets/img/icon-user.svg"
  return (
    <div className="bg-naranja min-vh-100 py-4" style={style}>
      <ul class="list-group bg-transparent">
        <li class="list-group-item bg-transparent border-0">
          <div className="d-flex justify-content-center overflow-hidden">
            <img
              className="img-fluid border rounded-pill p-1 d-md-block d-none"
              src={img}
              alt=""
              style={{ maxWidth: "150px", maxHeight: "250px", height: "150px" }}
            />
            <i class="bi bi-person-circle fs-2 text-white d-md-none d-block"></i>
          </div>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <div className="w-100 text-center text-white d-md-block d-none">
            {nombre+" "+apellido}
          </div>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <div className="w-100 text-center text-white d-md-block d-none">
            {email}
          </div>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <div className="w-100 text-center text-white d-md-block d-none">
            Estado de mi cuenta
          </div>
          <div className="w-100 text-center text-white">{status.name}</div>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <label
            className={
              label == "perfil"
                ? "w-100 text-center text-white btn border-0 py-1 bg-dark"
                : "w-100 text-center text-white btn border-0 py-1"
            }
          >
            <i class="bi bi-person-lines-fill me-1"></i>
            <span className="d-md-inline d-none">Perfil</span>
            <button
              className="d-none"
              name=""
              onClick={(event) => setLabel("perfil")}
            ></button>
          </label>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <label
            className={
              label == "entradas"
                ? "w-100 text-center text-white btn border-0 py-1 bg-dark"
                : "w-100 text-center text-white btn border-0 py-1"
            }
          >
            <i class="bi bi-tags-fill me-1"></i>
            <span className="d-md-inline d-none">Mis entradas 
            <span class="badge rounded-pill bg-dark ms-2">{total_entradas}</span>
            </span>
            <button
              className="d-none"
              name=""
              onClick={(event) => setLabel("entradas")}
            ></button>
          </label>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <label
            className={
              label == "membresias"
                ? "w-100 text-center text-white btn border-0 py-1 bg-dark"
                : "w-100 text-center text-white btn border-0 py-1"
            }
          >
            <i class="bi bi-bookmark-star-fill me-1"></i>
            <span className="d-md-inline d-none">Membresias
            <span class="badge rounded-pill bg-dark ms-2">{total_membresias}</span>
            </span>
            <button
              className="d-none"
              name=""
              onClick={(event) => setLabel("membresias")}
            ></button>
          </label>
        </li>
        <li class="list-group-item bg-transparent border-0">
          <label
            className={
              label == "cupones"
                ? "w-100 text-center text-white btn border-0 py-1 bg-dark"
                : "w-100 text-center text-white btn border-0 py-1"
            }
          >
            <i class="bi bi-ticket-perforated me-1"></i>
            <span className="d-md-inline d-none">Cupones</span>
            <button
              className="d-none"
              name=""
              onClick={(event) => setLabel("cupones")}
            ></button>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
