import { useState } from "react";

const ModalHistorial = ({ onClick, data }) => {
  let historialParse;

  if (data) {
    historialParse = JSON.parse(data).sort((a, b) => {
      if (new Date(a.fecha_actividad) == new Date(b.fecha_actividad)) {
        return 0;
      }
      if (new Date(a.fecha_actividad) > new Date(b.fecha_actividad)) {
        return -1;
      }
      return 1;
    });
  }

  const [historial, setHistorial] = useState(historialParse);

  

  //STATE

  const [filter, setFilter] = useState("");

  //ARROW FUNCTION HELPER

  const formatDate = (date) => {
    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const mes = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    let dt = new Date(date);
    dt.toString();
    return (
      dias[dt.getDay()] +
      " " +
      dt.getDate() +
      " de " +
      mes[dt.getMonth()] +
      " de " +
      dt.getFullYear() +
      " Hora: " +
      dt.getHours() +
      ":" +
      dt.getMinutes()
    );
  };

  //FILTER

  const onChange = (e) => {
    let historialP = historialParse.filter(function (element) {
      const actividad = element.actividad;
      const autor = element.autor;
      const search = (actividad + " " + autor).toUpperCase();
      const stringsearched = e.target.value.toUpperCase();
      return search.indexOf(stringsearched) > -1;
    });

    setHistorial(historialP);
  };

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex">
            <div>
              <h1>Historial</h1>
              <span class="fs-6 text-muted">
                Puedes ver el historial de actividad del usuario, si realizo
                modificaciones en sus eventos o si ingreso nuevos datos en su
                perfil.{" "}
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            {historial ? (
              <div className="list-group bg-white mb-3 p-3">
                <input
                  type="text"
                  className="fs-6 form-control form-search"
                  placeholder="Busca un evento en especifico"
                  onChange={(event) => onChange(event)}
                />
              </div>
            ) : (
              ""
            )}

            <ul class="list-group">
              {historial ? (
                historial.map((element) => (
                  <li class="list-group-item m-0 d-flex justify-content-between">
                    <div class="text-muted fs-7 me-2 border-end">
                      Actividad -{" "}
                      <span class="fw-normal text-dark">
                        {" "}
                        {element.actividad}{" "}
                      </span>
                    </div>

                    <div class="text-muted fs-7 me-2 border-end">
                      Fecha de actividad -{" "}
                      <span class="fw-normal text-dark">
                        {" "}
                        {formatDate(element.fecha_actividad)}{" "}
                      </span>
                    </div>

                    <div class="text-muted fs-7 text-nowrap">
                      Autor -{" "}
                      <span class="fw-normal text-dark"> {element.autor} </span>
                    </div>
                  </li>
                ))
              ) : (
                <li class="list-group-item m-0 d-flex justify-content-between border-0">
                  <div class="text-muted fs-6 me-3">
                    {" "}
                    <span class="fw-normal text-dark">
                      <i class="bi bi-emoji-frown"></i> No hay historias
                      disponibles para este usuario o evento.{" "}
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <button
                class="btn btn-secondary rounded-pill px-4"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHistorial;
