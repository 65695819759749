const ModalNotificacion = ({onClick, onChange, onSend, loading, alert, data, formNotification}) => {
  const {usuarios} = data
  const date = new Date();
  date.setDate(date.getDate()+1)
  const mes = ["01","02","03","04","05","06","07","08","09","10","11","12"]

  return (
    <div className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style={{ zIndex: "104" }}>
      
      <div  className="bg-light modal-dialog position-relative animation-modal my-md-5 overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important", zIndex: "106" }}>
          
        <div className="modal-content bg-light">
        <div class="title bg-white m-0 p-4 d-flex justify-content-between">
          <div>
            <h1>Crea una notificación</h1>
          <span class="fs-6 text-muted">Deja una notificación al usuario para avisarle de algún cambio o solo para sugerir algun cambio. </span>
          </div>
          <div>
            <button class="border-0 bg-transparent" 
                  name="close_modal"
                  onFocus={(event) => onClick(event, null)}><i class="bi bi-x-lg"></i></button>
          </div>
        </div>
        <div class="body p-md-4 p-2">

          <ul class="list-group">

            <li class="list-group-item m-0 mb-3 border-0 shadow-sm">
                <div class=" text-muted fs-6 me-3">Escribe el nombre de tu notificacion <span class="fw-normal  text-dark"><input class="form-control rounded-pill border px-2 border-custom-naranja" type="text" placeholder="Notificacion 1" name="nombre_notificacion" onChange={(event) => onChange(event)}/> </span>
                {alert.notificacion ? (
                    formNotification.nombre_notificacion.trim() == "" ? (
                      <span className="text-danger fs-7">
                        El nombre de tu notificación es necesario.
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div class="py-2 my-1">
                  <div class="text-muted fs-6 me-3">Describe la notificación</div>
                        <label for="exampleFormControlTextarea1" class="form-label text-muted fs-8">Escribi una descripcion corta y explicativa de la notificacion. </label>
                        <textarea class="form-control border-custom-naranja " id="exampleFormControlTextarea1" placeholder="Ejemplo... Verifica el nombre de usuario de tu cuenta." rows="3" name="descripcion_notificacion" onChange={(event) => onChange(event)}></textarea>
                {alert.notificacion ? (
                    formNotification.descripcion_notificacion.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Describe tu notificacion.
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                   
                </div>

                
              <div class="d-flex">
                <div class="text-muted fs-6 me-3"><div class="px-2">Tipo</div><span class="fw-normal text-dark">
                  <select class="form-select px-2 border-custom-naranja rounded-pill" aria-label=".form-select-sm example" name="tipo_notificacion" onChange={(event) => onChange(event)}>
                    <option selected>Selecciona el tipo de notificación</option>
                    <option value="ERROR">Error</option>
                    <option value="IMPORTANTE">Importante</option>
                    <option value="AVISO">Aviso</option>
                  </select></span>
                  {alert.notificacion ? (
                    formNotification.tipo_notificacion.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Selecciona uno.
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              <div class="text-muted fs-6 me-3"><div>Fecha de expiración</div><span class="fw-normal text-dark"><input class="form-control border border-custom-naranja rounded-pill" min={date.getFullYear()+"-"+(mes[date.getMonth()+1])+"-"+date.getDate()} type="date"  name="fecha_expiracion" onChange={(event) => onChange(event)}/></span>
              {alert.notificacion ? (
                    formNotification.fecha_expiracion.trim() == "" ? (
                      <span className="text-danger fs-7">
                        Es necesario avisar cuando expira esta notificación.
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
              </div>
              </div>

            </li>

            <li class="list-group-item m-0 mb-3 border-0 shadow-sm">
              <div class="d-flex justify-content-between border-0 py-2 my-1">
                <div class="text-muted fs-6 me-3">Destinatario <span class="fw-normal text-dark"> {usuarios.nombre+" "+usuarios.apellido} </span></div>
                <div class="text-muted fs-6 me-3">Email <span class="fw-normal text-dark"> {usuarios.email} </span></div>
              </div>
            </li>

          </ul>

        </div>
        <div class="footer bg-white p-4">
          <div class="d-flex justify-content-between">
            <div></div>
            <div class="">
            <button class="btn btn-secondary rounded-pill px-4" 
                  name="close_modal"
                  onClick={(event) => onClick(event, null)}
                >Cerrar</button>
           <button 
              class="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4" onClick={(event) => onSend(event)}
              disabled={
                loading?
                true
                :
                false
              }
              >Enviar</button>
            
            
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>
  )
}

export default ModalNotificacion