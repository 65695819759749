import Swal from "sweetalert2";
import AlertError from "../Alerts/AlertError";
import AlertSuccessXl from "../Alerts/AlertSuccessXl";
import clientAxios from "./Config";

export const Decidir = async (id) => {
  let params = {
    id: id,
  };

  const response = await clientAxios.get("/api/auth/Administrador/Decidir", {
    params,
  });

  return response.data;
};

export const Payment = async (
  formData,
  setLoading,
  setStatePayment,
  setErrPayment
) => {
  const response = await clientAxios({
    method: "post",
    url: "/api/Decidir/Pagar",
    data: formData,
  })
    .then((result) => {
      setLoading(false);
      setStatePayment(true);
      return AlertSuccessXl(result.data.msg, "¡Confirmación de entrada!");
    })
    .catch((error) => {
      setLoading(false);

      if (error.response.status == 400) {
        /* Error type 1 */
        if (error.response.data.data.hasOwnProperty("status_details")) {
          let parse_error = "";
          let reason = error.response.data.data.status_details.error
            ? error.response.data.data.status_details.error.reason.description
            : "No existen errores";
          parse_error +=
            '<li class="list-group-item border-0">Razon: ' + reason + "</li>";

          setErrPayment({
            status: error.response.data.data.status,
            reason: reason,
          });

          return Swal.fire({
            icon: "error",
            title: "Transacción invalida, consulte con su medio de pago.",
            html:
              '<ul class="list-group">' +
              '<li class="list-group-item border-0">Estado: ' +
              error.response.data.data.status +
              "</li>" +
              parse_error +
              "</ul>" +
              '<li class="list-group-item border-0"></li>' +
              "</ul>",
            showConfirmButton: false,
          });
        } else {
          /* Error type 2 */
          if (error.response.data.data.hasOwnProperty("validation_errors")) {
            let parse_error = "";
            let reason = error.response.data.data.validation_errors
              ? error.response.data.data.validation_errors[0].code
              : "No existen errores";
            parse_error +=
              '<li class="list-group-item border-0">Razon: ' + reason + "</li>";

            setErrPayment({
              status: error.response.data.data.error_type,
              reason: reason,
            });

            return Swal.fire({
              icon: "error",
              title: "Transacción invalida, consulte con su medio de pago.",
              html:
                '<ul class="list-group">' +
                '<li class="list-group-item border-0">Estado: ' +
                error.response.data.data.error_type +
                "</li>" +
                parse_error +
                "</ul>" +
                '<li class="list-group-item border-0"></li>' +
                "</ul>",
              showConfirmButton: false,
            });
          } else {
            setErrPayment({
              status: "ERROR",
              reason: error.response.data.msg,
            });

            return AlertError(error.response.data.msg, "Error");
          }
        }

        //return AlertError(error.response.data.msg,"Error");
      } 
      
      setErrPayment({
        status: "ERROR",
        reason: "SERVIDOR CAIDO",
      });
    });

  return response;
};

export const PaymentFree = async (formData, setLoading, entradas) => {
  const response = await clientAxios({
    method: "post",
    url: "/api/Decidir/Free",
    data: formData,
  })
    .then((result) => {
      //setModalFree(false);
      if(entradas.length == 0){
        setLoading(false);
        return AlertSuccessXl(result.data.msg, "¡Confirmación de entrada GRATIS!");
      }
    })
    .catch((error) => {
      setLoading(false);
      if (error.response.status == 400) {
        return AlertError(error.response.data.msg, "Error");
      }
    });

  return response;
};
