import axios from "axios";

//'https://antesala.com.ar/backend/public'
//'http://127.0.0.1:8000/

let apiAntesala = axios.create({
  baseURL: 'http://127.0.0.1:8000/api'
});

apiAntesala.defaults.headers.common["Content-Type"] = "application/json";

apiAntesala.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token_api");
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

export default apiAntesala;