import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CollectionStateContext } from "../../../context/EventosState";
import { upperRegistro } from "../../../helpers/AccessHelper";
import {
  obtenerMunicipios,
  obtenerProvincias,
} from "../../../helpers/NormGeograficoHelper";

const validationPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");

const UpperRegister = () => {
  const context = useContext(CollectionStateContext);
  const { administradorUsuarios, geolocationApi,administradorUsuariosSuscriptos, provincias, state } = context;

  const localStorageProvincias = localStorage.getItem("provincias"); 
  const [apiProvincia, setProvincias] = useState(
    localStorageProvincias ? JSON.parse(localStorageProvincias) : state.provincias
  );

  //const [apiProvincia, setApiProvincia] = useState(null);
  const [apiLocalidad, setApiLocalidad] = useState({
    class: "d-none",
    content: null,
  });

  const [alert, setAlert] = useState({
    msg: "",
    class: "d-none",
  });

  const [alertInput, setAlertInput] = useState("d-none");

  const [pass, setPass] = useState({
    icon: "bi bi-eye-slash",
    type: "password",
    status: false,
  });

  const [formClass, setFormClass] = useState({
    _1: "tab-pane fade show active",
    _2: "tab-pane fade",
    _3: "tab-pane fade",
  });

  const [formGlobal, setFormGlobal] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    direccion: "",
    provincia: "",
    localidad: "",
    password: "",
    password_confirmation: "",
    tipo: "",
  });

  const [formPrd, setFormPrd] = useState({
    nombre: "",
    email: "",
    telefono: "",
    direccion: "",
    provincia: "",
    localidad: "",
  });

  const [formSala, setFormSala] = useState({
    razon_social: "",
    nombre: "",
    email: "",
    telefono: "",
    direccion: "",
    provincia: "",
    localidad: "",
  });


  const showPassword = (e) => {
    e.preventDefault();

    if (pass.status == false) {
      setPass({
        ...pass,
        icon: "bi bi-eye",
        type: "text",
        status: true,
      });
    } else {
      setPass({
        ...pass,
        icon: "bi bi-eye-slash",
        type: "password",
        status: false,
      });
    }
  };

  const onChange = async (e, form) => {
    e.preventDefault();

    switch (form) {
      case "_1":
        {
          switch (e.target.name) {
            case "direccion":
            case "password_confirmation":
            case "password":
            case "email":
            case "telefono":
            case "apellido":
            case "tipo":
            case "provincia":
            case "nombre":
              {
                setFormGlobal({
                  ...formGlobal,
                  [e.target.name]: e.target.value,
                });
              }
              break;
            case "Selectlocalidad":
              {
                setFormGlobal({
                  ...formGlobal,
                  localidad: e.target.value,
                });

                setApiLocalidad({
                  class: "d-none",
                  content: null,
                });
              }
              break;
            case "SearchLocalidad":
              {
                setFormGlobal({
                  ...formGlobal,
                  localidad: e.target.value,
                });

                const axios = async () => {
                  let result = await obtenerMunicipios(
                    formGlobal.provincia,
                    e.target.value
                  );
                  setApiLocalidad({
                    class: "form-control",
                    content: result,
                  });
                };
                axios();
              }
              break;
          }
        }
        break;
      case "_2":
        {
          setFormPrd({
            ...formPrd,
            [e.target.name]: e.target.value,
          });

          if (e.target.name === "provincia") {
            let result = await obtenerMunicipios(e.target.value);
            setApiLocalidad(result);
          }
        }
        break;
      case "_3":
        {
          setFormSala({
            ...formSala,
            [e.target.name]: e.target.value,
          });

          if (e.target.name === "provincia") {
            let result = await obtenerMunicipios(e.target.value);
            setApiLocalidad(result);
          }
        }
        break;
    }
  };

  const onNext = (e) => {
    e.preventDefault();

    if (!validationPassword.test(formGlobal.password)) {
      return setAlert({
        msg: "Las contraseñas debe contener al menos una mayúscula (A-Z) y un número (0-9) y tienen que estar conformadas con 6 o más caracteres.",
        class: "w-100 alert alert-danger text-start my-1",
      });
    }

    if (
      formGlobal.password.trim() !== formGlobal.password_confirmation.trim()
    ) {
      return setAlert({
        msg: "Las contraseñas no coinciden.",
        class: "w-100 alert alert-danger text-start my-1",
      });
    }

    setAlert({
      msg: "",
      class: "d-none",
    });

    if (
      formGlobal.nombre.trim() == "" ||
      formGlobal.apellido.trim() == "" ||
      formGlobal.email.trim() == "" ||
      formGlobal.telefono.trim() == "" ||
      formGlobal.direccion.trim() == "" ||
      formGlobal.provincia.trim() == "" ||
      formGlobal.localidad.trim() == "" ||
      formGlobal.tipo.trim() == ""
    ) {
      return setAlertInput("text-danger");
    }

    switch (formGlobal.tipo) {
      case "prd":
        {
          setFormClass({
            _1: "tab-pane fade",
            _2: "tab-pane fade show active",
            _3: "tab-pane fade",
          });

          return setFormPrd({
            nombre: "",
            email: "",
            telefono: "",
            direccion: "",
            provincia: "",
            localidad: "",
          });
        }
        break;
      case "sala":
        {
          setFormClass({
            _1: "tab-pane fade",
            _2: "tab-pane fade",
            _3: "tab-pane fade show active",
          });
        }
        break;
    }
  };

  const onSubmit = (e, form) => {
    e.preventDefault();

    let dataForm = new FormData();

    dataForm.append("nombre", formGlobal.nombre);
    dataForm.append("apellido", formGlobal.apellido);
    dataForm.append("email", formGlobal.email);
    dataForm.append("telefono", formGlobal.telefono);
    dataForm.append("tipo", formGlobal.tipo);
    dataForm.append("direccion", formGlobal.direccion);
    dataForm.append("provincia", formGlobal.provincia);
    dataForm.append("localidad", formGlobal.localidad);
    dataForm.append("password", formGlobal.password);
    dataForm.append("password_confirmation", formGlobal.password_confirmation);

    switch (form) {
      case "_2":
        {
          if (formPrd.nombre.trim() == "") {
            return setAlertInput("text-danger");
          }

          dataForm.append("prd_nombre", formPrd.nombre);
          dataForm.append("prd_email", formGlobal.email);
          dataForm.append("prd_telefono", formGlobal.telefono);
          dataForm.append("prd_direccion", formGlobal.direccion);
          dataForm.append("prd_provincia", formGlobal.provincia);
          dataForm.append("prd_localidad", formGlobal.localidad);

          upperRegistro(dataForm);
        }
        break;
      case "_3":
        {
          if (
            formSala.razon_social.trim() == "" ||
            formSala.nombre.trim() == ""
          ) {
            return setAlertInput("text-danger");
          }

          dataForm.append("razon_social", formSala.razon_social);
          dataForm.append("prd_nombre", formSala.nombre);
          dataForm.append("prd_email", formGlobal.email);
          dataForm.append("prd_telefono", formGlobal.telefono);
          dataForm.append("prd_direccion", formGlobal.direccion);
          dataForm.append("prd_provincia", formGlobal.provincia);
          dataForm.append("prd_localidad", formGlobal.localidad);

          upperRegistro(dataForm);
        }
        break;
    }
  };


  
  useEffect(() => {
    if (!apiProvincia) {
      provincias(setProvincias);
    }
  }, [apiProvincia]);
  return (
    <div className="container-md texto-normal min-vh-100 border-0">
      <div className="tab-content" id="nav-tabContent">
        <div
          className={formClass._1}
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div className="d-flex justify-content-center">
            <form action="" className="width-md">
              <div className="text-center mb-3">
                <h1>Obtener cuenta</h1>
              </div>

              <div className="mb-3">
                <label for="inputNombre" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputNombre"
                  placeholder="Ingrese su Nombre"
                  name="nombre"
                  onChange={(event) => onChange(event, "_1")}
                />
                {!formGlobal.nombre ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputApellido" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputApellido"
                  placeholder="Ingrese su Apellido"
                  name="apellido"
                  onChange={(event) => onChange(event, "_1")}
                />
                {!formGlobal.apellido ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputEmail" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill"
                  id="inputEmail"
                  placeholder="Ingrese su Email"
                  name="email"
                  onChange={(event) => onChange(event, "_1")}
                />
                {!formGlobal.email ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Telefono
                </label>
                <div className="input-group">
                  <div
                    className="input-group-text btn px-2 btn-custom-naranja text-white"
                    id="basic-addon1"
                  >
                    +54
                  </div>
                  <input
                    type="number"
                    className="form-control col-md-9 rounded-start rounded-pill "
                    id="exampleFormControlInput1"
                    placeholder="Ingrese su número de Telefono"
                    name="telefono"
                    onChange={(event) => onChange(event, "_1")}
                  />
                </div>
                {!formGlobal.telefono ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputDireccion" className="form-label">
                  Direccion
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputDireccion"
                  placeholder="Ingrese su Direccion"
                  name="direccion"
                  onChange={(event) => onChange(event, "_1")}
                />
                {!formGlobal.direccion ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <label htmlFor="">Provincia</label>
              <select
                className="form-select mb-3 rounded-pill"
                aria-label="Default select example"
                name="provincia"
                onChange={(event) => onChange(event, "_1")}
              >
                <option selected>Seleccion una provincia</option>

                {!apiProvincia ? (
                  <option value="null">...</option>
                ) : (
                  apiProvincia.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))
                )}
              </select>

              {!formGlobal.provincia ? (
                <span className={alertInput}>
                  {" "}
                  El campo se encuentra vacio.{" "}
                </span>
              ) : (
                ""
              )}

              {formGlobal.provincia == "" ? (
                ""
              ) : (
                <div className=" mb-3">
                  <label htmlFor="">Localidad</label>
                  <input
                    className="form-control rounded-pill"
                    type="text"
                    value={formGlobal.localidad}
                    name="SearchLocalidad"
                    onChange={(event) => onChange(event, "_1")}
                    autocomplete="nope"
                  />
                  <div className="overflow-hidden">
                    {!apiLocalidad.content ? (
                      ""
                    ) : (
                      <ul class="list-group border-0 border-end border-start border-bottom">
                        {apiLocalidad.content.map((municipios) => (
                          <li class="list-group-item border-0 p-0">
                            <button
                              className="bg-transparent border-0 w-100"
                              name="Selectlocalidad"
                              value={municipios.nombre}
                              onClick={(event) => onChange(event, "_1")}
                            >
                              {municipios.nombre}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}

              {!formGlobal.provincia ? (
                <span className={alertInput}>
                  {" "}
                  El campo se encuentra vacio.{" "}
                </span>
              ) : (
                ""
              )}

              <div className="mb-3">
                <label for="password" className="form-label">
                  Contraseña
                </label>
                <div class="input-group mb-3">
                  <button
                    className="input-group-text btn px-2  btn-custom-naranja text-white"
                    id="basic-addon1"
                    onClick={(event) => showPassword(event, "_1")}
                  >
                    <i className={pass.icon}></i>
                  </button>
                  <input
                    type={pass.type}
                    class="form-control rounded-start rounded-pill"
                    placeholder="Ingrese su contraseña"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    name="password"
                    onChange={(event) => onChange(event, "_1")}
                  />
                </div>
              </div>

              <ul className="list-group mb-3">
                <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">
                  Debe contener al menos una mayúscula (A-Z) y un número (0-9)
                </li>
                <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">
                  Tiene que estar conformado con 6 o más caracteres
                </li>
              </ul>

              <div className="mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Confirmar contraseña
                </label>
                <div class="input-group mb-3">
                  <button
                    className="input-group-text btn px-2  btn-custom-naranja text-white"
                    id="basic-addon1"
                    onClick={(event) => showPassword(event, "_1")}
                  >
                    <i className={pass.icon}></i>
                  </button>
                  <input
                    type={pass.type}
                    class="form-control rounded-start rounded-pill"
                    placeholder="Confirme su contraseña"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    name="password_confirmation"
                    onChange={(event) => onChange(event, "_1")}
                  />
                </div>
              </div>

              <select
                className="form-select mb-0 rounded-pill"
                aria-label="Default select example"
                name="tipo"
                onChange={(event) => onChange(event, "_1")}
              >
                <option selected>Seleccion su tipo de usuario</option>
                <option value="sala">Sala</option>
                <option value="prd">Productor</option>
              </select>

              {!formGlobal.tipo ? (
                <span className={alertInput}>
                  {" "}
                  El campo se encuentra vacio.{" "}
                </span>
              ) : (
                ""
              )}

              <button
                name="registro"
                className="btn  btn-custom-amarillo w-100 mb-3 mt-3 text-white"
                onClick={(event) => onNext(event)}
              >
                Continuar Registro
              </button>

              <div className="w-100 text-center">
              <Link className="text-decoration-none d-block" to="/TYP">
                  <span className="text-dark">
                    Al registrarse acepta nuestros
                  </span>{" "}
                  Terminos y Condiciones
                </Link>
                <Link className="text-decoration-none" to="/ingresar">
                  <span className="fw-light">¿Ya tienes una cuenta?</span>
                  <span className="fw-normal"> Ingresa.</span>
                </Link>
              </div>

              <div className={alert.class}>
                <p className="fw-bold">Atencion.</p>
                <p>{alert.msg}</p>
              </div>
            </form>
          </div>
        </div>

        <div
          className={formClass._2}
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="d-flex justify-content-center">
            <form action="" className="width-md">
              <div className="text-center mb-3">
                <h1>Cuenta de productor</h1>
              </div>

              <div className="mb-3">
                <label for="inputNombre_2" className="form-label">
                  Nombre de Productor
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputNombre_2"
                  placeholder="Ingrese su nombre"
                  name="nombre"
                  onChange={(event) => onChange(event, "_2")}
                />
                {!formPrd.nombre ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputEmail_2" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill" 
                  id="inputEmail_2"
                  placeholder="Ingrese su email"
                  name="email"
                  disabled
                  value={formGlobal.email}
                  onChange={(event) => onChange(event, "_2")}
                />
              </div>

              <div className="mb-3">
                <label for="inputTelefono_2" className="form-label">
                  Telefono de contacto
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputTelefono_2"
                  placeholder="Ingrese un numero de contacto"
                  name="telefono"
                  disabled
                  value={formGlobal.telefono}
                  onChange={(event) => onChange(event, "_2")}
                />
              </div>

              <div className="mb-3">
                <label for="inputDireccion_2" className="form-label">
                  Direccion
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputDireccion_2"
                  placeholder="Ingrese su Direccion"
                  name="direccion"
                  disabled
                  value={formGlobal.direccion}
                  onChange={(event) => onChange(event, "_2")}
                />
              </div>

              <select
                className="form-select mb-3 rounded-pill"
                aria-label="Default select example"
                name="provincia"
                disabled
                value={formGlobal.provincia}
                onChange={(event) => onChange(event, "_2")}
              >
                <option selected>Seleccion una provincia</option>

                {!apiProvincia ? (
                  <option value="null">...</option>
                ) : (
                  apiProvincia.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))
                )}
              </select>

              <select
                className="form-select mb-3 rounded-pill"
                aria-label="Default select example"
                name="localidad"
                disabled
                value={formGlobal.localidad}
                onChange={(event) => onChange(event, "_2")}
              >
                <option selected>Seleccion una Localidad</option>
                <option value={formGlobal.localidad}>
                  {formGlobal.localidad}
                </option>
              </select>

              <button
                className="btn  btn-custom-amarillo w-100 mb-3 "
                onClick={(event) => onSubmit(event, "_2")}
              >
                Registrarse
              </button>

              <div className="w-100 text-center">
              <Link className="text-decoration-none d-block" to="/TYP">
                  <span className="text-dark">
                    Al registrarse acepta nuestros
                  </span>{" "}
                  Terminos y Condiciones
                </Link>
                <Link className="text-decoration-none" to="/ingresar">
                  <span className="fw-light">¿Ya tienes una cuenta?</span>
                  <span className="fw-normal"> Ingresa.</span>
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div
          className={formClass._3}
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div className="d-flex justify-content-center">
            <form action="" className="width-md">
              <div className="text-center mb-3">
                <h1>Cuenta de Sala</h1>
              </div>

              <div className="mb-3">
                <label for="inputRazonSocial_3" className="form-label">
                  Razon Social
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputRazonSocial_3"
                  placeholder="Ingrese su razon social"
                  name="razon_social"
                  onChange={(event) => onChange(event, "_3")}
                />

                {!formSala.razon_social ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputNombre_3" className="form-label">
                  Nombre de Sala
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputNombre_3"
                  placeholder="Ingrese su Nombre"
                  name="nombre"
                  onChange={(event) => onChange(event, "_3")}
                />
                {!formSala.nombre ? (
                  <span className={alertInput}>
                    {" "}
                    El campo se encuentra vacio.{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="inputEmail_3" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill"
                  id="inputEmail_3"
                  placeholder="Ingrese su email"
                  name="email"
                  disabled
                  value={formGlobal.email}
                />
              </div>

              <div className="mb-3">
                <label for="inputTelefono_3" className="form-label">
                  Telefono de contacto
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputTelefono_3"
                  placeholder="Ingrese un numero de contacto"
                  name="telefono"
                  disabled
                  value={formGlobal.telefono}
                />
              </div>

              <div className="mb-3">
                <label for="inputDireccion_3" className="form-label">
                  Direccion
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  id="inputDireccion_3"
                  placeholder="Ingrese su Direccion"
                  name="direccion"
                  value={formGlobal.direccion}
                  disabled
                />
              </div>

              <select
                className="form-select mb-3 rounded-pill"
                aria-label="Default select example"
                name="provincia"
                disabled
                value={formGlobal.provincia}
                onChange={(event) => onChange(event, "_3")}
              >
                <option selected>Seleccion una provincia</option>

                {!apiProvincia ? (
                  <option value="null">...</option>
                ) : (
                  apiProvincia.map((provincia) => (
                    <option value={provincia.nombre}>{provincia.nombre}</option>
                  ))
                )}
              </select>

              <select
                className="form-select mb-3 rounded-pill"
                aria-label="Default select example"
                name="localidad"
                disabled
                value={formGlobal.localidad}
                onChange={(event) => onChange(event, "_3")}
              >
                <option selected>Seleccion una Localidad</option>
              </select>

              <button
                className="btn  btn-custom-amarillo w-100 mb-3"
                onClick={(event) => onSubmit(event, "_3")}
              >
                Registrarse
              </button>

              <div className={alert.class}>
                <p className="fw-bold">Atencion.</p>
                <p>{alert.msg}</p>
              </div>

              <div className="w-100 text-center">
                <Link className="text-decoration-none d-block" to="/TYP">
                  <span className="text-dark">
                    Al registrarse acepta nuestros
                  </span>{" "}
                  Terminos y Condiciones
                </Link>
                <Link className="text-decoration-none" to="/ingresar">
                  <span className="fw-light">¿Ya tienes una cuenta?</span>
                  <span className="fw-normal"> Ingresa.</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperRegister;
