import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URLs } from "../../../../consts/UrlConst";
import { fetchMe, meState } from "../../../../slices/users/Index";
import Maintenance from "../../../layout/Loads/Maintenance";
import Cupones from "./label/Cupones";
import Entradas from "./label/Entradas";
import Membresia from "./label/Membresia";
import Perfil from "./label/Perfil";
import Sidebar from "./sidebar/Index";

const Espectador = () => {
  const { URL_ME } = URLs;
  const dispatch = useDispatch();
  //
  const _user = useSelector(meState);
  const me = _user.me;

  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("perfil");
  const [form, setForm] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    imagen_logo_url: null,
    imagen_logo: ""
  })

  //Params
  const [params, setParams] = useState({
    url: URL_ME,
  });

  const view = (e, value) => {
    switch (label) {
      case "perfil":
        {
          return <Perfil data={me} form={form} setForm={setForm} setLoading={setLoading} loading={loading} />;
        }
        break;
      case "entradas":
        {
          return <Entradas data={me} />;
        }
        break;
      case "membresias":
        {
          return <Membresia data={me} />;
        }
        break;
      case "cupones":
        {
          return <Cupones />;
        }
        break;
    }

    return null;
  };

  useEffect(() => {
    if (!me) {
      dispatch(fetchMe(params));
    }
  }, []);
  return (
    <div className="container-md">
      {
        me
        ? <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar data={me} label={label} setLabel={setLabel} />
        </div>
        <div className="col-md-9 col-9">{view()}</div>
      </div>
        : <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      }
    </div>
  );
  }

  
export default Espectador
