const ModalVideo = ({
  data,
  onClick
}) => {

  console.log(data);

  return (

    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "1000px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-3 d-flex justify-content-between">
            <div>
              <h1 className="fs-4">{data.titulo}</h1>
              <span class="fs-6 text-muted">
                {data.descripcion}
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
          <iframe width="100%" height="500px" src={data.url}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalVideo