import { useContext, useEffect, useState } from "react"
import Notas from "../../page/notas/Notas"
import { fetchNotas, notaState } from "../../models/notas";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contextProvider } from "../../../context/ContextProvider";
import Alerts from "../../components/Alerts/Alerts";
import RestApi from "../../../helpers/RestApi";

const NotasController = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const _notas = useSelector(notaState)
  const notas = _notas.notas;

  const { URL_NOTA_INDEX, URL_NOTA_UPDATE } = URLs;

  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "nuevo_evento": {
        return navigation('/dashboard/nota/0')
      } break;
      case "vista_previa": {

      } break;
      case "editar": {
        return navigation('/dashboard/nota/'+value)
      } break;
      case "status": {
        let formData = new FormData;
        formData.append("_method", 'PUT');
        formData.append("id", value.id);
        formData.append("status", value.status);

        const response = await RestApi({
          url: URL_NOTA_UPDATE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {


          let params = {
            url: URL_NOTA_INDEX,
            //status: 1
          };
          dispatch(fetchNotas(params))

          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;
    }
  }

  useEffect(() => {
    if (!notas) {
      let params = {
        url: URL_NOTA_INDEX,
        //status: 1
      };
      dispatch(fetchNotas(params))
    }
  }, [])
  return (
    <>
      <Notas
        data={notas}
        onClick={onClick}
      />
    </>
  )
}

export default NotasController