const Input = ({ onChange, label, name, type, value, validate, icon, nameType, uniqueKey, disabled, placeholder }) => {
  return (
    <div className="form-group my-2">
      {
        label
          ? <label for="email" className="form-label text-muted" style={{ fontSize: "14px" }}>
            {label}
          </label>
          : null
      }
      <div className="input-group">
        {
          icon
            ? <div className="input-group-text" id="basic-addon1" disabled>
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: icon }}></div>
            </div>
            : ""
        }
        <input
          id={uniqueKey}
          type={type}
          value={value}
          className="form-control border"
          aria-describedby="email"
          placeholder={placeholder ? placeholder : ""}
          name={name}
          onChange={(event) => onChange(event)}

          disabled={disabled}
        />
        {
          nameType
            ? <span className="input-group-text right">{nameType}</span>
            : ""
        }
      </div>

      {validate.status ? (
        <div className="text-danger">{validate.msg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
