import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { contextProvider } from "../../../context/ContextProvider";
import Register from "../../page/login/Register";
import { useContext } from "react";

const RegisterController = () => {
  const navigation = useNavigate();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const {tipo} = useParams();

  const { FORM_REGISTER } = FORMs;
  const { VALIDATION_FORM_REGISTER } = VALIDATIONs;

  const [urls, setUrls] = useState(URLs)
  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_REGISTER)));
  const [validation, setValidation] = useState(JSON.parse(JSON.stringify(VALIDATION_FORM_REGISTER)));

  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "show": {
        e.preventDefault();
        setShowPassword(!showPassword);
      } break;
      case "registro": {

      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "nombre":
      case "apellido":
      case "telefono":
      case "direccion":
      case "provincia":
      case "localidad":
      case "rol":
      case "password":
      case "confirm_password":
      case "email": {
        //setValidation(JSON.parse(JSON.stringify(VALIDATIONS.VALIDATION_LOGIN)))

        let setValue = form;
        setValue[e.target.name][0] = e.target.value;

        return setForm({
          ...form,
          [e.target.name]: setValue[e.target.name]
        });
      } break;
    }
  }

  return (
    <Register
      navigation={navigation}
      onClick={onClick}
      onChange={onChange}
      setShowPassword={setShowPassword}
      showPassword={showPassword}

      form={form}
      setForm={setForm}
      validation={validation}
      tipo={tipo}
    />
  )
}

export default RegisterController