import clientAxios from "./Config";

import Swal from "sweetalert2";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertError from "../Alerts/AlertError";

export const crearEvento = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/CrearEvento',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)

    return result.data.id
    //return (window.location.href = "/dashboard/eventos/editar/" + result.data.id); 
  }).catch((result) => {
    Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "3000",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
    return ""; //window.location.reload(true);
  });

  return response
}

export const crearLiquidacion = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Liquidacion',
    data: dataForm,
  }).then((result) => {
    return Swal.fire({
      text: result.data.msg,
      timer: "3000",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    }), window.location.reload(true);
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "1500",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}


export const UsuarioEventos = async (
  id,
  search,
  sala,
  status,
  date_range,
  url
) => {

  let params = {};

  if(id.trim() !== ""){
    params = {...params, id: id}
  }

  if(search.trim() !== ""){
    params = {...params, search: search}
  }
  
  if(status.trim() !== ""){
    params = {...params, status: status}
  }

  if(sala.trim() !== ""){
    params = {...params, sala: sala}
  }

  if(date_range.length !== 0){
    params = {...params, date_range: date_range}
  }

  const result  = await clientAxios.get(url,{params}).then((result) => {return result.data}).catch((result) => {return null});
  return result;

}

export const UsuarioEventosFilter = async (nombre, status) => {

  let params = {};

  if(nombre.trim() !== ""){
    params.nombre = nombre;
  }

  if(status.trim() !== ""){
    params.status = status;
  }

  const result  = await clientAxios.get("/api/auth/Usuario/Eventos",{params}).then((result) => {return result.data}).catch((result) => {return null});
  return result;

}

export const UsuarioEventosPagination = async (url) => {

  const result  = await clientAxios.get(url).then((result) => {return result.data}).catch((result) => {return null});
  return result;

}



export const ActualizarMisEventos = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Eventos/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error al actualizar evento","Error");
  });

  return response;

}

export const ActualizarLiquidacion = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/ActualizarLiquidacion',
    data: dataForm,
  }).then((result) => {
    return Swal.fire({
      text: result.data.msg,
      timer: "3000",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "1500",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}

export const ActualizarEntradas = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/ActualizarEntradas',
    data: dataForm,
  }).then((result) => {
    return Swal.fire({
      text: result.data.msg,
      timer: "3000",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "1500",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}

export const ActualizarCategorias = async (dataForm) => {
  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/ActualizarCategoria',
    data: dataForm,
  }).then((result) => {
    return Swal.fire({
      text: result.data.msg,
      timer: "3000",
      icon: 'success',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  }).catch((result) => {
    return Swal.fire({
      text: "Ooops! Ocurrio un error en el servidor vuelve a intentarlo mas tarde.",
      timer: "1500",
      icon: 'warning',
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });
}

export const AdministradorEventos = async (
  filter
) => {

  let params = {};

  if(filter.id.trim() !== ""){
    params = {...params, id: filter.id}
  }

  if(filter.search.trim() !== ""){
    params = {...params, search: filter.search}
  }

  if(filter.sala.trim() !== ""){
    params = {...params, sala: filter.sala}
  }
  
  if(filter.status.trim() !== ""){
    params = {...params, status: filter.status}
  }

  if(filter.date_range.length !== 0){
    params = {...params, date_range: filter.date_range}
  }

  if(filter.pagination !== ""){
    params = {...params, pagination: filter.pagination}
  }
  
  if(filter.provincia.trim() !== ""){
    params = {...params, provincia: filter.provincia}
  }

  const result  = await clientAxios.get(filter.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(!result.data){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;

}

export const AdministradorEventosPagination = async (url, nombre, status) => {
  let params = {};

  if(nombre.trim() !== ""){
    params.nombre = nombre;
  }

  if(status.trim() !== ""){
    params.status = status;
  }

  const result  = await clientAxios.get(url, {params}).then((result) => {return result.data}).catch((result) => {return null});
  return result;

}