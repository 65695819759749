const BtnAccept = ({label, value, name, onClick}) => {
  return (
    <label className="btn btn-custom-naranja fs-7 text-white">
      {label}
      <button className="d-none"
      name={name}
      onClick={(event) => onClick(event)}>
      </button>
    </label>
  )
}

export default BtnAccept