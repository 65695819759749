import { useContext, useState } from "react"
import Categorias from "../../page/categorias/Index"
import { useDispatch, useSelector } from "react-redux";
import { categoriaState, fetchCategoria, fetchCategoriaNotas } from "../../models/categorias/Index";
import { contextProvider } from "../../../context/ContextProvider";
import RestApi from "../../../helpers/RestApi";
import Alerts from "../../components/Alerts/Alerts";

const CategoriaController = ({tipo}) => {
  const dispatch = useDispatch();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;


  const { URL_CATEGORIAS_STORE, URL_CATEGORIAS_INDEX } = URLs;

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    nombre: ["", "required"]
  })

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar": {

        let formData = new FormData;

        /*Evento*/
        formData.append("nombre", form.nombre[0]);
        formData.append("tipo", tipo);

        const response = await RestApi({
          url: URL_CATEGORIAS_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {

          let params = {
            url: URL_CATEGORIAS_INDEX,
            tipo: tipo
          };

          if (tipo == "nota") {
            dispatch(fetchCategoriaNotas(params));
          }

          if (tipo == "evento") {
            dispatch(fetchCategoria(params));
          }

          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "nombre": {
        setForm({
          ...form,
          [e.target.name]: [e.target.value, ""]
        })
      } break;
    }
  }

  return (
    <>
      <Categorias
        onChange={onChange}
        onClick={onClick}
        form={form}
      />
    </>
  )
}

export default CategoriaController