const Contenido = () => {

  return (<div className="min-vh-100">
  <section className="wow bounceInUp d-none">
            <div className="container">
                <div className="row">
                    <div className="col-8 col-md-9 pe-0">
                        <div className="card-ticket cardLeft d-flex flex-column">
                            <h1 className="fc-blanco">Somos</h1>
                            <div className="title">
                                <h2>Antesala</h2>
                                <span>sala</span>
                                <div className="py-3"></div>
                            </div>
                            <div>
                                <div className="seat">
                                    <h2>156</h2>
                                    <span>asiento</span>
                                </div>
                                <div className="time">
                                    <h2>12:00</h2>
                                    <span>hora</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 ps-0">
                        <div className="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                            <div className="text-center">
                                <img src="/assets/img/iso-antesala.svg" />
                            </div>
                            <div className="number">
                                <h3>156</h3>
                                <span>asiento</span>
                            </div>
                            <div className="text-center">
                                <img className="barchart" src="assets/img/barcode.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="team" className="mt-100 position-relative overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="wow bounceInUp">Hola, somos ANTESALA, un equipo de amigues con mucha experiencia en diferentes áreas de las artes escénicas y la gestión cultural. Juntes creamos este sitio de difusión y venta de entradas para que toda la información de la cultura independiente sea más accesible para vos. Para que la info del Teatro Independiente se ponga On-line diseñamos Antesala, la página y ticketera desde la cual vas a poder acceder a toda la oferta cultural. Antesala es la escalera a la escena independiente, profunda y diversa. Antesala es la escalera a la nueva escena y también a la escena tradicional. Todas las poéticas a un click de vos. Somos la puerta de acceso al Teatro Independiente, somos su Antesala.
                        </p>
                    </div>
                    <div className="col-12 text-center pt-100 pb-50 wow bounceInUp">
                        <h2>team antesala</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center mb-4 wow bounceInUp">
                                <div className="box-team">
                                    <img src="/assets/img/img-team-01.jpg" width="100%" className="mb-4" />
                                    <h3>Belén Pistone</h3>
                                    <p>coord. comercial y de contenidos</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center mb-4 wow bounceInUp">
                                <div className="box-team">    
                                    <img src="/assets/img/img-team-02.jpg" width="100%" className="mb-4" />
                                    <h3>Lali Monayar</h3>
                                    <p>coord. general</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center mb-4 wow bounceInUp">
                                <div className="box-team">    
                                    <img src="/assets/img/img-team-03.jpg" width="100%" className="mb-4" />
                                    <h3>Rodri Brunelli</h3>
                                    <p>coord. de arte y web</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center mb-4 wow bounceInUp">
                                <div className="box-team">
                                    <img src="/assets/img/img-team-04.jpg" width="100%" className="mb-4" />
                                    <h3>Flavia Pipliza</h3>
                                    <p>legales</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex flex-column justify-content-center text-center mb-4 wow bounceInUp">
                                <div className="box-team">    
                                    <img src="assets/img/img-team-05.jpg" width="100%" className="mb-4" />
                                    <h3>Daniela Medrano</h3>
                                    <p class="mb-2">contabilidad y administración</p>
                                    <p class="mb-0">M.P. 10-17936-9</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/assets/img/bg-04.svg" className="position-absolute img" style={{top: "30%", zIndex: "-1", left: "-10%"}}   />
            </div>
        </section>
        
        <section id="news-somos" className="pb-100 pt-100 d-none">
            <img src="/assets/img/bg-03.svg" className="img-01" />
            <div className="container position-relative">
                <div className="row mb-100">
                    <div className="col-12 text-center mb-4">
                        <h2 className="fc-blanco wow bounceInUp">últimas notas y entrevistas</h2>
                    </div>
                    <div className="col-lg-4 wow bounceInUp">
                        <div className="bg-blanco box-multimedia mb-4">
                            <div className="header-box position-relative d-flex align-items-center justify-content-center">
                                <img src="assets/img/img-multimedia.png" width="100%"/>
                            </div>
                            <div className="d-flex flex-column justify-content-between p-4">
                                <div>
                                    <p>12 de enero de 2022</p>
                                    <hr className="hr-black" />
                                    <h1 className="fc-amarillo">"El pucho en la boca"</h1>
                                    <h3>Por Punu Vazquez</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis rerum sapiente accusantium, facilis labore exercitationem odio perferendis cum veniam quam!</p>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#" className="btn btn-custom-black">leer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow bounceInUp">
                        <div className="bg-blanco box-multimedia mb-4">
                            <div className="header-box position-relative d-flex align-items-center justify-content-center">
                                <img src="/assets/img/img-multimedia.png" width="100%" />
                            </div>
                            <div className="d-flex flex-column justify-content-between p-4">
                                <div>
                                    <p>12 de enero de 2022</p>
                                    <hr className="hr-black" />
                                    <h1 className="fc-amarillo">"El pucho en la boca"</h1>
                                    <h3>Por Punu Vazquez</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis rerum sapiente accusantium, facilis labore exercitationem odio perferendis cum veniam quam!</p>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#" className="btn btn-custom-black">leer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow bounceInUp">
                        <div className="bg-blanco box-multimedia mb-4">
                            <div className="header-box position-relative d-flex align-items-center justify-content-center">
                                <img src="assets/img/img-multimedia.png" width="100%" />
                            </div>
                            <div className="d-flex flex-column justify-content-between p-4">
                                <div>
                                    <p>12 de enero de 2022</p>
                                    <hr className="hr-black" />
                                    <h1 className="fc-amarillo">"El pucho en la boca"</h1>
                                    <h3>Por Punu Vazquez</h3>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis rerum sapiente accusantium, facilis labore exercitationem odio perferendis cum veniam quam!</p>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#" className="btn btn-custom-black">leer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-grid gap-2 wow bounceInUp">
                        <a href="#" className="btn btn-custom-naranja">ver más +</a>
                    </div>
                </div>
            </div>
        </section>
</div>);
}

export default Contenido