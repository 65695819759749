import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Input from "../../../components/custom/forms/Input";
import CheckBox from "../../../components/custom/forms/CheckBox";
import { useContext } from "react";
import { contextProvider } from "../../../../context/ContextProvider";
import Alerts from "../../../components/Alerts/Alerts";
import { Validate } from "../../../../utils/EventoUtils";

const Que = ({ setForm, form, setValidation, validation, categorias }) => {
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;
  const { VALIDATION_FORM_EVENTO } = VALIDATIONs;

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "ficha_tecnica-delete":
        {
          let input = form;
          let result = input.ficha_tecnica[0];

          delete result[value];
          result = result.filter((i) => i != null);

          setForm({
            ...form,
            ficha_tecnica: [result, "required"],
          });
        }
        break;
      case "ficha_tecnica":
        {
          const input_validation = Validate(form, JSON.parse(JSON.stringify(VALIDATION_FORM_EVENTO)), "ficha_tecnica");
          setValidation(input_validation);
          //Validaciones
          if (input_validation.error.status) {
            return Alerts({
              msg: "Warning",
              title: input_validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let input = form;
          let result = input[e.target.name][0];
          result.push({
            nombre: form["ficha_tecnica-nombre"][0],
            rol: form["ficha_tecnica-rol"][0],
          });

          input[e.target.name][0] = result;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
      case "link":
        {
          const input_validation = Validate(form, validation, "link");
          setValidation(input_validation);
          //Validaciones
          if (input_validation.error.status) {
            return Alerts({
              msg: "Warning",
              title: input_validation.error.msg,
              code: "201",
              type: "error",
            });
          }

          let input = form;
          let result = input[e.target.name][0];
          result.push({
            nombre: form["link-nombre"][0],
            url: form["link-url"][0],
          });

          input[e.target.name][0] = result;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
      case "link-delete":
        {
          let input = form;
          let result = input.link[0];

          delete result[value];
          result = result.filter((i) => i != null);

          setForm({
            ...form,
            link: [result, "required"],
          });
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "duracion":
      case "nombre_grupo":
      case "clase":
      case "grupo":
      case "nombre":
      case "link-nombre":
      case "link-url":
      case "ficha_tecnica-nombre":
      case "ficha_tecnica-rol":
        {
          let input = form;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
      case "imagen":
        {
          let input = form;
          input[e.target.name][0] = e.target.files[0];
          input[e.target.name + "_url"][0] = URL.createObjectURL(
            e.target.files[0]
          );

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
            [e.target.name + "_url"]: input[e.target.name + "_url"],
          });
        }
        break;
      case "categorias":
        {
          let input = form;
          let result = form.categorias[0];
          if (
            result.indexOf(categorias[e.target.id].id) < 0 &&
            result.length < 3
          ) {
            result.push(categorias[e.target.id].id);
          } else {
            delete result[result.indexOf(categorias[e.target.id].id)];
            result = result.filter((i) => i != null);
          }

          input["categorias"][0] = result;

          setForm({
            ...form,
            categorias: input["categorias"],
          });
        }
        break;
    }
  };

  let descripcion = form.descripcion[0];
  return (
    <div className="container-md  w-100">
      <div
        className="card shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-12 mb-3 px-1">
            <h5 className="">Datos del evento</h5>

            <hr />

            <div
              className="overflow-hidden mb-2"
              style={{ height: "250px", width: "300px", borderRadius: "10px" }}
            >
              <img
                src={
                  form.imagen_url[0]
                    ? form.imagen_url[0]
                    : "/assets/images/organizaciones.jpg"
                }
                alt=""
                className="img-fluid w-100 h-100"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 col-12 mb-3">
              <label className="form-label text-muted" style={{fontSize: "14px"}}>Imagen principal
              <span className="fw-bold ms-2">Tamaño de imagen 1:1 1080 x 1080 px</span>
              </label>
              <Input
                onChange={onChange}
                name={"imagen"}
                type={"file"}
                value={""}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={validation.imagen_url}
                disabled={false}
              />

              <Input
                onChange={onChange}
                label={"Nombre del evento"}
                name={"nombre"}
                type={"text"}
                value={form.nombre[0]}
                svg={""}
                validate={validation.nombre}
                disabled={false}
              />

              <Input
                onChange={onChange}
                label={"Nombre del grupo (opcional)"}
                name={"nombre_grupo"}
                type={"text"}
                value={form.nombre_grupo[0]}
                svg={""}
                validate={validation.nombre_grupo}
                disabled={false}
              />

              <Input
                onChange={onChange}
                label={"Duracion del evento en minutos"}
                name={"duracion"}
                type={"number"}
                value={form.duracion[0]}
                svg={""}
                validate={validation.duracion}
                disabled={false}
              />
            </div>

            <div className="col-md-4 col-12 mb-3">
              <span className="text-muted">
                Selecciona tu categoria (Hasta 3 categorias)
              </span>
              {categorias
                ? categorias.map((c, index) => (
                  <CheckBox
                    onChange={onChange}
                    label={c.nombre}
                    name={"categorias"}
                    type={"checkbox"}
                    value={
                      form.categorias[0].indexOf(c.id) < 0 ? false : true
                    }
                    uniqueKey={index}
                    disabled={false}
                  />
                ))
                : ""}

              {validation.categorias.status ? (
                <div className="text-danger">{validation.categorias.msg}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-4 col-12 mb-3 px-md-5">
              Tipo de publico
              <div class="form-check">
                <label class="form-check-label" for="flexRadioDefault1">
                  ATP
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="grupo"
                    id="flexRadioDefault1"
                    value={"ATP"}
                    checked={form.grupo[0] == "ATP" ? true : false}
                    onChange={(event) => onChange(event)}
                  />
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label" for="flexRadioDefault1">
                  ADULTOS
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="grupo"
                    id="flexRadioDefault1"
                    value={"ADULTOS"}
                    checked={form.grupo[0] == "ADULTOS" ? true : false}
                    onChange={(event) => onChange(event)}
                  />
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label" for="flexRadioDefault1">
                  INFANCIAS
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="grupo"
                    id="flexRadioDefault1"
                    value={"INFANCIAS"}
                    checked={form.grupo[0] == "INFANCIAS" ? true : false}
                    onChange={(event) => onChange(event)}
                  />
                </label>
              </div>
              {validation.grupo.status ? (
                <div className="text-danger">{validation.grupo.msg}</div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-4 col-12 mb-3 px-md-5 text-nowrap d-none">
              Tipo de evento
              <div class="form-check">
                <label class="form-check-label" for="flexRadioDefault1">
                  ONLINE
                  <input
                    class="form-check-input"
                    type="radio"
                    name="clase"
                    value={"ONLINE"}
                    id="flexRadioDefault1"
                    checked={form.clase[0] == "ONLINE" ? true : false}
                    onChange={(event) => onChange(event)}
                  />
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label" for="flexRadioDefault1">
                  PRESENCIAL
                  <input
                    class="form-check-input"
                    type="radio"
                    name="clase"
                    value={"PRESENCIAL"}
                    id="flexRadioDefault1"
                    checked={form.clase[0] == "PRESENCIAL" ? true : false}
                    onChange={(event) => onChange(event)}
                  />
                </label>
              </div>
              {validation.clase.status ? (
                <div className="text-danger">{validation.clase.msg}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="card shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-12 mb-3">
            <h5>Describe tu evento</h5>
            <hr />
            <CKEditor
              editor={ClassicEditor}
              data={descripcion}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                //console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                let input = form;
                input.descripcion[0] = data;

                setForm({
                  ...form,
                  descripcion: input.descripcion,
                });
                //console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                //console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                //console.log("Focus.", editor);
              }}
            />
          </div>
          {validation.descripcion.status ? (
            <div className="text-danger">{validation.descripcion.msg}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className="card shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <h5>Ficha técnica</h5>
            <span className="text-muted">
              Ingresa el rol que tendran los participantes.
            </span>
            <hr />
            <Input
              onChange={onChange}
              label={"Nombre de participante"}
              name={"ficha_tecnica-nombre"}
              type={"text"}
              value={form["ficha_tecnica-nombre"][0]}
              icon={'<i class="bi bi-arrow-right"></i>'}
              validate={validation["ficha_tecnica-nombre"]}
              disabled={false}
            />
            <Input
              onChange={onChange}
              label={"Rol de participante"}
              name={"ficha_tecnica-rol"}
              type={"text"}
              value={form["ficha_tecnica-rol"][0]}
              icon={'<i class="bi bi-arrow-right"></i>'}
              validate={validation["ficha_tecnica-rol"]}
              disabled={false}
            />
            <label className="btn btn-custom-naranja fw-normal GothamBold text-white w-100 mt-1">
              Añadir participante
              <button
                className="d-none"
                name="ficha_tecnica"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
            {validation.ficha_tecnica.status ? (
              <div className="text-danger">{validation.ficha_tecnica.msg}</div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6">
            <ul class="list-group">
              {form.ficha_tecnica[0].map((f, index) => (
                <li class="list-group-item border-0 text-muted d-flex justify-content-between shadow-sm mb-3">
                  <div>
                    <span className="text-black fw-bold">{f.rol}: </span>
                    {f.nombre}
                  </div>
                  <label
                    className="btn btn-outline-danger"
                    style={{ maxHeight: "40px" }}
                  >
                    <i class="bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="ficha_tecnica-delete"
                      onClick={(event) => onClick(event, index)}
                    ></button>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="card shadow-sm  p-3" style={{ borderRadius: "1rem" }}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <h5>Links para mi evento (Opcional)</h5>
            <span className="text-muted">
              Ingresa la URL para el evento.
            </span>
            <hr />
            <Input
              onChange={onChange}
              label={"Nombre"}
              name={"link-nombre"}
              type={"text"}
              value={form["link-nombre"][0]}
              icon={'<i class="bi bi-arrow-right"></i>'}
              validate={validation["link-nombre"]}
              disabled={false}
            />
            <Input
              onChange={onChange}
              label={"URL"}
              name={"link-url"}
              type={"text"}
              value={form["link-url"][0]}
              icon={'<i class="bi bi-arrow-right"></i>'}
              validate={validation["link-url"]}
              disabled={false}
            />
            <label className="btn btn-custom-naranja fw-normal GothamBold text-white w-100 mt-1">
              Añadir link
              <button
                className="d-none"
                name="link"
                onClick={(event) => onClick(event)}
              ></button>
            </label>
          </div>

          <div className="col-md-6">
            <ul class="list-group">
              {form.link[0].map((l, index) => (
                <li class="list-group-item border-0 text-muted d-flex justify-content-between shadow-sm mb-3">
                  <div>
                    <span className="text-black fw-bold">{l.nombre}: </span>
                    {l.url}
                  </div>
                  <label
                    className="btn btn-outline-danger"
                    style={{ maxHeight: "40px" }}
                  >
                    <i class="bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="link-delete"
                      onClick={(event) => onClick(event, index)}
                    ></button>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Que;
