import { configureStore } from "@reduxjs/toolkit";
import eventos from '../web/models/eventos/Index'
import categorias from '../web/models/categorias/Index'
import me from '../web/models/users/Index'
import salas from '../web/models/salas/Index'
import provincias from '../web/models/provincias/Index'
import notas from '../web/models/notas/index'
import membresias from '../web/models/membresias/Index'

export default configureStore({
  reducer: {
    me,
    eventos,
    categorias,
    salas,
    provincias,
    notas,
    membresias
  }
})