import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../helpers/Config";

const initialState = {
  me: null,

  data: null,
  auth: null,
  rol: null,
  status: null,
  galeria: null,
  comisiones: null
};

export const meSlice = createSlice({
  name: "me",
  initialState: initialState,
  reducers: {
    setMe: (state, action) => {
      state.me = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload.data
      state.auth = action.payload.auth
      state.rol = action.payload.rol
      state.status = action.payload.status
      state.galeria = action.payload.galeria
      state.comisiones = action.payload.comisiones
    }
  },
});

//Async functions
export const fetchMe = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setMe(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const fetchData = (params) => (dispatch) => {
  
};

export const { setMe } = meSlice.actions;

export const meState = (state) => state.me;

export default meSlice.reducer;
