import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { CollectionStateContext } from "../../../../context/EventosState";
import { AdministradorVideos, AdministradorVideosActualizar, AdministradorVideosCrear } from "../../../../helpers/VideosHelper";
import { Link, useParams } from "react-router-dom";
import CardVideo from "../../Card/CardVideo";
import ModalAddVideo from "../../Modals/ModalAddVideo";
import AlertWarning from "../../../../Alerts/AlertWarning";
import { DataF } from "@react-google-maps/api";
import ModalEditStatusVideo from "../../Modals/ModalEditStatusVideo";
import useApi from "../../../../hooks/useApi";

const ControlVideos = () => {
  const context = useContext(CollectionStateContext);
  const { state } = context;

  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const LSProvincias = localStorage.getItem("provincias");
  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );

  const [selectItem, setSelectItem] = useState(null);

  //MODALS
  const [modal_video, setModalVideo] = useState(false);
  const [modal_status_video, setModalStatusVideo] = useState(false);

  //FORMS

  const [form, setForm] = useState({
    titulo: "",
    descripcion: "",
    content: "",
    url: "",
    imagen: "",
    provincia: "",
    status: ""
  });

  //params
  const [params, setParams] = useState({
    id: "",
    provincia: "",
    url: "/api/auth/Administrador/Multimedia",
  });


  const onChange = (e, value) => {
    switch (e.target.name) {
      case "url":
        {
          let link = e.target.value;
          link = link.split("&");
          link = link[0].replace("watch?v=", "embed/");

          setForm({
            ...form,
            [e.target.name]: link,
          });
        }
        break;
      case "imagen":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            imagen_url: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
      case "status":
      case "titulo":
      case "descripcion": 
      case "provincia":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }
      break;
      //params
      case "p_status":{
        setParams({
          ...params,
          status: e.target.value
        })
      }
      break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "editVideo":
        {
          setModalVideo(true);
          setSelectItem(value)
        }
        break;
      case "statusVideo":{
        let video = value.video;
        let status = value.status;

        let dataForm = new FormData();

        dataForm.append("id", video.id);
        dataForm.append("status", status);

        const response = await AdministradorVideosActualizar(dataForm, setLoading);
        setSteps(steps+1);
      }  
      break;
      case "video":
        {
          setModalVideo(true);
          //setSelectItem(value)
        }
        break;
      case "close_modal":
        {
          setModalVideo(false);
          setModalStatusVideo(false);
          setSelectItem(null);


          setForm({
            titulo: "",
            descripcion: "",
            content: "",
            url: "",
            imagen: "",
            provincia: "",
            status: ""
          });
        }
        break;
    }
  };

  const onSave = async () => {
    if(
      form.titulo.trim() == "" ||
      form.descripcion.trim() == "" ||
      form.provincia.trim() == "" ||
      form.imagen == "" ||
      form.url.trim() == "" 
    ){
      return AlertWarning(
        "Falta rellenar campos",
        "Advertencia"
      );
    }

    let dataForm = new FormData();

    dataForm.append("titulo", form.titulo);
    dataForm.append("descripcion", form.descripcion);
    dataForm.append("provincia", form.provincia);
    dataForm.append("imagen", form.imagen);
    dataForm.append("url", form.url);

    const response = await AdministradorVideosCrear(dataForm, setLoading);
  };

  const onAply = async (e, value) => {
    let dataForm = new FormData();
    dataForm.append("id", value.id);

    if(form.titulo.trim() !== ""){
      dataForm.append("titulo", form.titulo);
    }

    if(form.descripcion.trim() !== ""){
      dataForm.append("descripcion", form.descripcion);
    }

    if(form.url.trim() !== ""){
      dataForm.append("url", form.url);
    }

    if(form.provincia.trim() !== ""){
      dataForm.append("provincia", form.provincia);
    }

    if(form.imagen !== ""){
      dataForm.append("imagen", form.imagen);
    }

    if(form.status.trim() !== ""){
      dataForm.append("status", form.status);
    }

    const response = await AdministradorVideosActualizar(dataForm, setLoading);
    setSteps(steps+1);
  };

  
  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps); 
  return (
    <div
      className="row container-md mx-auto p-0 m-0 border-0 texto-normal"
      style={{ maxWidth: "800px" }}
    >
      <div class="col-md-12">
        <h1>Videos</h1>
        <span class="text-muted">
          Puedes crear videos nuevos, visualizarlas y editarlas.
        </span>
      </div>
      <div class="col-md-12 d-flex justify-content-between">
        <div class="fs-5"></div>

        <button
          to="/dashboard/notas/crear"
          class="btn btn-custom-naranja text-white fw-normal GothamBold me-0 mx-1 my-4 px-2 py-2 fs-10"
          name="video"
          onClick={(event) => onClick(event)}
        >
          <i class="fs-8 fw-light bi bi-plus"></i> Agregar video
        </button>
      </div>
      <div class="col-md-8 my-2 d-none">
        <span class="fs-8">Filtrar Según categoria:</span>{" "}
        <button class="fs-10 btn border-custom-naranja text-custom-naranja py-1 rounded-pill border-0 px-2 py-0">
          Categoria 1
        </button>
      </div>
      <div class="col-md-4 my-2">
        <label for="" class="fs-8">
          Filtrar según estado
        </label>
        <select
          class="form-select fs-9 p-0 px-2 py-1 rounded-pill border-custom-naranja"
          aria-label="Default select example"
          name="p_status"
          onChange={(event) => onChange(event)}
        >
          <option selected>Ver todos: </option>
          <option value="ACTIVO">ACTIVO</option>
          <option value="INACTIVO">INACTIVO</option>
        </select>
      </div>
      <div className="col-md-8 col-12">
        <label
          for="exampleFormControlInput1"
          className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
        >
          Realiza una busqueda rapida ingresando el titulo del video.
        </label>
        <div class="input-group mb-3">
          <input
            type="text"
            class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del evento o la sala"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <button
            class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            {loading ? (
              <div
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <i class="bi bi-search"></i>
            )}
          </button>
        </div>
      </div>

      {/*
       <div class="col-md-6 col-12 my-2 d-md-flex justify-content-between d-none">
        <div class="w-100 text-end">
          <label for="" class="fs-9 form-label">Filtra según la fecha</label>
          <button className="fs-10 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 m-p px-2 py-2 w-100">
          {" "}
          <i className="bi bi-calendar2-event"></i> Filtra por rango de fecha
        </button>
          <div className="filter_date_range d-flex justify-content-center w-100 border-0">
          <DateRange
            onChange={(item) => {
              onFecha([item.selection]);
              setFilter({
                ...filter,
                date_range: [
                  item.selection.startDate.toISOString() ,
                  item.selection.endDate.toISOString(),
                ],
              });
            }}
            moveRangeOnFirstSelection={false}
            locale={locale}
            ranges={fecha}
            className={"fs-7"}
            style={{ fontSize: "12px" }}
          />
        </div>
        </div>
    </div>
     */}

      <div className="col-md-4 col-12 m-0 p-0">
        <div className="w-100 px-2 ">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Filtrar según la provincia.
          </label>
          <select
            className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
            aria-label="Default select example"
            name="provincia"
            onChange={(event) => onChange(event)}
          >
            <option selected>{params.provincia}</option>
            {dataProvincias
              ? dataProvincias.map((provincia) => (
                  <option value={provincia.nombre}>{provincia.nombre}</option>
                ))
              : ""}
          </select>
        </div>
      </div>

      <div className="col-md-12 col-12 row p-0 m-0 px-md-2 border-0 texto-normal">
        {data
          ? data.length !== 0
            ? data.map((video, index) => (
                <CardVideo
                  keys={index}
                  onClick={onClick}
                  onChange={onChange}
                  data={video}
                />
              ))
            : ""
          : ""}
      </div>

      {modal_video ? (
        <ModalAddVideo
          onClick={onClick}
          onChange={onChange}
          data={selectItem}
          provincia={dataProvincias}
          form={form}
          setForm={setForm}
          onSave={onSave}
          onAply={onAply}
        />
      ) : (
        ""
      )}

      {
        modal_status_video?
        <ModalEditStatusVideo
          onClick={onClick}
          onChange={onChange}
          data={selectItem}
          provincia={dataProvincias}
          form={form}
          setForm={setForm}
          onSave={onSave}
          onAply={onAply}
        />
        : ""
      }
    </div>
  );
};

export default ControlVideos;
