import { useContext, useEffect, useReducer, useState } from "react";
import { CollectionStateContext } from "../../../../../context/EventosState";
import { obtenerMunicipios } from "../../../../../helpers/NormGeograficoHelper";
import {
  StateInitialState,
  stateReducer,
} from "../../../../../reducers/ReducerStates";
import ContainerItem from "./ContainerItem";

const Container = () => {
  const context = useContext(CollectionStateContext);
  const {
    obtenerEventos,
    obtenerCategorias,
    obtenerApiProvincias,
    provincias,
    salas,
    state,
  } = context;

  
  const geolocation = localStorage.getItem("geolocation");
  const lSProvincias = localStorage.getItem("provincias");

  //const [dataEventos, setEventos] = useState(eventos);
  const [dataProvincias, setProvincias] = useState(lSProvincias? JSON.parse(lSProvincias) : state.provincias);
  const [apiLocalidad, setApiLocalidad] = useState({
    class: "d-none",
    content: null,
  });

  const [dataSalas, setSalas] = useState(state.salas);
  const [filter, setFilter] = useState({
    id: "",
    search: "",
    provincia: geolocation? geolocation : "",
    localidad: "",
    filtro: "true"
  })

  const onChange = (e) => {
    switch (e.target.name) {
      case "provincia":
      case "search":
        {
          setFilter({
            ...filter,
            [e.target.name]: e.target.value,
            filtro: "true"
          });
        }
        break;
      case "Selectlocalidad":
        {
          setFilter({
            ...filter,
            localidad: e.target.value,
            filtro: "true"
          });

          setApiLocalidad({
            class: "d-none",
            content: null,
          });
        }
        break;
      case "SearchLocalidad":
        {
          setFilter({
            ...filter,
            localidad: e.target.value,
          });

          const axios = async () => {
            let result = await obtenerMunicipios(
              filter.provincia, 
              e.target.value
            );
            setApiLocalidad({
              class: "form-control",
              content: result,
            });
          };
          axios();
        }
        break;
    }
  };

  useEffect(() => {
      salas(
        setSalas,
        filter.id,
        filter.search,
        filter.provincia,
        filter.localidad,
        filter.filtro
    );
  },[filter])

  useEffect(() => {
    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, [dataProvincias]);
  return (
    <div>
      <section class="mt-100">
        <div class="container">
          <div class="row texto-normal">
            <div class="col-12 d-md-flex justify-content-end">
              <div class="d-flex align-items-center me-0 me-md-4 mb-4 mb-md-0 wow bounceInUp">
                <p class="mb-0 me-2 text-nowrap">Provincia</p>
                <select
                  class="form-select form-select form-select-sm"
                  name="provincia"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{geolocation? geolocation : ""}</option>
                  {dataProvincias
                    ? dataProvincias.map((provincia) => {
                      if(provincia.stated == "ACTIVO"){
                        return (
                          <option value={provincia.nombre}>{provincia.nombre}</option>
                        )
                      }
                    })
                    : ""}
                </select>
              </div>
              
                {filter.provincia == "" ? (
                  ""
                ) : (
                    <div class="position-relative d-flex align-items-center me-0 me-md-4 mb-4 mb-md-0 wow bounceInUp">

                    <p class="mb-0 me-2 text-nowrap">Localidad</p>
                    <input
                      className="form-control form-search form-control-sm"
                      type="text"
                      value={filter.localidad}
                      name="SearchLocalidad"
                      onChange={(event) => onChange(event)}
                      autocomplete="off"
                    />

                    <div className="w-100 overflow-hidden position-absolute" style={{bottom: "-30px", zIndex: "2"}}>
                      {!apiLocalidad.content ? (
                        ""
                      ) : (
                        <ul class="list-group border-0 border-end border-start border-bottom bg-white">
                          {apiLocalidad.content.map((municipios) => (
                            <li class="list-group-item list-group-item-action border-0 p-0">
                              <button
                                className="bg-transparent border-0 w-100"
                                name="Selectlocalidad"
                                value={municipios.nombre}
                                onClick={(event) => onChange(event)}
                              >
                                {municipios.nombre}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                </div>
                  </div>
                )}
               
              <div class="d-flex align-items-center wow bounceInUp">
                <p class="mb-0 me-2 text-nowrap">Sala</p>
                <input
                  type="text"
                  class="form-control form-search form-control-sm"
                  placeholder="Buscar"
                  name="search"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>
            <div class="col-12">
              <hr class="wow bounceInUp" />
            </div>
          </div>
        </div>
      </section>

      <section id="salas" class="pt-100 pb-100 mt-4">
        <div class="container">
          <div class="row mt-4">
            {dataSalas
              ? dataSalas.map((item) => (
                  <ContainerItem key={item.id} data={item} />
                ))
              : ""}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Container;
