import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const ObtenerCategorias = async (filter_categorias) => {
  console.log(filter_categorias);
  let params = filter_categorias
  const result  = await clientAxios.get(filter_categorias.url, {params})
  .then((result) => {return result.data})
  .catch((result) => {return null});
  return result;
}

export const AdministradorCategoriaCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Categorias/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}


export const AdministradorCategoriaActualizar = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Categorias/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}


export const ActualizarEventoCategorias = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Eventos/Categorias/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}


export default (
  ObtenerCategorias
)