import { useState } from "react";
import AlertWarning from "../../../Alerts/AlertWarning";
import { PaymentFree } from "../../../helpers/DecidirHelper";

const EntradaGratis = ({ onClick, evento, data, setModalFree }) => {
//console.log(data);
//console.log(evento);

const [loading, setLoading] = useState(false);
const [form, setForm] = useState(
  {
    nombre: "",
    email: ""
  }
);

const onChange = (e) => {
  switch (e.target.name) {
    case "nombre":
    case "email":{
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }
    break;
  }
}

const confirmar = (e) => {
  setLoading(true);
  //validaciones formulario
  if(
    form.nombre.trim() == "" ||
    form.email.trim() == ""
  ){
    return AlertWarning("Complete todos los campos para confirmar su entrada","Advertencia");
  }

  if (Object.keys(data).length == 0){
    return AlertWarning("Debe seleccionar una o más entradas.","Advertencia");
  }
  //validaciones
  let result = [];

  let cant = false;

  Object.keys(data).map((key) => {
    //Si la cantidad es mayor a 1
    if(data[key].cantidad > 0){
      result.push(data[key]);
    }
  })

  if(result.length <= 0){
    return AlertWarning("Debe seleccionar una o más entradas.","Advertencia");
  }

  //crear formulario  
  let formData = new FormData();

  formData.append('nombre', form.nombre);
  formData.append('email', form.email);
  formData.append('id_evento', evento.id);
  formData.append('entradas', JSON.stringify(result));

  return PaymentFree(formData, setLoading, setModalFree);
}

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "500px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-3 d-flex justify-content-between">
            <div>
              <h1 className="fs-4">Entradas para {evento.nombre}</h1>
              <span class="fs-6 text-muted">
                Rellena los siguientes datos para obtener tu entrada gratis.{" "}
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div class="bg-white py-3 px-3" style={{borderRadius: "20px"}}>
              <div class="mb-3">
                <label for="" class="text-muted">
                  Nombre y apellido
                </label>
                <input
                  type="text"
                  class="form-control border-custom-naranja rounded-pill"
                  name="nombre"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div class="mb-3">
                <label for="" class="text-muted">
                  Email
                </label>
                <input
                  autoComplete="password"
                  type="text"
                  class="form-control border-custom-naranja rounded-pill"
                  name="email"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div class="mb-3">
                {/*entradas.entradas.map((entrada) => (
                  <ul class="list-group">
                    <li class="list-group-item border-0">
                      <span class="text-muted">Entrada</span> {entrada.nombre}
                    </li>
                    <li class="list-group-item border-0">
                      <span class="text-muted">Fecha</span> {entrada.fecha}
                    </li>
                    <li class="list-group-item border-0">
                      <span class="text-muted">Cantidad</span> {entrada.cantidad}
                    </li>
                    <li class="list-group-item border-0">
                      <span class="text-muted">Tipo</span> Gratis
                    </li>
                  </ul>
                ))*/}
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <button
                class="btn btn-custom-amarillo rounded-pill px-4"
                name="confirmarPedido"
                onFocus={(event) => confirmar(event)}
                disabled={loading}
              >
                {
                loading
                ? <div class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
                :  ""
                }
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntradaGratis;
