import { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AlertError from "../../../../../Alerts/AlertError";
import AlertSuccess from "../../../../../Alerts/AlertSucces";
import AlertWarning from "../../../../../Alerts/AlertWarning";
import { authContext } from "../../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../../context/EventosState";
import { ActualizarEventoCategorias } from "../../../../../helpers/CategoriaHelper";
import {
  CrearEntrada,
  EliminarEntrada,
  UpdateEntrada,
} from "../../../../../helpers/EntradasHelpers";
import { CrearEvento } from "../../../../../helpers/Eventos";
import {
  ActualizarMisEventos,
} from "../../../../../helpers/EventUserHelper";
import {
  CrearLiquidacion,
  EliminarLiquidacion,
  UpdateLiquidacion,
} from "../../../../../helpers/LiquidacionHelper";
import { obtenerMunicipios } from "../../../../../helpers/NormGeograficoHelper";
import { AdministradorCrearNotificacion } from "../../../../../helpers/NotificacionHelper";
import ModalHistorial from "../../../Modals/ModalHistorial";
import ModalNotificacion from "../../../Modals/ModalNotificacion";
import Cuando from "./Cuando";
import Donde from "./Donde";
import Entradas from "./Entradas";
import Liquidacion from "./Liquidacion";
import Que from "./Que";
//Date format
import { format, add, sub } from "date-fns";
//Hooks
import useApi from "../../../../../hooks/useApi";

const FormEvento = () => {
  const { user } = useContext(authContext);
  const { rol, auth } = user;
  const context = useContext(CollectionStateContext);
  const {
    usuarioEventos,
    obtenerDatoFiscal,
    obtenerCategorias,
    administradorEventos,
    geolocationApi,
    obtenerApiProvincias,
    provincias,
    salas,
    state,
  } = context;

  const { id } = useParams();

  //STATE HELPERS

  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const [selectElement, setSelect] = useState(null);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    datos: false,
    ficha: false,
    link: false,
    sitio: false,
    fecha: false,
    entrada: false,
    liquidacion: false,
  });
  const [label, setLabel] = useState("que");
  const [stateAut, setStateAut] = useState(false);
  const [autocomplete, setAutocomplete] = useState(false);
  const [dataLocalidad, setLocalidad] = useState({
    class: "d-none",
    content: null,
  });

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const [fecha, onFecha] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  //QUERY STATE

  //const [evento, setEventos] = useState(null);
  const [dataGeolocation, setGeolocation] = useState(state.provincias);
  const [dataCategorias, setCategorias] = useState(state.categorias);
  const [dataSalas, setSalas] = useState(state.salas);
  const [dataDatoFiscal, setDatoFiscal] = useState(state.datoFiscal);

  //MODALS

  const [modal_notificacion, setModalNotificacion] = useState(false);
  const [modal_historial, setModalHistorial] = useState(false);

  //params
  const [params, setParams] = useState({
    id: id,
    search: "",
    sala: "",
    status: 1,
    date_range: [],
    provincia: "",
    //static
    url: "api/auth/Eventos/All",
    pagination: 50,
  });

  const dayFilter = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];

  const [filter_salas, setFilterSalas] = useState({
    id: "",
    value: "",
    searchSala: "",
    provincia: "",
    localidad: "",
    filtro: "true",
    select_id_sala: null,
  });

  const [filter_categoria, setFilterCategoria] = useState({
    nombre: "",
    fecha: "",
    url: "api/categorias",
    tipo: "evento"
  });

  
  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps); 

  //dataEvento
  const evento = id? data : null;

  //FORM STATE

  const [form, setForm] = useState({
    //FORM QUE
    imagen_evento: "",
    nombre: "",
    nombre_grupo: "",
    duracion: "",
    categorias: [],
    atp: "NO",
    descripcion: "",
    url_youtube: id ? evento? evento.url_youtube : "" : "",
    grupo: "",
    clase: "",

    ficha_tecnica: null,
    ficha_key: "",
    ficha_value: "",

    links: null,
    link_key: "",
    link_value: "",

    update_categorias: [],

    //FORM DONDE

    nombre_sitio: "",
    email_sitio: "",
    telefono_sitio: "",
    website_sitio: "",
    maps_sitio: "",
    direccion: "",
    provincia: "",
    localidad: "",

    //FORM CUANDO

    dayFilter: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    fechas: null,
    time: "",
    stock: "",
    array_fechas: [],

    //FORM ENTRADAS

    entradas: [],
    nombre_entrada: "",
    tipo_entrada: "",
    habilitacion_entrada: "",
    hora_habilitacion_entrada: "",
    precio_entrada: "0",
    estado_descuento: false,
    porcentaje_descuento: "0",
    multiplicador_entrada: null,

    stateEditarEntradas: false,
    updateEntradaPrecio: "",
    updateEntradaNombre: "",
    updateEntradaDescuento: "",
    updateEntradaHabilitacion: "",
    updateEntradaHoraCierre: "",

    //FORM LIQUIDACION
    stateEditarLiqui: false,
    stateArgentores: false,
    porcentaje_argentores: 0,
    liquidacion: user.data.facturacion.length == 0 ? [] : [...user.data.facturacion],

    porcentaje: "",
    titular: "",
    dato_fiscal: "",
    cbu: "",
    update_porcentaje: "",
    update_dato_fiscal: "",
    update_cbu: "",
    update_titular: "",

    comprobante_afip: "",
    comprobante_cbu: "",
    comprobante_entidad: "",
    update_comprobante_afip: "",
    update_comprobante_cbu: "",
    update_comprobante_entidad: "",
    total: user.data.facturacion.length == 0 ? 100 : 0,
  });

  const [formNotification, setFormNotification] = useState({
    nombre_notificacion: "",
    descripcion_notificacion: "",
    tipo_notificacion: "",
    fecha_expiracion: "",
  });

  //ARROW FUNCTION

  const dateRange = (e) => {
    e.preventDefault();

    if (
      fecha[0].endDate == null ||
      form.time.trim() == "" ||
      form.stock.trim() == ""
    ) {
      setAlert({
        ...alert,
        fecha: true,
      });
      return AlertWarning(
        "Complete todos los campos antes de cargar una fecha",
        "Advertencia"
      );
    }

    const dias_semana = [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ];

    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    let start = fecha[0].startDate.toLocaleDateString();
    let end = fecha[0].endDate.toLocaleDateString();

    let array = [];

    start = start.split("/");
    end = end.split("/");

    for (let s = parseInt(start[1]); s <= parseInt(end[1]); s++) {
      const mes = s;
      let diasTotal;

      if (s !== parseInt(start[1])) {
        for (let i = 1; i <= parseInt(end[0]); i++) {
          let concatDate = mes + "/" + i + "/" + start[2];
          let dia = new Date(concatDate);
          let fechaFormat =
            dias_semana[dia.getDay()] +
            ", " +
            dia.getDate() +
            " de " +
            meses[dia.getMonth()] +
            " de " +
            dia.getUTCFullYear();

          array.push(fechaFormat);
        }
      } else {
        diasTotal =
          start[1] != end[1]
            ? new Date(parseInt(start[2]), s, 0).getDate()
            : end[0];

        for (let i = parseInt(start[0]); i <= diasTotal; i++) {
          let concatDate = start[1] + "/" + i + "/" + start[2];
          let dia = new Date(concatDate);
          let fechaFormat =
            dias_semana[dia.getDay()] +
            ", " +
            dia.getDate() +
            " de " +
            meses[dia.getMonth()] +
            " de " +
            dia.getUTCFullYear();

          array.push(fechaFormat);
        }
      }
    }

    form.dayFilter.map((day) => {
      array = array.filter((date) => !date.includes(day));
    });

    if (array.length == 0) {
      return AlertWarning(
        "El rango de fecha seleccionado no contiene un día habil",
        "Advertencia"
      );
    }

    let objFechas;
    let fechas = [];

    if (form.array_fechas.length == 0 && evento) {
      fechas = evento.fechas ? JSON.parse(evento.fechas) : [];
    } else {
      fechas = form.array_fechas;
    }

    let repeatDate = false;

    fechas.map((fecha) => {
      array.map((arr) => {
        if (
          arr + " HORA:" + form.time ==
          fecha.fechas[0] + " HORA:" + fecha.hora
        ) {
          repeatDate = true;
        }
      });
    });

    if (repeatDate) {
      return AlertWarning(
        "El rango de fecha seleccionado ya existe, seleccione otro horario o día.",
        "Advertencia"
      );
    }

    array.map((item) => {
      objFechas = {
        fechas: [item],
        hora: form.time,
        stock: form.stock,
      };

      fechas.push(objFechas);
    });

    setForm({
      ...form,
      array_fechas: fechas,
    });
  };

  //BUTTON EVENT

  const onClick = async (e, value, value_2) => {
    switch (e.target.name) {
      //LABEL
      case "siguiente":
        {
          switch (label) {
            case "que":
              setLabel("donde");
              break;
            case "donde":
              setLabel("cuando");
              break;
            case "cuando":
              setLabel("entradas");
              break;
            case "entradas":
              setLabel("liquidacion");
              break;
          }
        }
        break;
      case "anterior":
        {
          switch (label) {
            case "donde":
              setLabel("que");
              break;
            case "cuando":
              setLabel("donde");
              break;
            case "entradas":
              setLabel("cuando");
              break;
          }
        }
        break;
      //MODALS
      case "notificacion":
        {
          setSelect(value);
          setModalNotificacion(true);
        }
        break;
      case "historial":
        {
          setSelect(value);
          setModalHistorial(true);
        }
        break;
      case "close_modal":
        {
          setModalNotificacion(false);
          setModalHistorial(false);
        }
        break;
      //EDITABLE
      case "label":
        {
          setLabel(value);
        }
        break;
      //FICHA TECNICA
      case "addFichaTecnica":
        {
          if (form.ficha_key.trim() == "" || form.ficha_value.trim() == "") {
            setAlert({
              ...alert,
              ficha: true,
            });

            return AlertWarning(
              "Complete los campos de Rol y Nombre antes de agregar a la ficha técnica",
              "Advertencia"
            );
          }

          if (!form.ficha_tecnica && evento) {
            setForm({
              ...form,
              ficha_tecnica: {
                ...JSON.parse(evento.ficha_tecnica),
                [form.ficha_key]: form.ficha_value,
              },
            });
          } else {
            setForm({
              ...form,
              ficha_tecnica: {
                ...form.ficha_tecnica,
                [form.ficha_key]: form.ficha_value,
              },
            });
          }
        }
        break;
      case "deleteFichaTecnica":
        {
          let object;
          if (!form.ficha_tecnica && data) {
            object = JSON.parse(evento.ficha_tecnica);
          } else {
            object = form.ficha_tecnica;
          }

          delete object[value];

          setForm({
            ...form,
            ficha_tecnica: object,
          });
        }
        break;
      //LINKS
      case "addLink":
        {
          if (form.link_key.trim() == "" || form.link_value.trim() == "") {
            setAlert({
              ...alert,
              link: true,
            });

            return AlertWarning(
              "Complete los campos URL y Nombre antes de agregar links",
              "Advertencia"
            );
          }

          if (!form.links && evento) {
            setForm({
              ...form,
              links: {
                ...JSON.parse(evento.link),
                [form.link_key]: form.link_value,
              },
            });
          } else {
            setForm({
              ...form,
              links: { ...form.links, [form.link_key]: form.link_value },
            });
          }
        }
        break;
      case "deleteLink":
        {
          let object;
          if (!form.links && evento) {
            object = JSON.parse(evento.link);
          } else {
            object = form.links;
          }

          //verified property link

          if(typeof(object) == "string"){
            object = {};
          } else {
            delete object[value];
          }


          setForm({
            ...form,
            links: object,
          });
        }
        break;
      //DONDE
      case "stateAut":
        {
          if (e.target.checked) {
            setStateAut(true);
          } else {
            setStateAut(false);
          }
        }
        break;
      //CUANDO
      case "deleteFecha":
        {
          let object;
          if (form.array_fechas.length == 0 && evento) {
            object = JSON.parse(evento.fechas);
          } else {
            object = form.array_fechas;
          }

          let errorFecha = "FALSE";

          if (evento) {
            evento.reportes.map((reporte) => {
              //  console.log(reporte.fecha_entrada);
              // console.log(object[value].fechas[0]+" HORA: "+object[value].hora);
              if (
                reporte.fecha ==
                object[value].fechas[0] + " HORA: " + object[value].hora
              ) {
                errorFecha = "TRUE";
              }
            });
          }

          if (errorFecha == "TRUE") {
            return AlertWarning(
              "No se puede borrar esta fecha porque contiene entradas vendidas",
              "Advertencia"
            );
          }

          delete object[value];

          object = object.filter((item) => {
            return item != null;
          });

          if (evento) {
            evento.fechas = JSON.stringify(object);
          }

          setForm({
            ...form,
            array_fechas: object,
          });
        }
        break;
      //ENTRADA
      case "addEntrada":
        {
          if (
            form.nombre_entrada.trim() == "" ||
            form.habilitacion_entrada.trim() == "" ||
            form.precio_entrada.trim() == "" 
          ) {
            setAlert({
              ...alert,
              entrada: true,
            });
            return AlertWarning(
              "Falta rellenar campos antes de agregar una nueva entrada.",
              "Advertencia"
            );
          }

          let copyTicket;

          form.entradas.filter(function (f) {
            if (form.nombre_entrada == f.nombre) {
              return (copyTicket = "SI");
            } else {
              return (copyTicket = "NO");
            }
          });

          if (copyTicket == "SI") {
            return AlertWarning(
              "Esta entrada ya esta configurada dentro de tu lista",
              "Advertencia"
            );
          }

          let entrada;

          if (form.array_fechas.length == 0 && evento) {
            entrada = JSON.parse(evento.entradas);
          } else {
            entrada = form.entradas;
          }

          let obj = {
            nombre: form.nombre_entrada,
            fecha: form.habilitacion_entrada,
            tipo: form.tipo_entrada,
            precio: form.precio_entrada,
            estado_descuento: form.estado_descuento,
            porcentaje_descuento: form.porcentaje_descuento,
            hora: form.hora_habilitacion_entrada,
            multiplicador: form.multiplicador_entrada
          };

          entrada.push(obj);

          console.log(entrada);

          setForm({
            ...form,
            entradas: entrada,
          });
        }
        break;
      case "deleteEntrada":
        {
          let object;
          if (form.entradas.length == 0 && evento) {
            object = JSON.parse(evento.entradas);
          } else {
            object = form.entradas;
          }

          delete object[value];

          object = object.filter((item) => {
            return item != null;
          });

          if (evento) {
            evento.entradas = JSON.stringify(object);
          }

          setForm({
            ...form,
            entradas: object,
          });
        }
        break;
      case "stateEditarEntradas":
        {
          setForm({
            ...form,
            [e.target.name]: value,
          });
        }
        break;
      case "deleteEntradaEvento":
        {
          e.preventDefault();

          Swal.fire({
            title: "¿Estas seguro de querer eliminar esta entrada?",
            text:
              "La entrada " +
              evento.nombre +
              " se eliminara y no se podra revertir, Si la entrada es única el evento pasara a estar inactivo",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Eliminar",
            customClass: {
              cancelButton:
                "btn btn-secondary rounded-pill px-4 mx-1 mb-1 w-100",
              confirmButton:
                "btn btn-danger GothamBold ms-1 rounded-pill px-4 mx-1 mb-2 w-100",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              let dataForm = new FormData();
              dataForm.append("id", value.id);
              let response = await EliminarEntrada(dataForm, setLoading);

              return window.location.reload(true);
            }
          });
        }
        break;
      case "addEntradaEvento":
        {
          if (
            form.nombre_entrada.trim() == "" ||
            form.habilitacion_entrada.trim() == "" ||
            form.precio_entrada.trim() == ""
          ) {
            setAlert({
              ...alert,
              entrada: true,
            });
            return AlertWarning(
              "Falta rellenar campos antes de agregar una nueva entrada.",
              "Advertencia"
            );
          }

          let copyTicket;

          evento.entradas.filter(function (f) {
            if (form.nombre_entrada == f.nombre) {
              return (copyTicket = "SI");
            } else {
              return (copyTicket = "NO");
            }
          });

          if (copyTicket == "SI") {
            return AlertWarning(
              "Esta entrada ya esta configurada dentro de tu lista",
              "Advertencia"
            );
          }

          let dataForm = new FormData();
          dataForm.append("evento_id", evento.id);
          dataForm.append("nombre", form.nombre_entrada);
          dataForm.append("precio", form.precio_entrada);
          dataForm.append("habilitacion", form.habilitacion_entrada);

          if (form.multiplicador_entrada){
            dataForm.append("multiplicador", form.multiplicador_entrada);
          }

          if (form.hora_habilitacion_entrada.trim() !== "") {
            dataForm.append("hora", form.hora_habilitacion_entrada);
          }

          let response = await CrearEntrada(dataForm, setLoading);
          setSteps(steps+1);
        }
        break;
      case "updateEntradaEvento":
        {
          let nombre = form.updateEntradaNombre;
          let precio = form.updateEntradaPrecio;
          let habilitacion = form.updateEntradaHabilitacion;
          let hora = form.updateEntradaHoraCierre;
          let multiplicador = form.multiplicador_entrada;

          if (form.updateEntradaNombre.trim() == "") {
            nombre = value.nombre;
          }

          if (form.updateEntradaPrecio.trim() == "") {
            precio = value.precio;
          }

          if (form.updateEntradaHabilitacion.trim() == "") {
            habilitacion = value.habilitacion_fecha;
          }

          if (form.updateEntradaHoraCierre.trim() == "") {
            hora = value.habilitacion_hora;
          }

          if(!form.multiplicador_entrada){
            multiplicador = value.multiplicador;
          }

          let dataForm = new FormData();
          dataForm.append("id", value.id);
          dataForm.append("nombre", nombre);
          dataForm.append("precio", precio);
          dataForm.append("habilitacion", habilitacion);
          dataForm.append("hora", hora);
          dataForm.append("multiplicador", multiplicador);

          /*
          if (value.descuento_estado == "ACTIVO") {
            if (form.updateEntradaDescuento.trim() == "") {
              porcentaje = value.porcentaje_descuento;
            }

            dataForm.append("porcentaje_descuento", porcentaje);
          }
          */

          let response = await UpdateEntrada(dataForm, setLoading);
          setSteps(steps+1);
          //return window.location.reload(true);
        }
        break;
      case "statedUpdatedEntrada":
        {
          let stated = value.status;

          let dataForm = new FormData();
          dataForm.append("id", value.id);

          if (stated == "ACTIVO") {
            dataForm.append("precio", value.precio);
            dataForm.append("stated", "INACTIVO");
          } else {
            dataForm.append("precio", value.precio);
            dataForm.append("stated", "ACTIVO");
          }

          let response = await UpdateEntrada(dataForm, setLoading);
        }
        break;
      case "cancelEntradaEvento":{
        setForm({
          ...form,
          stateEditarEntradas: false,
        });
      } break;
      //LIQUIDACION
      case "stateEditarLiqui":
        {
          setForm({
            ...form,
            [e.target.name]: value,
          });
        }
        break;
      case "stateArgentores":
        {
          if (e.target.checked) {
            setForm({
              ...form,
              [e.target.name]: true,
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: false,
            });
          }
        }
        break;
      case "addLiquidacion":
        {
          if (
            form.titular.trim() == "" ||
            form.cbu.trim() == "" ||
            form.dato_fiscal.trim() == ""
          ) {
            setAlert({
              ...alert,
              liquidacion: true,
            });
            return AlertWarning(
              "Rellena los campos obligatorios",
              "Advertencia"
            );
          }

          let copyCbu;

          form.liquidacion.map((liqui) => {
            if (liqui.cbu == form.cbu) {
              copyCbu = "TRUE";
            }
          });

          if (copyCbu == "TRUE") {
            return AlertWarning(
              "Este número de CBU ya fue ingresado",
              "Advertencia"
            );
          }

          let liquidacion = form.liquidacion;

          let obj = {
            porcentaje: 0,
            entidad: form.titular,
            cbu: form.cbu,
            identificador: "",
            comprobante_afip: form.comprobante_afip,
            comprobante_entidad: form.comprobante_entidad,
            comprobante_cbu: form.comprobante_cbu,
            dato_fiscal: form.dato_fiscal,
            porcentaje_argentores: form.porcentaje_argentores,
          };

          liquidacion.push(obj);

          setForm({
            ...form,
            liquidacion: liquidacion,
          });
        }
        break;
      case "deleteLiquidacion":
        {
          let object = form.liquidacion;

          delete object[value];

          object = object.filter((item) => {
            return item != null;
          });

          setForm({
            ...form,
            liquidacion: object,
          });
        }
        break;
      case "addLiquidacionEvento":
        {
          if (
            (form.titular.trim() == "" ||
              form.cbu.trim() == "" ||
              form.dato_fiscal.trim() == "" ||
              form.dato_fiscal == "Selecciona su situación fiscal" ||
              form.comprobante_afip == "",
            form.comprobante_cbu == "",
            form.comprobante_entidad == "")
          ) {
            setAlert({
              ...alert,
              liquidacion: true,
            });
            return AlertWarning(
              "Rellena los campos obligatorios",
              "Advertencia"
            );
          }

          let dataForm = new FormData();

          dataForm.append("evento_id", evento.id);
          dataForm.append("cbu", form.cbu);
          dataForm.append("dato_fiscal", form.dato_fiscal);
          dataForm.append("entidad", form.titular);
          dataForm.append("comprobante_afip", form.comprobante_afip);
          dataForm.append("comprobante_cbu", form.comprobante_cbu);
          dataForm.append("comprobante_entidad", form.comprobante_entidad);

          if (!form.porcentaje_argentores == 0) {
            dataForm.append(
              "porcentaje_argentores",
              form.porcentaje_argentores
            );
          }

          let response = await CrearLiquidacion(dataForm, setLoading);
          setSteps(steps+1);
          //return window.location.reload(true);
        }
        break;
      case "deleteLiquidacionEvento":
        {
          e.preventDefault();

          Swal.fire({
            title: "¿Estas seguro de querer eliminar estos datos?",
            text:
              "El CBU número " +
              value.cbu +
              " sera eliminado, Si el dato eliminado es único el evento pasara a estar inactivo",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Eliminar",
            customClass: {
              cancelButton:
                "btn btn-secondary rounded-pill px-4 mx-1 mb-1 w-100",
              confirmButton:
                "btn btn-danger GothamBold ms-1 rounded-pill px-4 mx-1 mb-2 w-100",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              let dataForm = new FormData();
              dataForm.append("id", value.id);
              let response = await EliminarLiquidacion(dataForm, setLoading);

              setSteps(steps+1);
              //return window.location.reload(true);
            }
          });
        }
        break;
      case "updateLiquidacionEvento":
        {
          let dataForm = new FormData();
          
          dataForm.append("id_evento", value_2.id);
          dataForm.append("id", value.id);
          dataForm.append("porcentaje", value.porcentaje);

          if (form.update_comprobante_afip !== "") {
            dataForm.append("comprobante_afip", form.comprobante_afip);
          }
          if (form.update_comprobante_cbu !== "") {
            dataForm.append("comprobante_cbu", form.update_comprobante_cbu);
          }
          if (form.update_comprobante_entidad !== "") {
            dataForm.append(
              "comprobante_entidad",
              form.update_comprobante_entidad
            );
          }
          if (String(form.update_cbu).trim() !== "") {
            dataForm.append("cbu", form.update_cbu);
          }
          if (String(form.update_titular).trim() !== "") {
            dataForm.append("entidad", form.update_titular);
          }
          if (form.update_dato_fiscal.trim() !== "") {
            dataForm.append("dato_fiscal", form.update_dato_fiscal);
          }

          dataForm.append(
            "porcentaje_argentores",
            form.porcentaje_argentores
          );

          if (form.stateArgentores) {
            if (form.porcentaje_argentores !== "") {
              if (value.liquidacion.length == 0) {
                return AlertWarning(
                  "No tiene ningun CBU cargado",
                  "Advertencia"
                );
              }


              dataForm.append(
                "porcentaje_argentores",
                form.porcentaje_argentores
              );
            }
          }

          let response = await UpdateLiquidacion(dataForm, setLoading);
          setSteps(steps+1);

          return ""; //window.location.reload(true);
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "tipo_notificacion":
      case "descripcion_notificacion":
      case "fecha_expiracion":
      case "nombre_notificacion":
        {
          setFormNotification({
            ...formNotification,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //LIQUIDACION
      case "update_comprobante_entidad":
      case "update_comprobante_cbu":
      case "update_comprobante_afip":
      case "comprobante_entidad":
      case "comprobante_cbu":
      case "comprobante_afip":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
          });
        }
        break;
      case "update_porcentaje":
        {
          let obj = evento.liquidacion;

          obj[value].porcentaje = e.target.value;

          setForm({
            ...form,
            liquidacion: obj,
          });

          let valor = 100;
          obj.map((item) => {
            valor = valor - item.porcentaje;

            if (valor < 0) {
              AlertWarning(
                "Paso el limite de porcentaje permitido",
                "Advertencia"
              );

              obj[value].porcentaje = 0;

              setForm({
                ...form,
                liquidacion: obj,
              });

              return setForm({
                ...form,
                total: 0,
              });
            } else {
              return setForm({
                ...form,
                total: valor,
              });
            }
          });
        }
        break;
      case "porcentaje":
        {
          let obj = form.liquidacion;
          obj[value].porcentaje = e.target.value;

          setForm({
            ...form,
            liquidacion: obj,
          });

          let valor = 100;
          obj.map((item) => {
            valor = valor - item.porcentaje;

            if (valor < 0) {
              AlertWarning(
                "Paso el limite de porcentaje permitido",
                "Advertencia"
              );

              obj[value].porcentaje = 0;

              setForm({
                ...form,
                liquidacion: obj,
              });

              return setForm({
                ...form,
                total: 0,
              });
            } else {
              return setForm({
                ...form,
                total: valor,
              });
            }
          });
        }
        break;
      //ENTRADAS
      case "nombre_entrada":
        {
          const validName = ["/", "_", ":", ".", "=", "&", ";"];

          validName.map((valid) => {
            if (e.target.value.includes(valid)) {
              setAlert({
                ...alert,
                entrada: true,
              });

              return AlertWarning(
                "No se admiten caracteres especiales en el nombre de la entrada",
                "Advertencia"
              );
            }
          });

          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "estado_descuento":
        {
          if (e.target.checked) {
            setForm({
              ...form,
              [e.target.name]: true,
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: false,
            });
          }
        }
        break;
      case "updateEntradaDescuento":
      case "porcentaje_descuento":
        {
          if (parseInt(e.target.value) > 100) {
            return setForm({
              ...form,
              [e.target.name]: "",
            });
          } else {
            return setForm({
              ...form,
              [e.target.name]: e.target.value,
            });
          }
        }
        break;
      //CUANDO
      case "day":
        {
          let array = form.dayFilter;

          if (e.target.checked) {
            let deletValue = form["dayFilter"].filter(function (f) {
              return f !== e.target.value;
            });

            setForm({
              ...form,
              dayFilter: deletValue,
            });
          } else {
            array.push(e.target.value);

            setForm({
              ...form,
              dayFilter: array,
            });
          }
        }
        break;
      case "stock_fecha":
        {
          let array;
          if (form.array_fechas.length == 0 && data) {
            array = JSON.parse(evento.fechas);
          } else {
            array = form.array_fechas;
          }
          array[value].stock = e.target.value;

          setForm({
            ...form,
            array_fechas: array,
          });
        }
        break;
      //DONDE
      case "Selectlocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });

          setLocalidad({
            class: "d-none",
            content: null,
          });
        }
        break;
      case "SearchLocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });
          const axios = async () => {
            let result = await obtenerMunicipios(
              form.provincia,
              e.target.value
            );
            setLocalidad({
              class: "form-control",
              content: result,
            });
          };
          setTimeout(axios(), 5000);
        }
        break;
      case "searchSala":
        {
          setFilterSalas({
            ...filter_salas,
            [e.target.name]: e.target.value,
            filtro: "true",
          });

          if (e.target.value.trim() == "") {
            setAutocomplete(false);
          } else {
            setAutocomplete(true);
          }
        }
        break;
      case "selectSala":
        {
          setFilterSalas({
            ...filter_salas,
            searchSala: e.target.value,
            [e.target.name]: value,
          });

          setForm({
            ...form,
            nombre_sitio: value.nombre,
            direccion: value.direccion,
            provincia: value.provincia,
            localidad: value.localidad,
            telefono_sitio: value.telefono,
            website_sitio: value.website,
            email_sitio: value.email,
            maps_sitio: "no",
          });

          setAutocomplete(false);
        }
        break;
      case "nombre_sitio":
      case "email_sitio":
      case "telefono_sitio":
      case "website_sitio":
      case "maps_sitio":
      case "direccion":
      case "provincia":
      case "localidad":
      //LIQUIDACION
      case "update_cbu":
      case "update_titular":
      case "update_dato_fiscal":
      case "titular":
      case "dato_fiscal":
      case "cbu":
      case "porcentaje_argentores":
      //ENTRADAS
      case "tipo_entrada":
      case "hora_habilitacion_entrada":
      case "habilitacion_entrada":
      case "precio_entrada":
      case "updateEntradaNombre":
      case "updateEntradaPrecio":
      case "updateEntradaHabilitacion":
      case "updateEntradaHoraCierre":
      case "multiplicador_entrada":
      //CUANDO
      case "time":
      case "stock":
      //QUE
      case "clase":
      case "grupo":
      case "link_value":
      case "link_key":
      case "ficha_key":
      case "ficha_value":
      case "duracion":
      case "nombre_grupo":
      case "nombre":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "categorias":
        {
          let value = form[e.target.name];

          if (value.length == 3 && e.target.checked == true) {
            return AlertWarning(
              "Solo puedes selecionar 3 categorias",
              "Advertencia"
            );
          }

          if (e.target.checked) {
            value.push(e.target.value);
            setForm({
              ...form,
              [e.target.name]: value,
            });
          } else {
            let deletValue = form[e.target.name].filter(function (f) {
              return f !== e.target.value;
            });
            setForm({
              ...form,
              [e.target.name]: deletValue,
            });
          }
        }
        break;
      case "atp":
        {
          if (e.target.checked) {
            setForm({
              ...form,
              [e.target.name]: "SI",
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: "NO",
            });
          }
        }
        break;
      case "imagen_evento":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            imagen_evento_url: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
      case "update_categorias":
        {
          if (e.target.checked) {
            let obj = {
              id: value.id,
              nombre: value.nombre,
            };

            if (form.update_categorias.length == 3) {
              return AlertWarning(
                "Solo se puede cambiar hasta tres categorias, quite uno y vuelva a seleccionar.",
                "Advertencia"
              );
            }

            setForm({
              ...form,
              update_categorias: [...form.update_categorias, obj],
            });

            if (form.update_categorias.length >= 3) {
              return AlertWarning(
                "LLegaste al tope maximo de cambios de categoria",
                "Advertencia"
              );
            }
          } else {
            let deleteObj = form.update_categorias;

            deleteObj.map((categoria, index) => {
              if (categoria.id == value.id) {
                delete deleteObj[index];
              }
            });

            deleteObj = deleteObj.filter((item) => {
              return item != null;
            });

            setForm({
              ...form,
              update_categorias: deleteObj,
            });
          }
        }
        break;
      case "url_youtube":
        {
          let link = e.target.value;
          link = link.split("&");
          link = link[0].replace("watch?v=", "embed/");

          setForm({
            ...form,
            [e.target.name]: link,
          });
        }
        break;
    }
  };

  const onSave = async (e, value) => {
    let formData = new FormData();

    formData.append("status", value);

    if (form.entradas.length == 0) {
      return Swal.fire({
        title: "Advertencia",
        text: "Si guarda en este momento de la carga el evento quedara guardado en borradores.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        buttonsStyling: false,
        customClass: {
          cancelButton: "btn btn-secondary rounded-pill px-4 mx-1 mb-1 w-100",
          confirmButton:
            "btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4 mx-1 mb-2 w-100",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          //GUARDAR EVENTO

          if (
            form.imagen_evento == "" ||
            form.nombre.trim() == "" ||
            form.duracion.trim() == "" ||
            form.clase.trim() == "" ||
            form.grupo.trim() == "" ||
            form.categorias.length == 0 ||
            form.descripcion.trim() == "" ||
            !form.ficha_tecnica
          ) {
            setAlert({
              ...alert,
              datos: true,
              ficha: true,
            });
            return AlertError("Faltan completar datos obligatorios", "Error");
          }

          for (let i = 0; i < form.categorias.length; i++) {
            let stringCategoria = "categorias[" + i + "]";

            formData.append(stringCategoria, form.categorias[i]);
          }

          formData.append("imagen_evento", form.imagen_evento);
          formData.append("nombre", form.nombre);
          formData.append("nombre_grupo", form.nombre_grupo);
          formData.append("descripcion", form.descripcion);
          formData.append("atp", form.atp);
          formData.append("clase", form.clase);
          formData.append("grupo", form.grupo);
          formData.append("duracion", form.duracion);
          formData.append("ficha_tecnica", JSON.stringify(form.ficha_tecnica));

          if (form.links) {
            formData.append("link", JSON.stringify(form.links));
          }

          if (!form.nombre_sitio.trim() == "") {
            formData.append("nombre_sitio", form.nombre_sitio);
          }

          if (!form.email_sitio.trim() == "") {
            formData.append("email_sitio", form.email_sitio);
          }

          if (!form.telefono_sitio.trim() == "") {
            formData.append("telefono_sitio", form.telefono_sitio);
          }

          if (!form.url_youtube.trim() == "") {
            formData.append("url_youtube", form.url_youtube);
          }

          if (!form.website_sitio.trim() == "") {
            formData.append("website_sitio", form.website_sitio);
          }

          if (!form.direccion.trim() == "") {
            formData.append("direccion", form.direccion);
          }

          if (!form.provincia.trim() == "") {
            formData.append("provincia", form.provincia);
          }

          if (!form.localidad.trim() == "") {
            formData.append("localidad", form.localidad);
          }

          if (form.array_fechas.length !== 0) {
            formData.append("fechas", JSON.stringify(form.array_fechas));
          }

          //LIQUIDACION
          if (user.data.facturacion.length !== 0) {
            formData.append("liquidacion_titular", "true");
            formData.append(
              "liquidacion_titular_porcentaje",
              user.data.facturacion[0].porcentaje
            );
          }

          form.liquidacion.shift();

          for (let i = 0; i < form.liquidacion.length; i++) {
            let stringNameAfip = "comprobante_afip_" + i;
            let stringNameCbu = "comprobante_cbu_" + i;
            let stringNameEntidad = "comprobante_entidad_" + i;

            /*console.log(form.liquidacion[i]["comprobante_afip"]);*/

            formData.append(
              stringNameAfip,
              form.liquidacion[i].comprobante_afip
            );
            formData.append(stringNameCbu, form.liquidacion[i].comprobante_cbu);
            formData.append(
              stringNameEntidad,
              form.liquidacion[i].comprobante_entidad
            );
          }

          if (form.porcentaje_argentores !== "") {
            formData.append(
              "porcentaje_argentores",
              form.porcentaje_argentores
            );
          }

          formData.append("liquidacion", JSON.stringify(form.liquidacion));

          let response = await CrearEvento(formData, setLoading);
          //return (window.location.href ="/dashboard/eventos/editar/" + response);
        }
      });
    }

    //PUBLICAR EVENTO

    if (
      //CUANDO
      form.array_fechas.length == 0 ||
      //DONDE
      form.nombre_sitio.trim() == "" ||
      form.email_sitio.trim() == "" ||
      form.telefono_sitio.trim() == "" ||
      form.direccion.trim() == "" ||
      form.provincia.trim() == "" ||
      //QUE
      form.imagen_evento == "" ||
      form.nombre.trim() == "" ||
      form.duracion.trim() == "" ||
      form.clase.trim() == "" ||
      form.grupo.trim() == "" ||
      form.categorias.length == 0 ||
      form.descripcion.trim() == "" ||
      !form.ficha_tecnica
    ) {
      setAlert({
        ...alert,
        datos: true,
        ficha: true,
        sitio: true,
        fecha: true,
      });
      return AlertError("Falta completar datos obligatorios", "Error");
    }

    if (form.liquidacion.length == 0 && value !== 4) {
      setAlert({
        ...alert,
        datos: true,
        ficha: true,
        sitio: true,
        fecha: true,
      });
      return AlertWarning(
        "El evento no se puede publicar hasta completar los datos de liquidación",
        "Advertencia"
      );
    }

    for (let i = 0; i < form.categorias.length; i++) {
      let stringCategoria = "categorias[" + i + "]";
      formData.append(stringCategoria, form.categorias[i]);
    }

    //QUE
    formData.append("imagen_evento", form.imagen_evento);
    formData.append("nombre", form.nombre);
    formData.append("descripcion", form.descripcion);
    formData.append("atp", form.atp);
    formData.append("clase", form.clase);
    formData.append("grupo", form.grupo);
    formData.append("duracion", form.duracion);
    formData.append("ficha_tecnica", JSON.stringify(form.ficha_tecnica));
    if (!form.url_youtube.trim() == "") {
      formData.append("url_youtube", form.url_youtube);
    }
    if (form.links) {
      formData.append("link", JSON.stringify(form.links));
    }
    if (!form.nombre_grupo.trim() == "") {
      formData.append("nombre_grupo", form.nombre_grupo);
    }
    //CUANDO
    formData.append("fechas", JSON.stringify(form.array_fechas));
    //DONDE
    formData.append("nombre_sitio", form.nombre_sitio);
    formData.append("email_sitio", form.email_sitio);
    formData.append("telefono_sitio", form.telefono_sitio);
    formData.append("direccion", form.direccion);
    formData.append("provincia", form.provincia);
    formData.append("localidad", form.localidad);

    if (form.website_sitio) {
      if (!form.website_sitio.trim() == "") {
        formData.append("website_sitio", form.website_sitio);
      }
    }

    if (form.maps_sitio) {
      if (!form.maps_sitio.trim() == "") {
        formData.append("maps_sitio", form.maps_sitio);
      }
    }
    //ENTRADA
    formData.append("entradas", JSON.stringify(form.entradas));
    //LIQUIDACION
    if (user.data.facturacion.length !== 0) {
      formData.append("liquidacion_titular", "true");
      formData.append(
        "liquidacion_titular_porcentaje",
        user.data.facturacion[0].porcentaje
      );
    }

    form.liquidacion.shift();

    for (let i = 0; i < form.liquidacion.length; i++) {
      let stringNameAfip = "comprobante_afip_" + i;
      let stringNameCbu = "comprobante_cbu_" + i;
      let stringNameEntidad = "comprobante_entidad_" + i;

      /*console.log(form.liquidacion[i]["comprobante_afip"]);*/

      formData.append(stringNameAfip, form.liquidacion[i].comprobante_afip);
      formData.append(stringNameCbu, form.liquidacion[i].comprobante_cbu);
      formData.append(
        stringNameEntidad,
        form.liquidacion[i].comprobante_entidad
      );
    }

    if (form.porcentaje_argentores !== "") {
      formData.append("porcentaje_argentores", form.porcentaje_argentores);
    }

    formData.append("liquidacion", JSON.stringify(form.liquidacion));

    let response = await CrearEvento(formData, setLoading);
  };

  const onAply = async (e) => {
    e.preventDefault();

    let dataForm = new FormData();

    dataForm.append("id_evento", evento.id);
    dataForm.append("url_youtube", form.url_youtube);

    if (form.update_categorias) {
      let obj = form.update_categorias;

      for (let i = 0; i < evento.categorias.length; i++) {
        if (i <= obj.length - 1) {
          obj[i].pivot = evento.categorias[i].pivot.id;
        }
      }

      dataForm.append("id_evento", evento.id);
      dataForm.append("categorias", JSON.stringify(obj));

      let response = await ActualizarEventoCategorias(dataForm, setLoading);
    }

    if (!form.nombre == "") {
      dataForm.append("nombre", form.nombre);
    }

    if (!form.nombre_grupo == "") {
      dataForm.append("nombre_grupo", form.nombre_grupo);
    }

    if (!form.descripcion == "") {
      dataForm.append("descripcion", form.descripcion);
    }

    if (!form.imagen_evento == "") {
      dataForm.append("imagen_evento", form.imagen_evento);
    }

    if (!form.atp == "") {
      dataForm.append("atp", form.atp);
    }

    if (!form.duracion == "") {
      dataForm.append("duracion", form.duracion);
    }

    if (!form.nombre_sitio == "") {
      dataForm.append("nombre_sitio", form.nombre_sitio);
    }

    if (!form.email_sitio == "") {
      dataForm.append("email_sitio", form.email_sitio);
    }

    if (!form.telefono_sitio == "") {
      dataForm.append("telefono_sitio", form.telefono_sitio);
    }

    if (!form.website_sitio == "") {
      dataForm.append("website_sitio", form.website_sitio);
    }

    if (!form.maps_sitio == "") {
      dataForm.append("maps_sitio", form.maps_sitio);
    }

    if (!form.direccion == "") {
      dataForm.append("direccion", form.direccion);
    }

    if (!form.provincia == "") {
      dataForm.append("provincia", form.provincia);
    }

    if (!form.localidad == "") {
      dataForm.append("localidad", form.localidad);
    }

    if (!form.clase == "") {
      dataForm.append("clase", form.clase);
    }

    if (!form.grupo == "") {
      dataForm.append("grupo", form.grupo);
    }

    if (!form.ficha_tecnica == "") {
      dataForm.append("ficha_tecnica", JSON.stringify(form.ficha_tecnica));
    }

    if (!form.links == "") {
      dataForm.append("link", JSON.stringify(form.links));
    }

    if (!form.array_fechas.length == 0) {
      dataForm.append("fechas", JSON.stringify(form.array_fechas));
    }

    let result = await ActualizarMisEventos(dataForm, setLoading);
    setSteps(steps + 1);
  };

  const onSend = async (e) => {
    let dataForm = new FormData();

    if (
      formNotification.fecha_expiracion.trim() == "" ||
      formNotification.nombre_notificacion.trim() == "" ||
      formNotification.descripcion_notificacion.trim() == "" ||
      formNotification.tipo_notificacion.trim() == ""
    ) {
      setAlert({
        ...alert,
        notificacion: true,
      });
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    dataForm.append("id_user_destinatario", selectElement.usuarios.id);
    dataForm.append("nombre", formNotification.nombre_notificacion);
    dataForm.append("descripcion", formNotification.descripcion_notificacion);
    dataForm.append("tipo", formNotification.tipo_notificacion);
    dataForm.append("fecha_expiracion", formNotification.fecha_expiracion);

    const response = await AdministradorCrearNotificacion(dataForm, setLoading);

    setModalNotificacion(false);
    setLoading(false);
  };


  useEffect(() => {
    salas(
      setSalas,
      filter_salas.id,
      filter_salas.searchSala,
      filter_salas.provincia,
      filter_salas.localidad,
      filter_salas.filtro
    );
  }, [filter_salas]);

  useEffect(() => {
    if (dataDatoFiscal.length == 0) {
      obtenerDatoFiscal(setDatoFiscal);
    }

    if (!dataCategorias) {
      obtenerCategorias(setCategorias, filter_categoria);
    }

    /*
    if (!dataGeolocation) {
      setTimeout(
        geolocationApi(
          setGeolocation,
          "https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre"
        ),
        5000
      );
    }
    */
  }, []);

  useEffect(() => {
    provincias(setGeolocation);
  }, [steps]);



  return (
    <div className="container-md p-0 min-vh-100">
      <div className="row justify-content-center bg-light min-wh-100 p-0 m-0">
        {rol == 1 ? (
          data ? (
            <div className="col-md-12 d-md-flex justify-content-end enter align-items-center">
              <button
                style={{ borderRadius: "10px" }}
                className="btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center align-items-center me-2  px-2 effect-text-hidden"
                name="historial"
                onFocus={(event) => onClick(event, data)}
              >
                {" "}
                <i class="mx-1 bi bi-clock-history "></i>{" "}
                <span className="d-none">Historial</span>
              </button>
              <button
                style={{ borderRadius: "10px" }}
                className="btn btn-custom-amarillo text-white GothamBold effect-text-hidden px-2"
                name="notificacion"
                onFocus={(event) => onClick(event, data)}
              >
                {" "}
                <i class="fs-6 mx-1 bi bi-send-plus"></i>{" "}
                <span className="d-none">Enviar notificacion</span>{" "}
              </button>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="col-md-12 row d-flex justify-content-between my-3 border-0 border-bottom border-dark px-0">
          <button
            className={
              label == "que"
                ? "col-md-2 btn text-white fw-normal GothamBold d-flex justify-content-center align-items-center bg-gris-oscuro"
                : "col-md-2 btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center mb-1 align-items-center"
            }
            style={
              label == "que"
                ? { borderRadius: "10px 10px 0px 0px" }
                : { borderRadius: "10px" }
            }
            name="label"
            onClick={(event) => onClick(event, "que")}
          >
            ¿Qué?
          </button>
          <button
            className={
              label == "donde"
                ? "col-md-2 btn text-white fw-normal GothamBold d-flex justify-content-center align-items-center bg-gris-oscuro"
                : "col-md-2 btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center mb-1 align-items-center"
            }
            style={
              label == "donde"
                ? { borderRadius: "10px 10px 0px 0px" }
                : { borderRadius: "10px" }
            }
            name="label"
            onClick={(event) => onClick(event, "donde")}
          >
            ¿Dónde?
          </button>
          <button
            className={
              label == "cuando"
                ? "col-md-2 btn  text-white fw-normal GothamBold d-flex justify-content-center align-items-center bg-gris-oscuro"
                : "col-md-2 btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center mb-1 align-items-center"
            }
            style={
              label == "cuando"
                ? { borderRadius: "10px 10px 0px 0px" }
                : { borderRadius: "10px" }
            }
            name="label"
            onClick={(event) => onClick(event, "cuando")}
          >
            ¿Cuándo?
          </button>
          <button
            className={
              label == "entradas"
                ? "col-md-2 btn  text-white fw-normal GothamBold d-flex justify-content-center align-items-center bg-gris-oscuro"
                : "col-md-2 btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center mb-1 align-items-center"
            }
            style={
              label == "entradas"
                ? { borderRadius: "10px 10px 0px 0px" }
                : { borderRadius: "10px" }
            }
            name="label"
            onClick={(event) => onClick(event, "entradas")}
          >
            Entradas
          </button>
          <button
            className={
              label == "liquidacion"
                ? "col-md-2 btn  text-white fw-normal GothamBold d-flex justify-content-center align-items-center bg-gris-oscuro"
                : "col-md-2 btn btn-custom-naranja text-white fw-normal GothamBold d-flex justify-content-center mb-1 align-items-center"
            }
            style={
              label == "liquidacion"
                ? { borderRadius: "10px 10px 0px 0px" }
                : { borderRadius: "10px" }
            }
            name="label"
            onClick={(event) => onClick(event, "liquidacion")}
          >
            Liquidación
          </button>
        </div>

        <div className="col-12 mx-0 px-0 texto-normal">
          {label == "que" ? (
            <Que
              onClick={onClick}
              onChange={onChange}
              setForm={setForm}
              loading={loading}
              form={form}
              categorias={dataCategorias}
              evento={id? data : null}
              alert={alert}
            />
          ) : label == "donde" ? (
            <Donde
              onClick={onClick}
              onChange={onChange}
              setForm={setForm}
              loading={loading}
              form={form}
              categorias={dataCategorias}
              evento={id? data : null}
              dataSalas={dataSalas}
              dataGeolocation={dataGeolocation}
              dataLocalidad={dataLocalidad}
              filter_salas={filter_salas}
              alert={alert}
              stateAut={stateAut}
              autocomplete={autocomplete}
            />
          ) : label == "cuando" ? (
            <Cuando
              onClick={onClick}
              onChange={onChange}
              setForm={setForm}
              dateRange={dateRange}
              loading={loading}
              form={form}
              categorias={dataCategorias}
              evento={id? data : null}
              alert={alert}
              locale={locale}
              onFecha={onFecha}
              fecha={fecha}
            />
          ) : label == "entradas" ? (
            <Entradas
              onClick={onClick}
              onChange={onChange}
              setForm={setForm}
              loading={loading}
              form={form}
              categorias={dataCategorias}
              evento={id? data : null}
              alert={alert}
            />
          ) : label == "liquidacion" ? (
            <Liquidacion
              onClick={onClick}
              onChange={onChange}
              dateRange={dateRange}
              setForm={setForm}
              form={form}
              rule={rol}
              alert={alert}
              loading={loading}
              evento={id? data : null}
              DatoFiscal={dataDatoFiscal}
              facturacion={user.data.facturacion[0]}
            />
          ) : (
            ""
          )}
        </div>

        {evento ? (
          label == "entradas" || label == "liquidacion" ? (
            ""
          ) : (
            <button
              className="fixed-bottom w-100 mx-0 btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              onClick={(event) => onAply(event)}
              disabled={loading ? true : false}
            >
              {loading ? (
                <span
                  className="spinner-grow spinner-grow-sm mx-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Aplicar cambios
            </button>
          )
        ) : label == "liquidacion" ?  (
            <div className="fixed-bottom w-100 mx-0 d-flex">
              <button
                className="w-50 me-1 mb-1 btn btn-custom-naranja rounded-pill text-white fw-normal GothamBold"
                onClick={(event) => onSave(event, 4)}
                disabled={loading ? true : false}
              >
                {loading ? (
                  <span
                    className="spinner-grow spinner-grow-sm mx-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                Guardar en borradores
              </button>
              <button
                className=" w-50 ms-1 mb-1 btn btn-custom-naranja rounded-pill text-white fw-normal GothamBold"
                onClick={(event) => onSave(event, 1)}
                disabled={form.entradas.length == 0 ? true : loading }
              >
                {loading ? (
                  <span
                    className="spinner-grow spinner-grow-sm mx-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                Publicar evento
              </button>
            </div>
          ) : label == "que" ? (
          <button
            className="fixed-bottom w-100 mx-0 btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
            name="siguiente"
            onClick={(event) => onClick(event)}
          >
            Siguiente <i class="fs-5 bi bi-arrow-right-circle"></i>
          </button>
        ) : label == "donde" ? (
          <div className="d-flex fixed-bottom w-100">
            <button
              className="w-50 me-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="anterior"
              onClick={(event) => onClick(event)}
            >
              <i class="fs-5 bi bi-arrow-left-circle"></i> Anterior
            </button>
            <button
              className="w-50 ms-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="siguiente"
              onClick={(event) => onClick(event)}
            >
              Siguiente <i class="fs-5 bi bi-arrow-right-circle"></i>
            </button>
          </div>
        ) : label == "cuando" ? (
          <div className="d-flex fixed-bottom w-100">
            <button
              className="w-50 me-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="anterior"
              onClick={(event) => onClick(event)}
            >
              <i class="fs-5 bi bi-arrow-left-circle"></i> Anterior
            </button>
            <button
              className="w-50 ms-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="siguiente"
              onClick={(event) => onClick(event)}
            >
              Siguiente <i class="fs-5 bi bi-arrow-right-circle"></i>
            </button>
          </div>
        ) : label == "entradas" ? (
          <div className="d-flex fixed-bottom w-100">
            <button
              className="w-50 me-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="anterior"
              onClick={(event) => onClick(event)}
            >
              <i class="fs-5 bi bi-arrow-left-circle"></i> Anterior
            </button>
            <button
              className="w-50 ms-1 mb-1 mx-0 rounded-pill btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
              name="siguiente"
              onClick={(event) => onClick(event)}
            >
              Siguiente <i class="fs-5 bi bi-arrow-right-circle"></i>
            </button>
          </div>
        ) : (
          ""
        )}

        {modal_notificacion ? (
          <ModalNotificacion
            onClick={onClick}
            onChange={onChange}
            onSend={onSend}
            loading={loading}
            alert={alert}
            data={selectElement}
            formNotification={formNotification}
          />
        ) : (
          ""
        )}

        {modal_historial ? (
          <ModalHistorial onClick={onClick} data={selectElement.historia} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FormEvento;
