import React from "react";
import Actualidad from "./Sections/Actualidad/Actualidad";
import Apoyo from "./Sections/Apoyo/Apoyo";
import Calendario from "./Sections/Calendario/Calendario";
import HomeEventos from "./Sections/Eventos/HomeEventos";
import News from "./Sections/News/News";
import Publicidad from "./Sections/Publicidad/Publicidad";
import SliderHome from "./Sections/Slider/SliderHome";
import Suscriptions from "./Sections/Suscriptions/Suscriptions";
import Videos from "./Sections/Videos/Videos";

const Home = () => {

  
  //<Publicidad />
  //<News />

  return(
    <>
    <SliderHome />
    <Calendario />
    <HomeEventos /> 
    <Actualidad />
    <Videos/>
    <Apoyo />
    </>
  );
}

export default Home;