const Header = ({ onClick, labels, setLabel, label, id, form, setForm }) => {
  return (
    <div className="container-md  w-100">
      <div class=" border-0 border-bottom w-100 p-0 m-0 d-inline d-md-flex ">
        {labels.map((name, index) => (
          <div class="bg-transparent mb-2 border-0 px-0 m-0 mx-1">
            <label
              className={
                index == label
                  ? "fw-normal GothamBold btn btn-dark text-white w-100 px-5"
                  : "fw-normal GothamBold btn-custom-naranja text-center text-white w-100"
              }
              style={{ borderRadius: "10px 10px 0px 0px" }}
            >
              {name}
              <button
                className="d-none"
                onClick={(event) => setLabel(index)}
              ></button>
            </label>
          </div>
        ))}
      </div>

      {id == 0 ? (
        <div className="d-flex justify-content-end w-100 my-2">
          <label className="btn btn-outline-dark border-custom-naranja  fw-normal GothamBold text-custom-naranja px-4 py-2 me-2">
            Guardar en Borradores
            <button
              className="d-none"
              name="guardar"
              onClick={(event) => onClick(event, 4)}
            ></button>
          </label>
          <label className="btn btn-primary fw-normal GothamBold text-white px-4 ">
            Publicar
            <button
              className="d-none"
              name="guardar"
              onClick={(event) => onClick(event, 1)}
            ></button>
          </label>
        </div>
      ) : (
        <div className="d-flex justify-content-end w-100 my-2">
          <div class="dropdown me-2">
            <button
              class="btn btn-outline-dark dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {form.status_id[0] == 1 ? (
                <span className="text-success">
                  Publicado
                  <i class="bi bi-check-all"></i>
                </span>
              ) : form.status_id[0] == 2 ? (
                <span className="text-secondary">
                  Pausado
                  <i class="ms-2 bi bi-slash-circle"></i>
                </span>
              ) : form.status_id[0] == 4 ? (
                <span className="text-info">
                  Borrador
                  <i class="ms-2 bi bi-recycle"></i>
                </span>
              ) : (
                ""
              )}
            </button>
            <ul class="dropdown-menu">
              {form.status_id[0] == 1 ? (
                ""
              ) : (
                <li>
                  <label
                    class="dropdown-item text-success btn btn-outline-dark border-0"
                    href="#"
                  >
                    Publicar
                    <i class="bi bi-check-all"></i>
                    <button className="d-none"
                    name="status"
                    onClick={(event) => onClick(event, 1)}
                    ></button>
                  </label>
                </li>
              )}
              {form.status_id[0] == 4 ? (
                ""
              ) : (
                <li>
                  <label
                    class="dropdown-item text-info btn btn-outline-dark border-0"
                    href="#"
                  >
                    Borrador
                    <i class="ms-2 bi bi-recycle"></i>
                    <button className="d-none"
                    name="status"
                    onClick={(event) => onClick(event, 4)}
                    ></button>
                  </label>
                </li>
              )}
              {form.status_id[0] == 2 ? (
                ""
              ) : (
                <li>
                  <label
                    class="dropdown-item text-secondary btn btn-outline-dark border-0"
                    href="#"
                  >
                    Pausado
                    <i class="ms-2 bi bi-slash-circle"></i>
                    <button
                      className="d-none"
                      name="status"
                      onClick={(event) => onClick(event, 2)}
                    ></button>
                  </label>
                </li>
              )}
            </ul>
          </div>

                {
                  label == 3 || label == 4
                  ? ""
                  : <label className="btn btn-custom-naranja fw-normal GothamBold text-white px-4 ">
                  Aplicar cambios
                  <button
                    className="d-none"
                    name="aplicar"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                }
        </div>
      )}
    </div>
  );
};

export default Header;
