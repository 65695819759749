import clientAxios from "./Config";
import axios from "axios";
import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import AlertSuccessXl from "../Alerts/AlertSuccessXl";



export const TokenCard = async (formData, setLoading) => {
  
  //const urlDev = "https://developers.decidir.com/api/v2/tokens"
  //const urlPrd = "https://live.decidir.com/api/v2/tokens"
  //const apiKeyDev = "uRtPzTm6qqGl7NnTMPepwVTW7LqBKmIr";
  //const apiKeyPrd = "5b66cfc46dd144e49d816484e5ee652d"

  //API KEY process REACT_APP_PUBLIC_KEY_LOCAL // REACT_APP_PUBLIC_KEY_PRODUCTION 
  const apiKey = process.env.REACT_APP_PUBLIC_KEY_PRODUCTION  ;
  //URL proccess 
  const url = process.env.REACT_APP_URL_PRODUCTION ;

  setLoading(true);

  const response = await axios({
    method: "post",
    url: url,
    headers: {
      apikey: apiKey,
    },
    data: formData
  }).then((result) =>{
    return result.data 
  }).catch((result) => {
   setLoading(false);
   return AlertError("Error con los datos de su tarjeta","ERROR")
  })
  
  return response
}


export const Checkout = async (formData, setLoading, setStatePayment) => {
  setLoading(true);

  const response = await clientAxios({
    method: "post",
    url: "/api/SolicitarPago",
    data: formData
  }).then((result) =>{
    setStatePayment(true);
    AlertSuccessXl(result.data.msg, ("¡Gracias por su compra!"));
  }).catch((error) => {
    setLoading(false);
    if(error.response.status == 400){
    return AlertError(error.response.data.data, error.response.data.msg)
    }
  })

}

export const CheckoutFree = async (formData, setLoading) => {
  //setLoading(true);
  
  const response = await clientAxios({
    method: "post",
    url: "/api/SolicitarPagoFree",
    data: formData
  }).then((result) =>{
    setLoading(false);
    return AlertSuccessXl(result.data.msg, "Confirmamos su pedido.");
  }).catch((error) => {
    setLoading(false);
    if(error.response.status == 400){
      return AlertError(error.response.data.msg,"Error");
    }
  })

  return response.data;
}