import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ModalAddVideo = ({
  onClick,
  onChange,
  data,
  form,
  setForm,
  provincia,
  onSave,
  onAply
}) => {
  return (
    <div
      className="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        className="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "1200px" }}
      >
        <div className="modal-content bg-light">
          <div className="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Agrega tu video</h1>
              <span className="fs-6 text-muted">
                Agrega un video nuevo a la lista.{" "}
              </span>
            </div>
            <div>
              <button
                className="border-0 bg-transparent"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="body p-4">
            <form action="" className="row bg-white p-2">
              <div className="col-md-12 col-12 d-flex justify-content-center align-items-center">
                <label for="formImagenEvento" className="form-label">
                  <div
                    className="btn p-0 border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                    style={{ height: "300px" }}
                  >
                    <img
                      src={form.imagen_url?   form.imagen_url :  data? "/assets/img/videos/"+data.imagen : ""}
                      className="img-fluid"
                      alt="IMAGEN OBLIGATORIA"
                    />
                    <div className="img-edit-pencil">
                      <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                      <span className="fs-7 d-block">
                        Formatos admitidos JPG PNG JPEG{" "}
                      </span>
                      <span className="fs-7">
                        Dimension sugerida 500x500 72DPI{" "}
                      </span>
                    </div>
                  </div>
                </label>
                <input
                  className="form-control d-none"
                  type="file"
                  id="formImagenEvento"
                  name="imagen"
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-12 mb-3 me-2 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  titulo
                </label>
                <input
                  className="form-control border-custom-naranja rounded-pill"
                  placeholder="Titulo..."
                  defaultValue={data? data.titulo : ""}
                  type="text"
                  name="titulo"
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-12 mb-3 me-2 w-100">
                <label className="text-muted fs-6 mb-1" htmlFor="">
                  Descripcion
                </label>
                <textarea
                  className="form-control border-custom-naranja"
                  placeholder="Descripcion..."
                  defaultValue={data? data.descripcion : ""}
                  type="text"
                  name="descripcion"
                  onChange={(event) => onChange(event)}
                />
              </div>

              {provincia ? (
                <div className="col-md-12 mb-3 me-2 w-100">
                  <label className="text-muted fs-6 mb-1" htmlFor="">
                    Provincia habilitada
                  </label>
                  <select
                    className="form-select border-custom-naranja rounded-pill"
                    aria-label="Default select example"
                    name="provincia"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected>{data? data.provincia : ""}</option>
                    {
                      provincia.map((provin) => (
                        <option value={provin.nombre}>{provin.nombre}</option>
                      ))
                    }
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 col-12 p-1 pb-0 d-none">
                <div className="" style={{ height: "auto" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={form.content ? form.content : ""}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log();
                    }}
                    onChange={(event, editor) => {
                      //const data = editor.getData();
                      setForm({
                        ...form,
                        content: editor.getData(),
                      });
                    }}
                    onBlur={(event, editor) => {
                      console.log();
                      //console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                      console.log();
                      //console.log( 'Focus.', editor );
                    }}
                  />
                </div>
              </div>

              <div className="col-md-12 my-4">
                <label htmlFor="" className="text-muted fs-6 mb-1">
                  Insertar url de video
                </label>
                <input
                  type="text"
                  className="form-control border-custom-naranja"
                  placeholder="Copia y pega el url de tu video de Youtube"
                  defaultValue={data ? data.url : form.url}
                  name="url"
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-12 d-flex justify-content-center mb-4">
                <iframe
                  width="560"
                  height="315"
                  src={
                    form.url.trim() == "" ? (data ? data.url : "") : form.url
                  }
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </form>
          </div>
          <div className="footer bg-white p-4">
            <div className="d-flex justify-content-between">
              <div></div>
              <div> 
              <button
                className="btn btn-secondary rounded-pill px-4 me-1"
                name="close_modal"
                onFocus={(event) => onClick(event, null)}
              >
                cerrar
              </button>
              {
                data
                ? <button
                className="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                name="save"
                onClick={(event) => onAply(event, data)}
              >
                Aplicar
              </button>
                : <button
                className="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                name="save"
                onClick={(event) => onSave(event)}
              >
                Guardar
              </button>
              }
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddVideo;
