import { useState } from "react";
import { Link } from "react-router-dom";
import { RestaurarContraseña } from "../../../helpers/AccessHelper";
import Swal from "sweetalert2";

const ForgotPassword = () => {

  const [email, setEmail] = useState("")

  const onChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if(
      email.trim() == " "
    ){return Swal.fire({
        text: "Por favor escriba su Email para poder enviar un correo de recuperacion.",
        timer: "2000",
        icon: "alert",
        position: "bottom",
        showConfirmButton: false,
        customClass: {
          container: "add-to-cart-alert-container",
          popup: "add-to-cart-alert",
        }
      })
    }


    let dataForm = new FormData();
    dataForm.append('email', email);

    RestaurarContraseña(dataForm)
  }

  return(
    <div className="container-md texto-normal min-vh-100 d-flex justify-content-center">

      <div className="d-flex justify-content-center mx-auto py-5">

        <form action="" className="p-0 p-md-4">
          <div className="text-center mb-3 mx-auto">
            <h1><p>Recupera tu</p><p>Contraseña</p></h1>
          </div>

          <div className="mb-3">
            <label for="inputEmail" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Ingrese su Email"
              name="email"
              onChange={(e) => onChange(e)}
            />
          </div>

          <ul className="list-group mb-3">
            <li className="list-group-item border-0 text-muted p-0 m-0">
              Ingrese su Email y le enviaremos un correo con el Link para poder recuperar su contraseña
            </li>
            <li className="list-group-item border-0 text-muted fs-6 p-0 m-0">Si el correo no aparece, visite su casilla de Spam</li>
          </ul>

          <button
            className="btn btn-custom-amarillo w-100 mb-3"
            onClick={onSubmit}
          >
            Enviar
          </button>

          <div className="w-100 text-center">
            <a className="text-decoration-none d-block" href=""><span className="text-dark">Al ingresar acepta nuestros</span> Terminos y Condiciones</a>

            <Link className="text-decoration-none" aria-label="Ingresar"
              to="/registro"><span className="fw-light">¿No tienes una cuenta?</span><span className="fw-normal"> Obtener una cuenta </span></Link>
          </div>

        </form>

      </div>
    </div>
  );
}

export default ForgotPassword;