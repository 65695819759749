export const Tickets = (value, select) => {

    let suma = 0;
    value = value.length > 0 ? value : [value]


    value.map((evento) => {
      evento.reportes.map((reporte) => {
        if(select == "tickets"){
          if(reporte.status == "ACTIVO" || reporte.status == "LIQUIDADO"){
            suma = reporte.cantidad+suma
          }
        } else {
          if(reporte.status == "ACTIVO" || reporte.status == "LIQUIDADO"){
          suma = (reporte.cantidad*parseFloat(reporte.unidad))+suma
          }
        }
      });
    })


    return suma;
  }