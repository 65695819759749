//Date format
import { format, add, sub } from "date-fns";
import { useState } from "react";
//Hooks
import useApi from "../../../../../App/Hooks/useApi";
//Excel download
import ReactHTMLTableToExcel from "react-html-table-to-excel";
//Components
import { convertMesStartEnd } from "../../../../../Functions/FunctionInformes";
import {
  filterReports,
  totalReportEvents,
} from "../../../../../Functions/FunctionReportes";
import { ConcatDate } from "../../../../../App/Functions/ConcatDate";
//

const ModalInformeMes = ({ onClick }) => {
  //Constante de meses
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const años = ["2022", "2023", "2024"];

  const [steps, setSteps] = useState(0);

  //Paramatros
  const [params, setParams] = useState({
    id: "",
    search: "",
    sala: "",
    estado: "",
    cupon: "",
    date_range: [
      format(new Date("2021/01/01"), "yyyy/MM/dd").toString(),
      format(new Date("2021/01/31"), "yyyy/MM/dd").toString(),
    ],
    provincia: "",
    //static
    url: "api/auth/Reportes/All",
    mes: "",
    año: "",
  });

  //hooks
  const { data, message, isLoading } = useApi(params.url, params, steps);

  //Eventos
  const onChange = (e) => {
    switch (e.target.name) {
      case "selector_mes":
        {
          setParams({
            ...params,
            date_range: convertMesStartEnd(e.target.value, params.año),
            mes: e.target.value,
          });

          setSteps(steps + 1);
        }
        break;
      case "selector_año":
        {
          setParams({
            ...params,
            año: e.target.value,
          });

          setSteps(steps + 1);
        }
        break;
    }
  };

  const reportes = totalReportEvents(
    data ? filterReports(data, ["LIQUIDADO", "ACTIVO"]) : []
  );

  console.log(params);
  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "2500px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1 className="fs-2">Descargar informe por mes</h1>
              <span class="fs-6 text-muted">
                Descarga informes completos por mes.
              </span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="row bg-white p-2" style={{ borderRadius: "20px" }}>
              <div className="col-md-12 mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="selector_año"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>Seleccionar Año</option>
                  {años.map((año) => (
                    <option value={año}>{año}</option>
                  ))}
                </select>
              </div>

              {params.año ? (
                <div className="col-md-12 mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="selector_mes"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected>Seleccionar Mes</option>
                    {meses.map((mes) => (
                      <option value={mes}>{mes}</option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 mb-3">
                {" "}
                <label
                  className="btn btn-outline-dark fw-normal GothamBold me-1  my-4 px-2 py-1 fs-5"
                  title="Descargar tabla"
                >
                  <i class="bi bi-filetype-xls"></i>
                  <ReactHTMLTableToExcel
                    id="buttonDownloadExcel"
                    className="btn btn-custom-amarillo d-none text-white rounded-pill px-4"
                    table="informe_reportes"
                    filename={"reportes_mes_" + params.mes}
                    sheet="pagina 1"
                    buttonText="Exportar tabla"
                  />
                </label>
              </div>

              <div className="col-md-6">
                <div className="p-3 text-center">
                  <span className="fs-4 fw-bold text-muted d-block">
                    Cantidad de entradas vendidas
                  </span>
                  <span className="fs-3 d-block">
                    {reportes.total_cantidad}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="p-3 text-center">
                  <div className="fs-4 fw-bold text-muted d-block">
                    Total de pagos
                  </div>
                  <div className="fs-3 d-block">
                    <span className="fs-6">ARS</span>{" "}
                    {reportes.total_pagos.toFixed(2)}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table" id="informe_reportes">
                    <thead className="border-0">
                      <tr>
                        <th className="border-0 text-nowrap">Id</th>

                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Nombre evento
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Responsable
                        </th>

                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Nombre cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Telefono cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-person"></i> Email cliente
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Codigo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Tipo
                        </th>
                        <th className="border-0 text-nowrap">
                          <i className="bi bi-ticket-perforated"></i> Fecha de
                          entrada
                        </th>

                        <th className="border-0 text-nowrap">Monto Unidad</th>
                        <th className="border-0 text-nowrap">Cant</th>
                        <th className="border-0 text-nowrap">total</th>
                        <th className="border-0 text-nowrap">Descuento</th>
                        <th className="border-0 text-nowrap">
                          Total con descuento
                        </th>
                        <th className="border-0 text-nowrap">
                          Codigo descuento
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {data
                        ? filterReports(data, ["LIQUIDADO", "ACTIVO"]).map(
                            (reporte, index) => (
                              <tr>
                                <td className="border-0">{index + 1}</td>
                                <td className="border-0">
                                  {reporte.eventos.nombre}
                                </td>
                                <td className="border-0">
                                  {reporte.eventos.nombre_sitio}
                                </td>
                                <td className="border-0">
                                  <div className="fw-bold fs-7">
                                    <span className="fw-normal">
                                      {reporte.nombre_cliente}
                                    </span>
                                  </div>
                                </td>
                                <td className="border-0">
                                  <div className="fw-bold fs-7">
                                    <span className="fw-normal">
                                      {reporte.telefono_cliente
                                        ? reporte.telefono_cliente
                                        : reporte.usuarios
                                        ? reporte.usuarios.telefono
                                        : ""}
                                    </span>
                                  </div>
                                </td>
                                <td className="border-0">
                                  {reporte.email_cliente
                                    ? reporte.email_cliente
                                    : reporte.usuarios
                                    ? reporte.usuarios.email
                                    : ""}
                                </td>
                                <td className="border-0">{reporte.codigo}</td>
                                <td className="border-0">
                                  {reporte.entradas
                                    ? reporte.entradas.nombre
                                    : ""}
                                </td>
                                <td className="border-0">{reporte.fecha}</td>
                                <td className="border-0 text-nowrap">
                                  $ {reporte.unidad}
                                </td>
                                <td className="border-0">{reporte.cantidad}</td>
                                <td className="border-0 text-nowrap">
                                  $ {reporte.unidad * reporte.cantidad}
                                </td>
                                <td className="border-0 text-nowrap">
                                  {reporte.descuento ? (
                                    <div>
                                      ${" "}
                                      {((reporte.unidad * reporte.cantidad) /
                                        100) *
                                        reporte.descuento}
                                    </div>
                                  ) : (
                                    "Sin descuento"
                                  )}
                                </td>
                                <td className="border-0">
                                  {reporte.descuento ? (
                                    <div>
                                      ${" "}
                                      {reporte.unidad * reporte.cantidad -
                                        ((reporte.unidad * reporte.cantidad) /
                                          100) *
                                          reporte.descuento}
                                    </div>
                                  ) : (
                                    "Sin descuento"
                                  )}
                                </td>
                                <td className="border-0">
                                  {reporte.descuento ? (
                                    <div>{reporte.codigo_descuento}</div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="border-0">{reporte.status}</td>
                              </tr>
                            )
                          )
                        : ""}

                      <tr className="bg-secondary bg-opacity-50">
                        <td className="border-0" colSpan={10}>
                          Totales
                        </td>
                        <td className="border-0" colSpan={1}>
                          {reportes.total_cantidad}
                        </td>
                        <td className="border-0">{reportes.total_bruto}</td>
                        <td className="border-0">{reportes.total_descuento}</td>
                        <td className="border-0">{reportes.total_pagos}</td>
                        <td className="border-0">{reportes.use_cupon}</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary rounded-pill px-4 me-1"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                cerrar
              </button>

              <ReactHTMLTableToExcel
                id="buttonDownloadExcel"
                className="btn btn-custom-amarillo text-white rounded-pill px-4"
                table="informe_reportes"
                filename={"reportes_mes_" + params.mes}
                sheet="pagina 1"
                buttonText="Exportar tabla"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInformeMes;
