const Donde = ({
  onClick,
  onChange,
  setForm,
  form,
  categorias,
  evento,
  dataSalas,
  dataGeolocation,
  dataLocalidad,
  filter_salas,
  alert,
  stateAut,
  autocomplete,
}) => {
  //console.log(evento);
  //console.log(form);

  return (
    <div className="row">
      <div
        className="col-12  border border-0 shadow-sm bg-white mb-3 p-2"
        style={{ borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 col-12 border-bottom d-md-flex justify-content-between">
            <div className="mb-3 fs-5 py-2 fw-bold ">
              Datos del sitio
              <span className="fs-6 text-muted fw-normal d-block">
                Ingresa los datos del sitio en donde se realizara el evento.
              </span>
            </div>
            <div className="form-check form-switch py-2 me-md-5 fs-6">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                name="stateAut"
                onClick={(event) => onClick(event)}
                checked={
                  stateAut
                  ? true
                  : false
                }
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                Ingreso manual
              </label>
            </div>
          </div>

          {stateAut ? (
            <div className="row">
              <div className="col-md-6 col-12 border-0">
                <ul className="list-group">
                  <li className="list-group-item border-0 justify-content-between">
                    <div className="mb-3 w-100">
                      <label
                        for="formControlNombreSitio"
                        className="form-label fs-6  text-muted"
                      >
                        Nombre del sitio
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill border-custom-naranja"
                        id="formControlNombreSitio"
                        placeholder="Ingresa el nombre de tu sala..."
                        defaultValue={
                          form.nombre_sitio.trim() == ""
                          ? evento
                          ? evento.nombre_sitio 
                          : ""
                          : form.nombre_sitio
                        }
                        name="nombre_sitio"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Email del sitio
                      </label>
                      <input
                        type="email"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        placeholder="Ingresa el email de tu sala..."
                        defaultValue={
                          form.email_sitio.trim() == ""
                          ?evento? evento.email_sitio : ""
                          : form.email_sitio
                        }
                        name="email_sitio"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Telefono del sitio
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        placeholder="Ingresa el telefono de tu sala..."
                        defaultValue={
                          form.telefono_sitio.trim() == ""
                          ?evento? evento.telefono_sitio : ""
                          : form.telefono_sitio
                          }
                        name="telefono_sitio"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Sitio Web(opcional)
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        placeholder="Ingresa el URL del sitio web de tu sala..."
                        defaultValue={
                          form.website_sitio.trim() == ""
                          ?evento? evento.website_sitio : ""
                          : form.website_sitio
                        }
                        name="website_sitio"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-12 border-0">
                <ul className="list-group">
                  <li className="list-group-item border-0">
                    <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Dirección
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        placeholder="Busca tu sala por su nombre..."
                        defaultValue={
                          form.direccion.trim() == ""
                          ?evento? evento.direccion : ""
                          : form.direccion
                        }
                        name="direccion"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="mb-3 w-100">
                    <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Provincia
                      </label>
                      <select
                        class="form-select border-custom-naranja rounded-pill"
                        aria-label="Default select example"
                        defaultValue={evento? evento.provincia : ""}
                        name="provincia"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>Selecciona tu provincia</option>
                        {
                          dataGeolocation
                          ? dataGeolocation.map((provincia) => {
                            if(provincia.stated == "ACTIVO"){
                              return (
                                <option value={provincia.nombre}>{provincia.nombre}</option>
                              )
                            }
                          })
                          : ""
                        }
                      </select>
                    </div>
                    
                    {form.provincia == "" ? (
                        ""
                      ) : (
                        <div className="text-start mb-3">
                          <label htmlFor="" className="form-label fs-6  text-muted">Localidad</label>
                          <input
                            className="form-control border-custom-naranja rounded-pill"
                            type="text"
                            value={form.localidad}
                            name="SearchLocalidad"
                            onChange={(event) => onChange(event)}
                            autocomplete="nope"
                          />
                          <div className="overflow-hidden">
                            {!dataLocalidad.content ? (
                              ""
                            ) : (
                              <ul class="list-group border-0 border-end border-start border-bottom">
                                {dataLocalidad.content.map((municipios) => (
                                  <li class="list-group-item border-0 p-0">
                                    <button
                                      className="btn btn-outline-dark rounded-0 border-0 w-100"
                                      name="Selectlocalidad"
                                      value={municipios.nombre}
                                      onClick={(event) => onChange(event)}
                                    >
                                      {municipios.nombre}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                        {
                          form.direccion.trim() == "" ||
                          form.localidad.trim() == "" ||
                          form.provincia.trim() == "" 
                          ? evento 
                          ? <iframe src={"https://maps.google.com/?q="+encodeURI(evento.direccion+" "+evento.provincia+" "+evento.localidad)+"&z=14&t=m&output=embed"} width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="my-3"></iframe>
                          : ""
                          : <iframe src={"https://maps.google.com/?q="+encodeURI(form.direccion+" "+form.provincia+" "+form.localidad)+"&z=14&t=m&output=embed"} width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="my-3"></iframe>
                        }

                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6 col-12 border-0">
                <ul className="list-group">
                  <li className="list-group-item border-0 d-flex justify-content-between">
                    <div className="mb-1 w-100 position-relative">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Busca tu sala
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        placeholder="Busca tu sala por su nombre..."
                        name="searchSala"
                        value={filter_salas.searchSala}
                        autocomplete="off"
                        onChange={(event) => onChange(event, null)}
                      />
                      {!autocomplete ? (
                        ""
                      ) : (
                        <div
                          className="w-100 border-0  position-absolute overflow-hidden"
                          style={{ top: "73px", left: "0px", zIndex: "2" }}
                        >
                          <ul class="list-group border-0 border-end border-start border-bottom bg-white">
                            {!dataSalas
                              ? ""
                              : dataSalas.map((item) => (
                                  <li class="list-group-item list-group-item-action border-0 p-0">
                                    <button
                                      className="btn btn-outline-dark rounded-0 border-0 w-100"
                                      name="selectSala"
                                      value={item.nombre}
                                      onClick={(event) => onChange(event, item)}
                                    >
                                      {item.nombre}
                                    </button>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>

              {filter_salas.selectSala ? (
                <div className="col-md-6 col-12 border-0 d-flex justify-content-center align-items-center py-3">
                  <div
                    className="btn p-0 border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <img
                      src={
                        "/assets/img/usuarios/" +
                        filter_salas.selectSala.imagen_logo
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {filter_salas.selectSala || evento ? (
                <div className="row">
                  <div className="col-md-6 col-12 border-0">
                    <ul className="list-group">
                      <li className="list-group-item border-0 justify-content-between">
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Nombre del sitio
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border-0 bg-white"
                            id="exampleFormControlInput1"
                            defaultValue={
                              
                              ! filter_salas.selectSala
                              ? evento
                              ? evento.nombre_sitio 
                              : ""
                              : filter_salas.selectSala.nombre
                              
                            }
                            disabled
                          />
                        </div>
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Email del sitio
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border-0 bg-white"
                            id="exampleFormControlInput1"
                            defaultValue={
                              
                              ! filter_salas.selectSala
                              ? evento
                              ? evento.email_sitio 
                              : ""
                              : filter_salas.selectSala.email}
                            disabled
                          />
                        </div>
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Telefono del sitio
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border-0 bg-white"
                            id="exampleFormControlInput1"
                            defaultValue={
                              ! filter_salas.selectSala
                              ? evento
                              ? evento.telefono_sitio 
                              : "" 
                              : filter_salas.selectSala.telefono}
                            disabled
                          />
                        </div>
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Sitio Web
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill border-0 bg-white"
                            id="exampleFormControlInput1"
                            placeholder="Ingresa el URL del sitio web de tu sala..."
                            defaultValue={
                              
                              ! filter_salas.selectSala
                              ? evento
                              ? evento.website_sitio 
                              : ""
                              : filter_salas.selectSala.website}
                            disabled
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-12 border-0">
                    <ul className="list-group">
                      <li className="list-group-item border-0">
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Dirección
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill bg-white border-0"
                            id="exampleFormControlInput1"
                            defaultValue={
                              ! filter_salas.selectSala 
                              ? evento
                              ? evento.direccion 
                              : ""
                              : filter_salas.selectSala.direccion}
                            disabled
                          />
                        </div>
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Provincia
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill bg-white border-0"
                            id="exampleFormControlInput1"
                            defaultValue={
                              ! filter_salas.selectSala 
                              ? evento
                              ? evento.provincia 
                              : "" 
                              : filter_salas.selectSala.provincia}
                            disabled
                          />
                        </div>
                        <div className="mb-1 w-100">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            Localidad
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill bg-white border-0"
                            id="exampleFormControlInput1"
                            defaultValue={
                              ! filter_salas.selectSala 
                              ? evento
                              ? evento.localidad 
                              : ""
                              : filter_salas.selectSala.localidad
                            }
                            disabled
                          />
                        </div>

                      

                        <div className="mb-1 w-100">
                           {
                              ! filter_salas.selectSala 
                              ? evento
                              ? <iframe src={"https://maps.google.com/?q="+encodeURI(evento.direccion+" "+evento.provincia+" "+evento.localidad)+"&z=14&t=m&output=embed"} width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="my-3"></iframe>
                              : ""
                              : <iframe src={"https://maps.google.com/?q="+encodeURI(filter_salas.selectSala.direccion+" "+filter_salas.selectSala.provincia+" "+filter_salas.selectSala.localidad)+"&z=14&t=m&output=embed"} width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="my-3"></iframe>
                            }
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Donde;
