import { createSlice } from "@reduxjs/toolkit";
import clientAxios from "../../helpers/Config";

const initialState = {
  evento: null,
  eventos: null,
};

export const eventoSlice = createSlice({
  name: "eventos",
  initialState: initialState,
  reducers: {
    setEvento: (state, action) => {
      state.evento = action.payload
    }
  },
});

//Async functions
export const fetchEvento = (params) => (dispatch) => {
  clientAxios
    .get(params.url, { params })
    .then((result) => {
      return dispatch(setEvento(result.data.data));
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        return error.message;
      }
    });
};

export const { setEvento } = eventoSlice.actions;

export const eventoState = (state) => state.eventos;

export default eventoSlice.reducer;
