import clientAxios from "./Config";
import Swal from "sweetalert2";
import AlertSuccess from "../Alerts/AlertSucces";

export const Salas = async (
  id,
  search,
  provincia,
  localidad,
  filtro
) => {

  let params = {}

  if(!id.trim() == ""){
    params.id_sala = id;
  }

  if(!search.trim() == ""){
    params.search = search;
  }

  if(!provincia.trim() == ""){
    params.provincia = provincia;
  }

  if(!localidad.trim() == ""){
    params.localidad = localidad;
  }

  if(!filtro.trim() == ""){
    params.filtro = filtro;
  }

  const result  = await clientAxios.get('/api/Salas',{params}).then((result) => {return result.data}).catch((result) => {return null});
  

  
  if(!id.trim() == ""){
    return result;
  }
  
  if(!result){
    return result
  }

  let array = [];
  let psResult = result;

  if (!result.length) {
    Object.keys(result).map((key) => {
      array.push(result[key]);
    });

    psResult = array;
  }

  return psResult;
}

export const AdministradorActualizarSalas = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Salas/Actualizar',
    data: dataForm
  }).then(async (result) => {
    AlertSuccess(result.data.msg);
    return false;
  }).catch((result) => {
    setLoading(false);
    return Swal.fire({
      text: "Ocurrio un error, Vuelva a intentarlo mas tarde.",
      icon: 'error',
      timer: "1500",
      position: "bottom",
      showConfirmButton: false,
      customClass: {
        container: "add-to-cart-alert-container",
        popup: "add-to-cart-alert",
      }
    })
  });

  return response;

}

export const AdministradorSalas = async (
  filter
) => {
  let params = {}

  if(!filter.status.trim() == ""){
    params.status = filter.status;
  }

  if(!filter.search.trim() == ""){
    params.search = filter.search;
  }

  if(!filter.status_eventos.trim() == ""){
    params.status_eventos = filter.status_eventos;
  }
  
  if(!filter.provincia.trim() == ""){
    params.provincia = filter.provincia;
  }
  
  if(!filter.pagination == ""){
    params.pagination = filter.pagination;
  }
  
  const result  = await clientAxios.get(filter.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  if(!result.data){
    return result
  }

  let array = [];

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }

  return result;
}


