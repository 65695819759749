import { useParams } from "react-router-dom";

const ErrorCard = () => {

  const {error} = useParams();
  const parseError = JSON.parse(error);


  //console.log(parseError.error.reason.description);

  return(
    
    <div className="min-vh-100">
    <div className="container p-5 shadow-sm text-center">  
    <div className="text-danger" style={{fontSize: "100px"}}>
    <i class="bi bi-cart-x"></i>
    </div>
    <div className="fs-5">
      <p className="fw-bold fs-4">¡Ocurrió un error con su compra!</p>
      <p>Puede contactarse con la página para revisar posibles errores.</p>
      <p className="text-danger fw-bold">ERROR: {parseError.error.reason.description}</p>
    </div>
  </div>
  </div>

  )
}

export default ErrorCard;