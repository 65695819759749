const Liquidacion = ({
  onClick,
  onChange,
  alert,
  loading,
  setForm,
  form,
  evento,
  rule,
  DatoFiscal,
  facturacion,
}) =>
  /*
 <li className="list-group-item row border-0 d-md-flex justify-content-between p-0 m-0 py-1 px-1 mb-3 shadow-sm">
                <div className="col-md-12 mb-1 text-end">
                  <button
                    className="btn btn-danger d-none fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                    name="deleteLiquidacion"
                    onFocus={(event) => onClick(event)}
                  >
                    {" "}
                    <i className="bi bi-x-lg"></i>{" "}
                  </button>
                </div>
                <div className="col-md-7 mb-2">
                  <div>
                    <span className="text-muted d-block">Titular </span>
                    {facturacion.entidad}
                  </div>
                  <div>
                    <span className="text-muted  d-block">CBU </span>
                    {facturacion.cbu}
                  </div>
                </div>
                <div className="col-md-5 mb-2">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-6 text-nowrap text-muted"
                  >
                    % de liquidación
                  </label>
                  <input
                    type="number"
                    className="form-control rounded-pill m-0 p-0 px-2 border-custom-naranja"
                    id="exampleFormControlInput1"
                    value={facturacion.porcentaje}
                    name="porcentaje"
                    onChange={(event) => onChange(event, 0)}
                  />
                </div>
              </li>
*/

  {
    return (
      <div className="row">
        <div
          className="col-12  border border-0 shadow-sm bg-white mb-3 p-2"
          style={{ borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-md-12 col-12 border-bottom d-flex justify-content-between">
              <div className="mb-3 fs-5 py-2 fw-bold ">
                Datos de Liquidación
                <span className="fs-6 text-muted fw-normal d-block">
                  Ingresa tus datos para la Liquidación del evento. Asegurate de
                  que todos los datos sean correctos.
                </span>
              </div>
            </div>

            <div className="col-md-6 col-12 border-0">
              <ul className="list-group border-0 p-1">
                <li className="list-group-item d-flex justify-content-between border-0">
                  <div className="text-muted">
                    Porcentaje restante de liquidación
                  </div>
                  <div>{form.total}%</div>
                </li>

                <li className="list-group-item d-flex justify-content-between border-0 d-none">
                  <div className="text-muted">Argentores</div>
                    <div className="input-group d-flex justify-content-end">
                       {
                        form.stateEditarLiqui
                        ?  <input
                        type="number"
                        className="rounded-pill border-custom-naranja text-end"
                        style={{width: "70px"}}
                        id="exampleFormControlInput1"
                        defaultValue={
                          evento
                          ? evento.liquidacion.length !== 0
                            ? evento.liquidacion[0].porcentaje_argentores
                            : ""
                          : ""
                        }
                        name="porcentaje_argentores"
                        onChange={(event) => onChange(event)}
                      />
                        : <div>
                          { evento
                          ? evento.liquidacion.length !== 0
                            ? evento.liquidacion[0].porcentaje_argentores+"%"
                            : ""
                          : ""}
                        </div>
                        
                       }
                  </div>
                </li>

                {evento ? (
                  <li className="list-group-item row border-0 d-md-flex align-items-center justify-content-between p-0 m-0 py-2 px-3 mb-3 text-muted">
                    Para editar cada dato de liquidación debe habilitarlo.
                  </li>
                ) : facturacion ? (
                  <li className="list-group-item row border-0 d-md-flex align-items-center justify-content-between p-0 m-0 py-2 px-3 mb-0 text-muted">
                    El primer dato corresponde a los datos del titular
                  </li>
                ) : (
                  <li className="list-group-item row border-0 d-md-flex align-items-center justify-content-center p-0 m-0 py-1 px-1 mb-3 shadow-sm">
                    No existen datos de facturacion del titular
                  </li>
                )}

                {evento ? (
                  evento.liquidacion.map((liqui, index) => (
                    <li className="list-group-item row border-0 d-md-flex justify-content-between p-0 m-0 py-1 px-1 mb-3 shadow-sm">
                      <div className="col-md-12 mb-1 text-end">
                        <button
                          className={
                            form.stateEditarLiqui == liqui.id
                              ? "d-none"
                              : "btn btn-success fs-6 border-0 bg-white text-success rounded-pill text-nowrap fs-7 mx-2"
                          }
                          name="stateEditarLiqui"
                          onFocus={(event) => onClick(event, liqui.id)}
                        >
                          <i class="bi bi-pencil"></i>
                        </button>
                        {form.stateEditarLiqui == liqui.id ? (
                          <button
                            className="btn bg-secondary me-1 btn-custom-naranja rounded-0 text-white fw-normal GothamBold rounded-pill p-0 pt-1 pb-1 px-2 fs-7"
                            onClick={(event) => setForm({...form, stateEditarLiqui: ""})}
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              ""
                            )}
                            Cancelar
                          </button>
                        ) : (
                          ""
                        )}
                        {form.stateEditarLiqui == liqui.id ? (
                          <button
                            className="btn  btn-custom-naranja rounded-0 text-white fw-normal GothamBold rounded-pill p-0 pt-1 pb-1 px-2 fs-7"
                            name="updateLiquidacionEvento"
                            onClick={(event) => onClick(event, liqui, evento)}
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              ""
                            )}
                            Guardar
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          className={
                            index == 0
                              ? "btn btn-danger d-none fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                              : "btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                          }
                          name="deleteLiquidacionEvento"
                          onFocus={(event) => onClick(event, liqui)}
                        >
                          <i className="bi bi-x-lg"></i>{" "}
                        </button>
                      </div>

                      <div className="col-md-7 mb-2">
                        {form.stateEditarLiqui == liqui.id ? (
                          <div className="mb-2">
                            <span className="text-muted d-block fs-8 my-1">
                              Titular{" "}
                            </span>
                            <input
                              type="text"
                              className="border-custom-naranja w-100 rounded-pill"
                              defaultValue={liqui.entidad}
                              name="update_titular"
                              onChange={(event) => onChange(event)}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className="text-muted d-block fs-8 my-1">
                              Titular{" "}
                            </span>
                            {liqui.entidad}
                          </div>
                        )}

                        {form.stateEditarLiqui == liqui.id ? (
                          <div className="mb-2">
                            <span className="text-muted  d-block my-1 fs-8">
                              CBU{" "}
                            </span>
                            <input
                              type="text"
                              className="border-custom-naranja w-100 rounded-pill"
                              defaultValue={liqui.cbu}
                              name="update_cbu"
                              onChange={(event) => onChange(event)}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className="text-muted fs-8  d-block">
                              CBU{" "}
                            </span>
                            {liqui.cbu}
                          </div>
                        )}

                        {form.stateEditarLiqui == liqui.id ? (
                          <div className="mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor=""
                                className="text-muted my-1 fs-8"
                              >
                                Selecciona tu dato fiscal
                              </label>
                              <select
                                className="form-select border-custom-naranja rounded-pill"
                                aria-label=".form-select-lg example"
                                name="update_dato_fiscal"
                                onChange={(event) => onChange(event)}
                              >
                                <option selected>
                                  Selecciona su situación fiscal
                                </option>
                                {DatoFiscal.length == 0
                                  ? ""
                                  : DatoFiscal.map((dato) => (
                                      <option value={dato.descripcion}>
                                        {dato.descripcion}
                                      </option>
                                    ))}
                              </select>
                            </div>

                            <div className="mb-3">
                              <label
                                for="comprobanteAfip"
                                className="form-label w-100 my-1"
                              >
                                <span className="fs-8 text-muted">
                                  Subir comprobante de AFIP
                                </span>
                                <span className="btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill fs-7 my-1 w-100">
                                  Subir archivo PDF o formato imagen JPG JPEG
                                  PNG
                                </span>
                              </label>
                              <input
                                className="form-control d-none"
                                type="file"
                                id="comprobanteAfip"
                                name="update_comprobante_afip"
                                onChange={(event) => onChange(event)}
                              />
                            </div>

                            <div className="mb-3">
                              <label
                                for="comprobanteTitular"
                                className="form-label w-100 "
                              >
                                <span className="fs-8 text-muted">
                                  Subir DNI o instrumento constitutivo
                                </span>
                                <span className="btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill fs-7 my-1 w-100">
                                  Subir archivo PDF o formato imagen JPG JPEG
                                  PNG
                                </span>
                              </label>
                              <input
                                className="form-control d-none"
                                type="file"
                                id="comprobanteTitular"
                                name="update_comprobante_entidad"
                                onChange={(event) => onChange(event)}
                              />
                            </div>

                            <div className="mb-2">
                              <label
                                for="comprobanteCbu"
                                className="form-label w-100 "
                              >
                                <span className="fs-8 text-muted">
                                  Subir comprobante de CBU
                                </span>
                                <span className="btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill fs-7 my-1 w-100">
                                  Subir archivo PDF o formato imagen JPG JPEG
                                  PNG
                                </span>
                              </label>
                              <input
                                className="form-control d-none"
                                type="file"
                                id="comprobanteCbu"
                                name="update_comprobante_cbu"
                                onChange={(event) => onChange(event)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <span className="text-muted fs-8  d-block">
                              Dato fiscal
                            </span>
                            {liqui.dato_fiscal}
                          </div>
                        )}
                      </div>

                      <div className="col-md-5 mb-2">
                        {form.stateEditarLiqui == liqui.id ? (
                          <div>
                            <label
                              for="exampleFormControlInput1"
                              className="form-label fs-8 text-nowrap text-muted"
                            >
                              % de liquidación
                            </label>
                            <input
                              type="number"
                              className="form-control rounded-pill m-0 p-0 px-2 border-custom-naranja"
                              id="exampleFormControlInput1"
                              value={liqui.porcentaje}
                              name="update_porcentaje"
                              onChange={(event) => onChange(event, index)}
                            />
                          </div>
                        ) : (
                          <div>
                            <label
                              for="exampleFormControlInput1"
                              className="form-label fs-8 text-nowrap text-muted"
                            >
                              % de liquidación
                            </label>
                            <input
                              type="number"
                              className="form-control rounded-pill m-0 p-0 px-2 bg-transparent border-0"
                              id="exampleFormControlInput1"
                              value={liqui.porcentaje}
                              disabled
                            />
                          </div>
                        )}
                      </div>

                      {form.stateEditarLiqui == liqui.id ? (
                        ""
                      ) : (
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="text-muted fs-8">
                            <span>Archivos</span>
                          </div>
                          <div className="mb-1 d-flex text-nowrap">
                            <a
                              className="mx-1 fs-7  btn border-custom-naranja w-100 rounded-pill text-custom-naranja p-0 px-2 py-1"
                              target="blank"
                              title="Comprobante CBU"
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                liqui.comprobante_cbu
                              }
                            >
                              <i class="bi bi-file-earmark-pdf"></i> CBU
                            </a>
                            <a
                              className="mx-1 fs-7  btn border-custom-naranja w-100 rounded-pill text-custom-naranja p-0 px-2 py-1"
                              target="blank"
                              title="Comprobante CBU"
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                liqui.comprobante_afip
                              }
                            >
                              <i class="bi bi-file-earmark-pdf"></i> Afip
                            </a>
                            <a
                              className="mx-1 fs-7  btn border-custom-naranja w-100 rounded-pill text-custom-naranja p-0 px-2 py-1"
                              target="blank"
                              title="Comprobante CBU"
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                liqui.comprobante_entidad
                              }
                            >
                              <i class="bi bi-file-earmark-pdf"></i> Titular
                            </a>
                          </div>
                        </div>
                      )}
                    </li>
                  ))
                ) : form.liquidacion.length == 0 ? (
                  <li>
                    El evento no se podra publicar hasta completar datos de
                    liquidación
                  </li>
                ) : (
                  form.liquidacion.map((liqui, index) => (
                    <li className="list-group-item row border-0 d-md-flex justify-content-between p-0 m-0 py-1 px-1 mb-3 shadow-sm">
                      <div className="col-md-12 mb-1 text-end">
                        <button
                          className={
                            index == 0
                              ? "d-none"
                              : "btn btn-danger fs-6 border-0 bg-white text-danger rounded-pill text-nowrap fs-7"
                          }
                          name="deleteLiquidacion"
                          onFocus={(event) => onClick(event, index)}
                        >
                          {" "}
                          <i className="bi bi-x-lg"></i>{" "}
                        </button>
                      </div>
                      <div className="col-md-7 mb-2">
                        <div>
                          <span className="text-muted d-block">Titular </span>
                          {liqui.entidad}
                        </div>
                        <div>
                          <span className="text-muted  d-block">CBU </span>
                          {liqui.cbu}
                        </div>
                      </div>
                      <div className="col-md-5 mb-2">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label fs-6 text-nowrap text-muted"
                        >
                          % de liquidación
                        </label>
                        <input
                          type="number"
                          className="form-control rounded-pill m-0 p-0 px-2 border-custom-naranja"
                          id="exampleFormControlInput1"
                          value={liqui.porcentaje}
                          name="porcentaje"
                          onChange={(event) => onChange(event, index)}
                        />
                      </div>
                    </li>
                  ))
                )}
              </ul>

              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                      disabled={
                        form.stateEditarLiqui == ""
                        ? false
                        : true
                      }
                    >
                      AGREGAR NUEVO CBU
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                          

              <ul className="list-group">
                <li className="list-group-item border-0 ">
                  <div className="mb-3 w-100">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label fs-6  text-muted"
                    >
                      CUIL/CUIT del títular
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-pill border-custom-naranja"
                      id="exampleFormControlInput1"
                      defaultValue={
                        form.titular.trim() == "" ? "" : form.titular
                      }
                      name="titular"
                      onChange={(event) => onChange(event)}
                    />

                    {alert.liquidacion ? (
                      form.titular.trim() == "" ? (
                        <span className="text-danger fs-7">
                          Ingresa el nÚmero de CUIT/CUIT
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <select
                      className="form-select form-select-lg border-custom-naranja rounded-pill"
                      aria-label=".form-select-lg example"
                      name="dato_fiscal"
                      onChange={(event) => onChange(event)}
                    >
                      <option selected>
                        {form.dato_fiscal.trim() == ""
                          ? "Selecciona tu dato fiscal"
                          : form.dato_fiscal}
                      </option>
                      {DatoFiscal.length == 0
                        ? ""
                        : DatoFiscal.map((dato) => (
                            <option value={dato.descripcion}>
                              {dato.descripcion}
                            </option>
                          ))}
                    </select>
                    {alert.liquidacion ? (
                      form.dato_fiscal.trim() == "" ? (
                        <span className="text-danger fs-7">
                          Selecciona un dato
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-3">
                    <label for="comprobanteAfip" className="form-label w-100 ">
                      <span className="fs-6 text-muted">
                        Subir comprobante de AFIP
                      </span>
                      <span
                        className={
                          form.comprobante_afip == "" 
                            ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                            : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                        }
                      >
                        Subir archivo PDF o formato imagen JPG JPEG PNG
                      </span>
                    </label>
                    <input
                      className="form-control d-none"
                      type="file"
                      id="comprobanteAfip"
                      name="comprobante_afip"
                      onChange={(event) => onChange(event)}
                    />
                    {alert.liquidacion ? (
                      form.comprobante_afip == "" ? (
                        <span className="text-danger fs-7">
                          Sube el comprobante de afip
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-3">
                    <label
                      for="comprobanteTitular"
                      className="form-label w-100 "
                    >
                      <span className="fs-6 text-muted">
                        Subir DNI o instrumento constitutivo
                      </span>
                      <span
                        className={
                          form.comprobante_entidad == ""
                            ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                            : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                        }
                      >
                        Subir archivo PDF o formato imagen JPG JPEG PNG
                      </span>
                    </label>
                    <input
                      className="form-control d-none"
                      type="file"
                      id="comprobanteTitular"
                      name="comprobante_entidad"
                      onChange={(event) => onChange(event)}
                    />
                    {alert.liquidacion ? (
                      form.comprobante_entidad == "" ? (
                        <span className="text-danger fs-7">
                          Falta el archivo de DNI o instrumento constitutivo
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-3 w-100">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label fs-6  text-muted"
                    >
                      Número de CBU
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-pill border-custom-naranja"
                      id="exampleFormControlInput1"
                      defaultValue={form.cbu.trim() == "" ? "" : form.cbu}
                      name="cbu"
                      onChange={(event) => onChange(event)}
                    />
                    {alert.liquidacion ? (
                      form.cbu.trim() == "" ? (
                        <span className="text-danger fs-7">
                          Ingresa un número de CBU que no este en tu lista
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-2">
                    <label for="comprobanteCbu" className="form-label w-100">
                      <span className="fs-6 text-muted">
                        Subir comprobante de CBU
                      </span>
                      <span
                        className={
                          form.comprobante_cbu == ""
                            ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                            : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                        }
                      >
                        Subir archivo PDF o formato imagen JPG JPEG PNG
                      </span>
                    </label>
                    <input
                      className="form-control d-none"
                      type="file"
                      id="comprobanteCbu"
                      name="comprobante_cbu"
                      onChange={(event) => onChange(event)}
                    />
                    {alert.liquidacion ? (
                      form.comprobante_cbu == "" ? (
                        <span className="text-danger fs-7">
                          Sube el comprobante de CBU
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  {rule == 3 || rule == 1  && !evento? (
                    <div className="mb-3 d-none">
                      <div className="form-check form-switch mb-3 fs-6">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          name="stateArgentores"
                          onClick={(event) => onClick(event)}
                          checked={form.stateArgentores ? true : false}
                        />

                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          ¿Calcular Argentores?
                        </label>
                      </div>
                      {form.stateArgentores ? (
                        <div>
                          <label
                            for="exampleFormControlInput1"
                            className="form-label fs-6  text-muted"
                          >
                            % Argentores
                          </label>
                          <input
                            type="number"
                            className="form-control rounded-pill border-custom-naranja"
                            id="exampleFormControlInput1"
                            defaultValue={form.porcentaje_argentores}
                            name="porcentaje_argentores"
                            onChange={(event) => onChange(event)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {evento ? (
                    <div className="mb-3 text-center d-none">
                      <button
                        className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                        name="updateLiquidacionEvento"
                        onClick={(event) => onClick(event, evento)}
                      >
                        Modificar argentores
                        <i className="mx-1 bi bi-arrow-right-circle"></i>{" "}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

              {evento ? (
                <div className="mb-3 text-center">
                  <button
                    className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                    name="addLiquidacionEvento"
                    onClick={(event) => onClick(event)}
                    disabled={
                      form.stateEditarLiqui == ""
                      ? false
                      : true
                    }
                  >
                    AGREGAR NUEVO CBU
                  </button>
                </div>
              ) : (
                <div className="mb-3 text-center">
                  <button
                    className="btn w-100 btn-custom-naranja text-white fw-normal GothamBold rounded-pill"
                    name="addLiquidacion"
                    onClick={(event) => onClick(event)}
                  >
                    AGREGAR NUEVO CBU
                  </button>
                </div>
              )}
                </li>
              </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 border-0">
              
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Liquidacion;
