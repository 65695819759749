import ApiRest from "../../../../../hooks/useRest";
import BtnAccept from "../../../../forms/BtnAccept";
import Input from "../../../../forms/Input";
import Select from "../../../../forms/Select";

const Perfil = ({data, setForm, form, setLoading, loading}) => {

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "imagen_logo":{
        setForm({
          ...form,
          [e.target.name]: e.target.files[0],
          [e.target.name + "_url"]: URL.createObjectURL(e.target.files[0]),
        });
      } break;
      case "telefono":
      case "apellido":
      case "nombre":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }break;
    }
  }

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar":{

        let formData = new FormData();
        if(form.nombre.trim() !== "") formData.append("nombre", form.nombre)
        if(form.apellido.trim() !== "") formData.append("apellido", form.apellido)
        if(form.telefono.trim() !== "") formData.append("telefono", form.telefono)
        if(form.imagen_logo !== "") formData.append("imagen_logo", form.imagen_logo)

        
        const response = await ApiRest({
          url: "/api/auth/ActualizarUsuario",
          form: formData,
          setLoading: setLoading,
        });

        return response
      }break;
    }
  }

  const {apellido, nombre, email, telefono, imagen_logo, roles} = data;
  const img = form.imagen_logo_url? form.imagen_logo_url : imagen_logo? "https://antesala.com.ar/assets/img/usuarios/"+imagen_logo : "/assets/img/icon-user.svg"
  return (
    <div className="">
      <h1>Tu espacio Antesala</h1>

      <div className="row p-3 texto-normal">
        <div className="col-md-12 mb-3">
          <div className="w-100 mb-2 p-2 d-flex justify-content-center border overflow-hidden ">
          <img src={img} alt="" className="img-fluid" style={{width: "100px"}} />
          </div>
          <label className="w-100 btn btn-custom-naranja text-white fs-7">
            Editar Imagen
            <input type="file" className="d-none" name="imagen_logo" onChange={(event) => onChange(event)}/>
          </label>
        </div>
        <div className="col-md-4 mb-3">
          <Input defaultValue={nombre} name={"nombre"} label={"Nombre"} onChange={onChange} />
        </div>
        <div className="col-md-4 mb-3">
          <Input defaultValue={apellido} name={"apellido"} label={"Apellido"} onChange={onChange} />
        </div>
        <div className="col-md-4 mb-3">
          <Input defaultValue={telefono} name={"telefono"} label={"Telefono"} onChange={onChange} />
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-control w-100 border">{email}</div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-control w-100 border">{roles[0].descripcion}</div>
        </div>
        <div className="col-md-12 d-flex">
          <div className="me-2">
          <BtnAccept loading={loading} label={"Aplicar cambios"} value={""} name={"guardar"} onClick={onClick} /></div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
