import axios from "axios";
import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const GeolocationApi = async (url) => {
  const result = await axios.get(url);
  return result.data.provincias
} 

export const GeolocationIp = async () => {
  const response = await clientAxios.get("api/Geolocation/Ip");
  return response.data;
}

export const GeolocationStack = async (coordinates) => {
  let params = {
    access_key:  "669af0db378ead20f59a9f336bd0a947",
    query: coordinates
  }

  const result = await axios.get("http://api.positionstack.com/v1/reverse?limit=1",{params});
  return result.data
}

export const Provincias = async () => {
  let params = {}

  const result  = await clientAxios.get("api/Administrador/Provincias",{params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;
}

export const ProvinciasCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Provincias/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)

    //return (window.location.href = "/dashboard/notas/editar/" + result.data.id); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}

export const ProvinciasActualizar = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Provincias/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)

    //return (window.location.href = "/dashboard/notas/editar/" + result.data.id); 
  }).catch((result) => {
    return AlertError(result.response.data.msg, "ERROR");
  });

  return response
}