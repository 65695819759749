import { useContext, useState } from "react";
import Input from "../../../components/custom/forms/Input";
import { contextProvider } from "../../../../context/ContextProvider";
import Alerts from "../../../components/Alerts/Alerts";
import { porcentaje } from "../../../../utils/EventoUtils";
import RestApi from "../../../../helpers/RestApi";

const LiquidacionModal = ({
  form,
  setForm,
  validation,
  modal,
  setModal,
  data,
  url,
  loading,
  setLoading,
}) => {
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { VALIDATION_FORM_EVENTO, VALIDATION_FORM_EVENTO_LIQUIDACION } =
    VALIDATIONs;

  console.log(form);
  console.log(data);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setForm({
            ...form,
            ["liquidacion-id"]: ["", ""],
            ["liquidacion-cuit"]: ["", ""],
            ["liquidacion-fiscal"]: ["", ""],
            ["liquidacion-comprobante_afip"]: ["", ""],
            ["liquidacion-comprobante_cuit"]: ["", ""],
            ["liquidacion-comprobante_cbu"]: ["", ""],
            ["liquidacion-comprobante_afip_url"]: ["", ""],
            ["liquidacion-comprobante_cuit_url"]: ["", ""],
            ["liquidacion-comprobante_cbu_url"]: ["", ""],
            ["liquidacion-cbu"]: ["", ""],
            ["liquidacion-email"]: ["", ""],
            ["liquidacion-porcentaje"]: [0, ""],
          });

          setModal({
            ...modal,
            liquidacion: {
              status: false,
              data: null,
            },
          });
        }
        break;
      case "guardar":
        {
          let input = form;
          input.liquidacion[0][data]["cbu"][0] = form["liquidacion-cbu"][0];
          input.liquidacion[0][data]["comprobante_afip"][0] =
            form["liquidacion-comprobante_afip"][0];
          input.liquidacion[0][data]["comprobante_cbu"][0] =
            form["liquidacion-comprobante_cbu"][0];
          input.liquidacion[0][data]["comprobante_entidad"][0] =
            form["liquidacion-comprobante_cuit"][0];
          input.liquidacion[0][data]["dato_fiscal"][0] =
            form["liquidacion-fiscal"][0];
          input.liquidacion[0][data]["email"][0] = form["liquidacion-email"][0];
          input.liquidacion[0][data]["entidad"][0] =
            form["liquidacion-cuit"][0];
          input.liquidacion[0][data]["porcentaje"][0] =
            form["liquidacion-porcentaje"][0];

          setForm({
            ...form,
            liquidacion: input.liquidacion,
            ['liquidacion-id']: [null, ""],
            ['liquidacion-cuit']: ["", "required"],
            ['liquidacion-fiscal']: ["", "required"],
            ['liquidacion-comprobante_afip']: ["", "required"],
            ['liquidacion-comprobante_cuit']: ["", "required"],
            ['liquidacion-comprobante_cbu']: ["", "required"],
            ['liquidacion-comprobante_afip_url']: ["", "required"],
            ['liquidacion-comprobante_cuit_url']: ["", "required"],
            ['liquidacion-comprobante_cbu_url']: ["", "required"],
            ['liquidacion-cbu']: ["", "required"],
            ['liquidacion-email']: ["", "required"],
            ['liquidacion-porcentaje']: [0, "required"],
          });

          setModal({
            ...modal,
            liquidacion: {
              status: false,
              data: null,
            },
          });

          Alerts({
            msg: "Alert",
            title: "Datos actualizados",
            code: "401",
            type: "success",
          });

          if (form.id[0]) {
            /* UPDATE */
            let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", input.liquidacion[0][data]["id"][0]);
            formData.append("dato_fiscal", input.liquidacion[0][data]["dato_fiscal"][0]);
            formData.append("cbu", input.liquidacion[0][data]["cbu"][0]);
            formData.append("email", input.liquidacion[0][data]["email"][0]);
            formData.append("porcentaje", input.liquidacion[0][data]["porcentaje"][0]);
            
            if(input.liquidacion[0][data]["comprobante_cbu"][0]) formData.append("comprobante_cbu", input.liquidacion[0][data]["comprobante_cbu"][0]);
            
            if(input.liquidacion[0][data]["comprobante_afip"][0]) formData.append("comprobante_afip", input.entradas[0][data]["comprobante_afip"][0]);

            if(input.liquidacion[0][data]["comprobante_entidad"][0]) formData.append("comprobante_entidad", input.entradas[0][data]["comprobante_entidad"][0]);

            const response = await RestApi({
              url: url,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "success",
              });
            } else {
              if (typeof response.data.msg === "string") {
                Alerts({
                  msg: "Alert",
                  title: response.data.msg,
                  code: "200",
                  type: "success",
                });
              }

              Object.keys(response.data.msg).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data.msg[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "liquidacion-porcentaje":
        {
          let input = form;
          let old = input.liquidacion[0][data]["porcentaje"][0];
          input.liquidacion[0][data]["porcentaje"][0] = e.target.value;

          if (porcentaje(input.liquidacion[0], 0) < 0) {
            input.liquidacion[0][data]["porcentaje"][0] = old;

            return Alerts({
              msg: "Alert",
              title: "Porcentaje de ganancia superado",
              code: "401",
              type: "warning",
            });
          }

          input.liquidacion[0][data]["porcentaje"][0] = old;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
      case "liquidacion-comprobante_afip":
      case "liquidacion-comprobante_cuit":
      case "liquidacion-comprobante_cbu":
        {
          let input = form;
          input[e.target.name][0] = e.target.files[0];
          input[e.target.name + "_url"][0] = URL.createObjectURL(
            e.target.files[0]
          );

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
            [e.target.name + "_url"]: input[e.target.name + "_url"],
          });
        }
        break;
      case "liquidacion-fiscal":
      case "liquidacion-email":
      case "liquidacion-cbu":
      case "liquidacion-cuit":
        {
          let input = form;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
    }
  };

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h2>Datos de liquidación</h2>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Número de CBU"}
                      name={"liquidacion-cbu"}
                      type={"text"}
                      value={form["liquidacion-cbu"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"CUIT Titular"}
                      name={"liquidacion-cuit"}
                      type={"text"}
                      value={form["liquidacion-cuit"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Email Titular"}
                      name={"liquidacion-email"}
                      type={"text"}
                      value={form["liquidacion-email"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="form-label text-muted"
                      style={{ fontSize: "14px" }}
                    >
                      Dato fiscal
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="liquidacion-fiscal"
                      onChange={(event) => onChange(event)}
                    >
                      {form["liquidacion-fiscal"][0] ==
                      "Responsable Inscripto" ? (
                        <option selected>Responsable Inscripto</option>
                      ) : (
                        <option value="Responsable Inscripto">
                          Responsable Inscripto
                        </option>
                      )}

                      {form["liquidacion-fiscal"][0] == "IVA Exento" ? (
                        <option selected>IVA Exento</option>
                      ) : (
                        <option value="IVA Exento">IVA Exento</option>
                      )}

                      {form["liquidacion-fiscal"][0] == "Monotributo" ? (
                        <option selected>Monotributo</option>
                      ) : (
                        <option value="Monotributo">Monotributo</option>
                      )}
                    </select>
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"PORCENTAJE DE GANANCIAS"}
                      name={"liquidacion-porcentaje"}
                      type={"number"}
                      value={form["liquidacion-porcentaje"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Comprobante CBU"}
                      name={"liquidacion-comprobante_cbu"}
                      type={"file"}
                      //value={form.comprobante_cbu[0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Comprobante CUIT"}
                      name={"liquidacion-comprobante_cuit"}
                      type={"file"}
                      //value={form.comprobante_cbu[0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Comprobante AFIP"}
                      name={"liquidacion-comprobante_afip"}
                      type={"file"}
                      //value={form.comprobante_cbu[0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close"
                  onFocus={(event) => onClick(event)}
                >
                  Cancelar
                </button>
                <button
                  name="guardar"
                  onClick={(event) => onClick(event)}
                  className="ms-2 btn btn-custom-amarillo text-white"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiquidacionModal;
