import { DateRange } from "react-date-range";
import { format, add, sub } from "date-fns";

const Filtros = ({
  onClick,
  onChange,
  data,
  user,
  ruleUser,
  rule,
  params,
  setParams,
  fecha,
  onFecha,
  provincias,
  loading
}) => {
  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  console.log(rule)
  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <div
          className="border-0 shadow-sm bg-white p-4 text-center"
          style={{ borderRadius: "10px" }}
        >
          <span className="d-block text-muted fw-bold fs-4">
            Eventos publicados
          </span>
          <span className="d-block fw-bold fs-3">{data.total}</span>
        </div>
      </div>

      <div className="col-md-3 mb-2  p-0 py-2 m-0">
        <div className="p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Clasificar eventos.
          </label>
          <select
            class="form-select  fs-9 p-0 px-2 py-1  border-custom-naranja GothamBold  rounded-pill"
            aria-label="Default select example"
            name="clasificar_eventos"
            onChange={(event) => onChange(event)}
          >
            <option value="proximos">Próximos</option>
            <option value="anteriores">Anteriores</option>
          </select>
        </div>
      </div>
     
      <div className="col-md-3 mb-2 p-0 py-2 m-0">
        <div className="mb-3 text-start p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Rango de fecha.
          </label>
          <button className="fs-9 btn_date_range btn btn-custom-naranja text-white fw-normal GothamBold p-0 px-2 py-2 w-100">
            {" "}
            <i className="bi bi-calendar2-event"></i> Filtra por rango de fecha
          </button>
          <div className="filter_date_range d-flex justify-content-center w-100 border-0">
            <DateRange
              onChange={(item) => {
                onFecha([item.selection]);
                setParams({
                  ...params,
                  date_range: [
                    format(item.selection.startDate, "yyyy/MM/dd").toString(),
                    format(item.selection.endDate, "yyyy/MM/dd").toString(),
                  ],
                });
              }}
              moveRangeOnFirstSelection={false}
              locale={locale}
              ranges={fecha}
              className={"fs-7"}
              style={{ fontSize: "12px" }}
              
              color={"#d0552a"}
              showDateDisplay={false}
              rangeColors={["#d0552a", "#d0552a", "#d0552a"]}
            />
          </div>
        </div>
      </div>

      <div className="col-md-3 col-12 mb-2 p-0 py-2 d-flex justify-content-end">
        <div className="mb-3 w-100 text-start p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Filtrar según el estado
          </label>
          <select
            className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
            aria-label="Default select example"
            name="status"
            onChange={(event) => onChange(event)}
          >
            <option value="all">Según Estado: </option>
            <option value="1">Activos</option>
            <option value="3">Pausados</option>
            {rule == 1 ? <option value="5">Eliminados</option> : ""}
            <option value="4">Borradores</option>
          </select>
        </div>
      </div>

      
      {
        rule == 1?
        <div className="col-md-3 mb-2  p-0 py-2 m-0">
        <div className="p-2">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Provincia.
          </label>
          <select
            class="form-select  fs-9 p-0 px-2 py-1  border-custom-naranja GothamBold  rounded-pill"
            aria-label="Default select example"
            name="provincia"
            onChange={(event) => onChange(event)}
          >
            {
              provincias.map((provincia) => (
                <option value={provincia.nombre}>{provincia.nombre}</option>
              ))
            }
          </select>
        </div>
        </div>
        : ""
      }


      <div className="col-md-6 my-2">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-muted fs-9 p-0 px-1 border-0 text-nowrap"
                  >
                    Realiza una busqueda rapida ingresando el nombre del evento o sala.
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
                      placeholder="Busca tu reporte según el nombre del evento o la sala"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      onChange={(event) => onChange(event)}
                    />
                    <button
                      class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
                      id="basic-addon2"
                    >
                      {loading ? (
                        <div
                          class="spinner-grow spinner-grow-sm text-light"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i class="bi bi-search"></i>
                      )}
                    </button>
                  </div>
                </div>

      {rule == 1 ? (
        <div className="col-md-4 col-12 mb-3 m-0 p-0 d-none">
          <label
            for="exampleFormControlInput1"
            className="form-label  text-muted fs-9 p-0 px-2 border-primary"
          >
            Filtrar según la provincia.
          </label>
          <select
            className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
            aria-label="Default select example"
            name="provincia"
            onChange={(event) => onChange(event)}
          >
            <option selected>{params.provincia}</option>
            {provincias
              ? provincias.map((provincia) => (
                  <option value={provincia.nombre}>{provincia.nombre}</option>
                ))
              : ""}
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filtros;
