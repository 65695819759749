import Alerts from "../web/components/Alerts/Alerts";

export const attachEvento = (data, setForm) => {

  let categorias = [];
  data.categorias.map((c) => {
    categorias.push(c.id)
  })

  let sala = {
    id: ["", "required"],
    evento_id: [data.id, "required"],
    nombre: [data.nombre_sitio, "required"],
    razon_social: ["", ""],
    email: [data.email_sitio, "required"],
    telefono: [data.telefono_sitio, "required"],
    direccion: [data.direccion, "required"],
    localidad: [data.localidad, "required"],
    provincia: [data.provincia, "required"],
  }

  let ficha_tecnica = [];

  Object.keys(JSON.parse(data.ficha_tecnica)).map((f) => {
    let ficha = {
      rol: f,
      nombre: JSON.parse(data.ficha_tecnica)[f]
    }

    ficha_tecnica.push(ficha);
  })

  let links = []

  if (data.link) {
    Object.keys(JSON.parse(data.link)).map((f) => {
      let ficha = {
        nombre: f,
        url: JSON.parse(data.link)[f]
      }

      links.push(ficha);
    })
  }

  let entradas = [];

  data.entradas.map((e) => {
    let entrada = {
      id: [e.id, "required"],
      evento_id: [data.id, "required"],
      nombre: [e.nombre, "required"],
      tipo: [e.tipo, "required"],
      precio: [e.precio, "required"],
      habilitacion_hora: [e.habilitacion_hora, "required"],
      habilitacion_fecha: [e.habilitacion_fecha, "required"],
      stock: [e.stock ? e.stock : 0, "required"],
      multiplicador: [e.multiplicador ? e.multiplicador : "", "required"],
      status_id: [e.status == "ACTIVO" ? 1 : 0, "required"],
      created_at: [e.created_at, "required"],
      update_at: [e.updated_at, "required"],
    }

    entradas.push(entrada)
  })

  let liquidaciones = [];

  data.liquidacion.map((l) => {
    let liquidacion = {
      id: [l.id, "required"],
      evento_id: [data.id, "required"],
      porcentaje: [l.porcentaje, "required"],
      created_at: [l.created_At, "required"],
      updated_at: [l.updated_at, "required"],
      entidad: [l.entidad, "required"],
      cbu: [l.cbu, "required"],
      identificador: [null, "required"],

      comprobante_cbu: ["", "required"],
      comprobante_afip: ["", "required"],
      comprobante_entidad: ["", "required"],
      comprobante_cbu_url: [l.comprobante_cbu, "required"],
      comprobante_afip_url: [l.comprobante_afip, "required"],
      comprobante_entidad_url: [l.comprobante_entidad, "required"],

      dato_fiscal: [l.dato_fiscal, "required"],
      email: ["", "required"],
      porcentaje_argentores: [null, "required"],
    };

    liquidaciones.push(liquidacion);
  })

  let result = {
    id: [data.id, "required"],
    usuarios_id: [data.usuarios_id, "required"],
    status_id: [data.status, "required"],
    imagen: ["", "required"],
    imagen_url: ["https://antesala.com.ar/assets/img/eventos/" + data.imagen, ""],
    //url_youtube: ["", ""],
    nombre: [data.nombre, "required"],
    nombre_grupo: [data.nombre_grupo, "required"],
    duracion: [data.duracion, "required"],

    clase: [data.clase, "required"],
    grupo: [data.grupo, "required"],

    descripcion: [data.descripcion, "required"],

    nombre_sitio: ["", ""],
    maps_sitio: ["", ""],
    atp: ["", ""],
    direccion: ["", ""],
    provincia: ["", ""],
    localidad: ["", ""],
    email_sitio: ["", ""],
    telefono_sitio: ["", ""],
    website_sitio: ["", ""],
    historia: ["", ""],
    visitas: [data.visitas, ""],

    categorias: [categorias, "required"],
    created_at: [data.created_at, "required"],
    updated_at: [data.updated_at, "required"],

    fechas: [JSON.parse(data.fechas), "required"],
    ["fecha-day"]: [[
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ], ""],
    ["fecha-hora"]: ["", "required"],
    ["fecha-stock"]: ["", "required"],

    link: [links, ""],
    ['link-nombre']: ["", "required"],
    ['link-url']: ["", "required"],

    ficha_tecnica: [ficha_tecnica, "required"],
    ["ficha_tecnica-nombre"]: ["", "required"],
    ["ficha_tecnica-rol"]: ["", "required"],

    ['sala-search']: ['', ''],
    sala: [sala, 'required'],

    entradas: [entradas, 'required'],
    ["entrada-id"]: [null, ""],
    ["entrada-status"]: [1, ""],
    ["entrada-tipo"]: ["", "required"],
    ["entrada-precio"]: [0, ""],
    ["entrada-cierre"]: ["00:00", ""],
    ["entrada-multiplicador"]: [null, ""],
    ["entrada-habilitacion"]: ["", "required"],
    ["entrada-stock"]: [0, ""],


    liquidacion: [liquidaciones, 'required'],
    ['liquidacion-id']: [null, ""],
    ['liquidacion-cuit']: ["", "required"],
    ['liquidacion-fiscal']: ["", "required"],
    ['liquidacion-comprobante_afip']: ["", "required"],
    ['liquidacion-comprobante_cuit']: ["", "required"],
    ['liquidacion-comprobante_cbu']: ["", "required"],
    ['liquidacion-comprobante_afip_url']: ["", "required"],
    ['liquidacion-comprobante_cuit_url']: ["", "required"],
    ['liquidacion-comprobante_cbu_url']: ["", "required"],
    ['liquidacion-cbu']: ["", "required"],
    ['liquidacion-email']: ["", "required"],
    ['liquidacion-porcentaje']: [0, "required"],

    usuario: [null, '']
  };

  return setForm(result);
}

export const dateRange = (fecha, setForm, form) => {
  //e.preventDefault();

  /*
  if (
    fecha[0].endDate == null ||
    form.time.trim() == "" ||
    form.stock.trim() == ""
  ) {
    setAlert({
      ...alert,
      fecha: true,
    });
    return AlertWarning(
      "Complete todos los campos antes de cargar una fecha",
      "Advertencia"
    );
  }
  */

  const dias_semana = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];

  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  let start = fecha[0].startDate.toLocaleDateString();
  let end = fecha[0].endDate.toLocaleDateString();

  let array = [];

  start = start.split("/");
  end = end.split("/");

  for (let s = parseInt(start[1]); s <= parseInt(end[1]); s++) {
    const mes = s;
    let diasTotal;

    if (s !== parseInt(start[1])) {
      for (let i = 1; i <= parseInt(end[0]); i++) {
        let concatDate = mes + "/" + i + "/" + start[2];
        let dia = new Date(concatDate);
        let fechaFormat =
          dias_semana[dia.getDay()] +
          ", " +
          dia.getDate() +
          " de " +
          meses[dia.getMonth()] +
          " de " +
          dia.getUTCFullYear();

        array.push(fechaFormat);
      }
    } else {
      diasTotal =
        start[1] != end[1]
          ? new Date(parseInt(start[2]), s, 0).getDate()
          : end[0];

      for (let i = parseInt(start[0]); i <= diasTotal; i++) {
        let concatDate = start[1] + "/" + i + "/" + start[2];
        let dia = new Date(concatDate);
        let fechaFormat =
          dias_semana[dia.getDay()] +
          ", " +
          dia.getDate() +
          " de " +
          meses[dia.getMonth()] +
          " de " +
          dia.getUTCFullYear();

        array.push(fechaFormat);
      }
    }
  }

  console.log(form['fecha-day'][0]);
  console.log(array);

  form['fecha-day'][0].map((day) => {
    array = array.filter((date) => !date.includes(day));
  });


  console.log(array);

  if (array.length == 0) {
    Alerts({
      msg: "Alert",
      title: 'El rango de fecha no contiene un día habil',
      code: "401",
      type: "warning",
    });

    return null
  }

  let objFechas;
  let fechas = [];

  if (form.fechas[0].length == 0) {
    fechas = form.fechas[0].length > 0 ? form.fechas[0] : [];
  } else {
    fechas = form.fechas[0];
  }

  let repeatDate = false;

  fechas.map((fecha) => {
    array.map((arr) => {
      if (
        arr + " HORA:" + form['fecha-hora'][0] ==
        fecha.fechas[0] + " HORA:" + fecha.hora
      ) {
        repeatDate = true;
      }
    });
  });

  if (repeatDate) {
    Alerts({
      msg: "Alert",
      title: 'Fecha u Horario duplicado',
      code: "401",
      type: "warning",
    });

    return null
  }

  array.map((item) => {
    objFechas = {
      fechas: [item],
      hora: form['fecha-hora'][0],
      stock: form['fecha-stock'][0],
    };

    fechas.push(objFechas);
  });

  return fechas;

  //setForm({
  //  ...form,
  //  fecha: fechas,
  //});
};

export const porcentaje = (liquidaciones, porcentaje) => {

  let result = 100;

  liquidaciones.map((l) => {
    result = result - l.porcentaje[0];
  });

  result = result - porcentaje;

  return result
}

export const Validate = (form, validation, clave) => {
  let resValidate = {...validation};

  //Validacion de campos
  if (!clave) {
    Object.keys(form).map((campo) => {
      //
      let input_clave = campo.split('-');

      if (input_clave.length == 1) {
        let validate = false;
        let msg = "";
  
        //Require validate
        if (!form[campo][0] && form[campo].indexOf("required") > -1 || form[campo][0] == "" && form[campo].indexOf("required") > -1) {
          validate = true;
          msg = "El campo " + campo + " es requerido.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }
  
        let min = Array.isArray(form[campo])
          ? ""
          : form[campo].filter(
            (item) =>
              item.search("min") !=
              -1
          );
        min = min.length > 0 ? min[0].split(":")[1] : 0
  
        let max = Array.isArray(form[campo])
          ? ""
          : form[campo].filter(
            (item) =>
              item.search("max") !=
              -1
          );
        max = max.length > 0 ? max[0].split(":")[1] : 0
  
        if (min !== 0 && form[campo][0].length < min) {
          validate = true;
          msg = "El campo " + campo + " debe tener mas de " + min + " caracteres.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }
  
        if (max !== 0 && form[campo][0].length > max) {
          validate = true;
          msg = "El campo " + campo + " supera los " + max + " caracteres.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }
  
        //check validation
        if (!validate) {
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
          };
        }
      }
      
    });
  }

  if (clave) {
    Object.keys(form).map((campo) => {
      let input_clave = campo.split('-');

      if (input_clave[0] == clave && input_clave.length > 1) {
        //
        let validate = false;
        let msg = "";

        //Require validate
        if (!form[campo][0] && form[campo].indexOf("required") > -1 || form[campo][0] == "" && form[campo].indexOf("required") > -1) {
          validate = true;
          msg = "El campo " + campo + " es requerido.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }

        let min = Array.isArray(form[campo])
          ? ""
          : form[campo].filter(
            (item) =>
              item.search("min") !=
              -1
          );
        min = min.length > 0 ? min[0].split(":")[1] : 0

        let max = Array.isArray(form[campo])
          ? ""
          : form[campo].filter(
            (item) =>
              item.search("max") !=
              -1
          );
        max = max.length > 0 ? max[0].split(":")[1] : 0

        if (min !== 0 && form[campo][0].length < min) {
          validate = true;
          msg = "El campo " + campo + " debe tener mas de " + min + " caracteres.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }

        if (max !== 0 && form[campo][0].length > max) {
          validate = true;
          msg = "El campo " + campo + " supera los " + max + " caracteres.";
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
            error: {
              status: validate,
              msg: msg,
            },
          };
        }

        //check validation
        if (!validate) {
          resValidate = {
            ...resValidate,
            [campo]: {
              status: validate,
              msg: msg,
            },
          };
        }
      }

    });
  }


  return resValidate;
};