import { createContext, useEffect, useState } from "react";
import { URL } from "../../consts/UrlConst";
import Auth, { Me } from "../../helpers/AccessHelper"

export const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const geolocation = localStorage.getItem("geolocation");

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    provincia: geolocation? geolocation : "",
  });

  const fetch = async () => {
    const response = await Me(filter)
    setUser(response);
    setLoading(false)
  }

  useEffect(() => {
    if(!user){
      fetch();
    }
  },[])
  return (
    <authContext.Provider value={{user}}>
      {!loading && children}
    </authContext.Provider>
  )

}

export default (
  authContext, 
  AuthProvider
  );