import { useContext, useEffect, useState } from "react";
import AlertWarning from "../../../../Alerts/AlertWarning";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import { obtenerMunicipios } from "../../../../helpers/NormGeograficoHelper";
import { AdministradorCrearNotificacion } from "../../../../helpers/NotificacionHelper";
import { AdministradorActualizarUsuarios } from "../../../../helpers/UserHelper";
import ModalEditarStatus from "../../Modals/ModalEditarStatusUsuario";
import ModalHistorial from "../../Modals/ModalHistorial";
import ModalInforme from "../../Modals/ModalInforme";
import ModalNotificacion from "../../Modals/ModalNotificacion";
import ModalPlanilla from "../../Modals/ModalPlanilla";
import TableResponsive from "./Tablas/TableResponsive";


const ControlUsuarios = () => {
  const { user } = useContext(authContext); 
  const { data, rule, ruleUser, auth } = user;

  const context = useContext(CollectionStateContext);
  const { administradorUsuarios, geolocationApi,administradorUsuariosSuscriptos, provincias, state } = context;
  const localStorageProvincias = localStorage.getItem("provincias"); 

  //QUERY STATE

  const [dataUsuarios, setUsuarios] = useState(state.espectatorAdm);
  const [dataProvincias, setProvincias] = useState(
    localStorageProvincias ? JSON.parse(localStorageProvincias) : state.provincias
  );
  const [dataLocalidad, setLocalidad] = useState({
    class: "d-none",
    content: null,
  });

  //TABLE RESPONSIVE CONTENT

  const campos = ["id", "Nombre", "Telefono", "Email", "Telefono", "Direccion"];

  //STATE HELPER

  const [selectElement, setSelect] = useState(null);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    notificacion: false,
  });

  //MODALS

  const [modal_planilla_suscriptores, setModalPlanillaSuscriptos] = useState(false);
  const [modal_planilla_user, setModalPlanillaUser] = useState(false);
  const [modal_edit_content, setModalEdit] = useState(false);
  const [modal_edit_status, setModalEditStatus] = useState(false);
  const [modal_notificacion, setModalNotificacion] = useState(false);
  const [modal_historial, setModalHistorial] = useState(false);
  const [modal_informe, setModalInforme] = useState(false);

  // FORM

  const [form, setForm] = useState({
    id: "",
    nombre: "",
    apellido: "",
    telefono: "",
    imagen_logo: "",
    descripcion: "",
    direccion: "",
    provincia: "",
    localidad: "",
    website: "",
    usuario_estados_id: "",
  });

  const [formNotification, setFormNotification] = useState({
    nombre_notificacion: "",
    descripcion_notificacion: "",
    tipo_notificacion: "",
    fecha_expiracion: "",
  }); 

  //FILTER

  const [filter_usuarios, setFilterUsuarios] = useState({
    search: "",
    status: "",
    provincia: "",
    url: "api/auth/Administrador/Espectadores",
    pagination: 30
  })

  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  //BUTTON EVENTS

  const onChange = (e, value) => {
    switch (e.target.name) {
      //FILTER PRODUCTORES
      case "f_provincia":
        {
          setFilterUsuarios({
            ...filter_usuarios,
            provincia: e.target.value,
          });
        }
        break;
      case "status":{
        setFilterUsuarios({
          ...filter_usuarios,
          [e.target.name]: e.target.value
        })
      }
      break;
      case "search":
        {
          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setFilterUsuarios({
                ...filter_usuarios,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3 && !loading) {
              setLoading(true);

              let expect = setTimeout(() => {
                setFilterUsuarios({
                  ...filter_usuarios,
                  [e.target.name]: e.target.value,
                });

                setLoading(false);
              }, 2000);
            }
          }
        }
        break;
      //  FORM PRODUCTORES
      case "Selectlocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });

          setLocalidad({
            class: "d-none",
            content: null,
          });
        }
        break;
      case "searchLocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });

          const axios = async () => {
            let result = await obtenerMunicipios(
              form.provincia,
              e.target.value
            );
            setLocalidad({
              class: "form-control",
              content: result,
            });
          };

          setTimeout(axios(), 5000);
        }
        break;
      case "website":
      case "provincia":
      case "direccion":
      case "telefono":
      case "usuario_estados_id":
      case "nombre":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      // FORM NOTIFICATION
      case "tipo_notificacion":
      case "descripcion_notificacion":
      case "fecha_expiracion":
      case "nombre_notificacion":
        {
          setFormNotification({
            ...formNotification,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "planilla_suscriptos":{
        setModalPlanillaSuscriptos(true);
      }
      break;
      case "planilla":
      {
        setModalPlanillaUser(true);
      }
      break;
      case "select_edit":
        {
          setSelect(value);
          setModalEdit(true);
        }
        break;
      case "select_status":
        {
          let formData = new FormData();
      
          formData.append("id_user", value.content.id);
          formData.append("usuario_estados_id", value.status);

          const response = await AdministradorActualizarUsuarios(
            formData,
            setLoading
          );
          setSteps(steps + 1);
        }
        break;
      case "notificacion":
        {
          setSelect(value);
          setModalNotificacion(true);
        }
        break;
      case "historial":
        {
          setSelect(value);
          setModalHistorial(true);
        }
        break;
      case "select_informe":
        { 
          setSelect(value);
          setModalInforme(true);
        }
        break;
      case "close_modal":
        {
          setModalEditStatus(false);
          setModalEdit(false);
          setModalNotificacion(false);
          setModalHistorial(false);
          setModalInforme(false);
          setModalPlanillaUser(false);
          setModalPlanillaSuscriptos(false);
        }
        break;
    }
  };

  const onSave = async (e, data) => {
    setSelect(data);

    let formData = new FormData();

    formData.append("id_user", data.id);

    if (!form.nombre.trim() == "") {
      formData.append("nombre", form.nombre);
    }
    if (!form.telefono.trim() == "") {
      formData.append("telefono", form.telefono);
    }
    if (!form.descripcion.trim() == "") {
      formData.append("descripcion", form.descripcion);
    }
    if (!form.direccion.trim() == "") {
      formData.append("nombre", form.direccion);
    }
    if (!form.provincia.trim() == "") {
      formData.append("provincia", form.provincia);
    }
    if (!form.localidad.trim() == "") {
      formData.append("localidad", form.localidad);
    }
    if (!form.usuario_estados_id.trim() == "") {
      formData.append("usuario_estados_id", form.usuario_estados_id);
    }

    const response = await AdministradorActualizarUsuarios(
      formData,
      setLoading
    );

    setSteps(steps + 1);
    setModalEditStatus(false);
  };

  const onSend = async (e) => {
    let dataForm = new FormData();

    if (
      formNotification.fecha_expiracion.trim() == "" ||
      formNotification.nombre_notificacion.trim() == "" ||
      formNotification.descripcion_notificacion.trim() == "" ||
      formNotification.tipo_notificacion.trim() == ""
    ) {
      setAlert({
        ...alert,
        notificacion: true,
      });
      return AlertWarning("Todos los campos son obligatorios", "Advertencia");
    }

    dataForm.append("id_user_destinatario", selectElement.usuarios.id);
    dataForm.append("nombre", formNotification.nombre_notificacion);
    dataForm.append("descripcion", formNotification.descripcion_notificacion);
    dataForm.append("tipo", formNotification.tipo_notificacion);
    dataForm.append("fecha_expiracion", formNotification.fecha_expiracion);

    const response = AdministradorCrearNotificacion(dataForm, setLoading);

    setModalNotificacion(false);
  };

  //PAGINATION

  const nextPage = async () => {
    setFilterUsuarios({
      ...filter_usuarios,
      url: dataUsuarios.next_page_url,
    });
  };

  const prevPage = async () => {
    setFilterUsuarios({
      ...filter_usuarios,
      url: dataUsuarios.prev_page_url,
    });
  };

  const links = async (e, value) => {
    e.preventDefault();
    setFilterUsuarios({
      ...filter_usuarios,
      url: value,
    });
  };

  useEffect(() => {
    administradorUsuarios(setUsuarios, filter_usuarios);
  }, [filter_usuarios, steps]);

  
  useEffect(() => {
    if (!dataProvincias) {
      provincias(setProvincias);
    }
  }, [dataProvincias]);
  return (
    <div className="row container-md me-0 ps-0 p-0 m-0 mx-md-auto texto-normal">
      <div className="col-md-12 col-12 d-flex justify-content-between m-0 p-0">
        <div className="fs-5"></div>
        <button
          className="btn btn-custom-naranja text-white fw-normal GothamBold me-0 mx-1 my-4 px-2 py-2 fs-10"
          disabled
        >
          <i className="bi bi-plus-lg"></i> Agregar Usuario
        </button>
      </div>
      <div className="col-md-6 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label text-muted fs-9 p-0 px-1 border-primary"
        >
          Realiza una busqueda rapida ingresando el email o nombre del
          productor.
        </label>

        <div class="input-group mb-3">
        <input
          type="text"
          className=" border-custom-naranja rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
          id="exampleFormControlInput1"
          placeholder="name@example.com"
          name="search"
          onChange={(event) => onChange(event)}
        />
        <button
          class="input-group-text btn-custom-naranja px-2 p-0 border-custom-naranja text-white rounded-pill rounded-start"
          id="basic-addon2"
        >
          {loading ? (
            <div class="spinner-grow spinner-grow-sm text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <i class="bi bi-search"></i>
          )}
        </button>
        </div>

      </div>
      <div className="col-md-2 col-12 m-0 p-0"></div>
      <div className="col-md-4 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label  text-muted fs-9 p-0 px-2 border-primary"
        >
          Filtrar según el estado de las salas.
        </label>
        <select
          className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
          aria-label="Default select example"
          name="status"
          onChange={(event) => onChange(event)}
        >
          <option selected>Según Estado: </option>
          <option value="1">Activos</option>
          <option value="3">Pausados</option>
          <option value="4">Eliminador</option>
        </select>
      </div>

      <div className="col-md-3 mb-3  text-center">
          <label
            for="exampleFormControlInput1"
            className=" form-label text-muted fs-9 p-0 px-2 border-0 text-nowrap"
          >
           Exportar planilla de usuarios
          </label>
          <button className="btn btn-outline-success w-100 fs-7 rounded-pill" name="planilla" onClick={(event) => onClick(event)}>
            Descargar planilla de usuarios
          </button>
        </div>

        <div className="col-md-3 mb-3  text-center">
          <label
            for="exampleFormControlInput1"
            className=" form-label text-muted fs-9 p-0 px-2 border-0 text-nowrap"
          >
           Exportar planilla de usuarios
          </label>
          <button className="btn btn-outline-success w-100 fs-7 rounded-pill" name="planilla_suscriptos" onClick={(event) => onClick(event)}>
            Descargar planilla de usuarios suscriptos
          </button>
        </div>

        <div className="col-md-2 col-12 m-0 p-0"></div>
      <div className="col-md-4 col-12 mb-3 m-0 p-0">
        <label
          for="exampleFormControlInput1"
          className="form-label  text-muted fs-9 p-0 px-2 border-primary"
        >
          Filtrar según la provincia.
        </label>
        <select
          className="form-select fs-9 p-0 px-2 py-1 border-custom-naranja rounded-pill"
          aria-label="Default select example"
          name="f_provincia"
          onChange={(event) => onChange(event)}
        >
          <option selected>{filter_usuarios.provincia}</option>
          {
            dataProvincias?
            dataProvincias.map((provincia) => (
              <option value={provincia.nombre}>{provincia.nombre}</option>
            ))
            : ""
          }
        </select>
      </div>

      <div className="col-md-12 col-auto ">

        {
          dataUsuarios
          ? <TableResponsive 
           onClick={onClick}
            campos={campos}
            contents={dataUsuarios}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
          />
          : ""
        }

      

        {modal_edit_status ? (
          <ModalEditarStatus
            onClick={onClick}
            onChange={onChange}
            onSave={onSave}
            loading={loading}
            data={selectElement}
          /> 
        ) : (
          ""
        )}

        {modal_notificacion ? (
          <ModalNotificacion
            onClick={onClick}
            onChange={onChange}
            onSend={onSend}
            loading={loading}
            alert={alert}
            data={selectElement}
            formNotification={formNotification}
          />
        ) : (
          ""
        )}

        {modal_historial ? (
          <ModalHistorial
            onClick={onClick}
            data={selectElement.usuarios.historia}
          />
        ) : (
          ""
        )}

        {modal_informe ? (
          <ModalInforme onClick={onClick} data={selectElement} />
        ) : (
          ""
        )}

        {
          modal_planilla_user ?
          dataUsuarios ?
          <ModalPlanilla 
          administradorUsuarios={administradorUsuarios}
          contents={dataUsuarios}
          onClick={onClick}
          onChange={onClick}
          filter={filter_usuarios}
          />
          : ""
          : ""
        }

        {
          modal_planilla_suscriptores ?
          dataUsuarios ?
          <ModalPlanilla 
          administradorUsuarios={administradorUsuariosSuscriptos}
          contents={null}
          onClick={onClick}
          onChange={onClick}
          filter={null}
          />
          : ""
          : ""
        }
      </div>
    </div>

  )
}

export default ControlUsuarios