import RestApi from "../../../../helpers/RestApi";
import Alerts from "../../../components/Alerts/Alerts";
import Input from "../../../components/custom/forms/Input";

const EntradaModal = ({ form, setForm, validation, modal, setModal, data, url, setLoading, loading }) => {
  console.log(form);
  console.log(data);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setForm({
            ...form,
            ["entrada-status"]: [1, ""],
            ["entrada-tipo"]: ["", ""],
            ["entrada-precio"]: [0, ""],
            ["entrada-cierre"]: ["00:00", ""],
            ["entrada-multiplicador"]: [null, ""],
            ["entrada-habilitacion"]: ["", ""],
            ["entrada-stock"]: [null, ""],
          });

          setModal({
            ...modal,
            entrada: {
              status: false,
              data: null,
            },
          });
        }
        break;
      case "guardar":
        {
          let input = form;
          input.entradas[0][data]["nombre"][0] = input["entrada-tipo"][0];
          input.entradas[0][data]["precio"][0] = input["entrada-precio"][0];
          input.entradas[0][data]["tipo"][0] =
            input["entrada-precio"][0] > 0 ? "VENTA" : "GRATIS";
          input.entradas[0][data]["habilitacion_fecha"][0] =
            input["entrada-habilitacion"][0];
          input.entradas[0][data]["habilitacion_hora"][0] =
            input["entrada-cierre"][0];
          input.entradas[0][data]["multiplicador"][0] =
            input["entrada-multiplicador"][0];
          input.entradas[0][data]["stock"][0] = input["entrada-stock"][0];

          setForm({
            ...form,
            entradas: [input.entradas[0], ""],
            ["entrada-status"]: [1, ""],
            ["entrada-tipo"]: ["", ""],
            ["entrada-precio"]: [0, ""],
            ["entrada-cierre"]: ["00:00", ""],
            ["entrada-multiplicador"]: [null, ""],
            ["entrada-habilitacion"]: ["", ""],
            ["entrada-stock"]: [null, ""],
          });

          setModal({
            ...modal,
            entrada: {
              status: false,
              data: null,
            },
          });

          Alerts({
            msg: "Alert",
            title: "Datos actualizados",
            code: "401",
            type: "success",
          });

          if (form.id[0]) {
            /* UPDATE */
            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('id', input.entradas[0][data]["id"][0]);
            formData.append('nombre', input.entradas[0][data]["nombre"][0]);
            formData.append('precio', input.entradas[0][data]["precio"][0]);
            formData.append('stock', input.entradas[0][data]["stock"][0]);
            formData.append('habilitacion_fecha', input.entradas[0][data]["habilitacion_fecha"][0]);
            formData.append('habilitacion_hora', input.entradas[0][data]["habilitacion_hora"][0]);
            formData.append('multiplicador', input.entradas[0][data]["multiplicador"][0]);

            const response = await RestApi({
              url: url,
              formData: formData,
              method: "post",
              setLoading: setLoading,
            });

            //Error
            if (response.status == 201 || response.status == 200) {
              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "success",
              });
            } else {
              if (typeof response.data.msg === "string") {
                Alerts({
                  msg: "Alert",
                  title: response.data.msg,
                  code: "200",
                  type: "success",
                });
              }

              Object.keys(response.data.msg).map((key) => {
                return Alerts({
                  msg: "Alert",
                  title: response.data.msg[key][0],
                  code: "401",
                  type: "error",
                });
              });
            }
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "entrada-tipo":
      case "entrada-precio":
      case "entrada-habilitacion":
      case "entrada-cierre":
      case "entrada-multiplicador":
      case "entrada-stock":
        {
          let input = form;
          input[e.target.name][0] = e.target.value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });
        }
        break;
    }
  };

  return (
    <div
      class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px!important;" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h2>Edicion de entrada  {form["entrada-id"][0] ? "Nº" + form["entrada-id"][0] : "" }</h2>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-4">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Nombre"}
                      name={"entrada-tipo"}
                      type={"text"}
                      value={form["entrada-tipo"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Precio"}
                      name={"entrada-precio"}
                      type={"number"}
                      value={form["entrada-precio"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>
                  <div className="mb-2">
                    <Input
                      onChange={onChange}
                      label={"Fecha de habilitación"}
                      name={"entrada-habilitacion"}
                      type={"date"}
                      value={form["entrada-habilitacion"][0]}
                      svg={
                        ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                      }
                      validate={validation["liquidacion-cbu"]}
                      disabled={false}
                    />
                  </div>

                  <div className="mb-3">
                    <div className=" d-flex justify-content-between">
                    <div className={form['entrada-stock'][0] > 0 ? "d-none" : "form-check form-switch"}>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onChange={function (event) {
                          if (event.target.checked) {
                            document
                              .getElementById("stock-unico-")
                              .classList.remove("d-none");
                          } else {
                            document
                              .getElementById("stock-unico-")
                              .classList.add("d-none");
                          }
                        }}
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        Habilitar Stock unico
                      </label>
                    </div>
                    <div id="stock-unico-" className={form['entrada-stock'][0] > 0 ? "d-block" : "d-none" }>
                      <Input
                        onChange={onChange}
                        label={"Stock"}
                        name={"entrada-stock"}
                        type={"number"}
                        value={form["entrada-stock"][0]}
                        svg={
                          ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                        }
                        validate={validation["entrada-stock"]}
                        disabled={false}
                      />
                    </div>
                    </div>
                    <div className="text-muted mt-2" style={{fontSize: "10px"}}>Para quitar stock unico de esta entrada coloque el valor en 0</div>
                  </div>

                  <div className="mb-2">
                    <label
                      for="email"
                      className="form-label text-muted"
                      style={{ fontSize: "14px" }}
                    >
                      Hora de cierre
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="entrada-cierre"
                      onChange={(event) => onChange(event)}
                    >
                      {form["entrada-cierre"][0] == "01:00" ? (
                        <option selected>Una hora antes de la función</option>
                      ) : (
                        <option value="01:00">
                          Una hora antes de la función
                        </option>
                      )}
                      {form["entrada-cierre"][0] == "00:00" ? (
                        <option selected>Misma hora de función</option>
                      ) : (
                        <option value="00:00">Misma hora de función</option>
                      )}
                      {form["entrada-cierre"][0] == "23:00" ? (
                        <option selected>Al finalizar el día</option>
                      ) : (
                        <option value="23:00">Al finalizar el día</option>
                      )}
                    </select>
                  </div>

                  <div className="mb-2">
                    <label
                      for="email"
                      className="form-label text-muted"
                      style={{ fontSize: "14px" }}
                    >
                      Multiplicador de entradas
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="entrada-multiplicador"
                      onChange={(event) => onChange(event)}
                    >
                      <option selected></option>
                      {form["entrada-multiplicador"][0] == "2" ? (
                        <option selected>X2</option>
                      ) : (
                        <option value="2">X2</option>
                      )}
                      {form["entrada-multiplicador"][0] == "3" ? (
                        <option selected>X3</option>
                      ) : (
                        <option value="3">X3</option>
                      )}
                      {form["entrada-multiplicador"][0] == "4" ? (
                        <option selected>X4</option>
                      ) : (
                        <option value="4">X4</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close"
                  onFocus={(event) => onClick(event)}
                >
                  Cancelar
                </button>
                <button
                  name="guardar"
                  onClick={(event) => onClick(event)}
                  className="ms-2 btn btn-custom-amarillo text-white"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntradaModal;
