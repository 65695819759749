import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const EventoReportes = async (
  id_evento,
  id_user,
  id_entrada,
  date_range,
  tipo_entrada,
  fecha_entrada,
  nombre_cliente,
  estado,
  url,
  pagination
) => {
  let params = {};

  if (id_evento.trim() !== "") {
    params = { ...params, id_evento: id_evento };
  }

  if (id_user.trim() !== "") {
    params = { ...params, id_user: id_user };
  }

  if (id_entrada.trim() !== "") {
    params = { ...params, id_entrada: id_entrada };
  }

  if (date_range.length !== 0) {
    params = { ...params, date_range: date_range };
  }

  if (tipo_entrada.trim() !== "") {
    params = { ...params, tipo_entrada: tipo_entrada };
  }

  if (fecha_entrada.trim() !== "") {
    params = { ...params, fecha_entrada: fecha_entrada };
  }

  
  if (nombre_cliente.trim() !== "") {
    params = { ...params, nombre_cliente: nombre_cliente };
  }

  if (pagination !== "") {
    params = { ...params, pagination: pagination };
  }
   
  if (estado.trim() !== "") {
    params = { ...params, estado: estado };
  }

  const response = await clientAxios.get(url, {
    params,
  });

  return response.data;
};

export const VentasReportes = async (id) => {
  const params = {
    id_evento: id,
  };

  const response = await clientAxios.get("/api/Reportes/Ventas", { params });

  return response.data;
};

export const UsuarioReportes = async (id) => {
  const params = {
    id_user: id,
  };

  const response = await clientAxios.get("/api/auth/Reportes/Usuario", {
    params,
  });

  return response.data;
};

//REPORT ESPECTADOR

export const UsuarioTickets = async () => {
  const response = await clientAxios.get("/api/auth/Usuario/Reportes");

  return response.data;
};

export const UsuarioTicket = async (
  codigo,
  fecha
) => {

  let params = {}

  if(codigo.trim() !== ""){
    params = {...params, codigo: codigo}
  }

  if(fecha.trim() !== ""){
    params = {...params, fecha: fecha}
  }

  const response = await clientAxios.get("/api/auth/Usuario/Reportes",{params});
  return response.data;
}

//CRUD ADMINISTRADOR

export const AdministradorReportes = async (
  filter_report
) => {
  let params = {};


  if (filter_report.id_evento.trim() !== "") {
    params = { ...params, id_evento: filter_report.id_evento };
  }

  if (filter_report.id_user.trim() !== "") {
    params = { ...params, id_user: filter_report.id_user };
  }

  if (filter_report.id_entrada.trim() !== "") {
    params = { ...params, id_entrada: filter_report.id_entrada };
  }

  if (filter_report.date_range.length !== 0) {
    params = { ...params, date_range: filter_report.date_range };
  }

  if (filter_report.tipo_entrada.trim() !== "") {
    params = { ...params, tipo_entrada: filter_report.tipo_entrada };
  }

  if (filter_report.fecha_entrada.trim() !== "") {
    params = { ...params, fecha_entrada: filter_report.fecha_entrada };
  }


  if (filter_report.cupon.trim() !== "") {
    params = { ...params, cupon: filter_report.cupon };
  }

  if (filter_report.state_descuento.trim() !== "") {
    params = { ...params, state_descuento: filter_report.state_descuento };
  }

  if (filter_report.pagination !== "") {
    params = { ...params, pagination: filter_report.pagination };
  }
  
  if (filter_report.nombre_cliente.trim() !== "") {
    params = { ...params, nombre_cliente: filter_report.nombre_cliente };
  }
   
  if (filter_report.estado.trim() !== "") {
    params = { ...params, estado: filter_report.estado };
  }

  const result = await clientAxios.get(filter_report.url, {
    params,
  }).then((result) => {return result.data}).catch((result) => {return null});
  ;


  let array = [];
  

  if (!result.data.length) {
    Object.keys(result.data).map((key) => {
      array.push(result.data[key]);
    });

    result.data = array;
  }
  

  return result;
};

export const ActualizarReporte = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Reportes/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}

export const CrearReportes = async (dataForm, setLoading) => {};


//PDF files


export const PDFliquidado = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Archivo/Liquidado',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)
    return result.data
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}

export const ReeEmail = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Reportes/reSend',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg);
    setLoading(false)
    return result.data
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;
}