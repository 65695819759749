const TableGroup = ({ 
  rule, 
  eventos, 
  onChange, 
  onClick, 
  nextPage,
  prevPage,
  links,}) => {
  let suma = 0;

  const initialValue = () => {
    suma = 0
  }

  return (
    <div
      className="table-responsive bg-white my-3"
      style={{ borderRadius: "10px" }}
    >
      <table
        className="table table-hover align-middle border-0"
        id="reporteVentas"
      >
        <thead className="fs-10 text-start border-0">
          <tr className="">
            <th
              className="border-0 p-0 px-1 text-nowrap text-center d-none"
              colSpan={3}
            >
              <div className="py-2">
                <label htmlFor="" className="d-block text-muted fw-normal">
                  Crea una carpeta con la información de eventos que quieres
                  liquidar
                </label>
                <button
                  className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0"
                  name="editar_status"
                  onClick={(event) => onClick(event)}
                >
                  <i className="bi bi-three-dots"></i> Modificar Estado
                </button>
              </div>
            </th>
          </tr>

          <tr>
            <th className="border-0">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                name="check_all"
                onChange={(event) => onChange(event)}
              />
            </th>
            <th className="border-0 text-nowrap">Id</th>

            <th className="border-0 text-nowrap">
              {" "}
              <i class="bi bi-stars"></i> Nombre evento{" "}
            </th>

            <th className="border-0 text-nowrap">
              <i class="bi bi-bank"></i> Nombre sitio
            </th>

            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Fecha de función
            </th>

            <th className="border-0 text-nowrap">
              <i className="bi bi-ticket-perforated"></i> Entradas vendidas
            </th>
            <th className="border-0 text-nowrap">Monto Total</th>

            <th className="border-0">CBU</th>
            <th className="border-0"></th>
            <th className="border-0">CUIT</th>
            <th className="border-0"></th>
            <th className="border-0 text-nowrap">% </th>
            <th className="border-0 text-nowrap">Monto a abonar</th>
            <th className="border-0 text-nowrap">Estado de reporte</th>
            <th className="border-0"></th>
          </tr>
        </thead>
        <tbody className="fs-9 border-0">
          {eventos.data.map((evento) => (
            <>
              {
              evento.fechas
              ? JSON.parse(evento.fechas).map((fecha) => (
                <>
                {
                  fecha.fechas.map((dia) => {
                  return (  
                  <tr className="mb-4">
                    <td className="border-bottom">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="check_reporte"
                      />
                    </td>
                    <td className="border-bottom">{evento.id}</td>
                    <td className="border-bottom">{evento.nombre}</td>
                    <td className="border-bottom">{evento.nombre_sitio}</td>
                    <td className="border-bottom">
                      {dia + " HORA: " + fecha.hora}
                    </td>
                    <td className="border-bottom">
                      {initialValue()}
                      {
                        evento.reportes.map((reporte) => {
                        if (
                          dia + " HORA: " + fecha.hora ==
                          reporte.fecha_entrada && reporte.status == "ACTIVO"
                        ) {
                          suma = reporte.cantidad + suma;
                        }
                      })}
                      {suma}
                    </td>
                    <td className="border-bottom text-nowrap">
                      ARS{" "}
                      {initialValue()}
                      {evento.reportes.map((reporte) => {
                        if (
                          dia + " HORA: " + fecha.hora ==
                          reporte.fecha_entrada && reporte.status == "ACTIVO"
                        ) {
                          if((reporte.cantidad*reporte.precio_unitario) == reporte.precio_total){
                            if(reporte.stated_descuento == "ON"){
                            suma = (reporte.cantidad*reporte.precio_unitario) - ((parseInt(reporte.precio_total)/100)*reporte.value_descuento) + suma;
                            } else {
                            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                            }
                            } else {
                            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                            }
                        }
                      })}
                      {suma}
                    </td>
                    <td className="border-bottom">
                    {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <span><p></p></span>
                        : ""
                        : ""
                      }
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <span className="fw-bold">
                            {"CBU" + (index + 1) + ": "}
                          </span>
                          {liqui.cbu}
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom">
                    {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <span><p></p></span>
                        : ""
                        : ""
                      }
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <a
                            href={
                              "https://antesala.com.ar/assets/img/comprobantes/" +
                              liqui.comprobante_cbu
                            }
                            className="  rounded-pill"
                          >
                            <i title="DESCARGAR COMPROBANTE DE CBU" className=" bi bi-file-earmark-pdf"></i>
                          </a>
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom">
                    {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <span>Argentores</span>
                        : ""
                        : ""
                      }
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <span className="fw-bold">
                            {"CUIT" + (index + 1) + ": "}
                          </span>
                          {liqui.entidad}
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom">
                      {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <span><p></p></span>
                        : ""
                        : ""
                      }
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <a
                            href={
                              "https://antesala.com.ar/assets/img/comprobantes/" +
                              liqui.comprobante_entidad
                            }
                            className="  rounded-pill"
                          >
                            <i title="DESCARGAR COMPROBANTE DE TITULAR" className=" bi bi-file-earmark-pdf"></i>
                          </a>
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom">
                    {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <div className="fw-bold">% <span className="">{evento.liquidacion[0].porcentaje_argentores}</span></div>
                        : ""
                        : ""
                      }
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <span className="fw-bold">%</span>
                          {liqui.porcentaje}
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom">
                      {
                        evento.liquidacion.length !== 0
                        ? evento.liquidacion[0].porcentaje_argentores > 0
                        ? <div>
                          {initialValue()}
                        {evento.reportes.map((reporte) => {
                          if (
                            dia + " HORA: " + fecha.hora ==
                            reporte.fecha_entrada
                          ) {
                            if((reporte.cantidad*reporte.precio_unitario) == reporte.precio_total){
                              if(reporte.stated_descuento == "ON"){
                              suma = (reporte.cantidad*reporte.precio_unitario) - ((parseInt(reporte.precio_total)/100)*reporte.value_descuento) + suma;
                              } else {
                              suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                              }
                              } else {
                              suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                              }
                          }
                        })}
                          <div className="text-nowrap">
                            <span className="fw-bold">ARS </span>
                            {(suma / 100) * evento.liquidacion[0].porcentaje_argentores}
                          </div>
                        </div>
                        : ""
                        : ""
                      }
                      {initialValue()}
                      {evento.reportes.map((reporte) => {
                        if (
                          dia + " HORA: " + fecha.hora ==
                          reporte.fecha_entrada
                        ) {
                          if((reporte.cantidad*reporte.precio_unitario) == reporte.precio_total){
                            if(reporte.stated_descuento == "ON"){
                            suma = (reporte.cantidad*reporte.precio_unitario) - ((parseInt(reporte.precio_total)/100)*reporte.value_descuento) + suma;
                            } else {
                            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                            }
                            } else {
                            suma = (reporte.cantidad*reporte.precio_unitario) + suma;
                            }
                        }
                      })}
                      {evento.liquidacion.map((liqui, index) => (
                        <div className="text-nowrap">
                          <span className="fw-bold">ARS </span>
                          {
                          evento.liquidacion[0].porcentaje_argentores > 0
                          ? ((suma - ((suma / 100) * evento.liquidacion[0].porcentaje_argentores)) / 100) * liqui.porcentaje  
                          : (suma / 100) * liqui.porcentaje
                          }
                        </div>
                      ))}
                    </td>
                    <td className="border-bottom p-0">
                    {initialValue()}
                      {evento.reportes.map((reporte) => {
                        if (
                          reporte.status == "ACTIVO" && suma == 0
                        ) {
                          suma = 1;
                          return ( <i
                            title="Activo"
                            className="btn fs-6 text-success bi bi-check-circle text-nowrap"
                          >
                            {" "}
                            PUBLICADO{" "}
                          </i>)
                        } else{
                          if (reporte.status == "LIQUIDADO" && suma == 0) {
                            suma = 1;
                            return ( <i
                              title="Liquidado"
                              class="btn fs-6 text-success bi bi-coin text-nowrap"
                            >
                              {" "}
                              LIQUIDADO{" "}
                            </i>)
                          }
                        }
                      })}
                    </td>
                    <td className="border-0">
                      {initialValue()}
                      {evento.reportes.map((reporte) => {
                        if (
                          reporte.status == "ACTIVO" && suma == 0 || reporte.status == "LIQUIDADO" && suma == 0
                        ) {
                          suma = 1
                          return (<button
                            className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0"
                            name="editar_status"
                            onClick={(event) => onClick(event, evento.reportes)}
                          >
                            <i className="bi bi-three-dots"></i> Modificar Estado
                          </button>)
                        }
                      })}
                    </td>
                    <td className="border-0">
                      <div className="py-2">
                        <button
                          className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0"
                          name="ver_reportes"
                          onClick={(event) => onClick(event, evento.reportes)}
                        >
                          Ver reportes
                        </button>
                      </div>
                    </td>
                  </tr>
                  )})
                }
                </>
              ))
              : ""
              }
            </>
          ))}
        </tbody>
        <tfoot className="mb-0 p-0 border-0">
          <tr>
            <td colspan="14" className="border-0 m-0 px-1 pt-4">
              <div className="d-flex justify-content-end">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      <button
                        style={{ borderRadius: "10px" }}
                        className={
                          eventos
                            ? eventos.current_page == 1
                              ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                              : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                            : "d-none"
                        }
                        onClick={prevPage}
                      >
                        Anterior
                      </button>
                    </li>
                    {eventos
                      ? eventos.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == eventos.current_page + 1 ||
                            item.label == eventos.current_page + 2 ||
                            item.label == eventos.per_page ||
                            item.label == eventos.last_page
                          ) {
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true
                                    ? "page-item bg-naranja me-1 fs-8 text-white"
                                    : "page-item me-1 fs-8 text-dark"
                                }
                              >
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn fs-8 border-0 bg-transparent border-custom-naranja text-white"
                                      : "btn fs-8 border-0 bg-transparent text-custom-naranja border-custom-naranja"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      <button
                        style={{ borderRadius: "10px" }}
                        className={
                          eventos
                            ? eventos.current_page == eventos.last_page
                              ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                              : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                            : "d-none"
                        }
                        onClick={nextPage}
                      >
                        Siguiente
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableGroup;
