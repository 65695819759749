const TableResponsive = ({campos, contents, onClick, onChange, nextPage, links, prevPage}) => {


  return (
    <div
    className="table-responsive bg-white w-100 m-0 p-0"
    style={{ borderRadius: "10px;"}}
  >
    <table className="table table-hover align-middle m-0 p-0">
      <thead className="fs-10 text-start border-0">
        <tr className="border-0">
          {campos.map((campo) => (
            <th className="border-0 text-nowrap">{campo}</th>
          ))}

          <th className="border-0"></th>
          <th className="border-0"></th>
          <th className="border-0"></th>
          <th className="border-0"></th>
          <th className="border-0"></th>
        </tr>
      </thead>
      <tbody className="fs-9 border-0">
        {
          contents
          ? contents.data.map((content, index) => (
            <tr>
              <td className="border-0">{index+1}</td>
              <td className="border-0">{content.nombre}</td>
              <td className="border-0">{content.telefono}</td>
              <td className="border-0">{content.prd_email}</td>
              <td className="border-0">{content.telefono}</td>
              <td className="border-0">{content.direccion+" "+content.provincia+" "+content.localidad}</td>

              <td className="border-0 p-0 px-1 text-nowrap">
                    <button 
                    className="btn btn-success fs-10 rounded-pill m-0"
                    name="select_edit"
                    onFocus={(event) => onClick(event, content)}
                    >
                      <i className="bi bi-pencil"></i> Editar
                    </button>
                  </td>

                  <td className="border-0 p-0 px-1 text-nowrap">
                    <button className="btn btn-warning fs-10 rounded-pill m-0" name="historial"
                    onFocus={(event) => onClick(event, content)}>
                      <i className="bi bi-clock-history"></i> Historial
                    </button>
                  </td>

                  <td className="border-0 p-0 px-1 text-nowrap">
                  <div class="dropdown">
                      <button
                        class="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                      <i className="bi bi-three-dots"></i> Modificar Estado
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <label className="dropdown-item text-success">
                          <i class="bi bi-check-circle-fill me-1"></i>
                            Activar
                            <button 
                            className="d-none"
                            name="select_status"
                            onClick={(event) => onClick(event, {content: content, status: 1})}></button>
                          </label>
                        </li>
                        <li>
                          <label className="dropdown-item text-danger">
                            <i class="bi bi-x-circle-fill me-1"></i>
                            Desactivar
                            <button 
                            className="d-none"
                            name="select_status"
                            onClick={(event) => onClick(event, {content: content, status: 2})}></button>
                          </label>
                        </li> 
                        <li>
                          <label className="dropdown-item text-secondary">
                            <i class="bi bi-slash-circle me-1"></i>
                            Pausar
                            <button 
                            className="d-none"
                            name="select_status"
                            onClick={(event) => onClick(event, {content: content, status: 3})}></button>
                          </label>
                        </li>
                        <li>
                          <label className="dropdown-item text-danger">
                            <i class="bi bi-trash-fill me-1"></i>
                            Eliminar
                            <button 
                            className="d-none"
                            name="select_status"
                            onClick={(event) => onClick(event, {content: content, status: 4})}></button>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </td>

                  <td className="border-0 p-0 px-1 text-nowrap">
                    <div className="dropdown">
                      <button
                        className="btn btn-custom-amarillo px-3 fs-10 rounded-pill text-nowrap m-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Informes...
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#" name="select_informe"
                           onClick={(event) => onClick(event, content)}>
                            <i className=" mx-1 bi bi-file-earmark-arrow-down"></i>
                            Informe de ventas
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item d-none" href="#">
                            <i className=" mx-1 bi bi-ticket-perforated"></i>
                            Historial de Eventos
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>

                  <td className="border-0 p-0">
                    {content.usuario_estados_id == 1 ? (
                      <i className="btn fs-6 text-success bi bi-check-circle"></i>
                    ) : content.usuario_estados_id == 2 ? (
                      <i class="btn fs-6 text-danger bi bi-x-circle"></i>
                    ) : content.usuario_estados_id == 3 ? (
                      <i class="btn fs-6 text-secondary bi bi-slash-circle"></i>
                    ) : (
                      ""
                    )}
                  </td>
            </tr>
          ))
          : ""
        }
      </tbody>
      <tfoot className="border-0">
        <tr>
          <td colspan="11" className="border-0 m-0 px-1 pt-4 ">
          <div className="w-100 d-flex justify-content-end">
          <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
          <ul class="pagination bg-transparent">
            <li class="page-item">
              <button
                style={{ borderRadius: "10px" }}
                className={
                  contents
                    ? contents.current_page == 1
                      ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                      : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                    : "d-none"
                }
                onClick={prevPage}
              >
                Anterior
              </button>
            </li>
            {contents
              ? contents.links.map((item, index) => {
                  if (
                    (item.label !== "&laquo; Previous" &&
                      item.label !== "Next &raquo;" &&
                      item.active == true) ||
                    item.label == contents.current_page + 1 ||
                    item.label == contents.current_page + 2 ||
                    item.label == contents.per_page ||
                    item.label == contents.last_page
                  ) {
                    return (
                      <li
                        style={{ borderRadius: "5px" }}
                        className={
                          item.active == true
                            ? "page-item bg-naranja me-1 fs-8 text-white"
                            : "page-item me-1 fs-8 text-dark"
                        }
                      >
                        <button
                          style={{ borderRadius: "5px" }}
                          className={
                            item.active == true
                              ? "btn fs-8 border-0 bg-transparent border-custom-naranja text-white"
                              : "btn fs-8 border-0 bg-transparent text-custom-naranja border-custom-naranja"
                          }
                          onClick={(event) => links(event, item.url)}
                        >
                          {item.label}
                        </button>
                      </li>
                    );
                  }
                })
              : ""}
            <li class="page-item">
              <button
                style={{ borderRadius: "10px" }}
                className={
                  contents
                    ? contents.current_page == contents.last_page
                      ? "text-custom-naranja btn border-custom-naranja me-1 fs-8 disabled"
                      : "text-custom-naranja btn border-custom-naranja me-1 fs-8"
                    : "d-none"
                }
                onClick={nextPage}
              >
                Siguiente
              </button>
            </li>
          </ul>
        </nav>
          </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  )
}

export default TableResponsive