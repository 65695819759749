import { format } from "date-fns/esm";

//Helpers
export const convertMonth = (month) => {
  let numberMonth;

  switch (month) {
    case "enero":
      numberMonth = "01";
      break;
    case "febrero":
      numberMonth = "02";
      break;
    case "marzo":
      numberMonth = "03";
      break;
    case "abril":
      numberMonth = "04";
      break;
    case "mayo":
      numberMonth = "05";
      break;
    case "junio":
      numberMonth = "06";
      break;
    case "julio":
      numberMonth = "07";
      break;
    case "agosto":
      numberMonth = "08";
      break;
    case "septiembre":
      numberMonth = "09";
      break;
    case "octubre":
      numberMonth = 10;
      break;
    case "noviembre":
      numberMonth = 11;
      break;
    case "diciembre":
      numberMonth = 12;
      break;
  }

  return numberMonth;
};

//validaciones de prePago

//habilitacion de entradas
export const habilitacion = (entradas) => {
  const hoy = new Date();
  let result = [];

  entradas.map((entrada) => {
    //habilitacion
    entrada.habilitado = false;
    let habilitacion = new Date(entrada.habilitacion_fecha);
    //
    if (habilitacion < hoy) {
      entrada.habilitado = true;
    }

    result.push(entrada);
  });

  return result;
};

//validacion habilitacion de fechas
export const habilitacion_fecha = (fechas, hora) => {
  const hoy = new Date();
  let hour = hoy.getHours();
  let minute = hoy.getMinutes();

  let d = format(hoy, "yyy-MM-dd").toString();
  let h = hora ? hora.split(":") : "1:0".split(":");
  //Hora y minuto de entrada
  h = h[0];
  let m = h[1];

  let result = [];

  fechas.map((fecha) => {
    let funcion = fecha.fechas[0].split(" ");
    let funcion_hora = parseInt(fecha.hora.split(":")[0]);
    let funcion_minuto = parseInt(fecha.hora.split(":")[1]);

    funcion = format(
      new Date(funcion[5] + "/" + convertMonth(funcion[3]) + "/" + funcion[1]),
      "yyy-MM-dd"
    ).toString();


    if (funcion >= d) {
      switch (h) {
        case "00":
          {
            if (funcion > d) {
              result.push(fecha);
            } else {
              if (funcion_hora > hour) {
                result.push(fecha);
              }
            }
          }
          break;
        case "01":
          {
            if (funcion > d) {
              result.push(fecha);
            } else {
              //"Cierre hasta una hora antes de la función;
              if (funcion_hora - 1 > hour) {
                result.push(fecha);
              } else {
                if (funcion_hora - 1 == hour && funcion_minuto > minute) {
                  result.push(fecha);
                }
              }
            }
          }
          break;
        case "23":
          {
            //Cierre hasta las 23:59 del día de la función;
            result.push(fecha);
          }
          break;
        default:
          {
            if (funcion > d) {
              result.push(fecha);
            } else {
              //"Cierre hasta una hora antes de la función;
              if (funcion_hora - 1 > hour) {
                result.push(fecha);
              } else {
                if (funcion_hora - 1 == hour && funcion_minuto > minute) {
                  result.push(fecha);
                }
              }
            }
          }
          break;
      }
    }
  });

  return result;
};

//
export const Total = (entradas, tasa) => {
  //console.log(entradas);
  let subTotal = 0;
  let total = 0;
  let serv = 0;
  let type = "";

  Object.keys(entradas).map((key) => {

    subTotal = entradas[key].total + subTotal;
    serv = subTotal > 500 ? (subTotal / 100) * parseInt(tasa) :  75;
    total = serv + subTotal;
    type = subTotal > 500 ? tasa+"%" : "ARS 75";

    
  });

  return {
    type: type,
    serv: serv,
    subTotal: subTotal,
    total: total,
  };
};

//reportes
export const TotalReport = (fecha, stock, reportes) => {
  //console.log(reportes);
  //console.log(fecha);
  //console.log(stock);

  let result = 0;
  let total = 0;

  reportes.map((reporte) => {
    if (reporte.fecha == fecha) {
      let multiplicador = reporte.multiplicador? parseInt(reporte.multiplicador) : 1
      result = (reporte.cantidad*multiplicador) + result;
    }
  });

  return {
    stock: stock - result,
    total: total,
  };
};

//Hora de finalizacion
export const habilitacion_hora = (hora) => {
  let time = new Date().getTime();
  let h = hora? hora.split(":") : "1:0".split(":");
  //Hora y minuto de entrada
  h = h[0];
  let m = h[1];

  let text = "";

  switch (h) {
    case "00":
      {
        text = "Cierre hasta la hora de la función";
      }
      break;
    case "01":
      {
        text = "Cierre hasta una hora antes de la función";
      }
      break;
    case "23":
      {
        text = "Cierre hasta las 23:59 del día de la función";
      }
      break;
    default:
      {
        text = "Cierre hasta una hora antes de la función";
      }
      break;
  }

  return text;
};
