import { useState } from "react";
import AlertWarning from "../../../../../../Alerts/AlertWarning";
import ApiRest from "../../../../../../hooks/useRest";
import CardGaleria from "../../cards/CardGaleria"
import ModalAddGaleria from "../../modals/ModalAddGaleria";

const Galeria = ({
  provincias,
  data,
  steps,
  setSteps
}) => {
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [modalMultimedia, setModalMultimedia] = useState(false);

  //formularios
  const [form, setForm] = useState({
    nombre: "",
    link: "",
    url: "",
    archivo: "",
    archivo_url: "",
    provincia: "",
    vigencia: "",
    imagen: "",
    imagen_url: ""
  })
  //Eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      //modal
      case "modal_multimedia":{
        setModalMultimedia(true);
        setSelect(value);
      }break;
      case "close_modal":{
        setModalMultimedia(false);
        setSelect(null);
      }break;
      case "change_status":{
        let data = value.data;
        let status = value.status;

        let formData = new FormData();

        formData.append('id', data.id);
        formData.append('stated', status);

        const response = ApiRest({
          url: "/api/auth/Administrador/Galeria/Actualizar",
          form: formData,
          setLoading: setLoading,
        });

        setSteps(steps+1);
      }break;
      //api
      case "create":{
        if(
          form.posicion.trim() == "" ||
          form.provincia.trim() == "" ||
          form.nombre.trim() == "" ||
          form.imagen == "" ||
          form.estado.trim() == ""
        ){
          return AlertWarning(
            "Todos los campos deben ser completados",
            "Advertencia"
          );
        }


        //recreate form
        let formData = new FormData();
        //Required
        formData.append('nombre', form.nombre);
        formData.append('imagen', form.imagen);
        formData.append('provincia', form.provincia);
        formData.append('posicion', form.posicion);
        formData.append('stated', form.estado);

         //empty create form
         Object.keys(form).map((item) => {
          if(
            form[item] !== "" &&
            item == "vigencia"){
              formData.append(String(item), JSON.stringify(form[item]));
          }

          if(
            form[item] !== "" &&
            item !== "nombre" &&
            item !== "imagen" &&
            item !== "provincia" &&
            item !== "posicion" &&
            item !== "stated" &&
            item !== 'vigencia'
          ){
            formData.append(String(item), form[item]);
          }
        })

        
        //send api rest
       
        const response = await ApiRest({
          url: "/api/auth/Administrador/Galeria/Crear",
          form: formData,
          setLoading: setLoading,
        });

        //Actualizar datos
        setModalMultimedia(false);
        setSteps(steps + 1);
      }break;
      case "update":{
        console.log(form);

        let formData = new FormData();
        formData.append("id", select.id);
        
        //empty create form
        Object.keys(form).map((item) => {
          if(
            form[item] !== "" &&
            item == "vigencia"
            ){
              formData.append(String(item), JSON.stringify(form[item]));
          }

          if(
            form[item] !== "" &&
            item !== "vigencia"
          ){
            formData.append(String(item), form[item]);
          }
        })

        const response = ApiRest({
          url: "/api/auth/Administrador/Galeria/Actualizar",
          form: formData,
          setLoading: setLoading,
        });

        setSteps(steps+1);
      }break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      
    }
  }

  return (
      <div className="col-md-12 mb-4">
             <div className="mb-3 fs-4 py-2 fw-bold border-0 d-flex justify-content-between">
                <div>
                  Galeria multimedia
               <span className="d-block fs-8 fw-normal text-muted">
                 Sube y posiciona tus imagenes.
               </span>
                </div>
                <div>
                  <label className="btn btn-custom-naranja btn-outline-dark text-white border-0">
                    <i class="bi bi-plus-lg"></i>
                    Agregar archivo
                    <button className="d-none"
                    name="modal_multimedia"
                    onClick={(event) => onClick(event, null)}></button>
                  </label>
                </div>
             </div>


             <div className="row">
               { 
                data.map((imagen) => (
                     <CardGaleria 
                     onChange={onChange}
                     onClick={onClick}
                     data={imagen}
                     steps={steps}
                     setSteps={setSteps}
                     />
                   ))
                }
             </div>

             {
              modalMultimedia
              ? <ModalAddGaleria 
                data={select}
                form={form}
                setForm={setForm}
                provincias={provincias}
                onClick={onClick}
                alert={alert}
                loading={loading}
              />
              : ""
             }
           </div>
  )
}

export default Galeria