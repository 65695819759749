import { useEffect, useState } from "react";
import { ConcatDate } from "../../../App/Functions/ConcatDate";
import { Decidir } from "../../../helpers/DecidirHelper";

const ModalInfoDecidir = ({onClick, onChange, data}) => {
  const [decidir, setDecidir] = useState(null);


  useEffect(() => {
    const axios = async () => {
      const result = await Decidir(data.api_establecimiento_id);
      setDecidir(result);
    }
    axios()
  },[data])

  return (
    <div
    class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style={{ zIndex: 104 }}
  >
    <div
      class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
      style={{ maxWidth: "900px" }}
    >
      <div className="modal-content bg-light">
        <div class="title m-0 bg-white p-4 d-flex justify-content-between">
          <div>
            <h1>Medios de pago Decidir</h1>
            <span class="fs-6 text-muted">
              Verificación del estado en medio de pagos decidir.{" "}
            </span>
          </div>
          <div>
            <button
              class="border-0 bg-transparent"
              name="close"
              onFocus={(event) => onClick(event, null)}
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
        <div class="body p-4">

        <div class="row">
            <div class="col-md-12 bg-white py-2 mb-3">
              <label for="" class="text-muted"
                >Ingresa el codigo generado de tu reporte para revisar su
                estado.</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="codigo de ejemplo..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  defaultValue={data.api_establecimiento_id}
                  name="search_decidir" onChange={(event) => onChange(event)}
                />
                <button class="input-group-text btn btn-custom-naranja text-white" id="basic-addon2"
                 name="search_decidir" onClick={(event) => onClick(event)} ><i class="bi bi-search"></i></button
                >
              </div>
              {
                decidir
                ? decidir.results.map((dato) => (
              <ul class="list-group">
              <li class="list-group-item border-0">
                <div class="text-muted pb-2">
                  Id de transacción <span class="fw-bold"> {dato.id}</span>
                </div>
                <div class="text-muted pb-2">
                  Tipo de tarjeta <span class="fw-bold"> {dato.card_brand}</span>
                </div>
                <div class="text-muted pb-2">
                  Monto <span class="fw-bold"> {dato.amount}</span>
                </div>
                <div class="text-muted pb-2">
                  Nombre del cliente
                  <span class="fw-bold"> {dato.customer.id}</span>
                </div>
                <div class="text-muted pb-2">
                  Email del cliente
                  <span class="fw-bold"> {dato.customer.email}</span>
                </div>
                <div class="text-muted pb-2">
                  Fecha de importe <span class="fw-bold"> {dato.date}</span>
                </div>
                <div class="text-muted pb-2">
                  Estado del pago <span class="fw-bold"> {dato.status}</span>
                </div>
                <div class="text-muted pb-2">
                  Detalles <span class="fw-bold">{dato.status_details.error? dato.status_details.error.reason.description : "No existen errores" }</span>
                </div>
              </li>
            </ul>
                ))
                : ""
              }
            </div>
          </div>
        
        </div>
        <div class="footer bg-white p-4">
          <div class="d-flex justify-content-between">
            <div></div>
            <button
              class="btn btn-secondary rounded-pill px-4"
              name="close"
              onFocus={(event) => onClick(event, null)}
            >
              cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ModalInfoDecidir