import AlertError from "../Alerts/AlertError";
import AlertSuccess from "../Alerts/AlertSucces";
import clientAxios from "./Config";

export const Imagenes = async (filter_imagenes) => {
  let params = {}

  const result  = await clientAxios.get(filter_imagenes.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;
}

export const AdministradorImagenes = async (filter_imagenes) => {
  let params = {}

  const result  = await clientAxios.get(filter_imagenes.url,{params}).then((result) => {return result.data}).catch((result) => {return null});

  return result;
}

export const AdministradorImagenCrear = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Galeria/Crear',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;

}

export const AdministradorImagenesActualizar = async (dataForm, setLoading) => {
  setLoading(true);

  const response = await clientAxios({
    method: 'post',
    url: '/api/auth/Administrador/Galeria/Actualizar',
    data: dataForm,
  }).then((result) => {
    AlertSuccess(result.data.msg); 
    setLoading(false)
    return result
  }).catch((result) => {
    setLoading(false)
    return AlertError("Error en el servidor","Error");
  });

  return response;

}