import { useNavigate, useParams } from "react-router-dom"
import Notas from "../../page/notas/Notas"
import Nota from "../../page/notas/Nota"
import { useState } from "react";
import { useContext } from "react";
import { contextProvider } from "../../../context/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { categoriaState } from "../../../../slices/categorias/Index";
import { fetchCategoriaNotas } from "../../models/categorias/Index";
import { useEffect } from "react";
import { Validate } from "../../../utils/EventoUtils";
import Alerts from "../../components/Alerts/Alerts";
import { fetchProvincias, provinciaState } from "../../models/provincias/Index";
import RestApi, { GetApi } from "../../../helpers/RestApi";
import { attachNota } from "../../../utils/NotaUtils";
import SpinnerScreen from "../../components/loads/SpinnerScreen";

const NotaController = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { URL_CATEGORIAS_INDEX, URL_PROVINCIA_INDEX, URL_NOTA_STORE, URL_NOTA_UPDATE, URL_NOTA_SHOW } = URLs;
  const { FORM_NOTA } = FORMs;
  const { VALIDATION_FORM_NOTA } = VALIDATIONs;

  const _categorias = useSelector(categoriaState);
  const _provincias = useSelector(provinciaState);
  const categorias = _categorias.categorias_notas;
  const provincias = _provincias.provincias;



  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_NOTA)));
  const [validation, setValidation] = useState(JSON.parse(JSON.stringify(VALIDATION_FORM_NOTA)))
  const [nota, setNota] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar": {
        //Delete property
        const input_validation = Validate(form, validation, null);
        setValidation(input_validation);
        //Validaciones
        if (input_validation.error.status) {
          return Alerts({
            msg: "Warning",
            title: input_validation.error.msg,
            code: "201",
            type: "error",
          });
        }

        let formData = new FormData;


        /*Evento*/
        formData.append("autor", form.autor[0]);
        formData.append("imagen", form.imagen[0]);
        formData.append("descripcion", form.descripcion[0]);
        formData.append("titulo", form.titulo[0]);
        formData.append("contenido", form.contenido[0]);
        formData.append("autor", form.autor[0]);
        formData.append("provincia", form.provincia[0]);
        formData.append("status", value);

        form.categorias[0].map((c, index) => {
          formData.append("categorias[" + index + "]", c);
        })

        const response = await RestApi({
          url: URL_NOTA_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });

          return navigation('/dashboard/nota/' + response.data.data.id)
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;

      case "aplicar": {
        let formData = new FormData;

        /*Evento*/
        formData.append("_method", 'PUT');
        formData.append("id", form.id[0]);
        formData.append("autor", form.autor[0]);
        formData.append("descripcion", form.descripcion[0]);
        formData.append("titulo", form.titulo[0]);
        formData.append("contenido", form.contenido[0]);
        formData.append("autor", form.autor[0]);
        formData.append("provincia", form.provincia[0]);

        if (form.imagen[0] !== "") formData.append("imagen", form.imagen[0]);

        form.categorias[0].map((c, index) => {
          formData.append("categorias[" + index + "]", c);
        })

        const response = await RestApi({
          url: URL_NOTA_STORE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {
          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }
      } break;
      case "status": {
        let formData = new FormData;
        formData.append("_method", 'PUT');
        formData.append("id", form.id[0]);
        formData.append("status", value);

        const response = await RestApi({
          url: URL_NOTA_UPDATE,
          formData: formData,
          method: 'post',
          setLoading: setLoading,
        });

        //Error
        if (response.status == 201 || response.status == 200) {

          let input = form;
          input['status_id'][0] = value;

          setForm({
            ...form,
            [e.target.name]: input[e.target.name],
          });

          return Alerts({
            msg: "Alert",
            title: response.data.msg,
            code: "401",
            type: "success",
          });
        } else {

          if (typeof response.data.msg === 'string') {
            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "200",
              type: "success",
            });
          }

          Object.keys(response.data.msg).map((key) => {
            return Alerts({
              msg: "Alert",
              title: response.data.msg[key][0],
              code: "401",
              type: "error",
            });
          });

        }

      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "imagen": {
        let input = form;
        input[e.target.name][0] = e.target.files[0];
        input[e.target.name + "_url"][0] = URL.createObjectURL(
          e.target.files[0]
        );

        setForm({
          ...form,
          [e.target.name]: input[e.target.name],
          [e.target.name + "_url"]: input[e.target.name + "_url"],
        });
      } break;
      case "categorias": {
        let input = form;
        let result = form.categorias[0];
        if (
          result.indexOf(categorias[e.target.id].id) < 0 &&
          result.length < 3
        ) {
          result.push(categorias[e.target.id].id);
        } else {
          delete result[result.indexOf(categorias[e.target.id].id)];
          result = result.filter((i) => i != null);
        }

        input["categorias"][0] = result;

        setForm({
          ...form,
          categorias: input["categorias"],
        });
      } break;
      case "titulo":
      case "descripcion":
      case "contenido":
      case "localidad":
      case "provincia":
      case "autor": {
        let input = form;
        input[e.target.name][0] = e.target.value;

        setForm({
          ...form,
          [e.target.name]: input[e.target.name]
        })
      } break;
    }
  }


  useEffect(() => {
    if (!categorias) {
      let params = {
        url: URL_CATEGORIAS_INDEX,
        tipo: "nota"
      };
      dispatch(fetchCategoriaNotas(params));
    }
  }, [])

  useEffect(() => {
    if (!provincias) {
      let params = {
        url: URL_PROVINCIA_INDEX,
      };
      dispatch(fetchProvincias(params));
    }
  }, [])

  useEffect(() => {
    if (id > 0) {
      const fetch = async () => {
        let params = {
          id: id
        }
        const response = await GetApi({ url: URL_NOTA_SHOW + id, params: params, setLoading: setLoading });

        attachNota(response, setForm);
        setNota(true);
      }

      if (!nota) {
        fetch();
      }
    }
  }, [])
  return (
    <>
      {
        id == 0
          ? <Nota
            id={id}
            form={form}
            setForm={setForm}
            validation={validation}
            setValidation={setValidation}
            onChange={onChange}
            onClick={onClick}
            categorias={categorias}
            provincias={provincias}
          />
          : !nota
            ? <SpinnerScreen />
            : <Nota
              id={id}
              form={form}
              setForm={setForm}
              validation={validation}
              setValidation={setValidation}
              onChange={onChange}
              onClick={onClick}
              categorias={categorias}
              provincias={provincias}
            />
      }
    </>
  )
}

export default NotaController