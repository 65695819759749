//Date format
import { format, add, sub } from "date-fns";

//Date range Finde
export const DateRangeFinde = () => {
  const hoy = new Date();  
  const dia = hoy.getDay();

  let array = [];

  /* 
  domingo 0
  lunes 1
  martes 2
  miercoles 3
  jueves  4
  viernes 5
  sabado 6
  */

  switch (dia) {
    case 0:{ //Domingo
      let start = format(add(hoy, {days: 4}),"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 7}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 1:{ //Lunes
      let start = format(add(hoy, {days: 3}),"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 6}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 2:{ //Martes
      let start = format(add(hoy, {days: 2}),"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 5}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 3:{ //Miercoles
      let start = format(add(hoy, {days: 1}),"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 4}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 4:{ //Jueves
      let start = format(hoy,"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 3}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 5:{ //viernes
      let start = format(hoy,"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 2}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
    case 6:{ //sabado
      let start = format(hoy,"yyyy/MM/dd").toString();
      let end = format(add(hoy, {days: 1}),"yyyy/MM/dd").toString();

      array.push(start);
      array.push(end);
    }
    break;
  }

  

  return btoa(JSON.stringify(array));
}

//Date range mes
export const DateRangeMes = () => {

  const hoy = new Date();

  let array = [
    format(hoy, "yyyy/MM/dd").toString(),
    format(new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0), "yyyy/MM/dd").toString()
  ]

  array = JSON.stringify(array);
  array = btoa(array)  

  return array;
}