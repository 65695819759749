const ModalInforme = ({onClick, data}) => {
  const eventos = data.historial_eventos;

  const salesTotalTickets = (value, select) => {
    let suma = 0;
    value = value.length > 0 ? value : [value]

    value.map((evento) => {
      evento.reportes.map((reporte) => {
        if(select == "tickets"){
          suma = reporte.cantidad+suma
        } else {
          suma = (reporte.cantidad*reporte.precio_unitario)+suma
        }
      });
    })

    return suma;
  }

  return (

    <div class="modal d-block effect-blur  bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{zIndex: 104}}
      >
      
    <div class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal" style={{maxWidth: "1000px"}}>
      
    <div className="modal-content bg-light">
      <div class="title bg-white m-0 p-4 d-flex justify-content-between">
        <div>
          <h1>Informe de ventas por evento</h1>
        <span class="fs-6 text-muted">Vista rapida al total de ventas por evento de la sala. </span>  
      </div>
        <div>
          <button class="border-0 bg-transparent" name="close_modal" onFocus={(event) => onClick(event, null)}><i class="bi bi-x-lg"></i></button>
        </div>
      </div>
      <div class="body p-md-4 p-2">
          <div class="row">
            <div class="col-md-6 col-12 mb-3">
              <div class="bg-white px-3 py-3 shadow-sm fs-5 text-muted text-center" style={{borderRadius: "10px"}}> 
                Cantidad de entradas vendidas
                <span class="d-block fw-bold fs-4">{eventos.length == 0? "" : salesTotalTickets(eventos,"tickets")}</span>
              </div>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <div class="bg-white px-3 py-3 shadow-sm fs-5 text-muted text-center" style={{borderRadius: "10px"}}> 
               Total
                <span class="d-block fw-bold fs-4">{eventos.length == 0? "" :  "ARS " + salesTotalTickets(eventos, "sales")}</span>
              </div>
            </div>
            {
              eventos.length == 0
              ? <div className="col-md-12 mb-3">
                  <div className="bg-white shadow-sm d-flex justify-content-center align-items-center py-4">
                  <span class="fw-normal text-dark">
              <i class="bi bi-emoji-frown"></i> No hay reportes disponibles
            </span>
                  </div>
              </div>
              : eventos.map((evento) => (
                <div class="col-md-12 mb-3">
                <div class="w-100 text-end shadow-sm bg-white" style={{borderRadius: "10px 10px 0px 0px"}}> <button class="btn btn-custom-black text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-2 py-1 fs-10"><i class="fs-8 bi bi-file-earmark-arrow-up"></i> Ver detalles </button> </div>
                <div class="bg-white px-3 pb-3 shadow-sm fs-5 text-muted d-flex  justify-content-between" style={{borderRadius: "0px 0px 10px 10px"}}> 
                  <div>
                    <ul class="list-group">
                      <li class="list-group-item text-muted fs-6 border-0">Evento <span class="fs-5 fw-bold">{evento.nombre}</span></li>
                    </ul>
                  </div>
                  <div>
                    <ul class="list-group">
                      <li class="list-group-item fs-6 text-muted border-0">Entradas vendidas <span class="fw-bold fs-5">{eventos.length == 0? "" : salesTotalTickets(evento, "tickets")}</span></li>
                      <li class="list-group-item fs-6 text-muted border-0">Total <span class="fw-bold fs-5">ARS {eventos.length == 0? "" :salesTotalTickets(evento, "sales")}</span></li>
                    </ul>
                  </div>
                 </div>
              </div>
              ))
            }
           
          </div>
      </div>
      <div class="footer bg-white p-4">
        <div class="d-flex justify-content-between">
          <div></div>
          <div class="">
          <button class="btn btn-secondary rounded-pill px-4" name="close_modal" onFocus={(event) => onClick(event, null)}>Cerrar</button>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>

  )
}

export default ModalInforme