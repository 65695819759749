import { Link } from "react-router-dom";

const CardNota = ({data}) => {
    const {id, categorias, contenido, created_at, titulo, descripcion, autor, imagen} = data

    const concatDate = (fecha, select) => {
        if(!fecha){
          return "Sin fecha asignada"
        }
    
        let result;
    
        const dias = [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sabado",
        ];
        const mes = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
    
    
        switch (select) {
          case "created_at":{
            let fh = new Date(fecha);
            
            result = dias[fh.getDay()]+" "+fh.getDate()+" de "+(mes[fh.getMonth()])+" de "+fh.getFullYear()
          }
          break;
          case "funciones":{
            if(!fecha){
              return ""
            }
            let fechas = JSON.parse(fecha);
            let fh_start = fechas[0].fechas[0].split(" ");
            let fh_end = fechas[fechas.length-1].fechas[fechas[fechas.length-1].fechas.length-1].split(" ");
    
            result = "Desde el "+fh_start[0]+" "+fh_start[1]+" de "+fh_start[3]+" hasta el "+fh_end[0]+" "+fh_end[1]+" de "+fh_end[3]
          }
          break;
        }
    
        return result;
      }
  return (
    
    <div class="col-lg-4 wow bounceInUp">
    <div class="bg-blanco box-multimedia mb-4" style={{zIndex: 3}}>
    <div class="header-box position-relative d-flex align-items-center justify-content-center">
        <img src={"https://antesala.com.ar/assets/img/notas/" +imagen} width="100%" alt="IMAGEN NO ENCONTRADA" />
    </div>
    <div class="d-flex flex-column justify-content-between p-4">
        <div>
            <p>{concatDate(created_at, "created_at")}</p>
            <hr class="hr-black" />
            <h2 class="fc-amarillo">"{titulo}"</h2>
            <p class="Grifter">Por {autor}</p>
            <p>{descripcion}</p>
        </div>
        <div class="text-center mt-4">
            <Link to={"/contenido/nota/"+id} class="btn btn-custom-black">leer</Link>
        </div>
    </div>
</div></div>
  )
}

export default CardNota