import { useContext, useEffect, useState } from "react";
import { authContext } from "../../../../context/auth/AuthContext";
import { CollectionStateContext } from "../../../../context/EventosState";
import { obtenerMunicipios } from "../../../../helpers/NormGeograficoHelper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ActualizarRole,
  ActualizarUsuario,
} from "../../../../helpers/UserHelper";
import AlertWarning from "../../../../Alerts/AlertWarning";
import CreateFactura from "../../../../helpers/FacturaHelper";

const MiCuenta = () => {
  const { user } = useContext(authContext);
  const { data, rol, status, auth } = user;

  let arrayImg;

  const imagenes = [
    rol == 3 ? data.datos.imagen_desc_1 : "",
    rol == 3 ? data.datos.imagen_desc_2 : "",
    rol == 3 ? data.datos.imagen_desc_3 : "",
    rol == 3 ? data.datos.imagen_int_1 : "",
    rol == 3 ? data.datos.imagen_int_2 : "",
    rol == 3 ? data.datos.imagen_int_3 : "",
  ];

  const context = useContext(CollectionStateContext);
  const { geolocationApi, obtenerDatoFiscal, state } = context;

  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    role_localidad: "",
  });
  const [loading, setLoading] = useState(false);
  const LSProvincias = localStorage.getItem("provincias");
  const [dataProvincias, setProvincias] = useState(
    LSProvincias ? JSON.parse(LSProvincias) : state.provincias
  );

  //QUERY STATE

  const [DatoFiscal, setDatoFiscal] = useState(state.datoFiscal);
  const [dataGeolocation, setGeolocation] = useState(state.provincias);
  const [dataLocalidad, setLocalidad] = useState({
    class: "d-none",
    content: null,
  });

  //FILTER

  //FORM

  const [form, setForm] = useState({
    //PERFIL USUARIO
    editar_perfil: false,
    nombre: "",
    apellido: "",
    telefono: "",
    direccion: "",
    provincia: "",
    localidad: "",
    imagen_logo_url: null,
    //USER ROLE
    role_nombre: "",
    razon_social: "",
    role_telefono: "",
    role_direccion: "",
    role_provincia: "",
    role_localidad: "",
    website: "",
    url_maps: "",
    url_facebook: "",
    url_instagram: "",
    descripcion: "",
    imagen_1: "",
    imagen_2: "",
    imagen_3: "",
    imagen_4: "",
    imagen_5: "",
    imagen_6: "",
    imagen_logo: "",
    //USER FACTURACION
    titular: "",
    cbu: "",
    dato_fiscal: "",
    comprobante_cbu: "",
    comprobante_afip: "",
    comprobante_entidad: "",
  });

  //BUTTON EVENT

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "editar_perfil":
        {
          if (form.editar_perfil) {
            setForm({
              ...form,
              [e.target.name]: false,
            });
          } else {
            setForm({
              ...form,
              [e.target.name]: true,
            });
          }
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      //USER FACTURACION
      case "comprobante_entidad":
      case "comprobante_cbu":
      case "comprobante_afip":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
          });
        }
        break;
      //USER ROLE
      case "role_Selectlocalidad":
        {
          setForm({
            ...form,
            role_localidad: e.target.value,
            url_maps:
              form.role_direccion +
              " " +
              form.role_provincia +
              " " +
              e.target.value,
          });

          setLocalidad({
            class: "d-none",
            content: null,
          });
        }
        break;
      case "role_SearchLocalidad":
        {
          if (
            e.target.value.length < initialStateFilter.role_localidad.length
          ) {
            setInitialStateFilter({
              ...initialStateFilter,
              role_localidad: e.target.value,
            });
            if (e.target.value.length == 0) {
              setForm({
                ...form,
                role_localidad: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              role_localidad: e.target.value,
            });

            setForm({
              ...form,
              role_localidad: e.target.value,
            });
            if (e.target.value.length > 3 && !loading) {
              setLoading(true);

              setForm({
                ...form,
                role_localidad: e.target.value,
              });

              let expect = setTimeout(async () => {
                let result = await obtenerMunicipios(
                  form.role_provincia,
                  e.target.value
                );
                setLocalidad({
                  class: "form-control",
                  content: result,
                });

                setLoading(false);
              }, 2000);
            }
          }
        }
        break;
      //PERFIL USUARIO
      case "Selectlocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });

          setLocalidad({
            class: "d-none",
            content: null,
          });
        }
        break;
      case "SearchLocalidad":
        {
          setForm({
            ...form,
            localidad: e.target.value,
          });
          const axios = async () => {
            let result = await obtenerMunicipios(
              form.provincia,
              e.target.value
            );
            setLocalidad({
              class: "form-control",
              content: result,
            });
          };
          setTimeout(axios(), 5000);
        }
        break;
      case "provincia":
      case "direccion":
      case "provincia":
      case "telefono":
      case "apellido":
      case "nombre":
      //USER FACTURACION
      case "titular":
      case "cbu":
      case "dato_fiscal":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      //USER ROLE
      case "role_direccion":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            url_maps:
              e.target.value +
              " " +
              form.role_provincia +
              " " +
              form.role_localidad,
          });
        }
        break;
      case "role_provincia":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            url_maps:
              form.role_direccion +
              " " +
              e.target.value +
              " " +
              form.role_localidad,
          });
        }
        break;
      case "role_localidad":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            url_maps:
              form.role_direccion +
              " " +
              form.role_provincia +
              " " +
              e.target.value,
          });
        }
        break;
      case "website":
      case "url_instagram":
      case "url_facebook":
      case "role_telefono":
      case "razon_social":
      case "role_nombre":
      case "descripcion":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "imagen_1":
      case "imagen_2":
      case "imagen_3":
      case "imagen_4":
      case "imagen_5":
      case "imagen_6":
      //AMBOS USUARIO / ROLE
      case "imagen_logo":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            [e.target.name + "_url"]: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
    }
  };

  const onSave = (e) => {
    let userForm = new FormData();
    let roleForm = new FormData();

    //FORM USUARIO

    if (form.nombre.trim() !== "") {
      userForm.append("nombre", form.nombre);
    }

    if (form.apellido.trim() !== "") {
      userForm.append("apellido", form.apellido);
    }

    if (form.telefono.trim() !== "") {
      userForm.append("telefono", form.telefono);
    }

    if (form.direccion.trim() !== "") {
      userForm.append("direccion", form.direccion);
    }

    if (form.localidad.trim() !== "") {
      userForm.append("localidad", form.localidad);
    }

    if (form.provincia.trim() !== "") {
      userForm.append("provincia", form.provincia);
    }

    //FORM ROLE

    if (form.role_nombre.trim() !== "") {
      roleForm.append("nombre", form.role_nombre);
    }

    if (form.razon_social.trim() !== "") {
      roleForm.append("razon_social", form.razon_social);
    }

    if (form.role_telefono.trim() !== "") {
      roleForm.append("telefono", form.role_telefono);
    }

    if (form.role_direccion.trim() !== "") {
      roleForm.append("direccion", form.role_direccion);
    }

    if (form.role_localidad.trim() !== "") {
      roleForm.append("localidad", form.role_localidad);
    }

    if (form.role_provincia.trim() !== "") {
      roleForm.append("provincia", form.role_provincia);
    }

    if (form.url_maps.trim() !== "") {
      roleForm.append("url_maps", form.url_maps);
    }

    if (form.url_facebook.trim() !== "") {
      roleForm.append("url_facebook", form.url_facebook);
    }

    if (form.url_instagram.trim() !== "") {
      roleForm.append("url_instagram", form.url_instagram);
    }

    if (form.website.trim() !== "") {
      roleForm.append("website", form.website);
    }

    if (form.descripcion.trim() !== "") {
      roleForm.append("descripcion", form.descripcion);
    }

    if (form.imagen_logo !== "") {
      roleForm.append("imagen_logo", form.imagen_logo);
    }

    if (form.imagen_1 !== "") {
      roleForm.append("imagen_1", form.imagen_1);
    }

    if (form.imagen_2 !== "") {
      roleForm.append("imagen_2", form.imagen_2);
    }

    if (form.imagen_3 !== "") {
      roleForm.append("imagen_3", form.imagen_3);
    }

    if (form.imagen_4 !== "") {
      roleForm.append("imagen_4", form.imagen_4);
    }

    if (form.imagen_5 !== "") {
      roleForm.append("imagen_5", form.imagen_5);
    }

    if (form.imagen_6 !== "") {
      roleForm.append("imagen_6", form.imagen_6);
    }

    let responseUser = ActualizarUsuario(userForm, setLoading);
    let responseRole = ActualizarRole(roleForm, setLoading);
  };

  const onSaveLiquidacion = async (e) => {
    let dataForm = new FormData();

    //FORM FACTURACION

    if (data.facturacion.length == 0) {
      if (
        form.dato_fiscal.trim() == "" ||
        form.cbu.trim() == "" ||
        form.titular.trim() == "" ||
        form.comprobante_afip == "" ||
        form.comprobante_cbu == "" ||
        form.comprobante_entidad == ""
      ) {
        return AlertWarning(
          "Complete todos los campos para generar datos de liquidación",
          "Advertencia"
        );
      }
    }

    if (form.dato_fiscal.trim() !== "") {
      dataForm.append("dato_fiscal", form.dato_fiscal);
    }
    if (form.titular.trim() !== "") {
      dataForm.append("entidad", form.titular);
    }
    if (form.cbu.trim() !== "") {
      dataForm.append("cbu", form.cbu);
    }
    if (form.comprobante_afip !== "") {
      dataForm.append("comprobante_afip", form.comprobante_afip);
    }
    if (form.comprobante_cbu !== "") {
      dataForm.append("comprobante_cbu", form.comprobante_cbu);
    }
    if (form.comprobante_entidad !== "") {
      dataForm.append("comprobante_entidad", form.comprobante_entidad);
    }

    let response = await CreateFactura(dataForm, setLoading);
  };

  //EFFECT DOM

  useEffect(() => {
    if (DatoFiscal.length == 0) {
      obtenerDatoFiscal(setDatoFiscal);
    }
  }, []);
  return (
    <div
      className="row container-md mx-md-auto p-0 m-0 border-0 texto-normal"
      style={{ maxWidth: "1200px" }}
    >
      <div
        className="col-12 border-0 p-0 p-3 shadow-sm bg-white mb-3 texto-normal"
        style={{ borderRadius: "10px" }}
      >
        <div className="row m-0 p-0 w-100">
          <div className="col-md-3 col-12 text-center overflow-hidden p-0 mb-0 border-0">
            <label for="formImagenLogo" className="form-label m-0 p-0 p-1 border-0">
              <div
                className="btn p-1 m-0 bg-ligth border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                style={{
                  width: "230px",
                  height: "230px",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={
                    form.imagen_logo_url
                      ? form.imagen_logo_url
                      : data.datos
                      ? "https://antesala.com.ar/assets/img/usuarios/" + data.datos.imagen_logo
                      : "https://antesala.com.ar/assets/img/usuarios/" + data.imagen_logo
                  }
                  className="img-fluid"
                  style={{ height: "250px" }}
                  alt="IMAGEN NO ENCONTRADA"
                />
                <div 
                className="img-edit-pencil py-5"
                 style={{
                  width: "230px",
                  height: "230px",
                  borderRadius: "50%",
                }}
                >
                  <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                  <span className="text-white fs-7 d-block">
                    Formatos admitidos JPG PNG JPEG{" "}
                  </span>
                  <span className="text-white fs-7">
                    Dimension sugerida 500x500 72DPI{" "}
                  </span>
                </div>
              </div>
            </label>
            <input
              className="form-control d-none"
              type="file"
              id="formImagenLogo"
              name="imagen_logo"
              onChange={(event) => onChange(event)}
            />
            <div className="w-100 text-center">Imagen de perfil</div>
          </div>
          <div className="col-md-9 col-12 fs-7 mb-0">
            <ul className="list-group m-0 p-0">
              <li className="list-group-item border-0 fs-5 d-md-flex justify-content-between">
                <div>
                  {form.editar_perfil ? (
                    <div>
                      <label htmlFor="" className="text-muted fs-6 ms-3">
                        Nombre
                      </label>
                      <input
                        type="text"
                        className="form-control border-custom-naranja fs-6 p-0 m-0 px-1 ms-3 rounded-pill"
                        defaultValue={data.nombre}
                        name="nombre"
                        onChange={(event) => onChange(event)}
                      />

                      <label htmlFor="" className="text-muted fs-6 ms-3">
                        Apellido
                      </label>
                      <input
                        type="text"
                        className="form-control border-custom-naranja fs-6 p-0 m-0 px-1 ms-3 rounded-pill"
                        defaultValue={data.apellido}
                        name="apellido"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                  ) : (
                    <span>{data.nombre + " " + data.apellido}</span>
                  )}
                </div>
                <div className="text-end">
                  <label
                    className={
                      form.editar_perfil
                        ? "btn btn-outline-dark border-0 btn-custom-naranja text-white fw-normal GothamBold rounded-pill px-3 py-2 fs-5 me-2 active"
                        : "btn btn-outline-dark border-0 btn-custom-naranja text-white fw-normal GothamBold rounded-pill px-3 py-2 fs-5"
                    }
                  >
                    <i className="bi bi-pencil"></i>{" "}
                    {form.editar_perfil ? "Cancelar" : "Editar"}
                    <button
                      className="d-none"
                      name="editar_perfil"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                  <label className={
                      form.editar_perfil
                        ? "btn btn-outline-dark border-0 btn-custom-naranja text-white fw-normal GothamBold rounded-pill px-3 py-2 fs-5"
                        : "d-none"
                    }>
                    Aplicar cambios
                    <button
                      className="d-none"
                      onClick={(event) => onSave(event)}
                    >
                    </button>
                  </label>
                </div>
              </li>
              <li className="list-group-item border-0 fs-6">
                <i className="bi bi-person"></i>
                {rol == 1 ? (
                  <span className="ms-2">Administrador</span>
                ) : rol == 3 ? (
                  <span className="ms-2">Responsable de sala</span>
                ) : rol == 4 ? (
                  <span className="ms-2">Productor</span>
                ) : (
                  ""
                )}
              </li>
              <li className="list-group-item border-0 fs-6">
                <i className="bi bi-envelope"></i>{" "}
                <span className="ms-2">{data.email}</span>
              </li>
              <li className="list-group-item border-0 d-flex fs-6">
                <i className="bi bi-telephone"></i>{" "}
                {form.editar_perfil ? (
                  <input
                    type="text"
                    className="form-control border-custom-naranja fs-6 p-0 m-0 px-1 ms-2 rounded-pill"
                    name="telefono"
                    onChange={(event) => onChange(event)}
                    defaultValue={data.telefono}
                  />
                ) : (
                  <span className="ms-2">{data.telefono}</span>
                )}
              </li>
              <li className="list-group-item border-0 d-flex fs-6">
                <i className="bi bi-geo-alt"></i>
                {form.editar_perfil ? (
                  <div>
                    <input
                      type="text"
                      className="form-control border-custom-naranja fs-6 p-0 m-0 px-1 ms-2 rounded-pill"
                      defaultValue={data.direccion}
                    />

                    <div className="mt-2 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6 ms-2  text-muted"
                      >
                        Provincia
                      </label>
                      <select
                        class="form-select border-custom-naranja fs-6 p-0 m-0 px-1 ms-2 rounded-pill"
                        aria-label="Default select example"
                        defaultValue={data ? data.provincia : ""}
                        name="provincia"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>Selecciona tu provincia</option>
                        {dataProvincias
                          ? dataProvincias.map((provincia) => (
                              <option value={provincia.nombre}>
                                {provincia.nombre}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    {form.provincia == "" ? (
                      ""
                    ) : (
                      <div className="mt-2 text-start">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label fs-6 ms-2  text-muted"
                        >
                          Localidad
                        </label>
                        <input
                          className="form-control border-custom-naranja fs-6 p-0 m-0 px-1 ms-2 rounded-pill"
                          type="text"
                          value={form.localidad}
                          name="SearchLocalidad"
                          onChange={(event) => onChange(event)}
                          autocomplete="nope"
                        />
                        <div className="ms-3 overflow-hidden">
                          {!dataLocalidad.content ? (
                            ""
                          ) : (
                            <ul class="list-group border-0 border-end border-start border-bottom">
                              {dataLocalidad.content.map((municipios) => (
                                <li class="list-group-item border-0 p-0">
                                  <button
                                    className=" btn btn-outline-dark fs-6 rounded-0 border-0 w-100"
                                    name="Selectlocalidad"
                                    value={municipios.nombre}
                                    onClick={(event) => onChange(event)}
                                  >
                                    {municipios.nombre}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <span className="ms-2">
                    {data.direccion +
                      " " +
                      data.provincia +
                      " " +
                      data.localidad}
                  </span>
                )}
              </li>
              <li className="list-group-item border-0 fs-6 d-flex justify-content-between">
                <div>
                  {data.datos ? (
                    data.datos.usuario_estados_id == 1 ? (
                      <span>
                        <i class="bi bi-bookmark-check text-success"></i> Su
                        cuenta se encuentra Activa.
                      </span>
                    ) : data.datos.usuario_estados_id == 2 ? (
                      <span title="Su cuenta se encuentra inactiva, no podra publicar eventos nuevos">
                        <i class="bi bi-bookmark-x text-danger"></i> Su cuenta
                        se encuentra inactiva, para realizar acciones como
                        publicar eventos, debe solicitar la activación de su
                        cuenta a <strong>antesala.cordoba@gmail.com.</strong>{" "}
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <button className="d-none btn btn-custom-naranja text-white text-nowrap rounded-pill px-2 py-1 fs-10 GothamBold">
                    <i className="bi bi-moon-stars"></i> Premium{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {rol == 3 || rol == 4 ? (
        <div
          className="col-12 border-0 shadow-sm bg-white mb-3 p-4 texto-normal"
          style={{ borderRadius: "10px" }}
        >
          <div className="row m-0 p-0">
            <div className="col-md-12 col-12">
              <div className="mb-3 fs-5 py-2 fw-bold border-bottom">
                {rol == 3 ? (
                  <span>Datos de mi sala</span>
                ) : (
                  <span>Datos de productor</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  Nombre
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill fs-6 border-custom-naranja p-0 m-0 py-1 px-2"
                  placeholder="Ingresa el nombre de tu sala..."
                  defaultValue={data.datos.nombre}
                  name="role_nombre"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control bg-white border-0 rounded-pill p-0 m-0 py-1 px-2 "
                  id="exampleFormControlInput1"
                  defaultValue={data.datos.email}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-6">
              {rol == 3 ? (
                <div className="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label text-muted fs-8"
                  >
                    Razon Social
                  </label>
                  <input
                    type="email"
                    className="form-control rounded-pill   border-custom-naranja p-0 m-0 py-1 px-2"
                    id="exampleFormControlInput1"
                    placeholder="Ingresa la razon social de tu sala..."
                    defaultValue={data.datos.razon_social}
                    name="razon_social"
                    onChange={(event) => onChange(event)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  Telefono
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill  border-custom-naranja p-0 m-0 py-1 px-2"
                  id="exampleFormControlInput1"
                  placeholder="Ingresa el telefono de tu sala..."
                  defaultValue={data.datos.telefono}
                  name="role_telefono"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="" className="form-label fs-8 text-muted">
                  Dirección
                </label>
                <input
                  type="text"
                  className="form-control border-custom-naranja fs-6 p-0 m-0 py-1 px-2 rounded-pill"
                  defaultValue={data.datos.direccion}
                  name="role_direccion"
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="mb-3 w-100">
                <label
                  for="exampleFormControlInput1"
                  className="form-label fs-8 text-muted"
                >
                  Provincia
                </label>
                <select
                  class="form-select border-custom-naranja fs-6 p-0 m-0 px-2 py-1 rounded-pill"
                  aria-label="Default select example"
                  name="role_provincia"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{data.datos.provincia}</option>
                  {dataProvincias
                    ? dataProvincias.map((provincia) => (
                        <option value={provincia.nombre}>
                          {provincia.nombre}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              {form.role_provincia == "" ? (
                ""
              ) : (
                <div className="mb-3 text-start">
                  <label
                    for="exampleFormControlInput1"
                    className="form-label fs-8  text-muted"
                  >
                    Localidad
                  </label>
                  <input
                    className="form-control border-custom-naranja fs-6 p-0 m-0 py-1 px-2 rounded-pill"
                    type="text"
                    value={form.role_localidad}
                    name="role_SearchLocalidad"
                    onChange={(event) => onChange(event)}
                    autocomplete="nope"
                  />
                  <div className="ms-3 overflow-hidden">
                    {!dataLocalidad.content ? (
                      ""
                    ) : (
                      <ul class="list-group border-0 border-end border-start border-bottom">
                        {dataLocalidad.content.map((municipios) => (
                          <li class="list-group-item border-0 p-0">
                            <button
                              className=" btn btn-outline-dark fs-7 rounded-0 border-0 w-100"
                              name="role_Selectlocalidad"
                              value={municipios.nombre}
                              onClick={(event) => onChange(event)}
                            >
                              {municipios.nombre}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="d-flex w-100 bg-opacity-25 rounded h-100 justify-content-center align-items-center">
                {form.role_direccion.trim() == "" ? (
                  <iframe
                    src={
                      "https://maps.google.com/?q=" +
                      encodeURI(
                        data.datos.direccion +
                          " " +
                          data.datos.provincia +
                          " " +
                          data.datos.localidad
                      ) +
                      "&z=14&t=m&output=embed"
                    }
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="my-3"
                  ></iframe>
                ) : (
                  <iframe
                    src={
                      "https://maps.google.com/?q=" +
                      encodeURI(
                        form.role_direccion +
                          " " +
                          form.role_provincia +
                          " " +
                          form.role_localidad
                      ) +
                      "&z=14&t=m&output=embed"
                    }
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="my-3"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {rol == 3 ? (
        <div
          className="col-md-12  border-0 shadow-sm bg-white mb-3 p-4 texto-normal"
          style={{ borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 fs-5 py-2 fw-bold border-bottom">
                Links Sociales (Opcional)
                <span className="fs-6 text-muted fw-normal d-block">
                  Ingresa las URLs de tus principales redes sociales.
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  Sitio Web
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill  border-custom-naranja p-0 m-0 py-1 px-2"
                  id="exampleFormControlInput1"
                  placeholder="Ingresa el URL de tu sitio web..."
                  defaultValue={data.datos.website}
                  name="website"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  URL de Facebook
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill  border-custom-naranja p-0 m-0 py-1 px-2"
                  id="exampleFormControlInput1"
                  placeholder="Ingresa el URL de tu pagina oficial de Facebook..."
                  defaultValue={data.datos.url_facebook}
                  name="url_facebook"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label text-muted fs-8"
                >
                  URL de Instagram
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill  border-custom-naranja p-0 m-0 py-1 px-2"
                  id="exampleFormControlInput1"
                  placeholder="Ingresa el URL de tu pagina oficial de Instagram..."
                  defaultValue={data.datos.url_instagram}
                  name="url_instagram"
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {rol == 3 ? (
        <div
          className="col-md-12  border-0 shadow-sm bg-white mb-3 p-4 texto-normal"
          style={{ borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 fs-5 py-2 fw-bold border-0">
                Descripción de mi sala
                <span className="fs-6 text-muted fw-normal d-block">
                  Escribe una reseña o descripción de tu sala.
                </span>
              </div>
              <div className="" style={{ height: "auto" }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    form.descripcion
                      ? form.descripcion
                      : data
                      ? data.datos.descripcion
                      : "Describe tu sala"
                  }
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log();
                  }}
                  onChange={(event, editor) => {
                    //const data = editor.getData();
                    setForm({
                      ...form,
                      descripcion: editor.getData(),
                    });
                  }}
                  onBlur={(event, editor) => {
                    console.log();
                    //console.log( 'Blur.', editor );
                  }}
                  onFocus={(event, editor) => {
                    console.log();
                    //console.log( 'Focus.', editor );
                  }}
                />
              </div>
            </div>

            <div className="col-md-12"></div>
          </div>
        </div>
      ) : (
        ""
      )}

      {rol == 3 ? (
        <div
          className="col-md-12 w-100 border-0 shadow-sm bg-white mb-3 p-4 texto-normal"
          style={{ borderRadius: "10px" }}
        >
          <div className="row">
            <div className="w-100 fs-5 fw-bold border-bottom d-block mb-3">
              Galeria de imagenes
              <span className="fs-6 text-muted fw-normal d-block">
                Sube imagenes que muestren lo mejor de tu Sala.
              </span>
            </div>
            <div className="row w-100 border-0 bg-white mb-3 m-0 p-md-2 p-0">
              {imagenes.map((imagen, index) => (
                <div className="col-md-4 col-12 d-flex justify-content-center mb-2 border-0 p-0">
                  <label for={"formImg_" + index} className="border-0 w-100 p-1 m-0">
                    <div
                      className="btn p-0 m-0 d-flex align-items-center position-relative overflow-hidden w-100 border-0"
                      style={{ height: "200px" }}
                    >
                      <img
                        src={
                          form["imagen_" + (index + 1) + "_url"]
                            ? form["imagen_" + (index + 1) + "_url"]
                            : "https://antesala.com.ar/assets/img/salas/" + imagen
                        }
                        className="img-fluid w-100"
                        alt="IMAGEN NO ENCONTRADA"
                      />
                      <div className="img-edit-pencil w-100 border-0">
                        <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                        <span className="fs-7 d-block text-white">
                          Formatos admitidos JPG PNG JPEG{" "}
                        </span>
                      </div>
                    </div>
                  </label>

                  <input
                    className="form-control d-none"
                    type="file"
                    id={"formImg_" + index}
                    name={"imagen_" + (index + 1)}
                    onChange={(event) => onChange(event)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {rol == 1 || rol == 2 || rol == 3 || rol == 4 ? (
        <div
          className="col-md-12 w-100 border-0 shadow-sm bg-white mb-5 p-4 texto-normal"
          style={{ borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 fs-5 py-2 fw-bold border-0 d-flex justify-content-between">
                <div>
                  Datos de facturación
                  <span className="fs-6 text-muted fw-normal d-block">
                    Rellena tus datos de facturación para generar entradas mas
                    rapido!
                  </span>
                </div>
                <div></div>
              </div>

              <div className="mb-3 mx-auto" style={{ maxWidth: "700px" }}>
                <ul className="list-group">
                  <li className="list-group-item border-0 ">
                    <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        CUIL/CUIT del títular
                      </label>
                      <input
                        type="number"
                        className="form-control rounded-pill border-custom-naranja py-1"
                        id="exampleFormControlInput1"
                        defaultValue={
                          form.titular.trim() == ""
                            ? data.facturacion.length == 0
                              ? ""
                              : data.facturacion[0].entidad
                            : form.titular
                        }
                        name="titular"
                        onChange={(event) => onChange(event)}
                      />
                    </div>
                    <div className="mb-4">
                      <select
                        className="form-select form-select-lg border-custom-naranja rounded-pill py-1"
                        aria-label=".form-select-lg example"
                        name="dato_fiscal"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>
                          {form.dato_fiscal.trim() == ""
                            ? data.facturacion.length == 0
                              ? ""
                              : data.facturacion[0].dato_fiscal
                            : form.dato_fiscal}
                        </option>
                        {DatoFiscal.length == 0
                          ? ""
                          : DatoFiscal.map((dato) => (
                              <option value={dato.descripcion}>
                                {dato.descripcion}
                              </option>
                            ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label
                        for="comprobanteAfip"
                        className="form-label w-100 "
                      >
                        <div className="d-md-flex justify-content-between mb-2">
                          <span className="fs-6 text-muted ">
                            Subir comprobante de AFIP
                          </span>

                          {data.facturacion.length == 0 ? (
                            ""
                          ) : (
                            <a
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                data.facturacion[0].comprobante_afip
                              }
                              target="_blank"
                              className="d-md-flex d-block btn btn-dark fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill"
                            >
                              <i className=" bi bi-file-earmark-pdf"></i>
                              Descargar archivo
                            </a>
                          )}
                        </div>
                        <span
                          className={
                            form.comprobante_afip == ""
                              ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                              : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                          }
                        >
                          Subir archivo PDF o formato imagen JPG JPEG PNG
                        </span>
                      </label>
                      <input
                        className="form-control d-none"
                        type="file"
                        id="comprobanteAfip"
                        name="comprobante_afip"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        for="comprobanteTitular"
                        className="form-label w-100 "
                      >
                        <div className="d-md-flex justify-content-between mb-2">
                          <span className="fs-6 text-muted">
                            Subir DNI o instrumento constitutivo
                          </span>

                          {data.facturacion.length == 0 ? (
                            ""
                          ) : (
                            <a
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                data.facturacion[0].comprobante_entidad
                              }
                              target="_blank"
                              className="btn btn-dark d-block  fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill"
                            >
                              <i className=" bi bi-file-earmark-pdf"></i>
                              Descargar archivo
                            </a>
                          )}
                        </div>

                        <span
                          className={
                            form.comprobante_entidad == ""
                              ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                              : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                          }
                        >
                          Subir archivo PDF o formato imagen JPG JPEG PNG
                        </span>
                      </label>
                      <input
                        className="form-control d-none"
                        type="file"
                        id="comprobanteTitular"
                        name="comprobante_entidad"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    <div className="mb-4 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-6  text-muted"
                      >
                        Número de CBU
                      </label>
                      <input
                        type="number"
                        className="form-control rounded-pill border-custom-naranja"
                        id="exampleFormControlInput1"
                        defaultValue={
                          form.cbu.trim() == ""
                            ? data.facturacion.length == 0
                              ? ""
                              : data.facturacion[0].cbu
                            : form.cbu
                        }
                        name="cbu"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    <div className="mb-3">
                      <label for="comprobanteCbu" className="form-label w-100">
                        <div className="d-md-flex justify-content-between mb-2">
                          <span className="fs-6 text-muted">
                            Subir comprobante de CBU
                          </span>
                          {data.facturacion.length == 0 ? (
                            ""
                          ) : (
                            <a
                              href={
                                "https://antesala.com.ar/assets/img/comprobantes/" +
                                data.facturacion[0].comprobante_cbu
                              }
                              target="_blank"
                              className="btn btn-dark d-block  fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill"
                            >
                              <i className=" bi bi-file-earmark-pdf"></i>
                              Descargar archivo
                            </a>
                          )}
                        </div>
                        <span
                          className={
                            form.comprobante_cbu == ""
                              ? "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100"
                              : "btn btn-outline-dark border-custom-naranja text-custom-naranja rounded-pill my-1 w-100 active"
                          }
                        >
                          Subir archivo PDF o formato imagen JPG JPEG PNG
                        </span>
                      </label>
                      <input
                        className="form-control d-none"
                        type="file"
                        id="comprobanteCbu"
                        name="comprobante_cbu"
                        onChange={(event) => onChange(event)}
                      />
                    </div>

                    <div className="mb-3 ">
                      <button
                        className="btn btn-custom-naranja w-100 text-white fs-6 p-0 m-0 px-3 py-3"
                        onClick={(event) => onSaveLiquidacion(event)}
                      >
                        Guardar datos de facturación
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <button
        className="fixed-bottom w-100 mx-0 btn btn-custom-naranja rounded-0 text-white fw-normal GothamBold"
        onClick={(event) => onSave(event)}
      >
        Aplicar cambios
      </button>
    </div>
  );
};

export default MiCuenta;
