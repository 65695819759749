import Actualidad from "./sections/actualidad/Actualidad";
import Eventos from "./sections/eventos/Eventos";
import Header from "./sections/header/Header";
import Suscriptions from "../Home/Sections/Suscriptions/Suscriptions";

const Entradas = () => {
  return (
    <>
    <Header/>
    <Eventos/>
    <Actualidad/>
    </>
  )
}

export default Entradas;