import { useState } from "react";
import { Month_Days, Result_line, cycle_active, rangeYear, rangeYears } from "../../../../utils/MembresiaUtils";
import Input from "../../../components/custom/forms/Input";
import GraficoLineal from "../../../components/custom/graficos/GraficoLineal";
import { fetchSuscriptor } from "../../../models/membresias/Index";
import { GetApi } from "../../../../helpers/RestApi";

const Card = ({ data, grafic, onClick, }) => {
  const {
    code,
    created_at,
    updated_at,
    descont,
    description,
    fecha_limite,
    id,
    limit,
    limit_cash,
    name,
    region,
    status_id,
    terminos,
    suscriptos,
    cycle
  } = data;

  const ciclos = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ]

  const años = rangeYears();

  const [suscriptor, setSuscriptor] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "suscriptor": {
        if (e.target.value.length > 3) {
          let params = {
            ['email[0]']: e.target.value,
            membresia_id: id
          }

          const response = await GetApi({
            url: "/membresias/verify",
            params: params,
            setLoading: setLoading
          })

          setSuscriptor(response.data)
        }
      } break;
    }
  }

  console.log(suscriptor);
  return (
    <div
      className="col-md-12 card hover-card border border-light mb-3"
      style={{ borderRadius: "10px" }}
    >
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
        <div className="dropdown">
            <button
              className="d-flex btn btn-outline-dark fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="Cambiar ciclo..."
            >
              Años {data.year}
            </button>
            <ul
              className="dropdown-menu fs-8"
              aria-labelledby="dropdownMenuButton1"
            >
              {
                años.map((c) => {
                  if (c == data.year) {
                    return ""
                  } else {
                    return <li style={{ zIndex: "100" }}>
                      <label
                        class="dropdown-item text-success btn btn-outline-dark border-0"
                        href="#"
                      >
                        {c}
                        <i class="bi bi-check-all"></i>
                        <button
                          className="d-none"
                          name="year"
                          onClick={(event) => onClick(event, { id: id, year: c })}
                        ></button>
                      </label>
                    </li>
                  }
                })
              }
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="d-flex btn btn-outline-dark  fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="Cambiar ciclo..."
            >
              Ciclo {cycle_active(cycle)}
            </button>
            <ul
              className="dropdown-menu fs-8"
              aria-labelledby="dropdownMenuButton1"
            >
              {
                ciclos.map((c) => {
                  if (c.id == cycle) {
                    return ""
                  } else {
                    return <li style={{ zIndex: "100" }}>
                      <label
                        class="dropdown-item text-success btn btn-outline-dark border-0"
                        href="#"
                      >
                        {c.mes}
                        <i class="bi bi-check-all"></i>
                        <button
                          className="d-none"
                          name="ciclo"
                          onClick={(event) => onClick(event, { id: id, ciclo: c.id })}
                        ></button>
                      </label>
                    </li>
                  }
                })
              }
            </ul>
          </div>

          <label className="d-none btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
            <i class="bi bi-chevron-double-left"></i> Duplicar
            <button
              className="d-none"
              name="duplicar"
              onClick={(event) => onClick(event, data)}
            ></button>
          </label>
          

          <label className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
          <i class="bi bi-person-arms-up"></i> Usuarios
            <button
              className="d-none"
              name="usuarios"
              onClick={(event) => onClick(event, data)}
            ></button>
          </label>

          <label className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
            <i class="bi bi-ticket"></i> Reportes
            <button
              className="d-none"
              name="reportes"
              onClick={(event) => onClick(event, data)}
            ></button>
          </label>

          <label className="btn btn-custom-naranja text-white fw-normal GothamBold text-nowrap rounded-pill mx-1 my-2 px-3 py-2 fs-10">
            <i className="bi bi-pencil"></i> Editar
            <button
              className="d-none"
              name="editar"
              onClick={(event) => onClick(event, data)}
            ></button>
          </label>

          <div className="dropdown">
            <button
              className="btn btn-outline-dark fw-normal GothamBold rounded-pill  mx-1 my-2 px-3 py-2 fs-10 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Cambiar estado...
            </button>
            <ul
              className="dropdown-menu fs-8"
              aria-labelledby="dropdownMenuButton1"
            >
              {status_id == 1 ? (
                ""
              ) : (
                <li>
                  <label
                    class="dropdown-item text-success btn btn-outline-dark border-0"
                    href="#"
                  >
                    Activar
                    <i class="bi bi-check-all"></i>
                    <button
                      className="d-none"
                      name="status"
                      onClick={(event) => onClick(event, { id: id, status: 1 })}
                    ></button>
                  </label>
                </li>
              )}
              {status_id == 2 ? (
                ""
              ) : (
                <li>
                  <label
                    class="dropdown-item text-danger btn btn-outline-dark border-0"
                    href="#"
                  >
                    Desactivar
                    <i class="ms-2 bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="status"
                      onClick={(event) => onClick(event, { id: id, status: 2 })}
                    ></button>
                  </label>
                </li>
              )}
            </ul>
          </div>


        </div>
        <div className="col-md-4">
          <ul class="list-group">
            <li class="list-group-item py-1 border-0 bg-transparent">
              Nombre: <span className="fw-bold">{name + " | Ciclo " + cycle_active(cycle)}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Codigo: <span className="fw-bold">{code}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Limite de entradas: <span className="fw-bold">{limit}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Limite de gastos: <span className="fw-bold">{limit_cash}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Ciclo activo: <span className="fw-bold">{cycle_active(cycle)}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Region: <span className="fw-bold">{region}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              Usuarios Activos: <span className="fw-bold">{suscriptos}</span>
            </li>
            <li class="list-group-item py-1 border-0 bg-transparent">
              <Input
                onChange={onChange}
                label={"Consulta si tu usuario se encuentra con membresia"}
                name={"suscriptor"}
                type={"text"}
                svg={""}
                validate={{ msg: "", status: false }}
                disabled={false}
              />
              {
                suscriptor
                  ? <ul class="list-group">

                    {suscriptor[0].status
                      ? <li class="list-group-item border-0 d-flex text-success p-1" style={{ fontSize: "14px" }}>
                        {suscriptor[0].verificado}<i class="text-success bi bi-check2-all"></i> </li>
                      : <li class="list-group-item border-0 text-danger d-flex  p-1" style={{ fontSize: "14px" }}>
                        {suscriptor[0].verificado}<i class="text-danger bi bi-x"></i></li>
                    }

                    {suscriptor[0].status
                      ? <li class="list-group-item border-0 d-flex p-1">
                        <label className="btn btn-danger" style={{ fontSize: "10px" }}>
                          Quitar de la membresia
                          <button className="d-none" name="quitar-membresia" onClick={(event) => onClick(event, {...suscriptor[0], id: id})}></button>
                        </label>
                      </li>
                      : <li class="list-group-item border-0 d-flex p-1">
                        <label className="btn btn-success text-white me-2" style={{ fontSize: "10px" }}>
                          Agregar a la membresia
                          <button className="d-none" name="añadir-membresia" onClick={(event) => onClick(event, {...suscriptor[0], id: id})}></button>
                        </label>
                      </li>
                    }

                  </ul>
                  : ""
              }
            </li>
          </ul>
        </div>
        <div className="col-md-8">
          <GraficoLineal
            labels={Month_Days().a_dias}
            title={"Usos en el mes actual"}
            name_item={"Ventas"}
            input={Result_line(grafic).input}
            b_color={"rgb(53, 162, 235)"}
            bg_color={"rgba(53, 162, 235, 0.5)"}
            //comparativa={Month_Days().a_dias}
            c_name_item={
              "Limite de compras ($" + Result_line(grafic).limite[0] + ")"
            }
            c_input={Result_line(grafic).limite}
            c_b_color={"rgb(255, 99, 132)"}
            c_bg_color={"rgba(255, 99, 132, 0.5)"}
            //acumulativo={Result_line(grafic).acumulado[0]}
            acumulativo_item={
              "Compras acumuladas ($" +
              Result_line(grafic).acumulado[
              Result_line(grafic).acumulado.length - 1
              ] +
              ")"
            }
            acumulativo_input={Result_line(grafic).acumulado}
            acumulativo_b_color={"rgb(68, 201, 1)"}
            acumulativo_bg_color={"rgba(68, 201, 1, 0.5)"}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
