const ModalEditarProductor = ({onClick, onChange, onSave, data, dataGeolocation, dataLocalidad, setForm, form, loading}) => {

console.log(data)
  return (
    <div
    className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style={{ zIndex: "104" }}
  >
    <div
      className="bg-light modal-dialog position-relative animation-modal my-md-5 overflow-hidden texto-normal"
      style={{ maxWidth: "1000px", zIndex: "106" }}
    >
      <div className="modal-content bg-light">
        <div className="title bg-white p-4 m-0 d-flex justify-content-between">
          <div>
            <h1>Productor</h1>
            <span className="fs-6 text-muted">
              Edita la sala, y puedes dejar una notificacion de los cambios
              realizados.{" "}
              <button className="btn btn-warning rounded-pill text-white p-0 m-0 px-2">
                Notificación
              </button>
            </span>
          </div>
          <div>
            <button
              className="border-0 bg-transparent"
              name="close_modal"
              onFocus={(event) => onClick(event, null)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
        <div className="body p-md-4 p-2">
          <ul className="list-group">
            <li
              className="list-group-item d-flex justify-content-between m-0  border-0 shadow-sm"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            >
              <div></div>
              <button
                className="btn  btn-custom-naranja text-white fw-normal GothamBold  fs-9 rounded-pill m-0 py-1 px-3 pb-1 text-nowrap"
                disabled
              >
                <i className="bi bi-pencil "></i> Editar{" "}
              </button>
            </li>
            <li
              className="list-group-item d-md-flex m-0 mb-3 border-0 shadow-sm"
              style={{ borderRadius: "0px 0px 10px 10px" }}
            >
              <label for="formImagenLogo" className="form-label">
                <div
                  className="btn p-0 border-0 d-flex justify-content-center align-items-center position-relative overflow-hidden"
                  style={{
                    width: "300px",
                    height: "300px",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={
                      form.imagen_logo_url
                        ? form.imagen_logo_url
                        : "https://antesala.com.ar/assets/img/usuarios/" + data.imagen_logo
                    }
                    className="img-fluid"
                    alt="IMAGEN NO ENCONTRADA"
                  />
                  <div className="img-edit-pencil">
                    <i className=" d-flex align-items-center justify-content-center  bi bi-pencil"></i>
                    <span className="fs-7 d-block">
                      Formatos admitidos JPG PNG JPEG{" "}
                    </span>
                    <span className="fs-7">
                      Dimension sugerida 500x500 72DPI{" "}
                    </span>
                  </div>
                </div>
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="formImagenLogo"
                name="imagen_logo"
                onChange={(event) => onChange(event)}
              />
              <div className="p-3">
                <div className="text-muted fs-6 mb-3">
                  Nombre{" "}
                  <input
                    className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                    type="text"
                    defaultValue={data.nombre}
                    name="nombre"
                    onChange={(event) => onChange(event)}
                  />
                </div>
                <div className="text-muted fs-6 mb-3">
                  Email
                  <input
                    className="form-control rounded-pill p-0 m-0 px-2 border-0 border-0 bg-white"
                    type="email"
                    defaultValue={data.prd_email}
                    disabled
                  />
                </div>
                <div className="text-muted fs-6 mb-3">
                  Telefono{" "}
                  <input
                    className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                    type="number"
                    defaultValue={data.telefono}
                    name="telefono"
                    onChange={(event) => onChange(event)}
                  />
                </div>
                <div className="text-muted fs-6 mb-1">
                  <div className="mb-3 ">
                    Dirección{" "}
                    <input
                      className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                      type="text"
                      defaultValue={data.direccion}
                      name="direccion"
                      onChange={(event) => onChange(event)}
                    />
                  </div>

                  <div className="mb-3 w-100">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label fs-8 text-muted"
                      >
                        Provincia
                      </label>
                      <select
                        class="form-select border-custom-naranja fs-6 p-0 m-0 px-2 rounded-pill"
                        aria-label="Default select example"
                        name="provincia"
                        onChange={(event) => onChange(event)}
                      >
                        <option selected>{form.provincia}</option>
                        {dataGeolocation
                          ? dataGeolocation.map((provincia) => (
                              <option value={provincia.nombre}>
                                {provincia.nombre}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    {form.provincia == "" ? (
                      ""
                    ) : (
                      <div className="mb-3 text-start">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label fs-8  text-muted"
                        >
                          Localidad
                        </label>
                        <input
                          className="form-control border-custom-naranja fs-6 p-0 m-0 px-2 rounded-pill"
                          type="text"
                          value={form.localidad}
                          name="searchLocalidad"
                          onChange={(event) => onChange(event)}
                          autocomplete="nope"
                        />
                        <div className="ms-3 overflow-hidden">
                          {!dataLocalidad.content ? (
                            ""
                          ) : (
                            <ul class="list-group border-0 border-end border-start border-bottom">
                              {dataLocalidad.content.map((municipios) => (
                                <li class="list-group-item border-0 p-0">
                                  <button
                                    className=" btn btn-outline-dark fs-7 rounded-0 border-0 w-100"
                                    name="Selectlocalidad"
                                    value={municipios.nombre}
                                    onClick={(event) => onChange(event)}
                                  >
                                    {municipios.nombre}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </li>

            <li
              className="list-group-item m-0 mb-3 py-4 border-0 shadow-sm"
              style={{ borderRadius: "10px;" }}
            >
              <div className=" w-100 fs-5 fw-bold border-bottom d-block mb-3">
                Links Sociales
                <span className="fs-6 text-muted fw-normal d-block">
                  Ingresa las URLs de tus principales redes sociales.
                </span>
              </div>
              <div className="d-md-flex justify-content-between">
                <div className="text-muted fs-8 mb-1">
                  URL Sitio Web{" "}
                  <input
                    className="form-control rounded-pill p-0 m-0 px-2 border-custom-naranja"
                    type="text"
                    defaultValue={data.website}
                    name="website"
                    onChange={(event) => onChange(event)}
                  />
                </div>
              </div>
            </li>

          <div className="list-group-item d-flex justify-content-between m-0  border-0 shadow-sm row">
            <div className="col-md-12">
              <div className="mb-3 fs-5 py-2 fw-bold border-0 d-flex justify-content-between">
                <div>
                  Datos de facturación
                  <span className="fs-6 text-muted fw-normal d-block">
                  </span>
                </div>
                <div></div>
              </div>

                          {
                             data.usuarios.facturacion.length > 0
                             ?data.usuarios.facturacion.map((facturacion) => (
                             <div className="mb-3 mx-auto" style={{ maxWidth: "700px" }}>
                             <ul className="list-group">
                               <li className="list-group-item border-0 ">
                                 <div className="mb-3 w-100">
                                   <label
                                     for="exampleFormControlInput1"
                                     className="form-label fs-6  text-muted"
                                   >
                                     CUIL/CUIT del títular
                                   </label>
                                   <input
                                     type="number"
                                     className="form-control rounded-pill border-custom-naranja py-1 disabled"
                                     id="exampleFormControlInput1"
                                     defaultValue={facturacion.entidad}
                                     name="titular"
                                     disabled
                                     onChange={(event) => onChange(event)}
                                   />
                                 </div>
                                 <div className="mb-4">
                                   <select
                                     className="form-select form-select-lg border-custom-naranja rounded-pill py-1 disabled"
                                     aria-label=".form-select-lg example"
                                     name="dato_fiscal"
                                     onChange={(event) => onChange(event)}
                                     disabled
                                   >
                                     <option selected>
                                       {facturacion.dato_fiscal}
                                     </option>
                                   </select>
                                 </div>
             
                                 <div className="mb-4">
                                   <label
                                     for="comprobanteAfip"
                                     className="form-label w-100 "
                                   >
                                     <div className="d-md-flex justify-content-between mb-2">
                                       <span className="fs-6 text-muted ">
                                         Subir comprobante de AFIP
                                       </span>
             
                                         <a
                                           href={
                                             "https://antesala.com.ar/assets/img/comprobantes/" +
                                             facturacion.comprobante_afip
                                           }
                                           target="_blank"
                                           className="d-md-flex d-block btn btn-dark fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill "
                                         >
                                           <i className=" bi bi-file-earmark-pdf"></i>
                                           Descargar archivo
                                         </a>
                                     </div>
                                   </label>
                                   <input
                                     className="form-control d-none"
                                     type="file"
                                     id="comprobanteAfip"
                                     name="comprobante_afip"
                                     onChange={(event) => onChange(event)}
                                   />
                                 </div>
             
                                 <div className="mb-4">
                                   <label
                                     for="comprobanteTitular"
                                     className="form-label w-100 "
                                   >
                                     <div className="d-md-flex justify-content-between mb-2">
                                       <span className="fs-6 text-muted">
                                         Subir DNI o instrumento constitutivo
                                       </span>
             
                                       
                                         <a
                                           href={
                                             "https://antesala.com.ar/assets/img/comprobantes/" +
                                             facturacion.comprobante_entidad
                                           }
                                           target="_blank"
                                           className="btn btn-dark d-block  fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill"
                                         >
                                           <i className=" bi bi-file-earmark-pdf"></i>
                                           Descargar archivo
                                         </a>
                                     </div>
             
                                   </label>
                                   <input
                                     className="form-control d-none"
                                     type="file"
                                     id="comprobanteTitular"
                                     name="comprobante_entidad"
                                     onChange={(event) => onChange(event)}
                                   />
                                 </div>
             
                                 <div className="mb-4 w-100">
                                   <label
                                     for="exampleFormControlInput1"
                                     className="form-label fs-6  text-muted"
                                   >
                                     Número de CBU
                                   </label>
                                   <input
                                     type="number"
                                     className="form-control rounded-pill border-custom-naranja disabled"
                                     id="exampleFormControlInput1"
                                     defaultValue={
                                      facturacion.cbu
                                     }
                                     name="cbu"
                                     onChange={(event) => onChange(event)}
                                     disabled
                                   />
                                 </div>
             
                                 <div className="mb-3">
                                   <label for="comprobanteCbu" className="form-label w-100">
                                     <div className="d-md-flex justify-content-between mb-2">
                                       <span className="fs-6 text-muted">
                                         Subir comprobante de CBU
                                       </span>
                                      
                                         <a
                                           href={
                                             "https://antesala.com.ar/assets/img/comprobantes/" +
                                             facturacion.comprobante_cbu
                                           }
                                           target="_blank"
                                           className="btn btn-dark d-block  fw-normal GothamBold fs-8 text-nowrap p-0 m-0 px-2 mx-2 rounded-pill"
                                         >
                                           <i className=" bi bi-file-earmark-pdf"></i>
                                           Descargar archivo
                                         </a>
                                       
                                     </div>
                                     <span
                                       className="d-none"
                                     >
                                       Subir archivo PDF o formato imagen JPG JPEG PNG
                                     </span>
                                   </label>
                                   <input
                                     className="form-control d-none"
                                     type="file"
                                     id="comprobanteCbu"
                                     name="comprobante_cbu"
                                     onChange={(event) => onChange(event)}
                                   />
                                 </div>

                                 <div className="mb-4 w-100">
                                   <label
                                     for="exampleFormControlInput1"
                                     className="form-label fs-6  text-muted"
                                   >
                                     Procentaje
                                   </label>
                                   <input
                                     type="text"
                                     className="form-control rounded-pill border-custom-naranja disabled"
                                     id="exampleFormControlInput1"
                                     defaultValue={
                                      facturacion.porcentaje 
                                     }
                                     name="cbu"
                                     onChange={(event) => onChange(event)}
                                     disabled
                                   />
                                 </div>
                               </li>
                             </ul>
                           </div>
                             ))
                             : <div>No existen datos de facturación</div>
                          }      
            </div>
          </div>
          </ul>
        </div>

           
        </div>
        <div className="footer bg-white p-4">
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="">
              <button
                className="btn btn-secondary rounded-pill px-4"
                name="close_modal"
                onClick={(event) => onClick(event, null)}
              >
                Cerrar
              </button>
              <button
                className="btn btn-custom-amarillo GothamBold text-white ms-1 rounded-pill px-4"
                onClick={(event) => onSave(event, data)}
                disabled={
                  loading?
                  true
                  :
                  false
                }
              >
                {
                  loading?
                  <span className="spinner-grow spinner-grow-sm mx-1" role="status" aria-hidden="true"></span>
                  :
                  ""
                }
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalEditarProductor