import Cookie from "../cookies/Cookie";

const Header = () => {
  const bar = [
    {
      name: "INICIO",
      link: "/",
      dropdown: null,
    },
    {
      name: "ENTRADAS",
      link: "#",
      dropdown: null,
    },
    {
      name: "SOMOS",
      link: "#",
      dropdown: null,
    },
    {
      name: "CONTENIDOS",
      link: "#",
      dropdown: [
        {
          name: "NOTAS",
          link: "#",
        },
        {
          name: "VIDEOS",
          link: "#",
        },
      ],
    },
    {
      name: "SALAS",
      link: "#",
      dropdown: null,
    },
    {
      name: "FESTIVALES",
      link: "#",
      dropdown: null,
    },
    /*
    {
      name: "DESCUENTOS",
      link: "#",
      dropdown: null,
    },
    */
  ];

  const provincias = ["Córdoba", "Tucumán", "Santa Fe", "Entre Ríos"];

  return (
    <div className="container-md ">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src="/assets/img/marca/antesala-logo.svg"
              width="100px"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {bar.map((menu) => {
                if (menu.dropdown) {
                  return (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle fs-7 text-dark fw-bold"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {menu.name}
                      </a>
                      <ul className="dropdown-menu">
                        {menu.dropdown.map((submenu) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item fs-7 text-dark fw-bold"
                                href="#"
                              >
                                {submenu.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item">
                      <a
                        className="nav-link active fs-7 text-dark fw-bold"
                        aria-current="page"
                        href="#"
                      >
                        {menu.name}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>

            <form className="d-flex" role="search">
              <input
                className="form-control me-2 border border-dark"
                style={{ borderRadius: "20px" }}
                type="search"
                placeholder="Buscar"
                aria-label="Search"
              />
              <button
                className="btn btn-outline-dark border-0 rounded-pill me-2"
                type="submit"
              >
                <i class="bi bi-search"></i>
              </button>

              <div class="dropdown">
                <button
                  class="btn btn-outline-dark dropdown-toggle"
                  style={{ borderRadius: "20px" }}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Cordoba
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {provincias.map((provincia) => (
                    <li>
                      <label className="dropdown-item">
                        {provincia}
                        <button className="d-none"></button>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <label className="btn border-0  p-0 mx-2">
                <i class="rounded-pill bi bi-person-circle fs-4"></i>
                <button className="d-none"></button>
              </label>
            </form>
          </div>
        </div>
      </nav>
      <Cookie />
    </div>
  );
};

export default Header;
