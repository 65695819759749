import { useParams } from "react-router-dom";

const RestorePassword = () => {

const {info} = useParams();

return(
<div className="min-vh-100">
    <div className="container p-5 shadow-sm text-center" style={{borderRadius: "10px"}}>  
    <div className="text-success" style={{fontSize: "100px"}}>
    <i class="bi bi-envelope-check"></i>
    </div>
    <div className="fs-5">
      <p className="fw-bold fs-4">¡Recuperacion en progreso!</p>
      <p>Se enviará un correo de recuperacion a la cuenta <span className="fw-bold">{info}</span>.</p>
    </div>
  </div>
  </div>
);

}

export default RestorePassword