import Card from "../components/pago/Card.jsx";

const Pago = ({ info, setInfo, onChange }) => {
  return (
    <div className="container-md min-vh-100 d-flex justify-content-center align-items-center">
      <div className="row">
        <div className="col-md-8 col-12 ">
          <h3 className="GothamBold">Ingresá los datos de tu tarjeta.</h3>
          <div className="mb-3 d-flex justify-content-center">
            <Card info={info} />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Número de tarjeta
            </label>
            <input
              type="text"
              class="form-control py-3 border-dark"
              style={{ borderRadius: "20px" }}
              placeholder=""
              value={info.numero}
              name="numero"
              onChange={(e) => onChange(e)}
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Nombre y Apellido
            </label>
            <input
              type="text"
              class="form-control py-3 border-dark"
              name="titular"
              onChange={(e) => onChange(e)}
              style={{ borderRadius: "20px" }}
            />
          </div>
          <div className="d-flex">
            <div class="mb-3 me-2">
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                Fecha de vencimiento
              </label>
              <input
                type="text"
                class="form-control py-3 border-dark"
                placeholder="MM/AAAA"
                value={info.vencimiento}
                maxlength="7"
                name="vencimiento"
                onChange={(e) => onChange(e)}
                style={{ borderRadius: "20px" }}
              />
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                Código de seguridad
              </label>
              <input
                class="form-control py-3 border-dark"
                style={{ borderRadius: "20px" }}
                type="password"
                maxlength="7"
                name="codigo"
                onChange={(e) => onChange(e)}
                onFocus={(e) => setInfo({ ...info, focusCVV: true })}
                onBlur={(e) => setInfo({ ...info, focusCVV: false })}
              />
            </div>
          </div>
          <h3>Datos personales</h3>
          Lo vamos a utilizar para enviarte tu entrada o contactarte en caso de
          que sea necesario.
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              E-Mail
            </label>
            <input
              type="text"
              class="form-control py-3 border-dark"
              style={{ borderRadius: "20px" }}
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Teléfono
            </label>
            <input
              type="text"
              class="form-control py-3 border-dark"
              style={{ borderRadius: "20px" }}
            />
          </div>
        </div>
        <div className="col-md-4 bg-light">
          <div
            className="border"
            style={{
              backgroundImage: "url(/assets/img/img-teatro-01.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "30px",
              height: "300px",
            }}
          ></div>
          <ul class="list-group bg-transparent">
            <li class="list-group-item border-0 bg-transparent">
              <span className="fw-bold">MI NOMBRE ES EVA DUARTE</span>
            </li>
            <li class="list-group-item border-0 bg-transparent mb-3">
              <span className="fw-bold">Sabado, 15 de Abril 21Hrs</span>
              <div>
                <div className="d-flex justify-content-between">
                  <span>Precio</span>
                  <span>$ 1000</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Cantidad</span>
                  <span>X 1</span>
                </div>
              </div>
            </li>
            <li class="list-group-item border-0 bg-transparent">
              <div className="d-flex justify-content-between mb-3">
                <span>Sub Total</span>
                <span>$ 1000</span>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>+ Serv 15%</span>
                <span>$ 1000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="fw-bold">Total</span>
                <span>$ 1000</span>
              </div>
            </li>

            <li class="list-group-item border-0 bg-transparent">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" />
                <label class="form-check-label">
                  Utilizar  gift card
                </label>
              </div>

              <input type="text" class="satin-bright-input" placeholder="CODIGO" />
            </li>
            <li class="list-group-item border-0 bg-transparent">
              <label className="btn btn-custom-naranja text-white py-3 w-100 mb-1">
                Pagar
                <button className="d-none"></button>
              </label>
              <img
                src="/assets/img/decidir.png"
                className="img-fluid"
                style={{ width: "100px" }}
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pago;
