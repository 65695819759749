const Header = () => {

  return (
    <section class="wow bounceInUp d-none">
                <div class="container">
                    <div class="row">
                        <div class="col-8 col-md-9 pe-0">
                            <div class="card-ticket cardLeft d-flex flex-column">
                                <h1 class="fc-blanco">Salas</h1>
                                <div class="title">
                                    <h2>Antesala</h2>
                                    <span>sala</span>
                                    <div className="py-3"></div>
                                </div>
                                <div>
                                    <div class="seat">
                                        <h2>156</h2>
                                        <span>asiento</span>
                                    </div>
                                    <div class="time">
                                        <h2>12:00</h2>
                                        <span>hora</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-md-3 ps-0">
                            <div class="card-ticket cardRight d-flex flex-column justify-content-around position-relative">
                                <div class="text-center">
                                    <img src="assets/img/iso-antesala.svg" alt="" />
                                </div>
                                <div class="number">
                                    <h3>156</h3>
                                    <span>asiento</span>
                                </div>
                                <div class="text-center">
                                    <img class="barchart" src="assets/img/barcode.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default Header